import { Icon, IconProps } from "./Icon";

export type JumpProps = IconProps;

export const Jump = (props: JumpProps) => (
  <Icon width="30px" height="30px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill="currentcolor"
      d="M11.734 18.471c-.44 0-.66 0-.829-.085a.782.782 0 0 1-.344-.34c-.086-.166-.086-.384-.086-.82V12.05c0-.545 0-.817-.107-1.025a.978.978 0 0 0-.43-.425c-.21-.107-.485-.107-1.036-.107H7.98c-1.192 0-1.788 0-2.064-.233a.968.968 0 0 1-.342-.816c.029-.358.45-.774 1.293-1.608L14.084.698c.39-.386.584-.578.809-.65a.994.994 0 0 1 .608 0c.224.072.419.264.809.65l7.216 7.138c.843.834 1.265 1.25 1.293 1.608a.968.968 0 0 1-.342.816c-.276.233-.872.233-2.063.233h-.922c-.551 0-.826 0-1.037.107a.978.978 0 0 0-.43.425c-.107.208-.107.48-.107 1.025v5.176c0 .436 0 .654-.086.82a.782.782 0 0 1-.344.34c-.168.085-.388.085-.829.085h-6.925ZM10.476 22.46c0-.806.66-1.46 1.475-1.46h6.492c.815 0 1.475.654 1.475 1.46 0 .806-.66 1.46-1.475 1.46H11.95a1.467 1.467 0 0 1-1.475-1.46Z"
    />
    <path
      fill="currentcolor"
      d="M6.564 19.62c.639-.228.97-.925.74-1.557a1.233 1.233 0 0 0-1.573-.732c-1.595.568-2.982 1.305-3.992 2.204C.727 20.435 0 21.59 0 22.947c0 1.217.587 2.275 1.437 3.126.844.846 2.004 1.552 3.341 2.119C7.457 29.327 11.072 30 15 30s7.543-.673 10.221-1.808c1.338-.567 2.498-1.273 3.342-2.12.85-.85 1.437-1.908 1.437-3.125 0-1.358-.727-2.512-1.739-3.412-1.01-.9-2.397-1.636-3.992-2.204a1.233 1.233 0 0 0-1.573.732c-.23.632.101 1.329.74 1.556 1.412.503 2.484 1.104 3.18 1.724.697.62.925 1.166.925 1.604 0 .394-.183.872-.728 1.417-.55.551-1.405 1.103-2.56 1.592-2.305.978-5.576 1.612-9.253 1.612-3.677 0-6.948-.634-9.253-1.612-1.155-.49-2.01-1.04-2.56-1.592-.544-.545-.728-1.023-.728-1.418 0-.437.228-.983.924-1.603.697-.62 1.769-1.22 3.181-1.724Z"
    />
  </Icon>
);
