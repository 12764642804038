import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Plus } from "~/common/components/Icon";
import { useRefWrap } from "~/common/hooks/useRefWrap";
import { SectionHeader } from "~/hephaestus/components";
import { BufferGeometry } from "~/types/Geometry";
import ICoordinates from "~/types/ICoordinates";
import { GeometryInspectorBaseProps } from "../types";
import { PointEdit } from "./PointEdit";
import { selectBufferPoint, updatePoint, $selectedBufferPoint } from "./models";
import { useUnit } from "effector-react";

export type BufferGeometryInspectorProps = GeometryInspectorBaseProps<BufferGeometry>;

export const BufferGeometryInspector = ({ entity, geometry, onChange }: BufferGeometryInspectorProps) => {
  const selectedBufferPoint = useUnit($selectedBufferPoint);
  const geometryRef = useRefWrap(geometry);

  const handleAdd = useCallback(() => {
    const lastPoint = geometryRef.current.points[geometryRef.current.points.length - 1];
    const newPoint = lastPoint ? { ...lastPoint } : { x: 0, y: 0, z: 0 };
    const newPoints = [...geometryRef.current.points, newPoint];

    onChange?.({
      target: {
        value: {
          ...geometryRef.current,
          points: newPoints,
        },
      },
    });

    setTimeout(() => {
      selectBufferPoint({ entityId: entity.id, pointIndex: newPoints.length - 1 });
    }, 0);
  }, [entity.id, onChange]);

  const geometryChange = useCallback(
    (newPoint: ICoordinates, newPointIndex: number) => {
      onChange?.({
        target: {
          value: {
            ...geometryRef.current,
            points: geometryRef.current.points.map((point, index) => {
              if (index === newPointIndex) {
                return newPoint;
              }

              return point;
            }),
          },
        },
      });
    },
    [onChange]
  );

  const handleSelect = useCallback(
    (index: number) => selectBufferPoint({ entityId: entity.id, pointIndex: index }),
    [entity.id]
  );
  const handleRemove = useCallback(
    (removedIndex: number) => {
      onChange?.({
        target: {
          value: {
            ...geometryRef.current,
            points: geometryRef.current.points.filter((_, index) => index !== removedIndex),
          },
        },
      });
    },
    [onChange]
  );

  useEffect(() => {
    const subscription = updatePoint.watch((value) => {
      const index = selectedBufferPoint?.pointIndex ?? null;

      if (index !== null) {
        geometryChange(value, index);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [selectedBufferPoint?.pointIndex, geometryChange]);

  useEffect(() => {
    return () => {
      selectBufferPoint(null);
    };
  }, [entity.id]);

  return (
    <>
      <SectionHeader
        title="Points"
        suffix={
          <IconButton onClick={handleAdd}>
            <Plus />
          </IconButton>
        }
      />
      {geometry.points.map((point, index) => (
        <PointEdit
          key={index}
          point={point}
          index={index}
          active={index === selectedBufferPoint?.pointIndex}
          onSelect={handleSelect}
          onRemove={handleRemove}
          onChange={geometryChange}
        />
      ))}
    </>
  );
};
