import { useEffect } from "react";
import { Material } from "three";
import { suspend } from "suspend-react";
import { materialManager } from "./MaterialManager";

export function useMaterialAsset(materialAssetId: string) {
  let materialRecord = materialManager.materialsMap[materialAssetId];

  if (!materialRecord) {
    materialRecord = materialManager.requestMaterialAsset(materialAssetId);
  }

  useEffect(() => {
    return () => {
      materialManager.removeReferenceToMaterialAsset(materialAssetId);
    };
  }, [materialAssetId]);

  return suspend(materialRecord.promise, ["material", materialAssetId]) as Material;
}
