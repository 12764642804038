import { Icon, IconProps } from "./Icon";

export type Facebook2Props = IconProps;

export const Facebook2 = (props: Facebook2Props) => (
  <Icon width="14px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
      d="M8.73 4.333c-.84 0-1.26 0-1.58.187a1.624 1.624 0 0 0-.656.75c-.163.366-.163.846-.163 1.806V13m-1.5-5.333h4M1 7c0-1.676 0-2.514.236-3.187a4.2 4.2 0 0 1 2.577-2.577C4.486 1 5.324 1 7 1s2.514 0 3.187.236a4.2 4.2 0 0 1 2.577 2.577C13 4.486 13 5.324 13 7s0 2.514-.236 3.187a4.2 4.2 0 0 1-2.577 2.577C9.514 13 8.676 13 7 13s-2.514 0-3.187-.236a4.2 4.2 0 0 1-2.577-2.577C1 9.514 1 8.676 1 7Z"
    />
  </Icon>
);
