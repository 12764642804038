import { Navigate } from "~/common/components";
import { styled, Typography } from "@mui/material";

export function NavigationLink({ to, label }: { to: string; label: string }) {
  const pathSegment = to.split("/").pop() ?? "";
  return (
    <Navigate to={to}>
      <NavigationItem className={isActive(pathSegment) ? "active" : ""}>{label}</NavigationItem>
    </Navigate>
  );
}

const NavigationItem = styled(Typography)`
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  .active & {
    color: var(--White, #fff);
    border-bottom: 2px solid white;
    padding-bottom: 3px;
  }
`;

const isActive = (path: string) => {
  return window.location.pathname.includes(path);
};
