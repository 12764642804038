import { BaseSocket } from "../base";
import { stringValue } from "../values/stringValue";

export class StringSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: string = "") {
    super(stringValue.name, id, label, value);
  }

  clone() {
    return new StringSocket(this.id, this.label, this.value);
  }
}
