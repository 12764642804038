import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class CollisionMaskConstantNode extends BaseNode {
  static readonly type = "physics/collisionMaskConstant";
  static readonly label = "Collision mask";

  outputs = [new NumberSocket("mask")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const mask = node.readMetadata("mask", 0);

    context.writeOutput<number>("mask", mask);
  }
}
