import { useProfile } from "~/entities/profile";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Avatar } from "~/hephaestus/components/Avatar";
import { IconButton } from "~/hephaestus/components/IconButton";
import { WorkspaceUser } from "~/api/types";
import { Trash } from "../Icon";

type UserRecordProps = {
  user: WorkspaceUser;
  onDelete: (email: string) => void;
};

export function UserRecord({ user, onDelete }: UserRecordProps) {
  const { id, email, name, avatarPreviewUrl } = user;

  const isYourself = useProfile((state) => state.profile?.id === id);

  const [hover, setHover] = useState(false);

  const toName = () => {
    const displayName = name ? `${name} - ${email}` : email;
    return isYourself ? `${displayName} (you)` : displayName;
  };

  const toNameFirstLetter = (name?: string) => (name ? name.substring(0, 1) : "A");

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="3px"
      marginRight="3px"
      borderRadius="8px"
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      sx={{
        ...(hover && {
          background: palette.primary.black600,
        }),
      }}
    >
      <Stack direction="row" alignItems="center" gap="12px">
        <Avatar avatar={avatarPreviewUrl} text={toNameFirstLetter(name)} />
        <Typography variant="body1" color={palette.primary.gray600}>
          {toName()}
        </Typography>
      </Stack>
      {hover && !isYourself && (
        <IconButton onClick={() => onDelete(email)} size="small" sx={{ marginRight: "10px" }}>
          <Trash />
        </IconButton>
      )}
    </Stack>
  );
}
