import { CanvasTexture, ImageLoader, Mesh, MeshBasicMaterial, PlaneGeometry } from "three";
import { createEqualizer } from "~/view-scene/common/equalizer";

const geometry = new PlaneGeometry(1, 0.2719);

let joinedVoiceChat = false;

export class PushToTalkObject extends Mesh {
  private _active = false;

  private initialMesh: Mesh | null = null;
  private pressToTalkMesh: Mesh | null = null;
  private activeMesh: Mesh | null = null;
  private equalizerMesh: Mesh;
  private equalizer: ReturnType<typeof createEqualizer> | null = null;
  private analyser: AnalyserNode | null = null;

  get joinedVoiceChat() {
    return joinedVoiceChat;
  }

  get active() {
    return this._active;
  }

  joinVoiceChat(analyser: AnalyserNode) {
    this.analyser = analyser;
    this.equalizer = createEqualizer();

    joinedVoiceChat = true;

    if (this.initialMesh) {
      this.initialMesh.visible = false;
    }

    if (this.pressToTalkMesh) {
      this.pressToTalkMesh.visible = true;
    }
  }

  set active(flag: boolean) {
    this._active = flag;

    if (this.pressToTalkMesh) {
      this.pressToTalkMesh.visible = !flag;
    }

    if (this.activeMesh) {
      this.activeMesh.visible = flag;
      this.equalizerMesh.visible = flag;
    }
  }

  constructor() {
    super();

    new ImageLoader().load("/static/img/viewer/push-to-talk/b-to-join-voice.png", (image) => {
      const texture = new CanvasTexture(image);
      const material = new MeshBasicMaterial({ color: 0xffffff, map: texture, transparent: true });
      this.initialMesh = new Mesh(geometry.clone(), material);

      if (joinedVoiceChat) {
        this.initialMesh.visible = false;
      }

      this.add(this.initialMesh);
    });

    new ImageLoader().load("/static/img/viewer/push-to-talk/b-to-talk.png", (image) => {
      const texture = new CanvasTexture(image);
      const material = new MeshBasicMaterial({ color: 0xffffff, map: texture, transparent: true });
      this.pressToTalkMesh = new Mesh(geometry.clone(), material);

      if (!joinedVoiceChat || this.active) {
        this.pressToTalkMesh.visible = false;
      }

      this.add(this.pressToTalkMesh);
    });

    new ImageLoader().load("/static/img/viewer/push-to-talk/talk-now.png", (image) => {
      const texture = new CanvasTexture(image);
      const material = new MeshBasicMaterial({ color: 0xffffff, map: texture, transparent: true });
      this.activeMesh = new Mesh(geometry.clone(), material);

      if (!joinedVoiceChat || !this.active) {
        this.activeMesh.visible = false;
      }

      this.add(this.activeMesh);
    });

    this.equalizerMesh = new Mesh(
      geometry.clone(),
      new MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.4 })
    );
    this.equalizerMesh.position.set(0, 0, 0.001);
    this.equalizerMesh.scale.set(0, 1, 1);
    this.add(this.equalizerMesh);
    this.equalizerMesh.visible = false;
  }

  onBeforeRender = () => {
    if (!this.active || !this.equalizer) {
      return;
    }

    const scaleX = this.equalizer(this.analyser);
    this.equalizerMesh.scale.set(scaleX, 1, 1);
  };
}
