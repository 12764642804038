import { attach } from "effector";
import { updateEntity } from "./updateEntity";
import { selectEntity } from "./selectEntity";
import { $entities } from "~/entities/entities";
import { arrayMove } from "@dnd-kit/sortable";
import ISceneObject from "~/types/ISceneObject";

export const moveEntity = attach({
  source: $entities,
  effect: (entities, { id, parentId, overId }: { id: string; parentId: string | null; overId: string }) => {
    if (!entities) {
      return;
    }

    const entity = entities!.find((entity) => entity.id === id);
    if (!entity) {
      return;
    }

    const initialEntities = entities.sort((a, b) => a.order - b.order);
    const overIndex = initialEntities.findIndex((entity) => entity.id === overId);
    const initialEntityIndex = initialEntities.findIndex((entity) => entity.id === id);

    const adjustedEntities = arrayMove(initialEntities, initialEntityIndex, overIndex);

    const adjustedEntityIndex = adjustedEntities.indexOf(entity);

    const previousOrder = adjustedEntityIndex > 0 ? adjustedEntities[adjustedEntityIndex - 1].order : 0;
    const nextOrder =
      adjustedEntities.length - 1 >= adjustedEntityIndex + 1
        ? adjustedEntities[adjustedEntityIndex + 1].order
        : previousOrder + 1000;

    const order = getRandomFloat(previousOrder, nextOrder, 6);

    updateEntity({
      id,
      entity: {
        id,
        parentId,
        order,
      },
    });

    const orderedChildren = getAllChildrenOrdered(initialEntities, entity);

    const deltaOrder = (nextOrder - order) / (orderedChildren.length + 1);

    orderedChildren.forEach((child, index) => {
      updateEntity({
        id: child.id,
        entity: {
          id: child.id,
          order: order + deltaOrder * (index + 1),
        },
      });
    });

    selectEntity(id);
  },
});

const getAllChildrenOrdered = (orderedEntities: ISceneObject[], parent: ISceneObject): ISceneObject[] => {
  const children = orderedEntities.filter((child) => child.parentId === parent.id);
  const result: ISceneObject[] = [];
  children.forEach((child) => {
    result.push(child);
    result.push(...getAllChildrenOrdered(orderedEntities, child));
  });
  return result;
};

function getRandomFloat(min: number, max: number, decimals: number) {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);
  return parseFloat(str);
}
