import { createEvent, createStore } from "effector";
import { Matrix4, Object3D, Vector3 } from "three";

export type OcclusionItem = {
  object: Object3D;
  matrix: Matrix4;
  position: Vector3;
  name?: string;
  query?: WebGLQuery;
  queryInProgress?: boolean;
  occluded?: boolean;
};

export const addItems = createEvent<Pick<OcclusionItem, "object" | "matrix" | "position" | "name">[]>();
export const removeItems = createEvent<Pick<OcclusionItem, "object">[]>();
export const reset = createEvent();

export const $items = createStore<OcclusionItem[]>([])
  .on(addItems, (state, items) => [...state, ...items])
  .on(removeItems, (state, items) => {
    const uuids = new Set(items.map((item) => item.object.uuid));

    return state.filter((item) => !uuids.has(item.object.uuid));
  })
  .reset(reset);
