import { createEffect, createStore, forward } from "effector";
import IScene from "~/types/IScene";
import { cloneScene, deleteScene, getMyScenes } from "~/api/scene.api";

export const loadScenesFx = createEffect(async () => {
  const { status, body: scenes } = await getMyScenes();

  if (status !== 200) {
    throw new Error("Unable to load scenes");
  }

  return scenes as unknown as IScene[];
});

export const deleteScenesFx = createEffect(async (id: string) => {
  const { status } = await deleteScene(id);

  if (status !== 204) {
    throw new Error("Unable to delete the scene");
  }
});

export const cloneScenesFx = createEffect(async (id: string) => {
  const { status, body } = await cloneScene(id);

  if (status !== 200) {
    throw new Error("Unable to clone the scene");
  }

  return body as unknown as IScene;
});

export const $scenes = createStore<IScene[]>([]).on(loadScenesFx.doneData, (_, payload) => payload);

forward({
  from: [deleteScenesFx.doneData, cloneScenesFx.doneData],
  to: loadScenesFx,
});
