import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";

export class SetUserGTAGNode extends BaseNode {
  static readonly type = "analytics/setUserGtagNode";
  static readonly label = "Send User GTAG";

  inputs = [new FlowSocket("flow"), new StringSocket("userId")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const userId = context.readInput<string>("userId");

    if (window.gtag) window.gtag("set", { user_id: userId });
  }
}
