import { LabeledNumericField, LabeledNumericFieldProps } from "~/hephaestus/components/LabeledNumericField";
import { Stack, styled } from "@mui/material";
import { Vector2 } from "~/types/Vector2";

export type Vector2FieldFieldProps = Pick<LabeledNumericFieldProps, "disableNativeUndoRedo"> & {
  name: string;
  value: Vector2;
  onChange: (e: OnVector2FieldChangeEvent) => void;
};

export type OnVector2FieldChangeEvent = {
  target: {
    name: string;
    value: Vector2;
  };
};

export function Vector2Field({ name, value, onChange }: Vector2FieldFieldProps) {
  const { x, y } = value;

  const handleChange: NonNullable<LabeledNumericFieldProps["onChange"]> = (e) => {
    onChange({
      target: {
        name: name,
        value: {
          ...value,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <Stack direction="row">
      <LeftInput name="x" value={x} label="X" disableNativeUndoRedo onChange={handleChange} />
      <RightInput name="y" value={y} label="Y" disableNativeUndoRedo onChange={handleChange} />
    </Stack>
  );
}

const Input = styled(LabeledNumericField)`
  .MuiInputAdornment-root .MuiTypography-root {
    display: inline-block;
    width: 5px;
    font-size: 8px;
  }

  &.MuiInputBase-adornedEnd.MuiInputBase-adornedEnd {
    padding-right: 4px;
  }

  .MuiInputBase-input::-webkit-outer-spin-button,
  .MuiInputBase-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiInputBase-input {
    -moz-appearance: textfield;
    font-size: 11px;
    letter-spacing: -0.08em;
    padding-left: 4px;
    padding-right: 4px;
  }

  &:hover {
    .MuiInputAdornment-root {
      display: none;
    }

    .MuiInputBase-root {
      padding-right: 0;
    }

    .MuiInputBase-input {
      padding-right: 16px;
    }

    .MuiInputBase-input::-webkit-outer-spin-button,
    .MuiInputBase-input::-webkit-inner-spin-button {
      -webkit-appearance: auto;
      margin-right: -16px;
    }

    .MuiInputBase-input {
      -moz-appearance: auto;
    }
  }
`;

const LeftInput = styled(Input)`
  & .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const RightInput = styled(Input)`
  & .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
