import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket } from "../../sockets";

export class CameraControlSwitchNode extends BaseNode {
  static readonly type = "action/cameraControlSwitchNode";
  static readonly label = "Camera Control Switch";

  inputs = [new FlowSocket("flow"), new BooleanSocket("enabled")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const enabled = context.readInput<boolean>("enabled");
    node.sceneContext.updateCameraControls(enabled);
  }
}
