import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, Vector3Socket } from "../../sockets";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { Vector3Value } from "../../values";

const position = new Vector3();

export class WorldPositionNode extends BaseNode {
  static readonly type = "action/worldPosition";
  static readonly label = "Get world position";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new Vector3Socket("position")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const rootObj = node.readEntity(context, "entity")?.rootObjectRef.current;

    if (rootObj) {
      rootObj.getWorldPosition(position);
      setVector3ValueFromXYZ(node.storage.output, position);
    }

    context.writeOutput<Vector3Value>("position", node.storage.output);
  }
}
