import { styled } from "@mui/material";
import { SuspenseImage } from "./SuspenseImage";
import { modalsConfig } from "./modalsConfig";
import { buttons } from "./Button";

const modalUrls = Object.values(modalsConfig);
const buttonUrls = Object.values(buttons).map((button) => button.src);

const urls = [...modalUrls, ...buttonUrls];

export const ImagesPreload = () => {
  return (
    <Container>
      {urls.map((url, index) => (
        <SuspenseImage key={index} src={url} />
      ))}
    </Container>
  );
};

const Container = styled("div")`
  position: fixed;
  left: -9999999px;
  height: 0px;
  width: 0px;
  overflow: hidden;
`;
