import { Link, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import AttentionAlert from "~/common/components/AttentionAlert";
import { SocialNetworkLink } from "./SocialNetworkLink";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export function RegistrationSuccessful() {
  return (
    <>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "24px",
          margin: "0 0 24px 0",
          width: "100%",
          color: palette.primary.white,
        }}
        variant="body1"
      >
        Thank you for registering! You will be redirected to the page you have requested. You will receive a
        confirmation email shortly. Thank you!
      </Typography>
      <AttentionAlert
        sx={{
          "&.MuiAlert-root": {
            ".MuiAlert-message": {
              flex: 1,
            },
          },
        }}
        severity="info"
      >
        <Stack alignItems="center">
          <Typography
            textAlign="center"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              width: "100%",
              color: palette.primary.gray800,
            }}
            variant="body1"
          >
            If you have any questions, please, contact us at:
          </Typography>
          <Link
            href="mailto:hello@8xr.io"
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              textAlign: "center",
              width: "100%",
              textDecoration: "none",
              maxWidth: "fit-content",
              color: palette.primary.violet,
              borderBottom: "1px solid " + palette.primary.violet600,
              transition: ".4s ease",
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            hello@8xr.io
          </Link>
        </Stack>
      </AttentionAlert>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          marginTop: "24px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            color: palette.primary.gray800,
          }}
          variant="body1"
        >
          Follow us on:
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({
            gridGap: "28px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
            [theme.breakpoints.down("md")]: {
              position: "static",
              transform: "inherit",
            },
          })}
        >
          <SocialNetworkLink href="https://twitter.com/8xr_engine" Icon={TwitterIcon} />
          <SocialNetworkLink href="https://www.instagram.com/8xr.engine/" Icon={InstagramIcon} />
          <SocialNetworkLink href="https://www.linkedin.com/company/8xr/" Icon={LinkedInIcon} />
        </Stack>
      </Stack>
    </>
  );
}
