import { combine, StoreValue } from "effector";
import { $currentVideo360 } from "./video360";
import { $currentCutscene } from "./cutscene";

export const $sceneMode = combine([$currentVideo360, $currentCutscene], ([currentVideo360, currentCutscene]) => {
  if (currentVideo360) {
    return "video360";
  } else if (currentCutscene) {
    return "cutscene";
  }

  return "scene";
});

export type SceneMode = StoreValue<typeof $sceneMode>;
