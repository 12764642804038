import { RefObject } from "react";
import type { CollisionStartEvent, CollisionEndEvent } from "~/view-scene/physics";
import { EntityContext } from "../types";

export const subscribeCollisions = (entityContext: RefObject<EntityContext>, rigidBodyId?: string) => {
  const startHandler = (event: CollisionStartEvent) => {
    const entityId = event?.meta?.entityId;

    if (entityId) {
      entityContext.current?.events.emit("collisionStart", entityId);
    }
  };

  const endHandler = (event: CollisionEndEvent) => {
    const entityId = event?.meta?.entityId;

    if (entityId) {
      entityContext.current?.events.emit("collisionEnd", entityId);
    }
  };

  const physicsBody = entityContext.current?.getPhysicsBody(rigidBodyId);

  physicsBody?.on("collisionStart", startHandler);
  physicsBody?.on("collisionEnd", endHandler);

  return () => {
    physicsBody?.off("collisionStart", startHandler);
    physicsBody?.off("collisionEnd", endHandler);
  };
};
