import { createEffect, createEvent, createStore } from "effector";
import { keyBy } from "lodash-es";
import { ComponentType } from "~/types/ComponentType";
import { EnabledStatus } from "~/types/EnabledStatus";
import IScene from "~/types/IScene";
import { SceneObjectType } from "~/types/SceneObjectType";
import { mapPreloadConfigs } from "~/view-scene/preloadSystem";
import { resetSceneLoad } from "./reset";

type LoadItem = {
  id: string;
  type: ComponentType.RIGID_BODY | SceneObjectType.SPAWN_POINT | SceneObjectType.NAV_MESH | "asset";
  loaded: boolean;
};

type LoadItems = {
  total: number | undefined;
  loaded: number;
  items: Record<string, LoadItem>;
};

export const $loadItems = createStore<LoadItems>({
  total: undefined,
  loaded: 0,
  items: {},
});

export const initLoadProgressFx = createEffect((scene: IScene) => {
  const items = scene.objects.reduce((acc, object) => {
    if (object.enabled === EnabledStatus.disabled) {
      return acc;
    }

    if (object.type === SceneObjectType.SPAWN_POINT || object.type === SceneObjectType.NAV_MESH) {
      acc.push({
        id: object.id,
        type: object.type,
        loaded: false,
      });
    }

    for (const component of object.components) {
      if (component.enabled === EnabledStatus.enabled && component.type === ComponentType.RIGID_BODY) {
        acc.push({
          id: component.id,
          type: ComponentType.RIGID_BODY,
          loaded: false,
        });
      }
    }

    return acc;
  }, [] as LoadItem[]);

  const preloadConfigs = mapPreloadConfigs(scene.preloadedAssets);

  preloadConfigs.forEach((config) => {
    items.push({
      id: config.id,
      type: "asset",
      loaded: false,
    });
  });

  pushItems(items);
});

const pushItems = createEvent<LoadItem[]>();
export const loadFinish = createEvent<string>();

$loadItems.on(pushItems, (state, items) => {
  const newItems = items.filter((item) => !state.items[item.id]);

  if (newItems.length === 0) {
    return;
  }

  return {
    ...state,
    total: state.total ?? 0 + newItems.length,
    items: {
      ...state.items,
      ...keyBy(newItems, "id"),
    },
  };
});

$loadItems.on(loadFinish, (state, id) => ({
  ...state,
  loaded: state.loaded + 1,
  items: {
    ...state.items,
    [id]: {
      ...state.items[id],
      loaded: true,
    },
  },
}));

$loadItems.reset(resetSceneLoad);

export const $itemsLoadProgress = $loadItems.map((state) => {
  if (state.total === undefined) {
    return 0;
  }

  const percent = Math.min(state.loaded / state.total, 1);

  return percent * 100;
});

export const $itemsLoaded = $loadItems.map((state) => state.total !== undefined && state.loaded > state.total);
