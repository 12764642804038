import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { Color, SpotLight, SpotLightHelper } from "three";
import ISpotLight from "../../../types/ISpotLight";
import { RenderLightHelper } from "./RenderLightHelper";

type RenderSpotLightProps = {
  light: ISpotLight;
  drawHelper: boolean;
};

export default function RenderSpotLight({ light, drawHelper }: RenderSpotLightProps) {
  const { color, intensity, castShadow, distance, angle, penumbra, decay, target, shadowBias, normalBias, resolution } =
    light;

  const spotLightRef = useRef<SpotLight>() as MutableRefObject<SpotLight>;

  const spotLight = useMemo(() => {
    const light = new SpotLight(color, intensity, distance, angle, penumbra, decay);
    light.shadow.mapSize.width = resolution * 1024;
    light.shadow.mapSize.height = resolution * 1024;
    return light;
  }, [resolution]);

  useEffect(() => {
    spotLight.color = new Color(color);
    spotLight.intensity = intensity;
    spotLight.castShadow = castShadow;
    spotLight.distance = distance;
    spotLight.angle = angle;
    spotLight.penumbra = penumbra;
    spotLight.decay = decay;
    spotLight.shadow.bias = shadowBias;
    spotLight.shadow.normalBias = normalBias;
  }, [spotLight, castShadow, color, intensity, distance, angle, penumbra, decay, shadowBias, normalBias]);

  return (
    <>
      <primitive object={spotLight} ref={spotLightRef} />
      <primitive object={spotLight.target} position={[target.x, target.y, target.z]} />
      {drawHelper && <RenderLightHelper lightRef={spotLightRef} helperConstructor={SpotLightHelper} />}
    </>
  );
}
