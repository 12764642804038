import { Icon, IconProps } from "./Icon";

export type AlignLeftProps = IconProps;

export const AlignLeft = (props: AlignLeftProps) => (
  <Icon width="13px" height="10px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.667 3.667H1M12.333 1H1m11.333 5.333H1M9.667 9H1"
    />
  </Icon>
);
