import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { CameraContext } from "~/view-scene/runtime";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

const vector3 = new Vector3();

export class CameraWorldPositionNode extends BaseNode {
  static readonly type = "action/cameraWorldPosition";
  static readonly label = "Get Camera World Position";
  static readonly pure = true;

  outputs = [new Vector3Socket("position")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = 0;
    node.storage.output.y = 0;
    node.storage.output.z = 0;

    const entity = node.sceneContext.getEntityContext<CameraContext>("camera");

    if (!entity) {
      return;
    }
    const camera = entity.rootObjectRef.current;

    if (camera) {
      camera.getWorldPosition(vector3);
      setVector3ValueFromXYZ(node.storage.output, vector3);
    }

    context.writeOutput<Vector3Value>("position", node.storage.output);
  }
}
