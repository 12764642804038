import { Icon, IconProps } from "./Icon";

export type StorageProps = IconProps;

export const Storage = (props: StorageProps) => (
  <Icon width="14px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 3.333c0 1.105-2.686 2-6 2s-6-.895-6-2m12 0c0-1.104-2.686-2-6-2s-6 .896-6 2m12 0v9.334c0 1.107-2.667 2-6 2s-6-.893-6-2V3.333M13 8c0 1.107-2.667 2-6 2s-6-.893-6-2"
    />
  </Icon>
);
