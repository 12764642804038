import * as React from "react";
import { memo, ReactNode } from "react";
import { Entity } from "../Entity";
import ILightSceneObject from "~/types/ILightSceneObject";
import { RenderLight } from "./RenderLight";
import { useEntity } from "~/view-scene/utils/useEntity";

type LightEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const LightEntity = memo(({ entityId, children }: LightEntityProps) => {
  const entity = useEntity<ILightSceneObject>(entityId);

  return (
    <Entity entityId={entityId}>
      <RenderLight light={entity.light} />
      {children}
    </Entity>
  );
});
