import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class SetGravityNode extends BaseNode {
  static readonly type = "physics/setGravityNode";
  static readonly label = "Set gravity";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new Vector3Socket("gravity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");
    const gravity = context.readInput<Vector3Value>("gravity");
    const physicsBody = entityContext?.getPhysicsBody();

    physicsBody?.setGravity(gravity.x, gravity.y, gravity.z);
    physicsBody?.activate();
  }
}
