import { Euler, Matrix4, Quaternion, Vector3 } from "three";
import ITransformation from "../../types/ITransformation";

const tmpPosition = new Vector3();
const tmpRotation = new Quaternion();
const tmpScale = new Vector3();
const tmpEuler = new Euler();
const tmpMatrix4 = new Matrix4();

export const transformationToMatrix4 = ({ position, rotation, scale }: ITransformation) => {
  tmpPosition.set(position.x, position.y, position.z);
  tmpRotation.set(rotation.x, rotation.y, rotation.z, 1);
  tmpScale.set(scale.x, scale.y, scale.z);
  tmpEuler.set(rotation.x, rotation.y, rotation.z);
  tmpRotation.setFromEuler(tmpEuler);

  return tmpMatrix4.compose(tmpPosition, tmpRotation, tmpScale);
};
