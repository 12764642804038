import { Storage } from "~/types/Storage";
import {
  ArraySocket,
  BooleanSocket,
  MapSocket,
  NumberSocket,
  StringSocket,
  StructSocket,
  Vector3Socket,
} from "../../sockets";

export const getSockets = (storage: Storage) => {
  return storage.fields.map((field) => {
    switch (field.type) {
      case "boolean":
        return new BooleanSocket(field.id, field.name);
      case "number":
        return new NumberSocket(field.id, field.name);
      case "string":
        return new StringSocket(field.id, field.name);
      case "vector3":
        return new Vector3Socket(field.id, field.name);
      case "array":
        return new ArraySocket(field.id, field.valueType, field.name);
      case "map":
        return new MapSocket(field.id, field.valueType, field.name);
      case "struct":
        return new StructSocket(field.id, field.name);
    }
  });
};
