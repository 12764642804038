import ISkyboxAsset from "~/types/ISkyboxAsset";
import { useAsset } from "~/entities/assets";
import IImageAsset from "~/types/IImageAsset";

export const useCubeMap = (skyboxAsset: ISkyboxAsset) => {
  const {
    right: rightAssetId,
    left: leftAssetId,
    top: topAssetId,
    bottom: bottomAssetId,
    front: frontAssetId,
    back: backAssetId,
  } = skyboxAsset;

  const rightAsset = useAsset<IImageAsset>(rightAssetId);
  const leftAsset = useAsset<IImageAsset>(leftAssetId);
  const topAsset = useAsset<IImageAsset>(topAssetId);
  const bottomAsset = useAsset<IImageAsset>(bottomAssetId);
  const frontAsset = useAsset<IImageAsset>(frontAssetId);
  const backAsset = useAsset<IImageAsset>(backAssetId);

  if (!rightAsset || !leftAsset || !topAsset || !bottomAsset || !frontAsset || !backAsset) {
    return null;
  }

  return [rightAsset.url, leftAsset.url, topAsset.url, bottomAsset.url, frontAsset.url, backAsset.url];
};
