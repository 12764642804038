import { FC } from "react";
import { Cone, CubeBox, Cylinder, IconProps, Plane, Render, Sphere } from "~/common/components/Icon";
import { PartialRecord } from "~/common/typeUtils";
import { GeometryType } from "~/types/Geometry";

export type PrimitiveIconProps = IconProps & {
  type: GeometryType;
};

export const PrimitiveIcon = ({ type, ...rest }: PrimitiveIconProps) => {
  const Component = componentsMap[type] ?? Render;

  return <Component {...rest} />;
};

const componentsMap: PartialRecord<GeometryType, FC<IconProps>> = {
  plane: Plane,
  box: CubeBox,
  cylinder: Cylinder,
  cone: Cone,
  sphere: Sphere,
};
