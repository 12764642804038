import { NodeContext } from "../../base";
import { AudioSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToAudioNode extends CastToNode {
  static readonly type = "logic/castToAudio";
  static readonly label = "Cast to Audio";

  constructor(context: NodeContext) {
    super(context, new AudioSocket("audio"), "audio");
  }
}
