import IModelAsset from "~/types/IModelAsset";

export const getVariantUrl = ({ variants }: IModelAsset, variantId?: string | null): string => {
  if (variantId) {
    const targetVariant = variants.find((variant) => variant.id === variantId);
    if (targetVariant) {
      return targetVariant.url;
    }
  }

  return variants.find((variant) => variant.isOriginal)!.url;
};

export const getDefaultVariantId = ({ variants }: IModelAsset): string => {
  return variants.find((variant) => variant.isOriginal)!.id;
};
