import { Stack, StackProps } from "@mui/material";
import { Label, LabelProps } from "./Label";

export type LabelGroupProps = StackProps & {
  label: string;
  labelMaxWidth?: string;
  LabelProps?: Omit<LabelProps, "label">;
};

export function LabelGroup({ label, LabelProps, labelMaxWidth = "50px", children, sx }: LabelGroupProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: "4px 10px 4px 10px",
        gridGap: "10px",
        ...sx,
      }}
    >
      <Label label={label} sx={{ flex: `0 0 ${labelMaxWidth}` }} {...LabelProps} />
      {children}
    </Stack>
  );
}
