import { useStoreMap } from "effector-react";
import { $entitiesState } from "./entitiesState";

export const useEntityState = <TType = any>(id: string) => {
  return useStoreMap({
    store: $entitiesState,
    keys: [id],
    fn: (state, [id]) => state[id] ?? null,
  }) as TType | null;
};
