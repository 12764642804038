import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { BaseBehaveNode, BaseSocket, BaseNode, NodeContext } from "../../base";
import { EntitySocket } from "../../sockets";
import { EntityValue } from "../../values";

export class SceneEntityNode extends BaseNode {
  static readonly type: string = "variable/sceneEntity";
  static readonly label: string = "Entity";

  constructor(context: NodeContext, private socket: BaseSocket = new EntitySocket("entity")) {
    super(context);
    this.outputs = [socket];
  }

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityId = node.metadata?.entityId as string | undefined;

    node.storage.output.entityId = entityId ?? null;

    context.writeOutput<EntityValue>(this.socket.name, node.storage.output);
  }
}
