import { NodeContext } from "../../base";
import { AssetMaterialSocket } from "../../sockets";
import { AssetNode } from "./AssetNode";

export class AssetMaterialNode extends AssetNode {
  static readonly type = "variable/assetMaterial";
  static readonly label = "Material asset";

  constructor(context: NodeContext) {
    super(context, new AssetMaterialSocket("material"));
  }
}
