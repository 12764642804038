import { NodeEvalContext } from "~/libs/behave-graph";
import { TrajectoryComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, TrajectorySocket } from "../../sockets";

export class TrajectoryActiveNode extends BaseNode {
  static readonly type = "action/trajectoryActive";
  static readonly label = "Trajectory Is Active";

  inputs = [new TrajectorySocket("trajectory")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const trajectory = node.readComponent<TrajectoryComponentContext>(context, "trajectory");

    context.writeOutput<boolean>("result", trajectory?.isActive() ?? true);
  }
}
