import { Icon, IconProps } from "./Icon";

export type LockProps = IconProps;

export const Lock = (props: LockProps) => (
  <Icon width="46" height="50" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 50" {...props}>
    <path
      d="M36.3333 19.6667V14.3333C36.3333 6.96954 30.3638 1 23 1C15.6362 1 9.66663 6.96954 9.66663 14.3333V19.6667M23 31.6667V37M14.4666 49H31.5333C36.0137 49 38.2539 49 39.9652 48.1281C41.4705 47.3611 42.6944 46.1372 43.4613 44.6319C44.3333 42.9206 44.3333 40.6804 44.3333 36.2V32.4667C44.3333 27.9862 44.3333 25.746 43.4613 24.0347C42.6944 22.5294 41.4705 21.3056 39.9652 20.5386C38.2539 19.6667 36.0137 19.6667 31.5333 19.6667H14.4666C9.98621 19.6667 7.746 19.6667 6.0347 20.5386C4.52941 21.3056 3.30556 22.5294 2.53857 24.0347C1.66663 25.746 1.66663 27.9862 1.66663 32.4667V36.2C1.66663 40.6804 1.66663 42.9206 2.53857 44.6319C3.30556 46.1372 4.52941 47.3611 6.0347 48.1281C7.746 49 9.98621 49 14.4666 49Z"
      stroke="#9F92FF"
      strokeOpacity="0.8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
