import { useSceneData } from "~/common/stores/useSceneData";
import { DefaultMobileGuidance } from "./DefaultMobileGuidance";
import { SingleStickMobileGuidance } from "./SingleStickMobileGuidance";

export const MobileGuidance = () => {
  const inputProfile = useSceneData((state) => state.sceneState?.inputSystem.inputProfile);

  if (inputProfile?.type === "singleStick") {
    return <SingleStickMobileGuidance />;
  }

  return <DefaultMobileGuidance />;
};
