import { cloneDeep, keyBy } from "lodash-es";
import { v4 as uuid } from "uuid";
import { toHierarchyIds } from "~/common/utils/toHierarchyIds";
import { Hierarchical } from "~/types/Hierarchical";

export const cloneHierarchical = <TType extends Hierarchical>(entityId: string, entities: TType[]) => {
  const entitiesMap = keyBy(entities, "id");
  const hierarchyIds = toHierarchyIds(entities);
  const newEntities: TType[] = [];

  const clone = (entityId: string, override: Partial<Hierarchical> = {}) => {
    const entity = entitiesMap[entityId];

    if (!entity) {
      return;
    }

    const newEntity = {
      ...cloneDeep(entity),
      ...override,
    };
    newEntity.id = uuid();
    newEntity.children = undefined;

    newEntities.push(newEntity);
    const childrenIds = hierarchyIds[entityId] ?? [];

    for (const childId of childrenIds) {
      clone(childId, { parentId: newEntity.id });
    }
  };

  clone(entityId);

  return newEntities;
};
