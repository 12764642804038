import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { sRGBEncoding } from "three";
import { OrbitControls } from "@react-three/drei";
import { RenderAvatar } from "./RenderAvatar";
import { ErrorBoundary } from "~/view-scene/utils";

type AvatarPreviewProps = {
  avatarUrl: string;
};

export const AvatarPreview = ({ avatarUrl }: AvatarPreviewProps) => {
  const rendererOptions = {
    antialias: true,
    powerPreference: "high-performance",
    outputEncoding: sRGBEncoding,
  };

  return (
    <Canvas shadows={true} gl={rendererOptions}>
      <directionalLight intensity={1} position={[0, 10, 0]} />
      <ambientLight intensity={0.5} />
      <OrbitControls />
      <Suspense fallback={null}>
        <ErrorBoundary message="Failed rendering Avatar">
          <RenderAvatar avatarUrl={avatarUrl} />
        </ErrorBoundary>
      </Suspense>
    </Canvas>
  );
};
