import { DefaultRouter } from "./DefaultRouter";
import { ExportRouter } from "./ExportRouter";

export const Router = () => {
  if (window.__sceneData) {
    return <ExportRouter />;
  }

  return <DefaultRouter />;
};
