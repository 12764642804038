import { useSceneData } from "~/common/stores/useSceneData";
import { useEffect } from "react";
import { setAccessToken } from "~/api/accessToken";
import { useProfile } from "~/entities/profile";
import { loadStoragesFx } from "~/entities/storages";
import { usePhysics } from "~/view-scene/physics";
import { RenderScene } from "~/view-scene/RenderScene";
import { initLoadProgressFx, LoadingScreen } from "~/view-scene/sceneLoad";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { useDataReset } from "~/view-scene/useDataReset";
import { disableWarnings } from "~/view-scene/utils";
import { WelcomeScreen } from "./WelcomeScreen";

type NativeGameProps = {
  urlName: string;
  accessToken: string;
};

export function NativeGame({ urlName, accessToken }: NativeGameProps) {
  const loadData = useSceneData((state) => state.loadData);
  const loadPhysicsEngine = usePhysics((state) => state.load);
  useDataReset();

  const { loadProfile } = useProfile((state) => ({
    loadProfile: state.loadProfile,
  }));

  const { loadSupportedModes, updateStatus, displayLoadingScreen, displayWelcomeScene, displayScene } =
    useSessionStatus((state) => ({
      loadSupportedModes: state.loadSupportedModes,
      updateStatus: state.updateStatus,
      displayLoadingScreen: state.shouldDisplayLoadingScreen(),
      displayWelcomeScene: state.shouldDisplayWelcomeScene(),
      displayScene: state.shouldDisplayScene(),
    }));

  useEffect(() => {
    disableWarnings();

    async function initScene(urlName: string) {
      await loadSupportedModes();
      const { scene } = await loadData(urlName);

      if (scene) {
        initLoadProgressFx(scene);
        await Promise.all([loadPhysicsEngine(scene.physicsSystem), loadStoragesFx(scene.workspace._id!)]);
      }

      await updateStatus("data_loaded");
    }

    if (!urlName || !accessToken) {
      updateStatus("no_access");
      return;
    }

    setAccessToken(accessToken);
    loadProfile().then(() => initScene(urlName));
  }, []);

  return (
    <>
      {displayLoadingScreen && <LoadingScreen showHeader={false} />}
      {displayWelcomeScene && <WelcomeScreen />}
      {displayScene && <RenderScene />}
    </>
  );
}
