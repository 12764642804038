import { ScriptMethod } from "~/types/ScriptAsset";
import { Link } from "~/libs/behave-graph";

import { Graph } from "./Graph";
import { Registry } from "./Registry";
import { BaseBehaveNode } from "./base";
import { MethodInNode } from "./nodes";
import { ScriptContext } from "./types";
import type { SceneContext } from "./useSceneContext";
import type { MethodCallValues } from "./ScriptEvents";
import type { MethodsManager } from "./MethodsManager";

export class Method {
  private graph: Graph;
  private inNode: BaseBehaveNode | null = null;

  constructor(
    private method: ScriptMethod,
    registry: Registry,
    sceneContext: SceneContext,
    scriptContext: ScriptContext,
    methods: MethodsManager
  ) {
    this.graph = new Graph(method.graph, registry, sceneContext, scriptContext, methods);

    this.inNode = this.graph.findNode(MethodInNode.type);
  }

  call(values: MethodCallValues, callback: (values: MethodCallValues) => void) {
    const link = this.inNode!.outputSockets[0].links[0];

    this.graph.graphEvaluator.asyncCommit(new Link(this.inNode!.id, link.socketName), undefined);
    this.graph.executeAll();
  }
}
