import { Icon, IconProps } from "./Icon";

export type WidthProps = IconProps;

export const Width = (props: WidthProps) => (
  <Icon width="16px" height="18px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.5 9h15M8 .667v5.416m0 0 3.333-3.333M8 6.083 4.667 2.75M8 17.333v-5.416m0 0 3.333 3.333M8 11.917 4.667 15.25"
    />
  </Icon>
);
