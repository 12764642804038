import { attach } from "effector";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { $audioManager, initAudioManager } from "view-scene/audio";
import { diveIn } from "~/view-scene/DiveInSystem";

export const startSession = attach({
  source: $audioManager,
  effect: async ({ isReady }) => {
    useSessionStatus.getState().updateStatus("in_progress");
    diveIn();

    // TODO move it out of here
    if (!isReady) {
      await initAudioManager();
    }
  },
});
