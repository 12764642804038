import { useSuspenseImport } from "~/common/hooks/useSuspenseImport";
import { useSceneData } from "~/common/stores/useSceneData";

export type YukaModule = ReturnType<typeof useYukaModule>;

export const useYuka = () => {
  const navMeshEnabled = useSceneData((state) => state.sceneState?.navMeshSystem?.enabled ?? true);

  if (navMeshEnabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useYukaModule();
  } else {
    return null as any as YukaModule;
  }
};

const useYukaModule = () => {
  return useSuspenseImport(() => import("yuka"));
};
