import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RenderEmbeddedScene } from "./RenderEmbeddedScene";
import { useSceneData } from "~/common/stores/useSceneData";
import "webrtc-adapter";
import { initLoadProgressFx, LoadingScreen } from "~/view-scene/sceneLoad";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";

export function ViewEmbeddedScene() {
  const { urlName } = useParams();

  const loadData = useSceneData((state) => state.loadData);

  const navigate = useNavigate();

  const { updateStatus, displayLoadingScreen, displayScene } = useSessionStatus((state) => ({
    updateStatus: state.updateStatus,
    displayLoadingScreen: state.shouldDisplayLoadingScreen(),
    displayScene: state.shouldDisplayScene(),
  }));

  useEffect(() => {
    async function initScene(urlName: string) {
      const { scene } = await loadData(urlName);
      scene && initLoadProgressFx(scene);
      await updateStatus("data_loaded");
    }

    if (!urlName) {
      navigate("/404");
      return;
    }

    initScene(urlName);
  }, []);

  return (
    <>
      {displayLoadingScreen && <LoadingScreen />}
      {displayScene && <RenderEmbeddedScene />}
    </>
  );
}
