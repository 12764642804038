import { PartialRecord } from "~/common/typeUtils";
import { SceneObjectType } from "~/types/SceneObjectType";
import { CameraContext } from "./CameraContext";
import { SpriteContext } from "./SpriteContext";

export const getContextConstructor = (type: SceneObjectType): any | null => {
  return constructorsMap[type] ?? null;
};

const constructorsMap: PartialRecord<SceneObjectType, any> = {
  camera: CameraContext,
  sprite: SpriteContext,
};
