import { memo } from "react";
import ISkyboxAsset from "../../types/ISkyboxAsset";
import HDRIAsset from "~/types/HDRIAsset";
import { hasType } from "~/common/utils/hasType";
import IAsset from "~/types/IAsset";
import { AssetType } from "~/types/AssetType";
import { Environment as DreiEnvironment } from "@react-three/drei";
import { RenderCubemapEnvironment } from "./RenderCubemapEnvironment";
import { useAsset } from "~/entities/assets";

type EnvironmentProps = {
  background: boolean | "only";
  environmentAssetId?: string | null;
};

export const Environment = memo(({ background, environmentAssetId }: EnvironmentProps) => {
  const environmentAsset = useAsset<ISkyboxAsset | HDRIAsset>(environmentAssetId);

  if (!environmentAsset) {
    return null;
  }

  if (hasType<IAsset, ISkyboxAsset, AssetType>(environmentAsset, AssetType.skybox)) {
    return <RenderCubemapEnvironment background={background} cubemapAsset={environmentAsset} />;
  } else if (hasType<IAsset, HDRIAsset, AssetType>(environmentAsset, AssetType.hdri)) {
    const { url } = environmentAsset;
    return <DreiEnvironment background={background} files={url} />;
  }

  return null;
});
