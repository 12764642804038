import { defaultEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { playerId } from "~/view-scene/entities/PlayerEntity/constants";
import { BaseBehaveNode, BaseNode } from "../../base";
import { PlayerSocket } from "../../sockets";
import { PlayerValue } from "../../values";

export class ScenePlayerNode extends BaseNode {
  static readonly type: string = "variable/scenePlayer";
  static readonly label: string = "Player";
  static readonly category = "Variable";

  outputs = [new PlayerSocket("player")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = playerId;
    context.writeOutput<PlayerValue>("player", node.storage.output);
  }
}
