import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { playerValue, PlayerValue } from "../values";

export class PlayerSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: PlayerValue = defaultEntity()) {
    super(playerValue.name, id, label, value);
  }

  clone() {
    return new PlayerSocket(this.id, this.label, this.value);
  }
}
