import { useUnit } from "effector-react";
import { $structs } from "~/entities/structs";
import { Select, SelectItem, SelectProps } from "./Select";

export type StructSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const StructSelect = ({ emptyLabel = "Select struct", value, ...rest }: StructSelectProps) => {
  const structs = useUnit($structs);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {structs?.map((structs) => (
        <SelectItem key={structs.id} value={structs.id}>
          {structs.name}
        </SelectItem>
      ))}
    </Select>
  );
};
