import { RewardedVideoButtonUIEntity } from "~/types/ui";
import { Block } from "./Block";
import { useAsset } from "~/entities/assets";
import IImageAsset from "~/types/IImageAsset";
import { CanvasTexture, Color, LinearEncoding } from "three";
import { memo, ReactNode } from "react";
import { useTexture } from "~/common/utils/useTexture";
import { watchRewardedVideo } from "~/view-scene/RewardedVideoSystem";

export type RenderUIRewardedVideoButtonProps = {
  entity: RewardedVideoButtonUIEntity;
  aspect: [number, number, number];
  children?: ReactNode;
  onClick?: (entityId: string) => void;
  showBoxHelper?: boolean;
};

export const RenderUIRewardedVideoButton = memo(
  ({ entity, aspect, children, onClick, showBoxHelper }: RenderUIRewardedVideoButtonProps) => {
    const {
      id,
      name,
      enabled,
      width,
      height,
      sizeUnits,
      asset,
      contentDirection,
      padding,
      margin,
      borderRadius,
      borderWidth,
      borderColor,
      borderOpacity,
      justifyContent,
      alignItems,
      textAlign,
      backgroundSize,
    } = entity;

    const imageAsset = useAsset<IImageAsset>(asset);

    const texturesLoaded: { backgroundTexture?: CanvasTexture } = useTexture({ backgroundTexture: imageAsset?.url });
    if (texturesLoaded.backgroundTexture) {
      texturesLoaded.backgroundTexture.encoding = LinearEncoding;
    }

    if (!enabled) {
      return null;
    }

    const handleShowRewardedVideo = async () => {
      // it's a hack to enable selection in Editor
      if (onClick) {
        onClick(id);
      } else {
        await watchRewardedVideo();
      }
    };

    return (
      <Block
        backgroundOpacity={1}
        backgroundTexture={texturesLoaded.backgroundTexture}
        name={name}
        onClick={() => handleShowRewardedVideo()}
        width={sizeUnits === "percent" ? width * aspect[0] : width}
        height={sizeUnits === "percent" ? height * aspect[1] : height}
        margin={margin}
        padding={padding}
        contentDirection={contentDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
        textAlign={textAlign}
        borderRadius={borderRadius}
        borderOpacity={borderOpacity}
        borderWidth={borderWidth}
        borderColor={borderColor ? new Color(borderColor) : undefined}
        showBoxHelper={showBoxHelper}
        backgroundSize={backgroundSize}
      >
        {children}
      </Block>
    );
  }
);
