import { memo, ReactNode } from "react";
import { Entity } from "../Entity";
import IImageAsset from "~/types/IImageAsset";
import { useEntity } from "~/view-scene/utils/useEntity";
import { RenderImage } from "./RenderImage";
import IImageSceneObject from "~/types/IImageSceneObject";
import { useAsset } from "~/entities/assets";

export type ImageEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const ImageEntity = memo(({ entityId, children }: ImageEntityProps) => {
  const entity = useEntity<IImageSceneObject>(entityId);
  const assetId = entity.asset;
  const asset = useAsset<IImageAsset>(assetId);

  if (!asset) {
    return null;
  }

  return (
    <Entity entityId={entityId}>
      <RenderImage dto={entity} asset={asset} />
      {children}
    </Entity>
  );
});
