export enum SceneObjectType {
  ENTITY = "entity",
  PLAYER = "player",
  GLB = "glb",
  TEXT = "text",
  TELEPORT = "teleport",
  IMAGE = "image",
  UI = "ui",
  AUDIO = "audio",
  SCREEN_SHARE = "screenShare",
  TRIGGER_ZONE = "triggerZone",
  TRIGGER_VOLUME = "triggerVolume",
  OCCLUSION_VOLUME = "occlusionVolume",
  BLOCKING_VOLUME = "blockingVolume",
  KILL_VOLUME = "killVolume",
  PLATFORM = "platform",
  SKY = "sky",
  WATER = "water",
  CLOUD = "cloud",
  STARS = "stars",
  MIRROR = "mirror",
  TWITTER_BOARD = "twitterBoard",
  INSTANCED_MESH = "instancedMesh",
  NPC = "npc",
  LIGHT = "light",
  PRIMITIVE = "primitive",
  VIDEO_360 = "video360",
  VIDEO = "video",
  GROUP = "entity",
  BILLBOARD = "billboard",
  SPAWN_POINT = "spawnPoint",
  NAV_MESH = "navMesh",
  SNOW = "snow",
  SPRITE = "sprite",
  CAMERA = "camera",
}
