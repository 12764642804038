import { Suspense, lazy } from "react";

const MobileInventory = lazy(() => import("./MobileInventory").then((module) => ({ default: module.MobileInventory })));

export const MobileInventoryLazy = () => {
  return (
    <Suspense fallback={null}>
      <MobileInventory />
    </Suspense>
  );
};
