import { Icon, IconProps } from "./Icon";

export type UIProps = IconProps;

export const UI = (props: UIProps) => (
  <Icon width="38px" height="38px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" {...props}>
    <path
      d="M17 3H10.6C7.23969 3 5.55953 3 4.27606 3.65396C3.14708 4.2292 2.2292 5.14708 1.65396 6.27606C1 7.55953 1 9.23968 1 12.6V27.4C1 30.7603 1 32.4405 1.65396 33.7239C2.2292 34.8529 3.14708 35.7708 4.27606 36.346C5.55953 37 7.23968 37 10.6 37H25.4C28.7603 37 30.4405 37 31.7239 36.346C32.8529 35.7708 33.7708 34.8529 34.346 33.7239C35 32.4405 35 30.7603 35 27.4V21M27 27H9M35.2426 2.75736C37.5858 5.1005 37.5858 8.8995 35.2426 11.2426C32.8995 13.5858 29.1005 13.5858 26.7574 11.2426C24.4142 8.8995 24.4142 5.1005 26.7574 2.75736C29.1005 0.414214 32.8995 0.414214 35.2426 2.75736Z"
      stroke="currentcolor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
