import { useSceneData } from "~/common/stores/useSceneData";
import shallow from "zustand/shallow";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { useProfile } from "~/entities/profile";
import { RenderRemoteUsers } from "./RenderRemoteUsers";

export function RemoteUsers() {
  const isLoggedIn = useProfile((state) => state.isAuthorised());
  const sessionStarted = useSessionStatus((s) => s.sessionStatus === "in_progress" || s.sessionStatus === "onpause");
  const networkSystem = useSceneData((state) => state.sceneState?.networkSystem, shallow);
  const multiplayerEnabled = networkSystem?.enableMultiplayer ?? false;

  const shouldRenderRemoteUsers = multiplayerEnabled && isLoggedIn && sessionStarted;

  if (!shouldRenderRemoteUsers) {
    return null;
  }

  return <RenderRemoteUsers />;
}
