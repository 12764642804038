import { Image } from "@react-three/drei";
import IImageSceneObject from "~/types/IImageSceneObject";
import IImageAsset from "~/types/IImageAsset";
import { useTexture } from "~/view-scene/texture";

export type RenderImageV2Props = {
  dto: IImageSceneObject;
  asset: IImageAsset;
};

export const RenderImageV2 = ({ dto, asset }: RenderImageV2Props) => {
  const url = asset.url;
  const texture = useTexture({
    name: asset.name,
    url,
    type: asset.isCompressed ? "compressedImage" : "image",
    flipY: true,
  });

  if (!texture) {
    return null;
  }

  // @ts-ignore
  return <Image texture={texture} scale={[dto.width, dto.height, 1]} />;
};
