import { useStore, useStoreMap } from "effector-react";
import { hasType } from "~/common/utils/hasType";
import { $allScriptsMap } from "~/entities/allScripts";
import { $entities } from "~/entities/entities";
import { ComponentType } from "~/types/ComponentType";
import IComponent from "~/types/IComponent";
import { IConstraintComponent } from "~/types/IConstraintComponent";
import IScriptComponent from "~/types/IScriptComponent";
import ITrajectoryComponent from "~/types/ITrajectoryComponent";
import {
  ModelAnimationComponent,
  SpriteAnimationComponent,
  TweenAnimationComponent,
} from "~/types/component/animation";
import { Select, SelectItem, SelectProps } from "./Select";

export type ComponentSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  entityId?: string | null;
  emptyLabel?: string;
  typeFilter?: ComponentType[];
};

export const ComponentSelect = ({
  value,
  entityId,
  emptyLabel = "Select modification",
  typeFilter,
  ...rest
}: ComponentSelectProps) => {
  const { getComponentLabel } = useUtils();

  const components = useStoreMap({
    store: $entities,
    keys: [entityId, typeFilter],
    fn: (entities, [entityId, typeFilter]) => {
      if (!entities || !entityId) {
        return null;
      }
      const entity = entities.find((entity) => entity.id === entityId);

      if (!entity) {
        return null;
      }

      return typeFilter
        ? entity.components.filter((component) => typeFilter.includes(component.type))
        : entity.components;
    },
  });

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {components?.map((component) => (
        <SelectItem key={component.id} value={component.id}>
          {getComponentLabel(component)}
        </SelectItem>
      ))}
    </Select>
  );
};

const useUtils = () => {
  const scriptsMap = useStore($allScriptsMap);

  return {
    getComponentLabel: (component: IComponent) => {
      if (
        hasType<IComponent, ModelAnimationComponent, ComponentType>(component, ComponentType.ANIMATION) ||
        hasType<IComponent, SpriteAnimationComponent, ComponentType>(component, ComponentType.SPRITE_ANIMATION) ||
        hasType<IComponent, TweenAnimationComponent, ComponentType>(component, ComponentType.TWEEN_ANIMATION)
      ) {
        return component.animationName ?? "Animation";
      }

      if (hasType<IComponent, ITrajectoryComponent, ComponentType>(component, ComponentType.TRAJECTORY)) {
        return component.trajectory.name;
      }

      if (hasType<IComponent, IConstraintComponent, ComponentType>(component, ComponentType.CONSTRAINT)) {
        return component.name ?? "Constraint";
      }

      if (
        hasType<IComponent, IScriptComponent, ComponentType>(component, ComponentType.SCRIPT) ||
        hasType<IComponent, IScriptComponent, ComponentType>(component, ComponentType.JS_SCRIPT)
      ) {
        return scriptsMap?.[component.assetId ?? ""]?.name ?? "Script";
      }

      return component.type;
    },
  };
};
