export class FiniteStateMachine<T> {
  private readonly states: { [key: string]: FSMState };
  private currentState: string;
  private _prevState?: string;
  private _data: T;

  constructor(states: { [key: string]: FSMState }, initialState: string, data: T) {
    this.states = states;
    this._data = data;
    this.currentState = initialState;
    // this.transition(initialState);
  }

  get state() {
    return this.currentState;
  }

  get prevState(): string | undefined {
    return this._prevState;
  }

  get data(): T {
    return this._data;
  }

  set data(value: T) {
    this._data = value;
  }

  transition(state: string) {
    const oldState = this.states[this.currentState];
    if (oldState) {
      oldState.exit?.call(this);
      this._prevState = this.currentState;
    }

    this.currentState = state;
    const newState = this.states[state];
    newState?.enter?.call(this);
  }

  update() {
    const state = this.states[this.currentState];
    state?.update?.call(this);
  }
}

export type FSMState = {
  enter?: VoidFunction;
  update?: VoidFunction;
  exit?: VoidFunction;
};
