import { Select, SelectItem, SelectProps } from "./Select";
import { useUnit } from "effector-react";
import { $storages } from "~/entities/storages";

export type StorageSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const StorageSelect = ({ emptyLabel = "Select storage", value, ...rest }: StorageSelectProps) => {
  const storages = useUnit($storages);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {storages?.map((storage) => (
        <SelectItem key={storage.id} value={storage.id}>
          {storage.name}
        </SelectItem>
      ))}
    </Select>
  );
};
