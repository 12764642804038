import EventEmitter from "eventemitter3";

export type ActionEvent<TAction> = {
  pressed: boolean;
  action: TAction;
  skipCheck?: boolean;
};

export interface BasicActionSource<TAction extends string = string> {
  on(event: "action", listener: (e: ActionEvent<TAction>) => void): this;
  on(event: "action", listener: (e: ActionEvent<TAction>) => void): this;

  off(event: "action", listener: (e: ActionEvent<TAction>) => void): this;
  off(event: "action", listener: (e: ActionEvent<TAction>) => void): this;

  emit(event: "action", e: ActionEvent<TAction>): boolean;
  emit(event: "action", e: ActionEvent<TAction>): boolean;
}

export class BasicActionSource<TAction extends string> extends EventEmitter {
  private actions = new Map<TAction, boolean>();

  protected emitAction(e: ActionEvent<TAction>) {
    if (e.skipCheck) {
      this.emit("action", e);
      return;
    }

    const alreadyPressed = this.actions.get(e.action) ?? false;

    // don't emit event many times on key hold
    if (alreadyPressed && e.pressed) {
      return;
    }

    this.actions.set(e.action, e.pressed);
    this.emit("action", e);
  }
}
