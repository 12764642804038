import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { defaultEntity } from "~/entities/variable";
import { EntityValue, Vector3Value } from "../../values";

export class CloneEntityNode extends BaseNode {
  static readonly type = "action/cloneEntity";
  static readonly label = "Clone Entity";

  async = true;

  inputs = [
    new FlowSocket("flow"),
    new EntitySocket("entity"),
    new EntitySocket("parentEntity"),
    new Vector3Socket("position"),
    new Vector3Socket("rotation"),
    new Vector3Socket("scale", "scale", { x: 1, y: 1, z: 1 }),
  ];
  outputs = [new FlowSocket("success"), new FlowSocket("fail"), new EntitySocket("newEntity")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = null;

    const entity = context.readInput<EntityValue>("entity");
    const parentEntity = context.readInput<EntityValue>("parentEntity");
    const position = context.readInput<Vector3Value>("position");
    const rotation = context.readInput<Vector3Value>("rotation");
    const scale = context.readInput<Vector3Value>("scale");

    if (!entity.entityId) {
      context.writeOutput<EntityValue>("newEntity", node.storage.output);
      context.commit("fail");
      return;
    }

    node.sceneContext
      .cloneEntity(entity.entityId, { position, rotation, scale, parentId: parentEntity.entityId ?? null })
      .then((newEntityId) => {
        if (newEntityId) {
          node.storage.output.entityId = newEntityId;
          context.writeOutput<EntityValue>("newEntity", node.storage.output);
          context.commit("success");
          context.graphEvaluator.executeAll();
        } else {
          context.writeOutput<EntityValue>("newEntity", node.storage.output);
          context.commit("fail");
          context.graphEvaluator.executeAll();
        }
      });
  }
}
