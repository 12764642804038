import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ComponentSocket, EntitySocket, FlowSocket } from "../../sockets";
import { ComponentValue, EntityValue } from "~/types/Variable";
import { FollowTargetSteeringComponent } from "~/types/component";

export class SteeringFollowTargetSetTargetNode extends BaseNode {
  static readonly type = "logic/steeringFollowTargetSetTarget";
  static readonly label = "Steering - Follow Target, Set Target";

  inputs = [new FlowSocket("flow"), new ComponentSocket("steeringFollowTarget"), new EntitySocket("target")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const steeringFollowTargetComponent = context.readInput<ComponentValue>("steeringFollowTarget");
    const target = context.readInput<EntityValue>("target");

    if (steeringFollowTargetComponent.entityId && steeringFollowTargetComponent.componentId) {
      node.sceneContext.updateComponent<FollowTargetSteeringComponent>(steeringFollowTargetComponent.entityId, {
        id: steeringFollowTargetComponent.componentId,
        targetEntityId: target.entityId,
      });
    }
  }
}
