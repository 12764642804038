import { Layers } from "three";

export enum Layer {
  default = 0,
  video360 = 1,
  cutscene = 2,
}

export const defaultLayers = new Layers();
defaultLayers.set(Layer.default);

export const video360Layers = new Layers();
video360Layers.set(Layer.video360);

export const cutsceneLayers = new Layers();
cutsceneLayers.set(Layer.cutscene);
