import { useLoader, useThree } from "@react-three/fiber";
import { memo, useEffect } from "react";
import { TextureLoader } from "three";
import { textureManager } from "../TextureManager";
import { ImageTextureRecord } from "../types";
import { useCommonTextureProperties } from "./useCommonTextureProperties";

type ImageTextureProps = {
  textureRecord: ImageTextureRecord;
};

export const ImageTexture = memo(({ textureRecord }: ImageTextureProps) => {
  const gl = useThree((state) => state.gl);

  const { id, url, tiling, encoding, flipY } = textureRecord;

  const isBase64 = url.startsWith("data:");
  const texture = useLoader(TextureLoader, isBase64 ? url : `${url}?id=${id}`);

  useCommonTextureProperties(texture, flipY, encoding, tiling);

  useEffect(() => {
    gl.initTexture(texture);
    const gpuMemoryInBytes = texture.image.width * texture.image.width * 4 * 1.33;
    textureManager.finishTextureLoad(id, texture, gpuMemoryInBytes);

    return () => {
      texture.dispose();
    };
  }, []);

  return null;
});
