import { ErrorPageLayout, ErrorPageTitle, TrySuggestedScenes } from "./components";
import { HOST } from "~/config";

export const ServerErrorPage = () => {
  return (
    <ErrorPageLayout>
      <ErrorPageTitle icon="/static/img/content/broken.webp" subtitle="Something broken" />
      {HOST === "8xr" && <TrySuggestedScenes />}
    </ErrorPageLayout>
  );
};
