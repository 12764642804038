import { Stack } from "@mui/material";
import { palette } from "~/theme";
import React, { useEffect, useRef, useState } from "react";
import { ALLOWED_ORIGINS } from "~/config";
import { setAccessToken } from "~/api/accessToken";
import { AuthFormState } from "~/pages/auth/types";
import { LocalAuthProvider } from "~/pages/auth/LocalAuthProvider";

export function ExternalAuthIFrame() {
  const [formState, setFormState] = useState<AuthFormState>("login");

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const contentRect = entry.contentRect;
        const message = {
          eventName: "containerSizeChanged",
          height: contentRect.height,
          width: contentRect.width,
        };
        ALLOWED_ORIGINS.forEach((origin) => window.parent.postMessage(message, origin));
      }
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSuccessfulLogin = async (accessToken: string) => {
    setAccessToken(accessToken);
    const message = {
      eventName: "loginSuccess",
      accessToken,
    };
    ALLOWED_ORIGINS.forEach((origin) => window.parent.postMessage(message, origin));
  };

  const handleSuccessfulRegistration = async (accessToken: string) => {
    setAccessToken(accessToken);
    const message = {
      eventName: "registrationSuccess",
      accessToken,
    };
    ALLOWED_ORIGINS.forEach((origin) => window.parent.postMessage(message, origin));
    setFormState("registrationSuccessful");
  };

  const handleFormStateChange = (formState: AuthFormState) => {
    const message = {
      eventName: "formStateChanged",
      formState,
    };
    ALLOWED_ORIGINS.forEach((origin) => window.parent.postMessage(message, origin));
    setFormState(formState);
  };

  return (
    <Stack bgcolor={palette.primary.black} ref={containerRef}>
      <LocalAuthProvider
        formState={formState}
        isOauthEnabled={false}
        onFormStateChange={handleFormStateChange}
        onLoginSuccess={handleSuccessfulLogin}
        onRegistrationSuccess={handleSuccessfulRegistration}
      />
    </Stack>
  );
}
