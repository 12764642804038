import { Action, userActions } from "./userActions";
import { BaseControls } from "./baseControls";

export class DebugControls extends BaseControls {
  console = new Action();

  unSubscriptions: (() => void)[] = [];

  activate() {
    this.unSubscriptions.push(this.pipeAction(userActions.console, this.console));
  }

  deactivate() {
    for (const unsubscribe of this.unSubscriptions) {
      unsubscribe();
    }
  }

  update() {}

  reset() {}
}

export const debugControls = new DebugControls();
