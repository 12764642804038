import ISceneObject from "types/ISceneObject";
import { useStoreMap } from "effector-react";
import { $entities } from "./models";

export const useEntity = <T extends ISceneObject>(entityId: string | null | undefined) => {
  const entity = useStoreMap({
    store: $entities,
    keys: [entityId],
    fn: (entities, [entityId]) => entities?.find(({ id }) => id === entityId) ?? null,
    updateFilter: (update, current) => !(current != null && update == null),
  });

  if (entity) {
    return entity as T;
  } else {
    return null;
  }
};
