import { ErrorPageLayout, ErrorPageTitle, TrySuggestedScenes } from "./components";
import { HOST } from "~/config";

export const NotFoundPage = () => {
  return (
    <ErrorPageLayout>
      <ErrorPageTitle icon="/static/img/content/smile-sadness.webp" subtitle="Can't find this page" />
      {HOST === "8xr" && <TrySuggestedScenes />}
    </ErrorPageLayout>
  );
};
