import { createEffect, createEvent, createStore } from "effector";
import { lockPointer } from "~/view-scene/PointerLocker";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { CutsceneItem } from "~/types/CutsceneSystem";
import { useSceneData } from "~/common/stores/useSceneData";
import { resetRuntime } from "../reset";

export const enterCutscene = createEffect(async (cutsceneId: string) => {
  const sceneState = useSceneData.getState().sceneState;
  if (!cutsceneId || !sceneState) {
    setCurrentCutscene(null);
    return;
  }

  const cutscene = sceneState.cutsceneSystem.items.find((e) => e.id === cutsceneId) ?? null;
  setCurrentCutscene((cutscene as CutsceneItem) || null);
});

export const exitCutscene = createEffect(async () => {
  const isDesktop = useSessionStatus.getState().mode === "desktop";

  if (isDesktop) {
    await lockPointer();
  }

  setCurrentCutscene(null);
});

const setCurrentCutscene = createEvent<CutsceneItem | null>();

export const $currentCutscene = createStore<CutsceneItem | null>(null)
  .on(setCurrentCutscene, (_, cutscene) => cutscene)
  .reset(resetRuntime);
