import { Icon, IconProps } from "./Icon";

export type PreloadProps = IconProps;

export const Preload = (props: PreloadProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      d="M5.33334 10.6667L8.00001 8M8.00001 8L10.6667 10.6667M8.00001 8V14M13.3333 11.1619C14.1477 10.4894 14.6667 9.47196 14.6667 8.33333C14.6667 6.30829 13.0251 4.66667 11 4.66667C10.8543 4.66667 10.718 4.59066 10.6441 4.46516C9.77472 2.98989 8.16963 2 6.33334 2C3.57192 2 1.33334 4.23858 1.33334 7C1.33334 8.3774 1.89031 9.62472 2.79131 10.529"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
