import { v4 as uuid } from "uuid";
import hotkeys, { KeyHandler } from "hotkeys-js";

import { BasicActionSource } from "../BasicActionSource";
import { KeyboardKeyCombination } from "./types";
import { disableNativeUndoRedoKey } from "./constants";

type ActionInfo<TAction> = {
  action: TAction;
  hasCommand: boolean;
};

export class KeyboardActionSource<TAction extends string> extends BasicActionSource<TAction> {
  private scope: string;
  private invertedKeysMap: Record<KeyboardKeyCombination, ActionInfo<TAction>>;

  constructor(readonly keysMap: Record<TAction, KeyboardKeyCombination[]>) {
    super();
    this.scope = uuid();
    this.invertedKeysMap = this.invert(keysMap);

    const keys = Object.values(keysMap).join(",");
    hotkeys(keys, { scope: this.scope, keyup: true }, this.keysHandler);
  }

  activate() {
    hotkeys.setScope(this.scope);
  }

  dispose() {
    hotkeys.deleteScope(this.scope);
  }

  getKeyCombination(action: TAction) {
    return this.keysMap[action];
  }

  private keysHandler: KeyHandler = (keyboardEvent, hotkeysEvent) => {
    const actionInfo = this.invertedKeysMap[hotkeysEvent.key as KeyboardKeyCombination];
    const keyDown = keyboardEvent.type === "keydown";

    if (actionInfo) {
      this.emitAction({ action: actionInfo.action, pressed: keyDown, skipCheck: actionInfo.hasCommand });

      return false;
    }
  };

  private invert = (keysMap: Record<TAction, KeyboardKeyCombination[]>) => {
    return (Object.keys(keysMap) as TAction[]).reduce((acc, action) => {
      const keys = keysMap[action];

      for (const key of keys) {
        acc[key] = {
          action,
          hasCommand: this.hasCommandKey(key),
        };
      }

      return acc;
    }, {} as Record<KeyboardKeyCombination, ActionInfo<TAction>>);
  };

  private hasCommandKey = (key: string) => {
    return key.indexOf("command") >= 0;
  };
}

hotkeys.filter = (event: KeyboardEvent) => {
  var target = (event.target || event.srcElement) as HTMLElement;
  var tagName = target.tagName;

  const isInput = target.isContentEditable || tagName === "INPUT" || tagName === "SELECT" || tagName === "TEXTAREA";

  if (isInput) {
    const disableNativeUndoRedo = target.dataset[disableNativeUndoRedoKey];

    if (disableNativeUndoRedo) {
      const isUndoRedo = (event.ctrlKey || event.metaKey) && event.key === "z";

      if (isUndoRedo) {
        event.preventDefault();

        return true;
      }
    }

    return false;
  }

  return true;
};
