import { disposeMaterial, useGLTF } from "~/view-scene/utils";
import { useMemo } from "react";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { FrontSide, Mesh, MeshStandardMaterial, Object3D } from "three";

const FAR_LOD_AVATAR_TEMPLATE = "/static/models/far_lod_avatar.glb";

const FAR_LOD_AVATAR_MATERIAL = new MeshStandardMaterial({
  color: "white",
  side: FrontSide,
  transparent: true,
  opacity: 0.5,
});

export const useFarLODAvatar = () => {
  const { scene: farLODTemplate } = useGLTF(FAR_LOD_AVATAR_TEMPLATE);

  return useMemo(() => {
    const farAvatarLOD = (SkeletonUtils as any).clone(farLODTemplate);
    farAvatarLOD.traverse((obj: Object3D) => {
      if (obj instanceof Mesh) {
        disposeMaterial(obj.material);
        obj.material = FAR_LOD_AVATAR_MATERIAL;
      }
    });
    return farAvatarLOD;
  }, []);
};
