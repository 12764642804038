import { useMemo, useState } from "react";
import { BackSide, BoxGeometry, Mesh, MeshStandardMaterial, PlaneGeometry, ShaderMaterial } from "three";
import { useTeleportPreview } from "./useTeleportPreview";
import ITeleportSceneObject from "~/types/ITeleportSceneObject";
import { shaders } from "./shaders";

const GOLDEN_RATIO = 1.61803398875;

export function useTeleportObject(dto: ITeleportSceneObject) {
  const previewTexture = useTeleportPreview(dto);

  const [beautyMaterial] = useState(
    new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        animNormTime: { value: 0 },
      },
      vertexShader: shaders.teleport_vert,
      fragmentShader: shaders.teleport_frag,
      toneMapped: false,
    })
  );

  const [hiddenGeometry] = useState(() => {
    const hiddenGeometry = new BoxGeometry(1, GOLDEN_RATIO, 0.05);
    hiddenGeometry.computeBoundsTree();
    hiddenGeometry.setAttribute("finalStatePosition", hiddenGeometry.attributes.position);
    return hiddenGeometry;
  });

  const [smGeometry] = useState(() => {
    const smGeometry = new BoxGeometry(1, 0, 0.05);
    smGeometry.computeBoundsTree();
    smGeometry.setAttribute("finalStatePosition", hiddenGeometry.attributes.position);
    return smGeometry;
  });

  const teleportObject = useMemo(() => {
    const teleportMesh = new Mesh(new BoxGeometry(), beautyMaterial);
    teleportMesh.geometry.computeBoundsTree();
    teleportMesh.scale.set(1, GOLDEN_RATIO, 0.05);

    const previewGeometry = new PlaneGeometry();
    previewGeometry.computeBoundsTree();
    const aspect = 1 / GOLDEN_RATIO;

    const imageAspect = previewTexture.image.width / previewTexture.image.height;
    if (aspect < imageAspect) {
      previewTexture.matrix.setUvTransform(0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5);
    } else {
      previewTexture.matrix.setUvTransform(0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5);
    }

    const frontMaterial = new MeshStandardMaterial({ map: previewTexture });
    const frontPreview = new Mesh(previewGeometry, frontMaterial);
    frontPreview.scale.set(0.9, 0.93, 0.9);
    frontPreview.position.set(0, 0, 0.55);
    teleportMesh.add(frontPreview);

    const backMaterial = new MeshStandardMaterial({ side: BackSide, map: previewTexture });
    const backPreview = new Mesh(previewGeometry, backMaterial);
    backPreview.scale.set(0.9, 0.93, 0.9);
    backPreview.position.set(0, 0, -0.55);
    teleportMesh.add(backPreview);

    return teleportMesh;
  }, [previewTexture, beautyMaterial]);

  return { teleportObject, beautyMaterial, smGeometry, hiddenGeometry };
}
