import { useAsset } from "~/entities/assets";
import {
  IBasicMaterial,
  IMaterial,
  IMaterialAsset,
  IPhongMaterial,
  IShaderMaterial,
  IStandardMaterial,
  MaterialType,
} from "~/types/material";
import { hasType } from "~/common/utils/hasType";
import { MaterialRecord } from "../types";
import { StandardMaterialAsset } from "./StandardMaterialAsset";
import { BasicMaterialAsset } from "./BasicMaterialAsset";
import { ShaderMaterialAsset } from "./ShaderMaterialAsset";
import { PhongMaterialAsset } from "./PhongMaterialAsset";
import { memo } from "react";

type MaterialAssetProps = {
  materialRecord: MaterialRecord;
};

export const MaterialAsset = memo(({ materialRecord }: MaterialAssetProps) => {
  const materialAsset = useAsset<IMaterialAsset>(materialRecord.id);
  const material = materialAsset?.material;

  if (hasType<IMaterial, IStandardMaterial, MaterialType>(material, MaterialType.STANDARD)) {
    return <StandardMaterialAsset materialRecord={materialRecord} material={material} />;
  } else if (hasType<IMaterial, IBasicMaterial, MaterialType>(material, MaterialType.BASIC)) {
    return <BasicMaterialAsset materialRecord={materialRecord} material={material} />;
  } else if (hasType<IMaterial, IShaderMaterial, MaterialType>(material, MaterialType.SHADER)) {
    return <ShaderMaterialAsset materialRecord={materialRecord} material={material} />;
  } else if (hasType<IMaterial, IPhongMaterial, MaterialType>(material, MaterialType.PHONG)) {
    return <PhongMaterialAsset materialRecord={materialRecord} material={material} />;
  }

  return null;
});
