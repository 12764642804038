import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { SpriteContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, EntitySocket } from "../../sockets";
import { Box3 } from "three";

const box1 = new Box3();
const box2 = new Box3();

export class SpriteCollisionDetectorNode extends BaseNode {
  static readonly type = "logic/spriteCollisionDetector";
  static readonly label = "Detect Sprites Collisions";
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";

  inputs = [new EntitySocket("sprite1"), new EntitySocket("sprite2")];
  outputs = [new BooleanSocket("collided")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const sprite1Context = node.readEntity<SpriteContext>(context, "sprite1");
    const sprite2Context = node.readEntity<SpriteContext>(context, "sprite2");

    if (sprite1Context?.sprite && sprite2Context?.sprite) {
      box1.setFromObject(sprite1Context.sprite);
      box2.setFromObject(sprite2Context.sprite);
      if (box1.max.x > box2.min.x && box1.min.x < box2.max.x && box1.max.y > box2.min.y && box1.min.y < box2.max.y) {
        context.writeOutput<boolean>("collided", true);
      } else {
        context.writeOutput<boolean>("collided", false);
      }
    } else {
      context.writeOutput<boolean>("collided", false);
    }
  }
}
