import { IStructAsset } from "~/types/IStructAsset";
import { JSScriptAsset } from "~/types/JSScriptAsset";
import { NodeSpec, ScriptAsset } from "~/types/ScriptAsset";
import { BaseNode, NodeContext } from "../../base";
import { variableToSocket } from "../../utils";
import { getScriptVariables } from "~/entities/allScripts";

export abstract class ScriptVariableBaseNode extends BaseNode {
  protected structsMap: Record<string, IStructAsset>;
  protected scriptsMap: Record<string, ScriptAsset | JSScriptAsset>;

  constructor(context: NodeContext) {
    super(context);
    this.structsMap = context.structsMap;
    this.scriptsMap = context.scriptsMap;
  }

  protected getVariable(nodeSpec: NodeSpec) {
    const scriptId = nodeSpec.metadata?.scriptId;
    const variableId = nodeSpec.metadata?.variableId;
    const script = this.scriptsMap[scriptId ?? ""];
    const variable = getScriptVariables(script).find((p) => p.id === variableId);

    return variable ?? null;
  }

  protected getVariableSocket(nodeSpec: NodeSpec) {
    const variable = this.getVariable(nodeSpec);

    return variable ? variableToSocket(variable) : null;
  }
}
