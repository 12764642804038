import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { Progress, TelegramPattern } from "~/common/components";
import { palette } from "~/theme";
import { useEffect, useMemo } from "react";
import { $progress, setProgress } from "./models";
import { useUnit } from "effector-react";
import { gameById } from "~/common/data/games";

type LoadingScreenWithAdProps = {
  gameId: string;
};

export const LoadingScreenWithAd = ({ gameId }: LoadingScreenWithAdProps) => {
  const progress = useUnit($progress);
  const game = useMemo(() => gameById(gameId), [gameId]);

  useEffect(() => {
    const updateProgress = () => {
      if ($progress.getState() < 100) {
        setProgress($progress.getState() + 2);
        setTimeout(updateProgress, 120);
      }
    };

    setTimeout(updateProgress, 120);
  }, []);

  return (
    <RootContainer>
      <TopBlock>
        <GameIcon
          sx={{
            backgroundImage: `url("${game?.posterSmall}");`,
          }}
        />
        <ProgressContainer sx={{ marginTop: "23.35px" }}>
          <StyledProgress progress={progress} />
          <LoadingLabel sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            Loading...
          </LoadingLabel>
        </ProgressContainer>
      </TopBlock>
      <CenterBlock>
        <AdContainer sx={{ marginTop: "10px" }}>
          <AdLabel textAlign="center">Sponsor of this game</AdLabel>
          <AdHeader textAlign="center">Games</AdHeader>
          <AdContent textAlign="center">Play games with friends 🎮</AdContent>
          <JoinChannelButton
            variant="contained"
            onClick={() => window.Telegram?.WebApp.openLink("https://t.me/+QjIJXmcJVKJhMWQy")}
          >
            Join Channel
          </JoinChannelButton>
        </AdContainer>
        <AdDisclaimer textAlign="center">Advertising</AdDisclaimer>
      </CenterBlock>
      <BottomBlock>
        <LogoContainer>
          <img src="/static/img/content/logo-8xr-labeled-new.svg" alt="8XR logo" />
        </LogoContainer>
      </BottomBlock>
    </RootContainer>
  );
};

const RootContainer = styled(TelegramPattern)`
  background-color: #010101;
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(194.04deg, #53beb8 0%, #65a8e9 100%);
`;

const TopBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10vh;
`;

const GameIcon = styled(Box)`
  width: 148px;
  height: 148px;
  background-size: contain;
  border-radius: 20px;
`;

const ProgressContainer = styled(Box)`
  position: relative;
  width: 243px;
  height: 24px;
`;

const StyledProgress = styled(Progress)`
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border: 1px solid ${palette.primary.gray200};
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);

  & > .MuiBox-root {
    background: ${palette.shardQuest.blue};
  }
`;

export const LoadingLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.36px;
`;

const CenterBlock = styled(Stack)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const AdContainer = styled(Stack)`
  box-sizing: border-box;
  width: 335px;
  padding: 20px;
  flex-direction: column;
  gap: 12px;
  background: ${palette.primary.black100};
  border: 1px solid ${palette.primary.gray100};
  border-radius: 40px;
`;

export const AdLabel = styled(Typography)`
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  opacity: 0.6;
  font-family: Inter, serif;
  color: ${palette.primary.white};
`;

export const AdHeader = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  font-family: Inter, serif;
  color: ${palette.primary.white};
`;

export const AdContent = styled(Typography)`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: Inter, serif;
  color: ${palette.primary.white};
`;

const JoinChannelButton = styled(Button)`
  margin-top: 16px;
  width: 295px;
  height: 60px;
  border-radius: 34px;
  background: #007efb;
  color: #fff;
  font-family: Inter, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  box-shadow: none;
`;

export const AdDisclaimer = styled(Typography)`
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  opacity: 0.6;
  font-family: Inter, serif;
  color: ${palette.primary.white};
`;

const BottomBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled(Box)`
  height: 80px;
  display: flex;
  align-self: stretch;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgba(255, 255, 255, 0.6);
  border-top: 1px solid ${palette.primary.black200};
  backdrop-filter: blur(4px);
  align-items: center;
  justify-content: center;
`;
