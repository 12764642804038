import { useFrame } from "@react-three/fiber";
import { ReactNode, useRef } from "react";
import { Group } from "three";
import ITriggerZoneSceneObject from "~/types/ITriggerZoneSceneObject";
import { usePlayer } from "~/view-scene/player";
import { useEntityManager } from "~/view-scene/runtime";
import { useEntity } from "~/view-scene/utils/useEntity";
import { getDistance } from "../utils/getDistance";
import { Entity } from "./Entity";

type TriggerZoneEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export default function TriggerZoneEntity({ entityId, children }: TriggerZoneEntityProps) {
  const entity = useEntity<ITriggerZoneSceneObject>(entityId);

  const player = usePlayer();

  const getEntityContext = useEntityManager((state) => state.getEntityContext);

  const triggerZoneRef = useRef<Group>(null);

  useFrame(() => {
    const { triggeredWhen, triggeredBy, radius, sceneObjectId } = entity;

    let targetObject;
    if (triggeredBy === "player") {
      targetObject = player;
    } else if (sceneObjectId) {
      const target = getEntityContext(sceneObjectId);
      targetObject = target?.current?.rootObjectRef?.current;
    } else {
      return;
    }

    if (!targetObject) {
      return;
    }

    const triggerZoneObject = triggerZoneRef.current;
    if (!triggerZoneObject) {
      return;
    }

    const distance = getDistance(triggerZoneObject, targetObject);

    // TODO, change this ugly solution
    if (triggeredWhen === "insideZone") {
      entity.triggered = distance <= radius;
    } else if (triggeredWhen === "outsideZone") {
      entity.triggered = distance > radius;
    } else {
      entity.triggered = false;
    }
  });

  return (
    <Entity entityId={entityId} ref={triggerZoneRef}>
      {children}
    </Entity>
  );
}
