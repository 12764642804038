import {MutableRefObject, useEffect, useState} from "react";

export function useOnScreen(ref: MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    {rootMargin: '-50% 0% -50% 0%'}
  );

  useEffect(() => {
    observer.observe(ref.current);

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    }
  }, []);

  return isIntersecting;
}