import { Button, FormControl, Stack } from "@mui/material";
import React, { useState } from "react";
import { Title } from "./components/Title";
import { InputGroup, InputLabel, ManageAccessContainer, SectionName, StyledTextField } from "./components";
import { createWorkspaceFx, setActiveLayout } from "../models";
import { WorkspaceUser } from "~/api/types";
import { useProfile } from "~/entities/profile";
import { getUser } from "~/api/user.api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ManageProjectMembers } from "~/common/components";

const validationSchema = Yup.object({
  name: Yup.string().min(3).required("Name is required"),
  description: Yup.string().optional(),
});

export function CreateWorkspace() {
  const profile = useProfile((state) => state.profile!);

  const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUser[]>([
    {
      id: profile.id ?? "",
      email: profile.email ?? "",
      name: profile.name ?? "",
      avatarPreviewUrl: profile.avatarPreview ?? "",
    },
  ]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newSpace = {
        ...values,
        users: workspaceUsers.map((u) => u.id),
      };
      await createWorkspaceFx(newSpace);
      formik.resetForm();
    },
  });

  const handleUserAdd = async (email: string) => {
    const { status, body } = await getUser(email);
    if (status === 200) {
      const userId = body.id;
      if (workspaceUsers.some((u) => u.id === userId)) {
        return { success: false, errorMessage: "User already belongs to this Project" };
      }
      setWorkspaceUsers([...workspaceUsers, body]);
      return { success: true };
    } else {
      return { success: false, errorMessage: body.message };
    }
  };

  const handleUserDelete = async (email: string) => {
    const user = workspaceUsers.find((u) => u.email === email);
    if (user) {
      setWorkspaceUsers(workspaceUsers.filter((u) => u.id !== user.id));
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    setWorkspaceUsers([
      {
        id: profile.id ?? "",
        email: profile.email ?? "",
        name: profile.name ?? "",
        avatarPreviewUrl: profile.avatarPreview ?? "",
      },
    ]);
    setActiveLayout("myScenes");
  };

  return (
    <>
      <Title title="Project Creation" />
      <Stack gap="16px">
        <InputGroup>
          <InputLabel>Project Title</InputLabel>
          <FormControl error={formik.touched.name && Boolean(formik.errors.name)}>
            <StyledTextField
              placeholder="Enter Space Title"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
        </InputGroup>
        <InputGroup>
          <InputLabel>Project Description</InputLabel>
          <FormControl error={formik.touched.description && Boolean(formik.errors.description)}>
            <StyledTextField
              placeholder="Enter description for your project"
              multiline
              name="description"
              minRows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </FormControl>
        </InputGroup>
      </Stack>
      <Stack gap="16px">
        <SectionName>Members</SectionName>
        <ManageAccessContainer>
          <ManageProjectMembers members={workspaceUsers} onUserAdd={handleUserAdd} onUserDelete={handleUserDelete} />
        </ManageAccessContainer>
      </Stack>
      <Stack gap="12px" direction="row">
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="accent" fullWidth onClick={formik.submitForm} disabled={!formik.isValid}>
          Create Project
        </Button>
      </Stack>
    </>
  );
}
