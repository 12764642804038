import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type TextValue = EntityValue;

export const textValue = new ValueType(
  "text",
  defaultEntity,
  (text: TextValue) => text,
  (value: TextValue) => value
);
