import { Stack, styled, CircularProgress } from "@mui/material";
import { palette } from "~/theme";

export const PageLoader = () => (
  <Container alignItems="center" justifyContent="center">
    <CircularProgress size={100} sx={{ color: palette.primary.gray600 }} />
  </Container>
);

const Container = styled(Stack)`
  width: 100vw;
  height: 100vh;
`;
