import { NodeEvalContext } from "~/libs/behave-graph";
import { getDefaultValue } from "~/entities/variable";
import { StorageBaseNode } from "./StorageBaseNode";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { NodeSpec } from "~/types/ScriptAsset";

export class StorageGetNode extends StorageBaseNode {
  static type = "variable/storageGet";
  static label = "Storage Get";

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const values = node.sceneContext.getUserStorageData(node.storage.storage.id) ?? {};

    for (const field of node.storage.storage.fields) {
      const value = values[field.id] ?? field.initialValue ?? getDefaultValue(field.type);

      context.writeOutput(field.id, value);
    }
  }

  getOutputs(nodeSpec: NodeSpec): BaseSocket[] {
    return this.getStorageSockets(nodeSpec);
  }
}
