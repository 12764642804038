import { combine, createEffect, forward } from "effector";
import { $assets, $assetsError, loadAssetsFx } from "~/entities/assets";
import { loadStoragesFx } from "~/entities/storages";
import {
  $sceneData,
  $sceneDataError,
  $sceneHierarchy,
  $scenes,
  $scenesError,
  loadSceneDataFx,
  loadScenesFx,
} from "~/entities/sceneData";
import { $uiSettings, loadUISettingsFx } from "~/hephaestus/builderUIState";
import { $asyncProcesses, loadAsyncProcessesFx } from "~/entities/asyncProcesses";
import { $builderOnlineUsers, loadBuilderOnlineUsersFx } from "~/entities/builderOnlineUsers";
import { $workspaces, $workspacesError, loadWorkspacesFx } from "~/entities/workspace";
import { connected } from "~/api/socket";

export const loadDataFx = createEffect(async (sceneId: string) => {
  const sceneData = await loadSceneDataFx(sceneId);
  await Promise.all([loadAssetsFx(sceneData.workspace._id), loadStoragesFx(sceneData.workspace._id)]);

  await Promise.all([
    loadScenesFx(),
    loadUISettingsFx(sceneData._id),
    loadAsyncProcessesFx(sceneData._id),
    loadBuilderOnlineUsersFx(sceneData._id),
    loadWorkspacesFx(),
  ]);
});

forward({ from: connected, to: loadDataFx });

export const $isDataReady = combine(
  $assets,
  $sceneData,
  $sceneHierarchy,
  $scenes,
  $builderOnlineUsers,
  $asyncProcesses,
  $workspaces,
  $uiSettings,
  (assets, sceneData, sceneHierarchy, scenes, builderOnlineUsers, asyncProcesses, workspaces, uiSettings) =>
    assets && sceneData && sceneHierarchy && scenes && builderOnlineUsers && asyncProcesses && workspaces && uiSettings
);

export const $hasDataLoadingErrors = combine(
  $sceneDataError,
  $assetsError,
  $scenesError,
  $workspacesError,
  (sceneDataError, assetsError, scenesError, workspacesError) =>
    sceneDataError != null || assetsError != null || scenesError != null || workspacesError != null
);
