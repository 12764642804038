import { NodeSpec } from "~/types/ScriptAsset";

const getterFactory =
  <T = string>(key: string) =>
  (spec: NodeSpec) => {
    return (spec.metadata?.[key] as any as T | undefined) ?? null;
  };

const setterFactory =
  <T = string>(key: string) =>
  (spec: NodeSpec, value: T) => {
    spec.metadata ? (spec.metadata[key] = value) : ((spec.metadata as any) = { [key]: value });
  };

export const getScriptId = getterFactory("scriptId");
export const setScriptId = setterFactory("scriptId");

export const getEventId = getterFactory("eventId");
export const setEventId = setterFactory("eventId");

export const getMethodId = getterFactory("methodId");
export const setMethodId = setterFactory("methodId");

export const getComponentId = getterFactory("componentId");
export const setComponentId = setterFactory("componentId");

export const getAssetId = getterFactory("assetId");
export const setAssetId = setterFactory("assetId");

export const getVariantId = getterFactory("variantId");
export const setVariantId = setterFactory("variantId");
