import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";
import { StackProps } from "@mui/system";

type PanelItemContainerProps = StackProps & {
  isActive?: boolean;
};

export const PanelItemContainer = styled(({ isActive, sx, ...rest }: PanelItemContainerProps) => (
  <Stack
    sx={{
      ...(isActive && {
        background: palette.primary.violet600,
        color: palette.primary.white,
      }),
      ...sx,
    }}
    {...rest}
  />
))`
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  margin: 0 4px 0 4px;
  height: 16px;
  color: ${palette.primary.gray600};
  border: 1px solid transparent;
  gap: 6px;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background: transparent;
    border: 1px solid ${palette.primary.violet};
  }
`;
