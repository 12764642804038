import { memo } from "react";
import { EnabledStatus } from "~/types/EnabledStatus";
import { IPlayerSceneObject } from "~/types/IPlayerSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Player } from "./Player";
import { playerId } from "./constants";

export const PlayerEntity = memo(() => {
  const entity = useEntity<IPlayerSceneObject>(playerId);

  return entity && entity.enabled === EnabledStatus.enabled ? <Player entity={entity} /> : null;
});
