import { OrthographicCamera } from "@react-three/drei";
import { forwardRef } from "react";
import { Camera as ThreeCamera } from "three";
import { OrthographicCamera as OrthographicCameraType } from "~/types/ICameraSceneObject";
import { useUnit } from "effector-react";
import { $zoomFactor } from "~/view-scene/CameraSystem";

export type RenderOrthographicCameraProps = {
  default: boolean;
  camera: OrthographicCameraType;
};

const userData = {
  customCamera: true,
};

export const RenderOrthographicCamera = forwardRef<ThreeCamera, RenderOrthographicCameraProps>(
  ({ camera, default: isDefault }, ref) => {
    const zoomFactor = useUnit($zoomFactor);

    return (
      <OrthographicCamera
        ref={ref}
        far={camera.far}
        near={camera.near}
        zoom={camera.zoom * zoomFactor}
        userData={userData}
        makeDefault={isDefault}
      />
    );
  }
);
