import { Interactive } from "@react-three/xr";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Euler, Vector3 } from "three";
import { useThree } from "@react-three/fiber";
import { calcPositionInfront } from "~/common/utils/calcPositionInfront";
import { LeaveButton } from "./LeaveButton";
import { Layer, cutsceneLayers } from "~/view-scene/layers";

type ControlsTransform = {
  position: Vector3;
  rotation: Euler;
};

export const CutsceneInterface = memo(() => {
  const [controlsTransform, setControlsTransform] = useState<ControlsTransform | null>(null);
  const controlsTransformRef = useRef(controlsTransform);
  controlsTransformRef.current = controlsTransform;
  const inControlsZone = useRef(false);
  const camera = useThree((state) => state.camera);
  const cameraRef = useRef(camera);
  cameraRef.current = camera;

  const handleContainerHover = useCallback(() => {
    inControlsZone.current = true;
  }, []);

  const handleContainerBlur = useCallback(() => {
    inControlsZone.current = false;
  }, []);

  useEffect(() => {
    if (!controlsTransformRef.current) {
      const position = calcPositionInfront(cameraRef.current, 1);
      const rotation = cameraRef.current.rotation.clone();

      setControlsTransform({
        position,
        rotation,
      });
    } else {
      if (!inControlsZone.current) {
        setControlsTransform(null);
      }
    }
  }, []);

  return controlsTransform ? (
    <Interactive onHover={handleContainerHover} onBlur={handleContainerBlur}>
      <group layers={cutsceneLayers} position={controlsTransform.position} rotation={controlsTransform.rotation}>
        <LeaveButton position={leaveButtonPosition} width={leaveButtonWidth} layer={Layer.cutscene} />
      </group>
    </Interactive>
  ) : null;
});

const containerWidth = 1;
const leaveButtonWidth = containerWidth * 0.1;
const leaveLeftMargin = containerWidth * 0.02;
const leaveButtonPosition = new Vector3(containerWidth / 2 + leaveLeftMargin + leaveButtonWidth / 2, 0, 0);
