import { createEvent } from "effector";
import { Storage } from "~/types/Storage";
import { $storages } from "./storages";

export const addStorage = createEvent<Storage>();

$storages.on(addStorage, (storages, newStorage) => {
  if (!storages) {
    return;
  }

  return [...storages, newStorage];
});
