import { forwardRef, ReactNode } from "react";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "~/theme";
import { PanelLogo } from "./PanelLogo";
import { CollapsedPanelLogo } from "./CollapsedPanelLogo";
import { PanelHeader } from "./PanelHeader";
import { ButtonRight } from "~/common/components/Icon";
import { IconButton } from "../IconButton";
import { ToolbarGroup } from "../ToolbarGroup";

export type PanelProps = Pick<BoxProps, "sx" | "className" | "children"> & {
  open: boolean;
  showLogo?: boolean;
  headerContent?: ReactNode;
  toggable?: boolean;
  onToggle?: (flag?: boolean) => void;
};

export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  (
    // prettier-ignore
    {
      sx,
      className,
      open,
      showLogo = false,
      headerContent = <PanelHeader />,
      toggable = false,
      children,
      onToggle
    },
    ref
  ) => {
    return (
      <Box
        component="div"
        ref={ref}
        className={className}
        sx={{
          display: "flex",
          flex: 1,
          width: "100%",
          minWidth: "240px",
          maxWidth: "240px",
          flexDirection: "column",
          zIndex: 10,
          height: "calc(100vh - 16px)",
          background: palette.primary.gradient300,
          borderRadius: "12px",
          ...(!open && {
            width: showLogo ? "102px" : "38px",
            minWidth: showLogo ? "102px" : "38px",
            maxWidth: showLogo ? "102px" : "38px",
            background: "transparent",
            height: "38px",
            img: {
              opacity: 1,
            },
          }),
          ...sx,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            gridGap: "8px",
            padding: "10px",
            borderBottom: "1px solid " + palette.primary.gray40,
            ...(!open && {
              justifyContent: "center",
              margin: 0,
              padding: 0,
              border: "none",
            }),
          }}
        >
          <>
            {!open && (
              <>
                {showLogo && <CollapsedPanelLogo />}
                <GrayToolbarGroup>
                  <IconButton onClick={() => onToggle?.()}>
                    <ButtonRight />
                  </IconButton>
                </GrayToolbarGroup>
              </>
            )}

            {open && (
              <>
                {showLogo && <PanelLogo />}
                {headerContent}
                {toggable && (
                  <IconButton onClick={() => onToggle?.()}>
                    <ButtonRight />
                  </IconButton>
                )}
              </>
            )}
          </>
        </Stack>
        {open && (
          <Box
            component="div"
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              gridGap: "4px",
              alignItems: "stretch",
              height: "100%",
              paddingBottom: "20px",
              overflowY: "auto",
              overflowX: "hidden",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                width: "1px",
              },
              "&::-webkit-scrollbar-track": {
                background: palette.primary.gray200,
              },
              "&::-webkit-scrollbar-thumb": {
                background: palette.primary.gray600,
              },
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    );
  }
);

const GrayToolbarGroup = styled(ToolbarGroup)`
  background: ${palette.primary.gradient400};
`;
