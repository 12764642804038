import { styled } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { Modal } from "./Modal";
import { ProgressLoader } from "./ProgressLoader";
import { HyperModalConfig, hyperModalBridge } from "./hyperModalBridge";

export const HyperModal = () => {
  const [config, setConfig] = useState<HyperModalConfig | null>(null);

  useEffect(() => {
    const openHandler = (config: HyperModalConfig) => {
      setConfig(config);
    };

    const closeHandler = () => {
      setConfig(null);
    };

    hyperModalBridge.on("open", openHandler);
    hyperModalBridge.on("close", closeHandler);

    return () => {
      hyperModalBridge.off("open", openHandler);
      hyperModalBridge.off("close", closeHandler);
    };
  }, []);

  if (!config) {
    return null;
  }

  return (
    <Container>
      <Suspense fallback={<ProgressLoader />}>
        <Modal {...config} />
      </Suspense>
    </Container>
  );
};

const Container = styled("div")`
  position: fixed;
  width: 100vw;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
`;
