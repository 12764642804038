import { styled } from "@mui/material";
import { useMemo } from "react";
import { parseText, Item } from "./textParser";

const icons: Record<string, string> = {
  telegramInvite: "/static/img/content/hyper/telegramInviteIcon.png",
  copy: "/static/img/content/hyper/copyIcon.png",
  coin: "/static/img/content/hyper/coinIcon.png",
  arrow: "/static/img/content/hyper/arrowIcon.png",
  energy: "/static/img/content/hyper/energyIcon.png",
};

type TextProps = {
  text: string;
};

export const Text = ({ text }: TextProps) => {
  const items = useMemo(() => parseText(text), [text]);

  return (
    <>
      {items.map((item, i) => (
        <TextItem key={i} item={item} />
      ))}
    </>
  );
};

export type TextItemProps = {
  item: Item;
};

export const TextItem = ({ item }: TextItemProps) => {
  if (item.type === "text") {
    return <TextContainer dangerouslySetInnerHTML={{ __html: item.value }} />;
  }

  if (item.type === "icon") {
    return <Icon src={icons[item.value]} />;
  }

  return null;
};

const TextContainer = styled("span")`
  & a {
    font-family: "Noto Sans", sans-serif;
    color: #10d4ff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`;

const Icon = styled("img")`
  display: inline-block;
`;
