import { CatmullRomCurve3, Vector3 } from "three";
import ISegment from "~/types/ISegment";

export type Path = NonNullable<ReturnType<typeof segmentToPath>>;

export const segmentToPath = (segment: ISegment) => {
  const vectorizedPath = segment.path.map((path) => {
    const point = path.point;
    return new Vector3(point.x, point.y, point.z);
  });

  if (vectorizedPath.length === 0) {
    return null;
  } else if (vectorizedPath.length === 1) {
    vectorizedPath.push(vectorizedPath[0]);
  }

  return {
    glued: segment.glued,
    rotate: segment.rotate,
    delay: segment.delay,
    speed: segment.speed,
    route: new CatmullRomCurve3(vectorizedPath),
  };
};
