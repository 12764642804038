import { BaseAdsProvider } from "./BaseAdsProvider";

export class DevAdsProvider extends BaseAdsProvider {
  async init() {}

  start() {
    this.isPlaying = true;
    setTimeout(() => {
      this.isPlaying = false;
      this.emit("finished", true);
    }, 1000);
  }

  async dispose() {}

  async hasAds() {
    return true;
  }
}
