import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import type { EventCallPayload } from "../../ScriptEvents";
import { BaseBehaveNode } from "../../base";
import { FlowSocket } from "../../sockets";
import { CustomEventBaseNode } from "./CustomEventBaseNode";
import { getEventId } from "../../utils";

export class CustomEventNode extends CustomEventBaseNode {
  static type = "event/exec";
  static label = "Event";
  static category: NodeCategory = "Event";

  static utility = true;

  outputs = [new FlowSocket("flow")];

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  initNode(node: BaseBehaveNode) {
    node.storage.eventId = getEventId(node.spec) ?? "";
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onCallHandler = (payload: EventCallPayload) => {
      if (payload.id !== node.storage.eventId) {
        return;
      }

      Object.entries(payload.values).forEach(([id, value]) => {
        context.writeOutput(id, value);
      });

      context.commit("flow");
    };

    node.scriptContext.scriptEvents.eventCall.addListener(onCallHandler);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.eventCall.removeListener(onCallHandler);
    });
  }

  getOutputs(nodeSpec: NodeSpec) {
    return [...super.getOutputs(nodeSpec), ...this.getEventInputsSockets(nodeSpec)];
  }

  getLabel(nodeSpec: NodeSpec) {
    const event = this.getEvent(nodeSpec);

    return event?.name ?? "Event";
  }
}
