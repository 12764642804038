import { NodeContext } from "../../base";
import { AssetImageSocket } from "../../sockets";
import { AssetNode } from "./AssetNode";

export class AssetImageNode extends AssetNode {
  static readonly type = "variable/assetImage";
  static readonly label = "Image Asset";

  constructor(context: NodeContext) {
    super(context, new AssetImageSocket("image"));
  }
}
