import { NodeEvalContext } from "~/libs/behave-graph";
import { TrajectoryComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, TrajectorySocket } from "../../sockets";

export class TrajectoryPlayNode extends BaseNode {
  static readonly type = "action/trajectoryPlay";
  static readonly label = "Trajectory Play";

  async = true;

  inputs = [new FlowSocket("flow"), new TrajectorySocket("trajectory")];
  outputs = [new FlowSocket("flow"), new FlowSocket("onFinish")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const trajectoryComponent = node.readComponent<TrajectoryComponentContext>(context, "trajectory");
    trajectoryComponent?.play();
    context.commit("flow");

    if (trajectoryComponent && node.outputConnected("onFinish")) {
      trajectoryComponent.events.once("finish", () => {
        context.commit("onFinish");
        context.graphEvaluator.executeAll();
      });
    }
  }
}
