import { createEvent, createStore } from "effector";
import { resetRuntime } from "../reset";

export type EntitiesState = Record<string, any>;
export type EntityDescriptor = {
  id: string;
  entity: any;
};

type AddEntitiesPayload = {
  entities: EntityDescriptor[];
  override?: boolean;
};

type AddEntityPayload = {
  entity: EntityDescriptor;
  override?: boolean;
};

export const updateEntityState = createEvent<{ id: string; update: any }>();
export const init = createEvent<EntitiesState>();
export const addEntities = createEvent<AddEntitiesPayload>();
export const addEntity = addEntities.prepend((payload: AddEntityPayload) => {
  return {
    entities: [payload.entity],
    override: payload.override,
  };
});
export const removeEntities = createEvent<string[]>();
export const removeEntity = removeEntities.prepend((id: string) => [id]);

export const $entitiesState = createStore({} as EntitiesState)
  .on(init, (_, payload) => payload)
  .on(updateEntityState, (state, { id, update }) => {
    if (!state[id]) {
      return;
    }

    return {
      ...state,
      [id]: {
        ...state[id],
        ...update,
      },
    };
  })
  .on(addEntities, (state, { entities, override = true }) => {
    const newState = { ...state };

    entities.forEach(({ id, entity }) => {
      if (!override && newState[id]) {
        return;
      }

      newState[id] = entity;
    });

    return newState;
  })
  .on(removeEntities, (state, payload) => {
    const newState = { ...state };

    payload.forEach((id) => {
      delete newState[id];
    });

    return newState;
  })
  .reset(resetRuntime);
