import { BoxGeometry } from "~/types/Geometry";
import { LabelGroup, SliderInput } from "~/hephaestus/components";
import { geometryChange } from "./utils";
import { GeometryInspectorBaseProps } from "./types";

export type BoxGeometryInspectorProps = GeometryInspectorBaseProps<BoxGeometry>;

export const BoxGeometryInspector = ({ geometry, onChange }: BoxGeometryInspectorProps) => {
  const { width, height, depth, widthSegments, heightSegments, depthSegments } = geometry;

  return (
    <>
      <LabelGroup label="Width">
        <SliderInput
          name="width"
          min={0}
          max={1000}
          step={0.5}
          value={width}
          onChange={geometryChange("width", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Height">
        <SliderInput
          name="height"
          min={0}
          max={1000}
          step={0.5}
          value={height}
          onChange={geometryChange("height", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Depth">
        <SliderInput
          name="depth"
          min={0}
          max={1000}
          step={0.5}
          value={depth}
          onChange={geometryChange("depth", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Width Segments">
        <SliderInput
          name="widthSegments"
          min={0}
          max={100}
          step={1}
          value={widthSegments}
          onChange={geometryChange("widthSegments", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Height Segments">
        <SliderInput
          name="heightSegments"
          min={0}
          max={100}
          step={1}
          value={heightSegments}
          onChange={geometryChange("heightSegments", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Depth Segments">
        <SliderInput
          name="depthSegments"
          min={0}
          max={100}
          step={1}
          value={depthSegments}
          onChange={geometryChange("depthSegments", geometry, onChange)}
        />
      </LabelGroup>
    </>
  );
};
