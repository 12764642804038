import { defaultAsset } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { TextContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket, TextSocket } from "../../sockets";

export class TextSetContentNode extends BaseNode {
  static readonly type = "action/textSetContent";
  static readonly label = "Set content";

  inputs = [new FlowSocket("flow"), new TextSocket("text"), new StringSocket("content")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const text = node.readEntity<TextContext>(context, "text");
    const content = context.readInput<string>("content");

    text?.setContent(content);
  }
}
