import { defaultString } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { TextUIEntity } from "~/types/ui";
import { UITextContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { StringSocket, UITextSocket } from "../../sockets";
import { UITextValue } from "../../values";

export class UITextContentNode extends BaseNode {
  static readonly type = "action/uiTextContent";
  static readonly label = "UI Text Get Content";

  inputs = [new UITextSocket("uiText")];
  outputs = [new StringSocket("content")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const imageValue = context.readInput<UITextValue>("uiText");
    const entity = node.sceneContext
      .getUIEntityContext<UITextContext>(imageValue.entityId ?? "")
      ?.getEntity<TextUIEntity>();

    context.writeOutput<string>("content", entity?.content ?? defaultString());
  }
}
