import { MenuList } from "@mui/material";
import { MouseEvent, useCallback, useState } from "react";
import { Plus } from "~/common/components/Icon";
import { IconButton, Menu, MenuItem, MenuItemProps, MenuProps } from "~/hephaestus/components";

export type AddButtonProps = {
  items: AddButtonItem[];
  anchorOrigin?: MenuProps["anchorOrigin"];
  PaperProps?: MenuProps["PaperProps"];
  onAdd: (value: string) => void;
};

export type AddButtonItem = {
  value: string;
  label: string;
  disabled?: boolean;
};

export const AddButton = ({
  items,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  PaperProps = {
    sx: {
      marginTop: "8px",
    },
  },
  onAdd,
}: AddButtonProps) => {
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);

  const handleSelect: NonNullable<MenuItemProps["onSelect"]> = useCallback(
    (e) => {
      const value = (e.target as any).dataset["value"] as string;

      onAdd(value);
      setMenuOpen(null);
    },
    [onAdd]
  );

  return (
    <>
      <Menu
        anchorEl={menuOpen}
        anchorOrigin={anchorOrigin}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
        PaperProps={PaperProps}
      >
        <MenuList sx={{ padding: 0 }}>
          {items.map((item) => (
            <MenuItem key={item.value} data-value={item.value} disabled={item.disabled} onClick={handleSelect}>
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <IconButton variant="text" onClick={handleOpenMenu}>
        <Plus />
      </IconButton>
    </>
  );
};
