import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, NumberSocket } from "../../sockets";

export class CollisionMaskNode extends BaseNode {
  static readonly type = "physics/collisionMask";
  static readonly label = "Get collision mask";

  inputs = [new EntitySocket("entity")];
  outputs = [new NumberSocket("mask")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");

    context.writeOutput<number>("mask", entityContext?.getPhysicsBody()?.collisionMask ?? 0);
  }
}
