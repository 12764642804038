import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, SocketsArray } from "../../base";
import { FlowSocket } from "../../sockets";

export class SequenceNode extends BaseNode {
  static readonly type = "flow/sequence";
  static readonly label = "Sequence";
  static category: NodeCategory = "Flow";

  inputs = [new FlowSocket("flow")];
  outputs = [
    new FlowSocket("flow", "Then 0"),
    new SocketsArray({
      id: "flows",
      SocketConstructor: FlowSocket,
      maxCount: 20,
      nameGenerator: (_id, index) => `Then ${index + 1}`,
    }),
  ];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const sockets = node.outputSockets;

    const sequenceIteration = (i: number) => {
      if (i < sockets.length) {
        const id = sockets[i].id;

        context.commit(id, () => {
          sequenceIteration(i + 1);
        });
      }
    };

    sequenceIteration(0);
  }
}
