import { useController } from "@react-three/xr";
import { memo, useEffect, useRef } from "react";
import { PushToTalkObject } from "./PushToTalkObject";
import { useMediaState } from "~/view-scene/media";
import { useProfile } from "~/entities/profile";
import { micStreamSelector, useMediaStreamStore } from "~/view-scene/media/useMediaStreamStore";
import { createAnalyser } from "~/view-scene/common/equalizer";

export const PushToTalk = memo(() => {
  const rightController = useController("right");
  const { voiceChatActive, microphoneEnabled } = useMediaState((state) => ({
    voiceChatActive: state.voiceChatActive,
    microphoneEnabled: state.enabled,
  }));
  const deviceName = useProfile((state) => state.profile?.preferredMicDevice ?? null);
  const micStream = useMediaStreamStore(micStreamSelector(deviceName));
  const pushToTalkObjectRef = useRef<PushToTalkObject | null>(null);

  useEffect(() => {
    if (rightController) {
      const obj = new PushToTalkObject();
      obj.scale.set(0.06, 0.06, 0.06);
      obj.position.set(0.04, 0, 0);
      obj.rotation.set(-Math.PI / 2, Math.PI / 4, -1.74533);
      rightController.grip.add(obj);
      pushToTalkObjectRef.current = obj;

      return () => {
        pushToTalkObjectRef.current = null;
        rightController.grip.remove(obj);
      };
    }
  }, [rightController]);

  useEffect(() => {
    const pushToTalkObject = pushToTalkObjectRef.current;
    if (!rightController || !pushToTalkObject || !voiceChatActive || !micStream || pushToTalkObject.joinedVoiceChat) {
      return;
    }

    const analyser = createAnalyser(micStream);
    pushToTalkObject.joinVoiceChat(analyser);

    return () => {
      analyser.dispose();
    };
  }, [rightController, voiceChatActive, micStream]);

  useEffect(() => {
    const pushToTalkObject = pushToTalkObjectRef.current;
    if (!pushToTalkObject || !pushToTalkObject.joinedVoiceChat) {
      return;
    }

    pushToTalkObject.active = microphoneEnabled;
  }, [microphoneEnabled]);

  return null;
});
