import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setAccessToken } from "~/api/accessToken";
import { useProfile } from "~/entities/profile";
import { getReferer } from "~/pages/auth";

type OAuthLoginCallbackProps = {
  oAuthCb: (code: string, referer: string | null) => Promise<{ status: number; body: any }>;
};

export const OAuthLoginCallback = ({ oAuthCb }: OAuthLoginCallbackProps) => {
  const { loadProfile, redirectPage, resetRedirectPage } = useProfile();

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthLogin = async () => {
      if (!code) {
        navigate("/401");
        return;
      }

      const { status, body } = await oAuthCb(code, getReferer());
      if (status !== 200) {
        navigate("/401");
        return;
      }

      setAccessToken(body.jwt);

      await loadProfile();

      if (redirectPage) {
        navigate(redirectPage);
        resetRedirectPage();
      } else {
        navigate("/");
      }
    };

    handleOAuthLogin();
  }, [code]);

  return null;
};
