import { Object3D, Vector3 } from "three";

export function getForwardVector(object: Object3D, playerDirection: Vector3) {
  object.getWorldDirection(playerDirection);
  playerDirection.y = 0;
  playerDirection.normalize();
  return playerDirection;
}

export function getSideVector(object: Object3D, playerDirection: Vector3) {
  object.getWorldDirection(playerDirection);
  playerDirection.y = 0;
  playerDirection.normalize();
  playerDirection.cross(object.up);
  return playerDirection;
}
