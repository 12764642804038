import { Select, SelectItem, SelectProps } from "./Select";
import { useStoreMap } from "effector-react";
import { $sceneData } from "~/entities/sceneData";

export type CutsceneSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const CutsceneSelect = ({ emptyLabel = "Select Cutscene", value, ...rest }: CutsceneSelectProps) => {
  const cutsceneSystem = useStoreMap($sceneData, (state) => state?.cutsceneSystem);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {cutsceneSystem?.items?.map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.name}
        </SelectItem>
      ))}
    </Select>
  );
};
