import { Icon, IconProps } from "./Icon";

export type EyeProps = IconProps;

export const Eye = (props: EyeProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M1.613 8.475c-.09-.143-.136-.215-.161-.326a.782.782 0 0 1 0-.298c.025-.11.07-.183.161-.326C2.363 6.337 4.597 3.333 8 3.333c3.404 0 5.637 3.004 6.387 4.192.09.143.136.215.162.326.019.083.019.215 0 .298-.026.11-.071.183-.162.326-.75 1.188-2.983 4.192-6.387 4.192-3.403 0-5.636-3.004-6.387-4.192Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
