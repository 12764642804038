import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from "@mui/material";
import { ComponentType, forwardRef } from "react";

export type IconButtonProps = Omit<MuiIconButtonProps, "color"> & {
  variant?: "text" | "outlined" | "contained" | "accent" | "clear";
};

export const IconButton = forwardRef<HTMLButtonElement>(({ variant = "text", ...rest }: IconButtonProps, ref) => (
  // @ts-ignore
  <MuiIconButton {...rest} variant={variant} ref={ref} />
)) as ComponentType<IconButtonProps>;
