import { useGLTF } from "~/view-scene/utils/useGLTF";

export type RenderAvatarProps = {
  avatarUrl: string;
};

export const RenderAvatar = ({ avatarUrl }: RenderAvatarProps) => {
  const { scene } = useGLTF(avatarUrl);

  return (
    <>
      <primitive object={scene} scale={[10.0, 10.0, 10.0]} rotation={[-0.05, -0.15, 0]} position={[0, -15, -1.5]} />
    </>
  );
};
