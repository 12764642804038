import { BaseAdsProvider } from "./BaseAdsProvider";

const scriptId = "applixir-script";

export class ApplixirAdsProvider extends BaseAdsProvider {
  constructor(private zoneId: number, accountId: number, gameId: number, userId: string) {
    super();
    this.type = "Applixir";
  }

  async init() {
    return this.initScript();
  }

  start() {
    // TODO
    this.isPlaying = true;
    setTimeout(() => {
      this.isPlaying = false;
      this.emit("finished", false);
    }, 100);
  }

  async dispose() {
    // TODO;
  }

  async hasAds() {
    return false;
  }

  private initScript() {
    return new Promise<void>((resolve) => {
      if (document.getElementById(scriptId)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://cdn.applixir.com/applixir.sdk3.0m.js";
      script.async = true;
      script.crossOrigin = "anonymous";
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }
}

// https://cdn.applixir.com/applixir.sdk3.0m.js

// invokeApplixirVideoUnit({
//   userId: userId,
//   zoneId: rewardVideoSystem.zoneId,
//   accountId: rewardVideoSystem.accountId,
//   gameId: rewardVideoSystem.gameId,
//   endMsg: 0,
//   verbosity: 5,
//   fallback: 1,
//   adStatusCb: (status: any) => {
//     switch (status) {
//       case "ad-started":
//         break;
//       case "sys-closing":
//         break;
//       case "ad-watched":
//         rewardedVideoEvents.emit("rewardedVideoWatched");
//         break;
//     }
//   },
// });
