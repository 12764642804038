import { PropsWithChildren } from "react";
import { Box, Stack } from "@mui/material";
import { Container, Footer, Header } from "~/common/components";

export const ErrorPageLayout = (props: PropsWithChildren<any>) => {
  return (
    <Stack direction="column" height="100%" minHeight="100vh">
      <Header />
      <Box
        component="div"
        sx={{
          flexGrow: "1",
          padding: "64px 0 32px 0",
        }}
      >
        <Container>{props.children}</Container>
      </Box>
      <Footer />
    </Stack>
  );
};
