import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode, NodeContext } from "../../base";
import { BooleanSocket, NumberSocket } from "../../sockets";

export class NumberCompareNode extends BaseNode {
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";

  inputs = [new NumberSocket("a"), new NumberSocket("b")];
  outputs = [new BooleanSocket("result")];

  constructor(context: NodeContext, private comparator: (a: number, b: number) => boolean) {
    super(context);
  }

  eval(context: NodeEvalContext) {
    const a = context.readInput<number>("a");
    const b = context.readInput<number>("b");
    context.writeOutput<boolean>("result", this.comparator(a, b));
  }
}
