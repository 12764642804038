import { defaultComponent } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, EntitySocket, StringSocket } from "../../sockets";
import { AnimationValue } from "../../values";
import { ComponentType } from "~/types/ComponentType";

type Node = BaseBehaveNode<{ animation: AnimationValue }>;

export class AnimationGetByName extends BaseNode {
  static readonly type = "action/animationGetByName";
  static readonly label = "Get Animation By Name";

  inputs = [new EntitySocket("entity"), new StringSocket("name")];
  outputs = [new AnimationSocket("animation")];

  initNode(node: Node) {
    node.storage.animation = defaultComponent();
  }

  eval(context: NodeEvalContext, node: Node) {
    const entity = node.readEntity(context, "entity");
    const name = context.readInput("name");

    if (!entity?.components) {
      node.storage.animation.entityId = null;
      node.storage.animation.componentId = null;
    } else {
      const animation = Object.values(entity.components).find((component) => {
        return (
          (component.type === ComponentType.ANIMATION || component.type === ComponentType.SPRITE_ANIMATION) &&
          component.name === name
        );
      });

      if (animation) {
        node.storage.animation.entityId = entity.id;
        node.storage.animation.componentId = animation.id;
      } else {
        node.storage.animation.entityId = null;
        node.storage.animation.componentId = null;
      }
    }

    context.writeOutput("animation", node.storage.animation);
  }
}
