import { Action, Direction, InstantAction } from "./userActions";
import { isAction } from "./utils";

export abstract class BaseControls {
  abstract activate(): void;
  abstract deactivate(): void;
  abstract update(): void;

  pipeAction<TAction extends Action | InstantAction>(
    source: TAction,
    target: TAction,
    condition: () => boolean = () => true
  ) {
    if (isAction(source) && isAction(target)) {
      const startHandler = (value: any) => {
        if (condition()) {
          target.emit("start", value);
        }
      };

      const endHandler = (value: any) => {
        if (condition()) {
          target.emit("end", value);
        }
      };

      source.on("start", startHandler);
      source.on("end", endHandler);

      return () => {
        source.off("start", startHandler);
        source.off("end", endHandler);
      };
    } else if (!isAction(source) && !isAction(target)) {
      const fireHandler = (value: any) => {
        if (condition()) {
          target.emit("fire", value);
        }
      };

      source.on("fire", fireHandler);

      return () => {
        source.off("fire", fireHandler);
      };
    }

    return () => {};
  }

  abstract reset(): void;

  copyDirection(source: Direction, target: Direction) {
    target.side = source.side;
    target.forward = source.forward;
  }
}
