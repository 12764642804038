import { Paper, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { LoginWithEmailPassword } from "./LoginWithEmailPassword";
import { LoginWithOauth } from "./LoginWithOauth";
import { useProfile } from "~/entities/profile";
import { AuthFormState } from "~/pages/auth/types";

export type LoginProps = {
  isOauthEnabled?: boolean;
  onSuccess: (token: string) => void;
  onFormStateChange: (formState: AuthFormState) => void;
};

export function Login({ isOauthEnabled, onSuccess, onFormStateChange }: LoginProps) {
  const hasRedirectPage = useProfile((state) => state.redirectPage != null && state.redirectPage != "/");

  return (
    <>
      {hasRedirectPage && <LoginRequiredWarning />}
      {!hasRedirectPage && (
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            paddingBottom: "24px",
            width: "100%",
            color: palette.primary.gray800,
          }}
          variant="body1"
        >
          Log in or create a new account <br />
        </Typography>
      )}
      <LoginWithEmailPassword onSuccess={onSuccess} onFormStateChange={onFormStateChange} />
      {isOauthEnabled && <LoginWithOauth />}
    </>
  );
}

function LoginRequiredWarning() {
  return (
    <LoginRequiredWarningContainer>
      <Typography color={palette.primary.black} fontSize="16px" lineHeight="23px" fontWeight={600}>
        To get access to this content, please, log in or create a new account.
      </Typography>
    </LoginRequiredWarningContainer>
  );
}

const LoginRequiredWarningContainer = styled(Paper)`
  background-color: ${palette.primary.brand200};
  border-radius: 12px;
  padding: 16px 24px 16px 24px;
  margin-bottom: 12px;
`;
