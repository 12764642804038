import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, SceneSocket } from "../../sockets";
import { SceneValue } from "../../values";

export class UserHasAccessToSceneNode extends BaseNode {
  static readonly type = "action/userHasAccessToScene";
  static readonly label = "Has access to scene";

  async = true;
  interruptibleAsync = true;

  inputs = [new FlowSocket("flow"), new SceneSocket("scene")];
  outputs = [new FlowSocket("flow"), new BooleanSocket("hasAccess")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const scene = context.readInput<SceneValue>("scene");
    let canceled = false;

    if (!scene.sceneId) {
      context.writeOutput("hasAccess", false);
      context.commit("flow");
      return;
    }

    node.sceneContext.userHasAccessToScene(scene.sceneId).then((hasAccess) => {
      if (canceled) {
        return;
      }

      context.writeOutput<boolean>("hasAccess", hasAccess);
      context.commit("flow");
      context.graphEvaluator.executeAll();
    });

    context.onAsyncCancelled.addListener(() => {
      canceled = true;
    });
  }
}
