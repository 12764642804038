import { memo, useEffect, useMemo } from "react";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import ISceneObject from "~/types/ISceneObject";
import { useDIContext } from "~/view-scene/diContext";

type RenderSceneEntitiesProps = {
  entities: ISceneObject[];
};

export const RenderSceneEntities = memo(({ entities }: RenderSceneEntitiesProps) => {
  const { toSceneEntity } = useDIContext();
  const { sessionStatus, updateStatus } = useSessionStatus((state) => ({
    updateStatus: state.updateStatus,
    sessionStatus: state.sessionStatus,
  }));

  // effect triggers when all entities and their components mounted
  useEffect(() => {
    if (sessionStatus === "loaded") {
      updateStatus("ready");
    }
  }, [sessionStatus]);

  const sceneContent = useMemo(() => entities.map((entity) => toSceneEntity(entity)), [entities]);

  return <>{sceneContent}</>;
});
