import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";

export const Container = styled(Stack)`
  background: ${palette.primary.gray100};
  border: 1px solid ${palette.primary.gray200};
  border-radius: 60px;
  min-height: 60px;
  box-sizing: border-box;
`;
