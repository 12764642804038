import { Button, ButtonProps, styled } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { palette } from "~/theme";

export type LoginButtonProps = Pick<ButtonProps, "className" | "sx">;

export const LoginButton = (props: LoginButtonProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenLoginDialogClick = () => {
    const param = searchParams.get("auth");
    if (!param) {
      searchParams.append("auth", "login");
      setSearchParams(searchParams);
    }
  };

  return (
    <StyledButton {...props} variant="outlined" size="medium" onClick={handleOpenLoginDialogClick}>
      Log in
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  color: ${palette.primary.white};
  border-color: ${palette.primary.green400};
  background: ${palette.primary.green400};

  &:hover {
    border-color: ${palette.primary.green600};
    background: ${palette.primary.green600};
  }
`;
