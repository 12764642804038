import IHemisphereLight from "~/types/IHemisphereLight";
import { HemisphereLight, HemisphereLightHelper } from "three";
import { RenderLightHelper } from "./RenderLightHelper";
import { MutableRefObject, useRef } from "react";

export type RenderHemisphereLightProps = {
  light: IHemisphereLight;
  drawHelper: boolean;
};

export default function RenderHemisphereLight({ light, drawHelper }: RenderHemisphereLightProps) {
  const { color, groundColor, intensity } = light;

  const hemisphereLightRef = useRef<HemisphereLight>() as MutableRefObject<HemisphereLight>;

  return (
    <>
      <hemisphereLight args={[color, groundColor, intensity]} ref={hemisphereLightRef} />
      {drawHelper && <RenderLightHelper lightRef={hemisphereLightRef} helperConstructor={HemisphereLightHelper} />}
    </>
  );
}
