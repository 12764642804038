import { MutableRefObject, RefObject, useEffect, useState } from "react";
import { Group } from "three";
import { TweenAnimationComponent as TweenAnimationComponentDescriptor } from "~/types/component";
import { EntityContext, useComponentContext } from "~/view-scene/runtime";
import { TweenAnimationContext } from "./TweenAnimationContext";

export type RenderTweenAnimationProps = {
  component: TweenAnimationComponentDescriptor;
  entityId: string;
  objectRef: RefObject<Group>;
  contextRef: MutableRefObject<EntityContext>;
};

export const RenderTweenAnimation = ({ component, entityId, objectRef, contextRef }: RenderTweenAnimationProps) => {
  const [context, setContext] = useState<TweenAnimationContext | null>(null);

  useEffect(() => {
    let context: TweenAnimationContext;

    setTimeout(() => {
      const context = new TweenAnimationContext(component, contextRef);
      setContext(context);
    });

    return () => {
      context?.dispose();
    };
  }, [component]);

  useComponentContext(contextRef, component.id, () => context ?? ({} as any), [context]);

  return null;
};
