const banAvatars = [
  "ff70b5fa-4422-488b-b4bf-887e4be57b6c.glb",
  "f01adaa0-f257-4470-9d6c-3120b46ce1db.glb",
  "edf6c9cf-e2d9-468a-9890-20218a8e1e33.glb",
  "ed38eac9-9cce-413a-95bf-9c01648ba97d.glb",
  "ebfa0d17-259a-4a9c-a844-c304926c90f5.glb",
  "e82e34d5-6786-4f24-9e49-8905181615f0.glb",
  "e084420b-2bd6-4ffb-9ce2-c05e344f2728.glb",
  "d938237c-0a6a-4763-b66d-4da6dc749c97.glb",
  "d9124e37-4a71-4a60-87e4-a36ced21aa4b.glb",
  "d4116f8a-9d03-4f70-826a-00449f72d1e5.glb",
  "d166fcaf-5c77-49f8-998d-80e515ae42be.glb",
  "cb4583ff-f6da-4cc9-9682-25421e23ad8c.glb",
  "c66cd40b-a1d3-48dd-9cb8-ac08c52a4181.glb",
  "b5ed1e02-5d5a-4612-9ebd-d66741e68316.glb",
  "b1c23f40-542e-49cf-8e68-cad5c347901e.glb",
  "b087d98c-7c91-4359-b15a-f771e39df9bd.glb",
  "aed39445-1093-496e-aef4-3b159fcdde3a.glb",
  "932cc215-839e-499d-a241-7248a6a340bd.glb",
  "927ede81-79f7-4dcd-b2c9-da1b0f53a5ba.glb",
  "8d4c12b5-8389-496b-9377-bff506bce88c.glb",
  "83b4c1cd-1fef-4d70-8446-3719adb4ccf6.glb",
  "81fc7d06-6ae4-459e-a545-79d10e1d8e1b.glb",
  "8048b8fb-92e9-48d5-89e7-310319cb5a06.glb",
  "76b25bb5-a910-49b2-acb2-f4be1261c197.glb",
  "738c5771-390d-4a03-bb11-c1cd6146e35d.glb",
  "6e6ad62f-806a-4ac3-b090-dfc38ec337a3.glb",
  "6d2ee8d1-ba8f-477f-a2a8-f90558681730.glb",
  "6bb49ede-59fa-4943-9603-d7781ac683b7.glb",
  "6a0e3d76-d514-4b5f-b881-c7af61b5226b.glb",
  "67dd0552-a0d8-43cb-b693-0fd823772871.glb",
  "5ec09f2b-acb7-4a0d-9cdf-4bb8dae81581.glb",
  "5cf6b86f-cd62-4e54-b781-eded8e7561f1.glb",
  "4295d687-5545-4255-a830-1c7b2084c6c0.glb",
  "20eebb9a-eeaa-4082-a581-aba1995674e6.glb",
  "20a8172d-a032-41d7-b894-5fde2193367a.glb",
  "15feb422-c5ab-4365-bcf9-72413f9cfa30.glb",
  "14c6bf3e-ef0c-46b2-8053-7fc29cbff73e.glb",
  "0e25f30e-9006-41ea-b2e4-d7f392a6ab54.glb",
  "0c8009b8-7643-48b0-8a1b-c04def3bd22a.glb",
  "06f94348-4474-4cd7-9dd1-a5e4d7eda123.glb",
  "23f06ef4-8fdf-4571-bccb-d3c5699db621_1.glb",
];

export const isBanned = (url: string) => {
  for (const av of banAvatars) {
    if (url.indexOf(av) >= 0) {
      return true;
    }
  }

  return false;
};
