import { TouchEvent } from "react";
import { Position } from "./types";

export const getTouchPosition = (event: TouchEvent): Position => {
  const x = event.targetTouches ? event.targetTouches[0].pageX : (event as any).clientX;
  const y = event.targetTouches ? event.targetTouches[0].pageY : (event as any).clientY;
  return { x, y };
};

export const calcPosition = (touchPosition: Position, joystickPosition: Position, joystickSize: number) => {
  const maxRadius = joystickSize / 2;
  const maxRadiusSquared = maxRadius * maxRadius;

  const centerX = joystickPosition.x + maxRadius;
  const centerY = joystickPosition.y + maxRadius;

  let left = (touchPosition.x - centerX) / maxRadius;
  let top = (centerY - touchPosition.y) / maxRadius;

  const radSq = left * left + top * top;

  if (radSq > 1) {
    const magnitude = Math.sqrt(radSq);
    left = left / magnitude;
    top = top / magnitude;
  }

  const value = {
    side: left,
    forward: top,
  };

  const padding = joystickSize * 0.1;
  const posX = value.side * maxRadius;
  const posY = value.forward * maxRadius;

  const stickPosition = {
    x: maxRadius + posX,
    y: maxRadius - posY,
  };

  return {
    value,
    stickPosition,
  };
};
