import { Box, BoxProps, ListItemText, Menu, MenuItem, css, styled } from "@mui/material";
import { useState } from "react";
import { Navigate } from "~/common/components";
import { User } from "~/common/components/Icon";
import { HOST } from "~/config";
import { useProfile } from "~/entities/profile";
import { LoginButton } from "./LoginButton";

export type UserMenuProps = Pick<BoxProps, "className" | "sx">;

export const UserMenu = ({ className, sx }: UserMenuProps) => {
  const { profile, logOut } = useProfile((state) => ({
    profile: state.profile,
    logOut: state.logOut,
  }));
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);

  if (!profile) {
    return <LoginButton className={className} sx={sx} />;
  }

  const { avatarPreview, emailConfirmed } = profile;

  return (
    <>
      <Box className={className} component="span" sx={{ cursor: "pointer", ...sx }} onClick={handleOpenMenu}>
        {avatarPreview ? <UserImage src={avatarPreview} /> : <UserIcon color="primary.green600" />}
      </Box>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        disableAutoFocusItem
        anchorEl={menuOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <Navigate to="/profile/settings" requireAuth={true}>
            <ListItemText>Profile</ListItemText>
          </Navigate>
        </MenuItem>
        {HOST === "8xr" && emailConfirmed && (
          <MenuItem>
            <Navigate to="/projects" requireAuth={true}>
              <ListItemText>My Projects</ListItemText>
            </Navigate>
          </MenuItem>
        )}
        <MenuItem onClick={logOut}>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const userStyles = () => css`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

const UserIcon = styled(User)`
  ${userStyles}
`;

const UserImage = styled("img")`
  ${userStyles}
`;
