import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberCeilNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberCeil";
  static readonly label = "Number Ceil";

  constructor(context: NodeContext) {
    super(context, (value) => Math.ceil(value));
  }
}
