export type UINotification = {
  icon: NotificationIcon;
  text: string;
  duration?: number;
};

export enum NotificationIcon {
  SCREEN_SHARE_ICON = "/static/img/viewer/notifications/screen-share-icon.png",
  USER_ICON = "/static/img/viewer/notifications/user-icon.png",
}
