import { Action, Direction, InstantAction, userActions } from "./userActions";
import { BaseControls } from "./baseControls";

export class PlayerControls extends BaseControls {
  cameraActive: boolean = true;

  isActive = false;

  move = new Direction();
  look = new InstantAction<Direction>();

  jump = new Action();
  action = new Action();
  talk = new Action();
  cameraZoomIn = new InstantAction();
  cameraZoomOut = new InstantAction();

  emotion = new InstantAction<string>();

  inventory = new InstantAction();

  customAction = new Action<string>();

  unSubscriptions: (() => void)[] = [];

  activate() {
    this.isActive = true;

    this.unSubscriptions.push(
      this.pipeAction(userActions.jump, this.jump),
      this.pipeAction(userActions.action, this.action),
      this.pipeAction(userActions.talk, this.talk),
      this.pipeAction(userActions.look, this.look, () => this.cameraActive),
      this.pipeAction(userActions.cameraZoomIn, this.cameraZoomIn, () => this.cameraActive),
      this.pipeAction(userActions.cameraZoomOut, this.cameraZoomOut, () => this.cameraActive),
      this.pipeAction(userActions.customAction, this.customAction),

      this.pipeAction(userActions.emotion, this.emotion),

      this.pipeAction(userActions.inventory, this.inventory)
    );
  }

  deactivate() {
    for (const unsubscribe of this.unSubscriptions) {
      unsubscribe();
    }

    this.isActive = false;

    this.move.side = 0;
    this.move.forward = 0;
  }

  update() {
    if (!this.isActive) {
      return;
    }

    this.copyDirection(userActions.move, this.move);
  }

  reset() {
    this.move.side = 0;
    this.move.forward = 0;
  }
}

export const playerControls = new PlayerControls();
