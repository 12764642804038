import { useFrame } from "@react-three/fiber";
import { forwardRef, useMemo, useRef } from "react";
import { BackSide, Mesh, ShaderMaterial } from "three";
import { useTexture } from "~/common/utils/useTexture";
import { sphereGeometry } from "./constants";
import { fragment, vertex } from "./shaders";
import { useBasisLoader } from "./useBasisLoader";

export type RenderPreviewBallProps = {
  previewUrl: string;
};

export const RenderPreviewBall = forwardRef<Mesh | null, RenderPreviewBallProps>(({ previewUrl }, ref) => {
  const timeRef = useRef(0);
  const materialRef = useRef<ShaderMaterial | null>(null);
  const texture = useTexture(previewUrl);
  const panoballFx = useBasisLoader("/static/images/ballfx.basis");

  useFrame((_, delta) => {
    timeRef.current += delta;

    if (materialRef.current) {
      materialRef.current.uniforms.time.value += timeRef.current;
    }
  });

  const { geometry, material } = useMemo(() => {
    const geometry = sphereGeometry.clone();
    const material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        tex: { value: texture },
        texfx: { value: panoballFx },
        selected: { value: 0 },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      side: BackSide,
    });

    materialRef.current = material;

    return {
      geometry,
      material,
    };
  }, []);

  return <mesh ref={ref} geometry={geometry} material={material} />;
});
