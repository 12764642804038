import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class DoOnce extends BaseNode {
  static readonly type = "flow/doOnce";
  static readonly label = "Do Once";
  static category: NodeCategory = "Flow";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.didOnce = false;
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    if (!node.storage.didOnce) {
      node.storage.didOnce = true;
      context.commit("flow");
    } else {
      context.graphEvaluator.executeAll();
    }
  }
}
