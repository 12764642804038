import { NodeContext } from "../../base";
import { AudioSocket } from "../../sockets/AudioSocket";
import { SceneEntityNode } from "./SceneEntityNode";

export class SceneAudioNode extends SceneEntityNode {
  static readonly type = "variable/sceneAudio";
  static readonly label = "Audio";

  constructor(context: NodeContext) {
    super(context, new AudioSocket("audio"));
  }
}
