import { ValueType } from "~/libs/behave-graph";
import { defaultUIEntity } from "~/entities/variable";
import { UIEntityValue } from "./uiEntityValue";

export type UIBlockValue = UIEntityValue;

export const uiBlockValue = new ValueType(
  "uiBlock",
  defaultUIEntity,
  (text: UIBlockValue) => text,
  (value: UIBlockValue) => value
);
