import { styled } from "@mui/material";
import { useCallback } from "react";
import { Close, Search } from "~/common/components/Icon";
import { IconButton } from "./IconButton";
import { TextField, TextFieldProps } from "./TextField";

export type SearchFieldProps = Omit<TextFieldProps, "value"> & {
  value?: string;
};

export const SearchField = ({ value, name, onChange, ...rest }: SearchFieldProps) => {
  const clear = useCallback(() => {
    onChange &&
      onChange({
        target: {
          name,
          value: "",
        },
      } as any);
  }, [name, onChange]);

  return (
    <TextField
      value={value}
      name={name}
      autoComplete="off"
      InputProps={{
        startAdornment: <Search size="small" />,
        endAdornment: value ? (
          <StyledButton onClick={clear}>
            <Close size="small" />
          </StyledButton>
        ) : null,
      }}
      type="text"
      placeholder="Search"
      onChange={onChange}
      {...rest}
    />
  );
};

const StyledButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
`;
