import { Icon, IconProps } from "./Icon";

export type BillboardProps = IconProps;

export const Billboard = (props: BillboardProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="16px" height="14px" fill="none" viewBox="0 0 16 14" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 10.333V13H6v-2.667m-2.533 0h9.066c.747 0 1.12 0 1.406-.145.25-.128.455-.332.582-.583.146-.285.146-.658.146-1.405V3.133c0-.746 0-1.12-.146-1.405a1.333 1.333 0 0 0-.582-.583C13.654 1 13.28 1 12.534 1H3.466c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.146.285-.146.659-.146 1.405V8.2c0 .747 0 1.12.146 1.405.128.251.332.455.583.583.285.145.658.145 1.405.145ZM8.5 5.75a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm3.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-7 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </Icon>
);
