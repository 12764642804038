import { useController } from "@react-three/xr";
import { useEffect } from "react";
import { Layer } from "~/view-scene/layers";

export const useInitControllers = () => {
  const leftController = useController("left");
  const rightController = useController("right");

  useEffect(() => {
    if (!leftController || !rightController) {
      return;
    }

    leftController.traverse((obj) => {
      obj.layers.enable(Layer.video360);
      obj.layers.enable(Layer.cutscene);
    });

    rightController.traverse((obj) => {
      obj.layers.enable(Layer.video360);
      obj.layers.enable(Layer.cutscene);
    });
  }, [leftController, rightController]);
};
