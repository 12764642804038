import { Icon, IconProps } from "./Icon";

export type LogoProps = IconProps;

export const Logo = (props: LogoProps) => (
  <Icon width="29px" height="12px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 12" {...props}>
    <path
      d="M6.75988 6C6.07555 5.0875 4.98228 4.5 3.75549 4.5C2.5287 4.5 1.43543 5.0875 0.751098 6C0.279575 5.375 0 4.59583 0 3.75C0 1.67917 1.68163 0 3.75549 0C5.82936 0 7.51098 1.67917 7.51098 3.75C7.51098 4.59583 7.23141 5.375 6.75988 6Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M6.75988 6C7.23141 6.625 7.51098 7.40417 7.51098 8.25C7.51098 10.3208 5.82936 12 3.75549 12C1.68163 12 0 10.3208 0 8.25C0 7.40417 0.279575 6.625 0.751098 6C1.43543 6.9125 2.5287 7.5 3.75549 7.5C4.98228 7.5 6.07555 6.9125 6.75988 6Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M18.139 11.6458H15.6479L14.4086 9.82084C13.8828 9.05834 13.3904 8.3 12.898 7.52083C12.4056 8.3 11.9132 9.05834 11.3875 9.82084L10.1315 11.6458H7.70293L11.6921 5.8875L7.94495 0.350001H10.4236L11.5502 2.03333C12.0426 2.7625 12.5016 3.49167 12.9481 4.20833C13.0386 4.06779 13.1293 3.92659 13.2204 3.78484L13.2208 3.78416L13.2209 3.78412C13.5913 3.20751 13.9676 2.62176 14.3627 2.03333L15.506 0.350001H17.922L14.1874 5.8375L18.139 11.6458Z"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2394 7.4125H23.2548L26.0548 11.6458H28.5L25.4956 7.15833C27.1939 6.73333 28.1328 5.47917 28.1328 3.82917C28.1328 1.76667 26.6723 0.354167 24.1311 0.354167H19.0946V11.6458H21.2394V7.4125ZM24.0184 5.68333H21.2394V2.11667H24.0184C25.2703 2.11667 25.9713 2.84583 25.9713 3.89167C25.9713 4.95417 25.2745 5.68333 24.0184 5.68333Z"
      fill="white"
      fillOpacity="0.6"
    />
  </Icon>
);
