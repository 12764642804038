import { ThemeOptions, Theme } from "@mui/material";
import { palette } from "../palette";

const fontFamily = ["Inter"].join(",");

export const typography: NonNullable<ThemeOptions["typography"]> = {
  allVariants: {
    color: palette.primary.white,
    fontFamily,
  },
  fontFamily,
};

export const setupTypographyVariants = (theme: Theme) => {
  theme.typography.body1 = {
    ...theme.typography.body1,
    fontSize: "12px",
    lineHeight: "16px",
  };

  theme.typography.body2 = {
    ...theme.typography.body2,
    fontSize: "14px",
    lineHeight: "18px",
  };

  theme.typography.h1 = {
    ...theme.typography.h1,
    fontSize: "70px",
    fontWeight: "700",
    lineHeight: "75px",
    [theme.breakpoints.down("md")]: {
      fontSize: "48px",
      lineHeight: "55px",
    },
  };

  theme.typography.h2 = {
    ...theme.typography.h2,
    fontSize: "36px",
    lineHeight: "45px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      lineHeight: "35px",
    },
  };

  theme.typography.h3 = {
    ...theme.typography.h3,
    fontSize: "28px",
    lineHeight: "35px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  };
};
