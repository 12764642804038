import { Stack } from "@mui/material";
import { Circle, Description } from "./primitives";

export function SingleStickMobileGuidance() {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      sx={(theme) => ({
        display: "none",
        [theme.breakpoints.down("md")]: {
          padding: "16px",
          display: "flex",
          position: "fixed",
          width: "calc(100% - 32px)",
          left: 0,
          bottom: 40,
          justifyContent: "center",
        },
      })}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <Circle />
        <Description textAlign="center">Use the circle to move</Description>
      </Stack>
    </Stack>
  );
}
