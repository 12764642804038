import { Box, BoxProps, styled } from "@mui/material";
import { palette } from "~/theme";

export type ProgressProps = Pick<BoxProps, "sx" | "className"> & {
  progress: number;
};

export const Progress = ({ progress, ...rest }: ProgressProps) => {
  const scale = Math.max(progress / 100, 0);

  return (
    <Line {...rest}>
      <LineInner style={{ transform: `scaleX(${scale})` }} />
    </Line>
  );
};

const Line = styled(Box)`
  background: ${palette.primary.gray200};
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
`;

const LineInner = styled(Box)`
  transform-origin: center left;
  transition: 0.5s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${palette.primary.white};
`;
