import { memo, MutableRefObject, RefObject, useEffect, useRef } from "react";
import { Group, InstancedMesh, Mesh, Object3D, Vector3 } from "three";
import { DynamicVisibilityComponentContext, EntityContext, useComponentContext } from "~/view-scene/runtime";
import { ComponentType } from "~/types/ComponentType";
import { EnabledStatus } from "~/types/EnabledStatus";
import { DynamicVisibilityComponent as DynamicVisibilityComponentDescriptor } from "types/component";
import { Matrix4 } from "three/src/math/Matrix4";
import { useFrame } from "@react-three/fiber";

const MIN_DELAY = 4000;

const cameraWorldPosition = new Vector3();
const tmpPositionVector = new Vector3();

type DynamicVisibilityComponentProps = {
  componentDto: DynamicVisibilityComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const DynamicVisibilityComponent = memo(
  ({ componentDto, objectRef, contextRef }: DynamicVisibilityComponentProps) => {
    const instanceMatricesRef = useRef<Record<string, Matrix4[]>>({});

    const context: DynamicVisibilityComponentContext = {
      id: componentDto.id,
      type: ComponentType.DYNAMIC_VISIBILITY,
    };

    useComponentContext(contextRef, componentDto.id, () => context, []);

    useEffect(() => {
      const object = objectRef.current;
      if (componentDto.enabled !== EnabledStatus.enabled || !object) {
        return;
      }

      const setInstanceMatrices = (source: Object3D) =>
        source.traverse((el: Object3D) => {
          if (el instanceof InstancedMesh) {
            const matrices: Matrix4[] = [];

            for (let i = 0; i < el.count; i++) {
              const instanceMatrix = new Matrix4();
              el.getMatrixAt(i, instanceMatrix);
              matrices.push(instanceMatrix);
            }

            instanceMatricesRef.current[el.id] = matrices;
          }
        });

      setInstanceMatrices(object);

      return () => {
        instanceMatricesRef.current = {};
      };
    }, [componentDto.enabled]);

    const lastExecutedRef = useRef(Date.now());

    useFrame(({ camera }) => {
      const object = objectRef.current;
      if (componentDto.enabled !== EnabledStatus.enabled || !object) {
        return;
      }

      const now = Date.now();
      const timeSinceLastExecution = now - lastExecutedRef.current;

      if (timeSinceLastExecution < MIN_DELAY) {
        return;
      }

      lastExecutedRef.current = now;

      camera.getWorldPosition(cameraWorldPosition);

      updateVisibility(object, instanceMatricesRef, componentDto.visibleDistance);
    });

    return null;
  }
);

const updateVisibility = (
  object: Object3D,
  instanceMatricesRef: MutableRefObject<Record<string, Matrix4[]>>,
  maxVisibleDistance: number
) => {
  object.traverse((object3D: Object3D) => {
    if (object3D instanceof InstancedMesh) {
      const instanceMatrices = instanceMatricesRef.current[object3D.id];
      if (!instanceMatrices || instanceMatrices.length === 0) {
        return;
      }

      const visibleTransformations = instanceMatrices.filter((t) => {
        tmpPositionVector.setFromMatrixPosition(t);
        object3D.localToWorld(tmpPositionVector);

        const distance = tmpPositionVector.distanceTo(cameraWorldPosition);
        return distance < maxVisibleDistance;
      });

      object3D.count = visibleTransformations.length;

      for (let i = 0; i < visibleTransformations.length; i++) {
        object3D.setMatrixAt(i, visibleTransformations[i]);
      }

      object3D.instanceMatrix.needsUpdate = true;
    } else if (object3D instanceof Mesh) {
      object3D.getWorldPosition(tmpPositionVector);
      const distance = tmpPositionVector.distanceTo(cameraWorldPosition);
      object3D.visible = distance < maxVisibleDistance;
    }
  });
};
