import { ForwardedRef, forwardRef, memo, useMemo } from "react";
import { FrontSide, InstancedMesh, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D } from "three";
import { IMaterialAsset } from "types/material";
import { useAsset } from "~/entities/assets";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { meshBounds } from "@react-three/drei";
import { isArray } from "lodash-es";
import { calcRelativeTransformation, useToggleMatrixAutoUpdate } from "~/view-scene/utils";
import useBVH from "~/view-scene/utils/useBVH";
import { RenderObjectWithOverrideMaterial } from "./RenderObjectWithOverrideMaterial";

export type RenderObjectProps = {
  object: Object3D;
  isStatic?: boolean;
  materialId?: string | null;
  useBasicMaterial?: boolean;
  castShadow?: boolean;
  receiveShadow?: boolean;
  removeMetalness?: boolean;
  frustumCulling?: boolean;
};

export const RenderObject = memo(
  forwardRef(
    (
      {
        object,
        isStatic = false,
        materialId,
        useBasicMaterial = false,
        castShadow = false,
        receiveShadow = false,
        removeMetalness = false,
        frustumCulling = true,
      }: RenderObjectProps,
      ref: ForwardedRef<Object3D>
    ) => {
      const materialAsset = useAsset<IMaterialAsset>(materialId);

      const model = useMemo(() => {
        const model = (SkeletonUtils as any).clone(object) as Object3D;
        const duplicates = new Map<string, number>();

        model.traverse((child) => {
          if (child instanceof Mesh) {
            const instanceId = getInstanceId(child);
            duplicates.set(instanceId, (duplicates.get(instanceId) ?? 0) + 1);
          }
        });

        const shouldBeInstanced = (mesh: Mesh) => (duplicates.get(getInstanceId(mesh)) ?? 0) > 1;

        const instanceIdToInstances: Map<string, Mesh[]> = new Map();

        model.traverse((el: Object3D) => {
          if (el instanceof Mesh) {
            if (shouldBeInstanced(el)) {
              const instanceId = getInstanceId(el);
              const meshes = instanceIdToInstances.get(instanceId);
              if (meshes) {
                meshes.push(el);
              } else {
                instanceIdToInstances.set(instanceId, [el]);
              }
            }
          }
        });

        instanceIdToInstances.forEach((meshes) => {
          const geometry = meshes[0].geometry;
          const material = meshes[0].material;
          const count = meshes.length;
          const instancedMesh = new InstancedMesh(geometry, material, count);

          meshes.forEach((mesh, index) => {
            const transformationMatrix = calcRelativeTransformation(mesh, model);
            instancedMesh.setMatrixAt(index, transformationMatrix);
            mesh.parent?.remove(mesh);
          });

          instancedMesh.instanceMatrix.needsUpdate = true;
          model.add(instancedMesh);
        });

        const materials: Map<string, MeshBasicMaterial> = new Map();

        model.traverse((el: Object3D) => {
          if (el instanceof Mesh || el instanceof InstancedMesh) {
            el.castShadow = castShadow;
            el.receiveShadow = receiveShadow;
            // TODO: return it
            // el.frustumCulled = frustumCulling;

            let currentMaterial: MeshStandardMaterial | MeshStandardMaterial[] = el.material;

            if (!materialAsset && !Array.isArray(currentMaterial)) {
              currentMaterial = currentMaterial as MeshStandardMaterial;

              if (receiveShadow) {
                currentMaterial.side = FrontSide;
              }

              // Deprecated
              if (removeMetalness) {
                currentMaterial.metalness = 0.0;
              }

              if (useBasicMaterial) {
                if (materials.has(currentMaterial.name)) {
                  el.material = materials.get(currentMaterial.name);
                } else {
                  const basicMaterial = new MeshBasicMaterial();
                  basicMaterial.copy(currentMaterial);
                  el.material = basicMaterial;
                  materials.set(currentMaterial.name, basicMaterial);
                }
              }
            }
          }
        });

        return model;
      }, [object, castShadow, receiveShadow, removeMetalness, materialAsset, frustumCulling, useBasicMaterial]);

      useBVH(model);

      useToggleMatrixAutoUpdate(model, isStatic);

      return materialId ? (
        <RenderObjectWithOverrideMaterial object={model} materialAssetId={materialId} ref={ref} />
      ) : (
        <primitive object={model} raycast={meshBounds} ref={ref} />
      );
    }
  )
);

const getInstanceId = (mesh: Mesh) => {
  const geometryId = mesh.geometry?.uuid;
  const materialName = isArray(mesh.material)
    ? mesh.material.map((material) => material.name).join("-")
    : mesh.material.name;

  return `${geometryId}-${materialName}`;
};
