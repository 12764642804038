import { createStore } from "effector";
import { parse } from "query-string";

const defaultFlags = {
  mode: undefined,
  pauseOverlay: true,
  showProfiler: false,
  physicsDebugger: false,
  occlusionCullingDebugger: false,
  sceneConsole: false,
  loadDebugger: false,
  adsDebug: false,
};

export type Flags = typeof defaultFlags & {
  mode?: "desktop" | "mobile" | "vr";
};

const getFlags = () => {
  return {
    ...defaultFlags,
    ...parse(window.location.search, {
      parseNumbers: true,
      parseBooleans: true,
    }),
  };
};

export const $flags = createStore<Flags>(getFlags());
