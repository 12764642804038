import { useFrame } from "@react-three/fiber";
import { memo, ReactNode, Suspense, useEffect } from "react";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import { useAvatar, useAvatarAnimations, useAvatarUrl } from "~/view-scene/avatar";
import { Billboard, Text } from "@react-three/drei";
import { playRemotePlayerAnimation, stopRemotePlayerAnimation } from "../../models";
import { isBanned } from "../banAvatars";

const zeroVector3: [number, number, number] = [0, 0, 0];

type NearbyRemoteUserAvatarProps = {
  userId: string;
  children: ReactNode;
};

export const NearbyRemoteUserAvatar = memo(({ userId, children }: NearbyRemoteUserAvatarProps) => {
  const name = useSceneData((state) => state.getRemoteUser(userId)!.name ?? "Somebody", shallow);
  const { sex, avatarUrl } = useSceneData(
    (state) => ({
      sex: state.getRemoteUser(userId)!.avatar.sex,
      avatarUrl: state.getRemoteUser(userId)!.avatar.avatarUrl,
    }),
    shallow
  );

  const getRemoteUser = useSceneData((state) => state.getRemoteUser);

  const remoteUserAvatarUrl = useAvatarUrl(isBanned(avatarUrl) ? null : avatarUrl);
  const { avatarContainer, avatar, boundingBox } = useAvatar(remoteUserAvatarUrl);

  const { controls } = useAvatarAnimations(
    avatar,
    sex ?? "male",
    true,
    () => getRemoteUser(userId)?.avatar?.velocity ?? zeroVector3,
    () => getRemoteUser(userId)?.avatar.head?.rotation ?? zeroVector3,
    1 / 40
  );

  useEffect(() => {
    const playAnimationSub = playRemotePlayerAnimation.watch(({ animation, userId: remoteUserId }) => {
      if (remoteUserId === userId) {
        controls.playAnimation(animation);
      }
    });

    const stopAnimationSub = stopRemotePlayerAnimation.watch(({ animation, userId: remoteUserId }) => {
      if (remoteUserId === userId) {
        controls.stopAnimation(animation);
      }
    });

    return () => {
      playAnimationSub.unsubscribe();
      stopAnimationSub.unsubscribe();
    };
  }, [userId, controls]);

  useFrame(() => {
    const positionUpdate = getRemoteUser(userId)?.avatar.position;
    if (!positionUpdate) {
      return;
    }

    avatarContainer.position.set(positionUpdate[0], positionUpdate[1], positionUpdate[2]);
  });

  return (
    <primitive object={avatarContainer}>
      <Suspense fallback={null}>
        <Billboard position={[0, (boundingBox.max.y - boundingBox.min.y) / 2 + 0.2, 0]}>
          <Text rotation={[0, -Math.PI, 0]} fontSize={0.2}>
            {name}
          </Text>
        </Billboard>
      </Suspense>
      {children}
    </primitive>
  );
});
