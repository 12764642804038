import { last } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTimeoutFn, useMount } from "react-use";
import create from "zustand";
import { getTweets, Tweet } from "../../../api/twitter.api";

type UseTwitterType = {
  tweets: Record<string, Tweet[]>;
  tweetsIds: Record<string, Set<string>>;
  pending: Record<string, boolean>;

  loadTweets: (query: string) => Promise<Tweet[]>;
  setPendingState: (query: string, state: boolean) => void;
  pushTweets: (query: string, tweets: Tweet[]) => void;
  getIdsSet: (query: string) => Set<string>;
  getLastTweet: (query: string) => Tweet | null;
};

export const useTwitter = create<UseTwitterType>((set, get) => ({
  tweets: {},
  tweetsIds: {},
  pending: {},

  loadTweets: async (query) => {
    if (get().pending[query]) {
      return [];
    }

    get().setPendingState(query, true);

    const newTweets = await getTweets({
      query,
      sinceId: get().getLastTweet(query)?.id,
    });

    get().setPendingState(query, false);

    const ids = get().getIdsSet(query);
    const tweetsToAppend = newTweets.filter((tweet) => !ids.has(tweet.id)).reverse();

    tweetsToAppend.forEach((tweet) => ids.add(tweet.id));

    if (tweetsToAppend.length === 0) {
      return [];
    }

    get().pushTweets(query, tweetsToAppend);

    return tweetsToAppend;
  },

  setPendingState: (query, pendingState) => {
    set((state) => ({
      pending: {
        ...state.pending,
        [query]: pendingState,
      },
    }));
  },

  pushTweets: (query, newTweets) => {
    const tweets = get().tweets[query] || [];

    set((state) => ({
      tweets: {
        ...state.tweets,
        [query]: tweets.concat(newTweets),
      },
    }));
  },

  getIdsSet(query) {
    if (!get().tweetsIds[query]) {
      set((state) => ({
        tweetsIds: {
          ...state.tweetsIds,
          [query]: new Set(),
        },
      }));
    }

    return get().tweetsIds[query];
  },

  getLastTweet: (query: string) => {
    return last(get().tweets[query]) ?? null;
  },
}));

const noTweets: Tweet[] = [];

export type UseDisplayTweetsParams = {
  filter: string;
  amount: number;
  timeout: number;
};

export const useDisplayTweets = ({ filter, amount, timeout }: UseDisplayTweetsParams) => {
  const [rightIndex, setRightIndex] = useState<number>(-amount);
  const loadTweets = useTwitter((state) => state.loadTweets);
  const tweets = useTwitter((state) => state.tweets[filter]) || noTweets;

  const nextChunk = () => {
    if (rightIndex + amount >= tweets.length) {
      cancel();
      loadTweets(filter).finally(() => reset());
    } else {
      reset();
    }

    setRightIndex((i) => Math.min(i + amount, tweets.length));
  };

  const [, cancel, reset] = useTimeoutFn(() => {
    nextChunk();
  }, timeout);

  useMount(() => {
    nextChunk();
  });

  useEffect(() => {
    if (rightIndex <= 0 && tweets.length > 0) {
      nextChunk();
    }
  }, [tweets, rightIndex]);

  return useMemo(() => {
    const leftIndex = Math.max(0, rightIndex - amount);

    return tweets.slice(leftIndex, rightIndex).reverse();
  }, [tweets, amount, rightIndex]);
};

export type { Tweet };
