import { useEffect, useState } from "react";
import { CircularProgress, styled } from "@mui/material";

export type ProgressLoaderProps = {
  delay?: number;
};

export const ProgressLoader = ({ delay = 300 }: ProgressLoaderProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <ProgressContainer>
      <CircularProgress />
    </ProgressContainer>
  );
};

const ProgressContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
