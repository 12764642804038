import { useStore, useUnit } from "effector-react";
import { RefObject, memo, useEffect } from "react";
import { useUnmount } from "react-use";
import { useSceneData } from "~/common/stores/useSceneData";
import { $storages } from "~/entities/storages";
import { ComponentType } from "~/types/ComponentType";
import { JSScriptAsset } from "~/types/JSScriptAsset";
import { JSScriptComponent as JSScriptComponentType } from "~/types/JSScriptComponent";
import { EntityContext, JSScriptComponentContext, useComponentContext } from "~/view-scene/runtime";
import { $initialized, registerJSScript, unregisterScript } from "~/view-scene/script";

type JSScriptComponentProps = {
  componentDto: JSScriptComponentType;
  contextRef: RefObject<EntityContext>;
};

export const JSScriptComponent = memo(({ componentDto, contextRef }: JSScriptComponentProps) => {
  const getAsset = useSceneData((state) => state.getAsset);
  const storages = useUnit($storages);
  const asset = getAsset<JSScriptAsset>(componentDto.assetId);
  const initialized = useStore($initialized);

  useEffect(() => {
    if (asset && initialized && storages) {
      registerJSScript({
        id: componentDto.id,
        entityContextRef: contextRef,
        args: componentDto.arguments ?? {},
        code: asset.code,
        schema: asset.schema,
      });
    }
  }, [asset, initialized, storages]);

  useUnmount(() => {
    unregisterScript(componentDto.id);
  });

  const context: JSScriptComponentContext = {
    id: componentDto.id,
    name: asset?.name ?? "__unknown__",
    type: ComponentType.JS_SCRIPT,
    scriptId: componentDto.assetId ?? "",
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  return null;
});
