import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberCotanNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberCotan";
  static readonly label = "Cotan";

  constructor(context: NodeContext) {
    super(context, (value) => 1 / Math.tan(value));
  }
}
