import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type AudioValue = EntityValue;

export const audioValue = new ValueType(
  "audio",
  defaultEntity,
  (text: AudioValue) => text,
  (value: AudioValue) => value
);
