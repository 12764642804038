import { useSceneType } from "~/view-scene/hooks";
import { DefaultLoadingScreen } from "./DefaultLoadingScreen";
import { ShardQuestLoadingScreen } from "./ShardQuestLoadingScreen";
import { HyperLoadingScreen } from "./HyperLoadingScreen";

export type LoadingScreenProps = {
  showHeader?: boolean;
};

export const LoadingScreen = (props: LoadingScreenProps) => {
  const type = useSceneType();

  if (type === "shardQuest") {
    return <ShardQuestLoadingScreen />;
  } else if (type === "hyper") {
    return <HyperLoadingScreen />;
  }

  return <DefaultLoadingScreen {...props} />;
};
