import { RefObject } from "react";
import { ScriptArguments } from "~/types/IScriptComponent";
import { ScriptSchema } from "~/types/ScriptAsset";
import type { PlayerControls } from "~/view-scene/ControlsSystem";
import type { EntityContext } from "~/view-scene/runtime";
import { BehaveScript } from "./BehaveScript";
import { Registry } from "./Registry";
import { Sanitizer } from "./Sanitizer";
import type { SceneContext } from "./useSceneContext";
import { Script } from "./Script";
import { JSScript } from "./JSScript";
import { JSScriptSchema } from "~/types/JSScriptAsset";

type ScriptExecutorOptions = {
  registry: Registry;
  sceneContext: SceneContext;
  playerControls: PlayerControls;
};

export class ScriptExecutor {
  started: boolean = false;

  private scripts: Script[] = [];
  private scriptsMap: Record<string, Script> = {};
  private tickScripts: Script[] = [];
  private prevTick: number = 0;
  private registry: Registry;
  private sceneContext: SceneContext;
  private playerControls: PlayerControls;
  private sanitizer: Sanitizer;

  constructor({ registry, sceneContext, playerControls }: ScriptExecutorOptions) {
    this.registry = registry;
    this.sceneContext = sceneContext;
    this.playerControls = playerControls;
    this.sanitizer = new Sanitizer(registry);
  }

  registerBehave(
    id: string,
    name: string,
    entityContextRef: RefObject<EntityContext>,
    args: ScriptArguments,
    schema: ScriptSchema
  ) {
    this.sanitizer.sanitize(schema);
    const script = new BehaveScript(
      id,
      name,
      entityContextRef,
      args,
      schema,
      this.registry,
      this.sceneContext,
      this.playerControls
    );

    return this.register(script);
  }

  registerJS(
    id: string,
    entityContextRef: RefObject<EntityContext>,
    args: ScriptArguments,
    code: string,
    schema: JSScriptSchema
  ) {
    const script = new JSScript(id, args, code, schema, entityContextRef, this.sceneContext);

    return this.register(script);
  }

  register(script: Script) {
    this.scripts.push(script);
    this.scriptsMap[script.id] = script;

    if (this.started) {
      script.init();
      script.start();
    }

    if (script.hasTick) {
      this.tickScripts.push(script);
    }

    return script;
  }

  unregister(id: string) {
    const index = this.scripts.findIndex((script) => script.id === id);

    if (index === -1) {
      return false;
    }
    const script = this.scripts[index];
    script.dispose();

    this.scripts.splice(index, 1);
    this.tickScripts = this.tickScripts.filter((script) => script.id !== id);

    return true;
  }

  init() {
    for (const script of this.scripts) {
      script.init();
    }
  }

  start() {
    for (const script of this.scripts) {
      script.start();
    }

    this.started = true;
  }

  notifyGameSessionStarted() {
    for (const script of this.scripts) {
      script.gameSessionStarted();
    }
  }

  dispose() {
    for (const script of this.scripts) {
      script.dispose();
    }

    this.scripts = [];
    this.tickScripts = [];
  }

  tick() {
    const deltaTime = this.calcDeltaTime();

    for (const script of this.tickScripts) {
      script.tick(deltaTime);
    }
  }

  storageUpdate(storageId: string) {
    for (const script of this.scripts) {
      script.storageUpdate(storageId);
    }
  }

  getScript(id: string) {
    return this.scriptsMap[id] ?? null;
  }

  private calcDeltaTime() {
    const time = Date.now();
    const deltaTime = this.prevTick ? time - this.prevTick : 0;
    this.prevTick = time;

    return deltaTime;
  }
}
