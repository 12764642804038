const bin = (bins: string) => parseInt(bins, 2);

export const collisionGroup = {
  all: bin(new Array(32).fill("1").join("")),
  none: bin(new Array(32).fill("0").join("")),
  static: bin("1"),
  kinematic: bin("10"),
  dynamic: bin("100"),
  player: bin("10000"),
};
