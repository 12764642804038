import { memo, RefObject, useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Group } from "three";
import ITrajectoryComponent from "~/types/ITrajectoryComponent";
import { ComponentType } from "~/types/ComponentType";
import { FiniteStateMachine } from "~/view-scene/utils";
import { EntityContext, TrajectoryComponentContext, useComponentContext } from "~/view-scene/runtime";
import { usePhysics } from "~/view-scene/physics/usePhysics";
import useTriggerZone from "~/view-scene/utils/useTriggerZone";
import { segmentToPath, Path } from "./segmentToPath";
import adjustModelRotation from "./adjustModelRotation";
import calculateNextYPosition from "./calculateNextYPosition";
import { TrajectoryComponentEvents } from "./TrajectoryComponentEvents";

type TrajectoryComponentProps = {
  componentDto: ITrajectoryComponent;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

function TrajectoryComponent({ componentDto, objectRef, contextRef }: TrajectoryComponentProps) {
  const movementSurfaces = usePhysics((state) => state.movementSurfaces);

  const finiteStateMachine = useRef<FiniteStateMachine<{ fraction: number; segmentIndex: number }>>();
  const events = useMemo(() => new TrajectoryComponentEvents(), []);

  const { enabled, triggerZone: triggerZoneId, trajectory } = componentDto;

  const componentEnabled = useTriggerZone(triggerZoneId, enabled);
  const playRef = useRef(componentDto.trajectory.autoplay);

  const context: TrajectoryComponentContext = {
    id: componentDto.id,
    type: ComponentType.TRAJECTORY,
    playbackDirection: "forward",
    events,
    invert: () => {
      if (!componentContextRef.current) {
        return;
      }

      const currDirection = componentContextRef.current.playbackDirection;
      componentContextRef.current.playbackDirection = currDirection === "forward" ? "backward" : "forward";
    },
    play: () => {
      const fsm = finiteStateMachine.current;
      playRef.current = true;

      if (!fsm) {
        return;
      }

      if (fsm.state === "end") {
        fsm.data.segmentIndex = 0;
        fsm.transition("wait");
      }
    },
    pause: () => (playRef.current = false),
    isActive: () => playRef.current,
    reset: () => {
      finiteStateMachine.current?.transition("init");
    },
  };
  const componentContextRef = useComponentContext(contextRef, componentDto.id, () => context);

  // init finiteStateMachine only after objectRef has been rendered
  useEffect(() => {
    const object = objectRef.current;
    if (!object) {
      return;
    }

    const loop = trajectory.loop;
    const segments = trajectory.segments;
    const invertedSegments = [...segments]
      .reverse()
      .map((segment) => ({ ...segment, path: [...segment.path].reverse() }));
    const paths = segments.map((segment) => segmentToPath(segment)).filter((path): path is Path => Boolean(path));
    const invertedPaths = invertedSegments
      .map((segment) => segmentToPath(segment))
      .filter((path): path is Path => Boolean(path));

    const getPaths = () => {
      return componentContextRef.current?.playbackDirection === "backward" ? invertedPaths : paths;
    };

    if (paths.length === 0) {
      return;
    }

    const initialRotation = object.rotation.clone();

    const init = {
      update: () => {
        const fsm = finiteStateMachine.current;
        if (!fsm) {
          return;
        }

        if (segments.length === 0) {
          fsm.transition("end");
        }

        fsm.data = {
          fraction: 0,
          segmentIndex: 0,
        };

        fsm.transition("wait");
      },
    };

    const move = {
      enter: () => {
        const fsm = finiteStateMachine.current;
        if (!fsm) {
          return;
        }
        fsm.data.fraction = 0;
      },
      update: () => {
        const fsm = finiteStateMachine.current;
        if (!fsm) {
          return;
        }

        if (fsm.data.fraction > 1) {
          fsm.transition("nextSegment");
        }

        const { route, speed, glued, rotate } = getPaths()[fsm.data.segmentIndex];
        const newPosition = route.getPoint(fsm.data.fraction);
        const newPositionY = calculateNextYPosition(movementSurfaces, object.position, newPosition, glued);

        object.lookAt(newPosition.x, newPositionY, newPosition.z);
        adjustModelRotation(object, initialRotation, rotate);

        object.position.set(newPosition.x, newPositionY, newPosition.z);

        fsm.data.fraction = fsm.data.fraction + speed;

        // @ts-ignore
        if (object.body) {
          // @ts-ignore
          object.body.needUpdate = true;
        }
      },
    };

    const wait = {
      enter: () => {
        const fsm = finiteStateMachine.current;
        if (!fsm) {
          return;
        }

        const { delay } = getPaths()[fsm.data.segmentIndex];
        setTimeout(() => fsm.transition("move"), delay);
      },
    };

    const nextSegment = {
      update: () => {
        const fsm = finiteStateMachine.current;
        if (!fsm) {
          return;
        }

        const numberOfSegments = segments.length;
        const nextSegment = fsm.data.segmentIndex + 1;
        if (fsm.data.segmentIndex === -1) {
          fsm.data.segmentIndex = 0;
          fsm.transition("wait");
        } else if (nextSegment < numberOfSegments) {
          fsm.data.segmentIndex = nextSegment;
          fsm.transition("wait");
        } else if (nextSegment === numberOfSegments && loop) {
          fsm.data.segmentIndex = 0;
          fsm.transition("wait");
        } else {
          fsm.transition("end");
        }
      },
    };

    const end = {
      enter: () => {
        events.emit("finish");
      },
    };

    finiteStateMachine.current = new FiniteStateMachine({ init, move, wait, nextSegment, end }, "init", {
      fraction: 0,
      segmentIndex: 0,
    });
  }, [trajectory, movementSurfaces]);

  useFrame(() => {
    if (componentEnabled && playRef.current) {
      finiteStateMachine.current?.update();
    }
  });

  return null;
}

export default memo(TrajectoryComponent);
