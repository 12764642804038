import WebApp from "@twa-dev/sdk";
import { useEffect } from "react";
import "webrtc-adapter";
import { useSceneData } from "~/common/stores/useSceneData";
import { useFlags } from "~/entities/flags";
import { loadStoragesFx } from "~/entities/storages";
import { NoAccessScreen } from "./NoAccessScreen";
import { usePhysics } from "./physics";
import { RenderScene } from "./RenderScene";
import { initLoadProgressFx, LoadDebugger, LoadingScreen } from "./sceneLoad";
import useSessionStatus from "./stores/useSessionStatus";
import { useDataReset } from "./useDataReset";
import { disableWarnings } from "./utils";
import { WelcomeScreen } from "./welcome-screen";

export type ViewSceneProps = {
  urlName: string | undefined;
};

const ViewScene = ({ urlName }: ViewSceneProps) => {
  const loadData = useSceneData((state) => state.loadData);
  const loadPhysicsEngine = usePhysics((state) => state.load);
  const { loadDebugger } = useFlags();
  useDataReset();

  const {
    loadSupportedModes,
    updateStatus,
    displayLoadingScreen,
    displayWelcomeScene,
    displayNoAccessScreen,
    displayScene,
  } = useSessionStatus((state) => ({
    loadSupportedModes: state.loadSupportedModes,
    updateStatus: state.updateStatus,
    displayLoadingScreen: state.shouldDisplayLoadingScreen(),
    displayWelcomeScene: state.shouldDisplayWelcomeScene(),
    displayNoAccessScreen: state.shouldDisplayNoAccessScreen(),
    displayScene: state.shouldDisplayScene(),
  }));

  useEffect(() => {
    disableWarnings();

    async function initScene(urlName: string) {
      loadSupportedModes();
      await waitForTelegramSDK();
      if (window.Telegram) {
        WebApp.expand();
        WebApp.enableClosingConfirmation();
        if (urlName.includes("hyper")) {
          WebApp.themeParams.header_bg_color = "#000000";
          WebApp.themeParams.secondary_bg_color = "#000000";
          WebApp.themeParams.section_header_text_color = "#FFFFFF";
          WebApp.setBackgroundColor("#FFFFFF");
          WebApp.setHeaderColor("#000000");
        }
      }
      const { scene } = await loadData(urlName);

      if (scene) {
        initLoadProgressFx(scene);
        await Promise.all([loadPhysicsEngine(scene.physicsSystem), loadStoragesFx(scene.workspace._id!)]);
      }

      updateStatus("data_loaded");
    }

    if (!urlName) {
      updateStatus("no_access");
      return;
    }

    initScene(urlName);
  }, []);

  return (
    <>
      {displayScene && <RenderScene />}
      {displayNoAccessScreen && <NoAccessScreen />}
      {displayWelcomeScene && <WelcomeScreen />}
      {displayLoadingScreen && <LoadingScreen />}
      {loadDebugger && <LoadDebugger />}
    </>
  );
};

const waitForTelegramSDK = () => {
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (window.Telegram) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
};

export default ViewScene;
