import { NodeEvalContext } from "~/libs/behave-graph";
import { ComponentType } from "~/types/ComponentType";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";

export class RemoveAllRigidBodiesNode extends BaseNode {
  static readonly type = "physics/removeAllRigidBodies";
  static readonly label = "Remove All Rigid Bodies";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");

    if (!entityContext) {
      return;
    }

    const rigidBodyIds = Object.values(entityContext.components)
      .filter((componentContext) => componentContext.type === ComponentType.RIGID_BODY)
      .map((componentContext) => componentContext.id);

    if (rigidBodyIds.length > 0) {
      node.sceneContext.removeComponents(entityContext.id, rigidBodyIds);
    }
  }
}
