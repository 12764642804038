import { useEffect } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { mute, unmute } from "./models";

export const useMuteOnBlur = () => {
  const audioSystem = useSceneData((state) => state.sceneState?.audioSystem);

  useEffect(() => {
    if (!audioSystem?.muteOnBlur) {
      return;
    }

    const handler = () => {
      if (document.hidden) {
        mute();
      } else {
        unmute();
      }
    };

    document.addEventListener("visibilitychange", handler);

    return () => {
      document.removeEventListener("visibilitychange", handler);
    };
  }, []);
};
