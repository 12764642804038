import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, NodeSocket } from "../../base";
import { FlowSocket } from "../../sockets";
import { VariableBaseNode } from "./VariableBaseNode";

export class SetVariableNode extends VariableBaseNode {
  static type = "variable/setVariable";
  static label = "Set Variable";
  static category: NodeCategory = "Variable";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const variable = this.getVariable(node.spec)!;
    const value = context.readInput("value");
    node.scriptContext.variables.setValue(variable.id, value);
  }

  getInputs(nodeSpec: NodeSpec): NodeSocket[] {
    return [...super.getInputs(nodeSpec), this.getVariableSocket(nodeSpec)];
  }
}
