import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "./componentValue";

export type ConstraintValue = ComponentValue;

export const constraintValue = new ValueType(
  "constraint",
  defaultComponent,
  (json: ConstraintValue) => json,
  (value: ConstraintValue) => value
);
