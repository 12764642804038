import { Icon, IconProps } from "./Icon";

export type TutorialsProps = IconProps;

export const Tutorials = (props: TutorialsProps) => (
  <Icon width="16px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" {...props}>
    <path
      d="M7.99998 13L7.93328 12.8999C7.47018 12.2053 7.23863 11.858 6.93271 11.6065C6.66188 11.3839 6.34982 11.2169 6.01438 11.1151C5.63548 11 5.21805 11 4.3832 11H3.46665C2.71991 11 2.34654 11 2.06133 10.8547C1.81044 10.7268 1.60647 10.5229 1.47864 10.272C1.33331 9.98677 1.33331 9.6134 1.33331 8.86667V3.13333C1.33331 2.3866 1.33331 2.01323 1.47864 1.72801C1.60647 1.47713 1.81044 1.27316 2.06133 1.14532C2.34654 1 2.71991 1 3.46665 1H3.73331C5.22679 1 5.97352 1 6.54395 1.29065C7.04572 1.54631 7.45367 1.95426 7.70933 2.45603C7.99998 3.02646 7.99998 3.77319 7.99998 5.26667M7.99998 13V5.26667M7.99998 13L8.06668 12.8999C8.52978 12.2053 8.76132 11.858 9.06725 11.6065C9.33807 11.3839 9.65014 11.2169 9.98558 11.1151C10.3645 11 10.7819 11 11.6168 11H12.5333C13.28 11 13.6534 11 13.9386 10.8547C14.1895 10.7268 14.3935 10.5229 14.5213 10.272C14.6666 9.98677 14.6666 9.6134 14.6666 8.86667V3.13333C14.6666 2.3866 14.6666 2.01323 14.5213 1.72801C14.3935 1.47713 14.1895 1.27316 13.9386 1.14532C13.6534 1 13.28 1 12.5333 1H12.2666C10.7732 1 10.0264 1 9.456 1.29065C8.95424 1.54631 8.54629 1.95426 8.29063 2.45603C7.99998 3.02646 7.99998 3.77319 7.99998 5.26667"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
