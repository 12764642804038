import { useController, useInteraction } from "@react-three/xr";
import { RefObject, useEffect, useRef, useState } from "react";
import { Object3D } from "three";

export type VrScannerProps = {
  distance: number;
  objectRef: RefObject<Object3D | null>;
  onHover: () => void;
  onBlur: () => void;
};

export const VrScanner = ({ objectRef, ...restProps }: VrScannerProps) => {
  const [refobjectInitialized, setRefObjectInitialized] = useState(Boolean(objectRef.current));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefObjectInitialized(Boolean(objectRef.current));
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (!refobjectInitialized) {
    return null;
  }

  return <VRScannerInner {...restProps} objectRef={objectRef as any} />;
};

type VRScannerInnerProps = {
  distance: number;
  objectRef: RefObject<Object3D>;
  onHover: () => void;
  onBlur: () => void;
};

const VRScannerInner = ({ distance, objectRef, onHover, onBlur }: VRScannerInnerProps) => {
  const hoveredRef = useRef(false);
  const rightController = useController("right");
  const rightControllerRef = useRef(rightController);
  rightControllerRef.current = rightController;

  // @ts-ignore
  useInteraction(objectRef, "onHover", (event) => {
    if (
      event.target === rightControllerRef.current &&
      event.intersections.length > 0 &&
      event.intersections[0].distance <= distance &&
      !hoveredRef.current
    ) {
      hoveredRef.current = true;
      onHover();
    }
  });

  // @ts-ignore
  useInteraction(objectRef, "onBlur", (event) => {
    if (event.target === rightControllerRef.current && hoveredRef.current) {
      hoveredRef.current = false;
      onBlur();
    }
  });

  return null;
};
