import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { BaseBehaveNode, BaseSocket, BaseNode, NodeContext } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";
import { EntityValue } from "../../values";

export class CastToNode extends BaseNode {
  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];

  constructor(context: NodeContext, private socket: BaseSocket, private contextType: string) {
    super(context);
    this.outputs = [new FlowSocket("success"), new FlowSocket("fail"), socket];
  }

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = null;

    const entityValue = context.readInput<EntityValue>("entity");
    const entityContext = node.sceneContext.getEntityContext(entityValue.entityId ?? "");

    if (!entityContext || entityContext.type !== this.contextType) {
      context.writeOutput(this.socket.name, node.storage.output);
      context.commit("fail");
      return;
    }

    node.storage.output.entityId = entityValue.entityId;
    context.writeOutput(this.socket.name, node.storage.output);
    context.commit("success");
  }
}
