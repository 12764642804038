import { Suspense, lazy } from "react";

const DesktopInventory = lazy(() =>
  import("./DesktopInventory").then((module) => ({ default: module.DesktopInventory }))
);

export const DesktopInventoryLazy = () => {
  return (
    <Suspense fallback={null}>
      <DesktopInventory />
    </Suspense>
  );
};
