import { Variable } from "~/types/Variable";
import {
  ActionableSocket,
  AnimationSocket,
  AnySocket,
  ArraySocket,
  AssetMaterialSocket,
  AssetModelSocket,
  AssetSocket,
  AudioSocket,
  BooleanSocket,
  ComponentSocket,
  ConstraintSocket,
  EntitySocket,
  EventSocket,
  FollowTargetSocket,
  LightSocket,
  MapSocket,
  ModelSocket,
  NumberSocket,
  SceneSocket,
  StringSocket,
  StructSocket,
  TextSocket,
  TrajectorySocket,
  UIBlockSocket,
  UIEntitySocket,
  UIImageSocket,
  UITextSocket,
  Vector3Socket,
  VideoSocket,
} from "../sockets";

export const variableToSocket = (variable: Variable, id = "value") => {
  switch (variable.type) {
    case "actionable":
      return new ActionableSocket(id, variable.name, variable.initialValue);
    case "animation":
      return new AnimationSocket(id, variable.name, variable.initialValue);
    case "asset":
      return new AssetSocket(id, variable.name, variable.initialValue);
    case "assetMaterial":
      return new AssetMaterialSocket(id, variable.name, variable.initialValue);
    case "assetModel":
      return new AssetModelSocket(id, variable.name, variable.initialValue);
    case "audio":
      return new AudioSocket(id, variable.name, variable.initialValue);
    case "boolean":
      return new BooleanSocket(id, variable.name, variable.initialValue);
    case "component":
      return new ComponentSocket(id, variable.name, variable.initialValue);
    case "constraint":
      return new ConstraintSocket(id, variable.name, variable.initialValue);
    case "number":
      return new NumberSocket(id, variable.name, variable.initialValue);
    case "entity":
      return new EntitySocket(id, variable.name, variable.initialValue);
    case "event":
      return new EventSocket(id, variable.name, variable.initialValue);
    case "followTarget":
      return new FollowTargetSocket(id, variable.name, variable.initialValue);
    case "string":
      return new StringSocket(id, variable.name, variable.initialValue);
    case "vector3":
      return new Vector3Socket(id, variable.name, variable.initialValue);
    case "any":
      return new AnySocket(id, variable.name, variable.initialValue);
    case "array":
      return new ArraySocket(id, variable.valueType, variable.name, variable.initialValue);
    case "map":
      return new MapSocket(id, variable.valueType, variable.name, variable.initialValue);
    case "light":
      return new LightSocket(id, variable.name, variable.initialValue);
    case "model":
      return new ModelSocket(id, variable.name, variable.initialValue);
    case "scene":
      return new SceneSocket(id, variable.name, variable.initialValue);
    case "text":
      return new TextSocket(id, variable.name, variable.initialValue);
    case "trajectory":
      return new TrajectorySocket(id, variable.name, variable.initialValue);
    case "video":
      return new VideoSocket(id, variable.name, variable.initialValue);
    case "struct":
      return new StructSocket(id, variable.name, variable.initialValue);
    case "uiEntity":
      return new UIEntitySocket(id, variable.name, variable.initialValue);
    case "uiBlock":
      return new UIBlockSocket(id, variable.name, variable.initialValue);
    case "uiImage":
      return new UIImageSocket(id, variable.name, variable.initialValue);
    case "uiText":
      return new UITextSocket(id, variable.name, variable.initialValue);
  }
};
