import { memo } from "react";
import { useThree } from "@react-three/fiber";
import { Color } from "three";
import IBackgroundColor from "../../types/IBackgroundColor";

type SceneBackgroundColorProps = {
  dto: IBackgroundColor;
};

export const SceneBackgroundColor = memo(({ dto }: SceneBackgroundColorProps) => {
  const { scene } = useThree();

  const { enabled, color } = dto;

  if (enabled && color) {
    scene.background = new Color(color);
  } else if (scene.background instanceof Color) {
    scene.background = null;
  }

  return null;
});
