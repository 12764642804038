import { Icon, IconProps } from "./Icon";

export type NoSpaceProps = IconProps;

export const NoSpace = (props: NoSpaceProps) => (
  <Icon width="109px" height="110px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109 110" {...props}>
    <path
      d="M107.833 55H97.1667M107.833 55C107.833 84.4552 83.9552 108.333 54.5 108.333M107.833 55C107.833 25.5448 83.9552 1.66667 54.5 1.66667M92.2124 92.7124L84.6699 85.1699M11.8333 55H1.16667M1.16667 55C1.16667 84.4552 25.0448 108.333 54.5 108.333M1.16667 55C1.16667 25.5448 25.0448 1.66667 54.5 1.66667M24.3301 24.8301L16.7876 17.2876M54.5 12.3333V1.66667M84.6699 24.8301L92.2124 17.2876M54.5 108.333V97.6667M16.7876 92.7124L24.3301 85.1699M54.5 33.6667L75.8333 55L54.5 76.3333L33.1667 55L54.5 33.6667Z"
      stroke="white"
      strokeOpacity="0.3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
