import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, StringSocket } from "../../sockets";

export class StringToNumberNode extends BaseNode {
  static readonly type = "logic/stringToNumber";
  static readonly label = "String To Number";

  inputs = [new StringSocket("string")];
  outputs = [new NumberSocket("value", "number")];

  eval(context: NodeEvalContext) {
    const value = context.readInput<string>("string");

    context.writeOutput<number>("value", parseFloat(value));
  }
}
