import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, NumberSocket } from "../../sockets";
import { AnimationComponentContext } from "~/view-scene/runtime";

export class AnimationTimeNode extends BaseNode {
  static readonly type = "action/animationTime";
  static readonly label = "Animation Time";
  static readonly pure = true;

  inputs = [new AnimationSocket("animation")];
  outputs = [new NumberSocket("time", "time (sec)")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const animationComponent = node.readComponent<AnimationComponentContext>(context, "animation");

    context.writeOutput<number>("time", animationComponent?.duration ?? 0);
  }
}
