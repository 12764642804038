import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class HeaderColorNode extends BaseNode {
  static readonly type = "telegram/headerColor";
  static readonly label = "Telegram Header Color";

  inputs = [new FlowSocket("flow"), new StringSocket("color")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext) {
    window.Telegram?.WebApp.setHeaderColor(context.readInput<string>("color"));
  }
}
