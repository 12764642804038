import { useEffect } from "react";

export const DisableMouseScroll = () => {
  useEffect(() => {
    const preventDefault = (e: Event) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    // disableScroll
    document.body.style.touchAction = "none";
    document.body.style.overflow = "hidden";
    document.addEventListener("touchmove", preventDefault);

    // disable pinch zoom
    document.addEventListener("gesturestart", preventDefault);
    document.addEventListener("gesturechange", preventDefault);
    document.addEventListener("gestureend", preventDefault);

    const root = document.getElementById("root");
    if (root) {
      root.style.touchAction = "none";
      root.style.overflow = "hidden";
      root.addEventListener("touchmove", preventDefault);

      // disable pinch zoom
      root.addEventListener("gesturestart", preventDefault);
      root.addEventListener("gesturechange", preventDefault);
      root.addEventListener("gestureend", preventDefault);
    }

    return () => {
      document.removeEventListener("touchmove", preventDefault);
      document.removeEventListener("gesturestart", preventDefault);
      document.removeEventListener("gesturechange", preventDefault);
      document.removeEventListener("gestureend", preventDefault);
    };
  }, []);

  return null;
};
