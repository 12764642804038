import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { Vector3Socket } from "../../sockets";

export class Vector3ConstantNode extends BaseNode {
  static readonly type = "logic/vector3Constant";
  static readonly label = "Vector 3";
  static readonly category = "Variable";

  inputs = [new Vector3Socket("a")];
  outputs = [new Vector3Socket("value")];

  eval(context: NodeEvalContext) {
    context.writeOutput("value", context.readInput("a"));
  }
}
