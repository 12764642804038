import React from "react";
import { Stack, StackProps } from "@mui/material";
import { Label, LabelProps } from "./Label";
import { HelpHint } from "./HelpHint";

export type LabelGroupWithHintProps = LabelProps &
  StackProps & {
    labelMaxWidth?: string;
    LabelProps?: Omit<LabelProps, "label">;
  };

export function LabelGroupWithHint({
  label,
  LabelProps,
  children,
  labelMaxWidth = "60px",
  sx,
}: LabelGroupWithHintProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: "4px 10px 4px 10px",
        gridGap: "10px",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          gridGap: "8px",
          flex: `0 0 ${labelMaxWidth}`,
        }}
      >
        <Label label={label} {...LabelProps} />
        <HelpHint />
      </Stack>
      {children}
    </Stack>
  );
}
