import { useEffect, useRef } from "react";
import { Box, FormControl, MenuItem, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { useProfile } from "~/entities/profile";
import { useOnScreen } from "~/common/hooks/useOnScreen";
import Avatar from "~/common/components/Avatar";
import AttentionAlert from "~/common/components/AttentionAlert";
import { AvatarPreview } from "../components/AvatarPreview";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Plus } from "~/common/components/Icon";
import Select from "~/common/components/Select";
import { updateMySex } from "~/api/profile.api";
import { useNavigate } from "react-router-dom";

export type ProfileAvatarProps = {
  onScreen: VoidFunction;
};

export const ProfileAvatar = ({ onScreen }: ProfileAvatarProps) => {
  const { profile, loadProfile } = useProfile((state) => ({
    profile: state.profile,
    loadProfile: state.loadProfile,
  }));

  const navigate = useNavigate();

  const handleOpenEditAvatar = () => navigate("/profile/avatar");

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      onScreen();
    }
  }, [isVisible]);

  const handleSexChange = async (event: any) => {
    const sex = event.target.value;

    const { status } = await updateMySex(sex);
    if (status === 200) {
      await loadProfile();
    }
  };

  if (!profile) {
    return null;
  }

  const { avatarUrl, avatarPreview, faceAnimationEnabled } = profile;

  const hasAvatar = avatarUrl != null;

  return (
    <>
      <Box
        component="div"
        id="profile-avatar"
        ref={ref}
        sx={(theme) => ({
          border: "1px solid " + palette.primary.gray100,
          backgroundColor: palette.primary.black400,
          padding: "12px 12px 24px 24px",
          borderRadius: "20px",
          [theme.breakpoints.down("sm")]: {
            padding: "20px 12px 24px 24px",
          },
        })}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              flexWrap: "wrap",
              gridGap: "12px",
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24px",
              color: palette.primary.gray600,
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                lineHeight: "22px",
              },
            })}
            variant="body1"
          >
            Avatar
          </Typography>
          <Avatar avatar={avatarPreview} text={profile.name ?? ""} />
        </Stack>
        <Box
          component="div"
          sx={(theme) => ({
            border: "1px solid " + palette.primary.gray200,
            width: "200px",
            height: "200px",
            display: "block",
            position: "relative",
            overflow: "hidden",
            margin: "16px auto 0",
            objectFit: "contain",
            borderRadius: "50%",
            backgroundColor: palette.primary.gray100,
            transition: ".4s ease",
            opacity: "0.9",
            "&:hover": {
              transform: "scale(1.04)",
              opacity: "1",
            },
            [theme.breakpoints.down("lg")]: {
              margin: "24px auto 0",
            },
          })}
        >
          {!hasAvatar ? (
            <Box
              component="div"
              style={{
                border: "1px solid " + palette.primary.gray200,
                width: "200px",
                height: "200px",
                position: "absolute",
                top: "-1px",
                left: "-1px",
                borderRadius: "50%",
                display: "block",
                backgroundColor: palette.primary.gray100,
              }}
            >
              <IconButton
                onClick={handleOpenEditAvatar}
                sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
              >
                <Plus />
              </IconButton>
            </Box>
          ) : (
            <Box
              onClick={handleOpenEditAvatar}
              component="div"
              sx={(theme) => ({
                width: "200px",
                borderRadius: "50%",
                height: "200px",
                position: "relative",
              })}
            >
              <Box
                component="div"
                sx={{
                  cursor: "pointer",
                  width: "200px",
                  height: "200px",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              >
                <AvatarPreview avatarUrl={avatarUrl} />
              </Box>
            </Box>
          )}
        </Box>

        <Typography
          sx={(theme) => ({
            color: palette.primary.gray600,
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "12px",
            alignItems: "flex-start",
            fontWeight: "500",
          })}
        >
          Tap on the avatar to edit it.
        </Typography>

        {!hasAvatar && (
          <AttentionAlert
            sx={{
              marginTop: "24px",
            }}
          >
            To personalize your experience, add a custom avatar with Ready Player Me. If you don't add one, the default
            avatar will be used.
          </AttentionAlert>
        )}

        {hasAvatar && (
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" paddingTop={1}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                color: palette.primary.gray600,
              }}
              variant="body1"
            >
              Avatar sex
            </Typography>
            <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
              <FormControl fullWidth>
                <Select
                  value={profile.sex ?? "male"}
                  onChange={handleSexChange}
                  sx={{
                    height: "40px",
                    fonts: "16px",
                    border: "1px solid " + palette.primary.gray400,
                    backgroundColor: palette.primary.gray100,
                  }}
                >
                  <MenuItem value="male" key="male">
                    Male
                  </MenuItem>
                  <MenuItem value="female" key="female">
                    Female
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
};
