import { getAudioDeviceStream, getScreenStream, stopStream } from "./utils";
import create from "zustand";

export type UseMediaStreamStoreType = {
  streamContainers: { [name: string]: { stream: MediaStream | null } };

  getStream: (name: string | undefined | null) => void;
  removeStream: (name: string | undefined | null) => void;

  requestScreenStream: VoidFunction;
};

type NullableName = string | undefined | null;

export const SCREEN_STREAM_KEY = "screen-stream";

export const useMediaStreamStore = create<UseMediaStreamStoreType>((set, get) => ({
  streamContainers: {},

  getStream: async (name: NullableName) => {
    const key = getKey(name);
    const container = get().streamContainers[key];

    // stream already fetched or in process of fetching
    if (container) {
      return;
    }

    set({ streamContainers: { ...get().streamContainers, [key]: { stream: null } } });
    const stream = await getAudioDeviceStream(name);

    // if no one remove
    if (get().streamContainers[key]) {
      set({ streamContainers: { ...get().streamContainers, [key]: { stream } } });
    }
  },

  removeStream: (name: NullableName) => {
    const key = getKey(name);
    const container = get().streamContainers[key];

    if (container) {
      if (container.stream) {
        stopStream(container.stream);
      }

      const streamContainers = { ...get().streamContainers };
      delete streamContainers[key];

      set({ streamContainers });
    }
  },

  requestScreenStream: async () => {
    const container = get().streamContainers[SCREEN_STREAM_KEY];

    // TODO case if user stopped the stream manually in the browser. Change to regular check with stream removal and event instead.
    if (container && container.stream && container.stream.active) {
      return;
    }

    set({ streamContainers: { ...get().streamContainers, [SCREEN_STREAM_KEY]: { stream: null } } });
    const stream = await getScreenStream();

    // if no one remove
    if (get().streamContainers[SCREEN_STREAM_KEY]) {
      set({ streamContainers: { ...get().streamContainers, [SCREEN_STREAM_KEY]: { stream } } });
    }
  },
}));

const getKey = (name: NullableName) => {
  return name ? name : "__default__";
};

export const micStreamSelector = (name: NullableName) => (state: UseMediaStreamStoreType) => {
  const key = getKey(name);

  return state.streamContainers[key]?.stream ?? null;
};

export const screenStreamSelector = (state: UseMediaStreamStoreType) => {
  return state.streamContainers[SCREEN_STREAM_KEY]?.stream ?? null;
};
