import {Object3D, Raycaster, Vector3} from "three";

const down = new Vector3(0, -1, 0);
const raycaster = new Raycaster();
raycaster.firstHitOnly = true;

export default function calculateNextYPosition(movementSurfaces: Object3D[], currentPosition: Vector3,
                                               newPosition: Vector3, isGlued: boolean) {
  if (!isGlued) {
    return newPosition.y;
  }

  raycaster.set(newPosition, down);
  const intersects = raycaster.intersectObjects(movementSurfaces, true);
  if (intersects.length === 0) {
    return newPosition.y;
  }

  let shortestPath = Number.MAX_VALUE;
  let bestIntersect;

  intersects.forEach(intersect => {
    const distance = Math.abs(intersect.point.y - currentPosition.y);
    if (distance < shortestPath) {
      bestIntersect = intersect;
      shortestPath = distance;
    }
  })
  // @ts-ignore
  return bestIntersect.point.y;
}