import type { Components, ThemeOptions } from "@mui/material";
import { palette } from "../../palette";

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}

export const MuiInputBase: Components<ThemeOptions>["MuiInputBase"] = {
  styleOverrides: {
    root: {
      color: palette.primary.gray600,
      background: palette.primary.gray20,
      border: `1px solid ${palette.primary.gray20}`,

      "&:hover": {
        borderColor: palette.primary.gray200,
      },

      "&.Mui-focused": {
        borderColor: palette.primary.violet,
      },

      "&.Mui-error": {
        borderColor: palette.primary.red200,
        background: palette.primary.red100,
      },

      "&.Mui-disabled": {
        borderColor: palette.primary.gray20,
        color: palette.primary.gray200,
      },

      "&.MuiInputBase-adornedStart": {
        paddingLeft: "6px",
      },

      "&.MuiInputBase-adornedEnd": {
        paddingRight: "6px",
      },

      "&.MuiInputBase-multiline": {
        padding: 0,
      },
    },
    input: {
      "&::placeholder": {
        color: palette.primary.gray400,
      },
    },
  },
  variants: [
    {
      props: { size: "medium" },
      style: {
        minHeight: "28px",
      },
    },
    {
      props: { size: "small" },
      style: {
        minHeight: "24px",
      },
    },
    {
      props: { size: "large" },
      style: {
        fontSize: "16px",
        minHeight: "40px",

        "& .MuiInputBase-input": {
          padding: "8px 16px",
        },
      },
    },
  ],
};
