import {
  applyArrayValue,
  applyAssetValue,
  applyComponentValue,
  applyEntityValue,
  applyEventValue,
  applyMapValue,
  applySceneValue,
  applyStructValue,
  applyUIEntityValue,
  setVector3ValueFromXYZ,
} from "~/entities/variable";
import { NodeJSON } from "~/libs/behave-graph";
import { ScriptArgument } from "~/types/IScriptComponent";
import { ScriptVariable } from "~/types/ScriptAsset";
import {
  ArrayValue,
  AssetValue,
  ComponentValue,
  EntityValue,
  EventValue,
  MapValue,
  SceneValue,
  StructValue,
  UIEntityValue,
  Variable,
  Vector3Value,
} from "~/types/Variable";

export const isVariablePublic = (variable: ScriptVariable) => {
  return variable.public ?? true; // for back compatibility. If public === undefined we decide it's public
};

export const isArgumentOverride = (argument: ScriptArgument | undefined) => {
  if (!argument) {
    return false;
  }

  return argument.override ?? true; // for back compatibility. If public === undefined we decide it's public
};

export const getNodeVariable = (node: NodeJSON) => {
  return ((node.metadata?.parameter ?? node.metadata?.variable) as any as ScriptVariable | undefined) ?? null;
};

export const getValueSetter = (variable: ScriptVariable) => {
  return valueSetters[variable.type];
};

const defaultSetter = function (this: any, value: any) {
  this.value = value;

  return this.value;
};

const vectorSetter = function (this: any, value: Vector3Value) {
  return setVector3ValueFromXYZ(this.value, value);
};

const arraySetter = function (this: any, value: ArrayValue) {
  return applyArrayValue(this.value, value);
};

const mapSetter = function (this: any, value: MapValue) {
  return applyMapValue(this.value, value);
};

const entitySetter = function (this: any, value: EntityValue) {
  return applyEntityValue(this.value, value);
};

const eventSetter = function (this: any, value: EventValue) {
  return applyEventValue(this.value, value);
};

const componentSetter = function (this: any, value: ComponentValue) {
  return applyComponentValue(this.value, value);
};

const assetSetter = function (this: any, value: AssetValue) {
  return applyAssetValue(this.value, value);
};

const sceneSetter = function (this: any, value: SceneValue) {
  return applySceneValue(this.value, value);
};

const uiEntitySetter = function (this: any, value: UIEntityValue) {
  return applyUIEntityValue(this.value, value);
};

const structSetter = function (this: any, value: StructValue) {
  return applyStructValue(this.value, value);
};

const valueSetters: Record<Variable["type"], (v: any) => any> = {
  boolean: defaultSetter,
  number: defaultSetter,
  string: defaultSetter,
  vector3: vectorSetter,
  any: defaultSetter,
  array: arraySetter,
  map: mapSetter,

  entity: entitySetter,
  audio: entitySetter,
  model: entitySetter,
  video: entitySetter,
  text: entitySetter,
  light: entitySetter,

  component: componentSetter,
  actionable: componentSetter,
  animation: componentSetter,
  trajectory: componentSetter,
  followTarget: componentSetter,
  constraint: componentSetter,

  asset: assetSetter,
  assetMaterial: assetSetter,
  assetModel: assetSetter,

  scene: sceneSetter,

  uiEntity: uiEntitySetter,
  uiBlock: uiEntitySetter,
  uiImage: uiEntitySetter,
  uiText: uiEntitySetter,

  struct: structSetter,

  event: eventSetter,
};
