import { forward, sample } from "effector";
import { editorHotkey } from "~/hephaestus/hotkeys";
import { deleteEntityAndUnselect } from "./deleteEntity";
import { duplicateEntity } from "./duplicateEntity";
import { $selectedEntity, cancelEntitySelection } from "./selectEntity";

sample({
  source: $selectedEntity,
  clock: editorHotkey.duplicate.filter({ fn: (event) => event.pressed }),
  filter: (entity) => Boolean(entity?.id),
  fn: (entity) => entity!.id!,
  target: duplicateEntity,
});

sample({
  source: $selectedEntity,
  clock: editorHotkey.remove.filter({ fn: (event) => event.pressed }),
  filter: (entity) => Boolean(entity?.id),
  fn: (entity) => entity!.id!,
  target: deleteEntityAndUnselect,
});

forward({
  from: editorHotkey.cancelSelection.filter({ fn: (event) => event.pressed }),
  to: cancelEntitySelection,
});
