import { memo, useEffect, useRef } from "react";
import { Group } from "three";
import { useUnit } from "effector-react";
import { Text } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { debugControls } from "~/view-scene/ControlsSystem";
import { calcPositionInfront } from "~/common/utils/calcPositionInfront";
import { $text } from "./models";

export const SceneConsole = memo(() => {
  const camera = useThree((state) => state.camera);
  const ref = useRef<Group>(null);
  const text = useUnit($text);

  useEffect(() => {
    const handler = () => {
      if (!ref.current) {
        return;
      }

      if (ref.current.visible) {
        ref.current.visible = false;
      } else {
        const position = calcPositionInfront(camera, 2);
        ref.current.position.set(position.x, position.y, position.z);
        ref.current.visible = true;
      }
    };

    debugControls.console.on("start", handler);

    return () => {
      debugControls.console.off("start", handler);
    };
  }, [camera]);

  return (
    <group ref={ref} visible={false}>
      <Text color="red" fontSize={0.1}>
        {text}
      </Text>
    </group>
  );
});
