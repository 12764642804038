import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type LightValue = EntityValue;

export const lightValue = new ValueType(
  "light",
  defaultEntity,
  (text: LightValue) => text,
  (value: LightValue) => value
);
