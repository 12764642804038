import { Object3D, Quaternion, Vector3 } from "three";

const tmpPosition = new Vector3();
const tmpRotation = new Quaternion();
const tmpScale = new Vector3();

export const copyTransformation = (source: Object3D, target: Object3D) => {
  source.getWorldPosition(tmpPosition);
  source.getWorldQuaternion(tmpRotation);
  source.getWorldScale(tmpScale);

  target.position.set(tmpPosition.x, tmpPosition.y, tmpPosition.z);
  target.rotation.setFromQuaternion(tmpRotation);
  target.scale.set(tmpScale.x, tmpScale.y, tmpScale.z);
};
