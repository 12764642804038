import { NodeEvalContext } from "~/libs/behave-graph";
import { CameraContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class CameraAttachToNode extends BaseNode {
  static readonly type = "action/cameraAttachTo";
  static readonly label = "Camera Attach To";

  inputs = [
    new FlowSocket("flow"),
    new EntitySocket("target"),
    new Vector3Socket("position"),
    new Vector3Socket("rotation"),
  ];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const cameraContext = node.sceneContext.getEntityContext<CameraContext>("camera");
    const target = node.readEntity(context, "target")?.rootObjectRef.current;
    const position = context.readInput<Vector3Value>("position");
    const rotation = context.readInput<Vector3Value>("rotation");

    if (!cameraContext || !target) {
      return;
    }

    cameraContext.setEnabled(false);
    cameraContext.attachTo(target);
    cameraContext.rootObjectRef.current?.position.set(position.x, position.y, position.z);
    cameraContext.rootObjectRef.current?.rotation.set(rotation.x, rotation.y, rotation.z);
  }
}
