import { Select, SelectItem, SelectProps } from "./Select";

export type RigidBodySelectProps = Omit<SelectProps, "children">;

export const RigidBodySelect = (props: RigidBodySelectProps) => {
  return (
    <Select {...props}>
      <SelectItem value="static">Static</SelectItem>
      <SelectItem value="kinematic">Kinematic</SelectItem>
      <SelectItem value="dynamic">Dynamic</SelectItem>
    </Select>
  );
};
