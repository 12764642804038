import { defaultComponent } from "~/entities/variable";
import { BaseSocket } from "../base";
import { actionableValue, ActionableValue } from "../values";

export class ActionableSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: ActionableValue = defaultComponent()) {
    super(actionableValue.name, id, label, value);
  }

  clone() {
    return new ActionableSocket(this.id, this.label, this.value);
  }
}
