import { ValueType } from "~/libs/behave-graph";
import { defaultUIEntity } from "~/entities/variable";
import { UIEntityValue } from "./uiEntityValue";

export type UITextValue = UIEntityValue;

export const uiTextValue = new ValueType(
  "uiText",
  defaultUIEntity,
  (text: UITextValue) => text,
  (value: UITextValue) => value
);
