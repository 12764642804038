import { Box, FormControl, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { palette } from "~/theme";
import { TextField } from "~/hephaestus/components/TextField";
import { Button } from "~/hephaestus/components/Button";
import { Modal } from "~/common/components/Modal";
import * as authApi from "~/api/auth.api";
import { resetPassword } from "~/api/auth.api";
import { useSearchParams } from "react-router-dom";

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(/^.{6,}$/, "Password must contain at least 6 characters.")
    .required("Password is required"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Repeat your password"),
});

export function ResetPasswordModal() {
  const [resetRequestSubmitted, setResetRequestSubmitted] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const open = searchParams.get("resetPassword") === "show" && searchParams.get("code") != null;

  const handleClose = () => {
    searchParams.delete("resetPassword");
    searchParams.delete("code");
    setSearchParams(searchParams);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { password } = values;
      const code = searchParams.get("code");
      const { status } = await authApi.resetPassword({ code, password });
      if (status !== 200) {
        setIsResetSuccess(false);
      }
      setResetRequestSubmitted(true);
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
      setResetRequestSubmitted(false);
      setIsResetSuccess(true);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} title="Reset Password">
      {!resetRequestSubmitted ? (
        <form onSubmit={formik.handleSubmit}>
          <Typography
            color={palette.primary.gray800}
            sx={{
              fontWeight: "500",
              marginBottom: "24px",
              color: palette.primary.gray800,
            }}
            variant="body2"
          >
            Please, enter a new password
          </Typography>
          <Stack direction="column" gap="23px">
            <FormControl fullWidth error={formik.touched.password && Boolean(formik.errors.password)}>
              <TextField
                fullWidth
                type="password"
                name="password"
                size="large"
                placeholder="Password"
                autoComplete="new-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
            <FormControl fullWidth error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}>
              <TextField
                fullWidth
                type="password"
                size="large"
                name="repeatPassword"
                placeholder="Repeat Password"
                autoComplete="new-password"
                value={formik.values.repeatPassword}
                onChange={formik.handleChange}
                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
              />
            </FormControl>
          </Stack>
          <Box component="div" marginTop="36px">
            <Button type="submit" variant="accent" size="large" fullWidth>
              Reset Password
            </Button>
          </Box>
        </form>
      ) : (
        <Typography
          color={palette.primary.gray800}
          sx={{
            fontWeight: "500",
            marginBottom: "24px",
          }}
          variant="body2"
        >
          {isResetSuccess ? (
            <>
              Success! Your password has been updated.
              <br />
              <br />
              Now you can log in with your new password and the email address you used for the registration.
            </>
          ) : (
            <>
              Success! Your password has been updated.
              <br />
              <br />
              Now you can log in with your new password and the email address you used for the registration.
            </>
          )}
        </Typography>
      )}
    </Modal>
  );
}
