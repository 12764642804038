import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";

/**
 * @deprecated use PositionNode
 */
export class GetPositionNode extends BaseNode {
  static readonly type = "action/getPosition";
  static readonly label = "Get position";
  static readonly deprecated = true;

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow"), new Vector3Socket("position")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const position = node.readEntity(context, "entity")?.rootObjectRef.current?.position;

    if (position) {
      setVector3ValueFromXYZ(node.storage.output, position);
    }

    context.writeOutput<Vector3Value>("position", node.storage.output);
  }
}
