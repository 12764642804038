import { Box, BoxProps, styled, Typography } from "@mui/material";
import { Discord } from "~/common/components/Discord";
import { Instagram } from "~/common/components/Instagram";
import { Twitter } from "~/common/components/Twitter";
import { Telegram } from "~/common/components/Telegram";
import { Logo } from "./Logo";

export type FooterProps = Pick<BoxProps, "className" | "sx">;

export const Footer = (props: FooterProps) => {
  return (
    <Container {...props}>
      <LogoSlot>
        <Logo />
        <Typography
          sx={{ marginLeft: "10px" }}
          color="white"
          fontSize="16px"
          fontWeight="700"
          fontFamily="Plus Jakarta Sans"
        >
          8XR {new Date().getFullYear()}
        </Typography>
      </LogoSlot>
      <SocialSlot>
        <Telegram sx={{ width: "30px", height: "auto" }} />
        <Twitter sx={{ width: "30px", height: "auto" }} />
        <Instagram sx={{ width: "30px", height: "auto" }} />
        <Discord sx={{ width: "28px", height: "auto" }} />
      </SocialSlot>
      <AboutSlot>
        <a href="https://engine.8xr.io/">
          <Typography
            fontSize="16px"
            fontWeight="700"
            fontFamily="Plus Jakarta Sans"
            sx={{ textDecoration: "underline" }}
          >
            About 8XR Engine
          </Typography>
        </a>
      </AboutSlot>
    </Container>
  );
};

const Container = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-areas: "logo about social";

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "social social"
      "logo about";

    padding: 0 8px;
  }
`;

const LogoSlot = styled(Box)`
  grid-area: logo;
  display: flex;
  align-items: center;
`;

const SocialSlot = styled(Box)`
  grid-area: social;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const AboutSlot = styled(Box)`
  grid-area: about;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: flex-end;
  }
`;
