import { Suspense, useEffect, useRef } from "react";
import { cutsceneControls } from "~/view-scene/ControlsSystem";
import { cutsceneLayers, Layer } from "~/view-scene/layers";
import { exitCutscene } from "~/view-scene/runtime";
import { Euler, Group, Vector3 } from "three";
import { $cameraState, CameraStateType, setCameraState } from "~/view-scene/CameraSystem";
import { useThree } from "@react-three/fiber";
import { RenderLoader } from "./RenderLoader";
import { PLAYER_HEIGHT } from "~/config";
import { RenderCutscene } from "./RenderCutscene";

export type CutsceneProps = {
  url: string;
};

type ExtendedCameraState = {
  cameraState: CameraStateType;
  position: Vector3;
  rotation: Euler;
};

export const Cutscene = ({ url }: CutsceneProps) => {
  const camera = useThree((state) => state.camera);
  const playerPositionRef = useRef<Group>(null);
  const previousCameraStateRef = useRef<null | ExtendedCameraState>(null);

  useEffect(() => {
    const playerPosition = playerPositionRef.current;
    if (playerPosition) {
      previousCameraStateRef.current = {
        cameraState: $cameraState.getState(),
        position: camera.position.clone(),
        rotation: camera.rotation.clone(),
      };
      setCameraState({
        theta: 0,
        phi: 0,
        enabled: true,
        target: playerPosition,
      });
    }

    cutsceneControls.exit.on("fire", exitCutscene);

    return () => {
      cutsceneControls.exit.off("fire", exitCutscene);
      const previousCameraState = previousCameraStateRef.current;
      if (previousCameraState) {
        setCameraState(previousCameraState.cameraState);
        camera.position.copy(previousCameraState.position);
        camera.rotation.copy(previousCameraState.rotation);
        previousCameraStateRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <group position={[0, PLAYER_HEIGHT / 2, -12]} layers={Layer.cutscene}>
            <RenderLoader layers={cutsceneLayers} />
          </group>
        }
      >
        <RenderCutscene url={url} layers={cutsceneLayers} />
      </Suspense>
      <group ref={playerPositionRef} />
    </>
  );
};
