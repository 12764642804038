import { InstantAction, userActions } from "./userActions";
import { BaseControls } from "./baseControls";

export class CutsceneControls extends BaseControls {
  exit = new InstantAction();

  unSubscriptions: (() => void)[] = [];

  activate() {
    this.unSubscriptions.push(
      this.pipeAction(userActions.exit, this.exit),
      this.pipeAction(userActions.keyQ, this.exit)
    );
  }

  deactivate() {
    for (const unsubscribe of this.unSubscriptions) {
      unsubscribe();
    }
  }

  update() {}

  reset() {}
}

export const cutsceneControls = new CutsceneControls();
