import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket, NumberSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";
import { DirectionEvent } from "../../ScriptEvents";

export class CameraControlsNode extends BaseNode {
  static readonly type = "event/cameraControls";
  static readonly label = "Camera Controls";
  static category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow"), new NumberSocket("forward"), new NumberSocket("side")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onCameraControlsEvent = (value: DirectionEvent) => {
      context.writeOutput("forward", value.forward);
      context.writeOutput("side", value.side);
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.cameraControls.addListener(onCameraControlsEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.cameraControls.removeListener(onCameraControlsEvent);
    });
  }
}
