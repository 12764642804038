import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, StringSocket } from "../../sockets";

export class NumberToStringNode extends BaseNode {
  static readonly type = "logic/numberToString";
  static readonly label = "Number To String";
  static readonly category: NodeCategory = "Logic";

  inputs = [new NumberSocket("value")];
  outputs = [new StringSocket("string")];

  eval(context: NodeEvalContext) {
    const value = context.readInput<number>("value");

    context.writeOutput<string>("string", value.toString());
  }
}
