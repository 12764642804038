import { useFrame, useThree } from "@react-three/fiber";
import { memo, RefObject, useEffect, useRef } from "react";
import { Euler, Group, Object3D, Vector3 } from "three";
import { ComponentType } from "~/types/ComponentType";
import IFollowTargetComponent from "~/types/IFollowTargetComponent";
import { usePlayer } from "~/view-scene/player";
import {
  EntityContext,
  FollowTargetComponentContext,
  useComponentContext,
  useEntityManager,
} from "~/view-scene/runtime";
import { usePhysics } from "../../physics/usePhysics";
import { getDistance } from "../../utils/getDistance";
import useTriggerZone from "../../utils/useTriggerZone";
import calculateNextYPosition from "../trajectory/calculateNextYPosition";

type FollowTargetComponentProps = {
  componentDto: IFollowTargetComponent;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

function FollowTargetComponent({ componentDto, objectRef, contextRef }: FollowTargetComponentProps) {
  const {
    target,
    sceneObjectId,
    enabled,
    speed,
    glued,
    rotate,
    triggerZone: triggerZoneId,
    rotateOnly,
    autoplay,
  } = componentDto;

  const getEntityContext = useEntityManager((state) => state.getEntityContext);
  const sceneObjectIdRef = useRef(sceneObjectId);
  const player = usePlayer();
  const scene = useThree((state) => state.scene);
  const movementSurfaces = usePhysics((state) => state.movementSurfaces);
  const playRef = useRef(autoplay);

  const initialRotation = useRef<Euler>(new Euler());
  const newPosition = useRef(new Vector3());
  const targetPosition = useRef(new Vector3());

  const componentEnabled = useTriggerZone(triggerZoneId, enabled);

  const context: FollowTargetComponentContext = {
    id: componentDto.id,
    type: ComponentType.FOLLOW_TARGET,
    setTarget: (target) => (sceneObjectIdRef.current = target),
    play: () => (playRef.current = true),
    pause: () => (playRef.current = false),
    isActive: () => playRef.current,
  };
  useComponentContext(contextRef, componentDto.id, () => context);

  useEffect(() => {
    const object = objectRef.current;
    if (object) {
      initialRotation.current.copy(object.rotation);
    }
  }, []);

  useFrame(() => {
    if (!componentEnabled) {
      return;
    }

    if (!playRef.current) {
      return;
    }

    let targetObject: Object3D | undefined | null;
    if (target === "player") {
      targetObject = player;
    } else if (sceneObjectIdRef.current) {
      targetObject = getEntityContext(sceneObjectIdRef.current)?.current?.rootObjectRef?.current;
    }

    if (!targetObject) {
      return;
    }

    const controlledObject = objectRef.current;
    if (!controlledObject) {
      return null;
    }

    targetObject.getWorldPosition(targetPosition.current);

    if (rotateOnly) {
      controlledObject.lookAt(targetPosition.current.x, targetPosition.current.y, targetPosition.current.z);
      return;
    }

    const distance = getDistance(targetObject, controlledObject);
    if (distance < 2) {
      return;
    }

    controlledObject.getWorldPosition(newPosition.current);
    newPosition.current.lerp(targetPosition.current, speed);

    const newPositionY = calculateNextYPosition(
      movementSurfaces,
      controlledObject.position,
      newPosition.current,
      glued
    );

    controlledObject.lookAt(newPosition.current.x, newPositionY, newPosition.current.z);

    if (rotate.x) {
      controlledObject.rotateX(initialRotation.current.x);
    } else {
      controlledObject.rotation.x = initialRotation.current.x;
    }

    if (rotate.y) {
      controlledObject.rotateY(initialRotation.current.y);
    } else {
      controlledObject.rotation.y = initialRotation.current.y;
    }

    if (rotate.z) {
      controlledObject.rotateZ(initialRotation.current.z);
    } else {
      controlledObject.rotation.z = initialRotation.current.z;
    }

    const parent = controlledObject.parent;
    if (parent && parent !== scene) {
      scene.attach(controlledObject);
      controlledObject.position.set(newPosition.current.x, newPositionY, newPosition.current.z);
      parent.attach(controlledObject);
    } else {
      controlledObject.position.set(newPosition.current.x, newPositionY, newPosition.current.z);
    }

    const physicsBody = (controlledObject as any).body;
    if (physicsBody) {
      physicsBody.needUpdate = true;
    }
  });

  return null;
}

export default memo(FollowTargetComponent);
