import type { Components, ThemeOptions } from "@mui/material";
import { palette } from "../../palette";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    accent: true;
  }
}

export const MuiButton: Components<ThemeOptions>["MuiButton"] = {
  styleOverrides: {
    root: {
      textTransform: "initial",
      borderRadius: "24px",
      fontWeight: 400,
      whiteSpace: "nowrap",
    },
    startIcon: {
      marginRight: "4px",
    },
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        color: palette.primary.gray600,
        background: palette.primary.gray100,
        border: "1px solid " + palette.primary.gray100,

        "&:hover": {
          color: palette.primary.white,
          background: palette.primary.violet200,
          borderColor: palette.primary.violet,
        },
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        border: "1px solid " + palette.primary.gray100,
        background: palette.primary.gray100,
        color: palette.primary.gray600,

        "&:hover": {
          color: palette.primary.white,
          borderColor: palette.primary.green400,
          background: palette.primary.green400,
        },
      },
    },
    {
      props: { variant: "text" },
      style: {
        border: "1px solid transparent",
        color: palette.primary.white,

        "&:hover": {
          background: palette.primary.gray200,
        },
      },
    },
    {
      props: { variant: "accent" },
      style: {
        background: palette.primary.violet,
        border: "1px solid " + palette.primary.violet600,
        color: palette.primary.white,

        "&:hover": {
          backgroundColor: palette.primary.gray200,
        },
      },
    },
    {
      props: { color: "success" },
      style: {
        border: "1px solid " + palette.primary.greenToxic,
        background: palette.primary.greenToxic,
        color: palette.primary.black,

        "&:hover": {
          border: "1px solid " + palette.primary.greenToxic,
          background: palette.primary.greenToxic,
          color: palette.primary.black,
        },
      },
    },
    {
      props: { size: "small" },
      style: {
        height: "24px",
        padding: "5px 8px",
        fontSize: "12px",
        lineHeight: "16px",
      },
    },
    {
      props: { size: "medium" },
      style: {
        height: "36px",
        padding: "12px 10px",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
      },
    },
    {
      props: { size: "large" },
      style: {
        borderRadius: "32px",
        height: "40px",
        padding: "4px 12px",
        fontSize: "16px",
        lineHeight: "26px",
      },
    },
  ],
};
