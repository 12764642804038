import { Stack, StackProps, styled } from "@mui/material";
import { palette } from "~/theme";

export type TitlePanelProps = StackProps;

export const TitlePanel = styled((props: TitlePanelProps) => <Stack direction="row" alignItems="center" {...props} />)`
  height: 36px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8px;
  background: linear-gradient(0deg, ${palette.primary.violet600}, ${palette.primary.violet600}), ${palette.primary.black};
  border: 1px solid ${palette.primary.gray20};
`;
