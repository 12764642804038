import { createRef, ForwardedRef, forwardRef, memo, RefObject, useEffect, useImperativeHandle, useMemo } from "react";
import ITransformation from "~/types/ITransformation";
import { InstancedMesh, Material, Matrix4 } from "three";
import { calcRelativeTransformation, transformationToMatrix4, useGLTF } from "~/view-scene/utils";
import { isMesh } from "~/common/utils/isMesh";

export type RenderInstancedModelProps = {
  modelUrl: string;
  material?: Material;
  castShadow?: boolean;
  receiveShadow?: boolean;
  transformations: ITransformation[];
  onClick?: (instanceId: number | undefined) => void;
  onPointerEnter?: (instanceId: number | undefined) => void;
  onPointerLeave?: (instanceId: number | undefined) => void;
};

type MeshMeta = {
  ref: RefObject<InstancedMesh>;
  transformation: Matrix4;
};

export type RenderInstancedModelControls = {
  setTransformation: (index: number, transformation: Matrix4) => void;
};

export const RenderInstancedModel = memo(
  forwardRef(
    (
      {
        modelUrl,
        material,
        transformations,
        onClick,
        onPointerEnter,
        onPointerLeave,
        castShadow = false,
        receiveShadow = false,
      }: RenderInstancedModelProps,
      ref: ForwardedRef<RenderInstancedModelControls>
    ) => {
      const { scene } = useGLTF(modelUrl);
      const amount = transformations.length;

      const { instancedMeshes, meshesMeta } = useMemo(() => {
        const instancedMeshes: any[] = [];
        const meshesMeta: MeshMeta[] = [];

        scene.traverse((child) => {
          if (isMesh(child)) {
            const ref = createRef<InstancedMesh>();
            meshesMeta.push({
              ref,
              transformation: calcRelativeTransformation(child, scene),
            });

            const activeMaterial = material ?? child.material;

            instancedMeshes.push(
              <instancedMesh
                key={child.id}
                ref={ref}
                castShadow={castShadow}
                receiveShadow={receiveShadow}
                args={[child.geometry, activeMaterial, amount]}
                onClick={(e) => onClick?.(e.instanceId)}
                onPointerEnter={(e) => onPointerEnter?.(e.instanceId)}
                onPointerLeave={(e) => onPointerLeave?.(e.instanceId)}
              />
            );
          }
        });
        return { instancedMeshes, meshesMeta };
      }, [scene, material, amount, castShadow, receiveShadow]);

      useEffect(() => {
        for (let i = 0; i < transformations.length; i++) {
          const transformationMatrix = transformationToMatrix4(transformations[i]);

          for (const meta of meshesMeta) {
            const matrix = transformationMatrix.clone().multiply(meta.transformation);
            meta.ref.current?.setMatrixAt(i, matrix);
          }
        }

        for (const meta of meshesMeta) {
          if (meta.ref.current) {
            meta.ref.current.instanceMatrix.needsUpdate = true;
          }
        }
      }, [transformations, meshesMeta]);

      useImperativeHandle(
        ref,
        () => ({
          setTransformation: (index: number, transformation: Matrix4) => {
            for (const meta of meshesMeta) {
              const matrix = transformation.clone().multiply(meta.transformation);
              meta.ref.current?.setMatrixAt(index, matrix);
            }

            for (const meta of meshesMeta) {
              if (meta.ref.current) {
                meta.ref.current.instanceMatrix.needsUpdate = true;
              }
            }
          },
        }),
        [meshesMeta]
      );

      return <>{instancedMeshes}</>;
    }
  )
);
