import { useFrame } from "@react-three/fiber";
import { useController } from "@react-three/xr";
import { MutableRefObject, useContext } from "react";
import { NetworkSystemCtx } from "~/view-scene/network";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import { updateAvatarPositionMessage } from "./constants";
import { useBroadcastAnimations } from "./useBroadcastAnimations";
import { applyBaseData, applyXRData } from "./utils";

export type BroadcastXRProps = {
  contextRef: MutableRefObject<PlayerContext | null>;
};

export const BroadcastXR = ({ contextRef }: BroadcastXRProps) => {
  const { send } = useContext(NetworkSystemCtx)!;

  const player = usePlayer();
  const leftController = useController("left");
  const rightController = useController("right");

  useBroadcastAnimations();

  useFrame((state, _delta, xrFrame) => {
    const { camera, gl } = state;

    applyBaseData(updateAvatarPositionMessage, player, contextRef.current, camera);
    applyXRData(updateAvatarPositionMessage, gl, xrFrame, leftController, rightController);

    send("updateAvatarPosition", updateAvatarPositionMessage);
  });

  return null;
};
