import { Icon, IconProps } from "./Icon";

export type TelegramProps = IconProps;

export const Telegram = (props: TelegramProps) => (
  <Icon width="14px" height="12px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" {...props}>
    <path
      d="m2.348 5.195 9.26-3.597c.515-.2.772-.3.962-.253.166.042.305.15.382.299.089.17.043.436-.048.967l-1.039 6.054c-.158.92-.237 1.381-.486 1.638a1.21 1.21 0 0 1-.836.363c-.363.01-.77-.24-1.582-.737L7.238 8.87c-.518-.318-.777-.476-.889-.69a.847.847 0 0 1-.07-.607c.061-.232.278-.442.71-.863L9.9 3.884 4.998 6.74c-.393.229-.59.343-.8.406a1.84 1.84 0 0 1-.58.074c-.22-.007-.44-.068-.881-.19l-.297-.083c-.82-.228-1.231-.342-1.353-.535a.565.565 0 0 1-.029-.552c.1-.204.497-.358 1.29-.666Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </Icon>
);
