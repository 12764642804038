import { Icon, IconProps } from "./Icon";

export type TemplateProps = IconProps;

export const Template = (props: TemplateProps) => (
  <Icon width="24px" height="24px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.333 1.513v2.754c0 .373 0 .56.073.702a.667.667 0 0 0 .291.292c.143.072.33.072.703.072h2.754m-3.82 6h-4m5.333-2.666H3.333m8-2.008v4.808c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.427.218-.987.218-2.108.218H3.867c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108V4.533c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h2.14c.49 0 .735 0 .965.056a2 2 0 0 1 .578.239c.202.124.375.297.72.643l2.126 2.125c.346.346.519.519.643.72.11.18.19.375.24.579.054.23.054.475.054.964Z"
    />
  </Icon>
);
