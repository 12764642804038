import { Canvas, Props as CanvasProps } from "@react-three/fiber";
import { Suspense, memo } from "react";
import { NoToneMapping, sRGBEncoding } from "three";
import shallow from "zustand/shallow";
import { useForceRerender } from "~/common/stores/useForceRerender";
import { useSceneData } from "~/common/stores/useSceneData";
import { useFlags } from "~/entities/flags";
import { useProfile } from "~/entities/profile";
import { CameraSystem } from "../CameraSystem";
import { DesktopSourceLazy, MobileSourceLazy } from "../ControlsSource";
import { ControlsSystem } from "../ControlsSystem";
import { CutsceneSystem } from "../CutsceneSystem";
import { DIProvider } from "../DIProvider";
import { DisableMouseScroll } from "../DisableMouseScroll";
import { DiveInSystem } from "../DiveInSystem";
import { Environment } from "../Environment";
import { OpenLinkHelperLazy } from "../OpenLinkHelper";
import { PointerLocker } from "../PointerLocker";
import { RaycasterSetup } from "../RaycasterSetup";
import { RewardedVideoSystem } from "../RewardedVideoSystem";
import { SceneConsole } from "../SceneConsole";
import { TweenAnimationSystem } from "../TweenAnimationSystem";
import { Addons } from "../addons";
import { AudioSystem } from "../audio";
import { RenderSceneEntities, SceneBackgroundColor, SceneFog } from "../entities";
import { DesktopInventoryLazy, MobileInventoryLazy } from "../inventory";
import { MaterialSystem } from "../material";
import { MediaSystem } from "../media";
import { NetworkSystem, RemoteUsers } from "../network";
import { OcclusionCullingRenderer } from "../occlusionCulling";
import { PerfLazy } from "../perf";
import { PermissionsSystem } from "../permissions";
import { PhysicsDebugger, PhysicsSystem } from "../physics";
import { PreloadSystem } from "../preloadSystem";
import { SceneState } from "../runtime";
import { ScriptSystem } from "../script";
import { useCanvasEvents } from "../stores/canvasEvents";
import useSessionStatus from "../stores/useSessionStatus";
import { TextureSystem } from "../texture";
import { ScreenOverlay, UISystem } from "../ui";
import { YukaSystem } from "../yukaSystem";
import { CommonContext } from "./CommonContext";
import { VRContext } from "./VRContext";
import { HyperModal } from "../hyperModal";

export const RenderScene = memo(() => {
  const mode = useSessionStatus((state) => state.mode);
  const { showProfiler, sceneConsole } = useFlags();
  useForceRerender();

  const { isLoggedIn, profile } = useProfile((state) => ({ isLoggedIn: state.isAuthorised(), profile: state.profile }));

  const sceneState = useSceneData((state) => state.sceneState, shallow);
  const canvasEvents = useCanvasEvents();

  if (!sceneState) {
    return null;
  }

  const {
    fog,
    skybox,
    backgroundColor,
    objects,
    environment,
    postprocessing,
    communicationSystem,
    networkSystem,
    inputSystem,
    platformInterfaceSystem,
  } = sceneState;

  const glConfig: NonNullable<CanvasProps["gl"]> = {
    outputEncoding: sRGBEncoding,
    toneMapping: NoToneMapping,
    localClippingEnabled: true,
    antialias: !postprocessing.enabled,
  };

  const preferredDpr = profile?.preferredDpr ?? 2.0;

  const multiplayerEnabled = networkSystem.enableMultiplayer;
  const communicationEnabled = communicationSystem.voiceCommunicationEnabled;

  const isMediaSystemEnabled = isLoggedIn && multiplayerEnabled && communicationEnabled;
  const Context = mode === "vr" ? VRContext : CommonContext;

  return (
    <DIProvider>
      <div
        id="sceneContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
          height: "100%",
          userSelect: "none",
          WebkitUserSelect: "none",
        }}
      >
        <Canvas
          style={{ background: "#1d1d1d" }}
          shadows={true}
          gl={glConfig}
          dpr={preferredDpr}
          onTouchStart={canvasEvents.onTouchStart}
          onTouchEnd={canvasEvents.onTouchEnd}
          onTouchMove={canvasEvents.onTouchMove}
        >
          {showProfiler && <PerfLazy position="bottom-right" trackCPU={true} />}

          <UISystem />
          <RaycasterSetup />
          <TweenAnimationSystem />

          <PreloadSystem />
          <TextureSystem logMemoryUsage={showProfiler} />
          <MaterialSystem />

          <AudioSystem />
          {isMediaSystemEnabled && <MediaSystem />}
          <ScreenOverlay />

          <SceneState />

          <PhysicsSystem />
          <PhysicsDebugger />

          <YukaSystem />

          <PermissionsSystem />
          <ScriptSystem />
          <OcclusionCullingRenderer />
          <RewardedVideoSystem />

          <PointerLocker />

          {sceneConsole && <SceneConsole />}

          <NetworkSystem>
            <Context scene={sceneState}>
              <CameraSystem />
              <CutsceneSystem />

              {fog && <SceneFog dto={fog} />}
              {backgroundColor && <SceneBackgroundColor dto={backgroundColor} />}

              <Suspense fallback={null}>
                {skybox && <Environment background="only" environmentAssetId={skybox} />}
                {environment && <Environment background={false} environmentAssetId={environment} />}
              </Suspense>

              <RenderSceneEntities entities={objects} />

              <RemoteUsers />

              <ControlsSystem />
              <DiveInSystem />
            </Context>
          </NetworkSystem>
        </Canvas>

        {mode === "desktop" && (
          <>
            {!inputSystem.disableDefaultInputs && <DesktopSourceLazy />}
            {platformInterfaceSystem.gameInterfaceEnabled && <DesktopInventoryLazy />}
          </>
        )}
        {mode === "mobile" && (
          <>
            {!inputSystem.disableDefaultInputs && <MobileSourceLazy />}
            {platformInterfaceSystem.gameInterfaceEnabled && <MobileInventoryLazy />}
            <DisableMouseScroll />
          </>
        )}
      </div>
      <Addons />

      {platformInterfaceSystem.gameInterfaceEnabled && <OpenLinkHelperLazy />}
      <HyperModal />
    </DIProvider>
  );
});
