import { memo, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { BufferGeometry, Mesh } from "three";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

export const RaycasterSetup = memo(() => {
  const raycaster = useThree((state) => state.raycaster);

  useEffect(() => {
    raycaster.firstHitOnly = true;
  }, []);

  useFrame(({ camera, raycaster }) => {
    raycaster.setFromCamera({ x: 0, y: 0 }, camera);
  });

  return null;
});
