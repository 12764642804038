import { cn } from "bem-cn-css-modules";
import { useMemo, useRef } from "react";
import { Game, getCategoryName } from "~/common/data/games";
import styles from "./GameCard.module.css";
import { Typography } from "@mui/material";
import { Eye, Telegram, Facebook2 } from "~/common/components/Icon";
import { Navigate } from "~/common/components";

const cnGameCard = cn("GameCard", styles);

export type GameCardProps = {
  game: Game;
  className?: string;
  onClick?: (game: Game) => void;
};

export const GameCard = ({ className, game, onClick }: GameCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const categories = useMemo(() => game.categories.join(" "), [game.categories]);
  const { hasTelegram, hasFacebook, other } = useMemo(() => {
    return {
      hasTelegram: game.categories?.includes("telegram") ?? false,
      hasFacebook: game.categories?.includes("facebook") ?? false,
      other:
        game.categories
          ?.filter((category) => !["telegram", "facebook"].includes(category))
          .map((category) => getCategoryName(category))
          .join(" / ") ?? null,
    };
  }, [game]);

  const lSize = game.size === "l";
  const hasSocialIcons = hasTelegram || hasFacebook;

  return (
    <div
      className={cnGameCard({ size: game.size ?? "m" }, [className])}
      ref={cardRef}
      data-name={game.name}
      data-categories={categories}
      onClick={() => onClick?.(game)}
    >
      <div className={cnGameCard("Inner")}>
        <img className={cnGameCard("Poster")} src={game.posterSmall} alt={game.name} />
        {(lSize || hasSocialIcons) && (
          <div className={cnGameCard("Overlay")}>
            <div className={cnGameCard("OverlayTop")}>
              <div className={cnGameCard("OverlayTags")}>
                {hasTelegram && (
                  <span className={cnGameCard("SocialIcon")}>
                    <Telegram sx={{ width: "12px" }} color="primary.white" />
                  </span>
                )}
                {hasFacebook && (
                  <span className={cnGameCard("SocialIcon")}>
                    <Facebook2 sx={{ width: "12px" }} color="primary.white" />
                  </span>
                )}
                {other && lSize && (
                  <span className={cnGameCard("Tag")}>
                    <Typography fontSize="12px" fontWeight="500">
                      {other}
                    </Typography>
                  </span>
                )}
              </div>
              {game.played && lSize ? (
                <div className={cnGameCard("Views")}>
                  <Eye color="primary.white" sx={{ width: "13px" }} />
                  <Typography fontSize="12px" fontWeight="500">
                    {game.played}
                  </Typography>
                </div>
              ) : (
                <div />
              )}
            </div>
            {lSize && (
              <div className={cnGameCard("OverlayBottom")}>
                <Typography className={cnGameCard("Name")} fontSize="20px" lineHeight="26px" fontWeight="500">
                  {game.name}
                </Typography>
              </div>
            )}
          </div>
        )}
        {!onClick && <Navigate to={game.url} className={cnGameCard("Link")} openInNewWindow />}
      </div>
    </div>
  );
};
