import { ValueType } from "~/libs/behave-graph";
import { defaultUIEntity } from "~/entities/variable";
import { UIEntityValue } from "./uiEntityValue";

export type UIImageValue = UIEntityValue;

export const uiImageValue = new ValueType(
  "uiImage",
  defaultUIEntity,
  (text: UIImageValue) => text,
  (value: UIImageValue) => value
);
