import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket } from "../../sockets";
import { EntityValue } from "../../values";

export class ParentEntityNode extends BaseNode {
  static readonly type = "action/parentEntity";
  static readonly label = "Get Parent Entity";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new EntitySocket("parentEntity")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");

    node.storage.output.entityId = entity?.parentId ?? null;

    context.writeOutput<EntityValue>("parentEntity", node.storage.output);
  }
}
