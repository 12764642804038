import { MutableRefObject, useEffect, useRef } from "react";
import { Audio as ThreeAudio, PositionalAudio } from "three";
import { useStoreMap } from "effector-react";
import { $audioManager, addAudio, removeAudio } from "~/view-scene/audio";
import IAudioSceneObject from "~/types/IAudioSceneObject";
import { playHTMLMedia } from "~/view-scene/utils";

type RenderThreeAudioProps = {
  dto: IAudioSceneObject;
  audio: HTMLAudioElement | AudioBuffer;
  audioRef?: MutableRefObject<PositionalAudio | ThreeAudio | null>;
};

export function RenderThreeAudio({ dto: { autoplay, loop, volume }, audio, audioRef }: RenderThreeAudioProps) {
  const audioListener = useStoreMap($audioManager, (audioManager) => audioManager.audioListener!);

  const threeAudioRef = useRef<ThreeAudio>(null);

  useEffect(() => {
    const threeAudio = threeAudioRef.current;

    if (!threeAudio) {
      return;
    }

    if (audioRef) {
      audioRef.current = threeAudio;
    }

    if (audio instanceof HTMLAudioElement) {
      threeAudio.setMediaElementSource(audio);
      if (autoplay) {
        playHTMLMedia(audio);
      }
    } else if (audio instanceof AudioBuffer) {
      threeAudio.setBuffer(audio);
      if (autoplay) {
        threeAudio.play();
      }
    }

    addAudio({ sourceType: "mediaVideo", node: threeAudio });

    return () => {
      try {
        // HOTFIX
        threeAudio.stop();
      } catch (err) {}

      removeAudio(threeAudio);
    };
  }, [audio]);

  useEffect(() => {
    const audio = threeAudioRef.current;
    if (!audio) {
      return;
    }
    audio.setVolume(volume);
    audio.setLoop(loop);
  }, [volume, loop]);

  // @ts-ignore
  return <audio args={[audioListener]} ref={threeAudioRef} />;
}
