import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket } from "../../sockets";

export class SetMobileControlsVisibilityNode extends BaseNode {
  static readonly type = "action/setMobileControlsVisibility";
  static readonly label = "Set Mobile Controls Visibility";

  inputs = [new FlowSocket("flow"), new BooleanSocket("visible")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const platform = node.sceneContext.getPlatform();

    if (platform === "mobile") {
      node.sceneContext.setMobileControlsVisibility(context.readInput<boolean>("visible"));
    }
  }
}
