import { NodeContext } from "../../base";
import { LightSocket } from "../../sockets/LightSocket";
import { SceneEntityNode } from "./SceneEntityNode";

export class SceneLightNode extends SceneEntityNode {
  static readonly type = "variable/sceneLight";
  static readonly label = "Light";

  constructor(context: NodeContext) {
    super(context, new LightSocket("Light"));
  }
}
