import { AlertProps, styled } from "@mui/material";
import AttentionAlert from "common/components/AttentionAlert";
import { palette } from "~/theme";

const ErrorAlert = styled((props: AlertProps) => <AttentionAlert {...props} />)(() => ({
  color: palette.primary.red200,
  backgroundColor: palette.primary.red100,
  "& .MuiAlert-icon": {
    padding: "0",
  },
  "& .MuiAlert-icon svg path": {
    fill: palette.primary.red200,
  },
}));

export default ErrorAlert;
