import { useThree } from "@react-three/fiber";
import { useUnit } from "effector-react";
import { memo, useEffect } from "react";
import { Camera } from "three";
import { Layer } from "~/view-scene/layers";
import { setAudioSourceState } from "view-scene/audio";
import { $sceneMode, SceneMode } from "./models";

export const SceneState = memo(() => {
  const sceneMode = useUnit($sceneMode);

  useSwitchCamera(sceneMode);
  useSwitchAudio(sceneMode);
  useSwitchRaycaster(sceneMode);

  return null;
});

const useSwitchCamera = (mode: SceneMode) => {
  const { camera, gl } = useThree();

  useEffect(() => {
    const cameras: Camera[] = gl.xr.enabled ? gl.xr.getCamera().cameras : [camera];

    switch (mode) {
      case "scene":
        cameras.forEach((camera) => {
          camera.layers.set(Layer.default);
        });
        break;
      case "video360":
        cameras.forEach((camera) => {
          camera.layers.set(Layer.video360);
        });
        break;
      case "cutscene":
        cameras.forEach((camera) => {
          camera.layers.set(Layer.cutscene);
        });
        break;
    }
  }, [mode, camera, gl]);
};

const useSwitchAudio = (mode: SceneMode) => {
  useEffect(() => {
    switch (mode) {
      case "scene":
        setAudioSourceState({
          remoteUserAudioSource: { volume: 1 },
          mediaVideo: { volume: 1 },
          sfx: { volume: 1 },
          video360: { volume: 0 },
        });
        break;
      case "video360":
        setAudioSourceState({
          remoteUserAudioSource: { volume: 0 },
          mediaVideo: { volume: 0 },
          sfx: { volume: 0 },
          video360: { volume: 1 },
        });
        break;
      case "cutscene":
        setAudioSourceState({
          remoteUserAudioSource: { volume: 0 },
          mediaVideo: { volume: 0 },
          sfx: { volume: 0 },
          video360: { volume: 1 },
        });
        break;
    }
  }, [mode]);
};

const useSwitchRaycaster = (mode: SceneMode) => {
  const { raycaster } = useThree();

  useEffect(() => {
    switch (mode) {
      case "scene":
        raycaster.layers.set(Layer.default);
        break;
      case "video360":
        raycaster.layers.set(Layer.video360);
        break;
      case "cutscene":
        raycaster.layers.set(Layer.cutscene);
        break;
    }
  }, [mode, raycaster]);
};
