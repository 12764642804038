import { BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class HapticFeedbackNode extends BaseNode {
  static readonly type = "telegram/hapticFeedback";
  static readonly label = "Telegram Haptic Feedback";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval() {
    window.Telegram?.WebApp.HapticFeedback.impactOccurred("heavy");
  }
}
