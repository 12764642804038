import { createEffect, createEvent, createStore } from "effector";
import screenfull from "screenfull";
import { isDesktopSafari } from "~/common/utils/isDesktopSafari";

const setFullscreenState = createEvent<boolean>();
export const $isFullscreen = createStore(screenfull.isFullscreen ?? false).on(setFullscreenState, (_, flag) => flag);

export const enterFullscreen = createEffect(async () => {
  if (!isDesktopSafari()) {
    return screenfull.request?.();
  }
});

export const exitFullscreen = createEffect(async () => {
  if (!isDesktopSafari()) {
    return screenfull.exit?.();
  }
});

screenfull.on?.("change", () => {
  setFullscreenState(screenfull.isFullscreen ?? false);
});
