import { NodeEvalContext } from "~/libs/behave-graph";
import { ModelContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetMaterialSocket, FlowSocket, ModelSocket } from "../../sockets";
import { AssetMaterialValue } from "../../values";

export class ModelSetMaterialNode extends BaseNode {
  static readonly type = "action/modelSetMaterial";
  static readonly label = "Set model material";

  inputs = [new FlowSocket("flow"), new ModelSocket("model"), new AssetMaterialSocket("material")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const model = node.readEntity<ModelContext>(context, "model");
    const material = context.readInput<AssetMaterialValue>("material");

    model?.setMaterial(material.assetId);
  }
}
