import { useSceneData } from "~/common/stores/useSceneData";

export const useActivationDelay = () => {
  return useSceneData((state) => {
    const inputProfile = state.sceneState?.inputSystem.inputProfile;

    if (!inputProfile) {
      return 0;
    }

    if (inputProfile.type === "actionRPG" || inputProfile.type === "singleStick") {
      return inputProfile.delay;
    }

    return 0;
  });
};
