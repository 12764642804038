import { debounce } from "lodash-es";
import { Suspense, useEffect, useState } from "react";
import { useUnmount } from "react-use";
import { ErrorBoundary } from "~/view-scene/utils";
import { Texture } from "./Texture";
import { textureManager } from "./TextureManager";
import { TextureRecord } from "./types";

type TextureSystemProps = {
  logMemoryUsage: boolean;
};

export function TextureSystem({ logMemoryUsage }: TextureSystemProps) {
  const [cachedTextures, setCashedTextures] = useState<TextureRecord[]>([]);

  useEffect(() => {
    const handler = () => {
      setCashedTextures([...textureManager.textures]);
    };

    textureManager.on("update", handler);
    handler();

    return () => {
      textureManager.off("update", handler);
    };
  }, []);

  useEffect(() => {
    if (logMemoryUsage) {
      const gpuMemoryInBytes = cachedTextures.reduce(
        (totalMemory, textureRecord) => totalMemory + (textureRecord.gpuMemoryInBytes ?? 0),
        0
      );
      const header = "Texture GPU Memory\n\n";
      const totalMemoryUsage = `Overall: ${bytesToMB(gpuMemoryInBytes)}MB\n\n`;
      const memoryUsageByTexture = cachedTextures
        .map((texture) => `${texture.name.padEnd(20, " ")} ${bytesToMB(texture.gpuMemoryInBytes ?? 0)}MB`)
        .join("\n");

      debouncedLog(header + totalMemoryUsage + memoryUsageByTexture);
    }
  }, [logMemoryUsage, cachedTextures]);

  useUnmount(() => {
    textureManager.reset();
  });

  return (
    <>
      {cachedTextures.map((cachedTexture) => (
        <ErrorBoundary key={cachedTexture.id} message={`Failed loading TextureAsset with id=${cachedTexture.id}`}>
          <Suspense fallback={null}>
            <Texture textureRecord={cachedTexture} />
          </Suspense>
        </ErrorBoundary>
      ))}
    </>
  );
}

const debouncedLog = debounce((message: string) => console.log(message), 1000);

const bytesToMB = (bytes: number) => (bytes / 1024 / 1024).toFixed(2);
