import WebApp from "@twa-dev/sdk";
import { throttle } from "lodash-es";
import create from "zustand";
import { getAccessToken, setAccessToken } from "~/api/accessToken";
import { getMe, updateStorages } from "~/api/profile.api";
import { getStoragesValues } from "~/api/storage.api";
import { isLoadedInsideIframe } from "~/common/utils/isLoadedInsideIframe";
import { IStorageProvider, StorageData } from "~/types/Storage";
import { BuilderSettings, Profile } from "./types";
import {
  getBuilderSettings,
  getFaceAnimationEnabled,
  getPreferredDpr,
  getPreferredLanguage,
  getPreferredMic,
  getPreferredSpeaker,
  setBuilderSettings,
  setFaceAnimationEnabled,
  setPreferredDpr,
  setPreferredLanguage,
  setPreferredMic,
  setPreferredSpeaker,
} from "./utils/localSettings";

const REDIRECT_PAGE = "redirect_page";

type ProfileDataType = {
  profile: Profile | null;
  storageData: StorageData[];
  redirectPage: string | null;
  accumulator: Record<string, any>;
  sendStorages: () => void;
  isAuthorised: () => boolean;
  loadProfile: () => Promise<void>;
  auth: () => Promise<void>;
  loadStorageData: () => Promise<void>;
  updateProfile: (profile: Profile | null) => void;
  getBuilderSettings: (sceneId: string) => BuilderSettings;
  updateBuilderSettings: (sceneId: string, builderSettings: BuilderSettings) => void;
  updateRedirectPage: (redirectPage: string) => void;
  resetRedirectPage: () => void;
  logOut: () => void;
} & IStorageProvider;

const redirectPage = !isLoadedInsideIframe && localStorage.getItem(REDIRECT_PAGE);

export const useProfile = create<ProfileDataType>((set, get) => ({
  profile: null,

  storageData: [],

  redirectPage: redirectPage ? JSON.parse(redirectPage) : "/",

  accumulator: {},

  sendStorages: throttle(
    () => {
      const updates = Object.entries(get().accumulator).map(([storageId, values]) => ({
        storageId,
        values,
      }));

      if (updates.length === 0) {
        return;
      }

      updateStorages({ updates });
      get().accumulator = {};
    },
    3000,
    { leading: false }
  ),

  isAuthorised: () => {
    return get().profile != null;
  },

  loadProfile: async () => {
    await get().auth();
    await get().loadStorageData();

    if (isLoadedInsideIframe || getAccessToken() == null) {
      return;
    }

    const { status, body } = await getMe();
    if (status !== 200) {
      return;
    }

    const preferredMicDevice = await getPreferredMic();
    const preferredSpeakerDevice = await getPreferredSpeaker();
    const faceAnimationEnabled = getFaceAnimationEnabled();
    const preferredDpr = getPreferredDpr();
    const language = getPreferredLanguage();
    const builderSettings = getBuilderSettings();

    get().updateProfile({
      ...body,
      preferredSpeakerDevice,
      preferredMicDevice,
      faceAnimationEnabled,
      preferredDpr,
      language,
      builderSettings,
    });
  },

  loadStorageData: async () => {
    const { status, body } = await getStoragesValues();

    if (status !== 200) {
      return;
    }

    set(() => ({ storageData: body.storageData }));
  },

  auth: async () => {
    const authConfig = window.__sceneData?.authConfig;

    if (!authConfig) {
      return;
    }

    if (authConfig.type === "tg") {
      await tgAuth(authConfig.url, authConfig.botId);
    }
  },

  updateProfile: (profile: Profile | null) => {
    set(() => ({ profile: profile }));

    if (profile?.preferredSpeakerDevice) {
      setPreferredSpeaker(profile.preferredSpeakerDevice);
    }

    if (profile?.preferredMicDevice) {
      setPreferredMic(profile.preferredMicDevice);
    }

    if (profile?.preferredDpr) {
      setPreferredDpr(profile.preferredDpr);
    }

    if (profile?.faceAnimationEnabled != null) {
      setFaceAnimationEnabled(profile.faceAnimationEnabled);
    }

    if (profile?.language) {
      setPreferredLanguage(profile.language);
    }

    if (profile?.builderSettings) {
      setBuilderSettings(profile.builderSettings);
    }
  },

  getBuilderSettings: (sceneId: string) => {
    const profile = get().profile;
    const builderSettings = profile?.builderSettings;
    const existingSettings = builderSettings?.find((settings) => settings.sceneId === sceneId);

    return (
      existingSettings ?? {
        sceneId: sceneId,
        showGrid: true,
        gridSize: 100,
        gidDivisions: 100,
        showProfiler: false,
      }
    );
  },

  updateBuilderSettings: (sceneId: string, newSettings: BuilderSettings) => {
    const profile = get().profile;
    if (!profile) {
      throw new Error("Unauthorized");
    }

    const newBuilderSettings = profile.builderSettings?.filter((settings) => settings.sceneId !== sceneId) ?? [];
    newBuilderSettings.push(newSettings);

    const updatedProfile = {
      ...profile,
      builderSettings: newBuilderSettings,
    };

    get().updateProfile(updatedProfile);
  },

  updateRedirectPage: (redirectPage: string) => {
    if (redirectPage.includes("401") || redirectPage.includes("404")) {
      get().resetRedirectPage();
      return;
    }
    set(() => ({ redirectPage }));
    localStorage.setItem(REDIRECT_PAGE, JSON.stringify(redirectPage));
  },

  resetRedirectPage: () => {
    set(() => ({ redirectPage: null }));
    localStorage.removeItem(REDIRECT_PAGE);
  },

  logOut: () => {
    localStorage.clear();
    get().updateProfile(null);
    window.location.href = "/";
  },

  getStorageData: (storageId) => {
    const storageData = get().storageData;

    if (!storageData) {
      return null;
    }

    return storageData.find((storageData) => storageData.storage === storageId)?.values ?? null;
  },

  updateStorageData: (storageId, values) => {
    const storageData = get().storageData;

    if (!storageData) {
      return;
    }

    let found = false;
    const newStorageData = storageData.map((storageData) => {
      if (storageData.storage === storageId) {
        found = true;

        return {
          ...storageData,
          values: { ...storageData.values, ...values },
        };
      }

      return storageData;
    });

    if (!found) {
      newStorageData.push({
        storage: storageId,
        values,
      });
    }

    set({
      storageData: newStorageData,
    });

    get().accumulator[storageId] = {
      ...get().accumulator[storageId],
      ...values,
    };
    get().sendStorages();
  },
}));

const tgAuth = async (url: string, botId: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: WebApp.initData,
      initDataUnsafe: WebApp.initDataUnsafe,
      botId,
    }),
  };

  const response = await fetch(url, requestOptions);
  const status = response.status;

  if (status !== 200) {
    throw new Error(`Failed to authenticate with Telegram. Status: ${status}`);
  }

  const body = await response.json();
  const jwt = body.jwt;

  setAccessToken(jwt);
};
