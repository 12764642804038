import * as Yup from "yup";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as authApi from "~/api/auth.api";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { TextField } from "~/hephaestus/components/TextField";
import { Button } from "~/hephaestus/components/Button";

const requestPasswordResetValidationSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
});

export function RequestPasswordReset() {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: requestPasswordResetValidationSchema,
    onSubmit: async (values) => {
      const { email } = values;
      await authApi.requestResetPassword({ email });
      setShowSuccessMessage(true);
    },
  });

  return (
    <>
      {showSuccessMessage && (
        <Typography
          sx={{
            fontWeight: "500",
            marginBottom: "24px",
            color: palette.primary.gray800,
          }}
          variant="body2"
        >
          Check your mailbox! <br />
          If the entered email address matches our records, you’ll receive an email from us.
        </Typography>
      )}
      {!showSuccessMessage && (
        <form onSubmit={formik.handleSubmit}>
          <Typography
            sx={{
              fontWeight: "500",
              marginBottom: "24px",
              color: palette.primary.gray800,
            }}
            variant="body2"
          >
            Please, enter the email address you used to register on our platform
          </Typography>
          <Stack direction="column" gap="23px">
            <FormControl fullWidth error={formik.touched.email && Boolean(formik.errors.email)}>
              <TextField
                type="email"
                name="email"
                placeholder="Email"
                autoComplete="email"
                size="large"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
          </Stack>
          <Box component="div" marginTop="36px">
            <Button type="submit" variant="accent" size="large" fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      )}
    </>
  );
}
