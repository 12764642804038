import { Box, styled } from "@mui/material";
import { palette } from "~/theme";
import { Eye } from "~/common/components/Icon";
import { Button } from "~/hephaestus/components/Button";
import { Navigate } from "~/common/components";

type TemplateCardProps = {
  sceneUrlName: string;
  previewUrl: string;
};

export function TemplateCard({ sceneUrlName, previewUrl }: TemplateCardProps) {
  const viewLink = `/s/${sceneUrlName}/view`;

  return (
    <Container component="div">
      <Image src={previewUrl} />

      <Overlay component="div" className="TemplateCard-Overlay">
        <Navigate to={viewLink} openInNewWindow={true}>
          <PreviewButton variant="contained" startIcon={<Eye />}>
            Template Preview
          </PreviewButton>
        </Navigate>
      </Overlay>
    </Container>
  );
}

const Container = styled(Box)`
  height: 176px;
  position: relative;
  overflow: hidden;
  border: 1px solid ${palette.primary.gray400};
  border-radius: 20px;

  &:hover img {
    opacity: 0.8;
  }

  &:hover .TemplateCard-Overlay {
    display: flex;
  }
`;

const Overlay = styled(Box)`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: ${palette.primary.gray100};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PreviewButton = styled(Button)`
  border-radius: 20px;
  padding: 8px;
  background-color: ${palette.primary.black800};
  box-shadow: none;

  &:hover {
    background: ${palette.primary.brand100};
  }
`;
