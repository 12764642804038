import { DefaultBufferSize } from "../libs/AmmoDebugDrawer";

export class DebuggerData {
  debugVertices: Float32Array;
  debugColors: Float32Array;

  private debugIndex: Uint32Array;

  constructor(sharedArrayBuffer: SharedArrayBuffer) {
    this.debugIndex = new Uint32Array(sharedArrayBuffer, 0, 4);
    this.debugVertices = new Float32Array(sharedArrayBuffer, 4, DefaultBufferSize);
    this.debugColors = new Float32Array(sharedArrayBuffer, 4 + DefaultBufferSize, DefaultBufferSize);
  }

  getIndex() {
    return this.debugIndex[0];
  }

  release() {
    return (this.debugIndex[0] = 0);
  }
}
