import { useMemo, useRef } from "react";
import { useUpdateEffect } from "react-use";
import { EntityContext } from "./EntityContext";
import { useEntityManager } from "../useEntityManager";
import ISceneObject from "~/types/ISceneObject";

const noDeps: any[] = [];

export const useEntityContext = <TContext extends EntityContext<ISceneObject>>(
  entityId: string,
  factory: () => TContext,
  deps: any[] = noDeps
) => {
  const ref = useRef<TContext>(null!);
  const { addEntityContext, removeEntityContext } = useEntityManager((state) => ({
    addEntityContext: state.addEntityContext,
    removeEntityContext: state.removeEntityContext,
  }));

  useMemo(() => {
    if (!ref.current) {
      ref.current = factory();
    }

    addEntityContext(entityId, ref);
  }, []);

  useUpdateEffect(() => {
    removeEntityContext(entityId);
    ref.current = factory();
    addEntityContext(entityId, ref);

    return () => {
      removeEntityContext(entityId);
    };
  }, deps);

  return ref;
};
