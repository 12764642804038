import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";

export type AvatarProps = {
  avatar?: string;
  text: string;
};

export function Avatar({ text, avatar }: AvatarProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: "pointer",
        transition: ".4s ease",
        borderRadius: "50%",
        zIndex: "10",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {!avatar ? (
        <Typography
          sx={{
            background: palette.primary.violet500,
            fontSize: "11px",
            width: "22px",
            flex: "0 0 22px",
            overflow: "hidden",
            borderRadius: "50%",
            alignItems: "center",
            transition: ".4s ease",
            display: "flex",
            justifyContent: "center",
            lineHeight: "22px",
            border: "1px solid " + palette.primary.gray900,
            color: palette.primary.gray600,
          }}
          variant="body1"
        >
          {text}
        </Typography>
      ) : (
        <Box
          sx={(theme) => ({
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "block",
            transition: ".4s ease",
            objectFit: "cover",
            border: "1px solid " + palette.primary.gray900,
            "&:hover": {
              borderColor: palette.primary.violet,
            },
          })}
          component="img"
          src={avatar}
          alt={avatar}
        />
      )}
    </Stack>
  );
}
