import { useEffect } from "react";
import { Vector3 } from "three";
import { getBoundingBox } from "~/common/utils/getBoundingBox";
import { usePhysics } from "~/view-scene/physics";
import { RigidBodyComponentProps } from "./types";

export type BoundingBoxRigidBodyProps = RigidBodyComponentProps;

export const BoundingBoxRigidBody = ({ componentDto, contextRef }: BoundingBoxRigidBodyProps) => {
  const { addRigidBody, removeRigidBody } = usePhysics((state) => ({
    addRigidBody: state.addRigidBody,
    removeRigidBody: state.removeRigidBody,
  }));

  useEffect(() => {
    let object = contextRef.current?.rootObjectRef.current;
    const entityId = contextRef.current?.id;

    if (componentDto.attachTo) {
      object = contextRef.current?.getChild?.(componentDto.attachTo) ?? null;
    }

    if (!object || !entityId) {
      return;
    }

    const { box, worldPosition } = getBoundingBox(object);
    const objectWorldPosition = new Vector3();
    object.getWorldPosition(objectWorldPosition);
    const offset = new Vector3().subVectors(worldPosition, objectWorldPosition);

    const physicsBody = addRigidBody(
      object,
      { entityId, rigidBodyId: componentDto.id },
      {
        type: componentDto.rigidBodyType,
        ghost: componentDto.ghost,
        mass: componentDto.mass,
        shape: {
          type: "box",
          ...box,
          offset: {
            position: {
              x: offset.x,
              y: offset.y,
              z: offset.z,
            },
          },
        },
      }
    );

    contextRef.current.physicsBodies[componentDto.id] = physicsBody!;

    return () => {
      if (physicsBody) {
        removeRigidBody(physicsBody.uid);
        if (contextRef.current) {
          delete contextRef.current.physicsBodies[componentDto.id];
        }
      }
    };
  }, []);

  return null;
};
