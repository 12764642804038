import { memo, useEffect, useRef } from "react";
import { Color, MeshBasicMaterial } from "three";
import { IBasicMaterial } from "~/types/material";
import { useEnvTextureMap, useTextureMap } from "~/view-scene/texture";
import { materialManager } from "../MaterialManager";
import { MaterialRecord } from "../types";
import { toMaterialSide } from "../utils";

type BasicMaterialAssetProps = {
  materialRecord: MaterialRecord;
  material: IBasicMaterial;
};

export const BasicMaterialAsset = memo(({ materialRecord, material }: BasicMaterialAssetProps) => {
  const materialRef = useRef<MeshBasicMaterial>(null!);

  useEffect(() => {
    const basicMaterial = new MeshBasicMaterial();
    materialRef.current = basicMaterial;
    materialManager.finishMaterialLoad(materialRecord.id, basicMaterial);

    return () => {
      basicMaterial.dispose();
    };
  }, []);

  const { color, transparent, opacity, side } = material;

  useEffect(() => {
    materialRef.current.color = new Color(color);
    materialRef.current.transparent = transparent;
    materialRef.current.opacity = opacity;
    materialRef.current.side = toMaterialSide(side);
    materialRef.current.needsUpdate = true;
  }, [color, transparent, opacity, side]);

  const { texture, alphaMap, envMap } = material;

  const toTextureMap = useTextureMap(texture);
  const toAlphaMap = useTextureMap(alphaMap);
  const toEnvMap = useEnvTextureMap(envMap);

  useEffect(() => {
    materialRef.current.map = toTextureMap;
    materialRef.current.alphaMap = toAlphaMap;
    materialRef.current.envMap = toEnvMap;
    materialRef.current.needsUpdate = true;
  }, [toTextureMap, toAlphaMap, toEnvMap]);

  return null;
});
