import { createEffect, createEvent, createStore } from "effector";
import { Storage } from "~/types/Storage";
import { getStorages } from "~/api/storage.api";
import { keyBy } from "lodash-es";

export const loadStoragesFx = createEffect(async (workspaceId: string) => {
  if (window.__storages) {
    return window.__storages;
  }

  const { body } = await getStorages(workspaceId);

  return body.storages;
});

export const resetStorages = createEvent();

export const $storages = createStore<Storage[] | null>(null)
  .on(loadStoragesFx.doneData, (_, storages) => storages)
  .reset(resetStorages);

export const $storagesMap = $storages.map((storages) => keyBy(storages, "id"));
