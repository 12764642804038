import { RefObject } from "react";
import { ScriptArguments } from "~/types/IScriptComponent";
import { InteractionEvent } from "~/types/InteractionEvent";
import { ScriptSchema } from "~/types/ScriptAsset";
import type { Action, PlayerControls } from "~/view-scene/ControlsSystem";
import { rewardedVideoEvents } from "~/view-scene/RewardedVideoSystem";
import type { EntityContext } from "~/view-scene/runtime";
import { BehaveEventsManager } from "./BehaveEventsManager";
import { Graph } from "../Graph";
import { MethodsManager } from "../MethodsManager";
import { Registry } from "../Registry";
import { Script } from "../Script";
import { ScriptEvents } from "../ScriptEvents";
import { BaseBehaveNode } from "../base";
import {
  ActionNode,
  CameraControlsNode,
  CollisionEndNode,
  CollisionStartNode,
  ControlsActionDownNode,
  ControlsActionUpNode,
  GameSessionStartedNode,
  InteractionNode,
  PlayerCollisionEndNode,
  PlayerCollisionStartNode,
  RewardedVideoWatchedNode,
  StartNode,
} from "../nodes";
import { ScriptContext } from "../types";
import type { SceneContext } from "../useSceneContext";
import { BehaveVariablesManager } from "./BehaveVariablesManager";

export class BehaveScript extends Script {
  private graph: Graph;
  private scriptEvents: ScriptEvents;
  private scriptContext: ScriptContext;
  private actionUnsubscribers: Array<() => void> = [];
  private methods: MethodsManager;

  private startNode: BaseBehaveNode | null = null;
  private gameSessionStartedNode: BaseBehaveNode | null = null;
  private actionNode: BaseBehaveNode | null = null;
  private interactionNode: BaseBehaveNode | null = null;
  private entityId: string;
  private collisionStartNode: BaseBehaveNode | null = null;
  private rewardedVideoWatchedNode: BaseBehaveNode | null = null;
  private collisionEndNode: BaseBehaveNode | null = null;
  private playerCollisionStartNode: BaseBehaveNode | null = null;
  private playerCollisionEndNode: BaseBehaveNode | null = null;

  constructor(
    id: string,
    readonly name: string,
    private entityContextRef: RefObject<EntityContext>,
    private args: ScriptArguments,
    public schema: ScriptSchema,
    private registry: Registry,
    private sceneContext: SceneContext,
    private playerControls: PlayerControls
  ) {
    super(id);
    this.entityId = entityContextRef.current!.id;
    this.scriptEvents = new ScriptEvents();
    this.variables = new BehaveVariablesManager(schema.parameters, args);

    this.scriptContext = {
      entityId: this.entityId,
      scriptEvents: this.scriptEvents,
      variables: this.variables,
      events: null!,
    };

    this.methods = new MethodsManager(this.schema.methods ?? [], this.registry, this.sceneContext, this.scriptContext);
    this.graph = new Graph(schema.graph, registry, sceneContext, this.scriptContext, this.methods);

    this.startNode = this.graph.findNode(StartNode.type);
    this.gameSessionStartedNode = this.graph.findNode(GameSessionStartedNode.type);
    this.actionNode = this.graph.findNode(ActionNode.type);
    this.interactionNode = this.graph.findNode(InteractionNode.type);
    this.rewardedVideoWatchedNode = this.graph.findNode(RewardedVideoWatchedNode.type);
    this.collisionStartNode = this.graph.findNode(CollisionStartNode.type);
    this.collisionEndNode = this.graph.findNode(CollisionEndNode.type);
    this.playerCollisionStartNode = this.graph.findNode(PlayerCollisionStartNode.type);
    this.playerCollisionEndNode = this.graph.findNode(PlayerCollisionEndNode.type);
    this.hasTick = this.hasTickableNodes(this.graph);

    this.events = new BehaveEventsManager(schema.events ?? [], this.scriptContext, this.graph);
    this.scriptContext.events = this.events;

    this.graph.executeAll();
  }

  init() {
    this.listenCollisions();
    this.listenAction();
    this.listenInteraction();
    this.listenControls();
    this.listenRewardedVideoEvents();
  }

  start() {
    if (this.startNode) {
      this.scriptEvents.startEvent.emit();
      this.graph.executeAll();
    }
  }

  tick(deltaSeconds: number) {
    this.scriptEvents.tickEvent.emit(deltaSeconds);
    this.graph.executeAll();
  }

  gameSessionStarted() {
    if (this.gameSessionStartedNode) {
      this.scriptEvents.gameSessionStartedEvent.emit();
      this.graph.executeAll();
    }
  }

  storageUpdate(storageId: string) {
    this.scriptEvents.storageUpdate.emit(storageId);
    this.graph.executeAll();
  }

  dispose() {
    this.offCollisions();
    this.offAction();
    this.offInteraction();
    this.offControls();
  }

  private get hasAction() {
    return Boolean(this.actionNode);
  }

  private get hasInteraction() {
    return Boolean(this.interactionNode);
  }

  private get hasRewardedVideoEvents() {
    return Boolean(this.rewardedVideoWatchedNode);
  }

  private get hasCollisionStart() {
    return Boolean(this.collisionStartNode) || Boolean(this.playerCollisionStartNode);
  }

  private get hasCollisionEnd() {
    return Boolean(this.collisionEndNode) || Boolean(this.playerCollisionEndNode);
  }

  private hasTickableNodes(graph: Graph) {
    return graph.nodes.some((node) => node.tickable);
  }

  private listenCollisions() {
    if (this.hasCollisionStart) {
      this.entityContextRef.current?.events.on("collisionStart", this.handleCollisionStart);
    }

    if (this.hasCollisionEnd) {
      this.entityContextRef.current?.events.on("collisionEnd", this.handleCollisionEnd);
    }
  }

  private offCollisions() {
    this.entityContextRef.current?.events.off("collisionStart", this.handleCollisionStart);
    this.entityContextRef.current?.events.off("collisionEnd", this.handleCollisionEnd);
  }

  private listenRewardedVideoEvents() {
    if (this.hasRewardedVideoEvents) {
      rewardedVideoEvents.on("rewardedVideoWatched", this.handleRewardedVideoWatchedEvent);
      rewardedVideoEvents.on("noAds", () => this.handleRewardedVideoWatchedEvent(false));
    }
  }

  private listenAction() {
    if (this.hasAction) {
      this.entityContextRef.current?.events.on("action", this.handleAction);
    }
  }

  private offAction() {
    this.entityContextRef.current?.events.off("action", this.handleAction);
  }

  private listenInteraction() {
    if (this.hasInteraction) {
      this.entityContextRef.current?.events.on("interaction", this.handleInteraction);
    }
  }

  private offInteraction() {
    this.entityContextRef.current?.events.off("interaction", this.handleInteraction);
  }

  private listenControls() {
    if (this.graph.findNode(CameraControlsNode.type)) {
      this.playerControls.look.on("fire", this.handleCameraControls);
    }

    if (this.graph.findNode(ControlsActionDownNode.type) || this.graph.findNode(ControlsActionUpNode.type)) {
      [[this.playerControls.action, "action"] as const, [this.playerControls.jump, "jump"] as const].forEach(
        ([action, actionName]) => {
          this.actionUnsubscribers.push(this.listenControlsAction(action, actionName));
        }
      );

      this.actionUnsubscribers.push(this.listenCustomAction(this.playerControls.customAction));
    }
  }

  private listenControlsAction(action: Action, actionName: string) {
    let actionDown = false;

    const startHandler = () => {
      if (!actionDown) {
        actionDown = true;
        this.scriptEvents.controlsAction.emit({ action: actionName, down: true });
        this.graph.executeAll();
      }
    };

    const endHandler = () => {
      if (actionDown) {
        actionDown = false;
        this.scriptEvents.controlsAction.emit({ action: actionName, down: false });
        this.graph.executeAll();
      }
    };

    action.on("start", startHandler);
    action.on("end", endHandler);

    return () => {
      action.off("start", startHandler);
      action.off("end", endHandler);
    };
  }

  private listenCustomAction(action: Action<string>) {
    let actionDown = false;

    const startHandler = (id: string) => {
      if (!actionDown) {
        actionDown = true;
        this.scriptEvents.controlsAction.emit({ action: id, down: true });
        this.graph.executeAll();
      }
    };

    const endHandler = (id: string) => {
      if (actionDown) {
        actionDown = false;
        this.scriptEvents.controlsAction.emit({ action: id, down: false });
        this.graph.executeAll();
      }
    };

    action.on("start", startHandler);
    action.on("end", endHandler);

    return () => {
      action.off("start", startHandler);
      action.off("end", endHandler);
    };
  }

  private offControls() {
    this.playerControls.look.off("fire", this.handleCameraControls);
    this.actionUnsubscribers.forEach((unsubscribe) => unsubscribe());
  }

  private handleCollisionStart = (id: string) => {
    this.scriptEvents.collisionStart.emit(id);
    this.graph.executeAll();
  };

  private handleRewardedVideoWatchedEvent = (viewed: boolean) => {
    this.scriptEvents.rewardedVideoWatchedEvent.emit(viewed);
    this.graph.executeAll();
  };

  private handleCollisionEnd = (id: string) => {
    this.scriptEvents.collisionEnd.emit(id);
    this.graph.executeAll();
  };

  private handleAction = () => {
    this.scriptEvents.actionEvent.emit();
    this.graph.executeAll();
  };

  private handleInteraction = (interaction: InteractionEvent) => {
    this.scriptEvents.interactionEvent.emit(interaction);
    this.graph.executeAll();
  };

  private handleCameraControls = (value: { forward: number; side: number }) => {
    this.scriptEvents.cameraControls.emit(value);
    this.graph.executeAll();
  };
}
