import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, ComponentSocket, EntitySocket } from "../../sockets";
import { EntityValue } from "~/types/Variable";
import { FollowTargetSteeringComponentContext } from "~/view-scene/runtime";

export class SteeringFollowTargetCheckIsEntityReachableNode extends BaseNode {
  static readonly type = "logic/steeringFollowTargetCheckIsEntityReachable";
  static readonly label = "Steering - Follow Target, Check Is Entity Reachable";

  inputs = [new ComponentSocket("steeringFollowTarget"), new EntitySocket("entity")];
  outputs = [new BooleanSocket("isReachable")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const steeringFollowTargetComponent = node.readComponent<FollowTargetSteeringComponentContext>(
      context,
      "steeringFollowTarget"
    );
    const entityId = context.readInput<EntityValue>("entity")?.entityId;

    let isReachable = false;
    if (entityId && steeringFollowTargetComponent) {
      isReachable = steeringFollowTargetComponent.isEntityReachable(entityId);
    }

    context.writeOutput<boolean>("isReachable", isReachable);
  }
}
