import { memo } from "react";
import { CameraControlsDefault } from "./CameraControlsDefault";
import { useSceneData } from "~/common/stores/useSceneData";
import { CameraControlsFollow } from "./CameraControlsFollow";
import { useCameraContext } from "./useCameraContext";

export const CameraSystem = memo(() => {
  const controlsSystem = useSceneData((state) => state.sceneState!.controlsSystem);

  useCameraContext();

  if (controlsSystem.type === "default") {
    return <CameraControlsDefault controlsSystem={controlsSystem} />;
  }

  if (controlsSystem.type === "follow") {
    return <CameraControlsFollow controlsSystem={controlsSystem} />;
  }

  return null;
});
