import { merge } from "lodash-es";
import { UISettings } from "./types";

const BUILDER_UI_SETTINGS = "builder_ui_settings";

export const addInitialUISettings = (sceneId: string): UISettings => {
  const initialSettings = defaultSettingsFactory(sceneId);

  const allSettingsRaw = localStorage.getItem(BUILDER_UI_SETTINGS);
  const allSettings: UISettings[] = allSettingsRaw ? (JSON.parse(allSettingsRaw) as UISettings[]) : [];
  const updatedAllSettingsRaw = JSON.stringify([...allSettings, initialSettings]);

  localStorage.setItem(BUILDER_UI_SETTINGS, updatedAllSettingsRaw);

  return initialSettings;
};

export const getUISettings = (sceneId: string): UISettings | undefined => {
  const allSettingsRaw = localStorage.getItem(BUILDER_UI_SETTINGS);
  const allSettings: UISettings[] = allSettingsRaw ? (JSON.parse(allSettingsRaw) as UISettings[]) : [];

  const foundSettings = allSettings.find((s) => s.sceneId === sceneId);

  return foundSettings && merge(defaultSettingsFactory(sceneId), foundSettings);
};

export const saveUISettings = (settings: UISettings) => {
  const allSettingsRaw = localStorage.getItem(BUILDER_UI_SETTINGS);
  const allSettings: UISettings[] = allSettingsRaw ? (JSON.parse(allSettingsRaw) as UISettings[]) : [];
  const updatedAllSettings = allSettings.map((s) => (s.sceneId !== settings.sceneId ? s : settings));
  const updatedAllSettingsRaw = JSON.stringify(updatedAllSettings);

  localStorage.setItem(BUILDER_UI_SETTINGS, updatedAllSettingsRaw);
};

const defaultSettingsFactory = (sceneId: string): UISettings => {
  return {
    sceneMode: "3d",
    sceneId,
    activeInspector: "scene",
    gridHelper: {
      show: true,
      size1: 1,
      size2: 10,
      color: "white",
    },
    panels: {
      hierarchy: { open: true },
      inspector: { open: true },
    },
    profiler: {
      show: false,
    },
    expandedInspectorContainers: [],
  };
};
