import { LightType } from "~/types/LightType";
import IDirectionalLight from "~/types/IDirectionalLight";
import IHemisphereLight from "~/types/IHemisphereLight";
import ISpotLight from "~/types/ISpotLight";
import IPointLight from "~/types/IPointLight";
import ILight from "~/types/ILight";
import { SceneObjectType } from "~/types/SceneObjectType";
import ILightSceneObject from "~/types/ILightSceneObject";
import { generateGroupEntity } from "../groupEntity/utils";

export const generateAmbientLightEntity = (): Omit<ILightSceneObject<ILight>, "order"> => ({
  ...generateGroupEntity(undefined, SceneObjectType.LIGHT, "Ambient Light"),
  light: generateAmbientLight(),
});

export const generateDirectionalLightEntity = (): Omit<ILightSceneObject<IDirectionalLight>, "order"> => ({
  ...generateGroupEntity(undefined, SceneObjectType.LIGHT, "Directional Light"),
  light: generateDirectionalLight(),
});

export const generateHemisphereLightEntity = (): Omit<ILightSceneObject<IHemisphereLight>, "order"> => ({
  ...generateGroupEntity(undefined, SceneObjectType.LIGHT, "Hemisphere Light"),
  light: generateHemisphereLight(),
});

export const generateSpotLightEntity = (): Omit<ILightSceneObject<ISpotLight>, "order"> => ({
  ...generateGroupEntity(undefined, SceneObjectType.LIGHT, "Spot Light"),
  light: generateSpotLight(),
});
export const generatePointLightEntity = (): Omit<ILightSceneObject<IPointLight>, "order"> => ({
  ...generateGroupEntity(undefined, SceneObjectType.LIGHT, "Point Light"),
  light: generatePointLight(),
});

export const generateAmbientLight = (): ILight => ({
  lightType: LightType.AMBIENT,
  color: "white",
  intensity: 1,
});

export const generateDirectionalLight = (): IDirectionalLight => ({
  lightType: LightType.DIRECTIONAL,
  color: "white",
  intensity: 1,
  castShadow: false,
  target: { x: 0, y: 0, z: 0 },
  shadowBias: -0.0001,
  normalBias: 0.01,
  resolution: 2,
  near: 0.5,
  far: 500.0,
  left: 50,
  right: -50,
  top: 50,
  bottom: -50,
});

export const generateHemisphereLight = (): IHemisphereLight => ({
  lightType: LightType.HEMISPHERE,
  color: "white",
  groundColor: "white",
  intensity: 1,
});

export const generateSpotLight = (): ISpotLight => ({
  lightType: LightType.SPOT,
  color: "white",
  intensity: 1,
  castShadow: false,
  distance: 0,
  target: { x: 0, y: 0, z: 0 },
  angle: Math.PI / 3.0,
  penumbra: 0,
  decay: 1,
  shadowBias: -0.0001,
  normalBias: 0.01,
  resolution: 2,
});

export const generatePointLight = (): IPointLight => ({
  lightType: LightType.POINT,
  color: "white",
  intensity: 1,
  distance: 0,
  decay: 1,
  castShadow: false,
  shadowBias: -0.0001,
  radius: 5,
  normalBias: 0.01,
  resolution: 2,
});
