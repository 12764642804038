import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, PlayerSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class TeleportNode extends BaseNode {
  static readonly type = "logic/teleport";
  static readonly label = "Teleport";

  inputs = [new FlowSocket("flow"), new PlayerSocket("player"), new Vector3Socket("position")];
  outputs = [new FlowSocket("flow"), new BooleanSocket("teleported")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const player = node.readEntity<PlayerContext>(context, "player");
    const position = context.readInput<Vector3Value>("position");

    if (player) {
      player.teleport(position);
      context.writeOutput("teleported", true);
    } else {
      context.writeOutput("teleported", false);
    }
  }
}
