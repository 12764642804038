import { Icon, IconProps } from "./Icon";

export type VideoProps = IconProps;

export const Video = (props: VideoProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M14.667 5.954c0-.404 0-.606-.08-.7a.333.333 0 0 0-.28-.115c-.122.01-.265.153-.55.438L11.332 8l2.423 2.423c.286.286.429.428.551.438a.333.333 0 0 0 .28-.116c.08-.093.08-.295.08-.7v-4.09Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.333 6.533c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h3.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v2.934c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218h-3.6c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108V6.533Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
