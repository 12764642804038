import { ReactNode, useRef } from "react";
import { Camera } from "three";
import ICameraSceneObject from "~/types/ICameraSceneObject";
import { CameraContext, useEntityContext } from "~/view-scene/runtime/contexts";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "../Entity";
import { RenderCamera } from "./RenderCamera";

export type CameraEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const CameraEntity = ({ entityId, children }: CameraEntityProps) => {
  const entity = useEntity<ICameraSceneObject>(entityId);
  const ref = useRef<Camera | null>(null);
  const cameraContextRef = useEntityContext(entityId, () => new CameraContext(entity, ref));

  return (
    entity && (
      <Entity entityId={entityId} context={cameraContextRef}>
        <RenderCamera ref={ref} camera={entity.camera} default={entity.default} />
        {children}
      </Entity>
    )
  );
};
