import { ValueType } from "~/libs/behave-graph";
import { Vector3Value } from "~/types/Variable";
import { defaultVector3 } from "~/entities/variable";

export type { Vector3Value };

export const vector3Value = new ValueType(
  "vector3",
  defaultVector3,
  (text: Vector3Value) => text,
  (value: Vector3Value) => value
);
