import { RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import {
  EntityContext,
  FollowTargetSteeringComponentContext,
  NavMeshContext,
  useComponentContext,
  useEntityManager,
} from "~/view-scene/runtime";
import type { FollowPathBehavior, OnPathBehavior } from "yuka";
import { useFrame } from "@react-three/fiber";
import { FollowTargetSteeringComponent as FollowTargetSteeringComponentDescriptor } from "types/component";
import { EnabledStatus } from "~/types/EnabledStatus";
import { Group } from "three";
import { ComponentType } from "~/types/ComponentType";
import { PLAYER_HEIGHT } from "~/config";
import { useObstaclesAvoidingBehavior } from "./useObstaclesAvoidingBehavior";
import { useYuka } from "~/view-scene/yukaSystem";

export type SteeringComponentProps = {
  descriptor: FollowTargetSteeringComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

const MIN_DELAY = 250;

export function FollowTargetSteeringComponent({ descriptor, objectRef, contextRef }: SteeringComponentProps) {
  const { targetEntityId, navMeshEntityId, obstacleTags } = descriptor;

  const vehicle = contextRef.current?.vehicle ?? null;
  const yuka = useYuka();
  const target = useMemo(() => new yuka.Vector3(), []);

  useEffect(() => {
    const object = objectRef.current;

    if (!object || !vehicle) {
      return;
    }

    vehicle.updateNeighborhood = descriptor.updateNeighborhood;
    vehicle.boundingRadius = descriptor.boundingRadius;
    vehicle.maxSpeed = descriptor.maxSpeed;
    vehicle.maxForce = descriptor.maxForce;

    const followPathBehavior = new yuka.FollowPathBehavior();
    followPathBehavior.active = false;
    vehicle.steering.add(followPathBehavior);

    const onPathBehavior = new yuka.OnPathBehavior();
    onPathBehavior.active = false;
    onPathBehavior.predictionFactor = 0.1;
    vehicle.steering.add(onPathBehavior);

    // @ts-ignore
    const _arrive = vehicle.steering.behaviors[0]._arrive;
    _arrive.tolerance = 0.4;

    return () => {
      vehicle.steering.remove(followPathBehavior);
      vehicle.steering.remove(onPathBehavior);
    };
  }, []);

  useEffect(() => {
    if (!vehicle) {
      return;
    }

    vehicle.updateNeighborhood = descriptor.updateNeighborhood;
    vehicle.boundingRadius = descriptor.boundingRadius;
    vehicle.maxSpeed = descriptor.maxSpeed;
    vehicle.maxForce = descriptor.maxForce;
  }, [vehicle, descriptor]);

  const getNavMesh = useCallback(() => {
    if (!navMeshEntityId) {
      return null;
    }
    const navMeshEntityContext = useEntityManager.getState().getEntityContext<NavMeshContext>(navMeshEntityId);
    const navMesh = navMeshEntityContext?.current?.navMesh;
    return navMesh ?? null;
  }, [navMeshEntityId]);

  const getTargetEntityObject = useCallback((entityId: string | null) => {
    if (!entityId) {
      return null;
    }
    const targetEntity = useEntityManager.getState().getEntityContext(entityId);
    const targetObject = targetEntity?.current?.rootObjectRef.current;
    return targetObject ?? null;
  }, []);

  const context: FollowTargetSteeringComponentContext = {
    id: descriptor.id,
    type: ComponentType.FOLLOW_TARGET_STEERING,
    isEntityReachable: (entityId: string) => {
      const navMesh = getNavMesh();
      const targetEntityObject = getTargetEntityObject(entityId);
      if (!navMesh || !targetEntityObject) {
        return false;
      }
      target.set(targetEntityObject.position.x, targetEntityObject.position.y, targetEntityObject.position.z);
      return !!navMesh.getRegionForPoint(target, 1);
    },
  };

  useComponentContext(contextRef, descriptor.id, () => context);

  useObstaclesAvoidingBehavior({ vehicle, obstacleTags });

  const lastExecutedRef = useRef(Date.now());
  useFrame(() => {
    if (descriptor.enabled !== EnabledStatus.enabled) {
      return;
    }

    const now = Date.now();
    const timeSinceLastExecution = now - lastExecutedRef.current;

    if (timeSinceLastExecution < MIN_DELAY) {
      return;
    }

    const navMesh = getNavMesh();
    const targetObject = getTargetEntityObject(targetEntityId);

    const steeredObject = objectRef.current;

    if (!vehicle || !steeredObject || !navMesh || !targetObject) {
      return;
    }

    const closestDistance = descriptor.closestDistance;
    const currentDistance = steeredObject.position.distanceTo(targetObject.position);
    if (currentDistance < closestDistance) {
      vehicle.active = false;
      const followPathBehavior = vehicle.steering.behaviors[0] as FollowPathBehavior;
      followPathBehavior.active = false;
      followPathBehavior.path.clear();

      const onPathBehavior = vehicle.steering.behaviors[1] as OnPathBehavior;
      onPathBehavior.active = false;
      onPathBehavior.path.clear();

      return;
    }

    vehicle.active = true;
    const from = vehicle.position;

    // TODO move to offset
    let targetObjectPositionY = targetObject.position.y;
    if (targetEntityId === "player") {
      targetObjectPositionY -= PLAYER_HEIGHT / 2;
    }
    target.set(targetObject.position.x, targetObjectPositionY, targetObject.position.z);

    const path = navMesh.findPath(from, target);

    const followPathBehavior = vehicle.steering.behaviors[0] as FollowPathBehavior;
    followPathBehavior.active = true;
    followPathBehavior.path.clear();

    const onPathBehavior = vehicle.steering.behaviors[1] as OnPathBehavior;
    onPathBehavior.active = true;
    onPathBehavior.path.clear();

    path.forEach((point) => {
      followPathBehavior.path.add(point);
      onPathBehavior.path.add(point);
    });
  });

  return null;
}
