import { useEffect, useMemo } from "react";
import { Box3, Group } from "three";
import { useLoadAvatar } from "./useLoadAvatar";

export function useAvatar(url: string) {
  const avatarContainer = useMemo(() => {
    const container = new Group();
    container.rotateY(Math.PI);
    return container;
  }, []);

  const avatar = useLoadAvatar(url);

  const boundingBox = useMemo(() => {
    const box = new Box3();
    box.setFromObject(avatar);
    return box;
  }, [avatar]);

  useEffect(() => {
    avatar.position.setY(-(boundingBox.max.y - boundingBox.min.y) / 2);
    avatarContainer.add(avatar);
    return () => {
      avatarContainer.remove(avatar);
    };
  }, [avatar]);

  return { avatarContainer, avatar, boundingBox };
}
