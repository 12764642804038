import { EnabledStatus } from "~/types/EnabledStatus";
import ICameraSceneObject from "~/types/ICameraSceneObject";
import { SceneObjectType } from "~/types/SceneObjectType";

export const defaultCameraDto: ICameraSceneObject = {
  id: "camera",
  name: "Camera",
  type: SceneObjectType.CAMERA,
  enabled: EnabledStatus.enabled,
  visible: true,
  camera: {
    type: "perspective",
    fov: 50,
    aspect: 1,
    near: 0.1,
    far: 2000,
    zoom: 1,
  },
  default: true,
  order: 0,
  tags: [],
  position: { x: 0, y: 0, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
  scale: { x: 1, y: 1, z: 1 },
  isStatic: false,
  components: [],
  editable: true,
  removable: true,
};
