import React from "react";
import { Box } from "@mui/material";
import { palette } from "~/theme";

type LoaderProps = {
  size?: number;
  primaryColor?: string;
  borderPrimaryColor?: string;
};

export function Loader({
  size = 17,
  primaryColor = palette.primary.gray200,
  borderPrimaryColor = palette.primary.white,
}: LoaderProps) {
  return (
    <Box
      component="div"
      sx={{
        width: size + "px",
        height: size + "px",
        flex: "0 0 " + size + "px",
        display: "block",
        top: 0,
        left: 0,
        borderRadius: "50%",
        bottom: 0,
        right: 0,
      }}
    >
      <Box
        component="div"
        sx={{
          width: size - 2 + "px",
          height: size - 2 + "px",
          display: "block",
          top: 0,
          left: 0,
          borderRadius: "50%",
          bottom: 0,
          border: "1px solid " + primaryColor,
          borderRightColor: borderPrimaryColor,
          right: 0,
          animation: "loader-1-1 1.8s linear infinite",
          margin: "auto",
          "@keyframes loader-1-1": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    </Box>
  );
}
