import { useState } from "react";
import { Box, BoxProps, styled, Typography } from "@mui/material";
import { random } from "lodash-es";
import { useInterval } from "react-use";
import { palette } from "~/theme";
import { tips } from "./constants";

export type TipsProps = Pick<BoxProps, "sx" | "className">;

const tipTimeout = 10000;

export const Tips = (props: TipsProps) => {
  const [tip, setTip] = useState(getRandomTip());

  useInterval(() => {
    setTip(getRandomTip());
  }, tipTimeout);

  return (
    <Container {...props}>
      <Text dangerouslySetInnerHTML={{ __html: tip }} />
    </Container>
  );
};

const Container = styled(Box)`
  background: ${palette.primary.gray20};
  border: 1px solid ${palette.primary.gray20};
  padding: 8px;
  border-radius: 8px;
`;

const Text = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${palette.primary.gray800};

  & b {
    font-weight: 600;
  }
`;

const getRandomTip = () => {
  return tips[random(0, tips.length - 1)];
};
