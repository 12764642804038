import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

/**
 * @deprecated use ScaleNode
 */
export class GetScaleNode extends BaseNode {
  static readonly type = "action/getScale";
  static readonly label = "Get scale";
  static readonly deprecated = true;

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow"), new Vector3Socket("scale")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const entity = node.readEntity(context, "entity");

    if (!entity) {
      return;
    }

    const scale = entity.rootObjectRef.current?.scale;

    if (scale) {
      setVector3ValueFromXYZ(node.storage.output, scale);
    }

    context.writeOutput<Vector3Value>("scale", node.storage.output);
  }
}
