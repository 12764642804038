import { WHITE_LABELING_PROFILE } from "~/config";

export const getSceneUrl = (urlName: string) => {
  const profileScene = getWhiteLabelingProfile(urlName);

  if (profileScene) {
    const url = createURL(profileScene.path);
    const search = mergeSearches(window.location.search, url.search);

    return `${url.pathname}${search}`;
  }

  if (isTelegram()) {
    return buildTelegramUrl(urlName);
  }

  return buildDefaultUrl(urlName);
};

export const getWhiteLabelingProfile = (urlName: string) => {
  return WHITE_LABELING_PROFILE.scenes.find((s) => s.urlName === urlName) ?? null;
};

export const isWhiteLabeling = (urlName: string) => Boolean(getWhiteLabelingProfile(urlName));

export const isTelegram = () => {
  return window.location.pathname.includes("telegram");
};

export const buildDefaultUrl = (urlName: string) => `/s/${urlName}/view${window.location.search}`;
export const buildTelegramUrl = (urlName: string) => `/telegram/${urlName}${window.location.search}`;

export const mergeSearches = (s1: string, s2: string) => {
  const search1 = new URLSearchParams(s1);
  const search2 = new URLSearchParams(s2);

  for (const [key, value] of search2) {
    search1.set(key, value);
  }

  return `?${search1.toString()}`;
};

export const createURL = (url: string) => {
  if (url.startsWith("http")) {
    return new URL(url);
  }

  return new URL(url, window.location.origin);
};

export const buildQueryString = (params: Record<string, string>) => {
  const search = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    search.set(key, value);
  }

  return search.toString();
};
