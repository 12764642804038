import { useEntityManager } from "~/view-scene/runtime/useEntityManager";
import { useEffect, useMemo, useRef } from "react";
import { EntityContext } from "~/view-scene/runtime";
import { EventEmitter } from "eventemitter3";
import { useEntityContext } from "~/view-scene/runtime/utils/useEntityContext";
import { useRefWrap } from "~/common/hooks/useRefWrap";
import { Object3D } from "three";
import { playerAvatarId } from "./constants";
import { defaultVector3 } from "~/entities/variable";

const avatarTags = new Set([]);
const defaultVector = defaultVector3();

export function usePlayerAvatarContext(avatar: Object3D) {
  const { addEntityContext, removeEntityContext } = useEntityManager((state) => ({
    addEntityContext: state.addEntityContext,
    removeEntityContext: state.removeEntityContext,
  }));

  const avatarRef = useRefWrap(avatar);
  const contextRef = useRef<EntityContext>(null!);
  const events = useMemo(() => new EventEmitter(), []);

  const avatarContext: EntityContext = {
    id: playerAvatarId,
    dto: null!,
    parentId: null,
    type: "entity",
    name: "Player Avatar",
    tags: avatarTags,
    events,
    rootObjectRef: avatarRef,
    physicsBodies: {},
    components: {},
    getPhysicsBody: () => null,
    get position() {
      return avatarRef.current?.position ?? defaultVector;
    },
    set position(value) {
      avatarRef.current?.position.set(value.x, value.y, value.z);
    },
    get rotation() {
      return avatarRef.current?.rotation ?? defaultVector;
    },
    set rotation(value) {
      avatarRef.current?.rotation.set(value.x, value.y, value.z);
    },
    get scale() {
      return avatarRef.current?.scale ?? defaultVector;
    },
    set scale(value) {
      avatarRef.current?.scale.set(value.x, value.y, value.z);
    },
  };

  useEntityContext(contextRef, () => avatarContext);

  useEffect(() => {
    addEntityContext(playerAvatarId, contextRef);

    return () => removeEntityContext(playerAvatarId);
  }, []);

  return { playerAvatarId, contextRef };
}
