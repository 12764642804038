import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket } from "../../sockets";

export class BooleanFalseNode extends BaseNode {
  static readonly type = "logic/booleanFalse";
  static readonly label = "False";
  static readonly category: NodeCategory = "Logic";
  static readonly pure = true;

  outputs = [new BooleanSocket("false")];

  eval(context: NodeEvalContext) {
    context.writeOutput<boolean>("false", false);
  }
}
