import "./color-picker.css";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { Box, Stack } from "@mui/material";

export type ColorPickerProps = {
  name?: any;
  value: string;
  onChange?: (e: OnColorPickerChangeEvent) => void;
};

export type OnColorPickerChangeEvent = {
  target: {
    name: string;
    value: string;
  };
};

export function ColorPicker({ name, value = "black", onChange }: ColorPickerProps) {
  const [open, setOpen] = useState(false);
  const [colorPickerStyle, setColorPickerStyle] = useState({});

  const handleClick = (e: any) => {
    let left = e.clientX - 260;
    let top = e.clientY - 15;
    setColorPickerStyle({ left: `${left}px`, top: `${top}px` });
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (color: any) => {
    onChange?.({
      target: {
        name,
        value: color.hex,
      },
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        maxWidth: "165px",
        width: "100%",
        gridGap: "4px",
      }}
    >
      <Box
        component="div"
        sx={{
          width: "27.25px",
          height: "27.25px",
          transition: ".4s ease",
          borderRadius: "8px",
          "&:hover": {
            opacity: ".7",
          },
        }}
        className="color-picker-swatch"
        onClick={handleClick}
        style={{ background: value }}
      />
      {open && (
        <div className="color-picker-popover" style={colorPickerStyle}>
          <div className="color-picker-cover" onClick={handleClose} />
          <SketchPicker
            presetColors={[]}
            disableAlpha={true}
            color={value}
            onChange={handleChange}
            className="color-picker"
          />
        </div>
      )}
      {/*<TextField*/}
      {/*  sx={{*/}
      {/*    maxWidth: "70px",*/}
      {/*  }}*/}
      {/*  type="text"*/}
      {/*  name="name"*/}
      {/*  placeholder={palette.primary.violet}*/}
      {/*/>*/}
      {/*<TextField*/}
      {/*  sx={{*/}
      {/*    maxWidth: "52px",*/}
      {/*  }}*/}
      {/*  type="text"*/}
      {/*  name="name"*/}
      {/*  placeholder="100%"*/}
      {/*/>*/}
    </Stack>
  );
}
