import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, Vector3Socket } from "../../sockets";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { Vector3Value } from "../../values";

const direction = new Vector3();

export class WorldDirectionNode extends BaseNode {
  static readonly type = "entity/worldDirection";
  static readonly label = "Get world direction";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new Vector3Socket("direction")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const rootObj = node.readEntity(context, "entity")?.rootObjectRef.current;

    if (rootObj) {
      rootObj.getWorldDirection(direction);
      setVector3ValueFromXYZ(node.storage.output, direction);
    }

    context.writeOutput<Vector3Value>("direction", node.storage.output);
  }
}
