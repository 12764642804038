import { useParams } from "react-router-dom";

export const useSceneType = () => {
  const params = useParams();

  if (params.urlName?.includes("Shard_Quest")) {
    return "shardQuest";
  } else if (params.urlName?.includes("hyper") || window.__sceneData?.urlName?.includes("hyper")) {
    return "hyper";
  }

  return "default";
};
