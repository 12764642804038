import { useState } from "react";
import { IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { adminCreateWorkspace, adminDeleteWorkspace, adminUpdateWorkspace } from "~/api/workspace.api";
import IWorkspace from "~/types/IWorkspace";
import { EditWorkspaceDialog } from "./EditWorkspaceDialog";

export type WorkspaceFilterProps = {
  workspaces: IWorkspace[];
  reloadWorkspaces: VoidFunction;
};

export const WorkspaceFilter = ({ workspaces, reloadWorkspaces }: WorkspaceFilterProps) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selected, setSelected] = useState<IWorkspace | null>(null);

  const handleCloseEditDialog = () => {
    setSelected(null);
    setEditDialogOpen(false);
  };

  const handleSave = async () => {
    if (selected?._id) {
      await adminUpdateWorkspace(selected);
    } else {
      await adminCreateWorkspace(selected);
    }
    setSelected(null);
    setEditDialogOpen(false);

    reloadWorkspaces();
  };

  const handleDeleteClick = async () => {
    if (!selected?._id) {
      return;
    }
    await adminDeleteWorkspace(selected._id);
    reloadWorkspaces();
  };

  const handleOpenEditDialog = () => setEditDialogOpen(true);

  const renderWorkspaces = workspaces.map((workspace) => (
    <ListItem
      button
      key={workspace._id}
      onClick={() => setSelected(workspace)}
      selected={workspace._id === selected?._id}
    >
      <ListItemText primary={workspace.name} />
      {workspace._id === selected?._id && (
        <>
          <IconButton sx={{ padding: 0.3 }} onClick={() => setEditDialogOpen(true)}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ padding: 0.3 }} onClick={handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </>
      )}
    </ListItem>
  ));

  return (
    <>
      <EditWorkspaceDialog
        open={editDialogOpen}
        workspace={selected}
        onSave={handleSave}
        onUpdate={(workspace) => setSelected(workspace)}
        onClose={handleCloseEditDialog}
      />
      <>
        <Stack direction="row" marginBottom={2}>
          <Stack justifyContent="center" margin="0 auto">
            <Typography variant="h6" color="secondary">
              Workspaces
            </Typography>
          </Stack>
          <IconButton color="inherit" onClick={handleOpenEditDialog} sx={{ padding: 0.3 }}>
            <AddIcon fontSize="medium" />
          </IconButton>
        </Stack>
        <List component="nav" disablePadding dense={true}>
          {renderWorkspaces}
        </List>
      </>
    </>
  );
};
