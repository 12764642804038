import { ValueType } from "~/libs/behave-graph";

export type AnyValue = any;

export const anyValue = new ValueType(
  "any",
  () => null,
  (text: AnyValue) => text,
  (value: AnyValue) => value
);
