import { Stack, Tooltip, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Monitor, Phone, VR } from "./Icon";

const iconStyles = {
  opacity: ".8",
  transition: ".4s ease",
  "&:hover": {
    opacity: "1",
    color: palette.primary.violet,
  },
};

type WorksOnProps = {
  color?: string;
  margin?: string;
};

export function WorksOn({ color = palette.primary.gray800, margin = "18px 0" }: WorksOnProps) {
  return (
    <Stack direction="row" gap="18px" margin={margin}>
      <Typography variant="body1" fontSize="16px" lineHeight="20px" fontWeight={500} color={color}>
        Works on
      </Typography>
      <Tooltip title="VR Headset" placement="top">
        <span>
          <VR color={color} sx={iconStyles} />
        </span>
      </Tooltip>
      <Tooltip title="Mobile Phone" placement="top">
        <span>
          <Phone color={color} sx={iconStyles} />
        </span>
      </Tooltip>
      <Tooltip title="Desktop" placement="top">
        <span>
          <Monitor color={color} sx={iconStyles} />
        </span>
      </Tooltip>
    </Stack>
  );
}
