import { MeshBasicMaterial } from "three";
import { RenderStream } from "./RenderStream";
import { useCurrentMedia } from "../../media";

type RenderLocalStreamProps = {
  screenShareMaterial: MeshBasicMaterial
}

export function RenderLocalStream({ screenShareMaterial }: RenderLocalStreamProps) {
  const { screenStream } = useCurrentMedia();

  if (!screenStream) {
    return null;
  }

  return <RenderStream mediaStream={screenStream} screenShareMaterial={screenShareMaterial} />
}