import { UpdateAvatarPositionMessage } from "~/view-scene/network";

export const zeroVector3: [number, number, number] = [0, 0, 0];

export const updateAvatarPositionMessage: UpdateAvatarPositionMessage = {
  position: [...zeroVector3],
  velocity: [...zeroVector3],
  head: {
    position: [...zeroVector3],
    rotation: [...zeroVector3],
  },
};
