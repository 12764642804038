import { createEvent, sample } from "effector";
import { $uiSettings, updateUISettings } from "./models";

export const toggleProfiler = createEvent();

sample({
  clock: toggleProfiler,
  source: $uiSettings,
  fn: (uiSettings) => {
    if (!uiSettings) {
      return {};
    }

    return {
      profiler: {
        show: !uiSettings.profiler.show,
      },
    };
  },
  target: updateUISettings,
});
