import { IStorageProvider, StorageData } from "~/types/Storage";

export class MemoryStorage implements IStorageProvider {
  storages: Record<string, StorageData["values"]> = {};

  getStorageData(storageId: string) {
    return this.storages[storageId] ?? null;
  }

  updateStorageData(storageId: string, values: StorageData["values"]) {
    if (!this.storages[storageId]) {
      this.storages[storageId] = {};
    }

    this.storages[storageId] = {
      ...this.storages[storageId],
      ...values,
    };
  }
}
