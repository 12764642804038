import { defaultAsset } from "~/entities/variable";
import { BaseSocket } from "../base";
import { assetImageValue, AssetImageValue } from "../values";

export class AssetImageSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AssetImageValue = defaultAsset()) {
    super(assetImageValue.name, id, label, value);
  }

  clone() {
    return new AssetImageSocket(this.id, this.label, this.value);
  }
}
