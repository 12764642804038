import { InputAdornment, Typography } from "@mui/material";
import { NumericField, NumericFieldProps } from "../NumericField";

export type LabeledNumericFieldProps = NumericFieldProps & {
  label: string;
  hideLabel?: boolean;
};

export const LabeledNumericField = ({ label, hideLabel = false, ...rest }: LabeledNumericFieldProps) => {
  return (
    <NumericField
      {...rest}
      InputProps={
        !hideLabel
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color="primary.violet600" variant="body1">
                    {label}
                  </Typography>
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};
