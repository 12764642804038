import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "./componentValue";

export type TrajectoryValue = ComponentValue;

export const trajectoryValue = new ValueType(
  "trajectory",
  defaultComponent,
  (json: TrajectoryValue) => json,
  (value: TrajectoryValue) => value
);
