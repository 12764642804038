import { EventEmitter } from "eventemitter3";
import { Object3D } from "three";
import { ActivationState } from "~/types/IRigidBodyComponent";
import { RigidBodyType } from "~/types/RigidBodyType";
import { Messenger } from "./Messenger";
import { InitializedBodyConfig, IRpcBody, Vector } from "./types";
import { rigidBodyToCollisionFlags } from "./utils";

export type CollisionEvent = {
  objects: Object3D[];
};

export type CollisionStartEvent = {
  object: Object3D;
  body: PhysicsBody;
  meta: any;
};

export type CollisionEndEvent = {
  object: Object3D;
  body: PhysicsBody;
  meta: any;
};

export interface PhysicsBody {
  on(event: "collision", listener: (e: CollisionEvent) => void): this;
  on(event: "collisionStart", listener: (e: CollisionStartEvent) => void): this;
  on(event: "collisionEnd", listener: (e: CollisionEndEvent) => void): this;

  once(event: "collision", listener: (e: CollisionEvent) => void): this;
  once(event: "collisionStart", listener: (e: CollisionStartEvent) => void): this;
  once(event: "collisionEnd", listener: (e: CollisionEndEvent) => void): this;

  off(event: "collision", listener: (e: CollisionEvent) => void): this;
  off(event: "collisionStart", listener: (e: CollisionStartEvent) => void): this;
  off(event: "collisionEnd", listener: (e: CollisionEndEvent) => void): this;

  emit(event: "collision", e: CollisionEvent): boolean;
  emit(event: "collisionStart", e: CollisionStartEvent): boolean;
  emit(event: "collisionEnd", e: CollisionEndEvent): boolean;
}

export class PhysicsBody extends EventEmitter implements IRpcBody {
  readonly leanerVelocity: [number, number, number] = [0, 0, 0];
  readonly angularVelocity: [number, number, number] = [0, 0, 0];
  private lastCollisions: Record<number, Object3D> = {};

  private activationStateValue: ActivationState;
  private collisionGroupValue: number;
  private collisionMaskValue: number;
  private gravityValue: Vector;

  constructor(
    public readonly uid: number,
    public readonly rigidBodyType: RigidBodyType,
    public readonly ghost: boolean,
    private messenger: Messenger,
    public readonly initialConfig: InitializedBodyConfig
  ) {
    super();
    this.activationStateValue = initialConfig.activationState;
    this.collisionGroupValue = initialConfig.collisionGroup;
    this.collisionMaskValue = initialConfig.collisionMask;
    this.gravityValue = {
      x: 0,
      y: -9.81,
      z: 0,
    };
  }

  get activationState() {
    return this.activationStateValue;
  }

  get collisionGroup() {
    return this.collisionGroupValue;
  }

  get collisionMask() {
    return this.collisionMaskValue;
  }

  get gravity() {
    return this.gravityValue;
  }

  destroy() {
    this.removeAllListeners("collision");
    this.removeAllListeners("collisionStart");
    this.removeAllListeners("collisionEnd");
  }

  nextTick(callback: () => void) {
    let ticks = 0;
    const handler = () => {
      ticks++;
      // callback after 2 physics engine ticks
      if (ticks >= 2) {
        callback();
        this.messenger.off("tick", handler);
      }
    };

    this.messenger.on("tick", handler);
  }

  moveTo(x: number, y: number, z: number) {
    const initialType = this.rigidBodyType;
    const initialActivationState = this.activationState;
    this.forceActivationState(ActivationState.DISABLE_DEACTIVATION);
    this.setVelocity(0, 0, 0);
    this.setRigidBodyType("kinematic", this.ghost);
    const xOffset = this.initialConfig.shape?.offset?.position?.x ?? 0;
    const yOffset = this.initialConfig.shape?.offset?.position?.y ?? 0;
    const zOffset = this.initialConfig.shape?.offset?.position?.z ?? 0;
    this.setPosition(x - xOffset, y - yOffset, z - zOffset);

    this.nextTick(() => {
      this.setRigidBodyType(initialType, this.ghost);
      this.forceActivationState(initialActivationState);
      this.setVelocity(0, 0, 0);

      if (initialType === "dynamic") {
        this.activate(true);
      }
    });
  }

  setVelocity(x: number | undefined, y: number | undefined, z: number | undefined) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setVelocity",
        args: [x, y, z],
      },
    });
  }

  setAngularVelocity(x: number | undefined, y: number | undefined, z: number | undefined) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setAngularVelocity",
        args: [x, y, z],
      },
    });
  }

  setVelocityX(x: number): void {
    this.setVelocity(x, undefined, undefined);
  }

  setVelocityY(y: number): void {
    this.setVelocity(undefined, y, undefined);
  }

  setVelocityZ(z: number): void {
    this.setVelocity(undefined, undefined, z);
  }

  applyForce(x: number, y: number, z: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "applyForce",
        args: [x, y, z],
      },
    });
  }

  applyForceX(x: number) {
    this.applyForce(x, 0, 0);
  }

  applyForceY(y: number) {
    this.applyForce(0, y, 0);
  }

  applyForceZ(z: number) {
    this.applyForce(0, 0, z);
  }

  setFriction(friction: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setFriction",
        args: [friction],
      },
    });
  }

  setRestitution(restitution: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setRestitution",
        args: [restitution],
      },
    });
  }

  setAngularFactor(x: number, y: number, z: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setAngularFactor",
        args: [x, y, z],
      },
    });
  }

  setCcdMotionThreshold(threshold: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setCcdMotionThreshold",
        args: [threshold],
      },
    });
  }

  setCcdSweptSphereRadius(radius: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setCcdSweptSphereRadius",
        args: [radius],
      },
    });
  }

  setGravity(x: number, y: number, z: number) {
    this.gravity.x = x;
    this.gravity.y = y;
    this.gravity.z = z;

    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setGravity",
        args: [x, y, z],
      },
    });
  }

  setCollisionFlags(flags: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setCollisionFlags",
        args: [flags],
      },
    });
  }

  forceActivationState(state: ActivationState) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "forceActivationState",
        args: [state],
      },
    });
  }

  setRigidBodyType(type: RigidBodyType, ghost?: boolean) {
    this.setCollisionFlags(rigidBodyToCollisionFlags(type, ghost ?? this.ghost));
  }

  setPosition(x: number, y: number, z: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setPosition",
        args: [x, y, z],
      },
    });
  }

  activate(forceActivation?: boolean) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "activate",
        args: [forceActivation],
      },
    });
  }

  setCollisionGroup(group: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setCollisionGroup",
        args: [group],
      },
    });

    this.collisionGroupValue = group;
  }

  setCollisionMask(mask: number) {
    this.messenger.sendBodyRpc({
      uid: this.uid,
      call: {
        method: "setCollisionMask",
        args: [mask],
      },
    });

    this.collisionMaskValue = mask;
  }
}
