import { memo, ReactNode, useEffect, useRef } from "react";
import { Group } from "three";
import { IBlockingVolumeSceneObject } from "~/types/IBlockingVolumeSceneObject";
import { usePhysics } from "~/view-scene/physics";
import { RigidBodyType } from "~/types/RigidBodyType";
import { EntityContext, subscribeCollisions } from "~/view-scene/runtime";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "./Entity";

type BlockingVolumeEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const BlockingVolumeEntity = memo(({ entityId, children }: BlockingVolumeEntityProps) => {
  const entity = useEntity<IBlockingVolumeSceneObject>(entityId);

  const entityRef = useRef<Group>(null);
  const context = useRef<EntityContext>({} as EntityContext);
  const physicsManager = usePhysics((state) => state.physicsManager);

  useEffect(() => {
    if (!physicsManager || !entityRef.current) {
      return;
    }

    entityRef.current.userData["eightXRId"] = entity.id;
    const body = physicsManager.addBody(
      entityRef.current,
      { entityId, rigidBodyId: "root" },
      {
        type: "static",
        shape: entity.shape,
      }
    );

    context.current.physicsBodies["root"] = body;
    subscribeCollisions(context);

    return () => {
      if (body) {
        physicsManager.removeBody(body.uid);
      }
    };
  }, [physicsManager]);

  return (
    <Entity entityId={entityId} ref={entityRef} context={context}>
      {children}
    </Entity>
  );
});
