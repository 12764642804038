import { EventsManager } from "./EventsManager";
import { VariablesManager } from "./VariablesManager";

export abstract class Script {
  public hasTick: boolean = false;
  public variables: VariablesManager = null!;
  public events: EventsManager = null!;

  constructor(public readonly id: string) {}

  abstract tick(delta: number): void;
  abstract dispose(): void;
  abstract init(): void;
  abstract start(): void;
  abstract gameSessionStarted(): void;
  abstract storageUpdate(storageId: string): void;
}
