import { NodeEvalContext } from "~/libs/behave-graph";
import { BlockUIEntity } from "~/types/ui";
import { UIBlockContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket, UIBlockSocket } from "../../sockets";
import { UIBlockValue } from "../../values";

export class UIBlockSetSizeNode extends BaseNode {
  static readonly type = "action/uiBlockSetSize";
  static readonly label = "UI Block Set Size";

  inputs = [
    new FlowSocket("flow"),
    new UIBlockSocket("uiBlock"),
    new NumberSocket("width"),
    new NumberSocket("height"),
  ];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const width = context.readInput<number>("width");
    const height = context.readInput<number>("height");
    const blockValue = context.readInput<UIBlockValue>("uiBlock");

    node.sceneContext.getUIEntityContext<UIBlockContext>(blockValue.entityId ?? "")?.updateEntity<BlockUIEntity>({
      width,
      height,
    });
  }
}
