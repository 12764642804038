import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export function LoginButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenLoginDialogClick = () => {
    const param = searchParams.get("auth");
    if (!param) {
      searchParams.append("auth", "login");
      setSearchParams(searchParams);
    }
  };

  return (
    <Button variant="outlined" size="large" onClick={handleOpenLoginDialogClick}>
      Log in
    </Button>
  );
}
