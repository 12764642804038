import { RefObject, useEffect } from "react";
import { Socket } from "socket.io-client";
import { Bridge } from "./Bridge";
import { sendPVPGameResult } from "~/api/multiplayer";

export type UseMultiplayerConfig = {
  enabled: boolean;
  socket: Socket | undefined;
  connected: boolean;
  iframeRef: RefObject<HTMLIFrameElement>;
};

export const useMultiplayer = ({ socket, enabled, connected, iframeRef }: UseMultiplayerConfig) => {
  useEffect(() => {
    if (socket && connected && enabled) {
      const bridge = new Bridge(socket, iframeRef.current!);

      return () => {
        bridge.dispose();
      };
    }
  }, [socket, connected, enabled]);

  useEffect(() => {
    async function subscribe(event: any) {
      const data = event.data;
      if (data.eventName === "pvpGameResult") {
        sendPVPGameResult(data.gameId, data.winner, data.loser);
      }
    }

    window.addEventListener("message", subscribe);

    return () => {
      window.removeEventListener("message", subscribe);
    };
  }, []);
};
