import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

export type LeaderboardEntry = {
  score: number;
  telegramId: string;
  userId: string;
  username: string;
};

export const getLeaderboard = async (gameId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/leaderboard/${gameId}`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as Array<LeaderboardEntry>;
  return { status, body };
};

export const getLeaderboardReport = async (gameId: string, date: number) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/leaderboard/history/${gameId}/${date}`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};
