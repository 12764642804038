import { GroupProps, useFrame } from "@react-three/fiber";
import { Interactive, XRInteractionEvent } from "@react-three/xr";
import { useStoreMap } from "effector-react";
import { memo, useCallback, useMemo, useRef } from "react";
import { Group, Mesh, Vector3 } from "three";
import { video360Layers } from "~/view-scene/layers";
import { $video360State, setVideo360State } from "~/view-scene/runtime";

export type ProgressProps = Pick<GroupProps, "position"> & {
  width?: number;
  height?: number;
};

export const Progress = memo(({ position, width = 1, height = 0.01 }: ProgressProps) => {
  const video = useStoreMap($video360State, (state) => state.video);
  const lineContainerRef = useRef<Group>(null);
  const linesRef = useRef<Group>(null);
  const overlayRef = useRef<Mesh>(null);

  const { lineContainerPosition, linePosition } = useMemo(() => {
    return {
      lineContainerPosition: new Vector3(-width / 2, 0, 0),
      linePosition: new Vector3(width / 2, 0, 0),
    };
  }, [width]);

  useFrame(() => {
    if (!lineContainerRef.current) {
      return;
    }

    const scaleX = video ? video.currentTime / video.duration : 0;
    lineContainerRef.current.scale.set(scaleX, 1, 1);
  });

  const handleHover = useCallback((e: XRInteractionEvent) => {
    linesRef.current?.scale.set(1, 1.1, 1);
  }, []);

  const handleBlur = useCallback(() => {
    linesRef.current?.scale.set(1, 1, 1);
  }, []);

  const handleSelect = useCallback((e: XRInteractionEvent) => {
    if (e.intersection && overlayRef.current) {
      const linePosition = new Vector3();
      overlayRef.current.getWorldPosition(linePosition);
      const start = overlayRef.current.localToWorld(new Vector3(-width / 2, 0, 0));
      const end = overlayRef.current.localToWorld(new Vector3(width / 2, 0, 0));
      const progressVector = new Vector3().subVectors(end, start);
      const c = new Vector3().subVectors(e.intersection.point, start);
      const progressLength = progressVector.length();
      const projection = c.projectOnVector(progressVector);
      const clickLength = projection.length();

      const percent = clickLength / progressLength;
      setVideo360State({ position: percent });
    }
  }, []);

  return (
    <group position={position}>
      <group ref={linesRef}>
        <mesh layers={video360Layers}>
          <planeGeometry args={[width, height]} />
          <meshBasicMaterial color="#ffffff" transparent opacity={0.2} />
        </mesh>
        <group position={lineContainerPosition} layers={video360Layers} ref={lineContainerRef}>
          <mesh position={linePosition} layers={video360Layers}>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color="#9f92ff" />
          </mesh>
        </group>
      </group>
      <Interactive onHover={handleHover} onBlur={handleBlur} onSelect={handleSelect}>
        <mesh scale={overlayScale} layers={video360Layers} ref={overlayRef}>
          <planeGeometry args={[width, height]} />
          <meshBasicMaterial color="red" transparent opacity={0} />
        </mesh>
      </Interactive>
    </group>
  );
});

const overlayScale = new Vector3(1, 2, 1);
