import { useFrame } from "@react-three/fiber";
import { useUnmount } from "react-use";
import { usePhysics } from "./usePhysics";

export const PhysicsSystem = () => {
  const { physicsManager, reset } = usePhysics();

  useUnmount(() => {
    reset();
  });

  useFrame(() => physicsManager?.update());

  return null;
};
