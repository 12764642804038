export const palette = {
  primary: {
    light: "#74d9ff",
    main: "#151515",
    dark: "#212121",
    dark200: "#0E0F1D",
    white: "#ffffff",
    black: "#000000",
    red50: "#FF8686",
    red100: "rgba(255, 121, 121, 0.1)",
    red150: "#674343",
    red200: "#FF7979",
    red2000: "rgba(255, 121, 121, 0.2)",
    red600: "rgba(255, 121, 121, 0.6)",
    black100: "rgba(0, 0, 0, 0.1)",
    black200: "rgba(0, 0, 0, 0.2)",
    black400: "rgba(0, 0, 0, 0.4)",
    black500: "rgba(0, 0, 0, 0.5)",
    black600: "rgba(0, 0, 0, 0.6)",
    black700: "rgba(0, 0, 0, 0.7)",
    black800: "rgba(0, 0, 0, 0.8)",
    black900: "rgba(0, 0, 0, 0.9)",
    blue400: "#184A6D",
    blue200: "#047FD8",
    gray: "#ADADAD",
    gray10: "rgba(255, 255, 255, 0.03)",
    gray20: "rgba(255, 255, 255, 0.05)",
    gray40: "rgba(255, 255, 255, 0.075)",
    gray50: "rgba(255, 255, 255, 0.07)",
    gray100: "rgba(255, 255, 255, 0.1)",
    gray110: "rgba(255, 255, 255, 0.11)",
    gray200: "rgba(255, 255, 255, 0.2)",
    gray300: "rgba(255, 255, 255, 0.3)",
    gray400: "rgba(255, 255, 255, 0.4)",
    gray500: "rgba(255, 255, 255, 0.5)",
    gray600: "rgba(255, 255, 255, 0.6)",
    gray800: "rgba(255, 255, 255, 0.8)",
    gray900: "#262626",
    green: "#00E376",
    green200: "#177246",
    green400: "rgba(111, 255, 33, 0.4)",
    green600: "rgba(111, 255, 33, 0.6)",
    greenToxic: "#6FFF21",
    warning100: "#e38800",
    error100: "#ff7979",
    success100: "#07b275",
    brand100: "#9f92ff",
    brand200: "#D3C1FF",
    contrastText: "#000000",
    violet: "#9F92FF",
    violet200: "rgba(159, 146, 255, 0.2)",
    violet400: "rgba(159, 146, 255, 0.4)",
    violet450: "#877CD4",
    violet500: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #9F92FF",
    violet600: "rgba(159, 146, 255, 0.6)",
    violet800: "rgba(159, 146, 255, 0.8)",
    gradient300: "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #000000;",
    gradient400: "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #000000;",
    gradient500: "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;",
    gradient600: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #000000;",
    gradient700: "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #313131;",
    gradientViolet: " linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #9F92FF",
  },
  secondary: {
    main: "#f4f4f4",
  },
  info: {
    main: "rgba(255, 255, 255, 0.6)",
  },
  shardQuest: {
    blue: "#007EFB",
  },
};
