import { attach, createEvent, forward } from "effector";
import { $scriptExecutor } from "./executor";

export const unregisterScript = createEvent<string>();

const unregisterFx = attach({
  source: $scriptExecutor,
  effect: (executor, id: string) => {
    if (executor) {
      return executor.unregister(id);
    }
  },
});

forward({
  from: unregisterScript,
  to: unregisterFx,
});
