import { GlobalStyles as MuiGlobalStyles } from "@mui/material";
import { palette } from "./palette";

export function GlobalStyles() {
  return (
    <MuiGlobalStyles
      styles={() => ({
        input: {
          fontFamily: "Plus Jakarta Sans",
        },
        select: {
          fontFamily: "Plus Jakarta Sans",
        },
        fieldset: {
          border: "none !important",
        },
        p: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        a: {
          fontFamily: "Plus Jakarta Sans",
        },
        h1: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        h2: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        h3: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        h4: {
          margin: 0,
          fontFamily: "Plus Jakarta Sans",
          padding: 0,
        },
        h5: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        h6: {
          fontFamily: "Plus Jakarta Sans",
          margin: 0,
          padding: 0,
        },
        html: {
          height: "100%",
          fontFamily: "Plus Jakarta Sans",
          width: "100%",
        },
        "*, *::before, *::after": {
          boxSizing: "inherit",
        },
        body: {
          backgroundImage: "none!important",
          fontFamily: "Plus Jakarta Sans",
          fontSize: "16px",
          margin: 0,
          WebkitOverflowScrolling: "touch",
          padding: 0,
          "&::-webkit-scrollbar": {
            width: "px",
          },
          "&::-webkit-scrollbar-track": {
            background: palette.primary.dark,
          },
          "&::-webkit-scrollbar-thumb": {
            background: palette.primary.gray200,
          },
        },
        "#root": {
          minWidth: "auto!important",
          minHeight: "auto!important",
        },
      })}
    />
  );
}
