import { useUnit } from "effector-react";
import { forwardRef, useEffect, useMemo } from "react";
import { BackSide, Mesh, MeshBasicMaterial, SphereGeometry, VideoTexture } from "three";
import { Layer } from "~/view-scene/layers";
import { $video360State, setVideo360State } from "~/view-scene/runtime";
import { isIOS } from "~/common/utils/isMobile";
import { isDesktopSafari } from "~/common/utils/isDesktopSafari";
import { useTexture } from "~/view-scene/texture";

export type RenderVideo360Props = {
  url: string;
  assetName: string;
  isStream: boolean;
  active: boolean;
  layer?: number;
};

export const RenderVideo360 = forwardRef<Mesh | null, RenderVideo360Props>(
  ({ url, assetName, isStream, active, layer = Layer.default }, ref) => {
    const { position } = useUnit($video360State);

    const sphere = useMemo(() => {
      const geometry = new SphereGeometry(500, 60, 40);
      const sphere = new Mesh(geometry);
      sphere.scale.x = -1;
      sphere.layers.set(layer);

      return sphere;
    }, []);

    const videoTexture = useTexture({
      type: "video",
      name: assetName,
      url: url,
      isStream,
      loop: false,
      flipY: true,
      audioAttachTo: sphere,
      volume: 1,
      autoplay: false,
      audioSourceType: "video360",
      distanceModel: "inverse",
      maxDistance: 10000,
      refDistance: 1,
      rolloffFactor: 4,
    }) as VideoTexture | undefined;

    useEffect(() => {
      const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
      if (!htmlVideo) {
        return;
      }

      if (active) {
        const setDuration = () => {
          setVideo360State({
            duration: htmlVideo.duration * 1000,
            video: htmlVideo,
            initialized: true,
          });
        };

        if (htmlVideo.duration) {
          setDuration();
        } else {
          htmlVideo.addEventListener("loadedmetadata", () => setDuration(), { once: true });
        }
      } else {
        htmlVideo.pause();
      }
    }, [active, videoTexture]);

    useEffect(() => {
      const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
      if (!active || !htmlVideo) {
        return;
      }

      const playHandler = () => {
        setVideo360State({ play: true });
      };

      const pauseHandler = () => {
        setVideo360State({ play: false });
      };

      const endedHandler = () => {
        setVideo360State({ play: false });
      };

      htmlVideo.addEventListener("play", playHandler);
      htmlVideo.addEventListener("pause", pauseHandler);
      htmlVideo.addEventListener("ended", endedHandler);

      return () => {
        htmlVideo.removeEventListener("play", playHandler);
        htmlVideo.removeEventListener("pause", pauseHandler);
        htmlVideo.removeEventListener("ended", endedHandler);
      };
    }, [active, videoTexture]);

    const material = useMaterial(videoTexture);

    useEffect(() => {
      if (material) {
        material.side = BackSide;
        sphere.material = material;
      }
    }, [sphere, material]);

    useEffect(() => {
      const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
      if (!active || !htmlVideo) {
        return;
      }

      if (!isIOS() && !isDesktopSafari()) {
        htmlVideo.play();
      }
    }, [active, videoTexture]);

    useEffect(() => {
      const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
      if (active && htmlVideo) {
        htmlVideo.currentTime = htmlVideo.duration * position;
      }
    }, [active, position, videoTexture]);

    return active ? <primitive ref={ref} object={sphere} /> : null;
  }
);

const useMaterial = (texture?: VideoTexture) => {
  return useMemo(() => {
    if (!texture) {
      return null;
    }

    const material = new MeshBasicMaterial();
    material.map = texture;

    return material;
  }, [texture]);
};
