import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import IUser from "~/types/IUser";
import IWorkspace from "~/types/IWorkspace";
import Button from "~/common/components/Button";
import Select from "~/common/components/Select";
import { TextField } from "~/hephaestus/components/TextField";

export type EditUserDialog = {
  open: boolean;
  user: IUser;
  workspaces: IWorkspace[];
  onSave: (user: IUser) => void;
  onConfirmEmail: (id: string) => void;
  onClose: VoidFunction;
};

export const EditUserDialog = ({ open, user, workspaces, onSave, onConfirmEmail, onClose }: EditUserDialog) => {
  const [updatedUser, setUpdatedUser] = useState(user);

  const handleUserUpdate = (event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const updated = { ...updatedUser, [event.target.name]: value };
    setUpdatedUser(updated);
  };

  const handleWorkspaceUpdate = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value ?? [];
    const newWorkspaces = workspaces.filter((w) => w._id && value.indexOf(w._id) !== -1);
    setUpdatedUser({ ...updatedUser, workspaces: [...newWorkspaces] });
  };

  const isNewUser = updatedUser?._id == null;

  useEffect(() => setUpdatedUser(user), [user]);

  const dialogTitle = isNewUser ? "Edit User" : "Create User";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="center" padding={1}>
          <Typography variant="h5" color="secondary">
            {dialogTitle}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "450px", padding: 4, paddingBottom: 3 }}>
        <Stack spacing={3}>
          <Typography variant="body2">
            Please fill in User details and click the "Save" button to save the changes or "Cancel" to stop editing and
            discard any changes immediately.
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Name"
            size="small"
            autoFocus
            name="name"
            value={updatedUser?.name}
            fullWidth={true}
            onChange={handleUserUpdate}
          />
          <TextField
            variant="outlined"
            placeholder="Email"
            name="email"
            value={updatedUser?.email}
            type="email"
            fullWidth
            onChange={handleUserUpdate}
            size="small"
          />
          <Select
            name="role"
            value={updatedUser?.role ?? "user"}
            fullWidth
            onChange={handleUserUpdate}
            placeholder="Role"
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
          <Select
            name="workspaces"
            // variant="outlined"
            fullWidth
            // size="small"
            multiple
            placeholder="Workspaces"
            value={updatedUser?.workspaces?.map((w) => w._id ?? "") ?? []}
            onChange={handleWorkspaceUpdate as any}
            renderValue={(selected: any) => (
              <Box component="div" sx={{ display: "flex", flexWrap: "wrap" }}>
                {selected.map((workspaceId: any) => (
                  <Chip
                    key={workspaceId}
                    label={updatedUser?.workspaces?.find((ws) => ws._id === workspaceId)?.name}
                    sx={{ margin: "4px" }}
                    variant="filled"
                  />
                ))}
              </Box>
            )}
          >
            {workspaces.map((workspace) => (
              <MenuItem key={workspace._id} value={workspace._id}>
                {workspace.name}
              </MenuItem>
            ))}
          </Select>
          {updatedUser._id != null && (
            <Button color="secondary" onClick={() => onConfirmEmail(updatedUser._id!)}>
              Confirm Email
            </Button>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 4 }}>
        <Button color="inherit" size="small" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={() => onSave(updatedUser)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
