import { HTMLProps } from "react";
import { Stack } from "@mui/material";
import { palette } from "~/theme";

export type ToolbarGroupProps = HTMLProps<HTMLDivElement>;

export function ToolbarGroup({ className, children }: ToolbarGroupProps) {
  return (
    <Stack
      className={className}
      direction="row"
      alignItems="center"
      sx={{
        padding: "0 4px",
        gridGap: "8px",
        zIndex: 10,
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #000000",
        border: "1px solid " + palette.primary.gray40,
        height: "36px",
        borderRadius: "12px",
      }}
    >
      {children}
    </Stack>
  );
}
