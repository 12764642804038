import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

export const getSessionsHistoryReport = async (startDate: number, endDate: number) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/session/history?startDate=${startDate}&endDate=${endDate}`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};

export const getRetentionReport = async (startDate: number, gameId: string | null) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const optionalGameIdParam = gameId ? `/${gameId}` : "";
  const url = `${API_HOST}/session/retention/${startDate}${optionalGameIdParam}`;
  const response = await fetch(url, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};

export const getMAUReport = async (startDate: number) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/session/mau/${startDate}`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};
