import ISkyboxAsset from "~/types/ISkyboxAsset";
import { Environment } from "@react-three/drei";
import { useCubeMap } from "./useCubeMap";

type RenderCubemapEnvironmentType = {
  background: boolean | "only";
  cubemapAsset: ISkyboxAsset;
};

export function RenderCubemapEnvironment({ background, cubemapAsset }: RenderCubemapEnvironmentType) {
  const cubeMap = useCubeMap(cubemapAsset);

  return cubeMap ? <Environment background={background} files={cubeMap} /> : null;
}
