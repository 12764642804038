import { Select, SelectItem, SelectProps } from "./Select";

export type InteractionTypeSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const InteractionTypeSelect = ({
  emptyLabel = "Select Interaction Type",
  value,
  ...rest
}: InteractionTypeSelectProps) => {
  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      <SelectItem key="pointerUp" value="pointerUp">
        Pointer Up
      </SelectItem>
      <SelectItem key="pointerDown" value="pointerDown">
        Pointer Down
      </SelectItem>
      <SelectItem key="pointerOver" value="pointerOver">
        Pointer Over
      </SelectItem>
      <SelectItem key="pointerOut" value="pointerOut">
        Pointer Out
      </SelectItem>
      <SelectItem key="pointerEnter" value="pointerEnter">
        Pointer Enter
      </SelectItem>
      <SelectItem key="pointerLeave" value="pointerLeave">
        Pointer Leave
      </SelectItem>
      <SelectItem key="pointerMove" value="pointerMove">
        Pointer Move
      </SelectItem>
    </Select>
  );
};
