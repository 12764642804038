import { ActivationState } from "~/types/IRigidBodyComponent";
import { RigidBodyType } from "~/types/RigidBodyType";

export const getDefaultActivationState = (bodyType: RigidBodyType) => {
  switch (bodyType) {
    case "static":
      return ActivationState.DEACTIVATE;
    case "dynamic":
      return ActivationState.ENABLE_DEACTIVATION;
    case "kinematic":
      return ActivationState.DISABLE_DEACTIVATION;
  }
};
