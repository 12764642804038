import { useStore } from "effector-react";
import { useMemo } from "react";
import { useProfile } from "~/entities/profile";
import { $storagesMap, MemoryStorage } from "~/entities/storages";
import { IStorageProvider } from "~/types/Storage";

export const useUserStorageProvider = () => {
  const { isAuthorised, getStorageData, updateStorageData } = useProfile((state) => ({
    isAuthorised: state.isAuthorised,
    getStorageData: state.getStorageData,
    updateStorageData: state.updateStorageData,
  }));
  const storagesMap = useStore($storagesMap);
  const memoryStorage = useMemo(() => new MemoryStorage(), []);

  return useMemo(() => {
    if (!isAuthorised()) {
      return memoryStorage as IStorageProvider;
    }

    const provider: IStorageProvider = {
      getStorageData: (storageId) => {
        if (storagesMap[storageId].persistent) {
          return getStorageData(storageId);
        } else {
          return memoryStorage.getStorageData(storageId);
        }
      },
      updateStorageData: (storageId, values) => {
        if (storagesMap[storageId].persistent) {
          updateStorageData(storageId, values);
        } else {
          memoryStorage.updateStorageData(storageId, values);
        }
      },
    };

    return provider;
  }, [storagesMap]);
};
