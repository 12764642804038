import { NodeContext } from "../../base";
import { UITextSocket } from "../../sockets/UITextSocket";
import { CastToUIEntityNode } from "./CastToUIEntityNode";

export class CastToUITextNode extends CastToUIEntityNode {
  static readonly type = "logic/castToUIText";
  static readonly label = "Cast to UI Text";

  constructor(context: NodeContext) {
    super(context, "text", new UITextSocket("uiText"));
  }
}
