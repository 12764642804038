export const getAudioDeviceStream = async (deviceName?: string | null) => {
  const constraints = {
    audio: {
      deviceId: deviceName ? { exact: deviceName } : undefined,
    },
  };

  return navigator.mediaDevices.getUserMedia(constraints);
};

export const getScreenStream = async () => navigator.mediaDevices.getDisplayMedia();

export const toggleStream = (stream: MediaStream, flag?: boolean) => {
  stream.getAudioTracks().forEach((track) => {
    track.enabled = toggleValue(track.enabled, flag);
  });
};

export const stopStream = (stream: MediaStream) => {
  stream.getAudioTracks().forEach((track) => track.stop());
  stream.getVideoTracks().forEach((track) => track.stop());
};

export const toggleValue = (currentValue: boolean, newValue?: boolean) => {
  if (newValue !== undefined) {
    return newValue;
  }

  return !currentValue;
};
