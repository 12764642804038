import { Icon, IconProps } from "./Icon";

export type MicrophoneOffProps = IconProps;

export const MicrophoneOff = (props: MicrophoneOffProps) => (
  <Icon width="22px" height="22px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 11v1a8 8 0 0 0 14.138 5.132M1 1l20 20M15 9.4V6a4 4 0 0 0-6.53-3.1M11 16a4 4 0 0 1-4-4V7l7.281 7.288A3.995 3.995 0 0 1 11 16Z"
    />
  </Icon>
);
