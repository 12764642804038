import { attach } from "effector";
import { $audioManager } from "./audioManager";

export const removeStreamFromOutboundAudio = attach({
  source: $audioManager,
  effect: ({ audioNodes }, id: string) => {
    if (!audioNodes.has(id)) {
      return;
    }
    const node = audioNodes.get(id);
    if (node) {
      node.sourceNode.disconnect();
      node.gainNode.disconnect();
      audioNodes.delete(id);
    }
  },
});
