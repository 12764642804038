import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ConstraintSocket, FlowSocket } from "../../sockets";
import { ConstraintValue } from "../../values";

export class RemoveConstraintNode extends BaseNode {
  static readonly type = "constraints/removeConstraint";
  static readonly label = "Remove Constraint";

  inputs = [new FlowSocket("flow"), new ConstraintSocket("constraint")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const constraint = context.readInput<ConstraintValue>("constraint");

    if (!constraint.entityId || !constraint.componentId) {
      return;
    }

    node.sceneContext.removeComponent(constraint.entityId, constraint.componentId);
  }
}
