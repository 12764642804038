import { useEffect, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useSceneData } from "~/common/stores/useSceneData";
import { onLoadEnd, onLoadStart, setProviders } from "./models";
import {
  BaseAdsProvider,
  DevAdsProvider,
  MyTargetAdsProvider,
  AipAdsProvider,
  ApplixirAdsProvider,
  KukushkaAdsProvider,
} from "./providers";
import { useProfile } from "~/entities/profile";
import { RewardedVideoProvider } from "~/types/system";

export const RewardedVideoSystem = () => {
  const preloadOverlay = useRef<HTMLDivElement | null>(null);
  const rewardedVideoSystem = useSceneData((state) => state.sceneState?.rewardedVideoSystem);
  const profileUserId = useProfile((state) => state.profile?.id);
  const userId = useMemo(() => {
    return profileUserId || uuid();
  }, [profileUserId]);

  useEffect(() => {
    if (!rewardedVideoSystem) {
      return;
    }

    const providers: BaseAdsProvider[] = [];

    if (!rewardedVideoSystem.isInProduction) {
      providers.push(new DevAdsProvider());
    } else {
      rewardedVideoSystem.providers
        .filter((provider) => provider.enabled)
        .forEach((provider) => {
          const providerInstance = providerFactory(provider, userId);
          providerInstance.init();
          providers.push(providerInstance);
        });
    }

    setProviders(providers);

    return () => {
      providers.forEach((provider) => {
        provider.dispose();
      });
    };
  }, [rewardedVideoSystem, userId]);

  useEffect(() => {
    preloadOverlay.current = document.createElement("div");
    preloadOverlay.current.id = "ads-preload-overlay";
    preloadOverlay.current.style.display = "none";
    preloadOverlay.current.style.position = "absolute";
    preloadOverlay.current.style.inset = "0";
    preloadOverlay.current.style.zIndex = "100";
    preloadOverlay.current.style.backgroundColor = "#000000";
    preloadOverlay.current.style.backgroundImage = "url('/static/img/content/loading.gif')";
    preloadOverlay.current.style.backgroundRepeat = "no-repeat";
    preloadOverlay.current.style.backgroundPosition = "center";
    preloadOverlay.current.style.backgroundSize = "100px";
    document.body.appendChild(preloadOverlay.current);

    const loadStartSubscription = onLoadStart.watch(() => {
      if (preloadOverlay.current) {
        preloadOverlay.current.style.display = "block";
      }
    });

    const loadEndSubscription = onLoadEnd.watch(() => {
      if (preloadOverlay.current) {
        preloadOverlay.current.style.display = "none";
      }
    });

    return () => {
      loadStartSubscription.unsubscribe();
      loadEndSubscription.unsubscribe();
      preloadOverlay.current?.remove();
    };
  }, []);

  return null;
};

const providerFactory = (provider: RewardedVideoProvider, userId: string): BaseAdsProvider => {
  switch (provider.adNetwork) {
    case "Applixir":
      return new ApplixirAdsProvider(provider.accountId, provider.zoneId, provider.gameId, userId);
    case "MyTarget":
      return new MyTargetAdsProvider(provider.slotId);
    case "Aip":
      return new AipAdsProvider();
    case "Kukushka":
      return new KukushkaAdsProvider(userId, provider.gameKey);
  }
};
