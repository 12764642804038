import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ActionableSocket } from "../../sockets/ActionableSocket";
import { FlowSocket } from "../../sockets/FlowSocket";
import { ActionableComponentContext } from "~/view-scene/runtime";

export class ActionableEnableNode extends BaseNode {
  static readonly type = "action/actionableEnable";
  static readonly label = "Enable Actionable";

  inputs = [new FlowSocket("flow"), new ActionableSocket("actionable")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<ActionableComponentContext>(context, "actionable")?.enable();
  }
}
