import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode } from "../../base";
import { FlowSocket } from "../../sockets";
import { MethodBaseNode } from "./MethodBaseNode";

export class MethodInNode extends MethodBaseNode {
  static type = "method/in";
  static label = "Function In";

  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    //TODO
  }

  getOutputs(nodeSpec: NodeSpec) {
    return [...super.getOutputs(nodeSpec), ...this.getMethodOutputSockets(nodeSpec)];
  }
}
