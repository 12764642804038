import { BoxGeometry, Mesh, MeshBasicMaterial, Object3D } from "three";
import { getBoundingBoxMatrix } from "./getBoundingBoxMatrix";

const material = new MeshBasicMaterial({ color: 0xffcc55, opacity: 0.1, transparent: true });
export const boundingBoxGeometry = new BoxGeometry(1, 1, 1);

export const getBoundingBox = (mesh: Object3D) => {
  const { matrix } = getBoundingBoxMatrix(mesh);
  const boxGeo = boundingBoxGeometry.clone();
  const boundingBox = new Mesh(boxGeo, material);
  boundingBox.applyMatrix4(matrix);

  return boundingBox;
};
