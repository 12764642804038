import { Stack, Typography, css, styled } from "@mui/material";
import { palette } from "~/theme";
import { User } from "~/common/components/Icon";

interface Info {
  text: string;
  avatar?: string | null;
}

function Avatar(props: Info) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        borderRadius: 32,
        backgroundColor: palette.primary.gray100,
        // maxWidth: "fit-content",
        color: palette.primary.white,
        fontSize: 16,
        transition: ".4s ease",
        backdropFilter: "blur(20px)",
        textTransform: "inherit",
        padding: "4px 12px 4px 4px",
        gridGap: "8px",
        fontWeight: 500,
        border: "1px solid " + palette.primary.gray100,
      }}
    >
      {props.avatar ? <UserImage src={props.avatar} /> : <UserIcon color="primary.white" />}
      <Typography variant="body1">{props.text}</Typography>
    </Stack>
  );
}

const userStyles = () => css`
  width: 28px;
  height: 28px;
  border: 1px solid ${palette.primary.gray400};
  border-radius: 50%;
  transition: 0.4s ease;
`;

const UserIcon = styled(User)`
  ${userStyles}
`;
const UserImage = styled("img")`
  ${userStyles}
`;

export default Avatar;
