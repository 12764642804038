import { MenuItem, MenuItemProps, styled } from "@mui/material";
import { palette } from "~/theme";

export const SelectItem = styled(({ children, ...rest }: MenuItemProps) => <MenuItem {...rest}>{children}</MenuItem>)(() => ({
  gridGap: "6px",
  color: palette.primary.gray600,
  fontSize: "12px",
  fontWeight: "400",
  textDecoration: "none",
  lineHeight: "18px",
  padding: "7px 6px",
  borderRadius: "8px",
  textAlign: "left",
  justifyContent: "space-between",
  minHeight: "32px",
  position: "relative",
  opacity: 1,
  "&.Mui-selected": {
    background: palette.primary.gradient500,
  },
  "&:hover": {
    background: palette.primary.gradient500,
  },
}));
