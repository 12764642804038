import { ValueType } from "~/libs/behave-graph";
import { defaultUIEntity } from "~/entities/variable";
import { UIEntityValue } from "~/types/Variable";

export type { UIEntityValue };

export const uiEntityValue = new ValueType(
  "uiEntity",
  defaultUIEntity,
  (text: UIEntityValue) => text,
  (value: UIEntityValue) => value
);
