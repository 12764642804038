import { memo } from "react";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { DesktopDiveIn } from "./DesktopDiveIn";
import { VRDiveIn } from "./VRDiveIn";

export const DiveInSystem = memo(() => {
  const deviceMode = useSessionStatus((state) => state.mode);

  return deviceMode === "desktop" ? <DesktopDiveIn /> : deviceMode === "vr" ? <VRDiveIn /> : null;
});
