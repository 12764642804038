import { attach, createEvent, createStore } from "effector";
import { ActionEvent, KeyboardActionSource } from "~/hotkeys";
import { EditorAction, editorKeyMap } from "../editorKeyMap";

export const $actionSource = createStore(new KeyboardActionSource(editorKeyMap));
export const action = createEvent<ActionEvent<EditorAction>>();

const subscribeFx = attach({
  source: $actionSource,
  effect: (actionSource) => {
    actionSource.on("action", (e) => action(e));
  },
});

subscribeFx();
