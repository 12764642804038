import { Box, Stack, styled, ThemeProvider } from "@mui/material";
import { gamerTheme } from "~/theme";
import { HomePageContainer } from "../../home/HomePageContainer";
import { BarController, BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import { DAUChart } from "./DAUChart";
import { MAUChart } from "./MAUChart";
import { RetentionChart } from "./RetentionChart";
import { API_HOST } from "~/config";
import { DownloadChart } from "~/pages/telegram/analytics/DownloadChart";
import { useParams } from "react-router-dom";

export type AnalyticsParams = {
  gameId: string;
  cohortReferer?: string;
};

Chart.register(BarElement, BarController, CategoryScale, LinearScale);
export const TelegramAnalyticsPage = () => {
  let { gameId, cohortReferer } = useParams();
  let downloadPostfix = "/CSV";

  if (!gameId) {
    gameId = "tongochi_bot";
  }

  if (cohortReferer) downloadPostfix += "?cohortReferer=" + cohortReferer;

  return (
    <ThemeProvider theme={gamerTheme}>
      <HomePageContainer>
        <Stack flexGrow={1}>
          <PageTitle>{gameId}</PageTitle>
          <ChartsRow>
            <ChartContainer>
              <ChartsTitle>DAU</ChartsTitle>
              <DAUChart gameId={gameId} cohortReferer={cohortReferer} />
              <DownloadChart url={`${API_HOST}/api/events/${gameId}/dau` + downloadPostfix} name="DAU_report" />
            </ChartContainer>
            <ChartContainer>
              <ChartsTitle>MAU</ChartsTitle>
              <MAUChart gameId={gameId} cohortReferer={cohortReferer} />
              <DownloadChart url={`${API_HOST}/api/events/${gameId}/mau` + downloadPostfix} name="MAU_report" />
            </ChartContainer>
            <ChartContainer>
              <ChartsTitle>Retention</ChartsTitle>
              <RetentionChart gameId={gameId} cohortReferer={cohortReferer} />
              <DownloadChart
                url={`${API_HOST}/api/events/${gameId}/retention` + downloadPostfix}
                name="Retention_report"
              />
            </ChartContainer>
          </ChartsRow>
        </Stack>
      </HomePageContainer>
    </ThemeProvider>
  );
};

const ChartsRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartContainer = styled(Box)`
  position: relative;
  flex: 0 48%;

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const PageTitle = styled("h1")`
  text-align: center;
  text-transform: uppercase;
  color: white;
`;

const ChartsTitle = styled("h3")`
  text-align: center;
  color: white;
`;
