import { ReactNode, useMemo, useState } from "react";
import { UIEntitiesContext, UIEntitiesContextValue } from "./UIEntitiesContext";

export type UIEntitiesProviderProps = {
  hierarchy: UIEntitiesContextValue["hierarchy"];
  children: ReactNode;
};

export const UIEntitiesProvider = ({ hierarchy, children }: UIEntitiesProviderProps) => {
  const [entitySizes] = useState(() => new Map<string, { x: number; y: number }>());

  const value = useMemo(
    () => ({
      hierarchy,
      entitySizes,
      setEntitySize: (entityId: string, size: { x: number; y: number }) => {
        entitySizes.set(entityId, size);
      },
    }),
    []
  );

  return <UIEntitiesContext.Provider value={value}>{children}</UIEntitiesContext.Provider>;
};
