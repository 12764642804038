import { BODY_DATA_LENGTH, BufferState, HEADER_LENGTH } from "./constants";
import { Data } from "./Data";
import { calcLength } from "./utils";

export class SharedArrayBufferData extends Data {
  type = "shaderArrayBuffer" as const;

  constructor(public sharedArrayBuffer: SharedArrayBuffer) {
    super();
    this.maxBodies = calcLength(sharedArrayBuffer.byteLength);

    this.headerFloatArray = new Float32Array(this.sharedArrayBuffer, 0, HEADER_LENGTH);
    this.headerIntArray = new Int32Array(this.sharedArrayBuffer, 0, HEADER_LENGTH);
    this.objectMatricesFloatArray = new Float32Array(
      this.sharedArrayBuffer,
      HEADER_LENGTH * 4,
      BODY_DATA_LENGTH * this.maxBodies
    );
    this.objectMatricesIntArray = new Int32Array(
      this.sharedArrayBuffer,
      HEADER_LENGTH * 4,
      BODY_DATA_LENGTH * this.maxBodies
    );
  }

  consume() {
    this.setBufferState(BufferState.CONSUMED);
  }

  ready() {
    this.setBufferState(BufferState.READY);
  }

  dispose() {
    // TODO
  }
}
