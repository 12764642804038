import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { MouseEvent } from "react";

export type EnhancedTableHeadProps = {
  cells: HeadCell[];
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: MouseEvent, property: string) => void;
};

type HeadCell = {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};

export const EnhancedTableHead = ({ cells, order, orderBy, onRequestSort }: EnhancedTableHeadProps) => {
  const createSortHandler = (property: string) => (event: MouseEvent) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {cells.map((cell) => (
          <TableCell
            key={cell.id}
            align="left"
            padding={cell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === cell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : "asc"}
              onClick={createSortHandler(cell.id)}
            >
              <Typography variant="subtitle1" color="secondary">
                {cell.label}
              </Typography>
              {orderBy === cell.id && (
                <span
                  style={{
                    border: 0,
                    clip: "rect(0 0 0 0)",
                    height: 1,
                    margin: -1,
                    overflow: "hidden",
                    padding: 0,
                    position: "absolute",
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
