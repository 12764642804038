import { Icon, IconProps } from "./Icon";

export type ArrowProps = IconProps & {
  direction?: "up" | "down";
};

export const Arrow = ({ size, direction = "down" }: ArrowProps) => (
  <Icon
    width="16px"
    height="16px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    size={size}
    style={{ transform: direction === "up" ? "rotate(180deg)" : "none" }}
  >
    <path d="M8 2.667v10.666m0 0 4-4m-4 4-4-4" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
