import { CylinderGeometry } from "~/types/Geometry";
import { LabelGroup, SliderInput } from "~/hephaestus/components";
import { GeometryInspectorBaseProps } from "./types";
import { geometryChange } from "./utils";

export type CylinderGeometryInspectorProps = GeometryInspectorBaseProps<CylinderGeometry>;

export function CylinderGeometryInspector({ geometry, onChange }: CylinderGeometryInspectorProps) {
  const { height, radiusTop, radiusBottom, heightSegments, radialSegments } = geometry;

  return (
    <>
      <LabelGroup label="Height">
        <SliderInput
          name="height"
          min={0}
          max={1000}
          step={0.5}
          value={height}
          onChange={geometryChange("height", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Radius Top">
        <SliderInput
          name="radiusTop"
          min={0}
          max={1000}
          step={0.5}
          value={radiusTop}
          onChange={geometryChange("radiusTop", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Radius Bottom">
        <SliderInput
          name="radiusBottom"
          min={0}
          max={1000}
          step={0.5}
          value={radiusBottom}
          onChange={geometryChange("radiusBottom", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Height Segments">
        <SliderInput
          name="heightSegments"
          min={0}
          max={100}
          step={1}
          value={heightSegments}
          onChange={geometryChange("heightSegments", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Radial Segments">
        <SliderInput
          name="radialSegments"
          min={0}
          max={100}
          step={1}
          value={radialSegments}
          onChange={geometryChange("radialSegments", geometry, onChange)}
        />
      </LabelGroup>
    </>
  );
}
