export const stableSort = <T>(array: T[], comparator: (a: any, b: any) => number): T[] => {
  const stabilized = array.map((el, index) => ({ el, index }));

  stabilized.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) {
      return order;
    }
    return a.index - b.index;
  });

  return stabilized.map((el) => el.el);
};
