import { InteractiveComponent } from "~/types/component";
import { RefObject, useMemo } from "react";
import { hasType } from "~/common/utils/hasType";
import IComponent from "~/types/IComponent";
import { ComponentType } from "~/types/ComponentType";
import { ThreeEvent } from "@react-three/fiber";
import { EntityContext } from "~/view-scene/runtime";

type UseInteractiveComponentReturnType = {
  onPointerUp?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerDown?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerOver?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerOut?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerEnter?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerLeave?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerMove?: (event: ThreeEvent<PointerEvent>) => void;
};

export function useInteractiveComponent(
  components: IComponent[],
  contextRef: RefObject<EntityContext>
): UseInteractiveComponentReturnType {
  const eventEmitter = contextRef.current?.events;

  const interactiveComponent = useMemo(
    () =>
      components.find((component) =>
        hasType<IComponent, InteractiveComponent, ComponentType>(component, ComponentType.INTERACTIVE)
      ) as InteractiveComponent | undefined,
    [components]
  );

  return useMemo(() => {
    if (!interactiveComponent || !eventEmitter) {
      return {
        onPointerUp: undefined,
        onPointerDown: undefined,
        onPointerOver: undefined,
        onPointerOut: undefined,
        onPointerEnter: undefined,
        onPointerLeave: undefined,
        onPointerMove: undefined,
      };
    }

    return {
      onPointerUp: interactiveComponent.pointerUpEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerUp",
              event,
            })
        : undefined,
      onPointerDown: interactiveComponent.pointerDownEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerDown",
              event,
            })
        : undefined,
      onPointerOver: interactiveComponent.pointerOverEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerOver",
              event,
            })
        : undefined,
      onPointerOut: interactiveComponent.pointerOutEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerOut",
              event,
            })
        : undefined,
      onPointerEnter: interactiveComponent.pointerEnterEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerEnter",
              event,
            })
        : undefined,
      onPointerLeave: interactiveComponent.pointerLeaveEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerLeave",
              event,
            })
        : undefined,
      onPointerMove: interactiveComponent.pointerMoveEnabled
        ? (event: ThreeEvent<PointerEvent>) =>
            eventEmitter.emit("interaction", {
              type: "pointerMove",
              event,
            })
        : undefined,
    };
  }, [interactiveComponent, eventEmitter]);
}
