import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Container, Navigate } from "~/common/components";
import { getScenes } from "~/api/scene.api";
import IScene from "~/types/IScene";

type SpaceScenesProps = {
  sceneNames: string[];
};

export const SpaceScenes = ({ sceneNames }: SpaceScenesProps) => {
  const [scenes, setScenes] = useState([]);

  const loadScenes = async () => {
    const { body: result } = await getScenes();
    const spaceScenes = result
      .filter((scene: IScene) => sceneNames.includes(scene.urlName))
      .sort((a: IScene, b: IScene) => a.description.localeCompare(b.description));
    setScenes(spaceScenes);
  };

  useEffect(() => {
    loadScenes();
  }, []);

  return (
    <Box
      component="div"
      sx={{
        paddingTop: "20px",
      }}
    >
      <Box
        component="div"
        sx={{
          paddingTop: "12px",
        }}
      >
        <Container>
          <Typography
            sx={(theme) => ({
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "24px",
              width: "100%",
              color: palette.primary.white,
            })}
            variant="body1"
          >
            Space Scenes
          </Typography>
          <Stack
            sx={(theme) => ({
              display: "grid",
              marginTop: "16px",
              borderBottom: "1px solid " + palette.primary.gray200,
              paddingBottom: "32px",

              grid: "auto / repeat(4,1fr)",
              gridGap: "31px",
              [theme.breakpoints.down("lg")]: {
                grid: "auto / repeat(3,1fr)",
              },
              [theme.breakpoints.down("md")]: {
                grid: "auto / repeat(2,1fr)",
              },
              [theme.breakpoints.down("sm")]: {
                grid: "auto / repeat(1,1fr)",
              },
            })}
          >
            {scenes.map((scene: IScene, index) => {
              return (
                <Navigate key={index} to={`/s/${scene.urlName}/view`} requireAuth={true} openInNewWindow={true}>
                  <Box
                    component="div"
                    sx={(theme) => ({
                      width: "100%",
                      overflow: "hidden",
                      zIndex: "2",
                      backgroundColor: palette.primary.gray100,
                      borderRadius: "20px",
                      position: "relative",
                      border: "1px solid " + palette.primary.gray200,
                      transition: "1s  cubic-bezier(.46, 1.48, .18, .81) ",
                      "&:before": {
                        content: '""',
                        width: "100%",
                        height: "100%",
                        zIndex: "3",
                        opacity: "0",
                        transition: ".4s ease",
                        background: palette.primary.gray50,
                        position: "absolute",
                      },
                      "&:hover:before": {
                        opacity: 1,
                      },
                      "&:hover img": {
                        transform: "scale(1.05)",
                      },
                      "&::after": {
                        content: '""',
                        paddingBottom: "56.25%",
                        display: "block",
                        [theme.breakpoints.down("sm")]: {
                          paddingBottom: "55%",
                        },
                      },
                    })}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "100%",
                        height: "100%",
                        top: "0",
                        borderRadius: "20px",
                        left: "0",
                        zIndex: "2",
                        position: "absolute",
                        display: "block",
                        objectFit: "cover",
                        transition: "1s  cubic-bezier(.46, 1.48, .18, .81) ",
                      }}
                      src={scene.previewUrl}
                      alt={scene.previewUrl}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      marginTop: "8px",
                      lineHeight: "18px",
                      width: "100%",
                      color: palette.primary.gray600,
                    }}
                    variant="body1"
                  >
                    {scene.description}
                  </Typography>
                </Navigate>
              );
            })}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};
