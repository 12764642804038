import { EventEmitter } from "eventemitter3";

export class UserActions {
  move = new Direction();

  jump = new Action();
  action = new Action();
  talk = new Action();
  console = new Action();

  look = new InstantAction<Direction>();
  exit = new InstantAction();
  cameraZoomIn = new InstantAction();
  cameraZoomOut = new InstantAction();

  keyQ = new InstantAction();

  emotion = new InstantAction<string>();

  inventory = new InstantAction();

  customAction = new Action<string>();

  setMove({ side, forward }: Direction) {
    this.move.side = side;
    this.move.forward = forward;
  }

  reset() {
    this.move.side = 0;
    this.move.forward = 0;
  }
}

export interface Action<TValue = any> {
  emit(event: "start", value?: TValue): boolean;

  emit(event: "end", value?: TValue): boolean;

  on(event: "start", listener: (value: TValue) => void): this;

  on(event: "end", listener: (value: TValue) => void): this;

  off(event: "start", listener: (value: TValue) => void): this;

  off(event: "end", listener: (value: TValue) => void): this;
}

export class Action<TValue = any> extends EventEmitter {
  type = "action";
}

export interface InstantAction<TValue = any> {
  emit(event: "fire", value?: TValue): boolean;

  emit(event: "fire", value?: TValue): boolean;

  on(event: "fire", listener: (value: TValue) => void): this;

  on(event: "fire", listener: (value: TValue) => void): this;

  off(event: "fire", listener: (value: TValue) => void): this;

  off(event: "fire", listener: (value: TValue) => void): this;
}

export class InstantAction<TValue = any> extends EventEmitter {
  type = "instantAction";
}

export class Direction {
  side = 0;
  forward = 0;
}

export const userActions = new UserActions();
