import { IStructAsset } from "~/types/IStructAsset";
import { BooleanSocket } from "../../sockets/BooleanSocket";
import { NumberSocket } from "../../sockets/NumberSocket";
import { StringSocket } from "../../sockets/StringSocket";
import { Vector3Socket } from "../../sockets/Vector3Socket";
import { BaseSocket } from "../../base";

export const getSockets = (struct: IStructAsset) => {
  return struct.fields.map((field) => {
    switch (field.type) {
      case "boolean":
        return new BooleanSocket(field.id, field.name);
      case "number":
        return new NumberSocket(field.id, field.name);
      case "string":
        return new StringSocket(field.id, field.name);
      case "vector3":
        return new Vector3Socket(field.id, field.name);
    }
  }) as BaseSocket[];
};
