import { GroupProps } from "@react-three/fiber";
import { Interactive } from "@react-three/xr";
import { useStoreMap } from "effector-react";
import { memo, useCallback, useEffect, useRef } from "react";
import { Group } from "three";
import { $video360State } from "~/view-scene/runtime";
import { Layer } from "~/view-scene/layers";
import { useSprite } from "~/view-scene/utils";

export type PlayPauseButtonProps = Pick<GroupProps, "position"> & {
  width?: number;
};

export const PlayPauseButton = memo(({ position, width }: PlayPauseButtonProps) => {
  const { play, video } = useStoreMap($video360State, (state) => ({ play: state.play, video: state.video }));
  const playRef = useRef(play);
  playRef.current = play;
  const buttonRef = useRef<Group>(null);
  const playSprite = useSprite("/static/img/viewer/video360-entity/play.png", { width }, Layer.video360);
  const pauseSprite = useSprite("/static/img/viewer/video360-entity/pause.png", { width }, Layer.video360);

  const handleSelect = useCallback(() => {
    if (playRef.current) {
      video?.pause();
    } else {
      video?.play();
    }
  }, [video]);

  const handleHover = useCallback(() => {
    buttonRef.current?.scale.set(1.1, 1.1, 1.1);
  }, []);

  const handleBlur = useCallback(() => {
    buttonRef.current?.scale.set(1, 1, 1);
  }, []);

  useEffect(() => {
    playSprite.visible = !play;
    pauseSprite.visible = play;
  }, [play, playSprite, pauseSprite]);

  return (
    <group position={position}>
      <Interactive onSelectStart={handleSelect} onHover={handleHover} onBlur={handleBlur}>
        <group ref={buttonRef}>
          <primitive object={playSprite} />
          <primitive object={pauseSprite} />
        </group>
      </Interactive>
    </group>
  );
});
