import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { setLockElement } from "./models";

export const PointerLocker = () => {
  const gl = useThree((state) => state.gl);

  useEffect(() => {
    setLockElement(gl.domElement);
  }, []);

  return null;
};
