import { NodeContext } from "../../base";
import { NumberMathOperationNode } from "./NumberMathOperationNode";

export class NumberMultNode extends NumberMathOperationNode {
  static readonly type = "logic/numberMult";
  static readonly label = "Number *";

  constructor(context: NodeContext) {
    super(context, (a, b) => a * b);
  }
}
