import { Stack, Typography } from "@mui/material";
import * as Icons from "~/common/components/Icon";

const IconsCollection = Object.keys(Icons).map((key) => ({
  name: key,
  Component: (Icons as any)[key],
}));

export const IconsPage = () => {
  return (
    <Stack flexWrap="wrap" direction="row">
      {IconsCollection.map(({ name, Component }) => (
        <Stack
          key={name}
          color="primary.white"
          sx={{ width: 100, height: 100, alignItems: "center", justifyContent: "center" }}
        >
          <Component />
          <Typography>{name}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
