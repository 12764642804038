import { styled, Typography } from "@mui/material";

export const RankText = styled(Typography)`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.48px;
`;
