import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { AnySocket, ArraySocket, FlowSocket } from "../../sockets";
import { anyValue } from "../../values";

export class ArrayPushNode extends BaseNode {
  static readonly type = "array/push";
  static readonly label = "Array Push Item";

  inputs = [new FlowSocket(), new ArraySocket("array", anyValue.name), new AnySocket("newItem", "new item")];
  outputs = [new FlowSocket(), new ArraySocket("array", anyValue.name)];

  eval(context: NodeEvalContext) {
    const arr = context.readInput<any[]>("array");
    const item = context.readInput("newItem");

    context.writeOutput<any[]>("array", [...arr, item]);
  }
}
