import { CameraControls, TransformControls, TransformControlsMode } from "~/hephaestus/controls";
import { useStoreMap, useUnit } from "effector-react";
import { $activeControlsMode, $transformControlsSettings, activateControlsMode, requestFocus } from "./models";
import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Object3D } from "three";

export function ControlsSetup() {
  const { camera, controls, scene } = useThree(({ camera, controls, scene }) => ({ camera, controls, scene }));

  const cameraControlsEnabled = useStoreMap(
    $activeControlsMode,
    (activeControlsMode) => activeControlsMode.mode === "camera"
  );

  const { targetId, mode, onStart, onChange, onCommit } = useUnit($transformControlsSettings);

  const handleStartTransformControls = (mode: TransformControlsMode, object: Object3D) => {
    activateControlsMode({ mode: "transform" });
    onStart?.(mode, object);
  };

  const handleCommitTransformControls = (mode: TransformControlsMode, object: Object3D) => {
    onCommit?.(mode, object);
    activateControlsMode({ mode: "camera" });
  };

  useEffect(() => {
    const subscription = requestFocus.watch((entityId) => {
      const targetObject = scene.getObjectByName(entityId);
      if (!targetObject || !controls) {
        return;
      }

      const cameraControls = controls as any;
      cameraControls.enabled = false;
      cameraControls.maxDistance = Infinity;
      cameraControls.fitToBox(targetObject, true).then(() => {
        cameraControls.maxDistance = 0.1;
        cameraControls.dollyPublic(0.005, 0, 0);
        cameraControls.enabled = true;
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [camera, scene, controls]);

  return (
    <>
      <CameraControls camera={camera} enabled={cameraControlsEnabled} />
      <TransformControls
        targetId={targetId}
        size={0.45}
        mode={mode}
        onStart={handleStartTransformControls}
        onChange={onChange}
        onCommit={handleCommitTransformControls}
      />
    </>
  );
}
