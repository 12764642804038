import { useEffect } from "react";
import { remoteVideoEntitiesUpdate } from "./models";
import { useEntityManager, VideoContext } from "~/view-scene/runtime";
import { VideoEntityState } from "./types";

export function useHandleRemoteVideoEntitiesUpdates(subscribeToRemoteUpdates: boolean) {
  useEffect(() => {
    if (!subscribeToRemoteUpdates) {
      return;
    }

    const subscription = remoteVideoEntitiesUpdate.watch((remoteVideoEntitiesState) => {
      remoteVideoEntitiesState.forEach((remoteState) => handleRemoteVideoEntityUpdate(remoteState));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribeToRemoteUpdates]);
}

function handleRemoteVideoEntityUpdate(remoteState: VideoEntityState) {
  const videoEntityContext = useEntityManager.getState().getEntityContext<VideoContext>(remoteState.entityId)?.current;
  if (!videoEntityContext) {
    return;
  }

  if (remoteState.status === "play" && !videoEntityContext.isPlaying()) {
    videoEntityContext.play(remoteState.startedTime);
  } else if (remoteState.status === "pause" && videoEntityContext.isPlaying()) {
    videoEntityContext.pause();
  }
}
