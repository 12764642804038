import { Select, SelectItem, SelectProps } from "./Select";
import { useStoreMap } from "effector-react";
import { $sceneData } from "~/entities/sceneData";

export type AvatarAnimationSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
  hideDefault?: boolean;
};

export const AvatarAnimationSelect = ({
  emptyLabel = "Select Player Animation",
  value,
  hideDefault = false,
  ...rest
}: AvatarAnimationSelectProps) => {
  const animations = useStoreMap($sceneData, (state) => state?.avatarSystem.animations) ?? [];

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {animations
        .filter((animation) => (hideDefault ? !animation.isDefault : true))
        .map((animation) => (
          <SelectItem key={animation.id} value={animation.id}>
            {animation.name}
          </SelectItem>
        ))}
    </Select>
  );
};
