import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberSignNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberSign";
  static readonly label = "Sign";

  constructor(context: NodeContext) {
    super(context, (value) => Math.sign(value));
  }
}
