import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "./componentValue";

export type AnimationValue = ComponentValue;

export const animationValue = new ValueType(
  "animation",
  defaultComponent,
  (json: AnimationValue) => json,
  (value: AnimationValue) => value
);
