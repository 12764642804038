import { useMediaQuery, useTheme } from "@mui/material";
import { MobileHeader } from "./mobile/MobileHeader";
import { DesktopHeader } from "./desktop/DesktopHeader";

export function Header() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) {
    return <MobileHeader />;
  } else {
    return <DesktopHeader />;
  }
}
