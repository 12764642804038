import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { defaultVector3 } from "~/entities/variable";

export class MakeVector3Node extends BaseNode {
  static readonly type = "logic/makeVector3";
  static readonly label = "Make vector";
  static readonly category = "Logic";

  inputs = [new NumberSocket("x"), new NumberSocket("y"), new NumberSocket("z")];
  outputs = [new Vector3Socket("vector3")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = context.readInput("x");
    node.storage.output.y = context.readInput("y");
    node.storage.output.z = context.readInput("z");

    context.writeOutput("vector3", node.storage.output);
  }
}
