/* eslint-disable react-hooks/rules-of-hooks */
import { useFrame } from "@react-three/fiber";
import { MutableRefObject, useEffect, useState } from "react";
import { Object3D } from "three";
import { yukaManagerRef } from "./models";
import { useYuka } from "./useYuka";
import type { Vehicle } from "yuka";

export function useYukaEntityManager(theeObjectRef: MutableRefObject<Object3D | null>) {
  const yuka = useYuka();

  if (!yuka) {
    // if yuka is not required do nothing;
    return null as any as Vehicle;
  }

  const [vehicle] = useState(new yuka.Vehicle());

  if (!yukaManagerRef.current) {
    yukaManagerRef.current = new yuka.EntityManager();
  }

  useEffect(() => {
    const threeObject = theeObjectRef.current;
    if (threeObject) {
      vehicle.position.set(threeObject.position.x, threeObject.position.y, threeObject.position.z);
      vehicle.rotation.set(
        threeObject.quaternion.x,
        threeObject.quaternion.y,
        threeObject.quaternion.z,
        threeObject.quaternion.w
      );
    }

    yukaManagerRef.current?.add(vehicle);

    return () => {
      yukaManagerRef.current?.remove(vehicle);
    };
  }, []);

  useFrame(() => {
    const isSteeringBehaviorActive = vehicle.steering.behaviors.some((b) => b.active);
    const threeObject = theeObjectRef.current;

    if (threeObject && !isSteeringBehaviorActive) {
      vehicle.position.set(threeObject.position.x, threeObject.position.y, threeObject.position.z);
      vehicle.rotation.set(
        threeObject.quaternion.x,
        threeObject.quaternion.y,
        threeObject.quaternion.z,
        threeObject.quaternion.w
      );
    } else if (threeObject && isSteeringBehaviorActive) {
      threeObject.position.set(vehicle.position.x, vehicle.position.y, vehicle.position.z);
      threeObject.quaternion.set(vehicle.rotation.x, vehicle.rotation.y, vehicle.rotation.z, vehicle.rotation.w);
    }
  });

  return vehicle;
}
