import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class InventoryItemGetNode extends BaseNode {
  static readonly type = "variable/inventoryItemGet";
  static readonly label = "Get Inventory Item";

  outputs = [new NumberSocket("count")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const storageId = node.readMetadata("storageId", null);
    if (!storageId) {
      return;
    }
    const values = node.sceneContext.getUserStorageData(storageId) ?? {};
    const count = values[Object.keys(values)[0]] ?? 0;

    context.writeOutput<number>("count", count as number);
  }
}
