import { attach } from "effector";
import { $socket, setSocket } from "./socket";
import { unsubscribeFx } from "./subscribe";

export const disconnectFx = attach({
  source: $socket,
  effect: async (socket) => {
    if (!socket) {
      return;
    }

    unsubscribeFx(socket);
    setSocket(null);
  },
});
