import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ControlsActionEvent } from "../../ScriptEvents";

export class ControlsActionDownNode extends BaseNode {
  static readonly type = "event/controlsActionDown";
  static readonly label = "Action Down";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const action = node.readMetadata("action", null);
    const onControlsActionEvent = (value: ControlsActionEvent) => {
      if (value.action === action && value.down) {
        context.commit("flow");
      }
    };

    node.scriptContext.scriptEvents.controlsAction.addListener(onControlsActionEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.controlsAction.removeListener(onControlsActionEvent);
    });
  }
}
