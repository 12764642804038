import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";

export type ErrorPageTitleProps = {
  subtitle: string;
  icon: string;
};

export const ErrorPageTitle = ({ subtitle, icon }: ErrorPageTitleProps) => {
  return (
    <Box
      component="div"
      sx={{
        maxWidth: "426px",
        margin: "0 auto 0",
      }}
    >
      <Box
        component="div"
        sx={{
          paddingBottom: "12px",
          borderBottom: "1px solid " + palette.primary.gray200,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            gridGap: "20px",
          }}
        >
          <Typography sx={{ color: palette.primary.white, lineHeight: "54px" }} variant="h2">
            Apologize
          </Typography>
          <Box
            component="img"
            src={icon}
            alt={icon}
            sx={{
              width: 48,
              height: 48,
              objectFit: "cover",
              display: "block",
            }}
          />
        </Stack>
        <Typography sx={{ color: palette.primary.white, lineHeight: "54px" }} variant="h2">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
