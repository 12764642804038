import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { getTonConnect } from "./getTonConnect";

export class TonConnectDisconnectNode extends BaseNode {
  static readonly type = "web3/tonConnectDisconnect";
  static readonly label = "TonConnect disconnect wallet";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("manifest")];
  outputs = [new FlowSocket("success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext) {
    const tonConnectUI = getTonConnect(context.readInput<string>("manifest"));

    async function disconnectWallet() {
      await tonConnectUI.connectionRestored;

      try {
        if (tonConnectUI.connected) {
          await tonConnectUI.disconnect();

          context.commit("success");
          context.graphEvaluator.executeAll();
        } else {
          context.commit("fail");
          context.graphEvaluator.executeAll();
        }
      } catch (e) {
        context.commit("fail");
        context.graphEvaluator.executeAll();
      }
    }

    disconnectWallet();
  }
}
