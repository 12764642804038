import { Select, SelectItem, SelectProps } from "./Select";
import { useStoreMap } from "effector-react";
import { $sceneData } from "~/entities/sceneData";
import { useMemo } from "react";
import { useAssets } from "~/entities/assets";
import IImageAsset from "~/types/IImageAsset";

export type OverlaySelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const OverlaySelect = ({ emptyLabel = "Select overlay", value, ...rest }: OverlaySelectProps) => {
  const overlays = useStoreMap($sceneData, (sceneData) => sceneData?.screenOverlay?.items ?? []);
  const assetsMap = useAssets<IImageAsset>(overlays.map((overlay) => overlay.asset ?? ""));

  const items = useMemo(() => {
    return overlays.map((overlay) => ({
      id: overlay.id,
      label: assetsMap[overlay.asset ?? ""]?.name ?? "[Unnamed]",
    }));
  }, [overlays, assetsMap]);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {items.map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
};
