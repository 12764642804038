import { memo, ReactNode } from "react";
import ICloudSceneObject from "~/types/ICloudSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "./Entity";
import { Cloud } from "@react-three/drei";

type CloudEntityProps = {
  entityId: string;
  children?: ReactNode;
};

function CloudEntity({ entityId, children }: CloudEntityProps) {
  const entity = useEntity<ICloudSceneObject>(entityId);
  const { opacity, speed, width, depth, segments } = entity;

  return (
    <Entity entityId={entityId}>
      <Cloud opacity={opacity} speed={speed} width={width} depth={depth} segments={segments} />
      {children}
    </Entity>
  );
}

export default memo(CloudEntity);
