import { Stack, styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { palette } from "~/theme";

export type KeyboardButtonProps = {
  text?: string;
  icon?: ReactNode;
};

export const KeyboardButton = ({ icon, text }: KeyboardButtonProps) => {
  return (
    <Container direction="row" justifyContent="center" alignItems="center">
      {icon || (
        <Typography variant="body1" textTransform="capitalize">
          {text}
        </Typography>
      )}
    </Container>
  );
};

const Container = styled(Stack)`
  min-width: 24px;
  height: 24px;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 5px;
  border-radius: 8px;
  color: ${palette.primary.white};
  border: 1px solid ${palette.primary.gray100};
  background-color: ${palette.primary.gray100};
`;
