import { NodeEvalContext } from "~/libs/behave-graph";
import { MapValue } from "~/types/Variable";
import { BaseNode } from "../../base";
import { FlowSocket, MapSocket } from "../../sockets";
import { anyValue } from "../../values";

export class MapClearNode extends BaseNode {
  static readonly type = "map/clear";
  static readonly label = "Map Clear";

  inputs = [new FlowSocket(), new MapSocket("map", anyValue.name)];
  outputs = [new FlowSocket(), new MapSocket("map", anyValue.name)];

  eval(context: NodeEvalContext) {
    context.writeOutput<MapValue>("map", {});
  }
}
