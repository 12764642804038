import { Navigate, useLocation } from "react-router-dom";
import { useProfile } from "../models";
import { roleToRight } from "../constants";
import { Right } from "../types";
import { useEffect } from "react";

export type PrivateRouteProps = {
  right?: Right;
  children: JSX.Element;
};

export const PrivateRoute = ({ right, children }: PrivateRouteProps) => {
  const { isAuthorised, profile, updateRedirectPage } = useProfile((state) => ({
    isAuthorised: state.isAuthorised,
    profile: state.profile,
    updateRedirectPage: state.updateRedirectPage,
  }));

  const location = useLocation();

  useEffect(() => {
    if (!isAuthorised()) {
      updateRedirectPage(location.pathname);
    }
  }, [location.pathname]);

  if (!isAuthorised()) {
    return <Navigate to={{ pathname: "/?auth=login" }} />;
  }

  const userRole = profile?.role;
  if (right && (!userRole || roleToRight.get(userRole)?.indexOf(right) === -1)) {
    return <Navigate to={{ pathname: "/401" }} />;
  }

  return children;
};
