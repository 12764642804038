import { NodeEvalContext } from "~/libs/behave-graph";
import { ActionableComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, ActionableSocket } from "../../sockets";

export class ActionableIsActiveNode extends BaseNode {
  static readonly type = "action/actionableIsActive";
  static readonly label = "Enable Actionable";
  static readonly pure = true;

  inputs = [new ActionableSocket("actionable")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const isActive = node.readComponent<ActionableComponentContext>(context, "actionable")?.isActive() ?? false;

    context.writeOutput<boolean>("result", isActive);
  }
}
