import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { createChart } from "./utils";
import { Chart } from "chart.js";
import { API_HOST } from "~/config";
import { AnalyticsParams } from "./TelegramAnalyticsPage";

export function MAUChart({ gameId, cohortReferer }: AnalyticsParams) {
  const { status, isLoading, data } = useQuery({
    queryKey: `mau_${gameId}_${cohortReferer}`,
    queryFn: () => fetchData(gameId, cohortReferer),
  });

  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (status !== "success") {
      return;
    }

    const dauData = formatData(data);
    if (chartRef.current) {
      chartRef.current.data.labels = dauData.labels;
      chartRef.current.data.datasets = dauData.datasets;
      if (chartRef.current?.options.scales?.y) {
        chartRef.current.options.scales.y.max = Math.floor(data.maxUniqueUsers + data.maxUniqueUsers / 10);
      }
      chartRef.current.update();
    } else {
      chartRef.current = createChart("mauChart", dauData);
    }
  }, [status, data]);

  return <canvas id="mauChart"></canvas>;
}

type MAUResponse = {
  _id: {
    month: number;
    referer: string;
  };
  uniqueUsers: number;
}[];

function formatData(data: MAUResponse) {
  if (!data) {
    return { labels: [], datasets: [], maxUniqueUsers: 0 };
  }

  const labels = Array.from(new Set(data.map((d) => `${d._id.month}/2023`)));
  const referers = Array.from(new Set(data.map((d) => d._id.referer)));

  const monthlyTotals = labels.map((label) => {
    const month = parseInt(label.split("/")[0]);
    const monthlyEntries = data.filter((d) => d._id.month === month);
    return monthlyEntries.reduce((sum, entry) => sum + entry.uniqueUsers, 0);
  });

  const maxUniqueUsers = Math.max(...monthlyTotals);

  const datasets = referers.map((referer) => ({
    label: referer,
    data: labels.map((label) => {
      const month = parseInt(label.split("/")[0]);
      const entry = data.find((d) => d._id.referer === referer && d._id.month === month);
      return entry ? entry.uniqueUsers : 0;
    }),
    backgroundColor: "rgba(147, 112, 219, 0.6)",
    borderColor: "rgba(153, 50, 204, 0.3)",
    borderWidth: 1,
  }));

  return { labels, datasets, maxUniqueUsers };
}

async function fetchData(gameId: string, cohortReferer?: string) {
  let getLink = `${API_HOST}/api/events/${gameId}/mau`;
  if (cohortReferer) getLink += `?cohortReferer=${cohortReferer}`;

  const response = await fetch(getLink, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": "fsdjkalfh98370[2h-9hr1p'/1;m12lkdi",
    },
  });

  if (!response.ok) {
    throw new Error("Failed loading reviews");
  }

  return await response.json();
}
