import { StoreValue, createEffect, createEvent, createStore, forward } from "effector";
import { keyBy } from "lodash-es";
import { getAssets } from "~/api/assets.api";
import { AssetType } from "~/types/AssetType";
import IAsset from "~/types/IAsset";
import { resetAssets } from "./resetAssets";

export const loadAssetsFx = createEffect(async (workspaceID: string) => {
  const { status, body: assets } = await getAssets(workspaceID);

  if (status !== 200) {
    throw new Error("Not found");
  }

  return assets as unknown as IAsset[];
});

export const setAssets = createEvent<IAsset[] | null>();

forward({
  from: loadAssetsFx.doneData,
  to: setAssets,
});

export const $assets = createStore<IAsset[] | null>(null)
  .on(setAssets, (_, payload) => payload)
  .reset(resetAssets);

export const $assetsMap = $assets.map((assets) => (assets ? keyBy(assets, "id") : {}));
export type AssetsMap = StoreValue<typeof $assetsMap>;

export const $assetsError = createStore<Error | null>(null)
  .on(loadAssetsFx, () => null)
  .on(loadAssetsFx.failData, (_, error) => error);

export const $inventoryItems = $assets.map((assets) =>
  assets ? assets.filter((a) => a.type === AssetType.inventoryItem) : []
);

export const $inventoryItemsMap = $inventoryItems.map((storages) => keyBy(storages, "id"));
