import { NodeEvalContext } from "~/libs/behave-graph";
import { AnimationComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, FlowSocket } from "../../sockets";

export class AnimationResetNode extends BaseNode {
  static readonly type = "action/animationReset";
  static readonly label = "Animation Reset";

  inputs = [new FlowSocket("flow"), new AnimationSocket("animation")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<AnimationComponentContext>(context, "animation")?.reset();
  }
}
