import { ReactElement, useContext, useMemo } from "react";
import { hotkeysContext } from "../hotkeysContext";
import { KeyboardActionSource } from "./KeyboardActionSource";
import { getKeyboardType, getKeyCombination } from "./utils";
import { KeyboardSpecialKey, KeyboardSupportKey } from "./types";
import { KeyboardButton } from "./KeyboardButton";
import { Stack, styled } from "@mui/material";
import { Del } from "~/common/components/Icon";

export type RenderKeyCombinationProps<TAction> = {
  action: TAction;
  combinationIndex?: number;
};

export type RenderKeyCombinationType<TAction extends string> = (
  props: RenderKeyCombinationProps<TAction>
) => ReactElement | null;

export const RenderKeyCombination: RenderKeyCombinationType<string> = ({ action, combinationIndex }) => {
  const context = useContext(hotkeysContext);
  const source = context.actionSource;

  const rendered = useMemo(() => {
    if (!(source instanceof KeyboardActionSource)) {
      return null;
    }

    const key = source.getKeyCombination(action);
    const keyStr = getKeyCombination(key, combinationIndex);

    if (!keyStr) {
      return null;
    }

    const supportKeys = getKeyboardType() === "mac" ? macSupportKeys : otherSupportKeys;
    const parts = keyStr.split("+");
    const renderedParts: (JSX.Element | string)[] = [];

    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      const supportSymbol = supportKeys[part as KeyboardSupportKey];

      if (supportSymbol) {
        renderedParts.push(supportSymbol);
        continue;
      }

      const specialSymbol = specialKeys[part as KeyboardSpecialKey];

      if (specialSymbol) {
        renderedParts.push(specialSymbol);
        continue;
      }

      renderedParts.push(symbolsOverrideKeys[part] ?? <KeyboardButton key={part} text={part} />);
    }

    return renderedParts;
  }, [source, action, combinationIndex]);

  return rendered ? <Combination as="span">{rendered}</Combination> : null;
};

const Combination = styled(Stack)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const macSupportKeys: Record<KeyboardSupportKey, JSX.Element | string> = {
  shift: <KeyboardButton key="shift" text="Shift" />,
  alt: <KeyboardButton key="alt" text="Alt" />,
  control: <KeyboardButton key="control" text="⌃" />,
  option: <KeyboardButton key="option" text="⌥" />,
  command: <KeyboardButton key="command" text="⌘" />,
};

const otherSupportKeys: Record<KeyboardSupportKey, JSX.Element | string> = {
  shift: <KeyboardButton key="shift" text="Shift" />,
  alt: <KeyboardButton key="alt" text="Alt" />,
  option: <KeyboardButton key="alt2" text="Alt" />,
  control: <KeyboardButton key="control" text="Ctrl" />,
  command: <KeyboardButton key="command" text="Win" />,
};

const specialKeys: Record<KeyboardSpecialKey, JSX.Element | string> = {
  delete: <KeyboardButton key="delete" text="Del" />,
  backspace: <KeyboardButton key="backspace" icon={<Del />} />,
  tab: "tab",
  esc: "esc",
  space: "␣",
  enter: <span>Enter Element example</span>,
  up: "↑",
  down: "↓",
  left: "←",
  right: "→",
};

const symbolsOverrideKeys: Record<string, JSX.Element | string> = {
  "\\": <KeyboardButton key="|" text="|" />,
};
