import { Button, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { palette } from "~/theme";
import { HeaderWithNavigation, Navigate } from "~/common/components";
import { HOST, WHITE_LABELING_PROFILE } from "~/config";
import React from "react";
import { Eye } from "~/common/components/Icon";

export function DogeverseLandingPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <DogeverseLanding>
      <HeaderWithNavigation enableNavigation={HOST === "8xr"} label="Explore" target="/" showLogo={true} />
      <Content>
        {isSmallScreen ? <StartPlayingMobile /> : <StartPlaying />}
        <ComicBook />
      </Content>
    </DogeverseLanding>
  );
}

function StartPlaying() {
  const { landing, logo } = WHITE_LABELING_PROFILE;

  return (
    <StartPlayingContainer>
      <LogoContainer>
        <img
          src={logo}
          style={{
            width: "59px",
            height: "59px",
          }}
        />
      </LogoContainer>
      <Stack gap="10px" justifyContent="left">
        <Typography fontSize="35.28px" lineHeight="28px" fontWeight={700}>
          Dogeverse
        </Typography>
        <PlayersText>
          <EyeIcon size="medium" /> {generateRandomNumber(1235)} Players
        </PlayersText>
      </Stack>
      <Line />
      <Navigate to={landing.rootScene}>
        <Button size="large" variant="accent">
          Start Playing
        </Button>
      </Navigate>
    </StartPlayingContainer>
  );
}

function StartPlayingMobile() {
  const { landing, logo } = WHITE_LABELING_PROFILE;

  return (
    <StartPlayingMobileContainer>
      <Stack direction="row" gap="24px" justifyContent="center" alignItems="center">
        <LogoContainerMobile>
          <img
            src={logo}
            style={{
              width: "40.89px",
              height: "40.89px",
            }}
          />
        </LogoContainerMobile>
        <Stack gap="10px" justifyContent="left">
          <Typography fontSize="20px" lineHeight="25.2px" fontWeight={700}>
            Dogeverse
          </Typography>
          <PlayersText>
            <EyeIcon size="medium" /> {generateRandomNumber(1235)} Players
          </PlayersText>
        </Stack>
      </Stack>
      <Navigate to={landing.rootScene}>
        <Button
          size="large"
          variant="accent"
          sx={{
            width: "305px",
          }}
        >
          Start Playing
        </Button>
      </Navigate>
    </StartPlayingMobileContainer>
  );
}

const initialTime = 1679080662;

function generateRandomNumber(seed: number) {
  const hour = Math.floor(initialTime / (60 * 60));
  return (Math.floor(Math.sin(seed + hour) * 1000000) % 41) + 1620;
}

function ComicBook() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const comicBookUrl = isSmallScreen
    ? "/static/img/content/landing/dogeverse_landing_mobile.webp"
    : "/static/img/content/landing/dogeverse_landing.webp";

  return (
    <ComicBookContainer>
      <ComicBookImage alt="comic book" src={comicBookUrl} />
    </ComicBookContainer>
  );
}

const LogoContainer = styled(Stack)`
  width: 95px;
  min-width: 95px;
  height: 95px;
  border-radius: 111px;
  align-items: center;
  justify-content: center;
  background-color: ${palette.primary.black900};
  border: 1px solid ${palette.primary.gray200};
`;

const LogoContainerMobile = styled(LogoContainer)`
  width: 66px;
  min-width: 66px;
  height: 66px;
`;

const Line = styled(Stack)`
  margin: 0 12px;
  height: 58px;
  border: 1px solid ${palette.primary.gray200};
`;

const PlayersText = styled(Typography)`
  font-size: 16px;
  line-height: 20.16px;
  font-weight: 500;
  color: ${palette.primary.gray800};
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 5px;
`;

const EyeIcon = styled(Eye)`
  color: #9f92ff;
`;

const DogeverseLanding = styled(Stack)`
  background-color: ${palette.primary.dark};
  width: 100vw;
`;

const Content = styled(Stack)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 24px 220px;
  gap: 8px;
`;

const StartPlayingContainer = styled(Stack)`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 3%;
  width: 543px;
  height: 128px;
  border-radius: 102px;
  padding: 16px 38px 16px 16px;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: ${palette.primary.black800};
  border: 1px solid ${palette.primary.gray200};
`;

const StartPlayingMobileContainer = styled(StartPlayingContainer)`
  width: 329px;
  height: 169px;
  border-radius: 36px;
  padding: 16px 12px 16px 12px;
  flex-direction: column;
  gap: 16px;
`;

const ComicBookContainer = styled(Stack)`
  width: 100%;
  background-color: transparent;
  border-radius: 12px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ComicBookImage = styled("img")`
  width: 100%;
  max-width: 1240px;
`;
