import { attach, createEvent, createStore } from "effector";
import { isDesktopSafari } from "~/common/utils/isDesktopSafari";

export const setLockElement = createEvent<HTMLElement | null>();
export const $lockElement = createStore<HTMLElement | null>(null).on(setLockElement, (_, element) => element);

export const lockPointer = attach({
  source: $lockElement,
  effect: (element) => {
    return new Promise<"locked">((resolve, reject) => {
      if (!element) {
        return reject();
      }

      let unsubscribe: (() => void) | undefined;

      document.addEventListener(
        "pointerlockchange",
        () => {
          unsubscribe?.();
          resolve("locked");
        },
        { once: true }
      );

      if (isDesktopSafari()) {
        unsubscribe = actionHack(() => element.requestPointerLock());
      } else {
        element.requestPointerLock();
      }
    });
  },
});

export const unlockPointer = attach({
  source: $lockElement,
  effect: (element) => {
    return new Promise<"unlocked">((resolve, reject) => {
      if (!element) {
        return reject();
      }

      if (document.pointerLockElement !== element) {
        reject();
      }

      document.addEventListener(
        "pointerlockchange",
        () => {
          resolve("unlocked");
        },
        { once: true }
      );

      document.exitPointerLock();
    });
  },
});

const actionHack = (handler: () => void) => {
  const handlerWrapper = () => {
    handler();
    unsubscribe();
  };

  const unsubscribe = () => {
    document.removeEventListener("click", handlerWrapper);
    document.removeEventListener("keypress", handlerWrapper);
  };

  document.addEventListener("click", handlerWrapper);
  document.addEventListener("keypress", handlerWrapper);

  return unsubscribe;
};

export type PointerLockStatus = "locked" | "unlocked";

export const $pointerLockStatus = createStore<PointerLockStatus>("unlocked")
  .on(lockPointer.doneData, (_, status) => status)
  .on(unlockPointer.doneData, (_, status) => status);
