import {
  BoxGeometry,
  BufferGeometry,
  ConeGeometry,
  CylinderGeometry,
  Geometry,
  GeometryType,
  PlaneGeometry,
  SphereGeometry,
} from "~/types/Geometry";
import { v4 as uuid } from "uuid";
import { GeometryInspectorBaseProps } from "./types";
import { getChangeValue } from "~/hephaestus/utils";

export const generatePlaneGeometry = (): PlaneGeometry => ({
  id: uuid(),
  type: GeometryType.plane,
  width: 1,
  height: 1,
  widthSegments: 1,
  heightSegments: 1,
});

export const generateConeGeometry = (): ConeGeometry => ({
  id: uuid(),
  type: GeometryType.cone,
  radius: 1,
  height: 1,
  radialSegments: 8,
  heightSegments: 1,
});

export const generateCylinderGeometry = (): CylinderGeometry => ({
  id: uuid(),
  type: GeometryType.cylinder,
  radiusTop: 1,
  radiusBottom: 1,
  height: 1,
  radialSegments: 8,
  heightSegments: 1,
});

export const generateSphereGeometry = (): SphereGeometry => ({
  id: uuid(),
  type: GeometryType.sphere,
  radius: 1,
  widthSegments: 32,
  heightSegments: 16,
});

export const generateBoxGeometry = (): BoxGeometry => ({
  id: uuid(),
  type: GeometryType.box,
  width: 1,
  height: 1,
  depth: 1,
  widthSegments: 1,
  heightSegments: 1,
  depthSegments: 1,
});

export const generateBufferGeometry = (): BufferGeometry => ({
  id: uuid(),
  type: GeometryType.buffer,
  points: [],
});

export const geometryChange =
  <TGeometry extends Geometry>(
    fieldName: keyof TGeometry,
    geometry: TGeometry,
    onChange?: GeometryInspectorBaseProps<TGeometry>["onChange"]
  ) =>
  (e: any) => {
    onChange?.({
      target: {
        value: {
          ...geometry,
          [fieldName]: getChangeValue(e),
        },
      },
    });
  };
