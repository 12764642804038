import { ConeGeometry } from "~/types/Geometry";
import { LabelGroup, SliderInput } from "~/hephaestus/components";
import { GeometryInspectorBaseProps } from "./types";
import { geometryChange } from "./utils";

export type ConeGeometryInspectorProps = GeometryInspectorBaseProps<ConeGeometry>;

export function ConeGeometryInspector({ geometry, onChange }: ConeGeometryInspectorProps) {
  const { height, radius, heightSegments, radialSegments } = geometry;

  return (
    <>
      <LabelGroup label="Height">
        <SliderInput
          name="height"
          min={0}
          max={1000}
          step={0.5}
          value={height}
          onChange={geometryChange("height", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Radius">
        <SliderInput
          name="radius"
          min={0}
          max={1000}
          step={0.5}
          value={radius}
          onChange={geometryChange("radius", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Height Segments">
        <SliderInput
          name="heightSegments"
          min={0}
          max={100}
          step={1}
          value={heightSegments}
          onChange={geometryChange("heightSegments", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Radial Segments">
        <SliderInput
          name="radialSegments"
          min={0}
          max={100}
          step={1}
          value={radialSegments}
          onChange={geometryChange("radialSegments", geometry, onChange)}
        />
      </LabelGroup>
    </>
  );
}
