import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket } from "../../sockets";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "../../values";

export class SelfNode extends BaseNode {
  static readonly type = "logic/self";
  static readonly label = "Self";
  static readonly category = "Logic";

  outputs = [new EntitySocket("entity")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = node.scriptContext.entityId;
    context.writeOutput<EntityValue>("entity", node.storage.output);
  }
}
