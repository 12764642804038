import { memo, ReactNode, useEffect, useRef } from "react";
import { Group, Vector3 } from "three";
import { EnabledStatus } from "~/types/EnabledStatus";
import { loadFinish } from "~/view-scene/sceneLoad";
import { registerSpawnPoint } from "~/view-scene/runtime";
import { Entity } from "./Entity";
import { ISpawnPointSceneObject } from "~/types/ISpawnPointSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";

type SpawnPointEntityProps = {
  entityId: string;
  children?: ReactNode;
};

const position = new Vector3();
const scale = new Vector3();

export const SpawnPointEntity = memo(({ entityId, children }: SpawnPointEntityProps) => {
  const entity = useEntity<ISpawnPointSceneObject>(entityId);

  const entityRef = useRef<Group>(null);

  useEffect(() => {
    if (entity.enabled !== EnabledStatus.enabled) {
      loadFinish(entity.id);
      return;
    }

    entityRef.current!.getWorldPosition(position);
    entityRef.current!.getWorldScale(scale);

    const dir = new Vector3();
    entityRef.current!.getWorldDirection(dir);
    const angle = calcAngle(dir);

    registerSpawnPoint({
      id: entity.id,
      position: { x: position.x, y: position.y, z: position.z },
      rotation: { x: 0, y: angle, z: 0 },
      scale: { x: scale.x, y: scale.y, z: scale.z },
    });
    loadFinish(entity.id);
  }, []);

  if (entity.enabled !== EnabledStatus.enabled) {
    return null;
  }

  return (
    <Entity entityId={entityId} ref={entityRef}>
      {children}
    </Entity>
  );
});

const calcAngle = (dir: Vector3) => {
  const quarter = getQuarter(dir);

  switch (quarter) {
    case 1: {
      const cos = Math.abs(dir.z) / dir.length();
      const angle = Math.acos(cos);

      return angle;
    }
    case 2: {
      const cos = Math.abs(dir.x) / dir.length();
      const angle = Math.acos(cos);

      return Math.PI / 2 + angle;
    }
    case 3: {
      const cos = Math.abs(dir.z) / dir.length();
      const angle = Math.acos(cos);

      return Math.PI + angle;
    }
    case 4: {
      const cos = Math.abs(dir.x) / dir.length();
      const angle = Math.acos(cos);

      return Math.PI * 1.5 + angle;
    }
  }
};

const getQuarter = (dir: Vector3) => {
  if (dir.x > 0) {
    if (dir.z > 0) {
      return 1;
    } else {
      return 2;
    }
  } else {
    if (dir.z > 0) {
      return 4;
    } else {
      return 3;
    }
  }
};
