import EventEmitter from "eventemitter3";
import { MutableRefObject } from "react";
import { Object3D, Vector3 } from "three";
import type { Vehicle } from "yuka";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import ISceneObject from "~/types/ISceneObject";
import type { Vector3Value } from "~/types/Variable";
import { AnimatedProperty, PropertyLabel, PropertyType } from "~/view-scene/meta";
import { PhysicsBody } from "~/view-scene/physics";
import { ComponentContext } from "../types";

const tmpVector = new Vector3();
const tmpVector2 = new Vector3();

export class EntityContext<T extends ISceneObject> {
  id: string;
  parentId: string | null;
  dto: T;
  type: string;
  name: string;
  tags: Set<string>;
  vehicle?: Vehicle;
  events: EventEmitter;
  physicsBodies: Record<string, PhysicsBody> = {};
  components: Record<ComponentContext["id"], ComponentContext> = {};

  private _position = defaultVector3();
  private _rotation = defaultVector3();
  private _scale = defaultVector3();

  private _worldPosition = defaultVector3();

  constructor(_dto: T, public rootObjectRef: MutableRefObject<Object3D | null>) {
    this.id = _dto.id;
    this.parentId = _dto.parentId ?? null;
    this.dto = _dto;
    this.type = _dto.type;
    this.name = _dto.name;
    this.tags = new Set(_dto.tags);
    this.events = new EventEmitter();
  }

  @PropertyType("vector3")
  @AnimatedProperty(["vector3", "current", "entity"])
  get position(): Vector3Value {
    if (this.rootObjectRef.current) {
      setVector3ValueFromXYZ(this._position, this.rootObjectRef.current.position);
    }

    return this._position;
  }

  set position(value: Vector3Value) {
    this.rootObjectRef.current?.position.set(value.x, value.y, value.z);
  }

  @PropertyType("number")
  @PropertyLabel("Position X")
  @AnimatedProperty(["number", "current", "entity"])
  get positionX(): number {
    return this.position.x;
  }

  set positionX(x: number) {
    if (this.rootObjectRef.current) {
      this.rootObjectRef.current.position.setX(x);
    }
  }

  @PropertyType("number")
  @PropertyLabel("Position Y")
  @AnimatedProperty(["number", "current", "entity"])
  get positionY(): number {
    return this.position.y;
  }

  set positionY(y: number) {
    if (this.rootObjectRef.current) {
      this.rootObjectRef.current.position.setY(y);
    }
  }

  @PropertyType("number")
  @PropertyLabel("Position Z")
  @AnimatedProperty(["number", "current", "entity"])
  get positionZ(): number {
    return this.position.z;
  }

  set positionZ(z: number) {
    if (this.rootObjectRef.current) {
      this.rootObjectRef.current.position.setZ(z);
    }
  }

  @PropertyType("vector3")
  @AnimatedProperty(["vector3", "current", "entity"])
  get rotation(): Vector3Value {
    if (this.rootObjectRef.current?.rotation) {
      setVector3ValueFromXYZ(this._rotation, this.rootObjectRef.current.rotation);
    }

    return this._rotation;
  }

  set rotation(value: Vector3Value) {
    this.rootObjectRef.current?.rotation.set(value.x, value.y, value.z);
  }

  @PropertyType("vector3")
  @AnimatedProperty(["vector3", "current", "entity"])
  get scale(): Vector3Value {
    if (this.rootObjectRef.current?.scale) {
      setVector3ValueFromXYZ(this._scale, this.rootObjectRef.current.scale);
    }

    return this._scale;
  }

  set scale(value: Vector3Value) {
    this.rootObjectRef.current?.scale.set(value.x, value.y, value.z);
  }

  @PropertyType("vector3")
  @PropertyLabel("World Position")
  @AnimatedProperty(["vector3", "current", "entity"])
  get worldPosition() {
    if (this.rootObjectRef.current) {
      this.rootObjectRef.current.getWorldPosition(tmpVector);

      setVector3ValueFromXYZ(this._worldPosition, tmpVector);
    }

    return this._worldPosition;
  }

  set worldPosition(value: Vector3Value) {
    this.setWorldPosition(value.x, value.y, value.z);
  }

  @PropertyType("number")
  @PropertyLabel("World Position X")
  @AnimatedProperty(["number", "current", "entity"])
  get worldPositionX() {
    return this.worldPosition.x;
  }

  set worldPositionX(x: number) {
    this.setWorldPosition(x, undefined, undefined);
  }

  @PropertyType("number")
  @PropertyLabel("World Position Y")
  @AnimatedProperty(["number", "current", "entity"])
  get worldPositionY() {
    return this.worldPosition.y;
  }

  set worldPositionY(y: number) {
    this.setWorldPosition(undefined, y, undefined);
  }

  @PropertyType("number")
  @PropertyLabel("World Position Z")
  @AnimatedProperty(["number", "current", "entity"])
  get worldPositionZ() {
    return this.worldPosition.z;
  }

  set worldPositionZ(z: number) {
    this.setWorldPosition(undefined, undefined, z);
  }

  getPhysicsBody(rigidBodyId?: string) {
    if (rigidBodyId) {
      return this.physicsBodies[rigidBodyId] ?? null;
    }

    return this.physicsBodies[Object.keys(this.physicsBodies)[0]] ?? null;
  }

  getChild?: (id: string) => Object3D | null;

  private setWorldPosition(x: number | undefined, y: number | undefined, z: number | undefined) {
    if (!this.rootObjectRef.current) {
      return;
    }

    const position = this.rootObjectRef.current.position;
    const parent = this.rootObjectRef.current.parent;

    if (parent) {
      parent.getWorldPosition(tmpVector2);
      position.set(
        x !== undefined ? x - tmpVector2.x : position.x,
        y !== undefined ? y - tmpVector2.y : position.y,
        z !== undefined ? z - tmpVector2.z : position.z
      );
    } else {
      position.set(x ?? position.x, y ?? position.y, z ?? position.z);
    }
  }
}
