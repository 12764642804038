import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { VariableBaseNode } from "./VariableBaseNode";

export class VariableNode extends VariableBaseNode {
  static type = "variable/parameter";
  static label = "Get Variable";
  static category: NodeCategory = "Variable";

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const variable = this.getVariable(node.spec)!;
    const value = node.scriptContext.variables.getValue(variable.id);

    context.writeOutput("value", value);
  }

  getOutputs(nodeSpec: NodeSpec): BaseSocket[] {
    return [this.getVariableSocket(nodeSpec)];
  }
}
