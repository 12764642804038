/** Buffer schema
 * 0-3 bytes: bufferState: int
 * 4-7 bytes: calculationTime: float
 * 8-∞ bytes: Items: Item[] (Item schema below)
 */

/** Buffer Item Schema
 * Every physics body has 38 * 4 bytes (64bit float/int) assigned in the buffer
 * 0-15:  Matrix4 elements (floats)
 * 16-18: Linear Velocity (float)
 * 19-21: Angular Velocity (float)
 * 22-30: first 8 Collisions (ints)
 * 31-38: removed 8 collisions (ints)
 */

export const HEADER_LENGTH = 2;
export const TRANSFORM_OFFSET = 0;
export const LINEAR_VELOCITY_OFFSET = 16;
export const ANGULAR_VELOCITY_OFFSET = 19;
export const COLLISIONS_OFFSET = 22;
export const ENDED_COLLISIONS_OFFSET = 30;
export const COLLISIONS_NUMBER = 8;
export const BODY_DATA_LENGTH = ENDED_COLLISIONS_OFFSET + COLLISIONS_NUMBER;

export enum BufferState {
  UNINITIALIZED = 0,
  READY = 1,
  CONSUMED = 2,
}
