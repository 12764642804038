import { useEffect } from "react";

import { usePermissions } from "./usePermissions";

export const PermissionsSystem = () => {
  const init = usePermissions((state) => state.init);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
