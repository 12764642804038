import { hasType } from "~/common/utils/hasType";
import {
  BoxGeometry,
  BufferGeometry,
  ConeGeometry,
  CylinderGeometry,
  Geometry,
  GeometryType,
  PlaneGeometry,
  SphereGeometry,
} from "~/types/Geometry";
import { BoxGeometryInspector } from "./BoxGeometryInspector";
import { BufferGeometryInspector } from "./BufferGeometryInspector";
import { ConeGeometryInspector } from "./ConeGeometryInspector";
import { CylinderGeometryInspector } from "./CylinderGeometryInspector";
import { PlaneGeometryInspector } from "./PlaneGeometryInspector";
import { SphereGeometryInspector } from "./SphereGeometryInspector";
import { GeometryInspectorBaseProps } from "./types";

export type GeometryInspectorProps = GeometryInspectorBaseProps;

export const GeometryInspector = ({ entity, geometry, onChange }: GeometryInspectorProps) => {
  if (hasType<Geometry, BoxGeometry, GeometryType>(geometry, GeometryType.box)) {
    return <BoxGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  } else if (hasType<Geometry, ConeGeometry, GeometryType>(geometry, GeometryType.cone)) {
    return <ConeGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  } else if (hasType<Geometry, CylinderGeometry, GeometryType>(geometry, GeometryType.cylinder)) {
    return <CylinderGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  } else if (hasType<Geometry, SphereGeometry, GeometryType>(geometry, GeometryType.sphere)) {
    return <SphereGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  } else if (hasType<Geometry, PlaneGeometry, GeometryType>(geometry, GeometryType.plane)) {
    return <PlaneGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  } else if (hasType<Geometry, BufferGeometry, GeometryType>(geometry, GeometryType.buffer)) {
    return <BufferGeometryInspector entity={entity} geometry={geometry} onChange={onChange} />;
  }
  return null;
};
