import { useFrame, useThree } from "@react-three/fiber";
import { useStoreMap, useUnit } from "effector-react";
import { useEffect, useState } from "react";
import { useUnmount } from "react-use";
import { useSceneData } from "~/common/stores/useSceneData";
import { $flags } from "~/entities/flags";
import { $items, reset } from "./models";
import { initRender, render } from "./render";

export const OcclusionCullingRenderer = () => {
  const occlusionCulling = useSceneData((state) => state.sceneState?.occlusionCulling);
  const occlusionCullingDebugger = useStoreMap($flags, (flags) => flags.occlusionCullingDebugger);
  const [context, setContext] = useState<any | null>(null);
  const { camera } = useThree();
  const items = useUnit($items);

  useEffect(() => {
    if (!occlusionCulling) {
      return;
    }

    const ctx = initRender({
      debugger: occlusionCullingDebugger,
    });
    setContext(ctx);

    const resizeHandler = () => {
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      setContext(null);
    };
  }, [occlusionCulling, occlusionCullingDebugger]);

  useFrame(() => {
    if (!context) {
      return;
    }

    render(context, camera, items);
  });

  useUnmount(() => reset());

  return null;
};
