import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, NodeSocket } from "../../base";
import { FlowSocket } from "../../sockets";
import { ScriptVariableBaseNode } from "./ScriptVariableBaseNode";

export class SetScriptVariableNode extends ScriptVariableBaseNode {
  static type = "variable/setScriptVariable";
  static label = "Set Script Variable";
  static category: NodeCategory = "Variable";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const componentId = node.spec.metadata?.componentId ?? "";
    const variableId = node.spec.metadata?.variableId ?? "";
    const script = node.sceneContext.getScriptByComponent(componentId);
    const value = context.readInput("value");
    script?.variables.setValue(variableId, value);
  }

  getInputs(nodeSpec: NodeSpec): NodeSocket[] {
    const socket = this.getVariableSocket(nodeSpec);

    return socket ? [...super.getInputs(nodeSpec), socket] : [...super.getInputs(nodeSpec)];
  }
}
