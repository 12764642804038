import { Matrix4 } from "three";
import Ammo from "~/view-scene/libs/ammo/ammo.wasm";
import {
  ANGULAR_VELOCITY_OFFSET,
  BODY_DATA_LENGTH,
  BufferState,
  COLLISIONS_OFFSET,
  ENDED_COLLISIONS_OFFSET,
  LINEAR_VELOCITY_OFFSET,
  TRANSFORM_OFFSET,
} from "./constants";

export abstract class Data {
  maxBodies: number = 0;

  protected headerFloatArray!: Float32Array;
  protected headerIntArray!: Int32Array;
  protected objectMatricesFloatArray!: Float32Array;
  protected objectMatricesIntArray!: Int32Array;

  abstract dispose(): void;
  abstract consume(): void;
  abstract ready(): void;

  setTransform(index: number, sourceMatrix: Matrix4) {
    this.objectMatricesFloatArray.set(sourceMatrix.elements, index * BODY_DATA_LENGTH);
  }

  applyTransform(index: number, targetMatrix: Matrix4) {
    targetMatrix.fromArray(this.objectMatricesFloatArray, index * BODY_DATA_LENGTH + TRANSFORM_OFFSET);
  }

  setLinearVelocity(index: number, velocity: Ammo.btVector3) {
    const offset = index * BODY_DATA_LENGTH + LINEAR_VELOCITY_OFFSET;

    this.objectMatricesFloatArray[offset] = velocity.x();
    this.objectMatricesFloatArray[offset + 1] = velocity.y();
    this.objectMatricesFloatArray[offset + 2] = velocity.z();
  }

  applyLeanerVelocity(index: number, arr: [number, number, number]) {
    const offset = index * BODY_DATA_LENGTH + LINEAR_VELOCITY_OFFSET;

    arr[0] = this.objectMatricesFloatArray[offset];
    arr[1] = this.objectMatricesFloatArray[offset + 1];
    arr[2] = this.objectMatricesFloatArray[offset + 2];
  }

  setAngularVelocity(index: number, velocity: Ammo.btVector3) {
    const offset = index * BODY_DATA_LENGTH + ANGULAR_VELOCITY_OFFSET;

    this.objectMatricesFloatArray[offset] = velocity.x();
    this.objectMatricesFloatArray[offset + 1] = velocity.y();
    this.objectMatricesFloatArray[offset + 2] = velocity.z();
  }

  applyAngularVelocity(index: number, arr: [number, number, number]) {
    const offset = index * BODY_DATA_LENGTH + ANGULAR_VELOCITY_OFFSET;

    arr[0] = this.objectMatricesFloatArray[offset];
    arr[1] = this.objectMatricesFloatArray[offset + 1];
    arr[2] = this.objectMatricesFloatArray[offset + 2];
  }

  getBufferState() {
    return this.headerIntArray[0] as BufferState;
  }

  setBufferState(state: BufferState) {
    this.headerIntArray[0] = state;
  }

  getStepDuration() {
    return this.headerFloatArray[1];
  }

  setStepDuration(time: number) {
    this.headerFloatArray[1] = time;
  }

  setCollisions(index: number, collisions: number[]) {
    this.objectMatricesIntArray.set(collisions, index * BODY_DATA_LENGTH + COLLISIONS_OFFSET);
  }

  applyCollisions(index: number, targetArray: number[]) {
    const offset = index * BODY_DATA_LENGTH + COLLISIONS_OFFSET;

    targetArray[0] = this.objectMatricesIntArray[offset];
    targetArray[1] = this.objectMatricesIntArray[offset + 1];
    targetArray[2] = this.objectMatricesIntArray[offset + 2];
    targetArray[3] = this.objectMatricesIntArray[offset + 3];
    targetArray[4] = this.objectMatricesIntArray[offset + 4];
    targetArray[5] = this.objectMatricesIntArray[offset + 5];
    targetArray[6] = this.objectMatricesIntArray[offset + 6];
    targetArray[7] = this.objectMatricesIntArray[offset + 7];
  }

  setEndedCollisions(index: number, collisions: number[]) {
    this.objectMatricesIntArray.set(collisions, index * BODY_DATA_LENGTH + ENDED_COLLISIONS_OFFSET);
  }

  applyEndedCollisions(index: number, targetArray: number[]) {
    const offset = index * BODY_DATA_LENGTH + ENDED_COLLISIONS_OFFSET;

    targetArray[0] = this.objectMatricesIntArray[offset];
    targetArray[1] = this.objectMatricesIntArray[offset + 1];
    targetArray[2] = this.objectMatricesIntArray[offset + 2];
    targetArray[3] = this.objectMatricesIntArray[offset + 3];
    targetArray[4] = this.objectMatricesIntArray[offset + 4];
    targetArray[5] = this.objectMatricesIntArray[offset + 5];
    targetArray[6] = this.objectMatricesIntArray[offset + 6];
    targetArray[7] = this.objectMatricesIntArray[offset + 7];
  }

  isReady() {
    return this.getBufferState() === BufferState.READY;
  }

  isConsumed() {
    return !this.isReady();
  }
}
