import { Object3D } from "three";
import { useHelper } from "@react-three/drei";
import { MutableRefObject } from "react";

export type RenderLightHelperProps = {
  lightRef: MutableRefObject<Object3D>;
  helperConstructor: any;
};

export function RenderLightHelper({ lightRef, helperConstructor }: RenderLightHelperProps) {
  useHelper(lightRef, helperConstructor);

  return null;
}
