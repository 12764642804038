import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { $selectedWorkspace } from "../models";
import { useUnit } from "effector-react";
import * as sceneAPI from "~/api/scene.api";
import { TemplateCard } from "./TemplateCard";
import { useNavigate } from "react-router-dom";
import { Modal } from "~/common/components";
import { $templatesModal, closeTemplatesModal } from "./models";

export function TemplatesModal() {
  const [templatesModal, selectedSpace] = useUnit([$templatesModal, $selectedWorkspace]);
  const navigate = useNavigate();

  if (!templatesModal.open || !selectedSpace) {
    return null;
  }

  const handleCreateSceneFromTemplate = async (sceneId: string) => {
    const scene = await sceneAPI.createSceneFromTemplate(sceneId, selectedSpace._id);
    navigate(`/builder/${scene._id}`);
  };

  const templatesScenes = [
    {
      sceneId: "631b24b7f8f11ba1ffe2557d",
      sceneName: "Educational level",
      previewUrl: "https://8xr.io/assets/869362e7-0d5d-49d7-8e44-252577f7d966.jpg",
      sceneUrlName: "education-level",
    },
    {
      sceneId: "639b7ec2546478e0aeee3bd6",
      sceneName: "Ecommerce",
      previewUrl: "https://8xr.io/assets/a693ccb5-d618-4b7f-b52e-fa6c3123df1a.webp",
      sceneUrlName: "ecommerce",
    },
  ];

  return (
    <StyledModal title="Scene Templates" open={templatesModal.open} onClose={() => closeTemplatesModal()}>
      <Wrapper>
        {templatesScenes.map((item) => (
          <Box key={item.sceneId} component="div" sx={{ width: "313px" }}>
            <TemplateCard sceneUrlName={item.sceneUrlName} previewUrl={item.previewUrl} />

            <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ marginTop: "12px" }}>
              <Typography fontWeight="500" variant="body2">
                {item.sceneName}
              </Typography>

              <UseTemplateButton
                variant="outlined"
                size="medium"
                onClick={() => handleCreateSceneFromTemplate(item.sceneId)}
              >
                Use Template
              </UseTemplateButton>
            </Stack>
          </Box>
        ))}
      </Wrapper>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  backdrop-filter: blur(1px);
`;

const Wrapper = styled(Stack)`
  display: grid;
  border-top: 1px solid ${palette.primary.gray40};
  padding: 16px;
  grid-gap: 32px;
  grid: auto / repeat(3, 2fr);
  max-height: 464px;
  overflow: auto;

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.primary.gray200};
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.primary.gray600};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${palette.primary.white};
    transition: 0.4s ease;
    cursor: pointer;
  }
`;

const UseTemplateButton = styled(Button)`
  background-color: ${palette.primary.black};
  border-radius: 20px;
  padding: 4px 8px;
  border: 1px solid transparent;
`;
