import { NodeContext } from "../../base";
import { PlayerSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToPlayerNode extends CastToNode {
  static readonly type = "logic/castToPlayer";
  static readonly label = "Cast to Player";

  constructor(context: NodeContext) {
    super(context, new PlayerSocket("player"), "player");
  }
}
