import { GroupProps } from "@react-three/fiber";
import { Interactive } from "@react-three/xr";
import { memo, useCallback, useEffect } from "react";
import { userActions } from "~/view-scene/ControlsSystem";
import { Layer } from "~/view-scene/layers";
import { useSprite } from "~/view-scene/utils";

export type LeaveButtonProps = Pick<GroupProps, "position"> & {
  width?: number;
};

export const LeaveButton = memo(({ position, width = 1 }: LeaveButtonProps) => {
  const titleSprite = useSprite(
    "/static/img/viewer/video360-entity/leave.png",
    { width: width * 1.58 },
    Layer.video360
  );
  const buttonSprite = useSprite("/static/img/viewer/video360-entity/close.png", { width }, Layer.video360);

  useEffect(() => {
    titleSprite.visible = false;
    titleSprite.position.set(0, 0.1, 0);
  }, []);

  const handleHover = useCallback(() => {
    titleSprite.visible = true;
  }, []);

  const handleBlur = useCallback(() => {
    titleSprite.visible = false;
  }, []);

  const handleSelect = useCallback(() => {
    userActions.exit.emit("fire");
  }, []);

  return (
    <group position={position}>
      <primitive object={titleSprite} />
      <Interactive onHover={handleHover} onBlur={handleBlur} onSelect={handleSelect}>
        <group>
          <primitive object={buttonSprite} />
        </group>
      </Interactive>
    </group>
  );
});
