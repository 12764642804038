import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class NumberClampNode extends BaseNode {
  static readonly type = "logic/numberClamp";
  static readonly label = "Number Clamp";
  static readonly category: NodeCategory = "Logic";

  inputs = [new NumberSocket("value"), new NumberSocket("min"), new NumberSocket("max", "max", 1)];
  outputs = [new NumberSocket("result")];

  eval(context: NodeEvalContext) {
    const value = context.readInput<number>("value");
    const min = context.readInput<number>("min");
    const max = context.readInput<number>("max");

    context.writeOutput<number>("result", Math.min(Math.max(value, min), max));
  }
}
