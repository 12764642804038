import { AssetType } from "~/types/AssetType";
import {
  Audio,
  CodeSquare,
  Cube,
  Droplet,
  Flash,
  IconProps,
  InventoryItem,
  Map,
  Storage,
  UI,
  Video,
  AlignJustify,
  Image,
} from "~/common/components/Icon";
import { FC } from "react";

export type AssetIconProps = IconProps & {
  type: AssetType;
};

export const AssetIcon = ({ type, ...rest }: AssetIconProps) => {
  const Component = componentsMap[type];

  return Component ? <Component {...rest} /> : null;
};

const componentsMap: Record<AssetType, FC<IconProps>> = {
  animation: Flash,
  audio: Audio,
  glb: Cube,
  image: Image,
  material: Droplet,
  script: CodeSquare,
  jsScript: CodeSquare,
  skybox: Map,
  hdri: Map,
  video: Video,
  ui: UI,
  storage: Storage,
  inventoryItem: InventoryItem,
  // TODO icon for struct
  struct: AlignJustify,
};
