import { useLoader, useThree } from "@react-three/fiber";
import { memo, useEffect } from "react";
import { KTX2Loader } from "three-stdlib";
import { BASIS_TRANSCODER_URL } from "~/config";
import { textureManager } from "../TextureManager";
import { CompressedImageTextureRecord } from "../types";
import { useCommonTextureProperties } from "./useCommonTextureProperties";

type CompressedImageTextureProps = {
  textureRecord: CompressedImageTextureRecord;
};

export const CompressedImageTexture = memo(({ textureRecord }: CompressedImageTextureProps) => {
  const gl = useThree((state) => state.gl);

  const { id, url, tiling, encoding, flipY } = textureRecord;

  const texture = useLoader(KTX2Loader, `${url}?id=${flipY}`, (loader) => {
    loader.detectSupport(gl);
    loader.setTranscoderPath(BASIS_TRANSCODER_URL);
  });

  useCommonTextureProperties(texture, flipY, encoding, tiling);

  useEffect(() => {
    gl.initTexture(texture);

    let gpuMemoryInBytes = 0;
    for (const mip of texture.mipmaps) {
      gpuMemoryInBytes += mip.data.byteLength;
    }

    textureManager.finishTextureLoad(id, texture, gpuMemoryInBytes);

    return () => {
      texture.dispose();
    };
  }, [texture]);

  return null;
});
