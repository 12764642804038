import { EventEmitter } from "eventemitter3";

export interface BaseAdsProvider {
  on(event: "started", listener: () => void): this;
  on(event: "completed", listener: () => void): this;
  on(event: "closed", listener: () => void): this;
  on(event: "finished", listener: (viewed: boolean) => void): this;
  on(event: "error", listener: () => void): this;

  once(event: "started", listener: () => void): this;
  once(event: "completed", listener: () => void): this;
  once(event: "closed", listener: () => void): this;
  once(event: "finished", listener: (viewed: boolean) => void): this;
  once(event: "error", listener: () => void): this;

  off(event: "started", listener: () => void): this;
  off(event: "completed", listener: () => void): this;
  off(event: "closed", listener: () => void): this;
  off(event: "finished", listener: (viewed: boolean) => void): this;
  off(event: "error", listener: () => void): this;

  emit(event: "started"): boolean;
  emit(event: "completed"): boolean;
  emit(event: "closed"): boolean;
  emit(event: "finished", viewed: boolean): boolean;
  emit(event: "error"): boolean;
}

export abstract class BaseAdsProvider extends EventEmitter {
  public type = "";
  public isPlaying = false;

  abstract init(): Promise<void>;
  abstract dispose(): Promise<void> | void;
  abstract start(): void;
  abstract hasAds(): Promise<boolean>;
}
