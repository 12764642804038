import { createEvent, createStore } from "effector";
import { random } from "lodash-es";
import ITransformation from "~/types/ITransformation";
import { resetRuntime } from "../reset";

export type SpawnPoint = ITransformation & {
  id: string;
};

export const registerSpawnPoint = createEvent<SpawnPoint>();

export const $spawnPoints = createStore<SpawnPoint[]>([])
  .on(registerSpawnPoint, (points, newPoint) => [...points, newPoint])
  .reset(resetRuntime);

export const getSpawnPoint = (id?: string) => {
  const spawnPoints = $spawnPoints.getState();

  if (spawnPoints.length === 0) {
    return null;
  }

  if (!id) {
    return spawnPoints[random(0, spawnPoints.length - 1)];
  }

  return spawnPoints.find((point) => point.id === id) ?? null;
};

export const getPlayerSpawnTransform = (transform: ITransformation | null, yOffset: number): ITransformation => {
  if (!transform) {
    return {
      position: {
        x: 0,
        y: yOffset,
        z: 0,
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
    };
  } else {
    return {
      position: {
        x: transform.position.x,
        y: transform.position.y + yOffset / 2,
        z: transform.position.z,
      },
      rotation: {
        ...transform.rotation,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
    };
  }
};
