import { NotificationIcon, useNotificationsState } from "~/view-scene/ui";
import { useSceneData } from "~/common/stores/useSceneData";
import shallow from "zustand/shallow";
import { useEffect, useRef } from "react";

export function useShowJoinersLeaversNotifications() {
  const onlineUserIds = useSceneData((state) => state.remoteUsers.map((u) => u.id).sort(), shallow);
  const prevOnlineUserIdsRef = useRef<string[]>([]);

  const addNotification = useNotificationsState((state) => state.addNotification);

  useEffect(() => {
    const prevOnlineUserIds = prevOnlineUserIdsRef.current;
    const joiners = onlineUserIds.filter((onlineUserId) => !prevOnlineUserIds.includes(onlineUserId));
    const leavers = prevOnlineUserIds.filter((prevOnlineUserId) => !onlineUserIds.includes(prevOnlineUserId));

    joiners.forEach((joinerId) => {
      const joinerName = useSceneData.getState().getRemoteUser(joinerId)?.name ?? "Somebody";
      addNotification({
        icon: NotificationIcon.USER_ICON,
        text: `${joinerName} entered the space`,
        duration: 3000,
      });
    });

    leavers.forEach((leaverId) => {
      const leaverName = useSceneData.getState().getRemoteUser(leaverId)?.name ?? "Somebody";
      addNotification({
        icon: NotificationIcon.USER_ICON,
        text: `${leaverName} has left the space`,
        duration: 3000,
      });
    });

    prevOnlineUserIdsRef.current = onlineUserIds;
  }, [onlineUserIds]);
}
