import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class OpenTelegramLinkNode extends BaseNode {
  static readonly type = "telegram/openTelegramLink";
  static readonly label = "Open Telegram Link";

  inputs = [new FlowSocket("flow"), new StringSocket("tLink")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext) {
    const telegramLink = context.readInput<string>("tLink");
    window.Telegram?.WebApp.openTelegramLink(telegramLink);
  }
}
