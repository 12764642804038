import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

type SendUserEventPayload = {
  eventType: string;
  data?: any;
};

export const sendUserEvent = async (payload: SendUserEventPayload) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/userEvents`, requestOptions);
  const status = response.status;

  const body = await response.json();

  return { status, body };
};
