import { Typography } from "@mui/material";
import { palette } from "~/theme";

type DesktopHeaderMenuItemProps = {
  text: string;
};

export function DesktopHeaderMenuItem({ text }: DesktopHeaderMenuItemProps) {
  return (
    <Typography
      whiteSpace="nowrap"
      color={palette.primary.white}
      fontWeight={600}
      fontSize={16}
      lineHeight="20px"
      sx={{
        position: "relative",
        "&:hover:after": {
          width: "100%",
        },
        "&::after": {
          content: '""',
          height: "1px",
          position: "absolute",
          top: "calc(100% + 5px)",
          backgroundColor: palette.primary.white,
          width: "0",
          left: 0,
          transition: ".4s",
        },
      }}
    >
      {text}
    </Typography>
  );
}
