import { Menu as MuiMenu, MenuProps, styled } from "@mui/material";
import { palette } from "~/theme";
import * as React from "react";

export type { MenuProps };

export const Menu = styled(({ children, PaperProps, ...rest }: MenuProps) => (
  <MuiMenu
    PaperProps={{
      sx: {
        borderRadius: "12px",
        border: "1px solid " + palette.primary.gray20,
        background: palette.primary.gradient600,
        padding: "4px",
        minWidth: "180px",
        maxWidth: "180px",
        ...PaperProps?.sx,
      },
    }}
    {...rest}
  >
    {children}
  </MuiMenu>
))(({ theme }) => ({}));
