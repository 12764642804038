import { createEvent, EventPayload, sample } from "effector";
import { $entities, updateEntity as storeUpdateEntity } from "~/entities/entities";
import { sendUpdateEntity } from "~/api/socket";
import { editorUndoFactory } from "~/hephaestus/models";

const mappedSendUpdateEntity = createEvent<EventPayload<typeof storeUpdateEntity>>();

sample({
  source: $entities,
  clock: mappedSendUpdateEntity,
  fn: (entities, payload) => {
    const entity = entities?.find((entity) => entity.id === payload.id)!;
    return {
      ...entity,
      ...payload.entity,
    };
  },
  target: sendUpdateEntity,
});

export const [updateEntity] = editorUndoFactory(
  [storeUpdateEntity, mappedSendUpdateEntity],
  [storeUpdateEntity, mappedSendUpdateEntity],
  (payload) => {
    const prevEntity = $entities.getState()?.find((entity) => entity.id === payload.id);

    return (
      prevEntity && {
        id: prevEntity.id,
        entity: prevEntity,
      }
    );
  }
);
