import { useEffect, useRef } from "react";
import videojs, { VideoJsPlayerOptions } from "video.js";
import { Box, styled } from "@mui/material";
import "./index.css";
import { initialOptions } from "./constants";
import { Audio } from "~/common/components/Icon";
import { palette } from "~/theme";

export type AudioPlayerProps = {
  options: Partial<VideoJsPlayerOptions>;
};

export const AudioPlayer = ({ options }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const audioPlayerRef = useRef<videojs.Player>();

  useEffect(() => {
    const element = audioRef.current;
    if (!element) {
      return;
    }

    audioPlayerRef.current = videojs(element, {
      ...initialOptions,
      ...options,
    });

    return () => {
      audioPlayerRef.current?.dispose();
    };
  }, [options]);

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "240px",
        flex: "0 0 240px",
        position: "relative",
      }}
    >
      <audio ref={audioRef} className="video-js audio" />
      <AudioIcon />
    </Box>
  );
};

const AudioIcon = styled(Audio)`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${palette.primary.gray600};
`;
