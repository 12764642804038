import { defaultUIEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { uiTextValue, UITextValue } from "../values";

export class UITextSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: UITextValue = defaultUIEntity()) {
    super(uiTextValue.name, id, label, value);
  }

  clone() {
    return new UITextSocket(this.id, this.label, this.value);
  }
}
