import { useEffect, useState } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { useFrame } from "@react-three/fiber";
import ITriggerZoneSceneObject from "~/types/ITriggerZoneSceneObject";
import { EnabledStatus } from "~/types/EnabledStatus";

export default function useTriggerZone(triggerZoneId: string | null | undefined, enabled: EnabledStatus) {
  const [entityEnabled, setEntityEnabled] = useState(enabled === "enabled");

  const getEntity = useSceneData((state) => state.getEntity);

  useEffect(() => {
    const isEnabled = enabled === EnabledStatus.enabled;
    if (entityEnabled !== isEnabled) {
      setEntityEnabled(isEnabled);
    }
  }, [enabled]);

  useFrame(() => {
    if (enabled !== "triggerZone" || !triggerZoneId) {
      return;
    }

    const triggerZone = getEntity<ITriggerZoneSceneObject>(triggerZoneId);
    if (!triggerZone) {
      return;
    }

    const enableEntity = triggerZone.triggered ?? false;
    if (entityEnabled !== enableEntity) {
      setEntityEnabled(enableEntity);
    }
  });

  return entityEnabled;
}
