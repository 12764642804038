import { AdCampaignDto, AdConfigBodyDto } from "./types";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getAdConfig, updateAdConfig } from "~/api/telegram.api";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "~/hephaestus/components";
import { EditAdCampaignDialog } from "./EditAdCampaignDialog";

export function TelegramAdsSettings() {
  const [adConfig, setAdConfig] = useState<AdConfigBodyDto>();
  useEffect(() => {
    getAdConfig().then((response) => setAdConfig(response.body));
  }, []);

  const adCampaigns = useMemo(() => adConfig?.campaigns ?? [], [adConfig]);
  const [selectedAdCampaign, setSelectedAdCampaign] = useState<AdCampaignDto | null>(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleEdit = (adCampaign: AdCampaignDto) => {
    setSelectedAdCampaign(adCampaign);
    setEditDialogOpen(true);
  };

  const handleSave = async (adCampaign: AdCampaignDto) => {
    setSelectedAdCampaign(null);
    setEditDialogOpen(false);

    let addConfig: AdConfigBodyDto;
    if (adCampaign.id) {
      addConfig = {
        ...adConfig,
        campaigns: (adConfig?.campaigns ?? []).map((c) => (c.id === adCampaign.id ? adCampaign : c)),
      };
    } else {
      addConfig = {
        ...adConfig,
        campaigns: [...(adConfig?.campaigns ?? []), adCampaign],
      };
    }

    const { status, body } = await updateAdConfig(addConfig);
    if (status === 200) {
      setAdConfig(body);
    }
  };

  const handleClose = () => {
    setSelectedAdCampaign(null);
    setEditDialogOpen(false);
  };

  const handleDelete = async (adCampaign: AdCampaignDto) => {
    const updatedAdConfig = {
      ...adConfig,
      campaigns: (adConfig?.campaigns ?? []).filter((c) => c !== adCampaign),
    };
    const { status, body } = await updateAdConfig(updatedAdConfig);
    if (status === 200) {
      setAdConfig(body);
    }
  };

  const sortedCampaigns = useMemo(() => adCampaigns.sort((a, b) => a.priority - b.priority), [adCampaigns]);

  return (
    <Stack alignItems="flex-start" spacing={2}>
      <EditAdCampaignDialog
        open={editDialogOpen}
        adCampaign={selectedAdCampaign}
        onSave={handleSave}
        onClose={handleClose}
      />
      <Button onClick={() => setEditDialogOpen(true)} variant="accent">
        Add
      </Button>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell key={cell.id} align="left" padding={cell.disablePadding ? "none" : "normal"}>
                  <Typography variant="body2" color="secondary">
                    {cell.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCampaigns.map((adCampaign, index) => (
              <AdCampaignRecord
                key={adCampaign.id ?? index}
                adCampaign={adCampaign}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

type AdCampaignRecordProps = {
  adCampaign: AdCampaignDto;
  onEdit: (campaignDto: AdCampaignDto) => void;
  onDelete: (campaignDto: AdCampaignDto) => void;
};

function AdCampaignRecord({ adCampaign, onEdit, onDelete }: AdCampaignRecordProps) {
  return (
    <TableRow hover>
      <TableCell component="th" scope="row" padding="none" width="15%">
        {adCampaign.priority ?? "N/A"}
      </TableCell>
      <TableCell component="th" scope="row" padding="none" width="15%">
        {adCampaign.name ?? "N/A"}
      </TableCell>
      <TableCell>
        <StatusLabel isEnabled={adCampaign.enabled} />
      </TableCell>
      <TableCell>
        {adCampaign.locales?.length > 0 ? (
          <Box component="div" sx={{ display: "flex", flexWrap: "wrap" }}>
            {adCampaign.locales?.map((locale) => (
              <Chip key={locale} label={locale} sx={{ margin: "4px" }} variant="filled" />
            ))}
          </Box>
        ) : (
          "All Locales"
        )}
      </TableCell>
      <TableCell>
        {adCampaign.botIds?.length > 0 ? (
          <Box component="div" sx={{ display: "flex", flexWrap: "wrap" }}>
            {adCampaign.botIds?.map((botId) => (
              <Chip key={botId} label={botId} sx={{ margin: "4px" }} variant="filled" />
            ))}
          </Box>
        ) : (
          "All Bots"
        )}
      </TableCell>
      <TableCell>
        {adCampaign.excludedBotIds?.length > 0 ? (
          <Box component="div" sx={{ display: "flex", flexWrap: "wrap" }}>
            {adCampaign.excludedBotIds?.map((botId) => (
              <Chip key={botId} label={botId} sx={{ margin: "4px" }} variant="filled" />
            ))}
          </Box>
        ) : (
          "No Excluded"
        )}
      </TableCell>
      <TableCell>{adCampaign.targetImpressions ?? 0}</TableCell>
      <TableCell>{adCampaign.currentImpressions ?? 0}</TableCell>
      <TableCell>
        <IconButton onClick={() => onEdit(adCampaign)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(adCampaign)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const StatusLabel = ({ isEnabled }: { isEnabled: boolean }) => {
  return (
    <Chip
      label={isEnabled ? "Active" : "Not Active"}
      style={{
        backgroundColor: isEnabled ? "green" : "red",
        color: "white",
      }}
    />
  );
};
const headCells = [
  { id: "priority", numeric: true, disablePadding: true, label: "Priority" },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "enabled", numeric: false, disablePadding: false, label: "Active" },
  { id: "locales", numeric: false, disablePadding: false, label: "Locales" },
  { id: "botIds", numeric: false, disablePadding: false, label: "Included Bots" },
  { id: "excludedBotIds", numeric: false, disablePadding: false, label: "Excluded Bots" },
  { id: "targetImpressions", numeric: true, disablePadding: false, label: " Target Impressions" },
  { id: "currentImpressions", numeric: true, disablePadding: false, label: "Current Impressions" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];
