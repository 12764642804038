import { CircularProgress, IconButton, styled } from "@mui/material";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

export function DownloadChart({ url, name }: { url: string; name: string }) {
  const [isLoading, setIsLoading] = useState(false);

  async function downloadData() {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          "x-auth-token": "fsdjkalfh98370[2h-9hr1p'/1;m12lkdi",
        },
      });
      if (response.ok) {
        const data = await response.text();
        const blob = new Blob([data], { type: "text/csv" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${name}.csv`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } else {
        console.error(`Error fetching data: ${response.statusText}`);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <DownloadButton onClick={() => downloadData()} disabled={isLoading}>
      {isLoading ? <CircularProgress size={20} color="secondary" /> : <DownloadIcon />}
    </DownloadButton>
  );
}

const DownloadButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
`;
