import { BoxProps, styled } from "@mui/material";
import { ArrowLeft } from "~/common/components/Icon";
import { palette } from "~/theme";
import { Navigate } from "./Navigate";

type BackToExploreProps = {
  text?: string;
  url?: string;
  sx?: BoxProps;
};

export function BackToExplore({ text = "Explore", url = "/", sx }: BackToExploreProps) {
  return (
    <Navigate
      to={url}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "20px",
        color: palette.primary.white,
        textDecoration: "none",
        transition: ".4s ease",
        "&:hover": {
          opacity: 0.8,
        },
        ...sx,
      }}
    >
      <ArrowLeftIcon />
      {text}
    </Navigate>
  );
}

const ArrowLeftIcon = styled(ArrowLeft)`
  width: 24px;
  height: 24px;
`;
