import { useContext, useEffect } from "react";
import { UIEntitiesContext } from "./UIEntitiesContext";

export function useWidthHeight(
  id: string,
  parentId: string | undefined | null,
  width: number,
  height: number,
  aspectRatio: [number, number, number],
  sizeUnits: "percent" | "meters",
  relativeToParent = false
) {
  const context = useContext(UIEntitiesContext);

  const parentSize = context?.entitySizes?.get(parentId ?? "") ?? { x: 1, y: 1 };
  const multiplier = relativeToParent
    ? parentSize
    : {
        x: aspectRatio[0],
        y: aspectRatio[1],
      };

  const elementWidth = sizeUnits === "percent" ? multiplier.x * width : width;
  const elementHeight = sizeUnits === "percent" ? multiplier.y * height : height;

  useEffect(() => {
    if (!context?.setEntitySize) {
      return;
    }

    context?.setEntitySize(id, {
      x: elementWidth,
      y: elementHeight,
    });
  }, [id, elementWidth, elementHeight, context?.setEntitySize]);

  return { elementWidth, elementHeight };
}
