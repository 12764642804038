import { forwardRef } from "react";
import { Camera as ThreeCamera } from "three";
import { Camera } from "~/types/ICameraSceneObject";
import { RenderOrthographicCamera } from "./RenderOrthographicCamera";
import { RenderPerspectiveCamera } from "./RenderPerspectiveCamera";

export type RenderCameraProps = {
  default: boolean;
  camera: Camera;
};

export const RenderCamera = forwardRef<ThreeCamera, RenderCameraProps>(({ camera, default: isDefault }, ref) => {
  if (camera.type === "perspective") {
    return <RenderPerspectiveCamera ref={ref} camera={camera} default={isDefault} />;
  }

  if (camera.type === "orthographic") {
    return <RenderOrthographicCamera ref={ref} camera={camera} default={isDefault} />;
  }

  return null;
});
