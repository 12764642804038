import { requestNonceForWallet } from "~/api/auth.api";
import { BlockchainNetwork, Web3Wallet } from "~/web3Helpers";
import { attachWallet } from "~/api/profile.api";
import { AttachWalletRequest } from "./types";
import { encode } from "micro-base58";

type UseAttachPhantomProps = {
  onWalletAttached: VoidFunction;
  onError: (message?: string) => void;
};

export function useAttachPhantom({ onWalletAttached, onError }: UseAttachPhantomProps) {
  const requestAttachPhantom = async () => {
    const provider = () => {
      if ("solana" in window) {
        // @ts-ignore
        const provider = window.solana as any;
        if (provider.isPhantom) {
          return provider;
        } else {
          return false;
        }
      }
      window.open("https://phantom.app", "_blank");
    };

    const phantom = provider();
    if (phantom === false) {
      onError("Phantom Wallet is not installed");
      return;
    }

    await phantom.connect();
    const publicAddress = phantom.publicKey.toString();

    const nonce = await requestNonceForWallet(publicAddress);

    const message = `Sign the message to confirm your identity: ${nonce}`;
    const data = new TextEncoder().encode(message);
    const signed = await phantom.signMessage(data, "utf8");
    const signature = encode(signed.signature);

    const payload: AttachWalletRequest = {
      wallet: Web3Wallet.phantom,
      network: BlockchainNetwork.solana,
      walletAddress: publicAddress,
      signature,
    };

    const { status } = await attachWallet(payload);

    if (status !== 200) {
      onError();
      return;
    }

    await onWalletAttached();
  };

  return { requestAttachPhantom };
}
