import { useUnmount } from "react-use";
import { useSceneData } from "~/common/stores/useSceneData";
import { resetStorages } from "~/entities/storages";
import { resetRuntime } from "./runtime/reset";
import { resetSceneLoad } from "./sceneLoad";
import useSessionStatus from "./stores/useSessionStatus";
import { resetControlsSource } from "./ControlsSource";

export const useDataReset = () => {
  const sceneDataReset = useSceneData((state) => state.reset);
  const sessionStatusReset = useSessionStatus((state) => state.reset);

  useUnmount(() => {
    sceneDataReset();
    sessionStatusReset();
    resetSceneLoad();
    resetStorages();
    resetRuntime();
    resetControlsSource();
  });
};
