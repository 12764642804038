import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

const vectorA = new Vector3();
const vectorB = new Vector3();

export class VectorDistanceNode extends BaseNode {
  static readonly type = "logic/vectorDistance";
  static readonly label = "Vector Distance";

  inputs = [new Vector3Socket("a"), new Vector3Socket("b")];
  outputs = [new NumberSocket("distance")];

  eval(context: NodeEvalContext) {
    const a = context.readInput<Vector3Value>("a");
    const b = context.readInput<Vector3Value>("b");
    vectorA.set(a.x, a.y, a.z);
    vectorB.set(b.x, b.y, b.z);

    context.writeOutput<number>("distance", vectorA.distanceTo(vectorB));
  }
}
