import { defaultComponent } from "~/entities/variable";
import { BaseSocket } from "../base";
import { trajectoryValue, TrajectoryValue } from "../values";

export class TrajectorySocket extends BaseSocket {
  constructor(id: string, label: string = id, value: TrajectoryValue = defaultComponent()) {
    super(trajectoryValue.name, id, label, value);
  }

  clone() {
    return new TrajectorySocket(this.id, this.label, this.value);
  }
}
