import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Link, LinkProps } from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { useProfile } from "~/entities/profile";
import { HOST } from "~/config";

type NavigateProps = LinkProps & {
  to: string;
  requireAuth?: boolean;
  openInNewWindow?: boolean;
  children?: ReactNode;
};

export function Navigate({
  to,
  requireAuth = false,
  openInNewWindow = false,
  children,
  sx,
  className,
  ...rest
}: NavigateProps) {
  const navigate = useNavigate();

  const pathname = useLocation().pathname;

  const [searchParams, setSearchParams] = useSearchParams();

  const { isAuthorised, updateRedirectPage } = useProfile((state) => ({
    isAuthorised: state.isAuthorised,
    updateRedirectPage: state.updateRedirectPage,
  }));

  const handleNavigate = (e: MouseEvent) => {
    e.preventDefault();

    if (requireAuth && !isAuthorised()) {
      const param = searchParams.get("auth");
      if (param) {
        searchParams.delete("auth");
      }

      searchParams.append("auth", "login");
      setSearchParams(searchParams);
      updateRedirectPage(to);

      return;
    }

    const from = window.location.pathname;

    const isToProfile = to.includes("/profile");
    const isFromProfile = from.includes("/profile");
    const isNotInsideProfileNavigation = !(isToProfile && isFromProfile);

    const isToScene = isScenePath(to) || isDeverseeScene(to) || isTicketmetaScene(to);
    const isFromScene = isScenePath(from) || isDeverseeScene(from) || isTicketmetaScene(from);
    const isToOrFromScene = isToScene || isFromScene;

    if (openInNewWindow) {
      window.open(to, "_blank");
      return;
    }

    if (isToOrFromScene || ((isToProfile || isFromProfile) && isNotInsideProfileNavigation)) {
      window.location.href = to;
    } else {
      navigate(to);
    }
  };

  return (
    <Link
      href={to}
      className={pathname === to ? `active ${className}` : className}
      onClick={handleNavigate}
      sx={{
        cursor: "pointer",
        textDecoration: "none",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

const isTicketmetaScene = (scene: string) => HOST === "ticketmeta" && ["/test"].includes(scene);
const isDeverseeScene = (scene: string) => HOST === "deversee" && ["/university", "/holders"].includes(scene);
const isScenePath = (scene: string) => /^\/s\/[\w\-]+\/view$/.test(scene);
