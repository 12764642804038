import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";

export class ActivateNode extends BaseNode {
  static readonly type = "physics/activate";
  static readonly label = "Activate";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");

    entity?.getPhysicsBody()?.activate(true);
  }
}
