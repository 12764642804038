import { Chart } from "chart.js";
import { FormattedAnalyticsChartData } from "./types";

export function createChart(chartId: string, data: FormattedAnalyticsChartData) {
  return new Chart(document.getElementById(chartId) as HTMLCanvasElement, {
    type: "bar",
    data: {
      labels: data.labels,
      datasets: data.datasets,
    },
    options: {
      scales: {
        y: {
          stacked: true,
          min: 0,
          max: Math.floor(data.maxUniqueUsers + data.maxUniqueUsers / 10),
        },
        x: { stacked: true },
      },
      plugins: {},
    },
  });
}
