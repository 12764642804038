import { IStructAsset } from "~/types/IStructAsset";
import { NodeSpec, ScriptVariable } from "~/types/ScriptAsset";
import { BaseNode, NodeContext } from "../../base";
import { variableToSocket } from "../../utils";

export abstract class VariableBaseNode extends BaseNode {
  static utility = true;

  protected structsMap: Record<string, IStructAsset>;

  constructor(context: NodeContext) {
    super(context);
    this.structsMap = context.structsMap;
  }

  protected getVariable(nodeSpec: NodeSpec) {
    return (nodeSpec.metadata?.parameter ?? nodeSpec.metadata?.variable) as any as ScriptVariable;
  }

  protected getVariableSocket(nodeSpec: NodeSpec) {
    const variable = this.getVariable(nodeSpec);

    return variableToSocket(variable, "value");
  }
}
