import { memo, MutableRefObject } from "react";
import { PlayerContext } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { BroadcastCommon } from "./BroadcastCommon";
import { BroadcastXR } from "./BroadcastXR";

export type BroadcastPlayerDataProps = {
  contextRef: MutableRefObject<PlayerContext | null>;
};

export const BroadcastPlayerData = memo(({ contextRef }: BroadcastPlayerDataProps) => {
  const mode = useSessionStatus((state) => state.mode);

  return mode === "vr" ? <BroadcastXR contextRef={contextRef} /> : <BroadcastCommon contextRef={contextRef} />;
});
