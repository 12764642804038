import { defaultEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { playerAvatarId } from "~/view-scene/entities/PlayerEntity/constants";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket } from "../../sockets";
import { EntityValue } from "../../values";

export class ScenePlayerAvatarNode extends BaseNode {
  static readonly type: string = "variable/scenePlayerAvatar";
  static readonly label: string = "Player Avatar";
  static readonly category = "Variable";

  outputs = [new EntitySocket("avatar")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = playerAvatarId;
    context.writeOutput<EntityValue>("avatar", node.storage.output);
  }
}
