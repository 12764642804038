import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { EventValue } from "~/types/Variable";
import { BaseBehaveNode } from "../../base";
import { EventSocket, FlowSocket } from "../../sockets";
import { DynamicCustomEventBaseNode } from "./DynamicCustomEventBaseNode";

export abstract class ScriptCustomEventCallNode2 extends DynamicCustomEventBaseNode {
  static type = "event/callScriptEvent2";
  static label = "Call Script Event";

  inputs = [new FlowSocket("flow"), new EventSocket("event")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const values: Record<string, any> = {};
    const event = context.readInput<EventValue>("event");

    if (event.componentId && event.eventId) {
      const script = node.sceneContext.getScriptByComponent(event.componentId);

      for (let i = 2; i < node.inputSockets.length; i++) {
        const socket = node.inputSockets[i];
        values[socket.id] = context.readInput(socket.id);
      }

      script?.events.call(event.eventId, values);
    }
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getEventInputsSockets(nodeSpec)];
  }
}
