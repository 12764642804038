import { Icon, IconProps } from "./Icon";

export type ActionableProps = IconProps;

export const Actionable = (props: ActionableProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 2.333v-1m-2.626 2.04-.707-.706m.707 6-.707.707m6-6 .707-.707M2.334 6h-1m4.333-.333 2.74 8.518 1.926-1.926 2.408 2.408 1.926-1.926-2.408-2.408 1.926-1.926-8.518-2.74Z"
    />
  </Icon>
);
