import {
  AssetValue,
  EntityValue,
  ComponentValue,
  SceneValue,
  UIEntityValue,
  Variable,
  StructValue,
  ArrayValue,
  MapValue,
  EventValue,
} from "~/types/Variable";

export const applyValue = (type: Variable["type"], target: any, source: any) => {
  switch (type) {
    case "entity":
    case "audio":
    case "model":
    case "video":
    case "text":
    case "light":
      return applyEntityValue(target, source);
    case "component":
    case "actionable":
    case "animation":
    case "trajectory":
    case "followTarget":
    case "constraint":
      return applyComponentValue(target, source);
    case "asset":
    case "assetMaterial":
    case "assetModel":
      return applyAssetValue(target, source);
    case "scene":
      return applySceneValue(target, source);
    case "uiEntity":
    case "uiBlock":
    case "uiImage":
    case "uiText":
      return applyUIEntityValue(target, source);
    case "struct":
      return applyStructValue(target, source);
    default:
      return source;
  }
};

export const applyArrayValue = (target: ArrayValue, source: ArrayValue) => {
  target.length = 0;
  target.push(...source);

  return target;
};

export const applyMapValue = (target: MapValue, source: MapValue) => {
  for (const key in source) {
    target[key] = source[key];
  }

  return target;
};

export const applyEntityValue = (target: EntityValue, source: EntityValue) => {
  target.entityId = source.entityId;

  return target;
};

export const applyEventValue = (target: EventValue, source: EventValue) => {
  target.entityId = source.entityId;
  target.scriptId = source.scriptId;
  target.eventId = source.eventId;

  return target;
};

export const applyComponentValue = (target: ComponentValue, source: ComponentValue) => {
  target.entityId = source.entityId;
  target.componentId = source.componentId;

  return target;
};

export const applyAssetValue = (target: AssetValue, source: AssetValue) => {
  target.assetId = source.assetId;
  target.variantId = source.variantId;

  return target;
};

export const applySceneValue = (target: SceneValue, source: SceneValue) => {
  target.sceneId = source.sceneId;

  return target;
};

export const applyUIEntityValue = (target: UIEntityValue, source: UIEntityValue) => {
  target.entityId = source.entityId;

  return target;
};

export const applyStructValue = (target: StructValue, source: StructValue) => {
  Object.keys(source).forEach((key) => {
    target[key] = source[key];
  });

  return target;
};
