import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box, Stack, Tab, Typography } from "@mui/material";
import Tabs from "~/common/components/Tabs";
import AttentionAlert from "~/common/components/AttentionAlert";
import * as authApi from "~/api/auth.api";
import { useProfile } from "~/entities/profile";

import { ProfilePageLayout } from "../components/ProfilePageLayout";
import { Wallets } from "./Wallets";
import { SceneSettings } from "./SceneSettings";
import { ProfileAvatar } from "./ProfileAvatar";
import { PersonalInfo } from "./PersonalInfo";

const state = {
  navItem: [
    {
      label: "Avatar",
      href: "profile-avatar",
    },
    {
      label: "Personal info",
      href: "personal-info",
    },
    {
      label: "Scene settings",
      href: "scene-settings",
    },
    {
      label: "Linked wallets",
      href: "user-wallets",
    },
  ],
};

export const ProfileSettingPage = () => {
  const [value, setValue] = useState("profile-avatar");

  const navigate = useNavigate();

  const profile = useProfile((state) => state.profile);

  const handleResendVerificationEmail = async () => {
    await authApi.resendVerificationEmail();
  };

  if (!profile) {
    navigate("/401");
    return null;
  }

  const { emailConfirmed } = profile;

  return (
    <ProfilePageLayout>
      <Box
        component="div"
        sx={(theme) => ({
          flexGrow: 1,
          scrollBehavior: "smooth",
          display: "grid",
          grid: "auto / 312px 1fr",
          gridGap: "130px",

          alignItems: "flex-start",
          [theme.breakpoints.down("lg")]: {
            gridGap: "40px",
            grid: "auto / 200px 1fr",
          },
          [theme.breakpoints.down("md")]: {
            gridGap: "20px",
            grid: "auto / 1fr",
          },
        })}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          sx={(theme) => ({
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
            position: "sticky",
            top: "100px",
            left: "0",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          })}
        >
          {state.navItem.map((item, index) => {
            return <Tab key={index} value={item.href} href={`#${item.href}`} label={item.label} />;
          })}
        </Tabs>
        <Stack
          direction="column"
          sx={(theme) => ({
            maxWidth: "541px",
            gridGap: "24px",
            width: "100%",
            [theme.breakpoints.down("lg")]: {
              maxWidth: "100%",
            },
          })}
        >
          {!emailConfirmed && (
            <AttentionAlert
              sx={{
                backgroundColor: "rgba(227, 136, 0, 0.1)",
              }}
            >
              <Typography
                color="primary.white"
                fontSize="14px"
                lineHeight="24px"
                fontWeight={500}
                sx={{
                  cursor: "default",
                }}
              >
                Please follow the link in the email that we have sent you to unlock the ability to create new
                experiences.
              </Typography>
              <Typography
                color="primary.violet"
                onClick={handleResendVerificationEmail}
                fontSize="14px"
                lineHeight="24px"
                fontWeight={500}
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
              >
                Resend verification email
              </Typography>
            </AttentionAlert>
          )}
          <ProfileAvatar onScreen={() => setValue("profile-avatar")} />
          <PersonalInfo onScreen={() => setValue("personal-info")} />
          <SceneSettings onScreen={() => setValue("scene-settings")} />
          <Wallets onScreen={() => setValue("user-wallets")} />
        </Stack>
      </Box>
    </ProfilePageLayout>
  );
};
