import { useReconnectingSocket } from "~/view-scene/network";
import { useEffect } from "react";
import { useProfile } from "~/entities/profile";

type UseNetworkSystemOptions = {
  sceneId: string;
};

export const useNetworkSystem = ({ sceneId }: UseNetworkSystemOptions) => {
  const profile = useProfile((state) => state.profile);

  const { isConnected, connect, send, socket, disconnect } = useReconnectingSocket();

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, []);

  const isReady = !!(isConnected && profile);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    send("joinSession", {
      sceneId: sceneId,
      userID: profile.id,
      avatarUrl: profile?.avatarUrl ?? "/static/models/default-avatar.glb",
      mode: "desktop",
    });
  }, [isReady]);

  return {
    socket,
    isConnected,
  };
};
