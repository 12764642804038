import { ReactNode } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Help } from "~/common/components/Icon";

export type TooltipProps = {
  tooltipText: ReactNode;
  helperText?: string;
};

export function Tooltip({ tooltipText, helperText }: TooltipProps) {
  return (
    <Box component="div" sx={{ display: "block" }}>
      <Stack
        direction="row"
        alignItems="center"
        className="tooltip-alert"
        sx={{
          position: "absolute",
          top: "calc(100% + 10px)",
          left: "-25%",
          transform: "translate(-25%,0)",
          gridGap: "8px",
          padding: "0 8px",
          whiteSpace: "nowrap",
          visibility: "hidden",
          opacity: 0,
          zIndex: 10,
          transition: ".4s ease",
          background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #000000",
          border: "1px solid " + palette.primary.gray40,
          maxHeight: "24px",
          minHeight: "24px",
          overflow: "hidden",
          Height: "24px",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontWeight: "400",
            fontSize: "12px",
            letterSpacing: 0,
            lineHeight: "16px",
            color: palette.primary.gray600,
          })}
          variant="body1"
        >
          {tooltipText}
        </Typography>
        {helperText && (
          <Box component="div">
            <Help size="small" color="primary.gray600" />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
