import { Navigate } from "~/common/components";
import { Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { palette } from "~/theme";
import { SceneCard } from "./types";

type CardProps = {
  scene: SceneCard;
  cardsPerRow?: number;
  useMobilePoster?: boolean;
};

export function Card({ scene, cardsPerRow = 2, useMobilePoster = false }: CardProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const leftRightPadding = isSmallScreen ? "16px" : "48px";

  const previewImage = useMobilePoster && isSmallScreen ? scene.posterSmall : scene.posterBig;

  const aspectRatio = useMobilePoster && isSmallScreen ? "1/1" : "16/9";

  const card = (
    <StyledMuiCard
      sx={{
        width: `calc(((100vw - ${leftRightPadding} * 2) - 30px * (${cardsPerRow} - 1)) / ${cardsPerRow} - 2px)`,
        background: `url(${previewImage}) center no-repeat`,
        aspectRatio,
        "&:hover": {
          ...(scene.published && {
            transform: "scale(1.02)",
          }),
        },
      }}
    >
      <CardTagContainer>
        {!scene.published && (
          <NotPublishedTag>
            <CardTagText variant="body1" color="white">
              coming soon
            </CardTagText>
          </NotPublishedTag>
        )}
        <CardTag>
          <CardTagText variant="body1" color="black">
            {scene.tag}
          </CardTagText>
        </CardTag>
      </CardTagContainer>
    </StyledMuiCard>
  );

  return scene.published ? (
    <Navigate to={scene.url} openInNewWindow={true}>
      {card}
    </Navigate>
  ) : (
    <>{card}</>
  );
}

const StyledMuiCard = styled(Stack)`
  position: relative;
  aspect-ratio: 16 / 9;
  border-radius: 20px;
  background-size: cover !important;
  transition: 1s cubic-bezier(0.46, 1.48, 0.18, 0.81);
  border: 1px solid ${palette.primary.gray400};
`;

const CardTagContainer = styled(Stack)`
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 11px;
  right: 11px;
  flex-direction: row;
  gap: 12px;
`;

const CardTag = styled(Stack)`
  align-items: center;
  justify-content: center;
  background-color: #7cff2a;
  border-radius: 14px;
  height: 24px;
  padding: 4px 8px 4px 8px;
  box-sizing: border-box;
`;

const NotPublishedTag = styled(CardTag)`
  background-color: #783ada;
`;

const CardTagText = styled(Typography)`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-style: Medium;
`;
