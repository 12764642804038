import { memo } from "react";
import { useSprite } from "~/view-scene/utils";
import { Layer } from "~/view-scene/layers";

export type ContainerProps = {
  width?: number;
};

export const Container = memo(({ width = 1 }: ContainerProps) => {
  const sprite = useSprite("/static/img/viewer/video360-entity/container.png", { width }, Layer.video360);

  return <primitive object={sprite} />;
});
