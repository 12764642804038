import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class BreakVector3Node extends BaseNode {
  static readonly type = "logic/breakVector3";
  static readonly label = "Break vector";
  static readonly category = "Logic";

  inputs = [new Vector3Socket("vector3")];
  outputs = [new NumberSocket("x"), new NumberSocket("y"), new NumberSocket("z")];

  eval(context: NodeEvalContext) {
    const vector3 = context.readInput<Vector3Value>("vector3");
    context.writeOutput<number>("x", vector3.x);
    context.writeOutput<number>("y", vector3.y);
    context.writeOutput<number>("z", vector3.z);
  }
}
