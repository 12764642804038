import { Stack, StackProps, Typography } from "@mui/material";
import { palette } from "~/theme";
import { HelpHint } from "./HelpHint";
import { ReactNode } from "react";

export type EntityTypeLabelProps = StackProps & {
  label: string;
  children?: ReactNode | ReactNode[];
};

export function InspectorHeader({ label, children, sx, ...rest }: EntityTypeLabelProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        marginTop: "4px",
        padding: "4px 6px 0 10px",
        borderTop: "1px solid " + palette.primary.gray40,
        gridGap: "8px",
        minHeight: "28px",
        ...sx,
      }}
      {...rest}
    >
      <Typography
        sx={(theme) => ({
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          cursor: "default",
          color: palette.primary.white,
        })}
        variant="body1"
      >
        {label}
      </Typography>
      <HelpHint />
      {children}
    </Stack>
  );
}
