import { styled } from "@mui/material";
import { TextField } from "~/hephaestus/components/TextField";
import { palette } from "~/theme";

export const StyledTextField = styled(TextField)`
  background: ${palette.primary.gray20};

  input {
    &::placeholder {
      color: ${palette.primary.gray600};
    }
  }
`;
