import { useTexture } from "@react-three/drei";
import IImageSceneObject from "~/types/IImageSceneObject";
import IImageAsset from "~/types/IImageAsset";

export type RenderImageV1Props = {
  dto: IImageSceneObject;
  asset: IImageAsset;
};

export const RenderImageV1 = ({ dto, asset }: RenderImageV1Props) => {
  const url = asset.url;
  const texture = useTexture(url);

  const { width, height } = dto;

  return (
    <mesh>
      <boxGeometry attach="geometry" args={[width, height, 0.04]} />
      <meshStandardMaterial attach="material-0" color="white" />
      <meshStandardMaterial attach="material-1" color="white" />
      <meshStandardMaterial attach="material-2" color="white" />
      <meshStandardMaterial attach="material-3" color="white" />
      <meshStandardMaterial attach="material-4" map={texture} />
      <meshStandardMaterial attach="material-5" color="white" />
    </mesh>
  );
};
