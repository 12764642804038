import { createEvent, EventPayload, forward, sample, StoreValue } from "effector";
import ISceneObject from "~/types/ISceneObject";
import { $entities, addEntities as storeAddEntities, deleteEntities as storeDeleteEntities } from "~/entities/entities";
import { sendAddEntities, sendDeleteEntities } from "~/api/socket";
import { $three, editorUndoFactory } from "~/hephaestus/models";
import { selectEntity } from "./selectEntity";
import { SceneObjectType } from "~/types/SceneObjectType";
import { openAssetsModal } from "~/hephaestus/builderUIState";
import { updateEntity } from "./updateEntity";
import IModelSceneObject from "~/types/IModelSceneObject";
import IInstancedMeshSceneObject from "~/types/IInstancedMeshSceneObject";
import { entityTypeToAssetType } from "../utils";
import { calcPositionInfront } from "~/common/utils/calcPositionInfront";

export const addEntitiesAndCalculateOrder = createEvent<Omit<ISceneObject, "order">[]>();
export const addEntityAndCalculateOrder = addEntitiesAndCalculateOrder.prepend(
  (entity: Omit<ISceneObject, "order">) => [entity]
);
export const createEntity = createEvent<Omit<ISceneObject, "order">>();

sample({
  source: $three,
  clock: createEntity,
  fn: (three, entity) => {
    setEntityPosition(entity, three);

    return [entity];
  },
  target: addEntitiesAndCalculateOrder,
});

const [addEntities] = editorUndoFactory(
  [storeAddEntities, sendAddEntities],
  [storeDeleteEntities, sendDeleteEntities],
  (entities) => entities.map((entity) => entity.id)
);

sample({
  source: $entities,
  clock: addEntitiesAndCalculateOrder,
  fn: (entities, newEntitiesWithoutOrder) => {
    let maxOrder = entities!.reduce((currentMaxOrder, entity) => Math.max(currentMaxOrder, entity.order), 0);

    return newEntitiesWithoutOrder.map((newEntityWithoutOrder) => {
      maxOrder += 10000;

      return {
        ...newEntityWithoutOrder,
        order: maxOrder,
      };
    });
  },
  target: addEntities,
});

const firstAddEntity = addEntities.filter({ fn: (entities) => entities.length > 0 }).map((entities) => entities[0]);

forward({
  from: firstAddEntity,
  to: [selectEntity.prepend((entity: ISceneObject) => entity.id)],
});

sample({
  clock: firstAddEntity,
  filter: (entity: ISceneObject) =>
    [
      SceneObjectType.GLB,
      SceneObjectType.IMAGE,
      SceneObjectType.AUDIO,
      SceneObjectType.VIDEO,
      SceneObjectType.INSTANCED_MESH,
    ].includes(entity.type) && entity.asset == null,
  target: openAssetsModal.prepend((entity: ISceneObject) => ({
    open: true,
    mode: "assetSelection",
    selectorId: entity.id,
    assetTypes: [entityTypeToAssetType(entity.type)!],
    insertAssetTypes: [entityTypeToAssetType(entity.type)!],
    onSelectionCallback: ({ asset, variantId }) => {
      const updatedEntity: ISceneObject | IModelSceneObject | IInstancedMeshSceneObject = {
        ...entity,
        name: asset?.name ?? entity.name,
        asset: asset?.id,
        variantId: variantId,
      };

      updateEntity({ id: entity.id, entity: updatedEntity });
    },
  })),
});

const setEntityPosition = (
  entity: EventPayload<typeof addEntityAndCalculateOrder>,
  three: StoreValue<typeof $three>
) => {
  if (!three) {
    return;
  }

  const position = calcPositionInfront(three.camera, 4);

  entity.position = { x: position.x, y: position.y, z: position.z };
};
