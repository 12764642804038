import IAsset from "types/IAsset";
import { useStoreMap } from "effector-react";
import { $assets, $assetsMap } from "./models";
import { AssetType } from "~/types/AssetType";

export const useAsset = <T extends IAsset>(assetID: string | null | undefined) => {
  return useStoreMap({
    store: $assetsMap,
    keys: [assetID],
    fn: (assets, [assetID]) => {
      if (!assetID) {
        return null;
      }

      return (assets[assetID] as T) ?? null;
    },
  });
};

export const useAssets = <T extends IAsset>(assetIDs: string[]) => {
  return useStoreMap({
    store: $assetsMap,
    keys: [assetIDs],
    fn: (assets, [assetIDs]) => {
      const result: {
        [key: string]: T;
      } = {};

      assetIDs.forEach((id) => {
        if (assets[id]) {
          result[id] = assets[id] as T;
        }
      });

      return result;
    },
  });
};

export const useAssetsByType = <TAsset extends IAsset>(type: AssetType) => {
  return useStoreMap({
    store: $assets,
    keys: [type],
    fn: (assets, [type]) => {
      return (assets?.filter((asset) => asset.type === type) ?? []) as TAsset[];
    },
  });
};
