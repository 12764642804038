import { useFrame } from "@react-three/fiber";
import { EventEmitter } from "eventemitter3";
import { useEffect, useMemo, useRef } from "react";
import { Group, Object3D } from "three";
import { $cameraState } from "~/view-scene/CameraSystem";
import { collisionGroup, usePhysics } from "~/view-scene/physics";
import { usePlayer } from "~/view-scene/player";
import { EntityContext, useEntityContext, useEntityManager } from "~/view-scene/runtime";
import { playerSocketHandsId } from "./constants";

const emptySet = new Set<string>();

export const useSockets = () => {
  const player = usePlayer();
  const handsSocketPivotRef = useRef<Object3D | null>(null);
  const handsSocketRef = useRef<Object3D | null>(null);
  const handsSocketContextRef = useRef<EntityContext>(null);
  const addRigidBody = usePhysics((state) => state.addRigidBody);
  const { addEntityContext, removeEntityContext } = useEntityManager((state) => ({
    addEntityContext: state.addEntityContext,
    removeEntityContext: state.removeEntityContext,
  }));

  const events = useMemo(() => new EventEmitter(), []);

  useEntityContext(handsSocketContextRef, () => ({
    id: playerSocketHandsId,
    rootObjectRef: handsSocketRef,
    events,
    tags: emptySet,
    components: {},
    physicsBodies: {},
  }));

  useEffect(() => {
    addEntityContext(playerSocketHandsId, handsSocketContextRef);

    return () => {
      removeEntityContext(playerSocketHandsId);
    };
  }, []);

  useEffect(() => {
    const handsSocketPivot = new Group();
    player.add(handsSocketPivot);
    handsSocketPivotRef.current = handsSocketPivot;

    const handsSocket = new Group();
    handsSocket.position.set(0, 0.4, -0.5);
    handsSocketPivot.add(handsSocket);
    handsSocketRef.current = handsSocket;

    const physicsBody = addRigidBody(
      handsSocket,
      { entityId: playerSocketHandsId, rigidBodyId: "root" },
      {
        type: "kinematic",
        collisionMask: collisionGroup.none,
        shape: {
          type: "sphere",
          radius: 0.1,
        },
      }
    );

    if (handsSocketContextRef.current) {
      handsSocketContextRef.current.physicsBodies["root"] = physicsBody!;
    }

    return () => {
      handsSocket.removeFromParent();
      handsSocketPivot.removeFromParent();
    };
  }, [player]);

  useFrame(() => {
    if (!handsSocketPivotRef.current) {
      return;
    }

    const handsSocketPivot = handsSocketPivotRef.current;
    const { theta } = $cameraState.getState();

    handsSocketPivot.rotation.set(0, theta, 0);
  });
};
