import { RefObject, useEffect } from "react";
import { Object3D } from "three";
import { EventPayload } from "effector";
import { useThree } from "@react-three/fiber";
import { useStoreMap } from "effector-react";
import { $flags } from "~/entities/flags";
import { useSceneData } from "~/common/stores/useSceneData";
import { getBoundingBox, getBoundingBoxMatrix } from "../utils";
import { addItems, removeItems } from "../models";
import { IOcclusionCulledEntity } from "../types";
import { useOcclusionCullingEnabled } from "./useOcclusionCullingEnabled";

export const useOcclusionCulling = <TObject extends Object3D>(
  ref: RefObject<TObject | null>,
  entity: IOcclusionCulledEntity
) => {
  const scene = useThree((state) => state.scene);
  const occlusionCullingDebugger = useStoreMap($flags, (flags) => flags.occlusionCullingDebugger);
  const sceneOcclusionCulling = useSceneData((state) => state.sceneState!.occlusionCulling);
  const enabled = useOcclusionCullingEnabled(entity);

  useEffect(() => {
    if (!sceneOcclusionCulling || !enabled) {
      return;
    }

    setTimeout(() => {
      const items: EventPayload<typeof addItems> = [];

      const { matrix, position } = getBoundingBoxMatrix(ref.current!);
      items.push({
        object: ref.current! as any,
        matrix,
        position,
      });

      if (occlusionCullingDebugger) {
        const boundingBox = getBoundingBox(ref.current!);
        scene.add(boundingBox);
      }

      // ref.current?.traverse((object) => {
      //   if (object instanceof Mesh) {
      //     const { matrix, position } = getBoundingBoxMatrix(object);
      //     items.push({
      //       mesh: object,
      //       matrix,
      //       position,
      //     });

      //     if (occlusionCullingDebugger) {
      //       const boundingBox = getBoundingBox(object);
      //       context.scene.add(boundingBox);
      //     }
      //   }
      // });

      addItems(items);
    }, 1000);

    return () => {
      const items: EventPayload<typeof removeItems> = [];

      if (ref.current) {
        items.push({ object: ref.current as any });
      }

      // ref.current?.traverse((object) => {
      //   if (object instanceof Mesh) {
      //     items.push({ mesh: object });
      //   }
      // });

      if (items.length > 0) {
        removeItems(items);
      }
    };
  }, [enabled, sceneOcclusionCulling]);
};
