import type { VideoJsPlayerOptions } from "video.js";

export const initialOptions: VideoJsPlayerOptions = {
  controls: true,
  autoplay: false,
  techOrder: ["html5"],
  preload: "auto",
  controlBar: {
    volumePanel: {
      inline: false,
    },
    fullscreenToggle: false,
    pictureInPictureToggle: false,
  },
};
