import * as React from "react";
import { ReactNode, useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Group, Material, Mesh } from "three";
import { isArray } from "lodash-es";

type VRHudProps = {
  children?: ReactNode;
};

export const VRHud = ({ children }: VRHudProps) => {
  const { camera, gl } = useThree();

  const containerRef = useRef<Group>(null);

  const positionGroupRef = useRef<Group>(null);
  const rotationGroupRef = useRef<Group>(null);

  useEffect(() => {
    gl.sortObjects = true;
  }, [gl]);

  useFrame(() => {
    const container = containerRef.current;
    if (container) {
      container.traverse((obj) => {
        if (obj instanceof Mesh) {
          if (obj.material && !isArray(obj.material)) {
            (obj.material as Material).depthTest = false;
            (obj.material as Material).transparent = true;
          }
        }
      });
    }

    if (positionGroupRef.current) {
      camera.getWorldPosition(positionGroupRef.current.position);
    }

    if (rotationGroupRef.current) {
      camera.getWorldQuaternion(rotationGroupRef.current.quaternion);
    }
  });

  return (
    <group ref={positionGroupRef}>
      <group ref={rotationGroupRef}>
        <group position={[0, 0, -6]} renderOrder={100} ref={containerRef}>
          {children}
        </group>
      </group>
    </group>
  );
};
