import { Mesh, Object3D } from "three";

export const hasMesh = (object: Object3D) => {
  let hasMesh = false;

  object.traverse((el) => {
    if (el instanceof Mesh) {
      hasMesh = true;
    }
  });

  return hasMesh;
};
