import { useEffect, useState } from "react";
import type { NavMesh, NavMeshLoader } from "yuka";
import { useYuka } from "~/view-scene/yukaSystem";

const cache: Record<string, NavMesh> = {};

let loader: NavMeshLoader;

export const useNavMesh = (url: string | null) => {
  const [navMesh, setNavMesh] = useState<NavMesh | null>(null);
  const yuka = useYuka();

  useEffect(() => {
    if (!url) {
      return;
    }

    if (cache[url]) {
      setNavMesh(cache[url]);
      return;
    }

    if (!loader) {
      loader = new yuka.NavMeshLoader();
    }

    loader.load(url).then((navMesh) => {
      cache[url] = navMesh;
      setNavMesh(navMesh);
    });
  }, [url]);

  return navMesh;
};
