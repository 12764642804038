import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";
import { LeftPanel } from "./LeftPanel";
import { ProfileButton } from "~/common/components";
import { loadWorkspacesFx } from "./models";
import { ActiveContent } from "./ActiveContent";
import { useEffect } from "react";

export function Projects() {
  useEffect(() => {
    loadWorkspacesFx();
  }, []);

  return (
    <DashboardContainer>
      <LeftPanel />
      <Container>
        <ActiveContent />
      </Container>
      <Stack position="absolute" right="5px">
        <ProfileButton />
      </Stack>
    </DashboardContainer>
  );
}

const DashboardContainer = styled(Stack)`
  position: fixed;
  flex-direction: row;
  min-height: 100vh;
  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
  max-height: 100vh;
  padding: 8px;
  gap: 24px;
  background-color: ${palette.primary.black};
  overflow: hidden;
`;

const Container = styled(Stack)`
  flex-grow: 1;
  gap: 28px;
  overflow: hidden;
  height: 100%;
  background-color: ${palette.primary.black};
`;
