import { memo, MutableRefObject, ReactNode, useRef } from "react";
import { Text as TMUIText } from "../../libs/three-mesh-ui";
import { Color } from "three";
import { useUIUpdate } from "./useUIUpdate";
import { ThreeEvent } from "@react-three/fiber";

export type TextProps = {
  name?: string;
  content: string;
  fontSize?: number;
  fontColor?: Color;
  fontOpacity?: number;
  fontSuperSampling?: boolean;
  fontFamily?: string;
  fontTexture?: string;
  maxLength?: number;
  children?: ReactNode;
  castShadow?: boolean;
  receiveShadow?: boolean;
  onClick?: () => void;
};

export const Text = memo(({ children, content, maxLength, onClick, ...restProps }: TextProps) => {
  const objectRef = useRef<TMUIText>() as MutableRefObject<TMUIText>;
  useUIUpdate(objectRef, { ...restProps, content: cutText(content, maxLength) });

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <>
      <uiText onClick={handleClick} ref={objectRef}>
        {children}
      </uiText>
    </>
  );
});

const cutText = (text: string, maxlength?: number, filler: string = "...") => {
  if (maxlength !== undefined && text.length > maxlength) {
    return text.substring(0, maxlength) + filler;
  }

  return text;
};
