import { Button, styled } from "@mui/material";
import { palette } from "~/theme";

const SocialLoginButton = styled(Button)`
  color: ${palette.primary.white};
  font-size: 16px;
  letter-spacing: 0;
  border-radius: 0;
  font-weight: 600;
  padding: 8px 12px;
  transition: 0.4s ease;
  text-transform: inherit;

  &:hover {
    background-color: ${palette.primary.gray100} !important;
  }

  & .MuiButton-text {
    color: ${palette.primary.white};
  }
`;

export default SocialLoginButton;
