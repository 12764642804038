import { NodeContext } from "../../base";
import { NumberMathOperationNode } from "./NumberMathOperationNode";

export class NumberSubNode extends NumberMathOperationNode {
  static readonly type = "logic/numberSub";
  static readonly label = "Number -";

  constructor(context: NodeContext) {
    super(context, (a, b) => a - b);
  }
}
