import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode } from "../../base";
import { FlowSocket } from "../../sockets";
import { CustomEventBaseNode } from "./CustomEventBaseNode";
import { getEventId } from "../../utils";

export class CustomEventCallNode extends CustomEventBaseNode {
  static type = "event/call";
  static label = "Call Event";

  static utility = true;

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.eventId = getEventId(node.spec) ?? "";
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const values: Record<string, any> = {};

    // Skip the first input socket, which is the flow socket
    for (let i = 1; i < node.inputSockets.length; i++) {
      const socket = node.inputSockets[i];
      const value = context.readInput(socket.id);

      values[socket.id] = value;
    }

    node.scriptContext.events.call(node.storage.eventId, values);
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getEventInputsSockets(nodeSpec)];
  }

  getLabel(nodeSpec: NodeSpec) {
    const event = this.getEvent(nodeSpec);

    return `Call ${event?.name ?? "Event"}`;
  }
}
