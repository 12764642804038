import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";

export const ManageAccessContainer = styled(Stack)`
  background-color: ${palette.primary.gray40};
  gap: 20px;
  border-radius: 12px;
  border: 1px solid ${palette.primary.gray40};
  padding: 10px;
`;
