import { defaultAsset } from "~/entities/variable";
import { BaseSocket } from "../base";
import { assetModelValue, AssetModelValue } from "../values";

export class AssetModelSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AssetModelValue = defaultAsset()) {
    super(assetModelValue.name, id, label, value);
  }

  clone() {
    return new AssetModelSocket(this.id, this.label, this.value);
  }
}
