import { PermissionCondition } from "./types";

export const requestMicrophone = async (): Promise<PermissionCondition> => {
  try {
    await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: undefined,
      },
    });

    return "granted";
  } catch (err: any) {
    if (err.message.includes("denied")) {
      return "denied";
    }

    return "dismissed";
  }
};
