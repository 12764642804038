import { memo, ReactNode, useEffect, useRef } from "react";
import { Euler, Group, Matrix4, Object3D, Quaternion, Vector3 } from "three";
import { IOcclusionVolumeSceneObject } from "~/types/IOcclusionVolumeSceneObject";
import { EntityContext } from "~/view-scene/runtime";
import { addItems, removeItems } from "~/view-scene/occlusionCulling";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "./Entity";

type OcclusionVolumeEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const OcclusionVolumeEntity = memo(({ entityId, children }: OcclusionVolumeEntityProps) => {
  const entity = useEntity<IOcclusionVolumeSceneObject>(entityId);

  const entityRef = useRef<Group>(null);
  const context = useRef<EntityContext>({} as EntityContext);

  useEffect(() => {
    const shape = entity.shape;
    const position = new Vector3(entity.position.x, entity.position.y, entity.position.z);
    const rotation = new Euler(entity.rotation.x, entity.rotation.y, entity.rotation.z);
    const scale = new Vector3(
      entity.scale.x * shape.width,
      entity.scale.y * shape.height,
      entity.scale.z * shape.depth
    );
    const matrix = new Matrix4().compose(position, new Quaternion().setFromEuler(rotation), scale);
    const object = new Object3D();

    addItems([
      {
        object,
        matrix,
        position,
      },
    ]);

    return () => {
      removeItems([{ object }]);
    };
  }, []);

  return (
    <Entity entityId={entityId} ref={entityRef} context={context}>
      {children}
    </Entity>
  );
});
