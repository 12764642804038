import { capitalize } from "lodash-es";
import { useCallback } from "react";
import { ConstraintOptions, ConstraintType } from "~/types/IConstraintComponent";
import { Select, SelectItem, SelectProps } from "../Select";
import { generateConstraint } from "./utils";

export type ConstraintSelectProps = Pick<SelectProps, "sx" | "className" | "name"> & {
  value: ConstraintType;
  onChange?: (e: { target: { name: string; value: ConstraintOptions } }) => void;
};

const constraintTypes: ConstraintType[] = ["lock", "fixed", "spring", "slider", "hinge", "coneTwist", "pointToPoint"];

export const ConstraintSelect = ({ value, name, onChange, ...rest }: ConstraintSelectProps) => {
  const handleTypeChange: NonNullable<SelectProps<ConstraintType>["onChange"]> = useCallback(
    (e) => {
      const constraint = generateConstraint(e.target.value);

      if (constraint) {
        onChange?.({
          target: {
            name: name ?? "",
            value: constraint,
          },
        });
      }
    },
    [name, onChange]
  );

  return (
    <Select displayEmpty={true} name="type" value={value} onChange={handleTypeChange} {...rest}>
      {constraintTypes.map((type) => (
        <SelectItem key={type} value={type}>
          {typeToName(type)}
        </SelectItem>
      ))}
    </Select>
  );
};

const typeToName = (type: ConstraintType) => {
  switch (type) {
    case "coneTwist":
      return "Cone Twist";
    case "pointToPoint":
      return "Point to Point";
    default:
      return capitalize(type);
  }
};
