import { memo, ReactNode, useContext, useRef, useState } from "react";
import IVideoAsset from "~/types/IVideoAsset";
import { EnabledStatus } from "~/types/EnabledStatus";
import { useEntityContext, VideoContext } from "~/view-scene/runtime";
import { Entity } from "../Entity";
import { RenderVideo, VideoControls } from "./RenderVideo";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import { useFrame } from "@react-three/fiber";
import { INetworkSystemCtx, NetworkSystemCtx } from "~/view-scene/network";
import { IVideoSceneObject } from "~/types/IVideoSceneObject";
import { useAsset } from "~/entities/assets";
import { useEntity } from "~/view-scene/utils/useEntity";

type VideoEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const VideoEntity = memo(({ entityId, children }: VideoEntityProps) => {
  const entity = useEntity<IVideoSceneObject>(entityId);
  const { asset: assetId, enabled, controls } = entity;
  const contextRef = useRef({} as VideoContext);
  const asset = useAsset<IVideoAsset>(assetId);
  const videoControls = useRef<VideoControls>(null);

  const { send } = useContext(NetworkSystemCtx) as INetworkSystemCtx;

  const videoUrl = entity.isStream ? entity.streamUrl : asset?.url;

  const [isPlaying, setIsPlaying] = useState(false);

  const objectRef = useRef(null);

  useEntityContext<VideoContext>(contextRef, () => ({
    type: "video" as const,
    play: (startedTime?: Date) => videoControls.current?.play(startedTime),
    pause: () => videoControls.current?.pause(),
    stop: () => videoControls.current?.stop(),
    isPlaying: () => videoControls.current?.isPlaying() ?? false,
  }));

  useFrame(() => {
    if (videoControls.current) {
      setIsPlaying(videoControls.current.isPlaying());
    }
  });

  const toggleVideo = () => {
    const vC = videoControls.current;
    if (vC) {
      const isPlaying = vC.isPlaying();
      const supportsRemoteControls = entity.controls && entity.isSynced;
      if (isPlaying) {
        vC.pause();
        if (supportsRemoteControls) {
          send("updateVideoEntity", { entityId: entity.id, status: "pause" });
        }
      } else {
        vC.play();
        if (supportsRemoteControls) {
          send("updateVideoEntity", { entityId: entity.id, status: "play" });
        }
      }
    }
  };

  if (enabled !== EnabledStatus.enabled || !videoUrl) {
    return null;
  }

  return (
    <>
      {controls && (
        <ActionIcon
          icon={isPlaying ? "/static/img/viewer/video-entity/pause.png" : "/static/img/viewer/video-entity/play.png"}
          visibleDistance={20}
          onActionButtonReleased={toggleVideo}
          objectRef={objectRef}
        />
      )}
      <Entity entityId={entityId} context={contextRef} ref={objectRef}>
        <RenderVideo dto={entity} videoUrl={videoUrl} assetName={asset?.name ?? "Stream"} controls={videoControls} />
        {children}
      </Entity>
    </>
  );
});
