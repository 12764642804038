import { ValuesType } from "utility-types";
import { KeyboardKeyCombinationUnion } from "~/hotkeys";
import { getKeyboardType, normalizeKeyCombinations } from "~/hotkeys/keyboard/utils";

export const actionsList = [
  "undo",
  "redo",
  "transformTranslate",
  "transformRotate",
  "transformScale",
  "focus",
  "duplicate",
  "remove",
  "togglePanels",
  "cancelSelection",
  "copyCursorCoordinates",
] as const;

export type EditorAction = ValuesType<typeof actionsList>;

const editorKeyMapSource: Record<EditorAction, KeyboardKeyCombinationUnion> = {
  transformTranslate: "m",
  transformRotate: "r",
  transformScale: "c",
  focus: "f",
  cancelSelection: "esc",
  undo: [
    { type: "mac", combination: "command+z" },
    { type: "other", combination: "control+z" },
  ],
  redo: [
    { type: "mac", combination: "command+shift+z" },
    { type: "other", combination: "control+shift+z" },
  ],
  duplicate: [
    { type: "mac", combination: "command+d" },
    { type: "other", combination: "control+d" },
  ],
  remove: ["backspace", "delete"],
  togglePanels: [
    { type: "mac", combination: "command+\\" },
    { type: "other", combination: "control+\\" },
  ],
  copyCursorCoordinates: [
    { type: "mac", combination: "command+shift+c" },
    { type: "other", combination: "control+shift+c" },
  ],
};

export const editorKeyMap = normalizeKeyCombinations(editorKeyMapSource, getKeyboardType());
