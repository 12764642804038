const regexp = /({{[\w\d:]+}})/g;
const expressionRegexp = /{{([\w\d:]+)}}/;

export type TextItem = {
  type: "text";
  value: string;
};

export type IconItem = {
  type: "icon";
  value: string;
};

export type Item = TextItem | IconItem;

export const parseText = (text: string): Item[] => {
  const parts = text.split(regexp);

  return parts
    .filter((x) => Boolean(x))
    .map((item) => {
      const match = item.match(expressionRegexp);

      if (match) {
        const [type, value] = match[1].split(":");

        return {
          type: type as Item["type"],
          value,
        };
      }

      return {
        type: "text",
        value: item,
      };
    });
};
