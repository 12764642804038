import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, SocketsArray } from "../../base";
import { BooleanSocket } from "../../sockets";

const labels = ["c", "d", "e", "f", "g", "h", "i", "j"];

export class BooleanOrNode extends BaseNode {
  static readonly type = "logic/booleanOr";
  static readonly label = "Boolean OR";
  static readonly category: NodeCategory = "Logic";

  inputs = [
    new BooleanSocket("a"),
    new BooleanSocket("b"),
    new SocketsArray({
      id: "bools",
      SocketConstructor: BooleanSocket,
      maxCount: labels.length,
      nameGenerator: (_id, index) => labels[index],
    }),
  ];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    let res = false;

    for (const socket of node.inputSockets) {
      res = res || context.readInput<boolean>(socket.id);
    }

    context.writeOutput<boolean>("result", res);
  }
}
