import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Container, Navigate } from "~/common/components";

export type SpaceCreatorsProps = {
  creators: Creator[];
};

type Creator = {
  name: string;
  role: string;
  contactText: string;
  contactLink: string;
};

export const SpaceCreators = ({ creators }: SpaceCreatorsProps) => {
  return (
    <Box
      component="div"
      sx={{
        paddingTop: "32px",
      }}
    >
      <Container>
        <Typography
          sx={(theme) => ({
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            width: "100%",
            color: palette.primary.white,
          })}
          variant="body1"
        >
          Creators
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={(theme) => ({
            grid: "auto / repeat(6,1fr)",
            display: "grid",
            gridGap: "32px",
            marginTop: "16px",
            borderBottom: "1px solid " + palette.primary.gray200,
            paddingBottom: "32px",
            [theme.breakpoints.down("xl")]: {
              grid: "auto / repeat(5,1fr)",
            },
            [theme.breakpoints.down("lg")]: {
              grid: "auto / repeat(4,1fr)",
            },
            [theme.breakpoints.down("md")]: {
              grid: "auto / repeat(3,1fr)",
            },
            [theme.breakpoints.down("sm")]: {
              paddingBottom: "24px",
              borderBottom: "none",
              gridGap: "25px",
              grid: "auto / repeat(1,1fr)",
            },
          })}
        >
          {creators.map((creator, index) => {
            return (
              <Box
                component="div"
                key={index}
                sx={(theme) => ({
                  position: "relative",
                  paddingRight: "10px",

                  "&::last-child": {
                    display: "none",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    backgroundColor: palette.primary.gray200,
                    width: "1px",
                    height: "40px",
                    transform: "translate(0,-50%)",
                    display: "block",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                      top: "auto",
                      bottom: "-12px",
                      height: "1px",
                    },
                  },
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "100%",
                    color: palette.primary.white,
                  })}
                  variant="body1"
                >
                  {creator.name}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "20px",
                    width: "100%",
                    color: palette.primary.white,
                  })}
                  variant="body1"
                >
                  {creator.role}
                </Typography>
                <Navigate
                  to={creator.contactLink}
                  openInNewWindow={true}
                  sx={(theme) => ({
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "20px",
                    width: "100%",
                    color: palette.primary.gray600,
                    transition: ".4s ease",
                    "&:hover": {
                      color: palette.primary.white,
                      textDecoration: "none",
                    },
                  })}
                  variant="body1"
                >
                  {creator.contactText}
                </Navigate>
              </Box>
            );
          })}
        </Stack>
      </Container>
    </Box>
  );
};
