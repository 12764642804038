import { createEffect, createEvent, createStore } from "effector";
import { getBuilderOnlineUsers, OnlineUser } from "~/api/builderOnlineUsersApi";

export const loadBuilderOnlineUsersFx = createEffect(async (sceneId: string) => {
  const { status, body: builderOnlineUsers } = await getBuilderOnlineUsers(sceneId);

  if (status !== 200) {
    throw new Error("Not found");
  }

  return builderOnlineUsers;
});

export const $builderOnlineUsers = createStore<OnlineUser[] | null>(null).on(loadBuilderOnlineUsersFx.doneData, (_, payload) => payload);

export const addOnlineUser = createEvent<OnlineUser>();
export const deleteOnlineUser = createEvent<string>();

$builderOnlineUsers
  .on(addOnlineUser, (state, onlineUser) => {
    if (!state) {
      return state;
    }

    return [...state, onlineUser];
  })
  .on(deleteOnlineUser, (state, onlineUserSocketId) => {
    if (!state) {
      return state;
    }

    return state.filter((onlineUser) => onlineUser.socketId !== onlineUserSocketId);
  });
