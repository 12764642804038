import { defaultNumber } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { ImageUIEntity } from "~/types/ui";
import { UIImageContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket, UIImageSocket } from "../../sockets";
import { UIImageValue } from "../../values";

export class UIImageSizeNode extends BaseNode {
  static readonly type = "action/uiImageSize";
  static readonly label = "UI Image Get Size";

  inputs = [new UIImageSocket("uiImage")];
  outputs = [new NumberSocket("width"), new NumberSocket("height")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const imageValue = context.readInput<UIImageValue>("uiImage");
    const entity = node.sceneContext
      .getUIEntityContext<UIImageContext>(imageValue.entityId ?? "")
      ?.getEntity<ImageUIEntity>();

    context.writeOutput<number>("width", entity?.width ?? defaultNumber());
    context.writeOutput<number>("height", entity?.height ?? defaultNumber());
  }
}
