import { NodeEvalContext } from "~/libs/behave-graph";
import { VideoContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, VideoSocket } from "../../sockets";

export class VideoStopNode extends BaseNode {
  static readonly type = "action/videoStop";
  static readonly label = "Stop video";

  inputs = [new FlowSocket("flow"), new VideoSocket("video")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readEntity<VideoContext>(context, "video")?.stop();
  }
}
