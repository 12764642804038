import { FC } from "react";
import { Folder, IconProps, ImageEntity, RewardedVideoSystem, Text, Video } from "~/common/components/Icon";
import { PartialRecord } from "~/common/typeUtils";
import { UIEntityType } from "~/types/ui";

export type UIEntityIconProps = IconProps & {
  type: UIEntityType;
};

export const UIEntityIcon = ({ type, ...rest }: UIEntityIconProps) => {
  const Component = componentsMap[type] ?? Folder;

  return <Component {...rest} />;
};

const componentsMap: PartialRecord<UIEntityType, FC<IconProps>> = {
  block: Folder,
  text: Text,
  image: ImageEntity,
  video: Video,
  rewardedVideoButton: RewardedVideoSystem,
};
