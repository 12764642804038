import { XRController } from "@react-three/xr";

export default function getControllerJoystickState(controller: XRController | null) {
  if (!controller || !controller.inputSource || !controller.inputSource.gamepad) {
    return { side: 0, forward: 0 };
  }

  const gamepad = controller.inputSource.gamepad;
  let side, forward;
  if (controller.inputSource.profiles.includes("htc-vive")) {
    side = gamepad.axes[0];
    forward = -gamepad.axes[1];
  } else {
    side = gamepad.axes[2];
    forward = -gamepad.axes[3];
  }

  return { side, forward };
}
