import { TextureMap } from "~/types/texture";
import IImageAsset from "~/types/IImageAsset";
import IVideoAsset from "~/types/IVideoAsset";
import { useAsset } from "~/entities/assets";
import { useMemo } from "react";
import { TextureRequest, VideoTextureRequest } from "./types";
import { hasType } from "~/common/utils/hasType";
import IAsset from "~/types/IAsset";
import { AssetType } from "~/types/AssetType";
import { useTexture } from "./useTexture";

export function useTextureMap(textureMap: TextureMap) {
  const asset = useAsset<IVideoAsset | IImageAsset>(textureMap.asset);

  const { enabled, tiling, flipY, encoding } = textureMap;

  const textureRequest = useMemo(() => {
    if (!enabled || !asset) {
      return null;
    }

    if (hasType<IAsset, IImageAsset, AssetType>(asset, AssetType.image)) {
      const textureRequest: TextureRequest = {
        name: asset.name,
        url: asset.url,
        type: asset.isCompressed ? "compressedImage" : "image",
        tiling,
        encoding: encoding ?? "srgb",
        flipY,
      };
      return textureRequest;
    } else if (hasType<IAsset, IVideoAsset, AssetType>(asset, AssetType.video)) {
      const textureRequest: VideoTextureRequest = {
        name: asset.name,
        url: asset.url,
        type: "video",
        loop: true,
        autoplay: true,
        tiling,
        encoding: encoding ?? "srgb",
        flipY,
      };
      return textureRequest;
    }

    return null;
  }, [enabled, asset, tiling, flipY, encoding]);

  return useTexture(textureRequest);
}
