import { BlockUIEntity } from "~/types/ui";
import { Block } from "./Block";
import { Color } from "three";
import { memo, ReactNode } from "react";
import { useWidthHeight } from "~/view-scene/ui/RenderUIElement/useWidthHeight";

export type RenderUIBlockProps = {
  entity: BlockUIEntity;
  aspect: [number, number, number];
  children?: ReactNode;
  onClick?: (entityId: string) => void;
  showBoxHelper?: boolean;
};

export const RenderUIBlock = memo(({ entity, aspect, children, onClick, showBoxHelper }: RenderUIBlockProps) => {
  const {
    id,
    name,
    enabled,
    width,
    height,
    parentId,
    sizeUnits,
    relativeToParent,
    backgroundColor,
    contentDirection,
    padding,
    margin,
    borderRadius,
    borderWidth,
    borderColor,
    borderOpacity,
    justifyContent,
    alignItems,
    textAlign,
    backgroundOpacity,
  } = entity;

  const { elementWidth, elementHeight } = useWidthHeight(
    id,
    parentId,
    width,
    height,
    aspect,
    sizeUnits,
    relativeToParent
  );

  if (!enabled) {
    return null;
  }

  return (
    <Block
      name={name}
      onClick={onClick ? () => onClick(id) : undefined}
      width={elementWidth}
      height={elementHeight}
      backgroundColor={backgroundColor ? new Color(backgroundColor) : undefined}
      contentDirection={contentDirection}
      padding={padding}
      margin={margin}
      borderRadius={borderRadius}
      borderOpacity={borderOpacity}
      borderColor={borderColor ? new Color(borderColor) : undefined}
      borderWidth={borderWidth}
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={textAlign}
      backgroundOpacity={backgroundOpacity}
      hiddenOverflow={false}
      showBoxHelper={showBoxHelper}
    >
      {children}
    </Block>
  );
});
