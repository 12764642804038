import { ValueType } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { AssetValue } from "./assetValue";

export type AssetModelValue = AssetValue;

export const assetModelValue = new ValueType(
  "assetModel",
  defaultAsset,
  (json: AssetModelValue) => json,
  (value: AssetModelValue) => value
);
