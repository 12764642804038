import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, StringSocket } from "../../sockets";

export class StringLeftPadNode extends BaseNode {
  static readonly type = "logic/stringLeftPad";
  static readonly label = "String Left Pad";

  inputs = [new StringSocket("string"), new StringSocket("filler"), new NumberSocket("length")];
  outputs = [new StringSocket("result")];

  eval(context: NodeEvalContext) {
    const str = context.readInput<string>("string");
    const filler = context.readInput<string>("filler");
    const length = context.readInput<number>("length");

    context.writeOutput<string>("result", str.padStart(length, filler));
  }
}
