import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";
import { Vector3 } from "three";

const tmpA = new Vector3();
const tmpB = new Vector3();
const tmpValue = new Vector3();

export class VectorInverseLerpNode extends BaseNode {
  static readonly type = "logic/vectorInverseLerpScalar";
  static readonly label = "Vector Inverse Lerp";

  inputs = [new Vector3Socket("value"), new Vector3Socket("a"), new Vector3Socket("b")];
  outputs = [new NumberSocket("alpha")];

  eval(context: NodeEvalContext) {
    const value = context.readInput<Vector3Value>("value");
    const a = context.readInput<Vector3Value>("a");
    const b = context.readInput<Vector3Value>("b");

    tmpA.set(a.x, a.y, a.z);
    tmpB.set(b.x, b.y, b.z);
    tmpValue.set(value.x, value.y, value.z);

    // Vector3 AB = b - a;
    tmpB.sub(tmpA);

    // Vector3 AV = value - a;
    tmpValue.sub(tmpA);

    // Vector3.Dot(AV, AB) / Vector3.Dot(AB, AB);
    const alpha = tmpValue.dot(tmpB) / tmpB.dot(tmpB);

    context.writeOutput<number>("alpha", alpha);
  }
}
