import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class MetronomeNode extends BaseNode {
  static readonly type = "time/metronome";
  static readonly label = "Metronome";

  inputs = [new FlowSocket("start"), new FlowSocket("stop")];
  outputs = [new FlowSocket("tick"), new NumberSocket("time", "delta time (ms)")];

  async = true;

  initNode(node: BaseBehaveNode) {
    node.tickable = node.outputConnected("tick");
    node.storage.tickListener = null;
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    if (context.readInput<boolean>("start")) {
      if (node.storage.tickListener) {
        node.scriptContext.scriptEvents.tickEvent.removeListener(node.storage.tickListener);
      }

      const graphEvaluator = context.graphEvaluator;

      node.storage.tickListener = (delta: number) => {
        context.writeOutput("time", delta);
        context.commit("tick");
        graphEvaluator.executeAll();
      };

      node.scriptContext.scriptEvents.tickEvent.addListener(node.storage.tickListener);
      return;
    }

    if (context.readInput<boolean>("stop")) {
      if (node.storage.tickListener) {
        node.scriptContext.scriptEvents.tickEvent.removeListener(node.storage.tickListener);
        node.storage.tickListener = null;
      }

      return;
    }
  }
}
