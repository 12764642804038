import { IStructAsset } from "~/types/IStructAsset";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseNode, NodeContext } from "../../base";
import { getSockets } from "./utils";

export abstract class StructBaseNode extends BaseNode {
  static utility = true;

  protected structsMap: Record<string, IStructAsset>;

  constructor(context: NodeContext) {
    super(context);
    this.structsMap = context.structsMap;
  }

  initNode(node: BaseBehaveNode) {
    node.storage.struct = this.getStruct(node.spec);
  }

  /*
    TODO: this is temporary solution, we have to find the structId from input / output sockets
    not from nodeSpec meta and select
  */
  protected getStructId(nodeSpec: NodeSpec) {
    return nodeSpec.metadata?.structId ?? null;
  }

  protected getStruct(nodeSpec: NodeSpec) {
    const structId = this.getStructId(nodeSpec);

    return this.structsMap[structId ?? ""] ?? null;
  }

  protected getStructSockets(nodeSpec: NodeSpec) {
    const struct = this.getStruct(nodeSpec);

    if (!struct) {
      return [];
    }

    return struct ? getSockets(struct) : [];
  }
}
