import { Component, ReactNode } from "react";

export class ErrorBoundary extends Component<{ message?: string; children: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    if (this.state.hasError) {
      if (this.props.message) {
        console.error(this.props.message);
      }
      // render nothing if error
      return null;
    }

    return this.props.children;
  }
}
