import { createEvent } from "effector";
import IAsset from "~/types/IAsset";
import { $assets } from "./assets";

export const addAsset = createEvent<IAsset>();

$assets.on(addAsset, (state, asset) => {
  if (!state) {
    return state;
  }

  return [...state, asset];
});
