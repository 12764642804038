import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { FlowSocket } from "../../sockets";
import { MethodBaseNode } from "./MethodBaseNode";

export class MethodOutNode extends MethodBaseNode {
  static type = "method/out";
  static label = "Function Out";

  async = true;

  inputs = [new FlowSocket("flow")];
  outputs = [];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    //TODO
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getMethodInputSockets(nodeSpec)];
  }
}
