import * as React from "react";
import { Select as MuiSelect, SelectProps, styled } from "@mui/material";
import { palette } from "~/theme";

const Select = styled((props: SelectProps) => <MuiSelect {...props} />)(({}) => ({
  borderRadius: 32,
  width: "100%",
  maxWidth: "100%",
  minWidth: "100%",
  color: palette.primary.white,
  display: "flex",
  textTransform: "inherit",
  padding: "4px 0 4px 0",
  fontWeight: 400,
  transition: ".4s ease",
  "& svg path": {
    fill: palette.primary.white,
  },
  "&:hover svg path": {
    fill: palette.primary.violet,
    transition: ".4s ease",
  },
  "svg path": {
    transition: ".4s ease",
  },
  "&:hover": {
    color: palette.primary.violet,
    borderColor: palette.primary.violet,
    backgroundColor: palette.primary.violet200 + " !important",
  },
}));

export default Select;
