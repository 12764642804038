import { ReactNode, useMemo } from "react";
import { diContext } from "./diContext";
import { toSceneEntity } from "./entities/toSceneEntity";
import { toEntityComponent } from "./components/toEntityComponent";

type DIProviderProps = {
  children: ReactNode;
};

export const DIProvider = ({ children }: DIProviderProps) => {
  const value = useMemo(() => ({ toSceneEntity, toEntityComponent }), []);

  return <diContext.Provider value={value}>{children}</diContext.Provider>;
};
