import { hasType } from "common/utils/hasType";
import IAudioSceneObject from "~/types/IAudioSceneObject";
import { IBillboardSceneObject } from "~/types/IBillboardSceneObject";
import { IBlockingVolumeSceneObject } from "~/types/IBlockingVolumeSceneObject";
import ICameraSceneObject from "~/types/ICameraSceneObject";
import ICloudSceneObject from "~/types/ICloudSceneObject";
import IImageSceneObject from "~/types/IImageSceneObject";
import IInstancedMeshSceneObject from "~/types/IInstancedMeshSceneObject";
import { IKillVolumeSceneObject } from "~/types/IKillVolumeSceneObject";
import ILightSceneObject from "~/types/ILightSceneObject";
import IMirrorSceneObject from "~/types/IMirrorSceneObject";
import IModelSceneObject from "~/types/IModelSceneObject";
import INPCSceneObject from "~/types/INPCSceneObject";
import { IOcclusionVolumeSceneObject } from "~/types/IOcclusionVolumeSceneObject";
import IPlatformSceneObject from "~/types/IPlatformSceneObject";
import IPrimitiveSceneObject from "~/types/IPrimitiveSceneObject";
import ISceneObject from "~/types/ISceneObject";
import IScreenShareSceneObject from "~/types/IScreenShareSceneObject";
import ISkySceneObject from "~/types/ISkySceneObject";
import { ISpawnPointSceneObject } from "~/types/ISpawnPointSceneObject";
import IStarsSceneObject from "~/types/IStarsSceneObject";
import ITeleportSceneObject from "~/types/ITeleportSceneObject";
import ITextSceneObject from "~/types/ITextSceneObject";
import { ITriggerVolumeSceneObject } from "~/types/ITriggerVolumeSceneObject";
import ITriggerZoneSceneObject from "~/types/ITriggerZoneSceneObject";
import ITwitterBoardSceneObject from "~/types/ITwitterBoardSceneObject";
import { IUISceneObject } from "~/types/IUISceneObject";
import { IVideo360SceneObject } from "~/types/IVideo360SceneObject";
import { IVideoSceneObject } from "~/types/IVideoSceneObject";
import IWaterSceneObject from "~/types/IWaterSceneObject";
import NavMeshSceneObject from "~/types/NavMeshSceneObject";
import { SceneObjectType } from "~/types/SceneObjectType";
import { SnowSceneObject } from "~/types/SnowSceneObject";
import { SpriteSceneObject } from "~/types/sceneObject";
import { AudioEntity } from "./AudioEntity";
import { BillboardEntity } from "./BillboardEntity";
import { BlockingVolumeEntity } from "./BlockingVolumeEntity";
import { CameraEntity } from "./CameraEntity";
import CloudEntity from "./CloudEntity";
import { Entity } from "./Entity";
import { ImageEntity } from "./ImageEntity";
import { InstancedModelEntity } from "./InstancedModelEntity";
import { KillVolumeEntity } from "./KillVolumeEntity";
import { LightEntity } from "./LightEntity";
import { MirrorEntity } from "./MirrorEntity";
import { ModelEntity } from "./ModelEntity";
import { NPCEntityLazy } from "./NPCEntity";
import { NavMeshEntity } from "./NavMeshEntity";
import { OcclusionVolumeEntity } from "./OcclusionVolumeEntity";
import { PlatformEntity } from "./PlatformEntity";
import { PlayerEntity } from "./PlayerEntity";
import { PrimitiveEntity } from "./PrimitiveEntity";
import { ScreenShareEntity } from "./ScreenShareEntity";
import { SkyEntity } from "./SkyEntity";
import { SnowEntity } from "./SnowEntity";
import { SpawnPointEntity } from "./SpawnPointEntity";
import { SpriteEntity } from "./SpriteEntity";
import { StarsEntity } from "./StarsEntity";
import { TeleportEntity } from "./TeleportEntity";
import { TextEntity } from "./TextEntity";
import { TriggerVolumeEntity } from "./TriggerVolumeEntity";
import TriggerZoneEntity from "./TriggerZoneEntity";
import { TwitterBoardEntity } from "./TwitterBoard";
import { UIEntity } from "./UIEntity";
import { Video360Entity } from "./Video360Entity";
import { VideoEntity } from "./VideoEntity";
import { WaterEntity } from "./WaterEntity";

export function toSceneEntity(dto: ISceneObject) {
  const entityId = dto.id;

  if (hasType<ISceneObject, IModelSceneObject, SceneObjectType>(dto, SceneObjectType.GLB)) {
    return <ModelEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ITeleportSceneObject, SceneObjectType>(dto, SceneObjectType.TELEPORT)) {
    return <TeleportEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IWaterSceneObject, SceneObjectType>(dto, SceneObjectType.WATER)) {
    return <WaterEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ICloudSceneObject, SceneObjectType>(dto, SceneObjectType.CLOUD)) {
    return <CloudEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IAudioSceneObject, SceneObjectType>(dto, SceneObjectType.AUDIO)) {
    return <AudioEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IVideoSceneObject, SceneObjectType>(dto, SceneObjectType.VIDEO)) {
    return <VideoEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IVideo360SceneObject, SceneObjectType>(dto, SceneObjectType.VIDEO_360)) {
    return <Video360Entity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ITextSceneObject, SceneObjectType>(dto, SceneObjectType.TEXT)) {
    return <TextEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IStarsSceneObject, SceneObjectType>(dto, SceneObjectType.STARS)) {
    return <StarsEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ISkySceneObject, SceneObjectType>(dto, SceneObjectType.SKY)) {
    return <SkyEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ITriggerZoneSceneObject, SceneObjectType>(dto, SceneObjectType.TRIGGER_ZONE)) {
    return <TriggerZoneEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ITriggerVolumeSceneObject, SceneObjectType>(dto, SceneObjectType.TRIGGER_VOLUME)) {
    return <TriggerVolumeEntity key={entityId} entityId={entityId} />;
  } else if (
    hasType<ISceneObject, IOcclusionVolumeSceneObject, SceneObjectType>(dto, SceneObjectType.OCCLUSION_VOLUME)
  ) {
    return <OcclusionVolumeEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IBlockingVolumeSceneObject, SceneObjectType>(dto, SceneObjectType.BLOCKING_VOLUME)) {
    return <BlockingVolumeEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IKillVolumeSceneObject, SceneObjectType>(dto, SceneObjectType.KILL_VOLUME)) {
    return <KillVolumeEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IScreenShareSceneObject, SceneObjectType>(dto, SceneObjectType.SCREEN_SHARE)) {
    return <ScreenShareEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IImageSceneObject, SceneObjectType>(dto, SceneObjectType.IMAGE)) {
    return <ImageEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IMirrorSceneObject, SceneObjectType>(dto, SceneObjectType.MIRROR)) {
    return <MirrorEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ITwitterBoardSceneObject, SceneObjectType>(dto, SceneObjectType.TWITTER_BOARD)) {
    return <TwitterBoardEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IInstancedMeshSceneObject, SceneObjectType>(dto, SceneObjectType.INSTANCED_MESH)) {
    return <InstancedModelEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, INPCSceneObject, SceneObjectType>(dto, SceneObjectType.NPC)) {
    return <NPCEntityLazy key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ILightSceneObject, SceneObjectType>(dto, SceneObjectType.LIGHT)) {
    return <LightEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IPrimitiveSceneObject, SceneObjectType>(dto, SceneObjectType.PRIMITIVE)) {
    return <PrimitiveEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IBillboardSceneObject, SceneObjectType>(dto, SceneObjectType.BILLBOARD)) {
    return <BillboardEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ISpawnPointSceneObject, SceneObjectType>(dto, SceneObjectType.SPAWN_POINT)) {
    return <SpawnPointEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, SnowSceneObject, SceneObjectType>(dto, SceneObjectType.SNOW)) {
    return <SnowEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IUISceneObject, SceneObjectType>(dto, SceneObjectType.UI)) {
    return <UIEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, IPlatformSceneObject, SceneObjectType>(dto, SceneObjectType.PLATFORM)) {
    return <PlatformEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, NavMeshSceneObject, SceneObjectType>(dto, SceneObjectType.NAV_MESH)) {
    return <NavMeshEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ISceneObject, SceneObjectType>(dto, SceneObjectType.PLAYER)) {
    return <PlayerEntity key={entityId} />;
  } else if (hasType<ISceneObject, SpriteSceneObject, SceneObjectType>(dto, SceneObjectType.SPRITE)) {
    return <SpriteEntity key={entityId} entityId={entityId} />;
  } else if (hasType<ISceneObject, ICameraSceneObject, SceneObjectType>(dto, SceneObjectType.CAMERA)) {
    return <CameraEntity key={entityId} entityId={entityId} />;
  } else {
    return <Entity key={entityId} entityId={entityId} />;
  }
}
