import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => {
  return { ...contentTypeHeader, ...getAuthHeader() };
};

export const getUsers = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/users`, requestOptions);
};

export const getOnlineUsersCount = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/users/online/count`, requestOptions);
};

export const getUser = async (email) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/users/${email}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const createUser = async (user) => {
  const body = JSON.stringify(user);
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };
  return await fetch(`${API_HOST}/admin/users`, requestOptions);
};

export const confirmUserEmail = async (userID) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/admin/users/${userID}/confirm-email`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const updateUser = async (user) => {
  const userId = user._id;
  const body = JSON.stringify(user);
  const requestOptions = {
    method: "PATCH",
    headers: getHeaders(),
    body,
  };
  return await fetch(`${API_HOST}/admin/users/${userId}`, requestOptions);
};

export const deleteUser = async (userId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/users/${userId}`, requestOptions);
};
