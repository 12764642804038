import { upperFirst } from "lodash-es";
import { PartialRecord } from "../typeUtils";

export type GameCategory = "telegram" | "facebook" | "vr_art" | "desktop" | "drafts" | "web3" | "gameverse";

export type GameCategories =
  | "trending"
  | "puzzle"
  | "Cards"
  | "Web3"
  | "Board"
  | "Inline"
  | "Action"
  | "P2P"
  | "towerDefence";

export type GameSize = "s" | "m" | "l";

export type Game = {
  id: string;
  name: string;
  categories: GameCategory[];
  url: string;
  posterBig: string;
  posterSmall: string;
  tag?: string;
  size?: GameSize;
  played?: number;
  featured?: boolean;
  gameCategories?: GameCategories[];
  description?: string;
  online?: number;
};

const gamesConfig: Game[] = [
  {
    id: "royalFortress",
    name: "Royal Fortress",
    categories: ["telegram"],
    url: "https://t.me/RoyalFortressBot/RFortress?startapp=gam8s_catalog",
    posterBig: "/static/img/content/posters/royal_fortress_640_360.webp",
    posterSmall: "/static/img/content/posters/royal_fortress_360.webp",
    size: "l",
    featured: true,
    description: "Build your tower",
    tag: "game / telegram",
    gameCategories: ["Web3", "trending", "towerDefence"],
  },
  {
    id: "endlessSiege",
    name: "Endless Siege",
    categories: ["telegram"],
    url: "https://t.me/EndlessSiegeGameBot",
    posterBig: "/static/img/content/posters/endless-siege-1-640-360.webp",
    posterSmall: "/static/img/content/posters/endless-siege-1-360-360.webp",
    size: "l",
    tag: "game / telegram",
    featured: true,
    description: "Epic tower defence battle with your friends!",
    gameCategories: ["Web3", "trending", "Action", "towerDefence"],
  },
  {
    id: "gatto",
    name: "Gatto",
    categories: ["telegram"],
    url: "https://t.me/gatto_gamebot/Gatto?startapp=entrypoint=gam8s",
    posterBig: "/static/img/content/posters/gatto_poster_big.webp",
    posterSmall: "/static/img/content/posters/gatto_poster_small.webp",
    size: "l",
    featured: true,
    description: "Breed your unique pet",
    tag: "game / telegram",
    gameCategories: ["Web3", "trending"],
  },
  {
    id: "edchess",
    name: "Edchess",
    categories: ["telegram"],
    url: "https://t.me/edchess_bot?start=pkeRlWqaghLS",
    posterBig: "/static/img/content/posters/edchess_poster_big.webp",
    posterSmall: "/static/img/content/posters/edchess_poster_small.webp",
    description: "Chess with reward",
    gameCategories: ["Board", "Web3", "P2P"],
    size: "l",
    tag: "game / telegram",
  },
  {
    id: "tapFantasy",
    name: "Tap Fantasy",
    categories: ["telegram"],
    url: "https://t.me/TapFantasyGameBot?start=eyJ1c2VySWQiOiAicF9pbnN0YW50R2FtZSJ9",
    posterBig: "/static/img/content/posters/tap_fantasy_poster_big.webp",
    posterSmall: "/static/img/content/posters/tap_fantasy_poster_small.webp",
    size: "l",
    description: "Biggest MMORPG among Telegram",
    tag: "game / telegram",
    gameCategories: ["trending", "Web3", "Action"],
  },
  {
    id: "sudokuMaster",
    name: "Sudoku Master",
    categories: ["telegram"],
    url: "https://t.me/SudokuMasterGameBot",
    posterBig: "/static/img/content/posters/sudoku_master_poster_big.webp",
    posterSmall: "/static/img/content/posters/sudoku_master_poster_small.webp",
    size: "m",
    description: "Classic Sudoku game",
    tag: "game / telegram",
    gameCategories: ["puzzle"],
  },
  {
    id: "wordCake",
    name: "Word Cake",
    categories: ["telegram"],
    url: "https://t.me/WordCakeGameBot",
    posterBig: "/static/img/content/posters/word_cake_poster_big.webp",
    posterSmall: "/static/img/content/posters/word_cake_poster_small.webp",
    size: "m",
    description: "How many words you can build",
    tag: "game / telegram",
    gameCategories: ["puzzle", "trending"],
  },
  {
    id: "kingSolitaire",
    name: "King Solitaire",
    categories: ["telegram"],
    url: "https://t.me/KingSolitaireGameBot",
    posterBig: "/static/img/content/posters/king_solitaire_poster_big.webp",
    posterSmall: "/static/img/content/posters/king_solitaire_poster_small.webp",
    size: "m",
    description: "Classic solitaire game",
    tag: "game / telegram",
    gameCategories: ["Cards", "trending"],
  },
  {
    id: "osolitaireKlondike",
    name: "Solitaire Klondike",
    categories: ["telegram"],
    url: "https://t.me/osolitaire_bot/klondike",
    posterBig: "/static/img/content/posters/osolitaireKlondike-640-360.webp",
    posterSmall: "/static/img/content/posters/osolitaireKlondike-360-360.webp",
    size: "m",
    description: "Klondike solitaire game",
    tag: "game / telegram",
    gameCategories: ["Cards", "trending"],
  },
  {
    id: "TONGochi",
    name: "TONGochi",
    categories: ["telegram"],
    url: "https://t.me/TonGochi_bot?start=4116",
    posterBig: "/static/img/content/posters/tongochi_poster_big.webp",
    posterSmall: "/static/img/content/posters/tongochi_poster_small.webp",
    size: "l",
    tag: "game / telegram",
    description: "OG Telegram tamagochi game",
    gameCategories: ["Web3", "Inline"],
  },
  {
    id: "battalionCommander",
    name: "Battalion Commander",
    categories: ["telegram"],
    url: "https://t.me/BattalionCommanderGameBot",
    posterBig: "/static/img/content/posters/battalion_commander_poster_big.webp",
    posterSmall: "/static/img/content/posters/battalion_commander_poster_small.webp",
    size: "l",
    tag: "game / telegram",
    gameCategories: ["trending", "Action"],
    description: "Reach the end of the battlefield!",
  },
  {
    id: "endlessSiege2",
    name: "Endless Siege 2",
    categories: ["telegram"],
    url: "https://t.me/EndlessSiege2GameBot",
    posterBig: "/static/img/content/posters/endless_siege_2_poster_big.webp",
    posterSmall: "/static/img/content/posters/endless_siege_2_poster_small.webp",
    gameCategories: ["trending", "Action", "towerDefence"],
    description: "Tough version of ES",
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "crazyFishing",
    name: "Crazy Fishing",
    categories: ["telegram"],
    url: "https://t.me/CrazyFishingGameBot",
    posterBig: "/static/img/content/posters/crazy_fishing_poster_big.webp",
    posterSmall: "/static/img/content/posters/crazy_fishing_poster_small.webp",
    gameCategories: ["trending", "Action"],
    description: "Find out the deepest fish!",
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "boltClicker",
    name: "Bolt Clicker",
    categories: ["telegram"],
    url: "https://t.me/BoltClickerBot",
    posterBig: "/static/img/content/posters/bolt_clicker_poster_big.webp",
    posterSmall: "/static/img/content/posters/bolt_clicker_poster_small.webp",
    size: "l",
    gameCategories: ["Web3", "Action"],
    description: "Legend memecoin clicker",
    tag: "game / telegram",
  },
  {
    id: "kingdomSurvivor",
    name: "Kingdom Survivor",
    categories: ["telegram"],
    url: "https://t.me/KingdomSurvivorGameBot",
    posterBig: "/static/img/content/posters/kingdom_survivor_poster_big.webp",
    posterSmall: "/static/img/content/posters/kingdom_survivor_poster_small.webp",
    gameCategories: ["Action"],
    description: "Survivor-a-like game",
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "boxTower",
    name: "Box Tower",
    categories: ["telegram"],
    url: "https://t.me/BoxTowerGameBot",
    posterBig: "/static/img/content/posters/box_tower_poster_big.webp",
    posterSmall: "/static/img/content/posters/box_tower_poster_small.webp",
    description: "Build tallest tower",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "deversee",
    name: "Deversee",
    categories: ["desktop", "web3"],
    url: "https://meta.deversee.com/",
    posterBig: "/static/img/content/posters/deversee_poster_big.webp",
    posterSmall: "/static/img/content/posters/deversee_poster_small.webp",
    tag: "game",
    size: "m",
  },
  {
    id: "battleship",
    name: "Battleship",
    categories: ["telegram"],
    url: "https://t.me/BtlshipGameBot",
    posterBig: "/static/img/content/posters/battleship_poster_big.webp",
    posterSmall: "/static/img/content/posters/battleship_poster_small.webp",
    description: "Classic game",
    gameCategories: ["P2P"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "chess",
    name: "Chess",
    categories: ["telegram"],
    url: "https://t.me/Ch8ssBot",
    posterBig: "/static/img/content/posters/chess_poster_big.webp",
    posterSmall: "/static/img/content/posters/chess_poster_small.webp",
    size: "m",
    description: "Classic game",
    gameCategories: ["P2P"],
    tag: "game / telegram",
  },
  {
    id: "dropAndMerge",
    name: "Drop N Merge",
    categories: ["telegram"],
    url: "https://t.me/DropNMergeGameBot",
    posterBig: "/static/img/content/posters/drop_n_merge_poster_big.webp",
    posterSmall: "/static/img/content/posters/drop_n_merge_poster_small.webp",
    description: "Legendary puzzle game",
    gameCategories: ["puzzle"],
    size: "l",
    tag: "game / telegram",
  },
  {
    id: "parkingJam",
    name: "Parking Jam",
    categories: ["telegram"],
    url: "https://t.me/ParkingJamGameBot",
    posterBig: "/static/img/content/posters/parking_jam_poster_big.webp",
    posterSmall: "/static/img/content/posters/parking_jam_poster_small.webp",
    description: "Find out the way from parking",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "BilitaMpash",
    name: "Bilita Mpash",
    categories: ["desktop", "web3"],
    url: "/s/BilitaMpash/view",
    posterBig: "/static/img/content/posters/thinkpink_poster_big.webp",
    posterSmall: "/static/img/content/posters/thinkpink_poster_small.webp",
    tag: "art / nft",
    size: "s",
  },
  {
    id: "IntergalacticFootball",
    name: "Intergalactic Football",
    categories: ["desktop", "gameverse"],
    url: "/s/IntergalacticFootball/view",
    posterBig: "/static/img/content/posters/intergalactic_football_poster_big.webp",
    posterSmall: "/static/img/content/posters/intergalactic_football_poster_big.webp",
    tag: "game",
    size: "s",
  },
  {
    id: "geckoShrine",
    name: "Gecko Shrine",
    categories: ["desktop", "gameverse"],
    url: "/s/geckoShrine/view",
    posterBig: "/static/img/content/posters/gecko_shrine_1_poster_big.webp",
    posterSmall: "/static/img/content/posters/gecko_shrine_1_poster_big.webp",
    tag: "game",
    size: "s",
  },
  {
    id: "memoryMatch",
    name: "Memory Match",
    categories: ["telegram"],
    url: "https://t.me/MemoryMatchGameBot",
    posterBig: "/static/img/content/posters/memory_match_poster_big.webp",
    posterSmall: "/static/img/content/posters/memory_match_poster_small.webp",
    description: "Match two game",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "coinMatch",
    name: "Coin Match",
    categories: ["telegram"],
    url: "https://t.me/CoinMatchGameBot",
    posterBig: "/static/img/content/posters/coin_match_poster_big.webp",
    posterSmall: "/static/img/content/posters/coin_match_poster_small.webp",
    description: "Match three game",
    gameCategories: ["puzzle"],
    size: "l",
    tag: "game / telegram",
  },
  {
    id: "candyland",
    name: "Candyland Journey",
    categories: ["telegram"],
    url: "https://t.me/CandylandGameBot",
    posterBig: "/static/img/content/posters/candyland_journey_poster_big.webp",
    posterSmall: "/static/img/content/posters/candyland_journey_poster_small.webp",
    description: "The sweetest game in universe",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "Journey",
    name: "Journey",
    categories: ["desktop", "gameverse"],
    url: "/s/Journey/view",
    posterBig: "/static/img/content/posters/castle_journey_poster_big.webp",
    posterSmall: "/static/img/content/posters/castle_journey_poster_big.webp",
    tag: "game",
    size: "s",
  },
  {
    id: "geckoshrineroom2",
    name: "Gecko Shrine 2",
    categories: ["desktop", "gameverse"],
    url: "/s/geckoshrineroom2/view",
    posterBig: "/static/img/content/posters/gecko_shrine_2_poster_big.webp",
    posterSmall: "/static/img/content/posters/gecko_shrine_2_poster_big.webp",
    tag: "game",
    size: "s",
  },
  {
    id: "rudeRaces",
    name: "Rude Races",
    categories: ["telegram"],
    url: "https://t.me/RudeRacesGameBot",
    posterBig: "/static/img/content/posters/rude_races_poster_big.webp",
    posterSmall: "/static/img/content/posters/rude_races_poster_small.webp",
    description: "Beat you racing opponent",
    gameCategories: ["Action"],
    size: "l",
    tag: "game / telegram",
  },
  {
    id: "formulaRush",
    name: "Formula Rush",
    categories: ["telegram"],
    url: "https://t.me/FormulaRushGameBot",
    posterBig: "/static/img/content/posters/formula_rush_poster_big.webp",
    posterSmall: "/static/img/content/posters/formula_rush_poster_small.webp",
    description: "Speed is all you need",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "zombieAttack",
    name: "Zombie Attack",
    categories: ["telegram"],
    url: "https://t.me/ZombieAttackGameBot",
    posterBig: "/static/img/content/posters/zombie_attack_poster_big.webp",
    posterSmall: "/static/img/content/posters/zombie_attack_poster_small.webp",
    description: "Try to survive as long as possible",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "riseUp",
    name: "Rise Up",
    categories: ["telegram"],
    url: "https://t.me/RiseUpGameBot",
    posterBig: "/static/img/content/posters/rise_up_poster_big.webp",
    posterSmall: "/static/img/content/posters/rise_up_poster_small.webp",
    description: "Tower building game",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "Shard_Quest",
    name: "Shard Quest",
    categories: ["telegram"],
    url: "https://t.me/ShardQuestBot",
    posterBig: "/static/img/content/posters/shard_quest_poster_big.webp",
    posterSmall: "/static/img/content/posters/shard_quest_poster_small.webp",
    description: "Legendary AAA game",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "desertWarrior",
    name: "Desert Warrior",
    categories: ["telegram"],
    url: "https://t.me/DesertWarriorGameBot",
    posterBig: "/static/img/content/posters/desert_warrior_poster_big.webp",
    posterSmall: "/static/img/content/posters/desert_warrior_poster_small.webp",
    description: "Who is the greatest warrior",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "furiousRoad",
    name: "Furious Road",
    categories: ["telegram"],
    url: "https://t.me/FuriousRoadGameBot",
    posterBig: "/static/img/content/posters/furious_road_poster_big.webp",
    posterSmall: "/static/img/content/posters/furious_road_poster_small.webp",
    description: "Never ending racing",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "slingTomb",
    name: "Sling Tomb",
    categories: ["telegram"],
    url: "https://t.me/SlingTombGameBot",
    posterBig: "/static/img/content/posters/sling_tomb_poster_big.webp",
    posterSmall: "/static/img/content/posters/sling_tomb_poster_small.webp",
    description: "Find your way to the top",
    gameCategories: ["Action"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "hangman",
    name: "Hangman",
    categories: ["telegram"],
    url: "https://t.me/playHangmanGameBot",
    posterBig: "/static/img/content/posters/hangman_poster_big.webp",
    posterSmall: "/static/img/content/posters/hangman_poster_small.webp",
    description: "Hangman or word?",
    gameCategories: ["puzzle"],
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "polygami",
    name: "Polygami",
    categories: ["telegram"],
    url: "https://t.me/PolygamiGameBot",
    posterBig: "/static/img/content/posters/polygami_poster_big.webp",
    posterSmall: "/static/img/content/posters/polygami_poster_small.webp",
    size: "m",
    tag: "game / telegram",
  },
  {
    id: "nizn6xmq4j",
    name: "Mystery Coffee Shop",
    categories: ["desktop", "gameverse"],
    url: "/s/nizn6xmq4j/view",
    posterBig: "/static/img/content/posters/mystery_coffee_shop_poster_big.webp",
    posterSmall: "/static/img/content/posters/mystery_coffee_shop_poster_big.webp",
    tag: "game",
    size: "s",
  },
  {
    id: "dogeverse",
    name: "Dogeverse",
    categories: ["desktop"],
    url: "https://www.dogeverse.cool/",
    posterBig: "/static/img/content/posters/dogeverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/dogeverse_poster_small.webp",
    tag: "game",
    size: "m",
  },
  {
    id: "ticketmeta",
    name: "Ticket Meta",
    categories: ["desktop", "web3"],
    url: "https://play.ticketmeta.io/",
    posterBig: "/static/img/content/posters/ticketmeta_poster_big.webp",
    posterSmall: "/static/img/content/posters/ticketmeta_poster_small.webp",
    tag: "event",
    size: "s",
  },
  {
    id: "gameverse",
    name: "Gameverse",
    categories: ["gameverse"],
    url: "https://gameverse.8xr.io/",
    posterBig: "/static/img/content/posters/gameverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/gameverse_poster_small.webp",
    tag: "competition",
    size: "s",
  },
  {
    id: "haken",
    name: "Haken",
    categories: ["vr_art"],
    url: "https://play.haken-gallery.co.uk/",
    posterBig: "/static/img/content/posters/haken_poster_big.webp",
    posterSmall: "/static/img/content/posters/haken_poster_small.webp",
    tag: "art",
    size: "s",
  },
  {
    id: "digitalAir",
    name: "Digital Air",
    categories: ["vr_art"],
    url: "https://play.digital-air.art/",
    posterBig: "/static/img/content/posters/digital_air_poster_big.webp",
    posterSmall: "/static/img/content/posters/digital_air_poster_small.webp",
    tag: "art",
    size: "m",
  },
  {
    id: "chimeraPlatform",
    name: "Chimera Platform",
    categories: ["vr_art"],
    url: "https://play.chimeraplatform.art/",
    posterBig: "/static/img/content/posters/chimeraplatform_poster_big.webp",
    posterSmall: "/static/img/content/posters/chimeraplatform_poster_small.webp",
    tag: "art",
    size: "m",
  },
  {
    id: "incredible_dream_01",
    name: "Incredible Dreams",
    categories: ["desktop", "gameverse"],
    url: "/s/incredible_dream_01/view",
    posterBig: "/static/img/content/posters/incredible_dreams_poster_big.webp",
    posterSmall: "/static/img/content/posters/incredible_dreams_poster_small.webp",
    tag: "art",
    size: "s",
  },
  {
    id: "education-level",
    name: "Education Level",
    categories: ["desktop"],
    url: "/s/education-level/view",
    posterBig: "/static/img/content/posters/education-level-poster-big.webp",
    posterSmall: "/static/img/content/posters/education-level-poster-small.webp",
    tag: "template",
    size: "m",
  },
  {
    id: "IvanDurnev",
    name: "Ivan Durnev Workspace",
    categories: ["drafts"],
    url: "/s/IvanDurnev/view",
    posterBig: "/static/img/content/posters/ivan_durnev_gameverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/ivan_durnev_gameverse_poster_small.webp",
    tag: "sketch",
    size: "m",
  },
  {
    id: "relaxroom",
    name: "Relax Room",
    categories: ["drafts"],
    url: "/s/relaxroom/view",
    posterBig: "/static/img/content/posters/relaxroom_poster_big.webp",
    posterSmall: "/static/img/content/posters/relaxroom_poster_small.webp",
    tag: "sketch",
    size: "s",
  },
];

export const games = gamesConfig.map((game, index) => ({ ...game, id: String(index) }));

export const gameById = (id: string) => gamesConfig.find((game) => game.id === id);
export const gamesByCategories = games.reduce((acc, game) => {
  for (const category of game.categories) {
    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(game);
  }

  return acc;
}, {} as Record<GameCategory, Game[]>);

const categoriesOrder: GameCategory[] = ["telegram", "vr_art", "desktop", "drafts", "web3", "gameverse"];

export const categoriesOrderMap = categoriesOrder.reduce((acc, category, index) => {
  acc[category] = index;
  return acc;
}, {} as Record<GameCategory, number>);

export const availableCategories = (Object.keys(gamesByCategories) as GameCategory[]).sort((a, b) => {
  return categoriesOrderMap[a] - categoriesOrderMap[b];
});

export const getCategoryName = (category: GameCategory) => {
  return namesMap[category] || upperFirst(category);
};

const namesMap: PartialRecord<GameCategory, string> = {
  vr_art: "VR / Art",
  web3: "Web 3",
};
