import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, NumberSocket } from "../../sockets";
import { AnimationComponentContext } from "~/view-scene/runtime";

export class AnimationDurationNode extends BaseNode {
  static readonly type = "action/animationDuration";
  static readonly label = "Animation Duration";
  static readonly pure = true;

  inputs = [new AnimationSocket("animation")];
  outputs = [new NumberSocket("duration", "duration (ms)")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const animationComponent = node.readComponent<AnimationComponentContext>(context, "animation");

    context.writeOutput<number>("duration", animationComponent?.duration ?? 0);
  }
}
