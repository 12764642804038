import { Icon, IconProps } from "./Icon";

export type MoreProps = IconProps;

export const More = (props: MoreProps) => (
  <Icon width="12px" height="4px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 4" {...props}>
    <path
      d="M5.99996 2.66667C6.36815 2.66667 6.66663 2.36819 6.66663 2C6.66663 1.63181 6.36815 1.33334 5.99996 1.33334C5.63177 1.33334 5.33329 1.63181 5.33329 2C5.33329 2.36819 5.63177 2.66667 5.99996 2.66667Z"
      stroke="white"
      strokeOpacity="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6666 2.66667C11.0348 2.66667 11.3333 2.36819 11.3333 2C11.3333 1.63181 11.0348 1.33334 10.6666 1.33334C10.2984 1.33334 9.99996 1.63181 9.99996 2C9.99996 2.36819 10.2984 2.66667 10.6666 2.66667Z"
      stroke="white"
      strokeOpacity="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33329 2.66667C1.70148 2.66667 1.99996 2.36819 1.99996 2C1.99996 1.63181 1.70148 1.33334 1.33329 1.33334C0.965103 1.33334 0.666626 1.63181 0.666626 2C0.666626 2.36819 0.965103 2.66667 1.33329 2.66667Z"
      stroke="white"
      strokeOpacity="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
