import { styled } from "@mui/material";
import { palette } from "~/theme";
import { Telegram as TelegramIcon, TelegramProps as TelegramIconProps } from "./Icon";
import { Navigate } from "./Navigate";

export type TelegramProps = Pick<TelegramIconProps, "className" | "sx">;

export const Telegram = (props: TelegramProps) => {
  return (
    <Navigate to="https://t.me/gam8s" openInNewWindow={true}>
      <StyledTelegramIcon {...props} />
    </Navigate>
  );
};

const StyledTelegramIcon = styled(TelegramIcon)`
  width: 48px;
  height: 48px;
  transition: 0.4s ease;
  color: ${palette.primary.white};

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
  }
`;
