import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberAtanNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberAtan";
  static readonly label = "Arctan";

  constructor(context: NodeContext) {
    super(context, (value) => Math.atan(value));
  }
}
