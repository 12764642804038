import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import { TextField } from "~/hephaestus/components/TextField";
import IWorkspace from "~/types/IWorkspace";

export type EditWorkspaceDialogProps = {
  open: boolean;
  workspace: IWorkspace | null;
  onUpdate: (workspace: IWorkspace) => void;
  onSave: VoidFunction;
  onClose: VoidFunction;
};

export const EditWorkspaceDialog = ({ open, workspace, onUpdate, onSave, onClose }: EditWorkspaceDialogProps) => {
  const handleNameUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedWorkspace = { ...workspace, name: value };
    onUpdate(updatedWorkspace);
  };

  const isNew = workspace?._id;

  const dialogTitle = isNew ? "Edit Workspace" : "Create Workspace";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="center" padding={1}>
          <Typography variant="h5" color="secondary">
            {dialogTitle}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "450px", padding: 4, paddingBottom: 3 }}>
        <Stack spacing={3}>
          <Typography variant="body2">
            Please fill in Workspace details and click the "Save" button to save the changes or "Cancel" to stop editing
            and discard any changes immediately.
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Name"
            size="small"
            autoFocus
            name="name"
            value={workspace?.name}
            fullWidth={true}
            onChange={handleNameUpdate}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingRight: 4, paddingBottom: 3 }}>
        <Button color="inherit" size="small" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
