import { attach, createStore } from "effector";
import { RefObject } from "react";
import { ScriptArguments } from "~/types/IScriptComponent";
import { ScriptSchema } from "~/types/ScriptAsset";
import type { EntityContext } from "~/view-scene/runtime";
import { Script } from "../Script";
import { $scriptExecutor } from "./executor";
import { JSScriptSchema } from "~/types/JSScriptAsset";

export const $scripts = createStore<Record<string, Script>>({});

type RegisterBehavePayload = {
  id: string;
  name: string;
  entityContextRef: RefObject<EntityContext>;
  args: ScriptArguments;
  schema: ScriptSchema;
};

export const registerBehaveScript = attach({
  source: $scriptExecutor,
  effect: (executor, { id, name, entityContextRef, args, schema }: RegisterBehavePayload) => {
    if (executor) {
      return executor.registerBehave(id, name, entityContextRef, args, schema);
    }
  },
});

type RegisterJSScriptPayload = {
  id: string;
  entityContextRef: RefObject<EntityContext>;
  args: ScriptArguments;
  code: string;
  schema: JSScriptSchema;
};

export const registerJSScript = attach({
  source: $scriptExecutor,
  effect: (executor, { id, entityContextRef, args, code, schema }: RegisterJSScriptPayload) => {
    if (executor) {
      return executor.registerJS(id, entityContextRef, args, code, schema);
    }
  },
});

$scripts.on(registerBehaveScript.doneData, (scripts, script) => {
  if (script) {
    return {
      ...scripts,
      [script.id]: script,
    };
  }
});

registerBehaveScript.failData.watch((err) => console.error(err));
