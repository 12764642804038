import { ScriptEvent } from "~/types/ScriptAsset";
import { EventsManager } from "../EventsManager";
import { JSScriptExports } from "./types";

export class JSEventsManager extends EventsManager {
  constructor(events: ScriptEvent[], private exportObject: JSScriptExports) {
    super(events);
  }

  call(eventId: string, values: Record<string, any> | any[]) {
    const event = this.events[eventId];

    if (!event) {
      return;
    }

    this.callEvent(event, values);
  }

  callByName(name: string, values: Record<string, any> | any[]) {
    const event = this.eventsByName[name];

    if (!event) {
      return;
    }

    this.callEvent(event, values);
  }

  private callEvent(event: ScriptEvent, values: Record<string, any> | any[]) {
    const func = this.exportObject[event.name];

    if (func) {
      const valuesArr = Array.isArray(values) ? values : event.inputs.map((input) => values[input.id]);
      func.apply(this.exportObject, valuesArr);
    }
  }
}
