import { Billboard } from "@react-three/drei";
import { memo, ReactNode } from "react";
import { EnabledStatus } from "~/types/EnabledStatus";
import { IBillboardSceneObject } from "~/types/IBillboardSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";
import { useDIContext } from "~/view-scene/diContext";
import { Entity } from "./Entity";

type BillboardEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const BillboardEntity = memo(({ entityId, children }: BillboardEntityProps) => {
  const entity = useEntity<IBillboardSceneObject>(entityId);
  const { toSceneEntity } = useDIContext();

  if (entity.enabled !== EnabledStatus.enabled) {
    return null;
  }

  return (
    <Entity entityId={entityId} renderChildEntities={false}>
      <Billboard follow={entity.follow} lockX={entity.lockX} lockY={entity.lockY} lockZ={entity.lockZ}>
        {entity.children?.map((childEntity) => toSceneEntity(childEntity))}
      </Billboard>
      {children}
    </Entity>
  );
});
