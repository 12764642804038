import { $flags } from "~/entities/flags";
import { BaseAdsProvider } from "./BaseAdsProvider";
import { sendInfo } from "../models";

const publicModules = ["AdManPlayer", "AdManSDK"];
const initArrays = ["_AdManPlayerInit", "_AdManSDKInit"];
const containerId = "mytarget-ad";
const scriptId = "mytarget-script";

const adLoadTimeout = 3000;

const adsDebugMode = $flags.getState().adsDebug;

export class MyTargetAdsProvider extends BaseAdsProvider {
  private container: HTMLDivElement | null = null;
  private adsAvailable = true;

  constructor(private slot: number) {
    super();
    this.type = "MyTarget";
  }

  async init() {
    await this.loadScript();
  }

  start() {
    sendInfo({ provider: this.type, action: "start" });
    this.container = this.initContainer();
    this.isPlaying = true;
    this.runAd();
  }

  async dispose() {
    this.disposeContainer();
  }

  async hasAds() {
    sendInfo({ provider: this.type, action: "hasAdsRequest" });
    sendInfo({ provider: this.type, action: "hasAdsResult", result: this.adsAvailable });

    return this.adsAvailable;
  }

  private runAd() {
    let resolved = false;

    const stopTimeout = () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    let timeoutId: number | null = window.setTimeout(() => {
      resolved = true;
      this.adError();
    }, adLoadTimeout);

    // @ts-ignore
    AdManPlayer({
      container: `#${containerId}`,
      slot: `${this.slot}`,
      iframe: true,
      params: adsDebugMode ? { test_mode: 1 } : {},
      onError: () => {
        stopTimeout();

        if (!resolved) {
          this.adError();
        }
      },
      onStarted: () => {
        this.adLoaded();
        stopTimeout();
        this.emit("started");
      },
      onClosed: () => {
        if (!resolved) {
          this.finishAd(false);
        }
      },
      onCompleted: () => {
        if (!resolved) {
          this.finishAd(true);
        }
      },
    });
  }

  private async loadScript() {
    return new Promise<void>((resolve) => {
      if (document.getElementById(scriptId)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://ad.mail.ru/static/vk-adman.js";
      script.async = true;
      script.onload = () => resolve();
      const firstScriptElement = document.getElementsByTagName("script")[0];
      firstScriptElement.parentNode?.insertBefore(script, firstScriptElement);

      const func = function (this: any) {
        this.push(Array.prototype.slice.apply(arguments));
      };

      for (let i = 0; i < publicModules.length; i++) {
        // @ts-ignore
        window[publicModules[i]] = func.bind((window[initArrays[i]] = []));
      }
    });
  }

  private adLoaded() {
    if (this.container) {
      this.container.style.backgroundImage = "none";
    }
  }

  private initContainer() {
    const block = document.createElement("div");
    block.id = "mytarget-ad";
    block.style.display = "flex";
    block.style.flexDirection = "column";
    block.style.justifyContent = "center";
    block.style.backgroundColor = "#000000";
    block.style.backgroundImage = "url('/static/img/content/loading.gif')";
    block.style.backgroundRepeat = "no-repeat";
    block.style.backgroundPosition = "center";
    block.style.backgroundSize = "100px";
    block.style.position = "absolute";
    block.style.inset = "0";
    block.style.zIndex = "10001";
    document.body.appendChild(block);

    return block;
  }

  private disposeContainer() {
    if (this.container) {
      this.container.remove();
      this.container = null;
    }
  }

  private finishAd(viewed: boolean) {
    this.isPlaying = false;
    this.disposeContainer();
    if (viewed) {
      this.emit("completed");
      this.emit("finished", true);
    } else {
      this.emit("closed");
      this.emit("finished", false);
    }

    sendInfo({ provider: this.type, action: "viewed", result: viewed });
  }

  private adError() {
    this.isPlaying = false;
    this.adsAvailable = false;
    this.disposeContainer();
    this.emit("error");

    sendInfo({ provider: this.type, action: "error" });
  }
}
