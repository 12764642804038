import { Modal as MuiModal, ModalProps as MuiMuiModalProps, Stack, styled, SvgIcon, Typography } from "@mui/material";
import { palette } from "~/theme";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Close } from "./Icon";
import { ReactNode } from "react";

type ModalProps = Omit<MuiMuiModalProps, "children" | "onClose"> & {
  onClose: (reason?: string) => void;
  TitleIcon?: typeof SvgIcon;
  title: string;
  children?: ReactNode;
  hideCloseButton?: boolean;
  contentBackground?: string;
};

export function Modal({
  open,
  onClose,
  TitleIcon,
  title,
  hideCloseButton = false,
  children,
  contentBackground = palette.primary.black900,
  ...rest
}: ModalProps) {
  return (
    <StyledModal open={open} onClose={(_, reason) => onClose(reason)} {...rest}>
      <ModalContainer bgcolor={contentBackground}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24px",
              width: "100%",
              color: palette.primary.gray600,
            }}
            variant="body1"
          >
            <>
              {TitleIcon && <TitleIcon sx={{ color: "white", marginRight: "10px" }} />}
              {title}
            </>
          </Typography>
          {!hideCloseButton && (
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          )}
        </Stack>
        {children}
      </ModalContainer>
    </StyledModal>
  );
}

const StyledModal = styled(MuiModal)`
  backdrop-filter: blur(10px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.primary.gray200};
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.primary.gray600};
  }
`;

const ModalContainer = styled(Stack)`
  width: 541px;
  min-width: 300px;
  border: 1px solid ${palette.primary.black100};
  border-radius: 20px;
  vertical-align: middle;
  padding: 20px 24px;
  outline: none;

  @media (max-width: 600px) {
    width: calc(100% - 90px);
  }
`;
