import React, { useEffect, useRef, useState } from "react";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Stack, SvgIcon, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { palette } from "~/theme";
import { Button } from "~/hephaestus/components/Button";
import { useProfile } from "~/entities/profile";
import { useOnScreen } from "~/common/hooks/useOnScreen";
import AttentionAlert from "~/common/components/AttentionAlert";
import { RenderUserWallet } from "./RenderUserWallet";
import { AttachWalletDialog } from "./AttachWalletDialog";
import { BlockchainNetwork, networkToIcon, networkToName } from "~/web3Helpers";

export type WalletsProps = {
  onScreen: VoidFunction;
};

export const Wallets = ({ onScreen }: WalletsProps) => {
  const profile = useProfile((state) => state.profile);

  const [addWalletMenuOpen, setAddWalletMenuOpen] = useState<null | HTMLElement>(null);
  const isAddWalletMenuOpen = Boolean(addWalletMenuOpen);
  const handleOpenAddWalletMenu = (event: React.MouseEvent<HTMLElement>) => setAddWalletMenuOpen(event.currentTarget);
  const handleCloseAddWalletMenu = () => setAddWalletMenuOpen(null);

  const [attachWalletDialog, setAttachWalletDialog] = useState({
    open: false,
    network: BlockchainNetwork.ethereum,
  });

  const handleAttachEthWalletClick = () => {
    setAttachWalletDialog({
      open: true,
      network: BlockchainNetwork.ethereum,
    });
    handleCloseAddWalletMenu();
  };

  const handleAttachSolanaWalletClick = () => {
    setAttachWalletDialog({
      open: true,
      network: BlockchainNetwork.solana,
    });
    handleCloseAddWalletMenu();
  };

  const handleAttachPolygonWalletClick = () => {
    setAttachWalletDialog({
      open: true,
      network: BlockchainNetwork.polygon,
    });
    handleCloseAddWalletMenu();
  };

  const handleAttachTonkeeperWalletClick = () => {
    setAttachWalletDialog({
      open: true,
      network: BlockchainNetwork.ton,
    });
    handleCloseAddWalletMenu();
  };

  const handleAttachWalletDialogClose = () =>
    setAttachWalletDialog((prev) => ({
      ...prev,
      open: false,
    }));

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      onScreen();
    }
  }, [isVisible]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <AttachWalletDialog
        open={attachWalletDialog.open}
        network={attachWalletDialog.network}
        onClose={handleAttachWalletDialogClose}
      />

      <Menu
        anchorEl={addWalletMenuOpen}
        open={isAddWalletMenuOpen}
        onClose={handleCloseAddWalletMenu}
        sx={{
          marginTop: "6px",
        }}
      >
        <MenuItem onClick={handleAttachEthWalletClick}>
          <ListItemIcon>
            <Box
              src={networkToIcon(BlockchainNetwork.ethereum)}
              component="img"
              sx={{
                width: "32px",
                height: "32px",
                display: "block",
                objectFit: "contain",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                paddingLeft: "8px",
                lineHeight: "20px",
                color: palette.primary.white,
                display: "block",
                fontWeight: "600",
              })}
              variant="body1"
            >
              {networkToName(BlockchainNetwork.ethereum)}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAttachPolygonWalletClick}>
          <ListItemIcon>
            <Box
              src={networkToIcon(BlockchainNetwork.polygon)}
              component="img"
              sx={{
                width: "32px",
                height: "32px",
                display: "block",
                objectFit: "contain",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                paddingLeft: "8px",
                lineHeight: "20px",
                color: palette.primary.white,
                display: "block",
                fontWeight: "600",
              })}
              variant="body1"
            >
              {networkToName(BlockchainNetwork.polygon)}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAttachSolanaWalletClick}>
          <ListItemIcon>
            <Box
              src={networkToIcon(BlockchainNetwork.solana)}
              component="img"
              sx={{
                width: "32px",
                height: "32px",
                display: "block",
                objectFit: "contain",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                paddingLeft: "8px",
                lineHeight: "20px",
                color: palette.primary.white,
                display: "block",
                fontWeight: "600",
              })}
              variant="body1"
            >
              {networkToName(BlockchainNetwork.solana)}
            </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAttachTonkeeperWalletClick}>
          <ListItemIcon>
            <Box
              src={networkToIcon(BlockchainNetwork.ton)}
              component="img"
              sx={{
                width: "32px",
                height: "32px",
                display: "block",
                objectFit: "contain",
              }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                paddingLeft: "8px",
                lineHeight: "20px",
                color: palette.primary.white,
                display: "block",
                fontWeight: "600",
              })}
              variant="body1"
            >
              {networkToName(BlockchainNetwork.ton)}
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      <Box
        id="user-wallets"
        component="div"
        ref={ref}
        sx={{
          border: "1px solid " + palette.primary.gray100,
          backgroundColor: palette.primary.black400,
          padding: "20px 12px 24px 24px",
          borderRadius: "20px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              flexWrap: "wrap",
              gridGap: "12px",
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "24px",
              color: palette.primary.gray600,
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                lineHeight: "22px",
              },
            })}
            variant="body1"
          >
            Linked wallets
          </Typography>
          <Box component="div">
            <Button
              size="large"
              variant="contained"
              startIcon={
                <SvgIcon
                  sx={{
                    display: "block",
                    width: "24px",
                    position: "relative",
                    height: "24px",
                  }}
                >
                  <AddIcon />
                </SvgIcon>
              }
              onClick={handleOpenAddWalletMenu}
            >
              Add wallet
            </Button>
          </Box>
        </Stack>
        <AttentionAlert
          sx={{
            marginTop: "24px",
          }}
        >
          We only use your wallet address to fetch your assets. We do not access the wallet or perform any operation
          with it.
        </AttentionAlert>
        <Box
          component="div"
          sx={{
            marginTop: "16px",
          }}
        >
          {profile.wallets?.map((wallet, index) => (
            <RenderUserWallet key={index} wallet={wallet} />
          ))}
        </Box>
      </Box>
    </>
  );
};
