import { EventEmitter } from "eventemitter3";
import { ModalProps } from "./Modal";

export type HyperModalConfig = Pick<
  ModalProps,
  "topTexts" | "buttons" | "images" | "texts" | "showCloseButton" | "onButtonClick" | "onCloseClick" | "appearance"
>;

export interface HyperModalBridge {
  on(event: "open", listener: (config: HyperModalConfig) => void): this;
  on(event: "close", listener: () => void): this;

  off(event: "open", listener: (config: HyperModalConfig) => void): this;
  off(event: "close", listener: () => void): this;

  emit(event: "open", config: HyperModalConfig): boolean;
  emit(event: "close"): boolean;
}

export class HyperModalBridge extends EventEmitter {}

export const hyperModalBridge = new HyperModalBridge();
