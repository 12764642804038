import { createEffect } from "effector";
import * as logApi from "~/api/logs.api";
import { Level } from "~/types/Log";

type SendLogPayload = {
  level: Level;
  message: any;
};

const sendLogFx = createEffect(async (log: SendLogPayload) => {
  const response = await logApi.sendLog(log.level, log.message);

  return response;
});

export const info = sendLogFx.prepend((message: any) => ({ level: "info", message }));
export const warn = sendLogFx.prepend((message: any) => ({ level: "warn", message }));
export const error = sendLogFx.prepend((message: any) => ({ level: "error", message }));
