import ICoordinates from "~/types/ICoordinates";
import { MathUtils } from "three";

export const vector3DegToRad = (vector: ICoordinates) => {
  return {
    x: MathUtils.degToRad(vector.x),
    y: MathUtils.degToRad(vector.y),
    z: MathUtils.degToRad(vector.z),
  } as ICoordinates;
};
