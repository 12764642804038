import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { AnySocket, ArraySocket, NumberSocket } from "../../sockets";
import { anyValue } from "../../values";

export class ArrayGetNode extends BaseNode {
  static readonly type = "array/get";
  static readonly label = "Array Get Item";

  inputs = [new ArraySocket("array", anyValue.name), new NumberSocket("index")];
  outputs = [new AnySocket("item")];

  eval(context: NodeEvalContext) {
    const arr = context.readInput<any[]>("array");
    const index = context.readInput<number>("index");

    context.writeOutput<any>("item", arr[index]);
  }
}
