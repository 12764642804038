import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class StartSessionNode extends BaseNode {
  static readonly type = "action/startSession";
  static readonly label = "Start Session";

  inputs = [new FlowSocket()];
  outputs = [new FlowSocket()];

  eval() {
    // temporary solution. Should be passed via scene context
    useSessionStatus.getState().updateStatus("in_progress");
  }
}
