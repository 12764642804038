import { UIContext } from "~/view-scene/runtime";
import { useUIEntityManagerContext } from "~/view-scene/ui";

export const useUIEntityContext = (id: string) => {
  const { contextRef } = useUIEntityManagerContext<UIContext>(id);
  contextRef.current.type = "ui";

  return {
    contextRef,
  };
};
