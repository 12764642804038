import { Stack, StackProps, styled } from "@mui/material";
import ICoordinates from "~/types/ICoordinates";
import { LabeledNumericField, LabeledNumericFieldProps } from "./LabeledNumericField";
import { withAutoField } from "./withAutoField";

export type VectorFieldProps = Pick<LabeledNumericFieldProps, "disableNativeUndoRedo"> & {
  className?: string;
  name: string;
  value: ICoordinates;
  disabled?: boolean;
  hideSpinButtons?: boolean;
  onChange: (e: OnVectorFieldChangeEvent) => void;
} & Pick<StackProps, "sx">;

export type OnVectorFieldChangeEvent = {
  target: {
    name: string;
    value: ICoordinates;
  };
};

const inputProps = { step: 0.01 };

export const VectorField = ({
  name,
  value,
  disableNativeUndoRedo,
  onChange,
  disabled = false,
  hideSpinButtons = false,
  ...rest
}: VectorFieldProps) => {
  const { x, y, z } = value;

  const handleChange: NonNullable<LabeledNumericFieldProps["onChange"]> = (e) => {
    onChange({
      target: {
        name: name,
        value: {
          ...value,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <Stack direction="row" {...rest}>
      <LeftInput
        name="x"
        value={x}
        label="X"
        disabled={disabled}
        hideSpinButtons={hideSpinButtons}
        inputProps={inputProps}
        disableNativeUndoRedo={disableNativeUndoRedo}
        onChange={handleChange}
      />
      <MiddleInput
        name="y"
        value={y}
        label="Y"
        disabled={disabled}
        hideSpinButtons={hideSpinButtons}
        inputProps={inputProps}
        disableNativeUndoRedo={disableNativeUndoRedo}
        onChange={handleChange}
      />
      <RightInput
        name="z"
        value={z}
        label="Z"
        disabled={disabled}
        hideSpinButtons={hideSpinButtons}
        inputProps={inputProps}
        disableNativeUndoRedo={disableNativeUndoRedo}
        onChange={handleChange}
      />
    </Stack>
  );
};

const Input = styled(LabeledNumericField)`
  .MuiInputAdornment-root .MuiTypography-root {
    display: inline-block;
    width: 5px;
    font-size: 8px;
  }

  &.MuiInputBase-adornedEnd.MuiInputBase-adornedEnd {
    padding-right: 4px;
  }

  .MuiInputBase-input::-webkit-outer-spin-button,
  .MuiInputBase-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiInputBase-input {
    -moz-appearance: textfield;
    font-size: 11px;
    letter-spacing: -0.08em;
    padding-left: 4px;
    padding-right: 4px;
  }

  &:hover {
    .MuiInputAdornment-root {
      display: none;
    }

    .MuiInputBase-root {
      padding-right: 0;
    }

    .MuiInputBase-input {
      padding-right: 16px;
    }

    .MuiInputBase-input::-webkit-outer-spin-button,
    .MuiInputBase-input::-webkit-inner-spin-button {
      -webkit-appearance: auto;
      margin-right: -16px;
    }

    .MuiInputBase-input {
      -moz-appearance: auto;
    }
  }
`;

const LeftInput = styled(Input)`
  & .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const MiddleInput = styled(Input)`
  & .MuiInputBase-root {
    border-radius: 0;
  }
`;

const RightInput = styled(Input)`
  & .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const VectorFieldWithAuto = withAutoField({ defaultValue: { x: 0, y: 0, z: 0 } })(VectorField);
