import { useFrame } from "@react-three/fiber";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { $aspectRatio, updateAspectRatio } from "../models";

export function useAspectRatio() {
  const mode = useSessionStatus((state) => state.mode);

  useFrame(({ viewport, camera }) => {
    const aspectRatio = $aspectRatio.getState();

    if (mode === "vr") {
      if (aspectRatio.toString() !== [5.6, 5.6, 1].toString()) {
        updateAspectRatio([5.6, 5.6, 1]);
        return;
      }
    }

    const actualViewport = viewport.getCurrentViewport(camera);

    const height = actualViewport.height;
    const width = actualViewport.width;

    if (width !== aspectRatio[0] || height !== aspectRatio[1]) {
      updateAspectRatio([width, height, 1]);
    }
  });
}
