import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import { CubeRefractionMapping, CubeTexture, EquirectangularRefractionMapping, Texture } from "three";
import { EnvTextureMap } from "~/types/texture";

const textures: Record<string, Texture> = {};
const sceneTextureId = "scene";

export const useEnvTextureMap = (map: EnvTextureMap) => {
  const environment = useThree((state) => state.scene.environment);

  return useMemo(() => {
    if (!map.enabled) {
      return null;
    }

    if (map.asset) {
      // TODO
      return null;
    }

    if (environment) {
      if (!map.refraction) {
        return environment;
      }

      const cacheId = getCacheId(sceneTextureId, map.refraction);

      if (!textures[cacheId]) {
        const texture = environment.clone();

        if (isCubeTexture(texture)) {
          texture.mapping = CubeRefractionMapping;
        } else {
          texture.mapping = EquirectangularRefractionMapping;
        }

        textures[cacheId] = texture;
      }

      return textures[cacheId]!;
    }

    return null;
  }, [map, environment]);
};

const getCacheId = (id: string, refraction: boolean) => {
  return `${id}_${refraction}`;
};

const isCubeTexture = (texture: Texture): texture is CubeTexture => {
  return (texture as any).isCubeTexture === true;
};
