import { createRef, MutableRefObject, useMemo, useRef } from "react";
import { UIEntity, UIEntityType } from "~/types/ui";
import { $entitiesState, updateEntityState, UIEntityContext, UIEntitiesManager } from "~/view-scene/runtime";
import { buildUiEntityId } from "./utils";

export const useUIEntityManagerContexts = <TManagerContext extends UIEntitiesManager = UIEntitiesManager>(
  managerIds: string[]
) => {
  const contextsRef = useRef({} as Record<string, MutableRefObject<TManagerContext>>);
  const entityContextsRef = useRef(new Map<string, UIEntityContext>());

  useMemo(() => {
    for (const managerId of managerIds) {
      const contextRef = createRef<TManagerContext>() as MutableRefObject<TManagerContext>;

      contextRef.current = {
        getUIEntityContext: (uiEntityId: string) => {
          const id = buildUiEntityId(managerId, uiEntityId);
          let context = entityContextsRef.current.get(id);

          if (context) {
            return context;
          }

          const entity = $entitiesState.getState()[id];

          if (!entity) {
            return null;
          }

          const baseEntityContext: UIEntityContext = {
            id,
            type: "unknown",
            updateEntity: (update) =>
              updateEntityState({
                id,
                update,
              }),
            getEntity: <TEntity extends UIEntity>() => {
              const entity = $entitiesState.getState()[id];

              return entity as TEntity;
            },
          };

          switch (entity.type) {
            case UIEntityType.BLOCK: {
              context = {
                ...baseEntityContext,
                type: "block",
              };
              break;
            }
            case UIEntityType.IMAGE: {
              context = {
                ...baseEntityContext,
                type: "image",
              };
              break;
            }
            case UIEntityType.TEXT: {
              context = {
                ...baseEntityContext,
                type: "text",
              };
              break;
            }
          }

          if (context) {
            entityContextsRef.current.set(id, context);
          }

          return (context as any) ?? null;
        },
      } as TManagerContext;

      contextsRef.current[managerId] = contextRef;
    }
  }, []);

  return {
    contextsRef,
  };
};

export const useUIEntityManagerContext = <TManagerContext extends UIEntitiesManager = UIEntitiesManager>(
  managerId: string
) => {
  const { contextsRef } = useUIEntityManagerContexts<TManagerContext>([managerId]);

  return {
    contextRef: contextsRef.current[managerId],
  };
};
