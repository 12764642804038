import { Suspense, lazy } from "react";
const MobileSource = lazy(() => import("./MobileSource"));

export const MobileSourceLazy = () => {
  return (
    <Suspense fallback={null}>
      <MobileSource />
    </Suspense>
  );
};
