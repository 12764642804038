import { useFrame, useThree } from "@react-three/fiber";
import { easeInCubic, easeInOutQuint } from "js-easing-functions";
import { MutableRefObject, useEffect } from "react";
import { Vector3 } from "three";
import { playerControls } from "~/view-scene/ControlsSystem";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import { playPlayerAnimation, playPlayerJumpAnimation } from "./models";
import { getForwardVector, getSideVector } from "./utils";

const playerVelocity = new Vector3();
const playerDirection = new Vector3();

let jumpDelayActive = false;

export const useHandlePlayerMovements = (contextRef: MutableRefObject<PlayerContext | null>) => {
  const player = usePlayer();
  const camera = useThree((state) => state.camera);

  const handleMovements = () => {
    const { forward, side } = playerControls.move;
    const movementSpeed = contextRef.current?.movementSpeed ?? 4.5;

    const forwardEased = easeInOutQuint(Math.abs(forward), 0, Math.sign(forward), 1);
    const sideEased = easeInOutQuint(Math.abs(side), 0, Math.sign(side), 1);

    playerVelocity.set(0, 0, 0);
    playerVelocity.add(getForwardVector(camera, playerDirection).multiplyScalar(forward * movementSpeed));
    playerVelocity.add(getSideVector(camera, playerDirection).multiplyScalar(side * movementSpeed));

    contextRef.current?.getPhysicsBody()?.setVelocity(playerVelocity.x, undefined, playerVelocity.z);
  };

  useEffect(() => {
    const jumpHandler = () => {
      if (jumpDelayActive) {
        return;
      }

      contextRef.current?.getPhysicsBody()?.applyForceY(4);
      playPlayerJumpAnimation();

      jumpDelayActive = true;
      setTimeout(() => (jumpDelayActive = false), 850);
    };

    playerControls.jump.on("start", jumpHandler);

    return () => {
      playerControls.jump.off("start", jumpHandler);
    };
  }, [player]);

  useEffect(() => {
    const emotionHandler = (emotion: string) => playPlayerAnimation(emotion);

    playerControls.emotion.on("fire", emotionHandler);

    return () => {
      playerControls.emotion.off("fire", emotionHandler);
    };
  }, [player]);

  useFrame((_, delta) => {
    if (!contextRef.current?.getPhysicsBody() || !contextRef.current?.getMovementState()) {
      return;
    }

    handleMovements();
  });
};
