import { NodeEvalContext } from "~/libs/behave-graph";
import { CameraContext, PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class CameraResetNode extends BaseNode {
  static readonly type = "action/cameraReset";
  static readonly label = "Camera Reset";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const cameraContext = node.sceneContext.getEntityContext<CameraContext>("camera");
    const player = node.sceneContext.getEntityContext<PlayerContext>("player")?.rootObjectRef.current;

    if (!cameraContext || !player) {
      return;
    }

    cameraContext.setEnabled(true);
    cameraContext.attachTo(player);
  }
}
