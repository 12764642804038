import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Header } from "~/common/components/Header";
import { Footer } from "~/common/components/Footer";
import { spaces } from "~/common/data/spaces";
import { SpaceHeadliner } from "./SpaceHeadliner";
import { SpaceScenes } from "./SpaceScenes";
import { SpaceCreators } from "./SpaceCreators";

export const SpacePage = () => {
  const { name } = useParams();

  const navigate = useNavigate();

  const space = spaces.find((space) => space.name === name);

  useEffect(() => {
    if (!space) {
      navigate("/404");
    }
  }, [space]);

  if (!space) {
    return null;
  }

  const { link, posterLarge, posterSmall, scenes, creators } = space;

  return (
    <div>
      <Header />
      <SpaceHeadliner link={link} posterSmall={posterSmall} posterLarge={posterLarge} />
      <SpaceScenes sceneNames={scenes} />
      <SpaceCreators creators={creators} />
      <Footer />
    </div>
  );
};
