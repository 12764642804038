import { Circle, Help, Logo, Plus, Tutorials } from "~/common/components/Icon";
import { Button, Divider, Stack, styled } from "@mui/material";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Panel } from "~/hephaestus/components/Panel";
import { Workspace } from "./Workspace";
import { PanelItemContainer } from "./PanelItemContainer";
import { PanelItemName } from "./PanelItemName";
import { $activeLayout, $selectedWorkspace, $workspaces, setActiveLayout, setSelectedWorkspaceId } from "../models";
import { useUnit } from "effector-react";
import { palette } from "~/theme";
import { Navigate } from "~/common/components";

export function LeftPanel() {
  const [selectedSpace, workspaces, activeLayout] = useUnit([$selectedWorkspace, $workspaces, $activeLayout]);

  const handleMyScenesClick = () => {
    setActiveLayout("myScenes");
    setSelectedWorkspaceId(null);
  };

  const handleTutorialsClick = () => {
    setActiveLayout("tutorials");
    setSelectedWorkspaceId(null);
  };

  const handleAddClick = () => {
    setActiveLayout("createSpace");
    setSelectedWorkspaceId(null);
  };

  return (
    <Panel
      open={true}
      headerContent={
        <Navigate to="/">
          <IconButton variant="contained" sx={{ width: "59px" }}>
            <Logo size="large" />
          </IconButton>
        </Navigate>
      }
      sx={{
        background: palette.primary.gradient300,
      }}
    >
      <Stack padding="30px 0 30px 0">
        <PanelItemContainer onClick={handleMyScenesClick} isActive={activeLayout === "myScenes"}>
          <CircleIcon color={palette.primary.gray600} />
          <PanelItemName>My Scenes</PanelItemName>
        </PanelItemContainer>
        <PanelItemContainer onClick={handleTutorialsClick} isActive={activeLayout === "tutorials"}>
          <TutorialsIcon color={palette.primary.gray600} />
          <PanelItemName>Tutorials</PanelItemName>
        </PanelItemContainer>
      </Stack>
      <Stack gap="4px" flexGrow={1}>
        {workspaces?.map((workspace) => (
          <Workspace key={workspace._id} workspace={workspace} />
        ))}
        <AddButton variant="contained" onClick={handleAddClick} startIcon={<Plus size="small" />}>
          Add Project
        </AddButton>
      </Stack>
      <Stack direction="column">
        <Divider />
        <Navigate to="https://discord.gg/Kkjz4GRk" openInNewWindow={true}>
          <HelpButton variant="text" startIcon={<Help />}>
            Help and Feedback
          </HelpButton>
        </Navigate>
      </Stack>
    </Panel>
  );
}

const AddButton = styled(Button)`
  justify-content: left;
  background: transparent;
  padding: 8px 8px 8px 8px;
  margin: 12px 8px;
`;

const HelpButton = styled(Button)`
  color: ${palette.primary.gray600};
  justify-content: left;
  margin: 4px 4px 0 4px;
  width: 100%;
`;

const CircleIcon = styled(Circle)`
  width: 16px;
`;

const TutorialsIcon = styled(Tutorials)`
  width: 16px;
`;
