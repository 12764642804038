import { Icon, IconProps } from "./Icon";

export type SpriteProps = IconProps;

export const Sprite = (props: SpriteProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M14.667 8.07809C14.667 4.35306 11.6473 1.33333 7.92224 1.33333C4.89889 1.33333 2.34016 3.32257 1.48346 6.0636C1.42858 6.23919 1.40114 6.32698 1.40373 6.43676C1.40584 6.52603 1.43297 6.63631 1.47252 6.71636C1.52117 6.81481 1.59628 6.88992 1.74649 7.04012L8.96049 14.2538C9.1107 14.404 9.18581 14.4791 9.28426 14.5277C9.36432 14.5673 9.4746 14.5944 9.56387 14.5965C9.67365 14.5991 9.76144 14.5717 9.93702 14.5168C12.6779 13.66 14.667 11.1013 14.667 8.07809Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.29778 6.4894C2.45812 6.47799 2.62 6.47219 2.78324 6.47219C6.50827 6.47219 9.528 9.49192 9.528 13.217C9.528 13.3802 9.5222 13.5421 9.5108 13.7024C9.49087 13.9825 9.48091 14.1226 9.39926 14.2091C9.33294 14.2794 9.21977 14.3216 9.12363 14.312C9.00527 14.3001 8.89763 14.1925 8.68234 13.9772L2.02301 7.31786C1.80772 7.10257 1.70008 6.99492 1.68822 6.87656C1.67859 6.78043 1.72083 6.66726 1.79109 6.60094C1.87759 6.51928 2.01766 6.50932 2.29778 6.4894Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
