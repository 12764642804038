import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import * as authApi from "~/api/auth.api";
import { Box, FormControl, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { TextField } from "~/hephaestus/components/TextField";
import { TermsAndConditions } from "./TermsAndConditions";
import { Button } from "~/hephaestus/components/Button";
import { LoginWithOauth } from "../Login";
import { getReferer } from "../referer";
import { HOST } from "~/config";

const registerValidationSchema = Yup.object({
  name: Yup.string().min(2).required("Please provide your name"),
  email: Yup.string().email().required("Email is required"),
  password: Yup.string()
    .matches(/^.{6,}$/, "Password must contain at least 6 symbols.")
    .required("Password is required"),
});

type RegisterProps = {
  isOauthEnabled?: boolean;
  onSuccess: (token: string) => void;
};

export function Register({ isOauthEnabled, onSuccess }: RegisterProps) {
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] = useState(false);
  const [agreedPrivacyPolicy, setAgreedPrivacyPolicy] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: registerValidationSchema,
    onSubmit: async (values) => {
      const { name, email, password } = values;

      const tags: Record<string, string> = {};

      const referer = getReferer();
      if (referer) {
        tags["referer"] = referer;
      }
      tags["host"] = HOST;

      const payload = { name, email, password, tags };

      const { status, body } = await authApi.signup(payload);

      if (status !== 201) {
        const errorMessage = body.message;
        formik.setFieldError("email", errorMessage);
        return;
      }

      const { accessToken } = body;
      onSuccess(accessToken);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box component="div">
        <Typography
          sx={{
            fontWeight: "500",
            marginBottom: "24px",
            color: palette.primary.gray800,
          }}
          variant="body2"
        >
          Please fill in the fields and create your 8XR account
        </Typography>
        <Stack
          direction="column"
          sx={{
            gridGap: "23px",
            paddingBottom: "24px",
          }}
        >
          <FormControl fullWidth error={formik.touched.name && Boolean(formik.errors.name)}>
            <TextField
              name="name"
              placeholder="Name"
              size="large"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>

          <FormControl fullWidth error={formik.touched.email && Boolean(formik.errors.email)}>
            <TextField
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              size="large"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>

          <FormControl fullWidth error={formik.touched.password && Boolean(formik.errors.password)}>
            <TextField
              fullWidth
              type="password"
              name="password"
              size="large"
              placeholder="Password"
              autoComplete="new-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </FormControl>
        </Stack>

        {isOauthEnabled && <LoginWithOauth />}

        <TermsAndConditions
          agreedTermsAndConditions={agreedTermsAndConditions}
          setAgreedTermsAndConditions={setAgreedTermsAndConditions}
          agreedPrivacyPolicy={agreedPrivacyPolicy}
          setAgreedPrivacyPolicy={setAgreedPrivacyPolicy}
        />

        <Box component="div" marginTop="36px">
          <Button
            type="submit"
            disabled={!agreedTermsAndConditions || !agreedPrivacyPolicy}
            variant="accent"
            size="large"
            fullWidth
          >
            Register
          </Button>
        </Box>
      </Box>
    </form>
  );
}
