import { forwardRef, useMemo } from "react";
import { Mesh, MeshBasicMaterial } from "three";
import { sphereGeometry } from "./constants";

const material = new MeshBasicMaterial({ color: "white" });

export const RenderDummyBall = forwardRef<Mesh | null>((_, ref) => {
  const geometry = useMemo(() => sphereGeometry.clone(), []);

  return <mesh ref={ref} geometry={geometry} material={material} />;
});
