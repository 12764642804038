import { useFrame } from "@react-three/fiber";
import { memo, useEffect, useRef } from "react";
import { ShaderMaterial, Vector2 } from "three";
import { IShaderMaterial } from "~/types/material";
import { useTextureMap } from "~/view-scene/texture";
import { materialManager } from "../MaterialManager";
import { MaterialRecord } from "../types";
import { toMaterialSide } from "../utils";

type ShaderMaterialAssetProps = {
  materialRecord: MaterialRecord;
  material: IShaderMaterial;
};

export const ShaderMaterialAsset = memo(({ materialRecord, material }: ShaderMaterialAssetProps) => {
  const materialRef = useRef<ShaderMaterial>(null!);

  useEffect(() => {
    const uniforms = {
      time: { type: "f", value: 0.1 },
      resolution: { type: "v2", value: new Vector2() },
      channel0: { type: "t", value: null },
      channel1: { type: "t", value: null },
      channel2: { type: "t", value: null },
      channel3: { type: "t", value: null },
      channel4: { type: "t", value: null },
      channel5: { type: "t", value: null },
    };
    const shaderMaterial = new ShaderMaterial({ uniforms });
    materialRef.current = shaderMaterial;
    materialManager.finishMaterialLoad(materialRecord.id, shaderMaterial);
    return () => {
      shaderMaterial.dispose();
    };
  }, []);

  const { transparent, vertexShader, fragmentShader, opacity, side } = material;

  useEffect(() => {
    materialRef.current.transparent = transparent;
    materialRef.current.vertexShader = vertexShader;
    materialRef.current.fragmentShader = fragmentShader;
    materialRef.current.opacity = opacity;
    materialRef.current.side = toMaterialSide(side);
    materialRef.current.needsUpdate = true;
  }, [transparent, vertexShader, fragmentShader, opacity, side]);

  const { channel0, channel1, channel2, channel3, channel4, channel5 } = material;
  const toChannel0Map = useTextureMap(channel0);
  const toChannel1Map = useTextureMap(channel1);
  const toChannel2Map = useTextureMap(channel2);
  const toChannel3Map = useTextureMap(channel3);
  const toChannel4Map = useTextureMap(channel4);
  const toChannel5Map = useTextureMap(channel5);

  useEffect(() => {
    if (!materialRef.current) {
      return;
    }

    const uniforms = materialRef.current.uniforms;
    uniforms.channel0.value = toChannel0Map;
    materialRef.current.uniforms.channel1.value = toChannel1Map;
    materialRef.current.uniforms.channel2.value = toChannel2Map;
    materialRef.current.uniforms.channel3.value = toChannel3Map;
    materialRef.current.uniforms.channel4.value = toChannel4Map;
    materialRef.current.uniforms.channel5.value = toChannel5Map;
    materialRef.current.needsUpdate = true;
  }, [toChannel0Map, toChannel1Map, toChannel2Map, toChannel3Map, toChannel4Map, toChannel5Map]);

  useFrame((_, delta) => {
    if (!materialRef.current) {
      return;
    }

    const uniforms = materialRef.current.uniforms;
    uniforms.resolution.value.x = window.innerWidth;
    materialRef.current.uniforms.resolution.value.y = window.innerHeight;
    materialRef.current.uniforms.time.value += delta;
  });

  return null;
});
