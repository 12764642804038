import { forward } from "effector";
import { editorHotkey } from "~/hephaestus/hotkeys";
import { editorRedo, editorUndo } from "./editorUndoRedo";

forward({
  from: editorHotkey.undo.filter({ fn: (event) => event.pressed }),
  to: editorUndo,
});

forward({
  from: editorHotkey.redo.filter({ fn: (event) => event.pressed }),
  to: editorRedo,
});
