import { Checkbox as MuiCheckbox, CheckboxProps, styled } from "@mui/material";
import { Check } from "~/common/components/Icon";
import { palette } from "~/theme";

export type { CheckboxProps };

export function Checkbox({ sx, ...rest }: CheckboxProps) {
  return (
    <MuiCheckbox
      checkedIcon={<CheckedIcon color="primary.white" />}
      sx={{
        width: "20px",
        borderRadius: "6px",
        height: "20px",
        overflow: "hidden",
        border: "1px solid " + palette.primary.gray400,
        background: palette.primary.gray20,
        "&.Mui-checked": {
          borderColor: palette.primary.violet,
          background: palette.primary.violet,
        },
        "&.MuiCheckbox-root": {
          width: "16px",
          padding: "7px",
          height: "16px",
        },
        ...sx,
      }}
      {...rest}
    />
  );
}

const CheckedIcon = styled(Check)`
  width: 9px;
  height: 9px;
`;
