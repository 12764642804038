import { useProgress } from "@react-three/drei";
import { createEvent, createStore } from "effector";
import { resetSceneLoad } from "./reset";

type LoadResourcesData = {
  errors: string[];
  active: boolean;
  progress: number;
  item: string;
  loaded: number;
  total: number;
};

const setResourcesLoadData = createEvent<LoadResourcesData>();

export const $resourcesLoadData = createStore<LoadResourcesData>({
  errors: [],
  active: false,
  progress: 0,
  item: "",
  loaded: 0,
  total: 0,
})
  .on(setResourcesLoadData, (state, payload) => {
    if (payload.progress < state.progress) {
      payload.progress = state.progress;
    }

    return payload;
  })
  .reset(resetSceneLoad);

export const $resourcesLoaded = $resourcesLoadData.map((state) => state.progress === 100);
export const $resourcesLoadProgress = $resourcesLoadData.map((state) => state.progress);

useProgress.subscribe((data) => setResourcesLoadData(data));
