import { forwardRef } from "react";
import { useMove } from "./hooks";
import { DynamicJoystick, DynamicJoystickProps } from "./joystick";

export type DynamicSimpleMoveJoystickProps = Pick<
  DynamicJoystickProps,
  "className" | "style" | "onTouchStart" | "onTouchEnd"
>;

export const DynamicSimpleMoveJoystick = forwardRef<HTMLDivElement, DynamicSimpleMoveJoystickProps>((props, ref) => {
  const { handleChange } = useMove();

  return <DynamicJoystick {...props} style={{ position: "absolute", inset: 0 }} ref={ref} onChange={handleChange} />;
});
