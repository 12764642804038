import { useFrame } from "@react-three/fiber";
import { useStoreMap } from "effector-react";
import { memo, ReactNode, useEffect, useRef, useState } from "react";
import { Group, Mesh } from "three";
import { useSceneData } from "~/common/stores/useSceneData";
import { PLAYER_HEIGHT } from "~/config";
import { $cameraState } from "~/view-scene/CameraSystem";
import { usePhysics } from "~/view-scene/physics";
import { usePlayer } from "~/view-scene/player";
import { EntityContext, PlayerContext, subscribeCollisions, useEntityManager } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { useEntity } from "~/view-scene/utils/useEntity";
import ITeleportSceneObject from "../../../types/ITeleportSceneObject";
import { Entity } from "../Entity";
import { useTeleportObject } from "./useTeleportObject";

type TeleportEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const TeleportEntity = memo(({ entityId, children }: TeleportEntityProps) => {
  const entity = useEntity<ITeleportSceneObject>(entityId);

  const { scene: targetSceneId, targetPosition, alwaysOpen } = entity;

  const { currentSceneUrlName, targetScene } = useSceneData((state) => ({
    currentSceneUrlName: state.sceneState?.urlName,
    targetScene: targetSceneId && state.scenesLibraryLookupMap.get(targetSceneId),
  }));

  const player = usePlayer();

  const cameraMode = useStoreMap($cameraState, (state) => state.cameraMode);

  const gameSessionInProgress = useSessionStatus((state) => state.sessionStatus === "in_progress");

  const teleportState = useRef({
    teleportHappened: false,
    animationTime: 1900,
    animationOriginalTime: 1900,
    finalOpen: false,
    isBlinking: alwaysOpen,
    hasCollision: false,
  });

  const [teleport, setTeleport] = useState<Group | null>(null);

  const context = useRef<EntityContext>({} as EntityContext);
  const physicsManager = usePhysics((state) => state.physicsManager);

  const getEntityContext = useEntityManager((state) => state.getEntityContext);

  useEffect(() => {
    if (!physicsManager || !teleport) {
      return;
    }

    teleport.userData["eightXRId"] = entity.id;

    const body = physicsManager.addBody(
      teleport,
      { entityId, rigidBodyId: "root" },
      {
        type: "static",
        ghost: true,
        shape: {
          type: "auto",
        },
      }
    );
    context.current.physicsBodies["root"] = body;

    context.current.events.on("collisionStart", (payload) => {
      const entityContext = getEntityContext<PlayerContext>(payload);
      if (entityContext?.current && entityContext.current.type === "player") {
        teleportState.current.hasCollision = true;
      }
    });

    context.current.events.on("collisionEnd", (payload) => {
      const entityContext = getEntityContext<PlayerContext>(payload);
      if (entityContext?.current && entityContext.current.type === "player") {
        teleportState.current.hasCollision = false;
      }
    });

    subscribeCollisions(context);

    return () => {
      if (body) {
        physicsManager.removeBody(body.uid);
      }
    };
  }, [physicsManager, teleport]);

  const { teleportObject, beautyMaterial, smGeometry, hiddenGeometry } = useTeleportObject(entity);

  useFrame((state, deltaTime) => {
    if (!gameSessionInProgress || !teleport) {
      return;
    }

    const raycaster = state.raycaster;
    const intersections = raycaster.intersectObject(teleport, true);

    if (teleportState.current.isBlinking && teleportState.current.animationTime > 0) {
      const animationTime =
        teleportState.current.animationTime - deltaTime * 1000 >= 0
          ? teleportState.current.animationTime - deltaTime * 1000
          : 0;
      teleportState.current = { ...teleportState.current, animationTime };

      const animationNormTime =
        (teleportState.current.animationOriginalTime - animationTime) / teleportState.current.animationOriginalTime;
      beautyMaterial.uniforms.animNormTime.value = easeOutQuint(animationNormTime);
    }

    if (
      teleportState.current.isBlinking &&
      teleportState.current.animationTime === 0 &&
      !teleportState.current.finalOpen
    ) {
      //Teleport fully opened
      beautyMaterial.uniforms.animNormTime.value = 0;
      teleport.children.length = 0;
      teleport.add(teleportObject);

      teleportState.current = { ...teleportState.current, finalOpen: true };
    }

    beautyMaterial.uniforms.time.value += deltaTime;

    if (intersections.length === 0) {
      return;
    }

    const firstIntersection = intersections[0];

    const activateDistance = cameraMode === "firstPerson" ? 10 : 10 + PLAYER_HEIGHT;

    if (firstIntersection.distance < activateDistance && !teleportState.current.isBlinking) {
      const model = teleport.children[0];
      if (!model || !(model instanceof Mesh)) {
        return;
      }

      model.geometry = smGeometry;
      model.visible = true;
      model.material = beautyMaterial;

      teleportState.current = { ...teleportState.current, isBlinking: true };
    }

    if (teleportState.current.hasCollision && !teleportState.current.teleportHappened) {
      teleportState.current = { ...teleportState.current, teleportHappened: true };

      if (!targetScene) {
        return;
      }

      const targetSceneUrlName = targetScene.urlName;

      const playerId = player.userData["eightXRId"];
      const playerContext = getEntityContext<PlayerContext>(playerId)?.current;

      if (playerContext) {
        if (currentSceneUrlName === targetSceneUrlName) {
          playerContext.teleport(targetPosition);
          teleportState.current = { ...teleportState.current, teleportHappened: false };
        } else if (targetSceneId) {
          playerContext.teleportToScene(targetSceneId);
        }
      }
    }
  });

  return (
    <Entity entityId={entityId} ref={setTeleport} context={context}>
      <mesh geometry={hiddenGeometry} material={beautyMaterial} visible={false} />
      {children}
    </Entity>
  );
});

function easeOutQuint(x: number) {
  return 1 - Math.pow(1 - x, 5);
}
