import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode, NodeContext } from "../../base";
import { NumberSocket } from "../../sockets";

export class BitwiseOperationNode extends BaseNode {
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";
  static readonly pure = true;

  inputs = [new NumberSocket("a"), new NumberSocket("b")];
  outputs = [new NumberSocket("result")];

  constructor(context: NodeContext, private operation: (a: number, b: number) => number) {
    super(context);
  }

  eval(context: NodeEvalContext) {
    const a = context.readInput<number>("a");
    const b = context.readInput<number>("b");
    context.writeOutput<number>("result", this.operation(a, b));
  }
}
