import { disposeMaterial, useGLTF } from "~/view-scene/utils";
import { useMemo } from "react";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { FrontSide, Mesh, MeshStandardMaterial, Object3D } from "three";

const AVATAR_COLORS = ["#EAEAEA", "#B7D4FF", "#FFDBA5", "#C8F4D7", "#C2F4FF", "#FFE0EF"];

const COLOR_TO_MATERIAL = new Map<string, MeshStandardMaterial>();

export const useLoadAvatar = (url: string) => {
  const { scene: defaultAvatarTemplate } = useGLTF(url);

  return useMemo(() => {
    const avatar = (SkeletonUtils as any).clone(defaultAvatarTemplate);
    if (!url.includes("default")) {
      avatar.userData = { type: "avatar" };
      enableShadowsAndChangeMaterialSide(avatar);
      return avatar;
    }

    const color = getRandomColor();

    const material = getMaterialByColor(color);
    avatar.traverse((obj: Object3D) => {
      if (obj instanceof Mesh) {
        disposeMaterial(obj.material);
        obj.material = material;
      }
    });

    enableShadowsAndChangeMaterialSide(avatar);
    avatar.userData = { type: "avatar" };
    return avatar;
  }, [defaultAvatarTemplate]);
};

function getMaterialByColor(color: string) {
  if (COLOR_TO_MATERIAL.has(color)) {
    return COLOR_TO_MATERIAL.get(color);
  }

  const newMaterial = new MeshStandardMaterial({
    color,
    side: FrontSide,
  });

  COLOR_TO_MATERIAL.set(color, newMaterial);

  return newMaterial;
}

function getRandomColor() {
  return AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)];
}

function enableShadowsAndChangeMaterialSide(object: Object3D) {
  object.traverse((el: Object3D) => {
    if (el instanceof Mesh) {
      el.castShadow = true;
      el.receiveShadow = true;

      const currentMaterial = el.material;
      if (!Array.isArray(currentMaterial)) {
        currentMaterial.side = FrontSide;
      }
    }
  });
}
