import { defaultEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, StringSocket } from "../../sockets";
import { EntityValue } from "../../values";

export class FindChildEntityByTagNode extends BaseNode {
  static readonly type = "entity/findChildEntityByTag";
  static readonly label = "Find Child By Tag";

  inputs = [new EntitySocket("entity"), new StringSocket("tag")];
  outputs = [new EntitySocket("child")];

  initNode(node: BaseBehaveNode) {
    node.storage.entity = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = context.readInput<EntityValue>("entity");
    const tag = context.readInput<string>("tag");
    const entityContext = node.sceneContext
      .getEntityContexts()
      .find((entityContext) => entityContext.tags.has(tag) && entityContext.parentId === entity.entityId);

    if (entityContext) {
      node.storage.entity.entityId = entityContext.id;
    } else {
      node.storage.entity.entityId = null;
    }

    context.writeOutput<Array<EntityValue>>("child", node.storage.entity);
  }
}
