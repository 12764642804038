import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });
export const getExternalAdClicksReport = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/api/ads/report`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};
