import { useThree } from "@react-three/fiber";
import { useUnit } from "effector-react";
import { useEffect } from "react";
import { useUnmount } from "react-use";
import { useProfile } from "~/entities/profile";
import useSessionStatus from "../stores/useSessionStatus";
import { $audioManager, resetAudioSystem } from "./models";
import { useAudioVolume } from "./useAudioVolume";
import { useMuteOnBlur } from "./useMuteOnBlur";

export function AudioSystem() {
  const preferredSpeakerDevice = useProfile((state) => state.profile?.preferredSpeakerDevice);
  const disableSound = useSessionStatus((state) => state.disableSound);

  useMuteOnBlur();
  useAudioVolume();

  const camera = useThree(({ camera }) => camera);

  const { isReady, audioContext, audioListener } = useUnit($audioManager);

  useEffect(() => {
    if (!isReady || !audioListener) {
      return;
    }

    camera.add(audioListener);

    return () => {
      camera.remove(audioListener);
    };
  }, [isReady, audioListener]);

  useEffect(() => {
    if (!isReady || !audioContext) {
      return;
    }

    if (disableSound) {
      audioContext.suspend();
    } else {
      audioContext.resume();
    }
  }, [isReady, audioContext, disableSound]);

  useUnmount(() => {
    resetAudioSystem();
  });

  return null;
}
