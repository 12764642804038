import { Icon, IconProps } from "./Icon";

export type MouseRightProps = IconProps;

export const MouseRight = (props: MouseRightProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M7.333 1.5a4.5 4.5 0 0 0-4.5 4.5v4a4.5 4.5 0 0 0 4.5 4.5h1.334a4.5 4.5 0 0 0 4.5-4.5V6a4.5 4.5 0 0 0-4.5-4.5H7.334Z"
      stroke="currentcolor"
    />
    <path
      d="M12 6a3.333 3.333 0 0 0-3.333-3.333.667.667 0 0 0-.667.666v4.334a1 1 0 0 0 1 1h1.286c.947 0 1.714-.768 1.714-1.715V6Z"
      fill="currentcolor"
    />
  </Icon>
);
