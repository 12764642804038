import { Select, SelectProps, SelectItem } from "./Select";
import { fontFamilies, FontFamily as FontFamilyType } from "~/config";

const items = Object.keys(fontFamilies).map((key) => ({
  value: key,
  label: fontFamilies[key as FontFamilyType].name,
}));

export type FontFamilyProps = Omit<SelectProps, "children">;

export const FontFamily = (props: FontFamilyProps) => {
  return (
    <Select {...props}>
      {items.map((item) => (
        <SelectItem key={item.value} value={item.value}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
};
