import { createEffect } from "effector";
import { AudioContext, AudioListener } from "three";
import isMobile from "~/common/utils/isMobile";
import enableChromeAEC from "../utils/enableChromeAEC";
import { $audioManager, AudioManager } from "./audioManager";

export const initAudioManager = createEffect(async (preferredSpeakerDevice?: string) => {
  const audioListener = new AudioListener();
  const audioContext = AudioContext.getContext();
  const mediaStreamDestinationNode = audioContext.createMediaStreamDestination(); // Voice, Camera, Screenshare
  const audioDestination = audioContext.createChannelMerger(4);
  audioDestination.connect(audioContext.destination);
  const outboundStream = mediaStreamDestinationNode.stream;
  const outboundGainNode = audioContext.createGain();
  const outboundAnalyser = Object.assign(audioContext.createAnalyser(), { fftSize: 32 });

  const analyserLevels = new Uint8Array(outboundAnalyser.fftSize);
  outboundGainNode.connect(outboundAnalyser);
  outboundAnalyser.connect(mediaStreamDestinationNode);

  const audioContextNeedsToBeResumed = false;

  const mixer: NonNullable<AudioManager["mixer"]> = {
    remoteUserAudioSource: audioContext.createGain(),
    mediaVideo: audioContext.createGain(),
    sfx: audioContext.createGain(),
    video360: audioContext.createGain(),
  };

  const mixerAnalyser = Object.assign(audioContext.createAnalyser(), { fftSize: 32 });
  mixer.remoteUserAudioSource.connect(mixerAnalyser);
  mixer.mediaVideo.connect(mixerAnalyser);
  mixer.sfx.connect(mixerAnalyser);
  mixer.video360.connect(mixerAnalyser);

  mixer.remoteUserAudioSource.connect(audioContext.destination);
  mixer.mediaVideo.connect(audioContext.destination);
  mixer.sfx.connect(audioContext.destination);
  mixer.video360.connect(audioContext.destination);
  audioDestination.connect(audioContext.destination);

  if (preferredSpeakerDevice && preferredSpeakerDevice !== "default") {
    try {
      // @ts-ignore
      audioContext.setSinkId(preferredSpeakerDevice);
    } catch (err) {
      console.log("setSinkId is not available");
    }
  }

  await audioContext.resume();

  if (!isMobile() && /chrome/i.test(navigator.userAgent)) {
    await enableChromeAEC(audioListener.gain);
  }

  return {
    isReady: true,
    audioListener,
    audioContext,
    mediaStreamDestinationNode,
    audioDestination,
    outboundStream,
    outboundGainNode,
    outboundAnalyser,
    analyserLevels,
    audioContextNeedsToBeResumed,
    mixer,
    mixerAnalyser,
  };
});

$audioManager.on(initAudioManager.doneData, (state, initData) => ({ ...state, ...initData }));
