import { Mesh, PlaneGeometry } from "three";

const geometry = new PlaneGeometry();

/**
 * Returns a basic plane mesh.
 */
export default class Frame extends Mesh {
  constructor(material) {
    super(geometry, material);

    this.castShadow = false;
    this.receiveShadow = false;

    this.name = "MeshUI-Frame";
  }
}
