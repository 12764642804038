import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { uiValue, UIValue } from "../values";

export class UISocket extends BaseSocket {
  constructor(id: string, label: string = id, value: UIValue = defaultEntity()) {
    super(uiValue.name, id, label, value);
  }

  clone() {
    return new UISocket(this.id, this.label, this.value);
  }
}
