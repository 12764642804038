import React, { ReactNode, useEffect } from "react";
import { useReconnectingSocket } from "./useReconnectingSocket";
import { useWebRTC } from "./useWebRTC";
import useSessionStatus from "../stores/useSessionStatus";
import { useSceneSocketSession } from "./useSceneSocketSession";
import { useProfile } from "~/entities/profile";
import { NetworkSystemCtx } from "./NetworkSystemCtx";
import { useCurrentMedia, useMediaState } from "~/view-scene/media";
import { useHandleRemoteCollisions } from "./useHandleRemoteCollisions";
import { useHandleRemoteVideoEntitiesUpdates } from "./useHandleRemoteVideoEntitiesUpdates";
import { useHandleRemoteScreenShareEntitiesUpdate } from "./useHandleRemoteScreenShareEntitiesUpdate";
import { useUnmount } from "react-use";
import { useSceneData } from "~/common/stores/useSceneData";

type NetworkSystemProps = {
  children?: ReactNode;
};

const sessionStartedStatuses = ["in_progress", "onpause"];

export const NetworkSystem = ({ children }: NetworkSystemProps) => {
  const enabledMultiplayer = useSceneData((state) => state.sceneState?.networkSystem.enableMultiplayer ?? false);
  const voiceChatActive = useMediaState((state) => state.voiceChatActive);
  const { micStream: microphoneStream } = useCurrentMedia();

  const isAuthorised = useProfile((state) => state.isAuthorised());

  const { isConnected, socket, connect, send, disconnect } = useReconnectingSocket();

  const { isSocketSessionEstablished, joinSceneSocketSession, cleanupSceneSocketSession } =
    useSceneSocketSession(socket);

  const { joinVoiceConversation, leaveVoiceConversation } = useWebRTC(socket);

  const isGameSessionStarted = useSessionStatus((state) => sessionStartedStatuses.includes(state.sessionStatus));

  useEffect(() => {
    if (!isAuthorised || !enabledMultiplayer) {
      return;
    }

    connect();
    return () => disconnect();
  }, [isAuthorised, enabledMultiplayer]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    joinSceneSocketSession();
    return () => cleanupSceneSocketSession();
  }, [isConnected]);

  useEffect(() => {
    if (!isConnected || !isSocketSessionEstablished || !isGameSessionStarted || !voiceChatActive || !microphoneStream) {
      return;
    }
    joinVoiceConversation();

    return () => {
      leaveVoiceConversation();
    };
  }, [microphoneStream, isConnected, isSocketSessionEstablished, isGameSessionStarted, voiceChatActive]);

  const subscribeToRemoteUpdates = isConnected && isSocketSessionEstablished && isGameSessionStarted;

  useHandleRemoteCollisions(subscribeToRemoteUpdates);
  useHandleRemoteVideoEntitiesUpdates(subscribeToRemoteUpdates);
  useHandleRemoteScreenShareEntitiesUpdate(subscribeToRemoteUpdates);

  useUnmount(() => {
    leaveVoiceConversation();
    cleanupSceneSocketSession();
    disconnect();
  });

  return (
    <NetworkSystemCtx.Provider value={{ sessionId: socket?.id, isConnected, send }}>
      {children}
    </NetworkSystemCtx.Provider>
  );
};
