import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, FlowSocket } from "../../sockets";
import { AnimationComponentContext } from "~/view-scene/runtime";

export class AnimationPauseNode extends BaseNode {
  static readonly type = "action/animationPause";
  static readonly label = "Pause animation";

  inputs = [new FlowSocket("flow"), new AnimationSocket("animation")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<AnimationComponentContext>(context, "animation")?.pause();
  }
}
