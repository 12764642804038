import { MutableRefObject, useEffect } from "react";
import { PLAYER_HEIGHT } from "~/config";
import { ActivationState } from "~/types/IRigidBodyComponent";
import { collisionGroup, usePhysics } from "~/view-scene/physics";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { playerId } from "./constants";

const CAPSULE_RADIUS = 0.3;

export function useAddPlayerPhysics(contextRef: MutableRefObject<PlayerContext | null>) {
  const player = usePlayer();
  const sessionHasBeenStarted = useSessionStatus(
    (state) => state.sessionStatus === "in_progress" || state.sessionStatus === "onpause"
  );

  const physicsManager = usePhysics((state) => state.physicsManager);

  useEffect(() => {
    if (!physicsManager || !sessionHasBeenStarted || !contextRef.current) {
      return;
    }

    contextRef.current.waitForPositioning().then(() => {
      const body = physicsManager.addBody(
        player,
        { entityId: playerId, rigidBodyId: "root" },
        {
          type: "dynamic",
          activationState: ActivationState.DISABLE_DEACTIVATION,
          shape: {
            type: "capsule",
            radius: CAPSULE_RADIUS,
            height: PLAYER_HEIGHT - 2 * CAPSULE_RADIUS,
          },
          collisionGroup: collisionGroup.player,
        }
      );

      body.setFriction(0.8);
      body.setAngularFactor(0, 0, 0);
      body.setCcdMotionThreshold(1e-7);
      body.setCcdSweptSphereRadius(CAPSULE_RADIUS);

      contextRef.current!.physicsBodies["root"] = body;
    });
  }, [sessionHasBeenStarted, physicsManager]);
}
