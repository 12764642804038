import { styled, ButtonBase, ButtonBaseProps, Box } from "@mui/material";
import { memo, useCallback } from "react";
import { palette } from "~/theme";

export type AnchorProps = {
  value?: AnchorValue;
  onChange?: (value: AnchorValue) => void;
};

export type AnchorValue = {
  horizontal: "left" | "center" | "right";
  vertical: "top" | "middle" | "bottom";
};

const items: AnchorValue[] = [
  { horizontal: "left", vertical: "top" },
  { horizontal: "center", vertical: "top" },
  { horizontal: "right", vertical: "top" },
  { horizontal: "left", vertical: "middle" },
  { horizontal: "center", vertical: "middle" },
  { horizontal: "right", vertical: "middle" },
  { horizontal: "left", vertical: "bottom" },
  { horizontal: "center", vertical: "bottom" },
  { horizontal: "right", vertical: "bottom" },
];

export const Anchor = memo(({ value, onChange, ...props }: AnchorProps) => {
  const handleButtonClick: NonNullable<ButtonBaseProps["onClick"]> = useCallback(
    (e) => {
      const jsonValue = (e.target as HTMLElement).getAttribute("data-value");

      if (!jsonValue) {
        return;
      }

      const value = JSON.parse(jsonValue) as AnchorValue;
      onChange?.(value);
    },
    [onChange]
  );
  const valueJSON = JSON.stringify(value);

  return (
    <Container>
      {items.map((item, index) => {
        const itemJSON = JSON.stringify(item);

        return (
          <AnchorButton
            selected={valueJSON === itemJSON}
            data-value={itemJSON}
            key={index}
            onClick={handleButtonClick}
          />
        );
      })}
    </Container>
  );
});

const Container = styled(Box)`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  gap: 4px;
  padding: 2px;
  background: ${palette.primary.gray100};
  border-radius: 4px;
  border: 1px solid ${palette.primary.gray100};
`;

const activeStyles = {
  backgroundColor: palette.primary.violet,
  width: "8px",
  height: "8px",
};

const inactiveStyles = {
  backgroundColor: palette.primary.gray400,
  width: "2px",
  height: "2px",
};

type AnchorButtonProps = {
  selected: boolean;
};

const AnchorButton = styled(ButtonBase)<AnchorButtonProps>(({ selected }) => ({
  width: "18px",
  height: "18px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",

  "&:hover": {
    backgroundColor: palette.primary.gray200,
  },

  "&:after": {
    content: '""',
    borderRadius: "3px",
    ...(selected ? activeStyles : inactiveStyles),
  },
}));
