import { NodeEvalContext } from "~/libs/behave-graph";
import { UIEntity } from "~/types/ui";
import { UIEntityContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, UIEntitySocket } from "../../sockets";
import { UIEntityValue } from "../../values";

export class UIEntitySetVisibilityNode extends BaseNode {
  static readonly type = "action/uiEntitySetVisibility";
  static readonly label = "UI Entity Set Enabled";

  inputs = [new FlowSocket("flow"), new UIEntitySocket("uiEntity"), new BooleanSocket("visible", "enabled")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const visible = context.readInput<boolean>("visible");
    const entityValue = context.readInput<UIEntityValue>("uiEntity");

    node.sceneContext.getUIEntityContext<UIEntityContext>(entityValue.entityId ?? "")?.updateEntity<UIEntity>({
      enabled: visible,
    });
  }
}
