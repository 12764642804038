import { Stack, styled } from "@mui/material";
import { useUnit } from "effector-react";
import { useEffect, useMemo, useRef } from "react";
import { setAccessToken } from "~/api/accessToken";
import { ExternalGameName, getExternalGameConfig } from "~/config";
import { useProfile } from "~/entities/profile";
import { palette } from "~/theme";
import { disableWarnings } from "~/view-scene/utils";
import { $loadingStatus, LoadingScreenWithAd } from "../LoadingScreenWithAd";
import "./ExternalGame.scss";
import { useMultiplayer } from "./multiplayer";
import { useHandleSendScore } from "./useHandleSendScore";
import { useNetworkSystem } from "./useNetworkSystem";
import { useIframeUrl } from "./useIframeUrl";

type ExternalGameProps = {
  urlName: string;
  gameId?: string;
  accessToken: string;
  showAddLoadingScreen?: boolean;
  forceFullHeight?: boolean;
};

export function ExternalGame({
  urlName,
  gameId,
  accessToken,
  showAddLoadingScreen = false,
  forceFullHeight = false,
}: ExternalGameProps) {
  const loadProfile = useProfile((state) => state.loadProfile);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const externalGameConfig = useMemo(() => getExternalGameConfig(urlName as ExternalGameName), [urlName]);

  useEffect(() => {
    disableWarnings();

    setAccessToken(accessToken);

    loadProfile();

    // disableScroll
    document.body.classList.add("ExternalGame-RemoveScroll");
    document.addEventListener("touchmove", preventDefault);

    // disable pinch zoom
    document.addEventListener("gesturestart", preventDefault);
    document.addEventListener("gesturechange", preventDefault);
    document.addEventListener("gestureend", preventDefault);

    return () => {
      document.body.classList.remove("ExternalGame-RemoveScroll");
      document.removeEventListener("touchmove", preventDefault);
      document.removeEventListener("gesturestart", preventDefault);
      document.removeEventListener("gesturechange", preventDefault);
      document.removeEventListener("gestureend", preventDefault);
    };
  }, []);

  useHandleSendScore(gameId);
  const { socket, isConnected } = useNetworkSystem({
    sceneId: externalGameConfig.sceneId,
  });
  useMultiplayer({
    socket,
    connected: isConnected,
    enabled: externalGameConfig.multiplayer ?? false,
    iframeRef,
  });

  const loadingStatus = useUnit($loadingStatus);
  const iframeUrl = useIframeUrl(externalGameConfig.path);
  const isReady = isConnected || !externalGameConfig.multiplayer;

  return (
    <>
      {showAddLoadingScreen && loadingStatus === "loading" && <LoadingScreenWithAd gameId={urlName} />}

      <GameContainer
        id="game_container"
        sx={{
          height:
            !forceFullHeight && ["endlessSiege", "endlessSiege2", "endlessSiegeGamee"].includes(urlName)
              ? "97vh"
              : "100vh",
        }}
      >
        {isReady && iframeUrl && <StyledIframe ref={iframeRef} id="game_iframe" src={iframeUrl} />}
      </GameContainer>
    </>
  );
}

const GameContainer = styled(Stack)`
  height: 100vh;
  width: 100vw;
  pointer-events: none;
`;

const StyledIframe = styled("iframe")`
  border: none;
  background-color: ${palette.primary.black800};
  min-width: 100%;
  min-height: 100%;
  pointer-events: all;
`;

const preventDefault = (e: Event) => {
  e.returnValue = false;
  e.preventDefault();
  e.stopImmediatePropagation();
};
