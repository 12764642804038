import { Box, ThemeProvider, styled } from "@mui/material";
import Muuri from "muuri";
import { useEffect, useRef, useState } from "react";
import { games } from "~/common/data/games";
import { gamerTheme } from "~/theme";
import { Footer } from "./Footer";
import { Grid } from "./Grid";
import { Header } from "./Header";
import { HomePageContainer } from "./HomePageContainer";
import { calculateGridWidth } from "./utils";

export const HomePage = () => {
  const [gridWidth, setGridWidth] = useState(calculateGridWidth());
  const muuriRef = useRef<Muuri | null>(null);

  useEffect(() => {
    const handler = () => {
      setGridWidth(calculateGridWidth());
    };

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return (
    <ThemeProvider theme={gamerTheme}>
      <HomePageContainer>
        <Header />
        <GridContainer style={{ width: gridWidth }}>
          <StyledGrid games={games} muuriRef={muuriRef} />
        </GridContainer>
        <Footer sx={{ marginTop: "8px" }} />
      </HomePageContainer>
    </ThemeProvider>
  );
};

const GridContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-self: center;
`;

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;
