import { forwardRef, memo, ReactNode } from "react";
import { Object3D } from "three";
import { useSceneData } from "~/common/stores/useSceneData";
import IModelSceneObject from "~/types/IModelSceneObject";
import { Entity } from "../Entity";
import { useContext } from "./hooks";
import { RenderModel } from "./RenderModel";

type ModelProps = {
  entity: IModelSceneObject;
  children?: ReactNode;
};

export const Model = memo(
  forwardRef<Object3D, ModelProps>(({ entity, children }, ref) => {
    const { dto, contextRef } = useContext(entity);
    const assetId = dto.asset;
    const getModelUrl = useSceneData((state) => state.getModelUrl);

    const url = getModelUrl(assetId, dto.variantId);

    return (
      <Entity entityId={entity.id} context={contextRef} ref={ref}>
        {url && <RenderModel dto={dto} url={url} />}
        {children}
      </Entity>
    );
  })
);
