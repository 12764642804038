import { createEvent, createStore } from "effector";

export const completeUISetup = createEvent();

type ThreeMeshUIStore = {
  isReady: boolean;
};

export const $uiSystem = createStore<ThreeMeshUIStore>({
  isReady: false,
}).on(completeUISetup, (state) => ({ ...state, isReady: true }));

export const updateAspectRatio = createEvent<[number, number, number]>();
export const $aspectRatio = createStore<[number, number, number]>([1, 1, 1]).on(
  updateAspectRatio,
  (_, payload) => payload
);
