import { useEffect } from "react";
import { remoteScreenShareEntitiesUpdate } from "./models";
import { useSceneData } from "~/common/stores/useSceneData";
import IScreenShareSceneObject from "~/types/IScreenShareSceneObject";
import { ScreenShareEntityState } from "./types";

export function useHandleRemoteScreenShareEntitiesUpdate(subscribeToRemoteUpdates: boolean) {
  useEffect(() => {
    if (!subscribeToRemoteUpdates) {
      return;
    }

    const subscription = remoteScreenShareEntitiesUpdate.watch((screenShareEntities) => {
      screenShareEntities.forEach((remoteState) => handleRemoteScreenShareEntityUpdate(remoteState));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribeToRemoteUpdates]);
}

function handleRemoteScreenShareEntityUpdate(remoteState: ScreenShareEntityState) {
  const sceneData = useSceneData.getState();
  const entity = sceneData.getEntity<IScreenShareSceneObject>(remoteState.entityId);
  if (!entity) {
    return;
  }

  if (entity.remoteUserID !== remoteState.remoteUserId) {
    sceneData.updateEntity(remoteState.entityId, {
      ...entity,
      remoteUserID: remoteState.remoteUserId,
    });
  }
}
