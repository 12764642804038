const vertexShaderSource = `#version 300 es
layout(std140, column_major) uniform;
layout(location=0) in vec4 position;
uniform mat4 uModel;
uniform mat4 uViewProj;
void main() {
  gl_Position = uViewProj * uModel * position;
}
`;

const fragmentShaderSource = `#version 300 es
precision highp float;
layout(std140, column_major) uniform;
out vec4 fragColor;
uniform vec4 uColor;

void main() {
  fragColor = uColor;
}
`;

export const initShader = (gl: WebGL2RenderingContext) => {
  const program = initProgram(gl);
  const uniforms = initUniforms(gl, program);

  return {
    program,
    uniforms,
  };
};

const initProgram = (gl: WebGL2RenderingContext) => {
  const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
  gl.shaderSource(vertexShader, vertexShaderSource);
  gl.compileShader(vertexShader);

  if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(vertexShader));
  }

  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
  gl.shaderSource(fragmentShader, fragmentShaderSource);
  gl.compileShader(fragmentShader);

  if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(fragmentShader));
  }

  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
  }

  return program;
};

const initUniforms = (gl: WebGL2RenderingContext, shaderProgram: WebGLProgram) => {
  return {
    modelMatrixLocation: gl.getUniformLocation(shaderProgram, "uModel"),
    viewProjMatrixLocation: gl.getUniformLocation(shaderProgram, "uViewProj"),
    colorLocation: gl.getUniformLocation(shaderProgram, "uColor"),
  };
};
