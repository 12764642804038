import { Mesh, MeshBasicMaterial, Object3D } from "three";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { useGLTF } from "~/view-scene/utils";
import IModelAsset from "~/types/IModelAsset";
import { getVariantUrl, useAsset } from "~/entities/assets";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";

type RenderScreenSharePreviewWithCustomGeometryProps = {
  geometryAsset: string;
  screenShareMaterial: MeshBasicMaterial;
};

export const RenderScreenSharePreviewWithCustomGeometry = forwardRef(
  (
    { geometryAsset, screenShareMaterial }: RenderScreenSharePreviewWithCustomGeometryProps,
    ref: ForwardedRef<Mesh>
  ) => {
    const modelAsset = useAsset<IModelAsset>(geometryAsset)!;
    const defaultVariant = getVariantUrl(modelAsset);

    const { scene } = useGLTF(defaultVariant);

    const mesh: Mesh | null = useMemo(() => {
      const model = (SkeletonUtils as any).clone(scene);

      let foundMesh: Mesh | null = null;

      if (model instanceof Mesh) {
        foundMesh = model.geometry;
      } else {
        model.traverse((child: Object3D) => {
          if (foundMesh) {
            return;
          }

          if (child instanceof Mesh) {
            foundMesh = child;
          }
        });
      }

      if (foundMesh) {
        foundMesh.material = screenShareMaterial;
      }

      return foundMesh;
    }, [scene]);

    if (!mesh) {
      return null;
    }

    return <primitive object={mesh} ref={ref} />;
  }
);
