import { createEvent, createStore } from "effector";

export type TemplatesModalState = {
  open: boolean;
};

export const $templatesModal = createStore<TemplatesModalState>({
  open: false,
});

export const openTemplatesModal = createEvent();
export const closeTemplatesModal = createEvent();

$templatesModal
  .on(openTemplatesModal, () => ({ open: true }))
  .on(closeTemplatesModal, () => ({
    open: false,
  }));
