import { useStore } from "effector-react";
import { memo, ReactNode, Suspense, useState } from "react";
import { UIEntity } from "~/types/ui";
import { useEntityState } from "~/view-scene/runtime";
import { ErrorBoundary } from "~/view-scene/utils";
import { $uiSystem } from "../models";
import { uiSystemEvents } from "../UISystemSetup";
import { RenderUIElementSwitch } from "./RenderUIElementSwitch";
import { useUIEntitiesHierarchy } from "./UIEntitiesContext";
import { useUpdateEffect } from "react-use";

export type RenderUIElementProps = {
  entityId: string;
  aspect?: [number, number, number];
  children?: ReactNode;
  rerenderParent?: VoidFunction;
};

const defaultAspect = [1, 1, 1] as [number, number, number];

export const RenderUIElement = memo(({ entityId, aspect = defaultAspect, rerenderParent }: RenderUIElementProps) => {
  const uiSystem = useStore($uiSystem);
  const entity = useEntityState<UIEntity>(entityId)!;
  const hierarchy = useUIEntitiesHierarchy();

  const [resetKey, setResetKey] = useState(0);

  const rerender = () => setResetKey((state) => ++state);

  useUpdateEffect(() => {
    if (!entity) {
      return;
    }

    rerenderParent?.();
  }, [entity?.enabled]);

  if (!uiSystem.isReady || !entity) {
    return null;
  }

  const handleClick = entity.onPressEnabled ? () => uiSystemEvents.emit("press", { entityId }) : undefined;

  const children = (hierarchy[entityId] ?? []).map((childId) => (
    <RenderUIElement key={childId + resetKey} entityId={childId} aspect={aspect} rerenderParent={rerender} />
  ));

  return (
    <ErrorBoundary message={`Failed rendering UIEntity[${entity.type}] with id=${entity.id} and name=${entity.name}`}>
      <Suspense fallback={null}>
        <RenderUIElementSwitch entity={entity} aspect={aspect} onClick={handleClick}>
          {children}
        </RenderUIElementSwitch>
      </Suspense>
    </ErrorBoundary>
  );
});
