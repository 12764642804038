import { useUnit } from "effector-react";
import { useEffect, useRef } from "react";
import { useUnmount } from "react-use";
import { useFrame } from "@react-three/fiber";
import { $sceneMode } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { BaseControls } from "./baseControls";
import { playerControls } from "./playerControls";
import { video360Controls } from "./video360Controls";
import { cutsceneControls } from "./cutsceneControls";
import { useFlags } from "~/entities/flags";
import { debugControls } from "./debugControls";
import { userActions } from "./userActions";

export const ControlsSystem = () => {
  const { sceneConsole } = useFlags();
  const sceneMode = useUnit($sceneMode);
  const currentControlsRef = useRef<BaseControls | null>(null);
  const sessionStatus = useSessionStatus((state) => state.sessionStatus);
  const sessionInProgress = sessionStatus === "in_progress";

  useEffect(() => {
    let controls: BaseControls | null = null;

    if (sessionInProgress) {
      switch (sceneMode) {
        case "scene":
          controls = playerControls;
          break;
        case "video360":
          controls = video360Controls;
          break;
        case "cutscene":
          controls = cutsceneControls;
          break;
      }
    }

    currentControlsRef.current = controls;

    if (controls) {
      controls.activate();

      return () => {
        controls?.deactivate();
      };
    }
  }, [sceneMode, sessionInProgress]);

  useEffect(() => {
    if (sceneConsole) {
      debugControls.activate();

      return () => {
        debugControls.deactivate();
      };
    }
  }, [sceneConsole]);

  useUnmount(() => {
    userActions.reset();
    playerControls.reset();
    video360Controls.reset();
    cutsceneControls.reset();
    debugControls.reset();
  });

  useFrame(() => {
    currentControlsRef.current?.update();
  });

  return null;
};
