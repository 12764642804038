import { useState } from "react";
import { io, Socket } from "socket.io-client";
import { API_HOST } from "~/config";
import { getAuthHeader } from "~/api/getAuthHeader";

export function useReconnectingSocket() {
  const [socket, setSocket] = useState<Socket>();
  const [isConnected, setIsConnected] = useState(false);

  const connect = () => {
    const authHeader = getAuthHeader() ?? {};

    const socket = io(API_HOST, {
      withCredentials: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 1000,
      transports: ["websocket"],
      extraHeaders: authHeader,
    });

    setSocket(socket);

    socket.on("connect", () => setIsConnected(true));
    socket.on("disconnect", () => setIsConnected(false));

    return socket;
  };

  const disconnect = () => {
    if (socket?.connected) {
      socket.removeAllListeners();
      socket.disconnect();

      setSocket(undefined);
      setIsConnected(false);
    }
  };

  const send = (event: string, msg: any) => {
    if (!socket || socket.disconnected) {
      console.warn("Trying to send a network message without a connection established.");
      return;
    }

    socket.emit(event, msg);
  };

  return { isConnected, socket, connect, disconnect, send };
}
