import { FC } from "react";
import { IconProps, Monitor, Phone, Render, VR } from "~/common/components/Icon";
import { PartialRecord } from "~/common/typeUtils";
import { DeviceMode } from "~/types/DeviceMode";

export type DeviceIconProps = IconProps & {
  type: DeviceMode;
};

export const DeviceIcon = ({ type, ...rest }: DeviceIconProps) => {
  const Component = componentsMap[type] ?? Render;

  return <Component {...rest} />;
};

const componentsMap: PartialRecord<DeviceMode, FC<IconProps>> = {
  desktop: Monitor,
  mobile: Phone,
  vr: VR,
};
