import { useMemo } from "react";
import { useEntity } from "~/entities/entities";
import { useAsset } from "~/entities/assets";
import { UIAsset } from "~/types/ui";
import { Select, SelectItem, SelectProps } from "./Select";

export type SceneUIEntitySelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  entityId?: string | null;
  emptyLabel?: string;
};

export const SceneUIEntitySelect = ({
  value,
  entityId,
  emptyLabel = "Select UI Entity",
  ...rest
}: SceneUIEntitySelectProps) => {
  const entity = useEntity(entityId);
  const asset = useAsset<UIAsset>(entity?.asset);

  const items = useMemo(() => {
    if (!asset) {
      return [];
    }

    return asset.entities.map((entity) => ({
      id: entity.id,
      label: entity.name,
    }));
  }, [asset]);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {items.map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
};
