import { defaultVector3 } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";
import { setPosition } from "./utils";

export class AddPositionNode extends BaseNode {
  static readonly type = "action/addPosition";
  static readonly label = "Add position";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new Vector3Socket("diff")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode<{ position: Vector3Value }>) {
    node.storage.position = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode<{ position: Vector3Value }>) {
    const entity = node.readEntity(context, "entity");
    const diff = context.readInput<Vector3Value>("diff");

    if (!entity) {
      return;
    }

    const currPosition = entity.rootObjectRef.current?.position;

    if (currPosition) {
      node.storage.position.x = currPosition.x + diff.x;
      node.storage.position.y = currPosition.y + diff.y;
      node.storage.position.z = currPosition.z + diff.z;

      setPosition(entity, node.storage.position);
    }
  }
}
