import { defaultAsset } from "~/entities/variable";
import { BaseSocket } from "../base";
import { assetValue, AssetValue } from "../values";

export class AssetSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AssetValue = defaultAsset()) {
    super(assetValue.name, id, label, value);
  }

  clone() {
    return new AssetSocket(this.id, this.label, this.value);
  }
}
