import { XR } from "@react-three/xr";
import { ReactNode } from "react";
import IScene from "~/types/IScene";
import { VRSource } from "../ControlsSource";
import { PlayerProvider } from "../player";

export type VRContextProps = {
  scene: IScene;
  children?: ReactNode;
};

export const VRContext = ({ children }: VRContextProps) => {
  return (
    <XR foveation={0.1}>
      <PlayerProvider xr={true}>
        {children}
        <VRSource />
      </PlayerProvider>
    </XR>
  );
};
