import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberAcosNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberAcos";
  static readonly label = "Arccos";

  constructor(context: NodeContext) {
    super(context, (value) => Math.acos(value));
  }
}
