import { useMemo } from "react";
import { AnimationAction, AnimationMixer } from "three";
import { useGLTF } from "~/view-scene/utils";

export type EmotionConfig = {
  mixer: AnimationMixer;
  animationUrl: string;
  animationClipName: string;
};

export const useEmotion = ({ mixer, animationUrl, animationClipName }: EmotionConfig): AnimationAction => {
  const { animations } = useGLTF(animationUrl);

  return useMemo(() => {
    const clip = animations.find((clip) => clip.name === animationClipName);

    if (!clip) {
      throw new Error(`useEmotion: Animation clip "${animationClipName}" not found in "${animationUrl}"`);
    }

    return mixer.clipAction(clip);
  }, [mixer, animations, animationClipName]);
};
