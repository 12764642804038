import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, SocketsArray } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class MultiGateNode extends BaseNode {
  static readonly type = "flow/multiGate";
  static readonly label = "Multi gate";
  static category: NodeCategory = "Flow";

  inputs = [new FlowSocket("flow"), new NumberSocket("index")];
  outputs = [
    new FlowSocket("flow", "Out 0"),
    new SocketsArray({
      id: "flows",
      SocketConstructor: FlowSocket,
      maxCount: 20,
      nameGenerator: (_id, index) => `Out ${index + 1}`,
    }),
    new FlowSocket("fail", "Fail"),
  ];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const index = context.readInput<number>("index");
    const sockets = node.outputSockets;

    if (index >= 0 && index < sockets.length - 1) {
      context.commit(sockets[index].id);
    } else {
      context.commit("fail");
    }
  }
}
