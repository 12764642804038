import { keyBy } from "lodash-es";
import { Hierarchical } from "~/types/Hierarchical";

export const renewParentBranch = <TType extends Hierarchical>(entityId: string, entities: TType[]) => {
  const entitiesMap = keyBy(entities, "id");

  const entitiesToUpdate = new Set<string>();
  let entity: TType | null = entitiesMap[entityId];

  while (entity) {
    if (entity.parentId) {
      entitiesToUpdate.add(entity.parentId);
      entity = entitiesMap[entity.parentId];
    } else {
      entity = null;
    }
  }

  return entities.map((entity) => (entitiesToUpdate.has(entity.id) ? { ...entity } : entity));
};
