import { memo, RefObject } from "react";
import { Group } from "three";
import { SpriteAnimationComponent as SpriteAnimationComponentDescriptor } from "~/types/component";
import type { EntityContext } from "~/view-scene/runtime";
import { SpriteContext } from "~/view-scene/runtime";
import { RenderSpriteAnimation } from "./RenderSpriteAnimation";

type AnimationComponentProps = {
  componentDto: SpriteAnimationComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const SpriteAnimationComponent = memo(({ componentDto, objectRef, contextRef }: AnimationComponentProps) => {
  const entityContext = contextRef.current as SpriteContext | undefined;
  if (!entityContext) {
    return null;
  }

  return <RenderSpriteAnimation component={componentDto} objectRef={objectRef} contextRef={contextRef} />;
});
