import { Icon, IconProps } from "./Icon";

export type ClipProps = IconProps;

export const Clip = (props: ClipProps) => (
  <Icon width="10px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.667 3.504V11a3.667 3.667 0 1 1-7.334 0V3.778a2.444 2.444 0 1 1 4.89 0v7.186a1.222 1.222 0 0 1-2.445 0v-6.53"
    />
  </Icon>
);
