import { Node, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { ComponentContext, EntityContext } from "~/view-scene/runtime";
import { ScriptContext } from "../types";
import type { SceneContext } from "../useSceneContext";
import { ComponentValue, EntityValue } from "../values";
import { BaseSocket } from "./BaseSocket";

export interface BaseBehaveNode {
  inputSockets: BaseSocket[];
  outputSockets: BaseSocket[];
}

export class BaseBehaveNode<TStorage extends Storage = Storage> extends Node {
  scriptContext!: ScriptContext;
  sceneContext!: SceneContext;
  spec!: NodeSpec;

  storage!: TStorage;
  tickable = false;

  private _connectedInputs: Set<string> | null = null;
  private _connectedOutputs: Set<string> | null = null;

  get connectedInputs() {
    if (this._connectedInputs === null) {
      const set = new Set<string>();

      this.inputSockets.forEach((socket) => {
        if (socket.links.length > 0) {
          set.add(socket.name);
        }
      });

      this._connectedInputs = set;
    }

    return this._connectedInputs;
  }

  get connectedOutputs() {
    if (this._connectedOutputs === null) {
      const set = new Set<string>();

      this.outputSockets.forEach((socket) => {
        if (socket.links.length > 0) {
          set.add(socket.name);
        }
      });

      this._connectedOutputs = set;
    }

    return this._connectedOutputs;
  }

  inputConnected(socketName: string) {
    return this.connectedInputs.has(socketName);
  }

  outputConnected(socketName: string) {
    return this.connectedOutputs.has(socketName);
  }

  readEntity<TEntity extends EntityContext>(context: NodeEvalContext, socketName: string) {
    const entityValue = context.readInput<EntityValue>(socketName);

    if (!entityValue.entityId) {
      return null;
    }

    return this.sceneContext.getEntityContext<TEntity>(entityValue.entityId);
  }

  readComponent<TComponent extends ComponentContext>(context: NodeEvalContext, socketName: string) {
    const component = context.readInput<ComponentValue>(socketName);

    if (!component.entityId || !component.componentId) {
      return null;
    }

    return this.sceneContext.getComponentContext<TComponent>(component.entityId, component.componentId);
  }

  readMetadata<TType = any>(key: string, defaultValue: TType): TType {
    return (this.metadata[key] as any as TType) ?? defaultValue;
  }
}

type Storage = Record<string, any>;
