import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode, NodeContext } from "../../base";
import { BooleanSocket } from "../../sockets";

export class BooleanOperationNode extends BaseNode {
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";
  static readonly pure = true;

  inputs = [new BooleanSocket("a"), new BooleanSocket("b")];
  outputs = [new BooleanSocket("result")];

  constructor(context: NodeContext, private operation: (a: boolean, b: boolean) => boolean) {
    super(context);
  }

  eval(context: NodeEvalContext) {
    const a = context.readInput<boolean>("a");
    const b = context.readInput<boolean>("b");
    context.writeOutput<boolean>("result", this.operation(a, b));
  }
}
