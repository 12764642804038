import { Text } from "@react-three/drei";
import { forwardRef, Suspense } from "react";
import ITextSceneObject from "~/types/ITextSceneObject";
import { getDefaultFontUrl, getFontUrl } from "./utils";

export type RenderTextProps = {
  entity: ITextSceneObject;
};
export const RenderText = forwardRef<any, RenderTextProps>(({ entity }, ref) => {
  const profileLanguage = "en";

  const {
    isStatic,
    fontFamily,
    fontWeight = "bold",
    color = 0x000000,
    colorOpacity,
    stroke,
    strokeOpacity,
    strokeWidth,
    fontSize = 0.15,
    multilingualTexts = [],
    textAlign,
    lineHeight,
    letterSpacing,
    anchorX,
    anchorY,
  } = entity;

  const multilingualText = multilingualTexts.find((text) => text.languageCode === profileLanguage);
  const text = multilingualText?.text ?? "";
  const font = getFontUrl(fontFamily, fontWeight) ?? getDefaultFontUrl();

  return (
    <Suspense fallback={null}>
      <Text
        font={font}
        fontSize={fontSize}
        color={color}
        fillOpacity={colorOpacity}
        strokeColor={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={strokeWidth}
        textAlign={textAlign}
        lineHeight={lineHeight ?? undefined}
        letterSpacing={letterSpacing}
        anchorX={anchorX}
        anchorY={anchorY}
        matrixAutoUpdate={!isStatic}
        ref={ref}
      >
        {text}
      </Text>
    </Suspense>
  );
});
