import { memo, RefObject } from "react";
import { Group } from "three";
import IViewInARComponent from "~/types/IViewInARComponent";
import { useOpenLinkHelper } from "~/view-scene/OpenLinkHelper/useOpenLinkHelper";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";

type ViewInARComponentProps = {
  componentDto: IViewInARComponent;
  objectRef: RefObject<Group>;
};

export const ViewInARComponent = memo(({ componentDto, objectRef }: ViewInARComponentProps) => {
  const { url } = componentDto;

  const mode = useSessionStatus((state) => state.mode);

  const openLinkHelper = useOpenLinkHelper((state) => state.openLinkHelper);

  const handleViewAction = () => {
    if (!url) {
      return;
    }

    openLinkHelper("Preview in AR", `Do you want to preview this object in AR in your room?`, url);
  };

  if (mode !== "mobile") {
    return null;
  }

  return <ActionIcon visibleDistance={10} onActionButtonReleased={handleViewAction} objectRef={objectRef} />;
});
