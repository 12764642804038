import { useEffect, useMemo, useRef } from "react";
import { sendScore, sendTWAScore, updateScore } from "~/api/telegram.api";
import { v4 as uuid } from "uuid";

export function useHandleSendScore(gameId?: string) {
  const scoreIdTracker = useMemo(() => uuid().toString(), []);
  const differedSendScoreValueRef = useRef(-1);

  useEffect(() => {
    async function subscribe(event: any) {
      const data = event.data;
      if (data.eventName === "sendScore") {
        const score = convertScoreToFloat(data.score) ?? 0;
        if (gameId) {
          await sendTWAScore(score, gameId);
        } else {
          await sendScore(score);
        }
      } else if (data.eventName === "updateScore") {
        differedSendScoreValueRef.current = convertScoreToFloat(data.score);
      }
    }

    window.addEventListener("message", subscribe);

    const updateScoreJob = setInterval(() => {
      if (differedSendScoreValueRef.current > 0) {
        updateScore(scoreIdTracker, differedSendScoreValueRef.current);
      }
    }, 5000);

    return () => {
      clearInterval(updateScoreJob);
      window.removeEventListener("message", subscribe);
    };
  }, [gameId]);
}

function convertScoreToFloat(scoreValue: string | number): number {
  try {
    const stringValue = String(scoreValue);
    const floatNumber = parseFloat(stringValue);
    return Math.round(floatNumber * 100) / 100;
  } catch {
    return 0;
  }
}
