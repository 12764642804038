export const TON = (props: any) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_26_2984)">
      <path
        d="M24 48C37.2549 48 48 37.2549 48 24C48 10.7451 37.2549 0 24 0C10.7451 0 0 10.7451 0 24C0 37.2549 10.7451 48 24 48Z"
        fill="#0098EA"
      />
      <path
        d="M32.1944 13.3953H15.8044C12.7909 13.3953 10.8808 16.646 12.3969 19.2739L22.5122 36.8066C23.1723 37.9514 24.8265 37.9514 25.4866 36.8066L35.604 19.2739C37.118 16.6502 35.208 13.3953 32.1965 13.3953H32.1944ZM22.504 31.5488L20.3011 27.2853L14.9856 17.7784C14.6349 17.1699 15.068 16.3902 15.8023 16.3902H22.5019V31.5508L22.504 31.5488ZM33.0091 17.7764L27.6957 27.2873L25.4928 31.5488V16.3881H32.1924C32.9267 16.3881 33.3598 17.1679 33.0091 17.7764Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_26_2984">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
