import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { BufferAttribute, BufferGeometry, DynamicDrawUsage, LineBasicMaterial, LineSegments } from "three";
import { usePhysics } from "./usePhysics";

export type PhysicsDebuggerProps = {};

export const PhysicsDebugger = () => {
  const physicsManager = usePhysics((state) => state.physicsManager);
  const { scene } = useThree();
  const debugMeshRef = useRef<LineSegments | null>(null);

  useEffect(() => {
    if (!physicsManager || !physicsManager.debuggerData) {
      return;
    }

    const debugGeometry = new BufferGeometry();
    const positionAttribute = new BufferAttribute(physicsManager.debuggerData.debugVertices, 3).setUsage(DynamicDrawUsage);
    const colorAttribute = new BufferAttribute(physicsManager.debuggerData.debugColors, 3).setUsage(DynamicDrawUsage);
    debugGeometry.setAttribute("position", positionAttribute);
    debugGeometry.setAttribute("color", colorAttribute);
    const debugMaterial = new LineBasicMaterial({ vertexColors: true });

    const debugMesh = new LineSegments(debugGeometry, debugMaterial);
    debugMesh.geometry.setDrawRange(0, 0);
    debugMesh.frustumCulled = false;
    scene.add(debugMesh);
    debugMeshRef.current = debugMesh;

    return () => {
      scene.remove(debugMesh);
    };
  }, [physicsManager, scene]);

  useFrame(() => {
    if (debugMeshRef.current) {
      const index = physicsManager?.debuggerData?.getIndex();

      if (index) {
        debugMeshRef.current.geometry.attributes.position.needsUpdate = true;
        debugMeshRef.current.geometry.attributes.color.needsUpdate = true;
        debugMeshRef.current.geometry.setDrawRange(0, index);
        physicsManager?.debuggerData?.release();
      }
    }
  });

  return null;
};
