import { Suspense, useEffect, useState } from "react";
import { useUnmount } from "react-use";
import { ErrorBoundary } from "~/view-scene/utils";
import { MaterialAsset } from "./MaterialAsset";
import { materialManager } from "./MaterialManager";
import { MaterialRecord } from "./types";

export function MaterialSystem() {
  const [cachedMaterials, setCashedMaterials] = useState<MaterialRecord[]>([]);

  useEffect(() => {
    const handler = () => {
      setCashedMaterials([...materialManager.materials]);
    };

    materialManager.on("add", handler);
    handler();

    return () => {
      materialManager.off("add", handler);
    };
  }, []);

  useUnmount(() => {
    materialManager.reset();
  });

  return (
    <>
      {cachedMaterials.map((cachedMaterial) => (
        <ErrorBoundary key={cachedMaterial.id} message={`Failed rendering MaterialAsset with id=${cachedMaterial.id}`}>
          <Suspense fallback={null}>
            <MaterialAsset materialRecord={cachedMaterial} />
          </Suspense>
        </ErrorBoundary>
      ))}
    </>
  );
}
