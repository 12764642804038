import { NodeContext } from "../../base";
import { BitwiseUnaryOperationNode } from "./BitwiseUnaryOperationNode";

export class BitwiseNotNode extends BitwiseUnaryOperationNode {
  static readonly type = "logic/bitwiseNot";
  static readonly label = "Bitwise NOT";

  constructor(context: NodeContext) {
    super(context, (value) => ~value);
  }
}
