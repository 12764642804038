import { useEffect } from "react";
import { loadFinish } from "~/view-scene/sceneLoad";

type FailedAssetPreloadProps = {
  assetId: string;
};

export function FailedAssetPreload({ assetId }: FailedAssetPreloadProps) {
  useEffect(() => {
    loadFinish(assetId);
  }, []);

  return null;
}
