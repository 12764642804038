import { attach, createEvent, forward } from "effector";
import { getDuplicateName } from "~/common/utils/getDuplicateName";
import { $entities, cloneEntity } from "~/entities/entities";
import { addEntitiesAndCalculateOrder } from "./addEntity";

export const duplicateEntity = createEvent<string>();

const duplicateEntityFx = attach({
  source: $entities,
  effect: (entities, id: string) => {
    if (!entities) {
      return;
    }

    const existingEntity = entities.find((entity) => entity.id === id);

    if (!existingEntity) {
      return;
    }

    const newName = getDuplicateName(
      existingEntity.name,
      entities.map((entity) => entity.name)
    );
    const newEntities = cloneEntity(id, entities);

    if (newEntities[0]) {
      newEntities[0].name = newName;
    }

    addEntitiesAndCalculateOrder(newEntities);
  },
});

forward({ from: duplicateEntity, to: duplicateEntityFx });
