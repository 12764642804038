import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";

export class SendUserEventNode extends BaseNode {
  static readonly type = "analytics/sendUserEventEvent";
  static readonly label = "Send User Event";

  inputs = [new FlowSocket("flow"), new StringSocket("event")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const event = context.readInput<string>("event");

    node.sceneContext.sendUserEvent(event);
  }
}
