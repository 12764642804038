import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";
import { InteractionEvent } from "~/types/InteractionEvent";

export class InteractionNode extends BaseNode {
  static readonly type = "event/interaction";
  static readonly label = "Interaction";
  static category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket()];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const type = node.readMetadata("interactionType", null);

    const onInteractionEvent = (interaction: InteractionEvent) => {
      if (type === interaction.type) {
        context.commit("flow");
      }
    };

    node.scriptContext.scriptEvents.interactionEvent.addListener(onInteractionEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.interactionEvent.removeListener(onInteractionEvent);
    });
  }
}
