import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { palette } from "~/theme";
import { Container } from "~/common/components";

export type SpaceHeadlinerProps = {
  link: string;
  posterSmall: string;
  posterLarge: string;
  title?: string;
  text?: string;
};

export const SpaceHeadliner = ({ link, posterSmall, posterLarge, title, text }: SpaceHeadlinerProps) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sceneUrl = "/s/" + text + "/view";

  const previewImage = (isSmallScreen ? posterSmall : posterLarge) ?? "/static/img/content/preview_placeholder.png";

  return (
    <Box component="div">
      <Paper
        style={{ background: `url(${previewImage}) center no-repeat` }}
        sx={(theme) => ({
          overflow: "hidden",
          width: "100%",
          position: "relative",
          borderRadius: "0",
          height: "100%",
          paddingBottom: "33%",
          backgroundColor: palette.primary.gray200,
          backgroundSize: "cover !important",
          "&::after": {
            content: '""',
            width: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)",
            display: "block",
            position: "absolute",
            zIndex: "5",
            left: 0,
            bottom: "-1px",
            height: "100px",
            [theme.breakpoints.down("sm")]: {
              height: "160px",
            },
          },
          [theme.breakpoints.down("sm")]: {
            paddingBottom: "100%",
          },
        })}
      >
        <Container>
          <Box
            component="div"
            sx={(theme) => ({
              position: "absolute",
              bottom: "20px",
              zIndex: "10",
              maxWidth: "561px",
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "calc(100% - 32px)",
              },
            })}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.white,
              }}
              variant="body1"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                paddingTop: "8px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.gray800,
              }}
              variant="body1"
            >
              {text}
            </Typography>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};
