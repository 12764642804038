import { useUnit } from "effector-react";
import { ReactNode, useMemo } from "react";
import { hotkeysContext } from "~/hotkeys";
import { $actionSource } from "./models";

export type EditorHotkeysProviderProps = {
  children: ReactNode;
};

export const EditorHotkeysProvider = (props: EditorHotkeysProviderProps) => {
  const actionSource = useUnit($actionSource);
  const context = useMemo(() => {
    actionSource.activate();

    return {
      actionSource,
    };
  }, [actionSource]);

  return <hotkeysContext.Provider value={context}>{props.children}</hotkeysContext.Provider>;
};
