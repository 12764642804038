import { RefObject, useContext, useEffect } from "react";
import { INetworkSystemCtx, NetworkSystemCtx } from "~/view-scene/network";
import { CollisionEndEvent, CollisionStartEvent } from "~/view-scene/physics";
import IRigidBodyComponent from "~/types/IRigidBodyComponent";
import { EntityContext } from "~/view-scene/runtime";

export function useDynamicRigidBodyNetworkingSync(
  componentDto: IRigidBodyComponent,
  contextRef: RefObject<EntityContext>
) {
  const networkSystem = useContext(NetworkSystemCtx) as INetworkSystemCtx;
  const isReadyForNetworking = networkSystem?.isConnected;

  const componentRigidBodyType = componentDto.rigidBodyType;
  const enableNetworkSync = componentDto.enableNetworkSync;

  useEffect(() => {
    if (!isReadyForNetworking || !enableNetworkSync || componentRigidBodyType !== "dynamic") {
      return;
    }

    const physicsBody = contextRef.current?.getPhysicsBody(componentDto.id);

    if (!physicsBody) {
      return;
    }

    const handleCollision = (collisionEvent: CollisionStartEvent | CollisionEndEvent) => {
      const collidedEntityId = collisionEvent.meta["entityId"];
      if (collidedEntityId !== "player" || collisionEvent.body.rigidBodyType !== "dynamic") {
        return;
      }

      const context = contextRef.current;
      const physicsBody = context?.getPhysicsBody(componentDto.id);
      const threeObject = context?.rootObjectRef.current;

      if (context && physicsBody && threeObject) {
        const leanerVelocity = physicsBody.leanerVelocity;
        const angularVelocity = physicsBody.angularVelocity;

        networkSystem.send("rigidBodyCollision", {
          entityId: context.id,
          position: [threeObject.position.x, threeObject.position.y, threeObject.position.z],
          leanerVelocity,
          angularVelocity,
        });
      }
    };

    const onCollision = (collisionEvent: CollisionStartEvent | CollisionEndEvent) => handleCollision(collisionEvent);

    physicsBody.on("collisionStart", onCollision);
    physicsBody.on("collisionEnd", onCollision);

    return () => {
      physicsBody.off("collisionStart", onCollision);
      physicsBody.off("collisionEnd", onCollision);
    };
  }, [isReadyForNetworking, enableNetworkSync, componentRigidBodyType]);
}
