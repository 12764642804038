import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { App } from "~/app";

if (window.location.href.includes("hypercoin.bot")) {
  Sentry.init({
    dsn: "https://1388054a8452047fb72682b4ce28eb08@o4507295413829632.ingest.de.sentry.io/4507301569626192",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const rootElement = document.getElementById("root")!;

createRoot(rootElement).render(<App />);
