import { ValueType } from "~/libs/behave-graph";
import { defaultEvent } from "~/entities/variable";
import { EventValue } from "~/types/Variable";

export type { EventValue };

export const eventValue = new ValueType(
  "event",
  defaultEvent,
  (text: EventValue) => text,
  (value: EventValue) => value
);
