import { memo, ReactNode, useRef } from "react";
import { Object3D } from "three";
import { SpriteSceneObject } from "~/types/sceneObject";
import { Entity } from "~/view-scene/entities/Entity";
import { SpriteContext, useEntityContext } from "~/view-scene/runtime/contexts";
import { useEntity } from "~/view-scene/utils/useEntity";
import { RenderSprite } from "./RenderSprite";

type SpriteEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const SpriteEntity = memo(({ entityId, children }: SpriteEntityProps) => {
  const entity = useEntity<SpriteSceneObject>(entityId);
  const ref = useRef<Object3D>(null);

  const spriteContext = useEntityContext(entityId, () => new SpriteContext(entity, ref));

  return (
    <Entity entityId={entityId} context={spriteContext} ref={ref}>
      <RenderSprite entity={entity} contextRef={spriteContext} />
      {children}
    </Entity>
  );
});
