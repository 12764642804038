import { $audioManager } from "./audioManager";
import { SourceType } from "../types";
import { Audio, AudioLoader, PositionalAudio } from "three";
import { isThreeAudio } from "../utils";
import { attach } from "effector";

type AddAudioPayload = {
  sourceType: SourceType;
  node: PositionalAudio | Audio | MediaStreamAudioSourceNode;
};

export const addAudio = attach({
  source: $audioManager,
  effect: ({ mixer }, { sourceType, node }: AddAudioPayload) => {
    let outputNode: any = node;
    if (isThreeAudio(node)) {
      node.gain.disconnect();
      outputNode = node.gain;
    }
    const gainNode = mixer && mixer[sourceType];

    if (gainNode) {
      outputNode.connect(gainNode);
    }
  },
});

export const playAudio = attach({
  source: $audioManager,
  effect: ({ audioListener }, src: string) => {
    const sound = new Audio(audioListener!);

    const audioLoader = new AudioLoader();
    audioLoader.load(src, function (buffer) {
      sound.setBuffer(buffer);
      sound.setLoop(false);
      sound.setVolume(1);
      sound.play();
    });
  },
});
