import { NodeContext } from "../../base";
import { UIImageSocket } from "../../sockets/UIImageSocket";
import { CastToUIEntityNode } from "./CastToUIEntityNode";

export class CastToUIImageNode extends CastToUIEntityNode {
  static readonly type = "logic/castToUIImage";
  static readonly label = "Cast to UI Image";

  constructor(context: NodeContext) {
    super(context, "image", new UIImageSocket("uiImage"));
  }
}
