import { keyBy } from "lodash-es";
import { Registry as BehaveRegistry } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseNode, BaseNodeClass, NodeContext } from "./base";
import * as values from "./values";

type Factory = (nodeSpec: NodeSpec) => BaseBehaveNode;

export class Registry {
  behaveRegistry: BehaveRegistry;
  behaveFactories: Record<string, Factory> = {};
  nodes: BaseNode[];
  nodesMap: Record<string, BaseNode>;

  constructor(nodes: BaseNodeClass[], private context: NodeContext) {
    this.behaveRegistry = createBehaveRegistry();
    this.registerValues();
    const instances = this.initNodes(nodes);
    this.nodes = instances.nodes;
    this.nodesMap = instances.nodesMap;
  }

  private initNodes(nodes: BaseNodeClass[]) {
    const nodeInstances = nodes.map((NodeClass) => {
      return new NodeClass(this.context);
    });

    return {
      nodes: nodeInstances,
      nodesMap: keyBy(nodeInstances, "type"),
    };
  }

  updateContext(context: Partial<NodeContext>) {
    this.context = {
      ...this.context,
      ...context,
    };
  }

  registerNodeFor(nodeSpec: NodeSpec) {
    const node = this.nodesMap[nodeSpec.type];

    if (!node) {
      return;
    }

    if (this.behaveFactories[nodeSpec.type]) {
      return;
    }

    const factory: Factory = (nodeSpec) => {
      const behaveNode = node.produceBehaveNode(nodeSpec);

      return behaveNode;
    };

    this.behaveFactories[nodeSpec.type] = factory;
  }

  getNode(type: string) {
    return this.nodesMap[type] ?? null;
  }

  private registerValues() {
    this.behaveRegistry.values.register(values.animationValue);
    this.behaveRegistry.values.register(values.anyValue);
    this.behaveRegistry.values.register(values.assetMaterialValue);
    this.behaveRegistry.values.register(values.assetModelValue);
    this.behaveRegistry.values.register(values.assetValue);
    this.behaveRegistry.values.register(values.audioValue);
    this.behaveRegistry.values.register(values.booleanValue);
    this.behaveRegistry.values.register(values.componentValue);
    this.behaveRegistry.values.register(values.entityValue);
    this.behaveRegistry.values.register(values.modelValue);
    this.behaveRegistry.values.register(values.numberValue);
    this.behaveRegistry.values.register(values.variableValue);
    this.behaveRegistry.values.register(values.playerValue);
    this.behaveRegistry.values.register(values.sceneValue);
    this.behaveRegistry.values.register(values.stringValue);
    this.behaveRegistry.values.register(values.trajectoryValue);
    this.behaveRegistry.values.register(values.followTargetValue);
    this.behaveRegistry.values.register(values.vector3Value);
    this.behaveRegistry.values.register(values.videoValue);

    this.behaveRegistry.values.register(values.uiEntityValue);
    this.behaveRegistry.values.register(values.uiBlockValue);
    this.behaveRegistry.values.register(values.uiImageValue);
    this.behaveRegistry.values.register(values.uiTextValue);
    this.behaveRegistry.values.register(values.eventValue);
  }

  createNode(nodeSpec: NodeSpec) {
    const factory = this.behaveFactories[nodeSpec.type];

    if (!factory) {
      console.log(`No factory for node type: ${nodeSpec.type}`);

      return null;
    }

    return factory(nodeSpec);
  }

  initNode(node: BaseBehaveNode) {
    const nodeInstance = this.nodesMap[node.typeName];

    if (!nodeInstance) {
      return;
    }

    nodeInstance.initNode(node);
  }
}

const createBehaveRegistry = () => {
  const registry = new BehaveRegistry();
  // @ts-ignore
  registry.values.valueTypeNameToValueType = {};

  return registry;
};
