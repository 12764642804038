import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberAcotanNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberAcotan";
  static readonly label = "Arccotan";

  constructor(context: NodeContext) {
    super(context, (value) => Math.atan(1 / value));
  }
}
