import { NodeEvalContext } from "~/libs/behave-graph";
import { AudioContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AudioSocket, FlowSocket, NumberSocket } from "../../sockets";

/**
 * @deprecated useAudioTimeNode
 */
export class AudioGetTimeNode extends BaseNode {
  static readonly type = "action/audioGetTime";
  static readonly label = "Get audio current time";
  static deprecated = true;

  inputs = [new FlowSocket("flow"), new AudioSocket("audio")];
  outputs = [new FlowSocket("flow"), new NumberSocket("time")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const audio = node.readEntity<AudioContext>(context, "audio");
    const time = audio?.getCurrentTime() ?? -1;

    context.writeOutput<number>("time", time);
  }
}
