import { keyBy } from "lodash-es";
import { AssetType } from "~/types/AssetType";
import { JSScriptAsset } from "~/types/JSScriptAsset";
import { $assets } from "../assets";

export const $jsScripts = $assets.map((assets, lastState: JSScriptAsset[] | null) => {
  if (!assets) {
    return null;
  }

  const scripts = assets.filter((asset) => asset.type === AssetType.jsScript) as JSScriptAsset[];

  if (lastState?.length !== scripts.length) {
    return scripts;
  }

  for (let i = 0; i < scripts.length; i++) {
    if (assets[i] !== scripts[i]) {
      return scripts;
    }
  }

  return lastState;
}, null);

export const $jsScriptsMap = $jsScripts.map((scripts) => {
  return scripts ? keyBy(scripts, "id") : null;
});
