import { NodeEvalContext } from "~/libs/behave-graph";
import { AnimationComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, FlowSocket, NumberSocket } from "../../sockets";

export class AnimationSetTimeNode extends BaseNode {
  static readonly type = "action/animationSetTime";
  static readonly label = "Animation Set Time";

  inputs = [new FlowSocket("flow"), new AnimationSocket("animation"), new NumberSocket("time")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const animationComponent = node.readComponent<AnimationComponentContext>(context, "animation");

    if (animationComponent) {
      animationComponent.time = context.readInput<number>("time");
    }
  }
}
