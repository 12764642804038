import create from "zustand";

type OpenLinkHelperState = {
  open: boolean;
  title: string;
  content: string;
  url: string;
  onClose?: VoidFunction;

  openLinkHelper: (title: string, content: string, url: string, onClose?: VoidFunction) => void;
  closeLinkHelper: VoidFunction;
};

export const useOpenLinkHelper = create<OpenLinkHelperState>((set, get) => ({
  open: false,
  title: "",
  content: "",
  url: "",

  openLinkHelper: (title, content, url, onClose) => {
    set(() => ({ open: true, title, content, url, onClose }));
  },

  closeLinkHelper: () => {
    set(() => ({ open: false }));
    get().onClose?.();
  },
}));
