import { Socket } from "socket.io-client";
import { useEffect, useState } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { useProfile } from "~/entities/profile";
import useSessionStatus from "../stores/useSessionStatus";
import { RemoteUser, RemoteWorldUpdateMessage } from "./types";
import {
  cleanupWebRTCConnections,
  playRemotePlayerAnimation,
  remoteCollisions,
  remoteScreenShareEntitiesUpdate,
  remoteVideoEntitiesUpdate,
  stopRemotePlayerAnimation,
} from "./models";

export function useSceneSocketSession(socket: Socket | undefined) {
  const [isSocketSessionEstablished, setIsSocketSessionEstablished] = useState(false);

  const sceneId = useSceneData((state) => state.sceneState?._id);

  const mode = useSessionStatus((state) => state.mode);

  const { getRemoteUser, getRemoteUsers, updateRemoteUsers } = useSceneData((state) => ({
    getEntity: state.getEntity,
    updateEntity: state.updateEntity,
    getRemoteUser: state.getRemoteUser,
    getRemoteUsers: state.getRemoteUsers,
    updateRemoteUsers: state.updateRemoteUsers,
  }));

  const loadProfile = useProfile((state) => state.loadProfile);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("sessionEstablished", () => setIsSocketSessionEstablished(true));

    socket.on("disconnect", () => {
      cleanupWebRTCConnections();
      updateRemoteUsers([]);

      setIsSocketSessionEstablished(false);
    });

    socket.on("reloadProfile", async () => {
      await loadProfile();
    });

    socket.on(
      "remoteWorldUpdate",
      ({ remoteUsers, collisions, videoEntities, screenShareEntities }: RemoteWorldUpdateMessage) => {
        const skipMyUser = (remoteUsers as RemoteUser[]).filter((u) => u.id !== socket.id);
        updateRemoteUsers(skipMyUser);

        remoteCollisions(collisions);
        remoteVideoEntitiesUpdate(videoEntities);
        remoteScreenShareEntitiesUpdate(screenShareEntities);
      }
    );

    socket.on("playAnimation", ({ remoteUserId, payload }) => {
      const remoteUser = getRemoteUser(remoteUserId);
      if (!remoteUser) {
        return;
      }

      playRemotePlayerAnimation({
        animation: payload.animationName,
        userId: remoteUserId,
      });
    });

    socket.on("stopAnimation", ({ remoteUserId, payload }) => {
      const remoteUser = getRemoteUser(remoteUserId);
      if (!remoteUser) {
        return;
      }

      stopRemotePlayerAnimation({
        animation: payload.animationName,
        userId: remoteUserId,
      });
    });

    return () => {
      socket.removeListener("sessionEstablished");
      socket.removeListener("disconnect");
      socket.removeListener("reloadProfile");
      socket.removeListener("remoteWorldUpdate");
      socket.removeListener("playAnimation");
      socket.removeListener("stopAnimation");
    };
  }, [socket]);

  const profile = useProfile((state) => state.profile);

  const userAvatar = profile?.avatarUrl ?? "/static/models/default-avatar.glb";

  const joinSceneSocketSession = () => {
    socket?.emit("joinSession", {
      sceneId: sceneId,
      userID: profile?.id,
      avatarUrl: userAvatar,
      mode: mode,
    });
  };

  const cleanupSceneSocketSession = () => {
    cleanupWebRTCConnections();
    updateRemoteUsers([]);

    setIsSocketSessionEstablished(false);
  };

  return { isSocketSessionEstablished, joinSceneSocketSession, cleanupSceneSocketSession };
}
