import { NodeEvalContext } from "~/libs/behave-graph";
import { CameraContext } from "~/view-scene/runtime/contexts";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class CameraGetFovNode extends BaseNode {
  static readonly type = "camera/getFov";
  static readonly label = "Camera Get FOV";

  outputs = [new NumberSocket("fov")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const cameraContext = node.sceneContext.getEntityContext<CameraContext>("camera");
    context.writeOutput<number>("fov", cameraContext?.fov ?? 0);
  }
}
