import { requestNonceForWallet } from "~/api/auth.api";
import { BlockchainNetwork, Web3Wallet } from "~/web3Helpers";
import { attachWallet } from "~/api/profile.api";
import { AttachWalletRequest } from "./types";

type UseAttachMetamaskProps = {
  network: BlockchainNetwork;
  onWalletAttached: VoidFunction;
  onError: (message?: string) => void;
};

export function useAttachMetamask({ network, onWalletAttached, onError }: UseAttachMetamaskProps) {
  const requestAttachMetamask = async () => {
    const Web3Module = await import("web3");
    const Web3 = Web3Module.default;
    // @ts-ignore
    const ethereum = window.ethereum;
    if (!ethereum) {
      onError("Metamask Wallet is not installed");
      return;
    }

    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];

    if (!account) {
      onError("ETH-compatible wallet is not found");
      return;
    }

    const web3 = new Web3(ethereum);
    const nonce = await requestNonceForWallet(account);

    const message = `Sign the message to confirm your identity: ${nonce}`;
    const signature = await web3.eth.personal.sign(message, account, "");

    const payload: AttachWalletRequest = {
      wallet: Web3Wallet.metamask,
      network,
      walletAddress: account,
      signature,
    };

    const { status } = await attachWallet(payload);

    if (status !== 200) {
      onError();
      return;
    }

    await onWalletAttached();
  };

  return { requestAttachMetamask };
}
