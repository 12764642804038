import { RigidBodyType } from "~/types/RigidBodyType";

export const rigidBodyToCollisionFlags = (rigidBody: RigidBodyType, ghost: boolean) => {
  return valuesMap[rigidBody] | (ghost ? COLLISION_FLAG_GHOST : 0);
};

const COLLISION_FLAG_DYNAMIC = 0;
const COLLISION_FLAG_STATIC = 1;
const COLLISION_FLAG_KINEMATIC = 2;
const COLLISION_FLAG_GHOST = 4;

const valuesMap: Record<RigidBodyType, number> = {
  dynamic: COLLISION_FLAG_DYNAMIC,
  static: COLLISION_FLAG_STATIC,
  kinematic: COLLISION_FLAG_KINEMATIC,
};
