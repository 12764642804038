export class Indexer {
  private freeIndex = 0;
  private freeIndexArray: Int32Array = new Int32Array();

  constructor(max: number) {
    this.initFreeIndexes(max);
  }

  getNext() {
    if (this.freeIndex !== -1) {
      const returnValue = this.freeIndex;
      const nextFreeIndex = this.freeIndexArray[this.freeIndex];
      this.freeIndexArray[this.freeIndex] = -1;
      this.freeIndex = nextFreeIndex;

      return returnValue;
    }

    return -1;
  }

  release(index: number) {
    this.freeIndexArray[index] = this.freeIndex;
    this.freeIndex = index;
  }

  private initFreeIndexes(amount: number) {
    this.freeIndexArray = new Int32Array(amount);
    for (let i = 0; i < amount - 1; i++) {
      this.freeIndexArray[i] = i + 1;
    }
    this.freeIndexArray[amount - 1] = -1;
    this.freeIndex = 0;
  }
}
