import { NumberMathOperationNode } from "./NumberMathOperationNode";
import { NodeContext } from "../../base";

export class NumberModNode extends NumberMathOperationNode {
  static readonly type = "logic/numberMod";
  static readonly label = "Number %";

  constructor(context: NodeContext) {
    super(context, (a, b) => a % b);
  }
}
