import { memo, useRef, ReactNode } from "react";
import { useEntityContext, TextContext } from "~/view-scene/runtime";
import { Entity } from "../Entity";
import { RenderText } from "./RenderText";
import ITextSceneObject from "~/types/ITextSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";

export type TextEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const TextEntity = memo(({ entityId, children }: TextEntityProps) => {
  const entity = useEntity<ITextSceneObject>(entityId);
  const contextRef = useRef({} as TextContext);
  const textRef = useRef<any>(null);

  useEntityContext(contextRef, () => ({
    type: "text" as const,
    getContent: () => {
      return textRef.current?.text ?? "";
    },
    setContent: (content: string) => {
      if (textRef.current) {
        textRef.current.text = content;
      }
    },
  }));

  return (
    <Entity entityId={entityId} context={contextRef}>
      <RenderText entity={entity} ref={textRef} />
      {children}
    </Entity>
  );
});
