import { styled } from "@mui/material";
import { Framage } from "~/libs/react-framage";
import { Text } from "./Text";

export const buttons = {
  default: {
    src: "/static/img/content/hyper/defaultButton.png",
    width: 268,
    height: 88,
    nineSlice: {
      top: 34,
      bottom: 34,
      right: 34,
      left: 34,
    },
    style: {
      padding: "30px 44px",
      minWidth: "256px",
      margin: "-10px 0px",
      height: "84px",
    },
  },
  secondary: {
    src: "/static/img/content/hyper/secondaryButton.png",
    width: 236,
    height: 48,
    nineSlice: {
      top: 20,
      bottom: 20,
      right: 20,
      left: 20,
    },
    style: {
      padding: "15px 28px",
      minWidth: "224px",
      margin: "4px",
      height: "54px",
    },
  },
  invite: {
    src: "/static/img/content/hyper/inviteLargeButton.png",
    width: 268,
    height: 80,
    nineSlice: {
      top: 34,
      bottom: 34,
      right: 34,
      left: 34,
    },
    style: {
      padding: "30px 44px",
      height: "84px",
      minWidth: "256px",
      margin: "-10px 0px",
    },
  },
  free: {
    src: "/static/img/content/hyper/freeButton.png",
    width: 268,
    height: 88,
    nineSlice: {
      top: 34,
      bottom: 34,
      right: 34,
      left: 34,
    },
    style: {
      padding: "30px 44px",
      height: "84px",
      minWidth: "256px",
      margin: "-10px 0px",
    },
  },
  purchase: {
    src: "/static/img/content/hyper/purchaseButton.png",
    width: 268,
    height: 88,
    nineSlice: {
      top: 34,
      bottom: 34,
      right: 34,
      left: 34,
    },
    style: {
      padding: "30px 44px",
      minWidth: "256px",
      height: "84px",
      margin: "-10px 0px",
    },
  },
  disabled: {
    src: "/static/img/content/hyper/disabledButton.png",
    width: 268,
    height: 88,
    nineSlice: {
      top: 34,
      bottom: 34,
      right: 34,
      left: 34,
    },
    style: {
      padding: "30px 44px",
      height: "84px",
      minWidth: "256px",
      margin: "-10px 0px",
    },
  },
};

export type ButtonAppearance = "default" | "secondary" | "free" | "invite" | "purchase" | "disabled";

export type ButtonProps = {
  index: number;
  label: string;
  appearance?: ButtonAppearance;
  onClick: (index: number) => void;
};

export const Button = ({ index, label, appearance = "default", onClick }: ButtonProps) => {
  const { src, width, height, nineSlice, style } = buttons[appearance];

  const handleClick = () => {
    onClick(index);
  };

  return (
    <Container style={style} onClick={appearance === "disabled" ? undefined : handleClick}>
      <FramageContainer>
        <Framage src={src} alt="buttonBackgroundImage" view={{ width, height }} nineslice={nineSlice} />
      </FramageContainer>

      <Content>
        <LabelContainer sx={{ opacity: appearance === "disabled" ? "50%" : undefined }}>
          <Text text={label} />
        </LabelContainer>
      </Content>
    </Container>
  );
};

const Container = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const FramageContainer = styled("div")`
  position: absolute;
  inset: 0;

  & > react-framage {
    position: absolute;
    display: grid;
    inset: 0;
    width: auto;
    height: auto;
  }
`;

const Content = styled("div")`
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0 4px;

  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
`;
