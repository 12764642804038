import { getAssets } from "api/assets.api";
import { getScene, getScenes } from "api/scene.api";
import { RemoteUser } from "view-scene/network/types";
import create from "zustand";
import { PartialRecord, Update } from "~/common/typeUtils";
import { renewParentBranch } from "~/common/utils/renewParentBranch";
import { getVariantUrl, setAssets } from "~/entities/assets";
import { cloneEntity } from "~/entities/entities";
import { ComponentType } from "~/types/ComponentType";
import IAsset from "~/types/IAsset";
import IComponent from "~/types/IComponent";
import IModelAsset from "~/types/IModelAsset";
import IScene from "~/types/IScene";
import ISceneObject from "~/types/ISceneObject";
import { ScreenOverlayItem } from "~/types/ScreenOverlay";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { toHierarchy } from "../utils";

export type SceneData = {
  sceneState: IScene | null;
  sceneEntities: ISceneObject[];
  assetsLibraryLookupMap: Map<string, IAsset>;
  scenesLibraryLookupMap: Map<string, IScene>;

  getAsset: <T extends IAsset>(assetId: string | null | undefined) => T | undefined;
  getAssetByName: <T extends IAsset>(assetId: string) => T | undefined;
  getModelUrl: (assetId: string | null | undefined, variantId?: string | null) => string | null;
  getScene: (sceneId: string | null | undefined) => IScene | undefined;

  getEntity: <T extends ISceneObject>(entityId: string) => T | undefined;
  updateEntity: <T extends ISceneObject>(entityId: string, updatedState: T) => void;
  cloneEntity: (entityId: string, override?: Partial<ISceneObject>) => string | null;
  markEntityRemoved: (entityId: string) => boolean;
  removeEntity: (entityId: string) => void;

  getOverlays: () => ScreenOverlayItem[];
  updateOverlay: (updatedOverlay: Update<ScreenOverlayItem>) => void;

  loadData: (urlName: string) => Promise<{ scene: IScene | null }>;
  loadRemoteSceneState: (urlName: string) => Promise<IScene | null>;
  loadAssetsLibrary: (workspaceId: string) => Promise<IAsset[]>;
  loadScenesLibrary: () => void;

  remoteUsers: RemoteUser[];
  getRemoteUsers: () => RemoteUser[];
  getRemoteUser: (userId: string) => RemoteUser | undefined;
  updateRemoteUsers: (remoteUsers: RemoteUser[]) => void;

  reset: () => void;
};

export const useSceneData = create<SceneData>((set, get) => ({
  sceneState: null,
  sceneEntities: [],
  assetsLibraryLookupMap: new Map<string, IAsset>(),
  scenesLibraryLookupMap: new Map<string, IScene>(),

  getAsset: <T extends IAsset>(assetId: string | null | undefined) => {
    const assetsLibraryLookupMap = get().assetsLibraryLookupMap;
    return assetId ? (assetsLibraryLookupMap.get(assetId) as T) : undefined;
  },

  getAssetByName: <T extends IAsset>(assetName: string) => {
    const assetsLibraryLookupMap = get().assetsLibraryLookupMap;

    for (const asset of assetsLibraryLookupMap.values()) {
      if (asset.name === assetName) {
        return asset as T;
      }
    }

    return undefined;
  },

  getModelUrl: (assetId, variantId) => {
    const asset = get().getAsset<IModelAsset>(assetId);
    if (!asset || !variantId) {
      return null;
    }

    return getVariantUrl(asset, variantId);
  },

  getScene: (sceneId: string | null | undefined) => {
    return sceneId ? get().scenesLibraryLookupMap.get(sceneId) : undefined;
  },

  getEntity: <T extends ISceneObject>(entityId: string) => {
    const entity = get().sceneEntities.find((entity) => entity.id === entityId);
    if (entity) {
      return entity as T;
    }
    return undefined;
  },

  getOverlays: () => {
    const sceneState = get().sceneState;
    const screenOverlay = sceneState?.screenOverlay;

    return screenOverlay?.items ?? [];
  },

  updateOverlay: (updatedOverlay: Update<ScreenOverlayItem>) => {
    const sceneState = get().sceneState;
    const screenOverlay = sceneState?.screenOverlay;

    if (sceneState && screenOverlay) {
      set(() => ({
        sceneState: {
          ...sceneState,
          screenOverlay: {
            ...screenOverlay,
            items: screenOverlay.items.map((existingOverlay) =>
              existingOverlay.id === updatedOverlay.id ? { ...existingOverlay, ...updatedOverlay } : existingOverlay
            ),
          },
        },
      }));
    }
  },

  updateEntity: <T extends ISceneObject>(entityId: string, updatedState: T) => {
    const sceneEntities = get().sceneEntities;
    const updatedEntities = sceneEntities.map((sceneEntity) =>
      sceneEntity.id === entityId ? updatedState : sceneEntity
    );

    set(() => ({ sceneEntities: updatedEntities }));

    const sceneState = get().sceneState;

    if (sceneState) {
      set(() => ({
        sceneState: {
          ...sceneState,
          objects: toHierarchy<ISceneObject>([...updatedEntities]),
        },
      }));
    }
  },

  cloneEntity: (entityId, override) => {
    const sceneState = get().sceneState;
    const sceneEntities = get().sceneEntities;

    if (sceneState) {
      const newEntities = cloneEntity(entityId, sceneEntities);

      if (newEntities.length === 0) {
        return null;
      }

      newEntities[0] = {
        ...newEntities[0],
        ...override,
      };
      const updatedEntities = renewParentBranch(newEntities[0].id, [...sceneEntities, ...newEntities]);

      sceneState.objects = toHierarchy(updatedEntities);
      set(() => ({ sceneState, sceneEntities: updatedEntities }));

      return newEntities[0].id;
    }

    return null;
  },

  markEntityRemoved: (entityId: string) => {
    const sceneState = get().sceneState;

    if (!sceneState) {
      return false;
    }

    const sceneEntities = get().sceneEntities;
    let found = false;
    const markerEntities = sceneEntities.map((entity) => {
      if (entity.id === entityId) {
        found = true;
        return { ...entity, removed: true };
      }

      return entity;
    });
    const updatedEntities = renewParentBranch(entityId, markerEntities);

    if (!found) {
      return false;
    }

    sceneState.objects = toHierarchy([...updatedEntities]);
    set(() => ({ sceneState, sceneEntities: updatedEntities }));

    return true;
  },

  removeEntity: (entityId: string) => {
    const sceneEntities = get().sceneEntities;
    const index = sceneEntities.findIndex((entity) => entity.id === entityId);

    if (index !== -1) {
      sceneEntities.splice(index, 1);
    }
  },

  loadData: async (urlName: string) => {
    const [scene] = await Promise.all([get().loadRemoteSceneState(urlName), get().loadScenesLibrary()]);
    const workspaceId = scene?.workspace._id;

    if (workspaceId) {
      const assets = await get().loadAssetsLibrary(workspaceId);
      setAssets(assets);
    }

    return {
      scene,
    };
  },

  loadRemoteSceneState: async (urlName: string) => {
    let sceneState = window.__sceneData!;

    if (!sceneState) {
      const { status, body } = await getScene(urlName);

      if (status !== 200) {
        useSessionStatus.getState().updateStatus("no_access");
        return null;
      }

      sceneState = body;
    }

    sortSceneObjects(sceneState.objects);
    set(() => ({ sceneEntities: [...sceneState.objects] }));
    sceneState.objects = toHierarchy(sceneState.objects);
    set(() => ({ sceneState: sceneState }));

    return sceneState;
  },

  loadAssetsLibrary: async (workspaceId: string) => {
    const assets = window.__assets ? window.__assets : await getAssets(workspaceId).then((res) => res.body as IAsset[]);
    const assetsLibraryLookupMap = new Map();
    assets.forEach((record: IAsset) => assetsLibraryLookupMap.set(record.id, record));
    set(() => ({ assetsLibraryLookupMap: assetsLibraryLookupMap }));

    return assets;
  },

  loadScenesLibrary: async () => {
    const { status, body: scenesLibrary } = await getScenes();

    const scenesLibraryLookupMap = new Map();
    scenesLibrary.forEach((record: IScene) => scenesLibraryLookupMap.set(record._id, record));
    set(() => ({ scenesLibraryLookupMap: scenesLibraryLookupMap }));
  },

  remoteUsers: [],

  getRemoteUsers: () => get().remoteUsers,

  getRemoteUser: (userId: string) => {
    const remoteUsers = get().remoteUsers;
    return remoteUsers.find((user) => user.id === userId);
  },

  updateRemoteUsers: (users: RemoteUser[]) => {
    set(() => ({ remoteUsers: users }));
  },

  reset: () => {
    set(() => ({
      sceneState: null,
      sceneEntities: [],
      assetsLibraryLookupMap: new Map(),
      scenesLibraryLookupMap: new Map(),
      remoteUsers: [],
    }));
  },
}));

// sort components of objects
const sortSceneObjects = (objects: ISceneObject[]) => {
  for (const object of objects) {
    sortComponents(object.components);
  }
};

// less - render earlier
const prioritiesMap: PartialRecord<ComponentType, number> = {
  [ComponentType.CONSTRAINT]: 999,
};

const sortComponents = (components: IComponent[]) => {
  components.sort((a, b) => {
    const aPriority = prioritiesMap[a.type] ?? 0;
    const bPriority = prioritiesMap[b.type] ?? 0;

    return aPriority - bPriority;
  });
};
