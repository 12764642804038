import { useMemo, useRef } from "react";
import { hasSceneAccess } from "~/api/scene.api";
import { sendUserEvent } from "~/api/userEvent.api";
import { useForceRerender } from "~/common/stores/useForceRerender";
import { useSceneData } from "~/common/stores/useSceneData";
import { Update } from "~/common/typeUtils";
import { decodeUIEntityId } from "~/entities/variable";
import { ComponentType } from "~/types/ComponentType";
import IComponent from "~/types/IComponent";
import ISceneObject from "~/types/ISceneObject";
import { ScreenOverlayItem } from "~/types/ScreenOverlay";
import { StorageData } from "~/types/Storage";
import { requestCameraZoomIn, requestCameraZoomOut, setCameraState } from "~/view-scene/CameraSystem";
import { playerControls } from "~/view-scene/ControlsSystem";
import { lockPointer, unlockPointer } from "~/view-scene/PointerLocker";
import { playPlayerAnimation, stopPlayerAnimation } from "~/view-scene/entities/PlayerEntity";
import {
  ComponentContext,
  EntityContext,
  JSScriptComponentContext,
  NewContextEventValue,
  ScriptComponentContext,
  UIEntityContext,
  enterCutscene,
  useEntityManager,
} from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { setMobileControlsVisibility } from "../ControlsSource";
import { usePhysics } from "../physics";
import { useUserStorageProvider } from "./hooks";
import { $scriptExecutor } from "./models";

export const useSceneContext = () => {
  const physicsManager = usePhysics((state) => state.physicsManager!);
  const forceRerender = useForceRerender((state) => state.rerender);
  const platform = useSessionStatus((state) => state.mode);
  const {
    getEntity,
    updateEntity,
    markEntityRemoved,
    cloneEntity,
    getAssetByName,
    getAsset,
    getOverlays,
    updateOverlay,
  } = useSceneData((state) => ({
    getEntity: state.getEntity,
    updateEntity: state.updateEntity,
    markEntityRemoved: state.markEntityRemoved,
    cloneEntity: state.cloneEntity,
    getAssetByName: state.getAssetByName,
    getAsset: state.getAsset,
    getOverlays: state.getOverlays,
    updateOverlay: state.updateOverlay,
  }));
  const {
    entityManagerEvents,
    getEntityContext,
    getEntityContextByName,
    getComponentContext,
    getAllEntityContexts,
    getUIEntityContext,
    getOverlayUIEntityContext,
  } = useEntityManager((state) => ({
    entityManagerEvents: state.events,
    getEntityContext: state.getEntityContext,
    getEntityContextByName: state.getEntityContextByName,
    getAllEntityContexts: state.getAllEntityContexts,
    getComponentContext: state.getComponentContext,
    getUIEntityContext: state.getUIEntityContext,
    getOverlayUIEntityContext: state.getOverlayUIEntityContext,
  }));
  const userStorage = useUserStorageProvider();
  const sceneAccessCacheRef = useRef<Record<string, Promise<boolean>>>({});

  return useMemo(() => {
    const context = {
      playerControls,
      getEntityDescriptor: <TEntity extends ISceneObject>(id: string): TEntity | null => {
        const entity = getEntity(id);

        if (entity) {
          return entity as TEntity;
        } else {
          return null;
        }
      },
      updateEntityDescriptor: <TEntity extends ISceneObject>(entity: TEntity) => {
        updateEntity(entity.id, entity);
      },
      getUserStorageData: (storageId: string) => {
        return userStorage.getStorageData(storageId);
      },
      updateUserStorageData: (storageId: string, values: StorageData["values"]) => {
        userStorage.updateStorageData(storageId, values);
        setTimeout(() => $scriptExecutor.getState()?.storageUpdate(storageId), 0);
      },
      getEntityContext: <TContext extends EntityContext = EntityContext>(id: string) => {
        const contextRef = getEntityContext<TContext>(id);

        return contextRef?.current ?? null;
      },
      getEntityContextByName: <TContext extends EntityContext = EntityContext>(name: string) => {
        const contextRef = getEntityContextByName<TContext>(name);

        return contextRef?.current ?? null;
      },
      getChildContextByName: <TContext extends EntityContext = EntityContext>(
        parentEntityId: string,
        childName: string
      ) => {
        return (getAllEntityContexts().find(
          (contextRef) => contextRef.current?.parentId === parentEntityId && contextRef.current?.name === childName
        )?.current ?? null) as TContext | null;
      },
      getEntityContexts: () => {
        return getAllEntityContexts()
          .map((contextRef) => contextRef.current)
          .filter(Boolean) as EntityContext[];
      },
      getEntityContextsByTag: (tag: string) => {
        return getAllEntityContexts()
          .map((contextRef) => contextRef.current)
          .filter((context) => context?.tags.has(tag)) as EntityContext[];
      },
      getScriptByComponent: (scriptComponentId: string) => {
        return $scriptExecutor.getState()?.getScript(scriptComponentId) ?? null;
      },
      getEntityScript: (entityId: string, scriptId: string) => {
        const entity = context.getEntityContext(entityId);

        if (!entity) {
          return null;
        }

        const scriptComponentContext = Object.values(entity.components).find(
          (component) => isScript(component) && component.scriptId === scriptId
        );

        if (!scriptComponentContext) {
          return null;
        }

        return context.getScriptByComponent(scriptComponentContext.id);
      },
      getEntityScriptByName: (entityId: string, scriptName: string) => {
        const entity = context.getEntityContext(entityId);

        if (!entity) {
          return null;
        }

        const scriptComponentContext = Object.values(entity.components).find(
          (component) => isScript(component) && component.name === scriptName
        );

        if (!scriptComponentContext) {
          return null;
        }

        return context.getScriptByComponent(scriptComponentContext.id);
      },
      cloneEntity: (entityId: string, override: Partial<ISceneObject>) => {
        return new Promise<string | null>((resolve) => {
          const result = cloneEntity(entityId, override);

          if (!result) {
            return resolve(result);
          }

          forceRerender();

          const handler = ({ entityId }: NewContextEventValue) => {
            if (entityId === result) {
              entityManagerEvents.off("newContext", handler);
              resolve(result);
            }
          };

          entityManagerEvents.on("newContext", handler);
        });
      },
      removeEntity: (entityId: string) => {
        const result = markEntityRemoved(entityId);
        forceRerender();

        return result;
      },
      getComponentContext: <TContext extends ComponentContext = ComponentContext>(
        entityId: string,
        componentId: string
      ) => {
        const context = getComponentContext<TContext>(entityId, componentId);

        return context ?? null;
      },
      addComponent: <TComponent extends IComponent>(entityId: string, component: TComponent) => {
        const entity = getEntity(entityId);

        if (entity) {
          const newEntity = {
            ...entity,
            components: [...entity.components, component],
          };

          updateEntity(entityId, newEntity);
          // TODO: It's a crutch, but it's safe option to rerender scene
          forceRerender();
        }
      },
      updateComponent: <TComponent extends IComponent>(entityId: string, component: Update<TComponent>) => {
        const entity = getEntity(entityId);

        if (entity) {
          const newEntity = {
            ...entity,
            components: entity.components.map((c) => (c.id === component.id ? { ...c, ...component } : c)),
          };

          updateEntity(entityId, newEntity);
          // TODO: It's a crutch, but it's safe option to rerender scene
          forceRerender();
        }
      },
      removeComponent(entityId: string, componentId: string) {
        context.removeComponents(entityId, [componentId]);
      },
      removeComponents(entityId: string, componentIds: string[]) {
        const entity = getEntity(entityId);
        const idsSet = new Set(componentIds);

        if (entity) {
          const newEntity = {
            ...entity,
            components: entity.components.filter((component) => !idsSet.has(component.id)),
          };

          updateEntity(entityId, newEntity);
          forceRerender();
        }
      },
      userHasAccessToScene: async (sceneId: string) => {
        if (!sceneAccessCacheRef.current[sceneId]) {
          sceneAccessCacheRef.current[sceneId] = hasSceneAccess(sceneId)
            .then((res) => res.body.hasAccess ?? false)
            .catch(() => false);
        }

        return sceneAccessCacheRef.current[sceneId];
      },
      playCutscene: async (cutsceneId: string) => {
        await enterCutscene(cutsceneId);
      },
      lockPointer: async () => {
        await lockPointer();
      },
      unlockPointer: async () => {
        await unlockPointer();
      },
      setMobileControlsVisibility: (visible: boolean) => {
        setMobileControlsVisibility(visible);
      },
      updateCameraControls: (enabled: boolean) => {
        setCameraState({ enabled });
      },
      getAsset: (id: string) => getAsset(id) ?? null,
      getAssetByName: (assetName: string) => getAssetByName(assetName) ?? null,
      getOverlays: () => getOverlays(),
      updateOverlay: (overlay: Update<ScreenOverlayItem>) => {
        updateOverlay(overlay);
      },
      playPlayerAnimation: (avatarAnimationId: string) => {
        playPlayerAnimation(avatarAnimationId);
      },
      stopPlayerAnimation: (avatarAnimationId: string) => {
        stopPlayerAnimation(avatarAnimationId);
      },
      updateCameraMode: (cameraMode: "firstPerson" | "thirdPerson") => {
        cameraMode === "firstPerson" ? requestCameraZoomIn() : requestCameraZoomOut();
      },
      getUIEntityContext: <TContext extends UIEntityContext>(uiEntityId: string) => {
        if (!uiEntityId) {
          return null;
        }

        const data = decodeUIEntityId(uiEntityId);

        switch (data.type) {
          case "scene":
            return getUIEntityContext<TContext>(data.entityId, data.uiEntityId) ?? null;
          case "overlay":
            return getOverlayUIEntityContext<TContext>(data.overlayId, data.uiEntityId) ?? null;
        }

        return null;
      },
      getPlatform: () => platform,
      getPhysicsManager: () => physicsManager,
      getTargetPlatforms: () => useSceneData.getState().sceneState?.targetPlatforms ?? [],
      sendUserEvent: (eventType: string, data?: any) => {
        sendUserEvent({ eventType, data });
      },
    };

    return context;
  }, []);
};

export type SceneContext = ReturnType<typeof useSceneContext>;

const isScript = (
  componentContext: ComponentContext
): componentContext is ScriptComponentContext | JSScriptComponentContext => {
  return componentContext.type === ComponentType.SCRIPT || componentContext.type === ComponentType.JS_SCRIPT;
};
