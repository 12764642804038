import { NodeEvalContext } from "~/libs/behave-graph";
import { TrajectoryComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, TrajectorySocket } from "../../sockets";

export class TrajectoryPauseNode extends BaseNode {
  static readonly type = "action/trajectoryPause";
  static readonly label = "Trajectory Pause";

  inputs = [new FlowSocket("flow"), new TrajectorySocket("trajectory")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<TrajectoryComponentContext>(context, "trajectory")?.pause();
  }
}
