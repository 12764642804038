import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";

export const ProjectsListContainer = styled(Stack)`
  width: 100%;
  margin-bottom: 18px;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.primary.gray200};
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.primary.gray600};
  }
`;
