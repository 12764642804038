import CameraControls from "camera-controls";
import * as THREE from "three";

CameraControls.install({ THREE: THREE });

export class CameraControlsImpl extends CameraControls {
  public dollyPublic = (delta: number, x: number, y: number): void => {
    this._dollyInternal(delta, x, y);
  };
}
