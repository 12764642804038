import { Vector3Value } from "~/types/Variable";
import { MathUtils } from "three";

export const degToRad = (vector3: Vector3Value) => {
  vector3.x = MathUtils.degToRad(vector3.x);
  vector3.y = MathUtils.degToRad(vector3.y);
  vector3.z = MathUtils.degToRad(vector3.z);
};

export const radToDeg = (vector3: Vector3Value) => {
  vector3.x = MathUtils.radToDeg(vector3.x);
  vector3.y = MathUtils.radToDeg(vector3.y);
  vector3.z = MathUtils.radToDeg(vector3.z);
};
