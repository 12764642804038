import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { useUnit } from "effector-react";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { lockPointer } from "~/view-scene/PointerLocker";
import { $isFullscreen, enterFullscreen, exitFullscreen } from "~/view-scene/fullscreen";
import { useUpdateEffect } from "react-use";
import { diveIn } from "./models";

export const DesktopDiveIn = () => {
  const gl = useThree((state) => state.gl);
  const fullscreen = useUnit($isFullscreen);
  const { sessionStatus, updateStatus } = useSessionStatus((state) => ({
    sessionStatus: state.sessionStatus,
    updateStatus: state.updateStatus,
  }));
  const sessionStatusRef = useRef(sessionStatus);
  sessionStatusRef.current = sessionStatus;

  useUpdateEffect(() => {
    if (!fullscreen && sessionStatusRef.current === "in_progress") {
      updateStatus("onpause");
    }
  }, [fullscreen]);

  useUpdateEffect(() => {
    if (sessionStatus === "onpause") {
      exitFullscreen();
    }
  }, [sessionStatus]);

  useEffect(() => {
    const subscription = diveIn.watch(() => {
      enterFullscreen()
        .then(() => lockPointer())
        .catch((err) => console.log(err));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [gl]);

  return null;
};
