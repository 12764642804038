import { Alert as MuiAlert, AlertProps, styled } from "@mui/material";
import { palette } from "~/theme";
import { Note } from "~/common/components/Icon";

const AttentionAlert = styled((props: AlertProps) => <MuiAlert icon={<Note />} {...props} />)(() => ({
  color: palette.primary.white,
  backgroundColor: palette.primary.gray100,
  borderRadius: "8px",
  fontSize: "14px",
  lineHeight: "24px",
  alignItems: "center",
  padding: "10px 12px",
  fontFamily: "Plus Jakarta Sans !important",
  fontWeight: "400",
}));

export default AttentionAlert;
