import { Stack } from "@mui/material";
import { useProfile } from "~/entities/profile";
import { UserMenu } from "../UserMenu";
import { DesktopCategories } from "./DesktopCategories";
import { DesktopLogo } from "./DesktopLogo";

export const DesktopHeader = () => {
  const { isLoggedIn, emailConfirmed } = useProfile((state) => ({
    isLoggedIn: state.isAuthorised(),
    emailConfirmed: state.profile?.emailConfirmed ?? false,
  }));

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <DesktopLogo />
      <DesktopCategories />
      <UserMenu />
    </Stack>
  );
};
