import { useEffect } from "react";
import { video360Controls } from "~/view-scene/ControlsSystem";
import { Layer } from "~/view-scene/layers";
import { exitVideo360 } from "~/view-scene/runtime";
import { RenderVideo360 } from "./RenderVideo360";

export type Video360Props = {
  active: boolean;
  url: string;
  assetName: string;
  isStream: boolean;
};

export const Video360 = ({ active, assetName, isStream, url }: Video360Props) => {
  useExit(active);

  return <RenderVideo360 url={url} assetName={assetName} isStream={isStream} active={active} layer={Layer.video360} />;
};

const useExit = (active: boolean) => {
  useEffect(() => {
    if (!active) {
      return;
    }

    const exitHandler = () => {
      exitVideo360();
    };

    video360Controls.exit.on("fire", exitHandler);

    return () => {
      video360Controls.exit.off("fire", exitHandler);
    };
  }, [active]);
};
