import { forwardRef, ReactNode, useImperativeHandle, useRef } from "react";
import { Stack, StackProps, Typography } from "@mui/material";
import { palette } from "~/theme";
import { useStoreMap } from "effector-react";
import { $uiSettings, collapseInspectorContainer, expandInspectorContainer } from "~/hephaestus/builderUIState";
import { DropDown } from "~/common/components/Icon";
import { IconButton, IconButtonProps } from "./IconButton";

export type CollapsibleProps = StackProps & {
  id: string;
  title: string;
  headerContent?: ReactNode | ReactNode[];
  active?: boolean;
  expandCollapseEnabled?: boolean;
  children?: ReactNode | ReactNode[];
};

export type CollapsibleHandle = {
  expand: VoidFunction;
  collapse: VoidFunction;
  scrollIntoView: VoidFunction;
};

export const Collapsible = forwardRef<CollapsibleHandle, CollapsibleProps>(
  (
    { id, title, headerContent, active = false, expandCollapseEnabled = true, children, sx, ...rest }: CollapsibleProps,
    ref
  ) => {
    const expanded = useStoreMap(
      $uiSettings,
      (uiSettings) => uiSettings?.expandedInspectorContainers.includes(id) ?? false
    );

    const innerContentRef = useRef<HTMLDivElement>(null);

    const expandCollapse: NonNullable<IconButtonProps["onClick"]> = (e) => {
      e.stopPropagation();

      if (expanded) {
        collapseInspectorContainer(id);
      } else {
        expandInspectorContainer(id);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        expand: () => expandInspectorContainer(id),
        collapse: () => collapseInspectorContainer(id),
        scrollIntoView: () =>
          innerContentRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          }),
      }),
      []
    );

    return (
      <Stack
        direction="column"
        sx={{
          gridGap: "4px",
          borderRadius: "8px",
          background: palette.primary.gradient500,
          margin: "0 2px",
          ...(active && {
            border: "1px solid",
            borderColor: palette.primary.violet,
          }),
          ...sx,
        }}
        {...rest}
        ref={innerContentRef}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: "2px 4px",
            gridGap: "10px",
          }}
          ref={ref}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gridGap: "4px",
            }}
          >
            {expandCollapseEnabled && (
              <IconButton onClick={expandCollapse} size="small">
                <DropDown
                  size="small"
                  color="primary.white"
                  sx={{ transform: expanded ? "rotate(0deg)" : "rotate(-90deg)" }}
                />
              </IconButton>
            )}
            <Typography className="tooltip-main" color="primary.gray600" fontWeight="600" variant="body1">
              {title}
            </Typography>
          </Stack>
          {headerContent}
        </Stack>
        {(expandCollapseEnabled ? expanded : true) && (
          <Stack
            direction="column"
            sx={{
              gridGap: "4px",
              padding: "0 0 6px 0",
            }}
          >
            {children}
          </Stack>
        )}
      </Stack>
    );
  }
);
