import { ValueType } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { AssetValue } from "./assetValue";

export type AssetVideoValue = AssetValue;

export const assetVideoValue = new ValueType(
  "assetVideo",
  defaultAsset,
  (json: AssetVideoValue) => json,
  (value: AssetVideoValue) => value
);
