import { NodeEvalContext } from "~/libs/behave-graph";
import { ComponentType } from "~/types/ComponentType";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, EntitySocket, StringSocket } from "../../sockets";
import { AnimationValue } from "../../values";

export class FindEntityAnimationNode extends BaseNode {
  static readonly type = "action/findEntityAnimation";
  static readonly label = "Find Animation";
  static readonly pure = true;

  inputs = [new EntitySocket("entity"), new StringSocket("name")];
  outputs = [new AnimationSocket("animation")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = null;
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    if (!node.storage.output) {
      node.storage.output = this.findAnimation(context, node);
    }

    context.writeOutput<AnimationValue>("animation", node.storage.output);
  }

  private findAnimation(context: NodeEvalContext, node: BaseBehaveNode): AnimationValue {
    const animationName = context.readInput<string>("name");
    const entityContext = node.readEntity(context, "entity");

    if (!entityContext) {
      return {
        entityId: null,
        componentId: null,
      };
    }

    const animationContext = Object.values(entityContext.components ?? {}).find((component) => {
      return (
        (component.type === ComponentType.ANIMATION ||
          component.type === ComponentType.SPRITE_ANIMATION ||
          component.type === ComponentType.TWEEN_ANIMATION) &&
        component.name === animationName
      );
    });

    if (!animationContext) {
      return {
        entityId: null,
        componentId: null,
      };
    }

    return {
      entityId: entityContext.id,
      componentId: animationContext.id,
    };
  }
}
