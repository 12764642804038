import create from "zustand";
import { UINotification } from "./types";

export type UseNotificationsStateType = {
  notifications: UINotification[];
  addNotification: (notifiaction: UINotification) => void;
  getNextNotification: () => UINotification | undefined;
};

export const useNotificationsState = create<UseNotificationsStateType>((set, get) => ({
  notifications: [],

  addNotification: (notifiaction: UINotification) => {
    get().notifications.push(notifiaction);
  },

  getNextNotification: () => {
    const notifications = get().notifications;
    const nextNotification = notifications.shift();
    set({ notifications: notifications });

    return nextNotification;
  },
}));
