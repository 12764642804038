import { MutableRefObject, useMemo, useRef } from "react";
import type { EntityContext, ComponentContext } from "../types";

export const useComponentContext = <TComponentContext extends ComponentContext>(
  entityContextRef: MutableRefObject<EntityContext | null> | undefined,
  id: string,
  init: () => TComponentContext,
  deps: any[] = []
) => {
  const initFunctionRef = useRef(init);
  initFunctionRef.current = init;

  const entityContextContainer = useRef(entityContextRef);
  entityContextContainer.current = entityContextRef;
  const componentContextRef = useRef<TComponentContext | null>(null);

  componentContextRef.current = useMemo(() => {
    const contextRef = entityContextContainer.current;

    if (!contextRef || !contextRef.current) {
      return null;
    }
    const context = initFunctionRef.current();
    contextRef.current.components[id] = initFunctionRef.current();

    return context;
  }, deps);

  return componentContextRef;
};
