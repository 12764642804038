import { memo } from "react";
import ISkySceneObject from "~/types/ISkySceneObject";
import { RenderSky } from "./RenderSky";
import { useEntity } from "~/view-scene/utils/useEntity";

type SkyEntityProps = {
  entityId: string;
};

export const SkyEntity = memo(({ entityId }: SkyEntityProps) => {
  const entity = useEntity<ISkySceneObject>(entityId);

  return <RenderSky entity={entity} />;
});
