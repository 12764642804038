import { Footer, Header } from "~/common/components";
import { Stack, styled } from "@mui/material";
import { TelegramAdsSettings } from "./TelegramAdsSettings";

export function TelegramAdsPage() {
  return (
    <Stack direction="column" height="100%" minHeight="100vh">
      <Header />
      <Container>
        <TelegramAdsSettings />
      </Container>
      <Footer />
    </Stack>
  );
}

const Container = styled(Stack)`
  height: 100%;
  flex-grow: 1;
  padding: 0 48px;
  width: calc(100vw - 48px * 2);
  margin-top: 20px;
  @media (max-width: 600px) {
    padding: 0 16px;
    width: calc(100vw - 16px * 2);
  }
`;
