import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { StringSocket } from "../../sockets";

export class StringConstantNode extends BaseNode {
  static readonly type = "logic/stringConstant";
  static readonly label = "String";
  static readonly category = "Variable";

  inputs = [new StringSocket("a")];
  outputs = [new StringSocket("value")];

  eval(context: NodeEvalContext) {
    const str = context.readInput<string>("a");

    context.writeOutput<string>("value", str);
  }
}
