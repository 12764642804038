import { useController } from "@react-three/xr";
import { useEffect } from "react";
import { Mesh } from "three";

export type AttachSpriteXRProps = {
  sprite: Mesh;
};

export const AttachSpriteXR = ({ sprite }: AttachSpriteXRProps) => {
  const rightController = useController("right");

  useEffect(() => {
    if (rightController) {
      sprite.rotation.set(-Math.PI / 2, 0, 0.174533);
      sprite.position.set(0, -0.01, -0.18);
      rightController.grip.add(sprite);

      return () => {
        rightController.grip.remove(sprite);
      };
    }
  }, [sprite, rightController]);

  return null;
};
