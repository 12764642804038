import { memo, RefObject, useMemo, useRef, useState } from "react";
import { Group } from "three";
import { ActionableComponentContext, EntityContext, useComponentContext } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { IActionComponent } from "~/types/IActionComponent";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import { ComponentType } from "~/types/ComponentType";

type ActionableComponentProps = {
  componentDto: IActionComponent;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const ActionableComponent = memo(({ componentDto, objectRef, contextRef }: ActionableComponentProps) => {
  const mode = useSessionStatus((state) => state.mode);
  const [isActive, setIsActive] = useState(true);
  const isActiveRef = useRef(isActive);
  isActiveRef.current = isActive;

  const { visibleDistance = 20 } = componentDto;

  const handleAction = () => {
    contextRef.current?.events.emit("action");
  };

  const icon = useMemo(() => {
    if (mode === "mobile") {
      return "/static/img/viewer/actionable-component/sprite-mobile.png";
    }

    if (mode === "vr") {
      return "/static/img/viewer/actionable-component/sprite-vr.png";
    }

    return "/static/img/viewer/actionable-component/sprite-desktop.png";
  }, [mode]);

  const context: ActionableComponentContext = {
    id: componentDto.id,
    type: ComponentType.ACTIONABLE,
    enable: () => setIsActive(true),
    disable: () => setIsActive(false),
    isActive: () => isActiveRef.current,
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  return (
    <ActionIcon
      visible={isActive}
      icon={icon}
      visibleDistance={visibleDistance}
      onActionButtonReleased={handleAction}
      objectRef={objectRef}
    />
  );
});
