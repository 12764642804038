import { $audioManager } from "./audioManager";
import { Audio, PositionalAudio } from "three";
import { isThreeAudio } from "../utils";
import { attach } from "effector";

export const removeAudio = attach({
  source: $audioManager,
  effect: (audioManager, node: PositionalAudio | Audio) => {
    let outputNode: any = node;
    if (isThreeAudio(node)) {
      outputNode = node.gain;
    }
    outputNode.disconnect();
  },
});
