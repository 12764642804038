import { repeat } from "lodash-es";

type Storage = {
  set: Set<number>;
  arr: number[];
  ended: number[];
};

export class Collisions {
  private newCollisionFlag: number;
  private invertedNewCollisionFlag: number;
  private collisions = new Map<number, Storage>();
  private prevCollisions = new Map<number, Storage>();

  constructor(maxBodies: number) {
    this.newCollisionFlag = this.getNewCollisionFlag(maxBodies);
    this.invertedNewCollisionFlag = this.invert(this.newCollisionFlag);
  }

  clear() {
    this.switch();
    this.clearMap(this.collisions);
  }

  collide(uid0: number, uid1: number) {
    this.setCollision(uid0, uid1);
    this.setCollision(uid1, uid0);
  }

  calcEnded() {
    for (const [prevUid, prevStorage] of this.prevCollisions.entries()) {
      if (prevStorage.set.size > 0) {
        let storage = this.collisions.get(prevUid)!;

        if (!storage) {
          storage = this.createStorage();
          this.collisions.set(prevUid, storage);
        }

        storage.ended = [...prevStorage.set.values()];
      }
    }
  }

  getCollisions(uid: number) {
    return this.collisions.get(uid)?.arr;
  }

  getEndedCollisions(uid: number) {
    return this.collisions.get(uid)?.ended;
  }

  remove(uid: number) {
    this.collisions.delete(uid);
  }

  unmarkIsNew(uid: number) {
    return uid & this.invertedNewCollisionFlag;
  }

  isNew(uid: number) {
    return Boolean(uid & this.newCollisionFlag);
  }

  private setCollision(uid0: number, uid1: number) {
    let storage = this.collisions.get(uid0)!;

    if (!storage) {
      storage = this.createStorage();
      this.collisions.set(uid0, storage);
    }

    const prevStorage = this.prevCollisions.get(uid0);

    if (!storage.set.has(uid1)) {
      const isNew = !prevStorage?.set.delete(uid1);
      const markedUid = isNew ? this.markIsNew(uid1) : uid1;

      storage.set.add(uid1);
      storage.arr.push(markedUid);
    }
  }

  private switch() {
    const tmp = this.prevCollisions;
    this.prevCollisions = this.collisions;
    this.collisions = tmp;
  }

  private clearMap(map: Map<number, Storage>) {
    for (const storage of map.values()) {
      storage.set.clear();
      storage.arr.length = 0;
      storage.ended.length = 0;
    }
  }

  private getNewCollisionFlag = (maxBodies: number) => {
    const len = maxBodies.toString(2).length;

    return parseInt(`1${repeat("0", len)}`, 2);
  };

  private invert(num: number) {
    return parseInt(
      num
        .toString(2)
        .split("")
        .map((n) => (n === "1" ? "0" : "1"))
        .join(""),
      2
    );
  }

  private markIsNew(uid: number) {
    return uid | this.newCollisionFlag;
  }

  private createStorage(): Storage {
    return { set: new Set(), arr: [], ended: [] };
  }
}
