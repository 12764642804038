import { ForwardedRef, forwardRef, memo } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { getVariantUrl } from "~/entities/assets";
import IInstancedMeshSceneObject from "~/types/IInstancedMeshSceneObject";
import IModelAsset from "~/types/IModelAsset";
import { RenderInstancedModel, RenderInstancedModelControls } from "./RenderInstancedModel";
import { RenderInstancedModelWithOverrideMaterial } from "./RenderInstancedModelWithOverrideMaterial";

export type InstancedModelProps = {
  entity: IInstancedMeshSceneObject;
};

export type InstancedModelControls = RenderInstancedModelControls;

export const InstancedModel = memo(
  forwardRef(({ entity }: InstancedModelProps, ref: ForwardedRef<InstancedModelControls>) => {
    const sceneData = useSceneData();

    const { asset: assetId, variantId, receiveShadow, castShadow, overrideMaterial, material } = entity;
    const asset = sceneData.getAsset<IModelAsset>(assetId);

    if (!asset) {
      return null;
    }

    const modelUrl = getVariantUrl(asset, variantId);
    const renderWithCustomMaterial = overrideMaterial && material;

    return renderWithCustomMaterial ? (
      <RenderInstancedModelWithOverrideMaterial
        modelUrl={modelUrl}
        materialAssetId={material}
        castShadow={castShadow}
        receiveShadow={receiveShadow}
        transformations={entity.transformations}
        ref={ref}
      />
    ) : (
      <RenderInstancedModel
        modelUrl={modelUrl}
        castShadow={castShadow}
        receiveShadow={receiveShadow}
        transformations={entity.transformations}
        ref={ref}
      />
    );
  })
);
