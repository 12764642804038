import { Box, styled, ThemeProvider } from "@mui/material";
import Muuri from "muuri";
import { useEffect, useRef, useState } from "react";
import { gamesByCategories } from "~/common/data/games";
import { gamerTheme } from "~/theme";
import { Grid } from "../../home/Grid";
import { HomePageContainer } from "../../home/HomePageContainer";
import { calculateGridWidth } from "../../home/utils";
import { NavigationHeader } from "../components";

const telegramGames = gamesByCategories["telegram"];

export const TelegramHomePage = () => {
  const [gridWidth, setGridWidth] = useState(calculateGridWidth());
  const muuriRef = useRef<Muuri | null>(null);

  useEffect(() => {
    const handler = () => {
      setGridWidth(calculateGridWidth());
    };

    window.Telegram?.WebApp.expand();

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return (
    <ThemeProvider theme={gamerTheme}>
      <HomePageContainer>
        <NavigationHeader />
        <GridContainer style={{ width: gridWidth }}>
          <StyledGrid games={telegramGames} muuriRef={muuriRef} />
        </GridContainer>
      </HomePageContainer>
    </ThemeProvider>
  );
};

const GridContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;
