import { Matrix4, Object3D } from "three";

export const calcRelativeTransformation = (child: Object3D, targetAncestor: Object3D) => {
  let node: Object3D | null = child;
  const matrixes: Matrix4[] = [];
  const result = new Matrix4().identity();

  while (node && node !== targetAncestor) {
    const m = new Matrix4().compose(node.position, node.quaternion, node.scale);

    matrixes.push(m);
    node = node.parent;
  }

  for (let i = matrixes.length - 1; i >= 0; i--) {
    result.multiply(matrixes[i]);
  }

  return result;
};
