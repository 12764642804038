const regexp = /([\w\d\.\-\(\)\[\]\{\}]+)_([0-9]{4})/;

export const getDuplicateName = (name: string, otherNames: string[]) => {
  const match = regexp.exec(name);

  if (match) {
    const existingEntityNameBaseName = match[1];
    const similarNames = otherNames.filter((otherName) => otherName.includes(existingEntityNameBaseName));
    const existingDigits = [];
    for (let similarName of similarNames) {
      const similarNameMatch = regexp.exec(similarName);
      if (similarNameMatch && similarNameMatch[2]) {
        existingDigits.push(similarNameMatch[2]);
      }
    }
    existingDigits.sort();
    const biggestDigit = existingDigits[existingDigits.length - 1];
    const nextDigit = String(parseInt(biggestDigit) + 1).padStart(4, "0");
    return `${existingEntityNameBaseName}_${nextDigit}`;
  } else {
    return `${name}_0001`;
  }
};
