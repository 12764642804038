import { createEffect } from "effector";
import { uploadAssetThumbnail } from "~/api/assets.api";
import IAsset from "~/types/IAsset";

export type UploadThumbnailRequestPayload = { assetId: string; file: File | string };

export const uploadThumbnailFx = createEffect(async ({ assetId, file }: UploadThumbnailRequestPayload) => {
  const { body } = await uploadAssetThumbnail(assetId, file);

  return body as IAsset;
});
