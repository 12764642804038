import { useEffect, useMemo } from "react";
import { MeshBasicMaterial, sRGBEncoding, VideoTexture } from "three";

type RenderStreamProps = {
  mediaStream: MediaStream;
  screenShareMaterial: MeshBasicMaterial;
};

export function RenderStream({ mediaStream, screenShareMaterial }: RenderStreamProps) {
  const videoElement = useMemo(
    () =>
      Object.assign(document.createElement("video"), {
        crossOrigin: "anonymous",
        playsInline: true,
        muted: true,
        defaultMuted: true,
      }),
    []
  );

  useEffect(() => {
    videoElement.srcObject = mediaStream;
    videoElement.play();

    screenShareMaterial.map = Object.assign(new VideoTexture(videoElement), { encoding: sRGBEncoding });
    screenShareMaterial.needsUpdate = true;
  }, [mediaStream]);

  return null;
}
