import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BooleanSocket, FlowSocket } from "../../sockets";
import { BaseNode } from "../../base";

export class BranchNode extends BaseNode {
  static readonly type = "flow/branch";
  static readonly label = "Branch";
  static category: NodeCategory = "Flow";

  inputs = [new FlowSocket("flow"), new BooleanSocket("condition")];
  outputs = [new FlowSocket("true"), new FlowSocket("false")];

  eval(context: NodeEvalContext, node: any) {
    context.commit(context.readInput<boolean>("condition") === true ? "true" : "false");
  }
}
