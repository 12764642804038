import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, NodeContext } from "../../base";
import { Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class VectorOperationNode extends BaseNode {
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";

  inputs = [new Vector3Socket("a"), new Vector3Socket("b")];
  outputs = [new Vector3Socket("result")];

  constructor(
    context: NodeContext,
    private operation: (a: Vector3Value, b: Vector3Value, node: BaseBehaveNode) => Vector3Value
  ) {
    super(context);
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const a = context.readInput<Vector3Value>("a");
    const b = context.readInput<Vector3Value>("b");
    context.writeOutput<Vector3Value>("result", this.operation(a, b, node));
  }
}
