import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { BaseBehaveNode, BaseSocket, BaseNode, NodeContext, NodeMeta } from "../../base";
import { AssetSocket } from "../../sockets";
import { AssetValue } from "../../values";
import { NodeSpec } from "~/types/ScriptAsset";
import { getAssetId, getVariantId } from "../../utils";

export class AssetNode extends BaseNode {
  static readonly type: string = "variable/asset";
  static readonly pure = true;

  constructor(context: NodeContext, private socket: BaseSocket = new AssetSocket("asset")) {
    super(context);
    this.outputs = [socket];
  }

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.assetId = node.metadata?.assetId ?? null;
    node.storage.output.variantId = node.metadata?.variantId ?? null;

    context.writeOutput<AssetValue>(this.socket.name, node.storage.output);
  }

  getMeta(nodeSpec: NodeSpec): NodeMeta {
    const assetId = getAssetId(nodeSpec) ?? null;
    const variantId = getVariantId(nodeSpec) ?? undefined;

    const meta: NodeMeta = {
      resources: {
        assets: [],
      },
    };

    if (assetId) {
      meta.resources.assets.push({
        id: assetId,
        variantId,
      });
    }

    return meta;
  }
}
