import { Stack, styled } from "@mui/material";

export const Container = styled(Stack)`
  position: relative;
  padding: 0 48px;
  width: calc(100vw - 48px * 2);
  @media (max-width: 600px) {
    padding: 0 16px;
    width: calc(100vw - 16px * 2);
  }
`;
