import { NodeEvalContext } from "~/libs/behave-graph";
import { AudioContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AudioSocket, NumberSocket } from "../../sockets";

export class AudioDurationNode extends BaseNode {
  static readonly type = "action/audioDuration";
  static readonly label = "Audio duration";
  static readonly pure = true;

  inputs = [new AudioSocket("audio")];
  outputs = [new NumberSocket("duration")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const audio = node.readEntity<AudioContext>(context, "audio");
    const duration = audio?.getDuration() ?? -1;

    context.writeOutput<number>("duration", duration);
  }
}
