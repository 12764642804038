import { cloneDeep } from "lodash-es";
import { getDefaultValue } from "~/entities/variable";
import { ScriptArgument, ScriptArguments } from "~/types/IScriptComponent";
import { ScriptVariable } from "~/types/ScriptAsset";
import { VariablesManager } from "../VariablesManager";
import { getValueSetter, isArgumentOverride, isVariablePublic } from "../utils";
import { RuntimeVariable } from "./types";

export class BehaveVariablesManager extends VariablesManager {
  private variables: Record<string, RuntimeVariable>;
  private variablesByName: Record<string, RuntimeVariable>;

  constructor(variables: ScriptVariable[], args: ScriptArguments) {
    super();
    this.variables = this.initVariables(cloneDeep(variables), args);
    this.variablesByName = Object.values(this.variables).reduce((acc, variable) => {
      acc[variable.name] = variable;
      return acc;
    }, {} as Record<string, RuntimeVariable>);
  }

  getValue(variableId: string) {
    const variable = this.variables[variableId];

    if (!variable) {
      throw new Error(`No variable with id ${variableId}`);
    }

    return variable.value;
  }

  setValue(variableId: string, value: any) {
    this.variables[variableId].set(value);
  }

  getValueByName(name: string) {
    const variable = this.variablesByName[name];

    if (!variable) {
      throw new Error(`No variable with name ${name}`);
    }

    return variable.value;
  }

  setValueByName(name: string, value: any) {
    const variable = this.variablesByName[name];

    if (!variable) {
      throw new Error(`No variable with name ${name}`);
    }

    return variable.set(value);
  }

  private initVariables(variables: ScriptVariable[], args: ScriptArguments) {
    return variables.reduce((acc, variable) => {
      const argument = args[variable.id];

      acc[variable.id] = {
        ...variable,
        value: this.initVariableValue(variable, argument),
        set: getValueSetter(variable),
      };

      return acc;
    }, {} as Record<string, RuntimeVariable>);
  }

  private initVariableValue(variable: ScriptVariable, argument: ScriptArgument | undefined) {
    if (isArgumentOverride(argument) && isVariablePublic(variable)) {
      return argument!.value;
    }

    if (variable.initialValue) {
      return variable.initialValue;
    }

    return getDefaultValue(variable.type);
  }
}
