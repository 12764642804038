import { NodeEvalContext } from "~/libs/behave-graph";
import { uiSystemEvents } from "~/view-scene/ui";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, UIEntitySocket } from "../../sockets";
import { UIEntityValue } from "../../values";

export class UIOnPressNode extends BaseNode {
  static readonly type = "event/uiOnPressNode";
  static readonly label = "On Press UI";
  static readonly category = "Event";

  evaluateOnStartup = true;
  async = true;
  interruptibleAsync = true;

  inputs = [new UIEntitySocket("uiEntity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityValue = context.readInput<UIEntityValue>("uiEntity");
    const entityId = entityValue?.entityId;

    if (!entityId) {
      return;
    }

    uiSystemEvents.addListener("press", (event) => {
      if (entityId.endsWith(event.entityId)) {
        context.commit("flow");
        context.graphEvaluator.executeAll();
      }
    });
  }
}
