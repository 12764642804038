import { NodeContext } from "../../base";
import { IsPlatformNode } from "./IsPlatformNode";

export class IsDesktopNode extends IsPlatformNode {
  static readonly type = "action/isDesktop";
  static readonly label = "Is Desktop";

  constructor(context: NodeContext) {
    super(context, (device) => device === "desktop");
  }
}
