import { NodeEvalContext } from "~/libs/behave-graph";
import { CameraContext } from "~/view-scene/runtime/contexts";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class CameraSetFovNode extends BaseNode {
  static readonly type = "camera/setFov";
  static readonly label = "Camera Set FOV";

  inputs = [new FlowSocket("flow"), new NumberSocket("fov")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const fov = context.readInput<number>("fov");
    const cameraContext = node.sceneContext.getEntityContext<CameraContext>("camera");

    cameraContext && (cameraContext.fov = fov);
  }
}
