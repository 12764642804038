import { EventEmitter } from "eventemitter3";
import { RefObject } from "react";
import type { EntityContext } from "./types";

export type NewContextEventValue = {
  entityId: string;
  contextRef: RefObject<EntityContext>;
};

export interface EntityManagerEvents {
  emit(event: "newContext", value: NewContextEventValue): boolean;
  emit(event: "newContext", value: NewContextEventValue): boolean;

  on(event: "newContext", listener: (value: NewContextEventValue) => void): this;
  on(event: "newContext", listener: (value: NewContextEventValue) => void): this;

  once(event: "newContext", listener: (value: NewContextEventValue) => void): this;
  once(event: "newContext", listener: (value: NewContextEventValue) => void): this;

  off(event: "newContext", listener: (value: NewContextEventValue) => void): this;
  off(event: "newContext", listener: (value: NewContextEventValue) => void): this;
}

export class EntityManagerEvents extends EventEmitter {}
