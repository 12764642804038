import { useParams, useSearchParams } from "react-router-dom";
import { NativeGame } from "./NativeGame";
import { ExternalGame } from "./ExternalGame";

export function TelegramGameRoute() {
  const { urlName } = useParams();
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get("accessToken");

  if (!urlName || !accessToken) {
    return null;
  }

  switch (urlName) {
    case "kingdomSurvivor":
    case "parkingJam":
    case "polygami":
    case "candyland":
    case "endlessSiege":
    case "endlessSiege2":
    case "endlessSiegeGamee":
    case "furiousRoad":
    case "memoryMatch":
    case "boxTower":
    case "kingSolitaire":
    case "sudokuMaster":
    case "formulaRush":
    case "riseUp":
    case "zombieAttack":
    case "desertWarrior":
    case "dropAndMerge":
    case "crazyFishing":
    case "slingTomb":
    case "coinMatch":
    case "rudeRaces":
    case "dungeon2048":
    case "hangman":
    case "battleship":
    case "chess":
    case "chessSingle":
    case "boltClicker":
    case "battalionCommander":
    case "wordCake":
      return <ExternalGame key={urlName} urlName={urlName} accessToken={accessToken} />;
    default:
      return <NativeGame key={urlName} urlName={urlName} accessToken={accessToken} />;
  }
}
