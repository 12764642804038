import { Icon, IconProps } from "./Icon";

export type ImageEntityProps = IconProps;

export const ImageEntity = (props: ImageEntityProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M2 5.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C3.52 2 4.08 2 5.2 2h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C14 3.52 14 4.08 14 5.2v5.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C12.48 14 11.92 14 10.8 14H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 12.48 2 11.92 2 10.8V5.2Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6.333 4 10h8L9 5 7.333 7.333 6 6.333Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
