import ICoordinates from "~/types/ICoordinates";
import { Vector3Value } from "~/types/Variable";

export const setVector3ValueFromXYZ = (vector3Value: Vector3Value, vector3: ICoordinates) => {
  vector3Value.x = vector3.x;
  vector3Value.y = vector3.y;
  vector3Value.z = vector3.z;

  return vector3Value;
};
