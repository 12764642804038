import { hasType } from "common/utils/hasType";
import { MutableRefObject, RefObject } from "react";
import { Group } from "three";
import { ComponentType } from "~/types/ComponentType";
import { IActionComponent } from "~/types/IActionComponent";
import { IAttachToComponent } from "~/types/IAttachToComponent";
import IComponent from "~/types/IComponent";
import { IConstraintComponent } from "~/types/IConstraintComponent";
import IFollowTargetComponent from "~/types/IFollowTargetComponent";
import ILinkComponent from "~/types/ILinkComponent";
import IRigidBodyComponent from "~/types/IRigidBodyComponent";
import IScriptComponent from "~/types/IScriptComponent";
import ITrajectoryComponent from "~/types/ITrajectoryComponent";
import IViewInARComponent from "~/types/IViewInARComponent";
import { JSScriptComponent as JSScriptComponentType } from "~/types/JSScriptComponent";
import {
  DynamicVisibilityComponent as DynamicVisibilityComponentDescriptor,
  FollowTargetSteeringComponent as FollowTargetSteeringComponentDescriptor,
  InteractiveComponent as InteractiveComponentDescriptor,
  ModelAnimationComponent as ModelAnimationComponentDescriptor,
  SpriteAnimationComponent as SpriteAnimationComponentDescriptor,
  TweenAnimationComponent as TweenAnimationComponentDescriptor,
} from "~/types/component";
import type { EntityContext } from "~/view-scene/runtime";
import { ActionableComponent } from "./ActionableComponent";
import { AttachToComponent } from "./AttachToComponent";
import { ConstraintComponent } from "./ConstraintComponent";
import { DynamicVisibilityComponent } from "./DynamicVisibilityComponent";
import { JSScriptComponent } from "./JSScriptComponent";
import { RigidBodyComponent } from "./RigidBody";
import { ScriptComponent } from "./ScriptComponent";
import { ViewInARComponent } from "./ViewInARComponent";
import { ModelAnimationComponent, SpriteAnimationComponent, TweenAnimationComponent } from "./animation";
import FollowTargetComponent from "./follow-target/FollowTargetComponent";
import { InteractiveComponent } from "./interactive";
import { LinkComponent } from "./link/LinkComponent";
import { FollowTargetSteeringComponent } from "./steering";
import TrajectoryComponent from "./trajectory/TrajectoryComponent";

export function toEntityComponent(
  dto: IComponent,
  entityId: string,
  objectRef: RefObject<Group>,
  contextRef: MutableRefObject<EntityContext>
) {
  if (hasType<IComponent, IRigidBodyComponent, ComponentType>(dto, ComponentType.RIGID_BODY)) {
    return <RigidBodyComponent key={dto.id} componentDto={dto} contextRef={contextRef} />;
  } else if (hasType<IComponent, ModelAnimationComponentDescriptor, ComponentType>(dto, ComponentType.ANIMATION)) {
    return <ModelAnimationComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (
    hasType<IComponent, SpriteAnimationComponentDescriptor, ComponentType>(dto, ComponentType.SPRITE_ANIMATION)
  ) {
    return <SpriteAnimationComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (
    hasType<IComponent, TweenAnimationComponentDescriptor, ComponentType>(dto, ComponentType.TWEEN_ANIMATION)
  ) {
    return (
      <TweenAnimationComponent
        key={dto.id}
        entityId={entityId}
        componentDto={dto}
        objectRef={objectRef}
        contextRef={contextRef}
      />
    );
  } else if (hasType<IComponent, ITrajectoryComponent, ComponentType>(dto, ComponentType.TRAJECTORY)) {
    return <TrajectoryComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (hasType<IComponent, ILinkComponent, ComponentType>(dto, ComponentType.LINK)) {
    return <LinkComponent key={dto.id} componentDto={dto} objectRef={objectRef} />;
  } else if (hasType<IComponent, IViewInARComponent, ComponentType>(dto, ComponentType.VIEW_IN_AR)) {
    return <ViewInARComponent key={dto.id} componentDto={dto} objectRef={objectRef} />;
  } else if (hasType<IComponent, IFollowTargetComponent, ComponentType>(dto, ComponentType.FOLLOW_TARGET)) {
    return <FollowTargetComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (hasType<IComponent, IScriptComponent, ComponentType>(dto, ComponentType.SCRIPT)) {
    return <ScriptComponent key={dto.id} componentDto={dto} contextRef={contextRef} />;
  } else if (hasType<IComponent, JSScriptComponentType, ComponentType>(dto, ComponentType.JS_SCRIPT)) {
    return <JSScriptComponent key={dto.id} componentDto={dto} contextRef={contextRef} />;
  } else if (hasType<IComponent, IActionComponent, ComponentType>(dto, ComponentType.ACTIONABLE)) {
    return <ActionableComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (hasType<IComponent, IAttachToComponent, ComponentType>(dto, ComponentType.ATTACH_TO)) {
    return <AttachToComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (hasType<IComponent, IConstraintComponent, ComponentType>(dto, ComponentType.CONSTRAINT)) {
    return <ConstraintComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (hasType<IComponent, InteractiveComponentDescriptor, ComponentType>(dto, ComponentType.INTERACTIVE)) {
    return <InteractiveComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (
    hasType<IComponent, DynamicVisibilityComponentDescriptor, ComponentType>(dto, ComponentType.DYNAMIC_VISIBILITY)
  ) {
    return <DynamicVisibilityComponent key={dto.id} componentDto={dto} objectRef={objectRef} contextRef={contextRef} />;
  } else if (
    hasType<IComponent, FollowTargetSteeringComponentDescriptor, ComponentType>(
      dto,
      ComponentType.FOLLOW_TARGET_STEERING
    )
  ) {
    return (
      <FollowTargetSteeringComponent key={dto.id} descriptor={dto} objectRef={objectRef} contextRef={contextRef} />
    );
  }

  return null;
}
