import { IStructAsset } from "~/types/IStructAsset";
import { getDefaultValue } from "../variable";

export const getDefaultStructValue = (struct: IStructAsset) => {
  const { fields } = struct;
  const value: Record<string, any> = {};

  for (const field of fields) {
    value[field.name] = field.initialValue ?? getDefaultValue(field.type);
  }

  return value;
};
