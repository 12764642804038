import { useFrame } from "@react-three/fiber";
import { useUnit } from "effector-react";
import { keyBy } from "lodash-es";
import { memo, useEffect, useRef } from "react";
import { $allScriptsMap } from "~/entities/allScripts";
import { $storages } from "~/entities/storages";
import { $structsMap } from "~/entities/structs";
import { playerControls } from "~/view-scene/ControlsSystem";
import useSessionStatus, { Status } from "~/view-scene/stores/useSessionStatus";
import { Registry } from "./Registry";
import { availableNodes } from "./availableNodes";
import { $scriptExecutor, dispose, init } from "./models";
import { useSceneContext } from "./useSceneContext";

const readyStatuses: Status[] = ["ready", "in_progress", "onpause"];
const gameSessionStartedStatuses: Status[] = ["in_progress"];

export const ScriptSystem = memo(() => {
  const [scriptExecutor, storages, structsMap, scriptsMap] = useUnit([
    $scriptExecutor,
    $storages,
    $structsMap,
    $allScriptsMap,
  ]);
  const scriptExecutorRef = useRef(scriptExecutor);
  scriptExecutorRef.current = scriptExecutor;
  const status = useSessionStatus((state) => state.sessionStatus);
  const isReady = readyStatuses.includes(status);
  const isGameSessionStarted = gameSessionStartedStatuses.includes(status);

  useEffect(() => {
    if (isReady && scriptExecutor) {
      try {
        scriptExecutor.init();
        scriptExecutor.start();
      } catch (err) {
        console.error(err);
      }
    }
  }, [isReady, scriptExecutor]);

  useEffect(() => {
    if (isGameSessionStarted) {
      scriptExecutor?.started && scriptExecutor?.notifyGameSessionStarted();
    }
  }, [isGameSessionStarted]);

  useFrame(() => {
    scriptExecutor?.started && scriptExecutor?.tick();
  });

  const sceneContext = useSceneContext();

  useEffect(() => {
    if (!storages || !structsMap || !scriptsMap) {
      return;
    }

    const registry = new Registry(availableNodes, {
      storagesMap: keyBy(storages, "id"),
      structsMap,
      scriptsMap,
    });

    init({ registry, sceneContext, playerControls });

    return () => {
      dispose();
    };
  }, [storages, structsMap, scriptsMap]);

  return null;
});
