import { ValueType } from "~/libs/behave-graph";
import { defaultUIOverlay } from "~/entities/variable";
import { UIOverlayValue } from "~/types/Variable";

export type { UIOverlayValue };

export const uiOverlayValue = new ValueType(
  "uiOverlay",
  defaultUIOverlay,
  (text: UIOverlayValue) => text,
  (value: UIOverlayValue) => value
);
