import { useUnit } from "effector-react";
import { updateZoomFactor } from "./models";
import { useEffect } from "react";
import { $aspectRatio } from "~/view-scene/ui";

export function useZoomFactor() {
  const [width, height] = useUnit($aspectRatio);

  useEffect(() => {
    let zoomFactor = 1;

    const aspectRatio = width / height;
    if (aspectRatio > 2.1) {
      zoomFactor = 0.92;
    } else if (aspectRatio > 2) {
      zoomFactor = 0.7;
    } else if (aspectRatio > 1.8) {
      zoomFactor = 1.65;
    } else if (aspectRatio > 1.75) {
      zoomFactor = 0.8;
    } else if (aspectRatio > 1.7) {
      zoomFactor = 1.2;
    } else if (aspectRatio > 1.51) {
      zoomFactor = 1.6;
    } else if (aspectRatio > 1.4) {
      zoomFactor = 1.7;
    } else if (aspectRatio > 1.3) {
      zoomFactor = 1.2;
    } else if (aspectRatio < 0.45) {
      zoomFactor = 0.8;
    } else if (aspectRatio < 0.5) {
      zoomFactor = 0.95;
    } else if (aspectRatio < 0.6) {
      zoomFactor = 0.8;
    } else if (aspectRatio < 0.67) {
      zoomFactor = 1.6;
    } else if (aspectRatio < 0.7) {
      zoomFactor = 1.3;
    } else if (aspectRatio < 0.751) {
      zoomFactor = 1.2;
    } else if (aspectRatio < 0.8) {
      zoomFactor = 1;
    }

    updateZoomFactor(zoomFactor);
  }, [width, height]);
}
