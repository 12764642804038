import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

const tmpVectorA = new Vector3();
const tmpVectorB = new Vector3();

export class VectorLerpNode extends BaseNode {
  static readonly type = "logic/vectorLerpScalar";
  static readonly label = "Vector Lerp";

  inputs = [new Vector3Socket("a"), new Vector3Socket("b"), new NumberSocket("alpha")];
  outputs = [new Vector3Socket("result")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const a = context.readInput<Vector3Value>("a");
    const b = context.readInput<Vector3Value>("b");
    const alpha = context.readInput<number>("alpha");

    tmpVectorA.set(a.x, a.y, a.z);
    tmpVectorB.set(b.x, b.y, b.z);

    tmpVectorA.lerp(tmpVectorB, alpha);

    context.writeOutput<Vector3Value>("result", setVector3ValueFromXYZ(node.storage.output, tmpVectorA));
  }
}
