import { createContext, MutableRefObject, RefObject, useContext } from "react";
import { Group } from "three";
import IComponent from "~/types/IComponent";
import ISceneObject from "~/types/ISceneObject";
import { EntityContext } from "./runtime";

export type DIContext = {
  toSceneEntity: (dto: ISceneObject) => JSX.Element | null;
  toEntityComponent: (
    dto: IComponent,
    entityId: string,
    objectRef: RefObject<Group>,
    contextRef: MutableRefObject<EntityContext>
  ) => JSX.Element | null;
};

export const diContext = createContext<DIContext>(null!);

export const useDIContext = () => useContext(diContext);
