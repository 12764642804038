import { playerControls, video360Controls } from "~/view-scene/ControlsSystem";
import { requestCameraMovement, requestCameraZoomIn, requestCameraZoomOut } from "./models";

playerControls.look.on("fire", ({ forward, side }) => {
  requestCameraMovement({ forward, side });
});

video360Controls.look.on("fire", ({ forward, side }) => {
  requestCameraMovement({ forward, side });
});

playerControls.cameraZoomIn.on("fire", () => {
  requestCameraZoomIn();
});

playerControls.cameraZoomOut.on("fire", () => {
  requestCameraZoomOut();
});
