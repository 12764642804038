import { SceneObjectType } from "~/types/SceneObjectType";
import IPrimitiveSceneObject from "~/types/IPrimitiveSceneObject";
import {
  BoxGeometry,
  BufferGeometry,
  ConeGeometry,
  CylinderGeometry,
  PlaneGeometry,
  SphereGeometry,
} from "~/types/Geometry";
import {
  generatePlaneGeometry,
  generateBoxGeometry,
  generateSphereGeometry,
  generateConeGeometry,
  generateCylinderGeometry,
  generateBufferGeometry,
} from "../geometry";
import { generateGroupEntity } from "../groupEntity/utils";

export const generatePlaneEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<PlaneGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Plane"),
    rotation: {
      x: -Math.PI / 2,
      y: 0,
      z: 0,
    },
    geometry: generatePlaneGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};

export const generateBoxEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<BoxGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Box"),
    geometry: generateBoxGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};

export const generateSphereEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<SphereGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Sphere"),
    geometry: generateSphereGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};

export const generateConeEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<ConeGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Cone"),
    geometry: generateConeGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};

export const generateCylinderEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<CylinderGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Cylinder"),
    geometry: generateCylinderGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};

export const generateBufferEntity = () => {
  const entity: Omit<IPrimitiveSceneObject<BufferGeometry>, "order"> = {
    ...generateGroupEntity(undefined, SceneObjectType.PRIMITIVE, "Buffer"),
    geometry: generateBufferGeometry(),
    receiveShadow: false,
    castShadow: false,
    frustumCulling: true,
    occlusionCulling: false,
  };

  return entity;
};
