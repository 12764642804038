import { BaseAdsProvider } from "./BaseAdsProvider";

const scriptId = "aip-script";

export class AipAdsProvider extends BaseAdsProvider {
  private container: HTMLDivElement | null = null;

  constructor() {
    super();
    this.type = "aip";
  }

  async init() {
    this.container = this.initContainer();
    this.initFields();
    await this.initScript();
    this.initPlayer();
  }

  start() {
    this.isPlaying = true;
    window.aiptag.cmd.player.push(function () {
      window.aiptag.adplayer.startPreRoll();
    });
  }

  async dispose() {
    this.container?.remove();
    window.aiptag.cmd.player = [];

    delete window.aiptag;
  }

  async hasAds() {
    return false;
  }

  private initContainer() {
    const container = document.createElement("div");
    container.id = "aip-container";

    return container;
  }

  private initFields() {
    if (window.aiptag) {
      return;
    }

    window.aiptag = window.aiptag || { cmd: [] };
    window.aiptag.cmd.player = window.aiptag.cmd.player || [];
  }

  private initPlayer() {
    window.aiptag.cmp = {
      show: true,
      position: "centered", //centered, bottom
      button: true,
      buttonText: "Privacy settings",
      buttonPosition: "bottom-left", //bottom-left, bottom-right, top-left, top-right
    };
    window.aiptag.cmd.player.push(() => {
      window.aiptag.adplayer = new window.aipPlayer({
        AD_WIDTH: 960,
        AD_HEIGHT: 540,
        AD_DISPLAY: "fullscreen", //default, fullscreen, center, fill
        LOADING_TEXT: "loading advertisement",
        PREROLL_ELEM: () => {
          return this.container;
        },
        AIP_COMPLETE: (_state: any) => {
          this.finishAd(true);
        },
      });
    });
  }

  private initScript() {
    return new Promise<void>((resolve) => {
      if (document.getElementById(scriptId)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "//api.adinplay.com/libs/aiptag/pub/AXR/8xr.io/tag.min.js";
      script.async = true;
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  private finishAd(viewed: boolean) {
    this.isPlaying = false;
    if (viewed) {
      this.emit("completed");
      this.emit("finished", true);
    } else {
      this.emit("closed");
      this.emit("finished", false);
    }
  }
}
