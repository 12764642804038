import { Vector3 } from "three";
import { useSceneData } from "~/common/stores/useSceneData";
import { Suspense, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { FAR_LOD_AVATAR_DISTANCE } from "~/config";
import { FarRemoteUsers } from "./FarRemoteUsers";
import { isEqual } from "lodash-es";
import { ErrorBoundary } from "~/view-scene/utils";
import { NearbyRemoteUser } from "./NearbyRemoteUser";
import { useShowJoinersLeaversNotifications } from "./useShowJoinersLeaversNotifications";

const nearbyRemoteUsersUpdate: string[] = [];

const remoteUserPosition = new Vector3();
const cameraWorldPosition = new Vector3();

const UPDATE_RENDER_MODE_DELAY = 3000;
const MAX_NEW_NEARBY_USERS_PER_UPDATE = 5;

export function RenderRemoteUsers() {
  const [nearbyRemoteUsers, setNearbyRemoteUsers] = useState<string[]>([]);
  const farRemoteUsersRef = useRef<string[]>([]);

  const lastExecutedRef = useRef(Date.now());

  useFrame(({ camera }) => {
    const now = Date.now();
    const timeSinceLastExecution = now - lastExecutedRef.current;

    if (timeSinceLastExecution < UPDATE_RENDER_MODE_DELAY) {
      return;
    }

    lastExecutedRef.current = now;

    camera.getWorldPosition(cameraWorldPosition);

    nearbyRemoteUsersUpdate.length = 0;
    farRemoteUsersRef.current.length = 0;
    const remoteUsers = useSceneData.getState().remoteUsers;

    for (const remoteUser of remoteUsers) {
      const avatarPosition = remoteUser.avatar.position;
      remoteUserPosition.set(avatarPosition[0], avatarPosition[1], avatarPosition[2]);

      const distance = remoteUserPosition.distanceTo(cameraWorldPosition);
      if (distance < FAR_LOD_AVATAR_DISTANCE) {
        nearbyRemoteUsersUpdate.push(remoteUser.id);
      } else if (distance > FAR_LOD_AVATAR_DISTANCE) {
        farRemoteUsersRef.current.push(remoteUser.id);
      }
    }

    if (!isEqual(nearbyRemoteUsers, nearbyRemoteUsersUpdate.sort())) {
      const maxUpdateLength = nearbyRemoteUsers.length + MAX_NEW_NEARBY_USERS_PER_UPDATE;
      if (nearbyRemoteUsersUpdate.length > maxUpdateLength) {
        nearbyRemoteUsersUpdate.length = maxUpdateLength;
      }
      setNearbyRemoteUsers([...nearbyRemoteUsersUpdate]);
    }
  });

  useShowJoinersLeaversNotifications();

  return (
    <group>
      {nearbyRemoteUsers.map((nearbyRemoteUserId) => (
        <NearbyRemoteUser key={nearbyRemoteUserId} userId={nearbyRemoteUserId} />
      ))}
      <ErrorBoundary message="Failed rendering FarRemoteUsers">
        <Suspense fallback={null}>
          <FarRemoteUsers remoteUserIdsRef={farRemoteUsersRef} />
        </Suspense>
      </ErrorBoundary>
    </group>
  );
}
