import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";

export const Section = styled(Stack)`
  background: ${palette.primary.black400};
  border: 1px solid ${palette.primary.black200};
  border-radius: 20px;
  min-height: 60px;
  box-sizing: border-box;
  margin: 5px;
  align-items: center;
  padding: 20px;
`;
