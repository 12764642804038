import { Icon, IconProps } from "./Icon";

export type VRProps = IconProps;

export const VR = (props: VRProps) => (
  <Icon width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentcolor"
      d="M8 14a1 1 0 1 0 0 2v-2Zm8 2a1 1 0 1 0 0-2v2Zm5-7v6h2V9h-2ZM3 15V9H1v6h2ZM15 4h1V2h-1v2ZM8 4h1V2H8v2Zm15 5a7 7 0 0 0-7-7v2a5 5 0 0 1 5 5h2ZM3 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5 0h8V7H8v2Zm13 5v1h2v-1h-2ZM3 15v-1H1v1h2Zm13 5H8v2h8v-2ZM1 15a7 7 0 0 0 7 7v-2a5 5 0 0 1-5-5H1Zm20 0a5 5 0 0 1-5 5v2a7 7 0 0 0 7-7h-2Zm-5-6a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2ZM8 7a7 7 0 0 0-7 7h2a5 5 0 0 1 5-5V7Zm3-5h2V0h-2v2Zm3 1v5h2V3h-2Zm-4 5V3H8v5h2Zm3-6a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2Zm-2-2a3 3 0 0 0-3 3h2a1 1 0 0 1 1-1V0ZM8 16h8v-2H8v2Z"
    />
  </Icon>
);
