import { useUnit } from "effector-react";
import { $activeLayout, $searchQuery, $selectedWorkspace, setActiveLayout, setSearchQuery } from "./models";
import { Button, Stack, styled } from "@mui/material";
import { Settings } from "~/common/components/Icon";
import { AddScene } from "./AddScene";
import { SearchField } from "~/hephaestus/components/SearchField";
import { Scenes } from "./Scenes";
import { palette } from "~/theme";
import { ActiveContentName } from "./ActiveContentName";
import { ManageWorkspace } from "./ManageWorkspace";
import { Tutorials } from "./Tutorials";

export function ActiveContent() {
  const activeLayout = useUnit($activeLayout);

  switch (activeLayout) {
    case "createSpace":
    case "editSpace":
      return <ManageWorkspace />;
    case "workspace":
      return <ActiveWorkspace />;
    case "tutorials":
      return <ActiveTutorials />;
    case "myScenes":
    default:
      return <MyScenes />;
  }
}

function MyScenes() {
  const searchQuery = useUnit($searchQuery);

  return (
    <>
      <Toolbar width="244px">
        <SearchField
          sx={{ width: "240px" }}
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Toolbar>
      <ActiveContentName variant="body1">My Scenes</ActiveContentName>
      <Scenes />
    </>
  );
}

function ActiveTutorials() {
  const searchQuery = useUnit($searchQuery);

  return (
    <>
      <Toolbar width="244px">
        <SearchField
          sx={{ width: "240px" }}
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Toolbar>
      <ActiveContentName variant="body1">Tutorials</ActiveContentName>
      <Tutorials />
    </>
  );
}

function ActiveWorkspace() {
  const selectedWorkspace = useUnit($selectedWorkspace);

  if (!selectedWorkspace) {
    return null;
  }

  return (
    <>
      <Toolbar width="190px">
        <Button variant="contained" onClick={() => setActiveLayout("editSpace")} startIcon={<Settings size="small" />}>
          Settings
        </Button>
        <AddScene />
      </Toolbar>
      <ActiveContentName variant="body1">{selectedWorkspace.name}</ActiveContentName>
      <Scenes />
    </>
  );
}

const Toolbar = styled(Stack)`
  margin-bottom: 4px;
  flex-direction: row;
  min-height: 36px;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  gap: 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #000000;
  border: ${`1px solid ${palette.primary.gray40}`};
  border-radius: 12px;
`;
