import { Stack, styled } from "@mui/material";
import { useCallback } from "react";
import { Trash } from "~/common/components/Icon";
import { IconButton, Vector2Field, VectorField, VectorFieldProps } from "~/hephaestus/components";
import { palette } from "~/theme";
import ICoordinates from "~/types/ICoordinates";

export type PointEditProps = {
  index: number;
  point: ICoordinates;
  active: boolean;
  onSelect: (index: number) => void;
  onChange: (value: ICoordinates, index: number) => void;
  onRemove: (index: number) => void;
};

export const PointEdit = ({ point, index, active, onChange, onRemove, onSelect }: PointEditProps) => {
  const handleClick = useCallback(() => onSelect(index), [index, onSelect]);

  const handlePointChange: NonNullable<VectorFieldProps["onChange"]> = useCallback(
    (e) => {
      onChange(e.target.value, index);
    },
    [index, onChange]
  );

  const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <Container onClick={handleClick} direction="row" sx={{ gap: "4px" }} data-active={active}>
      <VectorField name="point" value={point} onChange={handlePointChange} />
      <IconButton onClick={handleRemove}>
        <Trash />
      </IconButton>
    </Container>
  );
};

const Container = styled(Stack)`
  padding: 8px 8px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;

  &[data-active="true"] {
    background: ${palette.primary.violet600};
    border-color: ${palette.primary.violet600};
  }

  &:hover {
    background: ${palette.primary.gradient400};
    border-color: ${palette.primary.violet};
  }
`;
