import { useHelper } from "@react-three/drei";
import { BoxHelper, Object3D } from "three";
import { MutableRefObject } from "react";

type RenderBoxHelperProps = {
  color?: string;
  refObject: MutableRefObject<Object3D>;
};

export function RenderBoxHelper({ refObject, color = "#383030" }: RenderBoxHelperProps) {
  useHelper(refObject, BoxHelper, color);

  return null;
}
