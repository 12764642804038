import { SceneObjectType } from "~/types/SceneObjectType";
import { generateGroupEntity } from "../groupEntity";
import { IPlayerSceneObject } from "~/types/IPlayerSceneObject";

export const generatePlayerEntity = (parentId?: string): Omit<IPlayerSceneObject, "order"> => {
  return {
    ...generateGroupEntity(parentId, SceneObjectType.PLAYER, "Player"),
    id: "player",
    removable: false,
    avatarBehavior: "auto",
    movementSpeed: 4.5,
  };
};
