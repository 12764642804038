import { EventValue } from "~/types/Variable";
import { ComponentSelector, ComponentSelectorProps } from "./ComponentSelector";
import { ScriptEventSelect, ScriptEventSelectProps } from "./ScriptEventSelect";
import { Stack } from "@mui/material";
import { ComponentType } from "~/types/ComponentType";
import { useCallback } from "react";
import { getScriptAssetId } from "~/hephaestus/ScriptEditor/nodes/utils";
import { $entities } from "~/entities/entities";

export type EventSelectProps = {
  value: EventValue;
  onChange: (event: EventValue) => void;
};

const componentsFilter = [ComponentType.SCRIPT, ComponentType.JS_SCRIPT];

export const EventSelect = ({ value, onChange }: EventSelectProps) => {
  const handleChange: NonNullable<ComponentSelectorProps["onChange"]> = useCallback(
    (e) => {
      const entityId = e.target.value.entityId ?? null;
      const componentId = e.target.value.componentId ?? null;
      const scriptId = getScriptAssetId($entities.getState() ?? [], entityId, componentId);

      onChange({
        ...value,
        entityId,
        componentId,
        scriptId,
        eventId: null,
      });
    },
    [onChange, value]
  );

  const handleEventChange: NonNullable<ScriptEventSelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        ...value,
        eventId: e.target.value || null,
      });
    },
    [onChange, value]
  );

  return (
    <Stack direction="column">
      <ComponentSelector value={value} componentTypeFilter={componentsFilter} onChange={handleChange} />
      <ScriptEventSelect
        scriptId={value.scriptId ?? undefined}
        value={value.eventId ?? undefined}
        onlyPublic
        onChange={handleEventChange}
      />
    </Stack>
  );
};
