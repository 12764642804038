import { Icon, IconProps } from "./Icon";

export type PersonTopProps = IconProps;

export const PersonTop = (props: PersonTopProps) => (
  <Icon width="24px" height="28px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28" {...props}>
    <path
      fill="currentcolor"
      d="M18.747 9.21c0 2.139-.855 4.162-1.777 5.79-1.272 2.245-2.947 3.737-5.172 3.737C9.573 18.737 8.272 17.245 7 15c-.922-1.628-2.152-3.651-2.152-5.79C4.848 4.125 7.96 0 11.798 0s6.95 4.124 6.95 9.21ZM12 21.957c1.944-.006 3.189-.454 4.004-.908.63-.35 1.377-.481 2.042-.201l4.246 1.788A2.791 2.791 0 0 1 21.209 28H2.79a2.791 2.791 0 0 1-1.083-5.364l4.246-1.788c.665-.28 1.411-.15 2.042.201.815.454 2.06.902 4.004.908Z"
    />
  </Icon>
);
