import { Stack, StackProps } from "@mui/material";
import { useMemo } from "react";
import { PartialRecord } from "~/common/typeUtils";
import { ConstraintType } from "~/types/IConstraintComponent";
import { LabelGroup } from "../LabelGroup";
import { ConeTwistInspector } from "./ConeTwistInspector";
import { ConstraintSelect } from "./ConstraintSelect";
import { HingeInspector } from "./HingeInspector";
import { PointToPointInspector } from "./PointToPointInspector";
import { SliderInspector } from "./SliderInspector";
import { SpringInspector } from "./SpringInspector";
import { ConstraintInspectorBaseProps } from "./types";

export type ConstraintInspectorProps = Pick<StackProps, "sx" | "className"> & ConstraintInspectorBaseProps;

export const ConstraintInspector = (props: ConstraintInspectorProps) => {
  const { sx, className, value, name, onChange } = props;

  const Inspector = useMemo(() => {
    return inspectorsMap[value.type] ?? null;
  }, [value.type]);

  return (
    <Stack sx={sx} className={className}>
      <LabelGroup label="Type">
        <ConstraintSelect name={name} value={value.type} onChange={onChange} />
      </LabelGroup>
      {Inspector && <Inspector {...props} />}
    </Stack>
  );
};

const inspectorsMap: PartialRecord<ConstraintType, any> = {
  spring: SpringInspector,
  slider: SliderInspector,
  hinge: HingeInspector,
  coneTwist: ConeTwistInspector,
  pointToPoint: PointToPointInspector,
};
