import { createTheme } from "@mui/material/styles";

import { palette } from "../palette";
import { typography, setupTypographyVariants } from "./typography.token";

import { MuiAutocomplete } from "./components/autocomplete";
import { MuiButton } from "./components/button";
import { MuiIconButton } from "./components/iconButton";
import { MuiInputAdornment } from "./components/inputAdornment";
import { MuiInputBase } from "./components/inputBase";
import { MuiOutlinedInput } from "./components/outlinedInput";
import { MuiTextField } from "./components/textField";
import { MuiTooltip } from "./components/tooltip";

export const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    ...palette,
  },
  typography,
  zIndex: {},
  transitions: {},
  components: {
    MuiAutocomplete,
    MuiButton,
    MuiIconButton,
    MuiInputAdornment,
    MuiInputBase,
    MuiOutlinedInput,
    MuiTextField,
    MuiTooltip,
  },
});

setupTypographyVariants(defaultTheme);
