import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class PauseSessionNode extends BaseNode {
  static readonly type = "action/pauseSession";
  static readonly label = "Pause Session";

  inputs = [new FlowSocket()];
  outputs = [new FlowSocket()];

  eval() {
    // temporary solution. Should be passed via scene context
    useSessionStatus.getState().updateStatus("onpause");
  }
}
