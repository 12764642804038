import { MutableRefObject, useEffect } from "react";
import { setTheta } from "~/view-scene/CameraSystem";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext, getPlayerSpawnTransform, getSpawnPoint } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";

export const useSpawnPosition = (contextRef: MutableRefObject<PlayerContext | null>) => {
  const player = usePlayer();
  const isLoaded = useSessionStatus((state) => state.statusAfterOrEqual("loaded"));

  useEffect(() => {
    if (isLoaded) {
      const spawnPositionY = contextRef.current?.getYOffset() ?? 0;
      const spawnPoint = getSpawnPoint();
      const { position, rotation } = getPlayerSpawnTransform(spawnPoint, spawnPositionY);

      player.position.set(position.x, position.y, position.z);
      setTheta(Math.PI + rotation.y);

      if (contextRef.current) {
        contextRef.current.positionInitialized = true;
        contextRef.current.events.emit("positionInitialized");
      }
    }
  }, [isLoaded]);
};
