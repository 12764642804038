import { MutableRefObject, RefObject, useEffect, useMemo, useRef, useState } from "react";
import { AnimationAction, AnimationMixer, Group, LoopOnce, LoopRepeat, Mesh } from "three";
// @ts-ignore
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module";
import { useFrame } from "@react-three/fiber";
import { ComponentType } from "~/types/ComponentType";
import IModelAsset from "~/types/IModelAsset";
import { AnimationComponentContext, EntityContext, useComponentContext } from "~/view-scene/runtime";
import { useGLTF } from "~/view-scene/utils";
import { ModelAnimationComponent as ModelAnimationComponentDescriptor } from "~/types/component";
import { AnimationComponentEvents } from "../AnimationComponentEvents";

type RenderAnimationProps = {
  component: ModelAnimationComponentDescriptor;
  asset: IModelAsset;
  objectRef: RefObject<Group>;
  contextRef?: RefObject<EntityContext>;
};

export function RenderModelAnimation({ component, asset, objectRef, contextRef }: RenderAnimationProps) {
  const { cullingMode, animationName, speed, autoplay, loop } = component;
  const defaultVariant = asset?.variants.find((variant) => variant.isOriginal)!;
  const { url } = defaultVariant;

  const { animations } = useGLTF(url);

  const animation = useMemo(
    () => animations.find((animation) => animation.name === animationName),
    [animations, animationName]
  );

  const [mixer, setMixer] = useState<AnimationMixer | null>(null);
  const events = useMemo(() => new AnimationComponentEvents(), []);
  useEffect(() => {
    const object = objectRef.current;
    if (!object || !animation) {
      return;
    }

    if (cullingMode === "never") {
      object.traverse((el) => {
        if (el instanceof Mesh) {
          el.frustumCulled = false;
        }
      });
    }

    const mixer = new AnimationMixer(object);
    animationActionRef.current = mixer.clipAction(animation);
    animationActionRef.current.setLoop(loop ? LoopRepeat : LoopOnce, Infinity);
    animationActionRef.current.clampWhenFinished = true;
    animationActionRef.current.play();

    mixer.addEventListener("finished", () => {
      if (animationActionRef.current?.loop === LoopOnce) {
        events.emit("finish");
        playRef.current = false;
      }
    });

    setMixer(mixer);
  }, [animation]);

  const playRef = useRef(autoplay);
  const animationActionRef: MutableRefObject<AnimationAction | null> = useRef<AnimationAction>(null);

  const context: AnimationComponentContext = {
    id: component.id,
    type: ComponentType.ANIMATION,
    events,
    get duration() {
      return (animation?.duration ?? 0) * 1000;
    },
    get time() {
      return (animationActionRef.current?.time ?? 0) * 1000;
    },
    set time(value: number) {
      if (animationActionRef.current) {
        const isRunning = animationActionRef.current.isRunning();

        if (!isRunning) {
          animationActionRef.current.play();
        }

        mixer?.setTime(value / 1000);
      }
    },
    name: animationName ?? "__UNKNOWN__NAME__",
    play() {
      if (!animationActionRef.current?.isRunning()) {
        animationActionRef.current?.reset();
      }

      playRef.current = true;
    },
    pause() {
      playRef.current = false;
    },
    stop() {
      playRef.current = false;
      animationActionRef.current?.reset();
      mixer?.update(0.0001);
    },
    setLoop(flag) {
      animationActionRef.current?.setLoop(flag ? LoopRepeat : LoopOnce, Infinity);
    },
    reset() {
      animationActionRef.current?.reset();
      mixer?.update(0.0001);
    },
  };

  useComponentContext(contextRef, component.id, () => context, [mixer]);

  useFrame((_, delta) => {
    if (!mixer || !animation || !playRef.current) {
      return;
    }

    const adjustedBySpeed = delta * speed;
    mixer.update(adjustedBySpeed);
  });

  return null;
}
