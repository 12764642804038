import { useMemo } from "react";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import { PreloadConfig, ScenePreloadConfig } from "~/types/PreloadConfig";
import { getDefaultImagePreloadConfig } from "./utils";

export const usePreloadConfigs = () => {
  const preloadedAssets = useSceneData((state) => state.sceneState?.preloadedAssets ?? [], shallow);

  return useMemo(() => mapPreloadConfigs(preloadedAssets), [preloadedAssets]) as PreloadConfig[];
};

export const mapPreloadConfigs = (preloadConfigs: ScenePreloadConfig[]) => {
  return preloadConfigs.map((preloadConfig) => {
    if (typeof preloadConfig === "string") {
      return getDefaultImagePreloadConfig(preloadConfig);
    }

    return preloadConfig;
  }) as PreloadConfig[];
};
