import { useMemo } from "react";
import { AnimationAction, AnimationMixer } from "three";
import { useEmotion } from "./useEmotion";

export const useEmotionAnimations = (mixer: AnimationMixer): Record<string, AnimationAction> => {
  const hi = useEmotion({
    mixer,
    animationUrl: "/static/models/emotions/emotion_hi.glb",
    animationClipName: "Armature|mixamo.com|Layer0.002",
  });
  const yes = useEmotion({
    mixer,
    animationUrl: "/static/models/emotions/emotion_yes.glb",
    animationClipName: "Armature|mixamo.com|Layer0",
  });
  const no = useEmotion({
    mixer,
    animationUrl: "/static/models/emotions/emotion_no.glb",
    animationClipName: "Armature|mixamo.com|Layer0.001",
  });
  const dance = useEmotion({
    mixer,
    animationUrl: "/static/models/emotions/emotion_dance.glb",
    animationClipName: "Armature|mixamo.com|Layer0.001",
  });
  const test = useEmotion({
    mixer,
    animationUrl: "/static/models/emotions/emotion_test.glb",
    animationClipName: "Armature|Take 001|BaseLayer",
  });

  return useMemo(() => {
    return {
      hi,
      yes,
      no,
      dance,
      test,
    };
  }, [hi, yes, no, dance, test]);
};
