import { ValueType } from "~/libs/behave-graph";
import { defaultScene } from "~/entities/variable";
import { SceneValue } from "~/types/Variable";

export type { SceneValue };

export const sceneValue = new ValueType(
  "scene",
  defaultScene,
  (text: SceneValue) => text,
  (value: SceneValue) => value
);
