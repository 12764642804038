import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, PlayerSocket } from "../../sockets";

export class PlayerSetMovementStateNode extends BaseNode {
  static readonly type = "logic/playerSetMovementState";
  static readonly label = "Set movement state";

  inputs = [new FlowSocket("flow"), new PlayerSocket("player"), new BooleanSocket("enabled")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const player = node.readEntity<PlayerContext>(context, "player");
    const enabled = context.readInput<boolean>("enabled");

    player?.setMovementState(enabled);
  }
}
