import { useMemo } from "react";
import { hasType } from "~/common/utils/hasType";
import { ComponentType } from "~/types/ComponentType";
import { EnabledStatus } from "~/types/EnabledStatus";
import IComponent from "~/types/IComponent";
import IRigidBodyComponent from "~/types/IRigidBodyComponent";
import { RigidBodyType } from "~/types/RigidBodyType";
import { IOcclusionCulledEntity } from "../types";

const dynamicRigidBodies = new Set(["dynamic", "kinematic"] as RigidBodyType[]);

export const useOcclusionCullingEnabled = (entity: IOcclusionCulledEntity) => {
  return useMemo(() => {
    if (!entity.occlusionCulling) {
      return false;
    }

    for (const component of entity.components) {
      if (component.enabled !== EnabledStatus.enabled) {
        continue;
      }

      if (
        hasType<IComponent, IRigidBodyComponent, ComponentType>(component, ComponentType.RIGID_BODY) &&
        dynamicRigidBodies.has(component.rigidBodyType)
      ) {
        return false;
      }

      if (
        hasType<IComponent, IRigidBodyComponent, ComponentType>(component, ComponentType.TRAJECTORY) ||
        hasType<IComponent, IRigidBodyComponent, ComponentType>(component, ComponentType.FOLLOW_TARGET)
      ) {
        return false;
      }
    }

    return true;
  }, [entity]);
};
