import { ReactNode } from "react";
import shallow from "zustand/shallow";
import { Right } from "../types";
import { roleToRight } from "../constants";
import { useProfile } from "../models";

export type PrivateComponentProps = {
  accessRight?: Right;
  children: ReactNode;
};

export const PrivateComponent = ({ accessRight, children }: PrivateComponentProps) => {
  const { isAuthorised, profile } = useProfile(
    (state) => ({
      isAuthorised: state.isAuthorised,
      profile: state.profile,
    }),
    shallow
  );

  if (!isAuthorised()) {
    return null;
  }

  if (accessRight) {
    const accountActive = profile?.emailConfirmed;
    const hasRequiredRight = profile?.role && roleToRight.get(profile?.role)?.indexOf(accessRight) !== -1;

    if (accountActive && hasRequiredRight) {
      return <>{children}</>;
    } else {
      return null;
    }
  } else {
    return <>{children}</>;
  }
};
