import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class RandomNode extends BaseNode {
  static readonly type = "logic/random";
  static readonly label = "Random integer";
  static readonly category = "Logic";

  inputs = [new NumberSocket("min", "min", 0), new NumberSocket("max", "max", 1)];
  outputs = [new NumberSocket("value")];

  eval(context: NodeEvalContext) {
    const min = context.readInput<number>("min");
    const max = context.readInput<number>("max");
    const value = Math.floor(Math.random() * (max - min + 1) + min);

    context.writeOutput("value", value);
  }
}
