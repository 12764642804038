import { requestNonce } from "~/api/auth.api";
import { BlockchainNetwork, Web3Wallet } from "~/web3Helpers";
import { TonConnect } from "@tonconnect/sdk";
import { attachTonWallet } from "~/api/profile.api";

type UseAttachTonkeeperProps = {
  onWalletConnected: (qrCodeUrl: string) => void;
  onWalletAttached: VoidFunction;
  onError: (message?: string) => void;
};

const walletConnectionSource = {
  universalLink: "https://app.tonkeeper.com/ton-connect",
  bridgeUrl: "https://bridge.tonapi.io/bridge",
};

const dappMetadata = {
  manifestUrl: "https://8xr.io/static/metadata/tonConnectManifest.json",
};

const tonConnector = new TonConnect(dappMetadata);

export function useAttachTonkeeper({ onWalletConnected, onWalletAttached, onError }: UseAttachTonkeeperProps) {
  const requestAttachTonkeeper = async () => {
    const nonce = await requestNonce();

    const message = `Sign the message to confirm your identity: ${nonce}`;
    const universalLink = tonConnector.connect(walletConnectionSource, { tonProof: message });
    onWalletConnected(universalLink);

    tonConnector.onStatusChange(async (wallet) => {
      if (!wallet) {
        onError();
        return;
      }

      const tonProof = wallet.connectItems?.tonProof;
      if (tonProof) {
        if ("proof" in tonProof) {
          const payload = {
            wallet: Web3Wallet.tonkeeper,
            network: BlockchainNetwork.ton,
            nonce,
            walletInfo: wallet,
          };

          const { status } = await attachTonWallet(payload);

          if (status !== 200) {
            onError();
            return;
          }

          await onWalletAttached();
        }
      } else {
        onError();
      }
    });
  };

  return { requestAttachTonkeeper };
}
