import { HTMLProps } from "react";

export const valueToDisplayValue = (value: HTMLProps<HTMLInputElement>["value"], defaultValue = "0") => {
  return value ? value.toString() : defaultValue;
};

export const valueToNumber = (value: HTMLProps<HTMLInputElement>["value"], defaultValue = 0) => {
  if (value === undefined) {
    return defaultValue;
  }

  if (typeof value === "number") {
    return isNaN(value) ? defaultValue : value;
  }

  if (typeof value === "string") {
    const parsedValue = parseFloat(value);

    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  if (Array.isArray(value) && value.length > 0) {
    return parseFloat(value[0]);
  }

  return defaultValue;
};

export const clamp = (value: number, min: number | undefined, max: number | undefined) => {
  let result: number;

  if (min !== undefined && value < min) {
    result = min;
  } else if (max !== undefined && value > max) {
    result = max;
  } else {
    result = value;
  }

  return result;
};

export const addZeroPrefix = (value: string) => {
  if (value.startsWith("-")) {
    return `-0${value.substring(1)}`;
  }

  return `0${value}`;
};
