import { easeInQuad } from "js-easing-functions";

export const createEqualizer = () => {
  const equalizerState = {
    value: 0,
    initialValue: 0,
    startTime: 0,
  };

  return (analyzer: AnalyserNode | null) => {
    if (!analyzer) {
      return 0;
    }
    const percent = calcAverageVolumePercent(analyzer);

    if (percent > equalizerState.value) {
      equalizerState.initialValue = percent;
      equalizerState.startTime = Date.now();
    }

    const elapsed = Date.now() - equalizerState.startTime;
    equalizerState.value =
      elapsed < animationDuration
        ? easeInQuad(elapsed, equalizerState.initialValue, -equalizerState.initialValue, animationDuration)
        : 0;

    return equalizerState.value;
  };
};

const threshold = 0.2;
const maxValue = 160;
const animationDuration = 1000;

const calcAverageVolumePercent = (analyser: AnalyserNode) => {
  const volumes = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(volumes);

  let volumeSum = 0;

  for (const volume of volumes) {
    volumeSum += volume;
  }

  const averageVolume = volumeSum / volumes.length;
  const percent = averageVolume / maxValue;

  return percent > threshold ? percent : 0;
};
