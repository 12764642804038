import { EventEmitter } from "eventemitter3";
import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Object3D } from "three";
import { useSceneData } from "~/common/stores/useSceneData";
import { getSceneUrl } from "~/common/utils/navigate";
import { PLAYER_HEIGHT } from "~/config";
import { defaultVector3 } from "~/entities/variable";
import { IPlayerSceneObject } from "~/types/IPlayerSceneObject";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import { useEntityManager } from "~/view-scene/runtime/useEntityManager";
import { useEntityContext } from "~/view-scene/runtime/utils/useEntityContext";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { playerId } from "./constants";

const SPAWN_OFFSET_3D = PLAYER_HEIGHT + 0.1;
const SPAWN_OFFSET_VR = 1;
const defaultVector = defaultVector3();

export const usePlayerContext = (entity: IPlayerSceneObject) => {
  const player = usePlayer();
  const navigate = useNavigate();
  const movementStateRef = useRef(true);
  const setSceneTransition = useSessionStatus((state) => state.setSceneTransition);
  const { addEntityContext, removeEntityContext } = useEntityManager((state) => ({
    addEntityContext: state.addEntityContext,
    removeEntityContext: state.removeEntityContext,
  }));
  const mode = useSessionStatus((state) => state.mode);

  const contextRef = useRef<PlayerContext>(null!);
  const playerRef = useRef(player);
  const events = useMemo(() => new EventEmitter(), []);
  const teleportHappened = useRef(false);
  const tags = useMemo(() => new Set(entity.tags ?? []), [entity.tags]);

  const playerContext: PlayerContext = {
    id: playerId,
    dto: entity,
    parentId: null,
    type: "player",
    name: "Player",
    tags,
    movementSpeed: entity.movementSpeed,
    events,
    rootObjectRef: playerRef,
    positionInitialized: false,
    teleport: (position) => {
      if (!teleportHappened.current) {
        const physicsBody = contextRef.current?.getPhysicsBody();
        teleportHappened.current = true;
        physicsBody?.moveTo(position.x, position.y, position.z);

        setTimeout(() => {
          teleportHappened.current = false;
        }, 1000);
      }
    },
    teleportToScene: (sceneId, forceReload = false) => {
      const scene = useSceneData.getState().scenesLibraryLookupMap.get(sceneId);

      if (scene) {
        const url = getSceneUrl(scene.urlName);

        if (!forceReload) {
          setSceneTransition(true);
          navigate(url);
        } else {
          window.location.href = url;
        }
      }
    },
    getYOffset: () => {
      return mode === "vr" ? SPAWN_OFFSET_VR : SPAWN_OFFSET_3D;
    },
    getMovementState: () => {
      return movementStateRef.current;
    },
    setMovementState: (enabled) => {
      movementStateRef.current = enabled;
    },
    waitForPositioning: () => {
      return new Promise((resolve) => {
        if (contextRef.current?.positionInitialized) {
          resolve();
        } else {
          contextRef.current?.events.once("positionInitialized", () => {
            resolve();
          });
        }
      });
    },
    components: {},
    physicsBodies: {},
    getPhysicsBody: (_id?: string) => {
      return contextRef.current?.physicsBodies["root"] ?? null;
    },
    getChild(id) {
      let child: Object3D | null = null;

      contextRef.current.rootObjectRef.current?.traverse((obj) => {
        if (obj.name === id) {
          child = obj;
        }
      });

      return child;
    },
    get position() {
      return playerRef.current?.position ?? defaultVector;
    },
    set position(value) {
      playerRef.current?.position.set(value.x, value.y, value.z);
    },
    get rotation() {
      return playerRef.current?.rotation ?? defaultVector;
    },
    set rotation(value) {
      playerRef.current?.rotation.set(value.x, value.y, value.z);
    },
    get scale() {
      return playerRef.current?.scale ?? defaultVector;
    },
    set scale(value) {
      playerRef.current?.scale.set(value.x, value.y, value.z);
    },
  };

  useEntityContext(contextRef, () => playerContext);

  useEffect(() => {
    player.userData["eightXRId"] = playerId;

    addEntityContext(playerId, contextRef);

    return () => removeEntityContext(playerId);
  }, []);

  return { playerId, contextRef };
};
