import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { AnySocket, ArraySocket, FlowSocket, NumberSocket } from "../../sockets";
import { anyValue } from "../../values";

export class ArraySetNode extends BaseNode {
  static readonly type = "array/set";
  static readonly label = "Array Set Item";

  inputs = [
    new FlowSocket(),
    new ArraySocket("array", anyValue.name),
    new NumberSocket("index"),
    new AnySocket("item"),
  ];
  outputs = [new FlowSocket(), new ArraySocket("array", anyValue.name)];

  eval(context: NodeEvalContext) {
    const arr = context.readInput<any[]>("array");
    const index = context.readInput<number>("index");
    const item = context.readInput<number>("item");
    const newArr = [...arr];
    newArr[index] = item;

    context.writeOutput<any[]>("array", newArr);
  }
}
