import { createTheme } from "@mui/material/styles";

import { palette } from "../palette";
import { typography, setupTypographyVariants } from "./typography.token";

import { MuiButton } from "./components/button";

export const gamerTheme = createTheme({
  palette: {
    mode: "dark",
    ...palette,
  },
  typography,
  zIndex: {},
  transitions: {},
  components: {
    MuiButton,
  },
});

setupTypographyVariants(gamerTheme);
