import { styled, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from "@mui/material";
import { palette } from "~/theme";

export type SwitchProps = MuiSwitchProps;

export const Switch = styled((props: SwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple color="secondary" {...props} />
))(({ theme }) => ({
  width: 34,
  height: 20,
  borderRadius: 26 / 2,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "1px 2px",
    transitionDuration: "300ms",
    top: 1,
    opacity: 0.4,
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      opacity: 1,
      "& + .MuiSwitch-track": {
        backgroundColor: palette.primary.violet,
        opacity: 1,
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? palette.primary.gray100 : palette.primary.violet,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "red",
        border: "6px solid red",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      boxShadow: "none",
      height: 16,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    boxShadow: "none",
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: palette.primary.gray100,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
