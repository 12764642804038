import { Footer, Header } from "~/common/components";
import { Button, Container, Stack, styled, TextField, Typography } from "@mui/material";
import { getMAUReport, getRetentionReport, getSessionsHistoryReport } from "~/api/session.api";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { dogeReset as dogeResetApi, getDogeLeaderboardReport, getUsers, sqReset as sqResetApi } from "~/api/admin.api";
import { getAdClicksReport, getSentAdMessagesReport } from "~/api/telegram.api";
import { getLeaderboardReport } from "~/api/leaderboard.api";
import { Select, SelectItem } from "~/hephaestus/components";
import { getExternalAdClicksReport } from "~/api/ads.api";

const botIds = [
  "catalog",
  "slingTombGameBot",
  "rudeRacesGameBot",
  "crazyFishingGameBot",
  "formulaRushGameBot",
  "desertWarriorGameBot",
  "zombieAttackGameBot",
  "riseUpGameBot",
  "sudokuMasterGameBot",
  "kingSolitaireGameBot",
  "boxTowerGameBot",
  "dropNMergeGameBot",
  "memoryMatchGameBot",
  "deverseeGameBot",
  "furiousRoadGameBot",
  "candylandGameBot",
  "kingdomSurvivorGameBot",
  "endlessSiegeGameBot",
  "parkingJamGameBot",
  "polygamiGameBot",
  "shardQuestBot",
  "endlessSiegeGameBot2",
  "wordCakeGameBot",
  "tonSpacesBot",
  "BtlshipGameBot",
  "battalionCommanderGameBot",
  "osolitaire_bot",
  "tongochi_bot",
  "xrocket",
  "kingSolitaireGameBot",
];

export function ReportPage() {
  const [leaderboardDate, setLeaderboardDate] = useState<Dayjs>(dayjs());

  const [sessionsHistoryStartDate, setSessionsHistoryStartDate] = useState<Dayjs>(dayjs());
  const [sessionsHistoryEndDate, setSessionsHistoryEndDate] = useState<Dayjs>(dayjs());

  const [mauStartDate, setMauStartDate] = useState<Dayjs>(dayjs());
  const [retentionStartDate, setRetentionStartDate] = useState<Dayjs>(dayjs());

  const handleSessionsHistoryStartDateChange = (date: Dayjs | null) => {
    if (date === null) {
      return;
    }
    setSessionsHistoryStartDate(date);
  };

  const handleSessionsHistoryEndDateChange = (date: Dayjs | null) => {
    if (date === null) {
      return;
    }
    setSessionsHistoryEndDate(date);
  };

  const handleLeaderboardDateChange = (date: Dayjs | null) => {
    if (date === null) {
      return;
    }
    setLeaderboardDate(date);
  };

  const handleMauStartDateChange = (date: Dayjs | null) => {
    if (date === null) {
      return;
    }
    setMauStartDate(date);
  };

  const handleRetentionStartDateChange = (date: Dayjs | null) => {
    if (date === null) {
      return;
    }
    setRetentionStartDate(date);
  };

  const downloadHistoryOfSessions = async () => {
    const startDate = sessionsHistoryStartDate.toDate().getTime();
    const endDate = sessionsHistoryEndDate.toDate().getTime();

    const { blob } = await getSessionsHistoryReport(startDate, endDate);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Sessions_History.csv";
    link.click();
  };

  const [leaderboardBotId, setLeaderboardBotId] = useState<string | null>(null);

  const downloadLeaderboardReport = async () => {
    const date = leaderboardDate.toDate().getTime();
    if (!date || !leaderboardBotId) {
      return;
    }

    const { blob } = await getLeaderboardReport(leaderboardBotId, date);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Leaderboard_${leaderboardBotId}_${leaderboardDate.toDate().toDateString()}.csv`;
    link.click();
  };

  const downloadTelegramAdClicks = async () => {
    const { blob } = await getAdClicksReport();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Telegram_Ad_Analytics.csv";
    link.click();
  };

  const downloadExternalAdReport = async () => {
    const { blob } = await getExternalAdClicksReport();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Telegram_External_Ad_Analytics.csv";
    link.click();
  };

  const downloadTelegramSentAdMessages = async () => {
    const { blob } = await getSentAdMessagesReport();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Sent_Ad_Messages_Analytics.csv";
    link.click();
  };

  const [botId, setBotId] = useState<string | null>(null);

  const showRetention = async () => {
    const startDate = retentionStartDate.toDate().getTime();
    const { blob } = await getRetentionReport(startDate, botId);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Retention_Report_${retentionStartDate.format("DD/MM/YYYY")}.csv`;
    link.click();
  };

  const downloadMAU = async () => {
    const startDate = mauStartDate.toDate().getTime();
    const { blob } = await getMAUReport(startDate);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `MAU_${mauStartDate.format("DD/MM/YYYY")}.csv`;
    link.click();
  };

  const downloadDogeLeaderboard = async () => {
    const { blob } = await getDogeLeaderboardReport();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `doge_leaderboard.csv`;
    link.click();
  };

  const dogeReset = async () => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm("It will drop all the doge data. Are you SURE?");

    if (result) {
      await dogeResetApi();
      alert("Success!");
    }
  };

  const sqReset = async () => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm("It will drop all the Shard Quest data. Are you SURE?");

    if (result) {
      await sqResetApi();
      alert("Success!");
    }
  };

  const downloadUsers = async () => {
    const { blob } = await getUsers();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `users.csv`;
    link.click();
  };

  return (
    <Stack direction="column" height="100%" minHeight="100vh">
      <Header />
      <Container maxWidth="xl" sx={{ flexGrow: 1 }}>
        <Stack direction="column" paddingTop="40px" gap={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              History of sessions
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="gb">
              <DesktopDatePicker
                label="Start date"
                inputFormat="DD/MM/YYYY"
                value={sessionsHistoryStartDate}
                onChange={handleSessionsHistoryStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
              <DesktopDatePicker
                label="End date"
                inputFormat="DD/MM/YYYY"
                value={sessionsHistoryEndDate}
                onChange={handleSessionsHistoryEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button onClick={downloadHistoryOfSessions} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Telegram Ad Clicks
            </Typography>
            <Button onClick={downloadTelegramAdClicks} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              External Ad Messages
            </Typography>
            <Button onClick={downloadExternalAdReport} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Leaderboard
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="gb">
              <DesktopDatePicker
                label="Date"
                inputFormat="DD/MM/YYYY"
                value={leaderboardDate}
                onChange={handleLeaderboardDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Select
              name="textAlign"
              displayEmpty={true}
              value={leaderboardBotId ?? ""}
              onChange={(e) => setLeaderboardBotId(e.target.value)}
            >
              <SelectItem key="" value="">
                None
              </SelectItem>
              {botIds.map((option) => (
                <SelectItem key={option} value={option}>
                  {botIdToName(option)}
                </SelectItem>
              ))}
            </Select>
            <Button onClick={downloadLeaderboardReport} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Sent Ad Messages
            </Typography>
            <Button onClick={downloadTelegramSentAdMessages} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Retention
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="gb">
              <DesktopDatePicker
                label="Start date"
                inputFormat="DD/MM/YYYY"
                value={retentionStartDate}
                onChange={handleRetentionStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Select name="textAlign" displayEmpty={true} value={botId ?? ""} onChange={(e) => setBotId(e.target.value)}>
              <SelectItem key="" value="">
                None
              </SelectItem>
              {botIds.map((option) => (
                <SelectItem key={option} value={option}>
                  {botIdToName(option)}
                </SelectItem>
              ))}
            </Select>
            <Button onClick={showRetention} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              MAU
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="gb">
              <DesktopDatePicker
                label="Start date"
                inputFormat="DD/MM/YYYY"
                value={mauStartDate}
                onChange={handleMauStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button onClick={downloadMAU} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Doge Leaderboard
            </Typography>
            <Button onClick={downloadDogeLeaderboard} variant="contained">
              Download
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Reset Dogeverse
            </Typography>
            <DangerButton onClick={dogeReset} variant="accent">
              RESET DOGE VERSE
            </DangerButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Reset Shard Quest
            </Typography>
            <DangerButton onClick={sqReset} variant="accent">
              RESET SHARD QUEST
            </DangerButton>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="350px" gap="10px">
            <Typography variant="body1" flexGrow={1}>
              Users
            </Typography>
            <Button onClick={downloadUsers} variant="contained">
              Download
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Footer />
    </Stack>
  );
}

const DangerButton = styled(Button)`
  background-color: #ff0000;
`;

const botIdToName = (botId: string) =>
  botId
    .split(/(?=[A-Z])/)
    .join(" ")
    .replace(/\b\w/g, (match) => match.toUpperCase());
