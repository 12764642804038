import { v4 as uuid } from "uuid";
import ISceneObject from "~/types/ISceneObject";
import { SceneObjectType } from "~/types/SceneObjectType";
import { EnabledStatus } from "~/types/EnabledStatus";

export const generateGroupEntity = (
  parentId?: string,
  type = SceneObjectType.ENTITY,
  name = "Group"
): Omit<ISceneObject, "order"> => {
  return {
    id: uuid(),
    type,
    parentId,
    enabled: EnabledStatus.enabled,
    visible: true,
    name,
    tags: [],
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
    position: {
      x: 0,
      y: 0,
      z: 0,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    isStatic: false,
    triggerZone: "none",
    editable: true,
    removable: true,
    components: [],
  };
};
