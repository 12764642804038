import { Navigate } from "~/common/components";
import { ProjectCard } from "~/projects/ProjectCard";
import { Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { toMaxLength } from "~/common/utils/toMaxLength";
import React from "react";
import { Tutorial } from "./Tutorial";

type TutorialCardProps = {
  tutorial: Tutorial;
};

export function TutorialCard({ tutorial }: TutorialCardProps) {
  const { url, previewUrl, name, duration } = tutorial;

  return (
    <Stack gap="6px">
      <Navigate to={url} openInNewWindow>
        <ProjectCard previewUrl={previewUrl ?? "/static/img/content/preview_placeholder.png"} />
      </Navigate>
      <Stack direction="row">
        <Stack gap="2px">
          <Typography fontSize="12px" fontWeight="400" lineHeight="15px" color={palette.primary.gray800}>
            {name}
          </Typography>
          <Typography fontSize="10px" fontWeight="400" lineHeight="12.1px" color={palette.primary.gray400}>
            {duration}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
