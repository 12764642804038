import { ThreeEvent } from "@react-three/fiber";
import { EventEmitter } from "eventemitter3";
import { forwardRef, memo, MutableRefObject, ReactNode, Suspense, useEffect, useMemo, useRef } from "react";
import mergeRefs from "react-merge-refs";
import { Group, Vector3 } from "three";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { EnabledStatus } from "~/types/EnabledStatus";
import ISceneObject from "~/types/ISceneObject";
import { useInteractiveComponent } from "~/view-scene/components/interactive";
import { useDIContext } from "~/view-scene/diContext";
import { EntityContext, useEntityContext, useEntityManager } from "~/view-scene/runtime";
import { ErrorBoundary } from "~/view-scene/utils/ErrorBoundary";
import { useDefaultRef } from "~/view-scene/utils/useDefaultRef";
import { useEntity } from "~/view-scene/utils/useEntity";
import { migratedContexts } from "../runtime/contexts";
import useBVH from "../utils/useBVH";
import useTriggerZone from "../utils/useTriggerZone";
import { Vector3Value } from "../script/values";
import { useYukaEntityManager } from "../yukaSystem";

export type EntityProps = {
  entityId: string;
  renderChildEntities?: boolean;
  children?: ReactNode | ReactNode[];
  onPointerDown?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerUp?: (event: ThreeEvent<PointerEvent>) => void;
  context?: MutableRefObject<EntityContext>;
};

const tmpVector = new Vector3();
const tmpVector2 = new Vector3();

export const Entity = memo(
  forwardRef(
    ({ entityId, renderChildEntities = true, onPointerDown, onPointerUp, children, context }: EntityProps, ref) => {
      const entity = useEntity<ISceneObject>(entityId);
      const { toSceneEntity, toEntityComponent } = useDIContext();

      const { addEntityContext, removeEntityContext } = useEntityManager((state) => ({
        addEntityContext: state.addEntityContext,
        removeEntityContext: state.removeEntityContext,
      }));

      const objectRef = useRef<Group>(null);
      const worldPosition = useRef(defaultVector3());
      useBVH(objectRef.current);

      const {
        id,
        parentId,
        name,
        type,
        enabled,
        isStatic,
        children: childEntities = [],
        triggerZone: triggerZoneId,
        components = [],
        position = { x: 0, y: 0, z: 0 },
        rotation = { x: 0, y: 0, z: 0 },
        scale = { x: 0, y: 0, z: 0 },
      } = entity;

      const vehicle = useYukaEntityManager(objectRef);

      const contextRef = useDefaultRef(context);
      const events = useMemo(() => new EventEmitter(), []);
      const tags = useMemo(() => new Set(entity.tags ?? []), [entity.tags]);
      const cash = useRef<any>();
      cash.current = useMemo(
        () => ({
          position: defaultVector3(),
          rotation: defaultVector3(),
          scale: defaultVector3(),
        }),
        []
      );

      if (!migratedContexts.has(type)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEntityContext(contextRef, () => ({
          id,
          dto: entity,
          parentId,
          name,
          tags,
          events,
          vehicle,
          rootObjectRef: objectRef,
          components: {},
          physicsBodies: {},
          get position() {
            if (objectRef.current?.position) {
              setVector3ValueFromXYZ(cash.current.position, objectRef.current.position);
            }

            return cash.current.position;
          },
          set position(value) {
            objectRef.current?.position.set(value.x, value.y, value.z);
          },
          get worldPosition() {
            if (objectRef.current) {
              objectRef.current.getWorldPosition(tmpVector);

              setVector3ValueFromXYZ(worldPosition.current, tmpVector);
            }

            return worldPosition.current;
          },
          set worldPosition(value: Vector3Value) {
            if (!objectRef.current) {
              return;
            }

            if (!objectRef.current.parent) {
              objectRef.current.position.set(value.x, value.y, value.z);
              return;
            }

            objectRef.current.parent.getWorldPosition(tmpVector2);
            objectRef.current.position.set(value.x - tmpVector2.x, value.y - tmpVector2.y, value.z - tmpVector2.z);
          },
          get rotation() {
            if (objectRef.current?.rotation) {
              setVector3ValueFromXYZ(cash.current.rotation, objectRef.current.rotation);
            }

            return cash.current.rotation;
          },
          set rotation(value) {
            objectRef.current?.rotation.set(value.x, value.y, value.z);
          },
          get scale() {
            if (objectRef.current?.scale) {
              setVector3ValueFromXYZ(cash.current.scale, objectRef.current.scale);
            }

            return cash.current.scale;
          },
          set scale(value) {
            objectRef.current?.scale.set(value.x, value.y, value.z);
          },
          getPhysicsBody: (rigidBodyId?: string) => {
            if (rigidBodyId) {
              return contextRef.current.physicsBodies[rigidBodyId] ?? null;
            }

            return contextRef.current.physicsBodies[Object.keys(contextRef.current.physicsBodies)[0]] ?? null;
          },
        }));

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          addEntityContext(id, contextRef);

          return () => {
            removeEntityContext(id);
            contextRef.current.events.removeAllListeners();
          };
        }, []);
      }

      useEffect(() => {
        const object = objectRef.current;
        if (object) {
          object.updateMatrix();
          object.matrixAutoUpdate = !isStatic;
        }
      }, [isStatic]);

      const entityEnabled = useTriggerZone(triggerZoneId, enabled);

      const enabledComponents = useMemo(
        () => components.filter((component) => component.enabled === EnabledStatus.enabled),
        [components]
      );

      const interactions = useInteractiveComponent(enabledComponents, contextRef);

      if (!entityEnabled) {
        return null;
      }

      const handlePointerDown =
        onPointerDown || interactions.onPointerDown
          ? (event: ThreeEvent<PointerEvent>) => {
              event.stopPropagation();
              onPointerDown?.(event);
              if (interactions.onPointerDown) {
                interactions.onPointerDown(event);
              }
            }
          : undefined;

      const handlePointerUp =
        onPointerUp || interactions.onPointerUp
          ? (event: ThreeEvent<PointerEvent>) => {
              event.stopPropagation();
              onPointerUp?.(event);
              if (interactions.onPointerUp) {
                interactions.onPointerUp(event);
              }
            }
          : undefined;

      return (
        <ErrorBoundary message={`Failed rendering Entity[${type}] with id=${id} and name=${name}`}>
          <Suspense fallback={null}>
            <group
              name={name}
              onPointerDown={handlePointerDown}
              onPointerUp={handlePointerUp}
              onPointerOver={interactions.onPointerOver}
              onPointerOut={interactions.onPointerOut}
              onPointerEnter={interactions.onPointerEnter}
              onPointerLeave={interactions.onPointerLeave}
              onPointerMove={interactions.onPointerMove}
              position={[position.x, position.y, position.z]}
              scale={[scale.x, scale.y, scale.z]}
              rotation={[rotation.x, rotation.y, rotation.z]}
              ref={mergeRefs([objectRef, ref])}
              userData={{ eightXRId: id }}
              visible={entity.visible}
            >
              {children}
              {renderChildEntities && childEntities.map((childEntity) => toSceneEntity(childEntity))}
            </group>
            {enabledComponents.map((component) => toEntityComponent(component, entityId, objectRef, contextRef))}
          </Suspense>
        </ErrorBoundary>
      );
    }
  )
);
