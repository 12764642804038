import { useMemo } from "react";
import { useAsset } from "~/entities/assets";
import { UIAsset } from "~/types/ui";
import { Select, SelectItem, SelectProps } from "./Select";
import { useStoreMap } from "effector-react";
import { $sceneData } from "~/entities/sceneData";

export type OverlayUIEntitySelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  overlayId?: string | null;
  emptyLabel?: string;
};

export const OverlayUIEntitySelect = ({
  value,
  overlayId,
  emptyLabel = "Select UI Entity",
  ...rest
}: OverlayUIEntitySelectProps) => {
  const overlay = useStoreMap({
    store: $sceneData,
    keys: [overlayId],
    fn: (sceneData, [overlayId]) => sceneData?.screenOverlay.items?.find(({ id }) => id === overlayId) ?? null,
  });
  const asset = useAsset<UIAsset>(overlay?.asset);

  const items = useMemo(() => {
    if (!asset) {
      return [];
    }

    return asset.entities.map((entity) => ({
      id: entity.id,
      label: entity.name,
    }));
  }, [asset]);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {items.map((item) => (
        <SelectItem key={item.id} value={item.id}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
};
