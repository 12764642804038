import { BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class TelegramReferalsNode extends BaseNode {
  static readonly type = "hackneyapi/telegramRefs";
  static readonly label = "Get Telegram Refs";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow", "success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext) {
    async function getMyReferals() {
      const answer = await window.HackneyGamesSDK?.getReferals();
      console.log("Referals answer: " + answer);
      context.commit("flow");
      context.graphEvaluator.executeAll();
    }

    getMyReferals().catch((error) => {
      console.error("Error during user authentication :", error);
      context.commit("fail");
      context.graphEvaluator.executeAll();
    });
  }
}
