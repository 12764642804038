import { Object3DNode, extend, useFrame } from "@react-three/fiber";
import { useUnit } from "effector-react";
import { EventEmitter } from "eventemitter3";
import { lazy, memo, useEffect } from "react";
import ThreeMeshUI, { Block, InlineBlock, Text } from "../libs/three-mesh-ui";
import { $uiSystem, completeUISetup } from "./models";
import { useSceneData } from "~/common/stores/useSceneData";

const SetupFonts = lazy(() => import("./SetupFonts").then((module) => ({ default: module.SetupFonts })));

extend(ThreeMeshUI);

declare module "@react-three/fiber" {
  interface ThreeElements {
    block: Object3DNode<Block, typeof Block>;
    uiText: Object3DNode<Text, typeof Text>;
    inlineBlock: Object3DNode<InlineBlock, typeof InlineBlock>;
  }
}

export const uiSystemEvents = new EventEmitter();

export const UISystemSetup = memo(() => {
  const hasText = useSceneData((state) => state.sceneState?.uiSystem?.hasText ?? true);
  const uiSystem = useUnit($uiSystem);

  useEffect(() => {
    if (!hasText) {
      completeUISetup();
    }
  }, []);

  useFrame(() => {
    if (uiSystem.isReady) {
      ThreeMeshUI.update();
    }
  });

  return hasText ? <SetupFonts /> : null;
});
