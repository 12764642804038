import { useUnit } from "effector-react";
import { ReactNode, useCallback, useMemo, useRef } from "react";
import { BoxGeometry, Mesh, MeshBasicMaterial } from "three";
import { useSceneData } from "~/common/stores/useSceneData";
import { EnabledStatus } from "~/types/EnabledStatus";
import IImageAsset from "~/types/IImageAsset";
import { IVideo360SceneObject } from "~/types/IVideo360SceneObject";
import IVideoAsset from "~/types/IVideoAsset";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import { $currentVideo360, enterVideo360 } from "~/view-scene/runtime";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "../Entity";
import { RenderFloatingBall } from "./RenderFloatingBall";
import { Video360 } from "./Video360";

export type Video360EntityProps = {
  entityId: string;
  children?: ReactNode;
};

const geometry = new BoxGeometry(1, 1, 1);
const material = new MeshBasicMaterial({ transparent: true, opacity: 0 });

export const Video360Entity = ({ entityId, children }: Video360EntityProps) => {
  const entity = useEntity<IVideo360SceneObject>(entityId);
  const { id, enabled } = entity;
  const getAsset = useSceneData((state) => state.getAsset);
  const currentVideo360 = useUnit($currentVideo360);
  const objectRef = useRef(null);
  const previewAsset = getAsset<IImageAsset>(entity.previewAsset);
  const videoAsset = getAsset<IVideoAsset>(entity.videoAsset);

  const videoUrl = entity.isStream ? entity.streamUrl : videoAsset?.url;

  const shapeExtension = useMemo(() => new Mesh(geometry.clone(), material), []);

  const handleDiveVideo = useCallback(() => {
    enterVideo360(id);
  }, [id]);

  return videoUrl ? (
    <>
      {enabled === EnabledStatus.enabled && (
        <ActionIcon visibleDistance={10} onActionButtonReleased={handleDiveVideo} objectRef={objectRef} />
      )}
      <Entity entityId={entityId} ref={objectRef}>
        <primitive object={shapeExtension} />
        <RenderFloatingBall previewUrl={previewAsset?.url} />
        <Video360
          url={videoUrl}
          assetName={videoAsset?.name ?? "Stream"}
          isStream={entity.isStream}
          active={id === currentVideo360?.id}
        />
        {children}
      </Entity>
    </>
  ) : null;
};
