import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, LightSocket, NumberSocket } from "../../sockets";
import ILightSceneObject from "~/types/ILightSceneObject";

export class LightSetIntensityNode extends BaseNode {
  static readonly type = "action/lightSetIntensity";
  static readonly label = "Set Light Intensity";

  inputs = [new FlowSocket("flow"), new LightSocket("light"), new NumberSocket("intensity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "light");
    if (!entity) {
      return;
    }
    const entityDescriptor = node.sceneContext.getEntityDescriptor<ILightSceneObject>(entity.id);
    if (!entityDescriptor) {
      return;
    }

    const intensity = context.readInput<number>("intensity");

    node.sceneContext.updateEntityDescriptor<ILightSceneObject>({
      ...entityDescriptor,
      light: {
        ...entityDescriptor.light,
        intensity,
      },
    });
  }
}
