import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class StorageUpdateNode extends BaseNode {
  static readonly type = "event/storageUpdate";
  static readonly label = "Storage Update";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.storageId = node.readMetadata("storageId", "");
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onStorageUpdate = (storageId: string) => {
      if (node.storage.storageId === storageId) {
        context.commit("flow");
      }
    };

    node.scriptContext.scriptEvents.storageUpdate.addListener(onStorageUpdate);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.storageUpdate.removeListener(onStorageUpdate);
    });
  }
}
