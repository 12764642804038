import { CircleGeometry, DoubleSide, Group, Layers, Mesh, MeshBasicMaterial } from "three";
import { useMemo } from "react";
import { useFrame } from "@react-three/fiber";

type Circle = {
  mesh: Mesh<CircleGeometry, MeshBasicMaterial>;
  opacityStep: number;
};

type RenderLoaderProps = {
  circleRadius?: number;
  circleSegments?: number;
  groupRadius?: number;
  circleCount?: number;
  animationDepth?: number;
  layers: Layers;
};

export function RenderLoader({
  circleRadius = 0.25,
  circleSegments = 16,
  groupRadius = 1,
  circleCount = 8,
  animationDepth = 0.05,
  layers,
}: RenderLoaderProps) {
  const loader = useMemo(() => {
    const loader = new Group();
    loader.layers = layers;
    return loader;
  }, [layers]);

  const circles: Circle[] = useMemo(() => {
    const circles: Circle[] = [];

    const angleStep = (2 * Math.PI) / circleCount;
    let currOpacity = 0;
    let opacityStep = 1 / (circleCount / 2 + 1);
    let animationStep = 0.002;
    let currAngle = 0;

    for (let i = 0; i < circleCount; i++) {
      currOpacity += opacityStep;
      if (currOpacity > 1) {
        currOpacity = 1 - opacityStep;
        opacityStep = -opacityStep;
        animationStep = -animationStep;
      }
      const oneCircle = makeCircle(circleRadius, circleSegments, currOpacity, animationStep, layers);

      const pos = polar2cartesian({ distance: groupRadius, radians: currAngle });
      oneCircle.mesh.position.set(pos.x, pos.y, currOpacity * animationDepth);

      currAngle += angleStep;
      loader.add(oneCircle.mesh);
      circles.push(oneCircle);
    }

    return circles;
  }, [circleRadius, circleSegments, groupRadius, circleCount, animationDepth, layers]);

  useFrame(() => {
    loader.rotation.z += 0.02;
    const zStep = 50 / (circleCount / 2 + 1);

    for (let i = 0; i < circles.length; i++) {
      const oneCircle = circles[i];
      let newOpacity = oneCircle.mesh.material.opacity + oneCircle.opacityStep;

      if (newOpacity > 1) {
        newOpacity = 1 - oneCircle.opacityStep;
        oneCircle.opacityStep = -oneCircle.opacityStep;
      } else if (newOpacity < 0) {
        newOpacity = oneCircle.opacityStep;
        oneCircle.opacityStep = -oneCircle.opacityStep;
      }

      oneCircle.mesh.material.opacity = newOpacity;
      oneCircle.mesh.position.z = newOpacity * animationDepth;
    }
  });

  return <primitive object={loader} layers={layers} />;
}

function makeCircle(
  circleRadius = 5,
  circleSegments = 16,
  opacity: number,
  opacityStep = 0.01,
  layers: Layers
): Circle {
  const circleGeometry = new CircleGeometry(circleRadius, circleSegments);
  const circleMaterial = new MeshBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity,
    side: DoubleSide,
  });

  const circle = new Mesh<CircleGeometry, MeshBasicMaterial>(circleGeometry, circleMaterial);
  circle.layers = layers;

  return {
    mesh: circle,
    opacityStep,
  };
}

function polar2cartesian(polar: { distance: number; radians: number }) {
  return {
    x: Math.round(polar.distance * Math.cos(polar.radians) * 1000) / 1000,
    y: Math.round(polar.distance * Math.sin(polar.radians) * 1000) / 1000,
  };
}
