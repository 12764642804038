import { BrowserRouter, Route, Routes } from "react-router-dom";
import ViewScene from "~/view-scene/ViewScene";

export const ExportRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ViewScene urlName={window.__sceneData!.urlName} />} />
      </Routes>
    </BrowserRouter>
  );
};
