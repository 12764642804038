import { defaultNumber } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BlockUIEntity } from "~/types/ui";
import { UIBlockContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket, UIBlockSocket } from "../../sockets";
import { UIBlockValue } from "../../values";

export class UIBlockSizeNode extends BaseNode {
  static readonly type = "action/uiBlockSize";
  static readonly label = "UI Block Get Size";

  inputs = [new UIBlockSocket("uiBlock")];
  outputs = [new NumberSocket("width"), new NumberSocket("height")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const blockValue = context.readInput<UIBlockValue>("uiBlock");
    const entity = node.sceneContext
      .getUIEntityContext<UIBlockContext>(blockValue.entityId ?? "")
      ?.getEntity<BlockUIEntity>();

    context.writeOutput<number>("width", entity?.width ?? defaultNumber());
    context.writeOutput<number>("height", entity?.height ?? defaultNumber());
  }
}
