import { NodeEvalContext } from "~/libs/behave-graph";
import { ImageUIEntity } from "~/types/ui";
import { UIImageContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket, UIImageSocket } from "../../sockets";
import { UIImageValue } from "../../values";

export class UIImageSetSizeNode extends BaseNode {
  static readonly type = "action/uiImageSetSize";
  static readonly label = "UI Image Set Size";

  inputs = [
    new FlowSocket("flow"),
    new UIImageSocket("uiImage"),
    new NumberSocket("width"),
    new NumberSocket("height"),
  ];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const width = context.readInput<number>("width");
    const height = context.readInput<number>("height");
    const imageValue = context.readInput<UIImageValue>("uiImage");

    node.sceneContext.getUIEntityContext<UIImageContext>(imageValue.entityId ?? "")?.updateEntity<ImageUIEntity>({
      width,
      height,
    });
  }
}
