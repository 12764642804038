export const availableScenes = [
  {
    url: "https://t.me/CandylandGameBot",
    posterBig: "/static/img/content/posters/candyland_journey_poster_big.webp",
    posterSmall: "/static/img/content/posters/candyland_journey_poster_small.webp",
    tag: "game / telegram",
    published: true,
  },
  {
    url: "https://t.me/PolygamiGameBot",
    posterBig: "/static/img/content/posters/polygami_poster_big.webp",
    posterSmall: "/static/img/content/posters/polygami_poster_small.webp",
    tag: "game / telegram",
    published: true,
  },
  {
    url: "https://t.me/ParkingJamGameBot",
    posterBig: "/static/img/content/posters/parking_jam_poster_big.webp",
    posterSmall: "/static/img/content/posters/parking_jam_poster_small.webp",
    tag: "game / telegram",
    published: true,
  },
  {
    url: "https://meta.deversee.com/",
    posterBig: "/static/img/content/posters/deversee_poster_big.webp",
    posterSmall: "/static/img/content/posters/deversee_poster_small.webp",
    tag: "game",
    published: true,
  },
  {
    url: "https://www.dogeverse.cool/",
    posterBig: "/static/img/content/posters/dogeverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/dogeverse_poster_small.webp",
    tag: "game",
    published: true,
  },
  {
    url: "/s/BilitaMpash/view",
    posterBig: "/static/img/content/posters/thinkpink_poster_big.webp",
    posterSmall: "/static/img/content/posters/thinkpink_poster_small.webp",
    tag: "art / nft",
    published: true,
  },
  {
    url: "/s/Journey/view",
    posterBig: "/static/img/content/posters/castle_journey_poster_big.webp",
    posterSmall: "/static/img/content/posters/castle_journey_poster_big.webp",
    tag: "game",
    published: true,
  },
  {
    url: "/s/IntergalacticFootball/view",
    posterBig: "/static/img/content/posters/intergalactic_football_poster_big.webp",
    posterSmall: "/static/img/content/posters/intergalactic_football_poster_big.webp",
    tag: "game",
    published: true,
  },
  {
    url: "/s/geckoShrine/view",
    posterBig: "/static/img/content/posters/gecko_shrine_1_poster_big.webp",
    posterSmall: "/static/img/content/posters/gecko_shrine_1_poster_big.webp",
    tag: "game",
    published: true,
  },
  {
    url: "/s/geckoshrineroom2/view",
    posterBig: "/static/img/content/posters/gecko_shrine_2_poster_big.webp",
    posterSmall: "/static/img/content/posters/gecko_shrine_2_poster_big.webp",
    tag: "game",
    published: true,
  },
  {
    url: "/s/nizn6xmq4j/view",
    posterBig: "/static/img/content/posters/mystery_coffee_shop_poster_big.webp",
    posterSmall: "/static/img/content/posters/mystery_coffee_shop_poster_big.webp",
    tag: "game",
    published: true,
  },
  {
    url: "https://play.ticketmeta.io/",
    posterBig: "/static/img/content/posters/ticketmeta_poster_big.webp",
    posterSmall: "/static/img/content/posters/ticketmeta_poster_small.webp",
    tag: "event",
    published: true,
  },
  {
    url: "https://gameverse.8xr.io/",
    posterBig: "/static/img/content/posters/gameverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/gameverse_poster_small.webp",
    tag: "competition",
    published: true,
  },
  {
    url: "https://play.haken-gallery.co.uk/",
    posterBig: "/static/img/content/posters/haken_poster_big.webp",
    posterSmall: "/static/img/content/posters/haken_poster_small.webp",
    tag: "art",
    published: true,
  },
  {
    url: "https://play.digital-air.art/",
    posterBig: "/static/img/content/posters/digital_air_poster_big.webp",
    posterSmall: "/static/img/content/posters/digital_air_poster_small.webp",
    tag: "art",
    published: true,
  },
  {
    url: "https://play.chimeraplatform.art/",
    posterBig: "/static/img/content/posters/chimeraplatform_poster_big.webp",
    posterSmall: "/static/img/content/posters/chimeraplatform_poster_small.webp",
    tag: "art",
    published: true,
  },
  {
    url: "/s/incredible_dream_01/view",
    posterBig: "/static/img/content/posters/incredible_dreams_poster_big.webp",
    posterSmall: "/static/img/content/posters/incredible_dreams_poster_small.webp",
    tag: "art",
    published: true,
  },
  {
    url: "/s/education-level/view",
    posterBig: "/static/img/content/posters/education-level-poster-big.webp",
    posterSmall: "/static/img/content/posters/education-level-poster-small.webp",
    tag: "template",
    published: true,
  },
  {
    url: "/s/IvanDurnev/view",
    posterBig: "/static/img/content/posters/ivan_durnev_gameverse_poster_big.webp",
    posterSmall: "/static/img/content/posters/ivan_durnev_gameverse_poster_small.webp",
    tag: "sketch",
    published: true,
  },
  {
    url: "/s/relaxroom/view",
    posterBig: "/static/img/content/posters/relaxroom_poster_big.webp",
    posterSmall: "/static/img/content/posters/relaxroom_poster_small.webp",
    tag: "sketch",
    published: true,
  },
];
