import { RefObject, Suspense, useCallback, useEffect, useMemo } from "react";
import { CanvasTexture, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry } from "three";
import { useRefWrap } from "~/common/hooks/useRefWrap";
import { useTexture } from "~/common/utils/useTexture";
import { playerControls } from "~/view-scene/ControlsSystem";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { AttachSprite } from "./AttachSprite";
import { AttachSpriteXR } from "./AttachSpriteXR";
import { RaycastScanner } from "./RaycastScanner";
import { VrScanner } from "./VrScanner";

const defaultIconWidth = 1492;
const defaultIconHeight = 608;

type ActionIconProps = {
  icon?: string;
  visible?: boolean;
  visibleDistance: number;
  onActionButtonPressed?: VoidFunction;
  onActionButtonReleased?: VoidFunction;
  objectRef: RefObject<Object3D>;
};

export function ActionIcon({
  icon,
  visible = true,
  visibleDistance,
  onActionButtonPressed,
  onActionButtonReleased,
  objectRef,
}: ActionIconProps) {
  const pressedRef = useRefWrap(onActionButtonPressed);
  const releasedRef = useRefWrap(onActionButtonReleased);
  const mode = useSessionStatus((state) => state.mode);
  const sprite = useMessageSprite({ icon });

  useEffect(() => {
    let pressed = false;

    const actionStartHandler = () => {
      if (!pressed && sprite.visible) {
        pressed = true;
        pressedRef.current?.();
      }
    };
    const actionEndHandler = () => {
      if (pressed) {
        pressed = false;
        releasedRef.current?.();
      }
    };

    playerControls.action.on("start", actionStartHandler);
    playerControls.action.on("end", actionEndHandler);

    return () => {
      playerControls.action.off("start", actionStartHandler);
      playerControls.action.off("end", actionEndHandler);
    };
  }, []);

  const setVisibility = useCallback((flag: boolean) => {
    sprite.visible = flag;
  }, []);

  useEffect(() => {
    if (!visible) {
      setVisibility(false);
    }
  }, [visible]);

  const handleHover = useCallback(() => {
    if (visible) {
      setVisibility(true);
    }
  }, [visible, setVisibility]);

  const handleBlur = useCallback(() => {
    if (visible) {
      setVisibility(false);
    }
  }, [visible, setVisibility]);

  return (
    <Suspense fallback={null}>
      {mode === "vr" ? (
        <>
          <VrScanner objectRef={objectRef} distance={visibleDistance} onHover={handleHover} onBlur={handleBlur} />
          <AttachSpriteXR sprite={sprite} />
        </>
      ) : (
        <>
          <RaycastScanner objectRef={objectRef} distance={visibleDistance} onHover={handleHover} onBlur={handleBlur} />
          <AttachSprite sprite={sprite} />
        </>
      )}
    </Suspense>
  );
}

const useMessageSprite = ({ icon }: Pick<ActionIconProps, "icon">) => {
  const mode = useSessionStatus((state) => state.mode);

  const tipIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    if (mode === "mobile") {
      return "/static/img/viewer/actionable-component/sprite-mobile.png";
    }

    if (mode === "vr") {
      return "/static/img/viewer/actionable-component/sprite-vr.png";
    }

    return "/static/img/viewer/actionable-component/sprite-desktop.png";
  }, [mode, icon]);

  const baseScale = useMemo(() => {
    switch (mode) {
      case "vr":
        return 0.175;
      default:
        return 0.02;
    }
  }, [mode]);

  const texture = useTexture(tipIcon) as CanvasTexture;

  const spriteMaterial = useMemo(() => new MeshBasicMaterial({ color: 0xffffff, transparent: true }), []);

  const sprite = useMemo(() => {
    const imageAspect = texture.image.height / texture.image.width;
    const geometry = new PlaneGeometry(1, imageAspect);
    const sprite = new Mesh(geometry, spriteMaterial);
    sprite.visible = false;

    return sprite;
  }, []);

  useEffect(() => {
    const scaleWidth = (texture.image.width / defaultIconWidth) * baseScale;
    const scaleHeight = (texture.image.height / defaultIconHeight) * baseScale;
    const scale = Math.max(scaleWidth, scaleHeight);
    sprite.scale.set(scale, scale, 1);
    spriteMaterial.map = texture;
    spriteMaterial.needsUpdate = true;
  }, [texture]);

  return sprite;
};
