import { memo, RefObject } from "react";
import { Group } from "three";
import { ModelAnimationComponent as ModelAnimationComponentDescriptor } from "~/types/component";
import { useSceneData } from "~/common/stores/useSceneData";
import IModelAsset from "~/types/IModelAsset";
import { RenderModelAnimation } from "./RenderModelAnimation";
import type { EntityContext } from "~/view-scene/runtime";

type AnimationComponentProps = {
  componentDto: ModelAnimationComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const ModelAnimationComponent = memo(({ componentDto, objectRef, contextRef }: AnimationComponentProps) => {
  const { asset: assetId } = componentDto;
  const getAsset = useSceneData((state) => state.getAsset);
  const asset = getAsset<IModelAsset>(assetId);

  if (!asset) {
    return null;
  }

  return <RenderModelAnimation component={componentDto} asset={asset} objectRef={objectRef} contextRef={contextRef} />;
});
