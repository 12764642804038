import {Euler, Object3D} from "three";
import IRotate from "../../../types/IRotate";

export default function adjustModelRotation(object: Object3D, initialRotation: Euler, rotate: IRotate) {
  if (rotate.x) {
    object.rotateX(initialRotation.x);
  } else {
    object.rotation.x = initialRotation.x;
  }

  if (rotate.y) {
    object.rotateY(initialRotation.y);
  } else {
    object.rotation.y = initialRotation.y;
  }

  if (rotate.z) {
    object.rotateZ(initialRotation.z);
  } else {
    object.rotation.z = initialRotation.z;
  }
}