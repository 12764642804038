import { Bone, Object3D } from "three";
import { isBone } from "./isBone";

export const mapChildrenByIndex = (object: Object3D) => {
  let index = 0;
  const map: Record<string, Object3D> = {};

  object.traverse((obj) => {
    map[index] = obj;
    index++;
  });

  return map;
};

export const mapChildrenBonesByName = (object: Object3D) => {
  const map: Record<string, Bone> = {};

  object.traverse((obj) => {
    if (isBone(obj)) {
      map[obj.name] = obj;
    }
  });

  return map;
};
