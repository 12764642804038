import { RigidBodyType } from "~/types/RigidBodyType";
import { collisionGroup } from "../constants";

export const getDefaultCollisionGroup = (bodyType: RigidBodyType) => {
  switch (bodyType) {
    case "static":
      return collisionGroup.static;
    case "dynamic":
      return collisionGroup.dynamic;
    case "kinematic":
      return collisionGroup.kinematic;
  }
};
