import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class BasicInviteNode extends BaseNode {
  static readonly type = "hackneyapi/basicInvite";
  static readonly label = "Telegram Basic Invite";

  inputs = [new FlowSocket("flow"), new StringSocket("tMeURL")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext) {
    const urlBase = context.readInput<string>("tMeURL");
    const telegramUserId = window.Telegram?.WebApp.initDataUnsafe.user?.id;

    if (!urlBase || !telegramUserId) {
      console.log("URL or User Telegram Id is not set");
      return;
    }
    // Full URL should looks like https://t.me/RoyalFortressBot/RFortress?startapp=referal=89657014
    const url = "https://t.me/share/url?url=" + urlBase + "?startapp=referal=" + telegramUserId;
    window.Telegram?.WebApp.openTelegramLink(url);
  }
}
