import { ValueType } from "~/libs/behave-graph";

export type VariableValue = any;

export const variableValue = new ValueType(
  "parameter",
  () => null,
  (text: VariableValue) => text,
  (value: VariableValue) => value
);
