import { Object3D } from "three";
import { MutableRefObject } from "react";
import { SpriteSceneObject } from "~/types/sceneObject";
import { AnimatedSprite } from "~/view-scene/libs/AnimatedSprite";
import { EntityContext } from "./EntityContext";
import { AnimatedProperty, PropertyLabel, PropertyType } from "~/view-scene/meta";

export class SpriteContext extends EntityContext<SpriteSceneObject> {
  sprite: AnimatedSprite | null = null;

  constructor(dto: SpriteSceneObject, public rootObjectRef: MutableRefObject<Object3D | null>) {
    super(dto, rootObjectRef);
  }

  @PropertyType("number")
  @PropertyLabel("Crop Y")
  @AnimatedProperty(["number", "current"])
  get verticalOffset(): number {
    return this.dto.verticalOffset;
  }

  set verticalOffset(verticalOffset: number) {
    this.dto.verticalOffset = verticalOffset;
    this.sprite?.setVerticalOffset(verticalOffset);
  }
}
