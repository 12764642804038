import { NodeContext } from "../../base";
import { NumberMathOperationNode } from "./NumberMathOperationNode";

export class NumberDivNode extends NumberMathOperationNode {
  static readonly type = "logic/numberDiv";
  static readonly label = "Number /";

  constructor(context: NodeContext) {
    super(context, (a, b) => a / b);
  }
}
