import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = {
  "Content-Type": "application/json",
};

const getHeaders = (ignoreIframeCheck = false) => ({
  ...contentTypeHeader,
  ...getAuthHeader(ignoreIframeCheck),
});

const path = "auth";

export const handleGoogleCb = async (oauthToken, referer) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const codeParam = `code=${oauthToken}`;
  const refererParam = referer ? `&referer=${referer}` : "";

  const response = await fetch(`${API_HOST}/${path}/google/redirect?${codeParam}${refererParam}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const handleFacebookCb = async (oauthToken, referer) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const codeParam = `code=${oauthToken}`;
  const refererParam = referer ? `&referer=${referer}` : "";

  const response = await fetch(`${API_HOST}/${path}/facebook/redirect?${codeParam}${refererParam}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export async function requestNonceForWallet(walletAddress) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/${path}/wallet/${walletAddress}/nonce`, requestOptions);
  const { nonce } = await response.json();

  return nonce;
}

export async function requestNonce() {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };
  const response = await fetch(`https://api.hackney.games/auth/nonce`, requestOptions);
  const { nonce } = await response.json();

  return nonce;
}

export async function loginWithMetamask(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/metamaskLogin`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
}

export async function loginWithSolana(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/solanaLogin`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
}

export async function loginWithEmailPassword(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/login`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
}

export async function signup(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/signup`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
}

export async function mfcSignup(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/mfc/signup`, requestOptions);
  const status = response.status;
  return { status };
}

export async function confirmEmail(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/confirm-email`, requestOptions);
  const status = response.status;

  return { status };
}

export async function resendVerificationEmail() {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/${path}/confirm-email/resend`, requestOptions);
  const status = response.status;

  return { status };
}

export async function requestResetPassword(body) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };

  const response = await fetch(`${API_HOST}/${path}/request-reset-password`, requestOptions);
  const status = response.status;

  return { status };
}

export async function authWithTelegram(telegramId) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(true),
    body: JSON.stringify({ telegramId }),
  };

  const response = await fetch(`${API_HOST}/${path}/telegram`, requestOptions);

  const status = response.status;
  if (status === 200) {
    const body = await response.json();
    return { status, body };
  } else {
    return { status };
  }
}

export async function resetPassword(body) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };

  const response = await fetch(`${API_HOST}/${path}/reset-password`, requestOptions);
  const status = response.status;

  return { status };
}

export const getGoogleAuthUrl = () => `${API_HOST}/${path}/google`;

export const getFacebookAuthUrl = () => `${API_HOST}/${path}/facebook`;
