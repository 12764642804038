import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "~/types/Variable";

export type { EntityValue };

export const entityValue = new ValueType(
  "entity",
  defaultEntity,
  (text: EntityValue) => text,
  (value: EntityValue) => value
);
