import { MouseEvent, useCallback } from "react";
import { NumericField, NumericFieldProps } from "./NumericField";
import { SliderProps, Stack } from "@mui/material";
import { Slider } from "./Slider";
import { debounce, noop } from "lodash-es";

export type SliderInputProps = Omit<SliderProps, "value" | "min" | "max" | "step" | "onChange"> & {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  context?: any;
  onChange?: (e: OnSliderChangeEvent) => void;
  onSliderRelease?: (e: OnSliderChangeEvent) => void;
};

export type OnSliderChangeEvent = {
  target: {
    value: number;
    type: "range" | "number";
    name: string;
    context: any;
  };
};

export const SliderInput = ({
  name = "",
  min,
  max,
  step,
  value,
  placeholder = "",
  context,
  onChange = noop,
  onMouseUp,
  onSliderRelease,
  ...rest
}: SliderInputProps) => {
  const changeValue = useCallback(
    (newValue: number, type: OnSliderChangeEvent["target"]["type"]) => {
      onChange({
        target: {
          value: newValue,
          type,
          name,
          context,
        },
      });
    },
    [name, context, onChange]
  );

  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      const value = typeof newValue === "number" ? newValue : 0;
      changeValue(value, "range");
    },
    [changeValue]
  );

  const handleInputChange: NonNullable<NumericFieldProps["onChange"]> = useCallback(
    (e) => {
      changeValue(e.target.value, "number");
    },
    [changeValue]
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      onMouseUp && onMouseUp(e);

      const input = e.target as HTMLInputElement;
      const value = isNaN(input.valueAsNumber) ? 0 : input.valueAsNumber;
      onSliderRelease &&
        onSliderRelease({
          target: {
            value,
            type: "range",
            name,
            context,
          },
        });
    },
    [name, context, onMouseUp, onSliderRelease]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      paddingRight="4px"
      sx={{
        gridGap: "14px",
        width: "100%",
      }}
    >
      <NumericField
        name={name}
        min={min}
        max={max}
        step={step}
        value={value ?? 0}
        onChange={handleInputChange}
        sx={{
          maxWidth: "65px",
          width: "100%",
          flex: "0 0 65px",
        }}
      />
      <Slider
        name={name}
        min={min}
        max={max}
        step={step}
        onChange={debounce(handleSliderChange)}
        onMouseUp={handleMouseUp}
        value={value}
        {...rest}
      />
    </Stack>
  );
};
