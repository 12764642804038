import { MenuItem as MuiMenuItem, MenuItemProps, styled } from "@mui/material";
import { palette } from "~/theme";

export type { MenuItemProps };

export const MenuItem = styled(MuiMenuItem)({
  gridGap: "6px",
  padding: "7px 6px",
  borderRadius: "8px",
  justifyContent: "space-between",
  position: "relative",
  "&:hover": {
    background: palette.primary.gradient500,
  },
});
