import { Box, BoxProps, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { useState } from "react";
import { Button } from "./Button";
import { CodeEditor, CodeEditorProps } from "./CodeEditor";
import { IconButton } from "./IconButton";
import { Close } from "~/common/components/Icon";

export type CodeEditorModalProps = Pick<CodeEditorProps, "language"> &
  Pick<BoxProps, "sx"> & {
    title: string;
    open: boolean;
    onSave: (value: string) => void;
    onClose: VoidFunction;
    initialValue: string;
  };

export const CodeEditorModal = ({ language, title, open, onSave, onClose, initialValue, sx }: CodeEditorModalProps) => {
  const [value, setValue] = useState(initialValue);

  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "calc(100% - 240px*2 - 48px)",
        background: palette.primary.gradient300,
        border: "1px solid " + palette.primary.black100,
        borderRadius: "12px",
        zIndex: "200",
        verticalAlign: "middle",
        display: "block",
        top: 68,
        overflow: "hidden",
        position: "fixed",
        left: "264px",
        ...sx,
      }}
      component="div"
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "8px 16px",
          borderBottom: "1px solid " + palette.primary.gray20,
        }}
      >
        <Typography fontWeight="600" variant="body1">
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Box
        component="div"
        sx={{
          height: "calc(100vh - 215px)",
          background: palette.primary.gradient500,
        }}
      >
        <CodeEditor language={language} value={value} onChange={(e) => e && setValue(e)} height="calc(100% - 10px)" />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          gridGap: "12px",
          background: palette.primary.gradient500,
          borderTop: "1px solid " + palette.primary.gray20,
          padding: "6px 8px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            gridGap: "12px",
          }}
        >
          <Button size="small" variant="outlined" onClick={onClose} sx={{ minWidth: "auto" }}>
            Don’t save and exit
          </Button>
          <Button size="small" variant="accent" onClick={() => onSave(value)}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
