import { Object3D } from "three";
import { TransformControlsMode } from "~/hephaestus/controls";

export const getTransformValue = (object: Object3D, mode: TransformControlsMode) => {
  switch (mode) {
    case "translate":
      return { x: object.position.x, y: object.position.y, z: object.position.z };
    case "rotate":
      return { x: object.rotation.x, y: object.rotation.y, z: object.rotation.z };
    case "scale":
      return { x: object.scale.x, y: object.scale.y, z: object.scale.z };
    default:
      return null;
  }
};

const modeToFieldMap: Record<TransformControlsMode, string> = {
  translate: "position",
  rotate: "rotation",
  scale: "scale",
  disabled: "",
};

export const transformModeToField = (mode: TransformControlsMode) => {
  return modeToFieldMap[mode];
};
