import { hasType } from "~/common/utils/hasType";
import ILight from "~/types/ILight";
import ISpotLight from "~/types/ISpotLight";
import { LightType } from "~/types/LightType";
import IPointLight from "~/types/IPointLight";
import IDirectionalLight from "~/types/IDirectionalLight";
import IHemisphereLight from "~/types/IHemisphereLight";
import RenderDirectionalLight from "./RenderDirectionalLight";
import RenderSpotLight from "./RenderSpotLight";
import RenderAmbientLight from "./RenderAmbientLight";
import RenderPointLight from "./RenderPointLight";
import RenderHemisphereLight from "./RenderHemisphereLight";

export type RenderLightProps = {
  light: ILight;
  drawHelper?: boolean;
};

export function RenderLight({ light, drawHelper = false }: RenderLightProps) {
  if (hasType<ILight, ISpotLight, LightType>(light, LightType.SPOT, "lightType")) {
    return <RenderSpotLight light={light} drawHelper={drawHelper} />;
  } else if (hasType<ILight, IPointLight, LightType>(light, LightType.POINT, "lightType")) {
    return <RenderPointLight light={light} drawHelper={drawHelper} />;
  } else if (hasType<ILight, IDirectionalLight, LightType>(light, LightType.DIRECTIONAL, "lightType")) {
    return <RenderDirectionalLight light={light} drawHelper={drawHelper} />;
  } else if (hasType<ILight, IHemisphereLight, LightType>(light, LightType.HEMISPHERE, "lightType")) {
    return <RenderHemisphereLight light={light} drawHelper={drawHelper} />;
  } else if (hasType<ILight, ILight, LightType>(light, LightType.AMBIENT, "lightType")) {
    return <RenderAmbientLight light={light} />;
  }

  return null;
}
