import { Object3D } from "three";
import { useEffect } from "react";

export function useToggleMatrixAutoUpdate(model: Object3D | null, isStatic: boolean) {
  useEffect(() => {
    if (!model) {
      return;
    }

    model.traverse((obj) => {
      if (isStatic) {
        obj.updateMatrix();
      }
      obj.matrixAutoUpdate = !isStatic;
    });
  }, [model, isStatic]);
}
