import { useEffect, useMemo } from "react";
import { $assetsMap } from "~/entities/assets";
import { ScreenOverlay } from "~/types/ScreenOverlay";
import { UIAsset } from "~/types/ui";
import { EntityDescriptor, addEntities, removeEntities, useEntityManager } from "~/view-scene/runtime";
import { useUIEntityManagerContexts } from "../useUIEntityManagerContext";
import { buildUiEntityId } from "../utils";

export const useInitOverlays = (screenOverlay: ScreenOverlay | undefined) => {
  useRegisterUIEntities(screenOverlay);
  useRegisterOverlayContexts(screenOverlay);
};

export const useRegisterUIEntities = (screenOverlay: ScreenOverlay | undefined) => {
  useEffect(() => {
    if (screenOverlay) {
      const entitiesStatePayload: EntityDescriptor[] = [];
      const assetsMap = $assetsMap.getState();

      screenOverlay.items.forEach((overlay) => {
        const asset = assetsMap[overlay.asset ?? ""] as UIAsset;

        if (!asset) {
          return;
        }

        asset.entities.forEach((entity) => {
          entitiesStatePayload.push({
            id: buildUiEntityId(overlay.id, entity.id),
            entity: { ...entity },
          });
        });
      });

      addEntities({
        entities: entitiesStatePayload,
      });

      return () => {
        removeEntities(entitiesStatePayload.map((entity) => entity.id));
      };
    }
  }, []);
};

export const useRegisterOverlayContexts = (screenOverlay: ScreenOverlay | undefined) => {
  const registerOverlayContext = useEntityManager((state) => state.registerOverlayContext);
  const ids = useMemo(() => {
    if (screenOverlay) {
      return screenOverlay.items.map((overlay) => overlay.id);
    }

    return [];
  }, [screenOverlay]);

  const { contextsRef } = useUIEntityManagerContexts(ids);

  useEffect(() => {
    for (const id of ids) {
      registerOverlayContext(id, contextsRef.current[id]);
    }
  }, []);
};
