import { hasType } from "~/common/utils/hasType";
import {
  CompressedImageTextureRecord,
  ImageTextureRecord,
  TextureRecord,
  TextureType,
  VideoTextureRecord,
} from "../types";
import { ImageTexture } from "./ImageTexture";
import { memo } from "react";
import { CompressedImageTexture } from "./CompressedImageTexture";
import { VideoTexture } from "./VideoTexture";

type TextureProps = {
  textureRecord: TextureRecord;
};

export const Texture = memo(({ textureRecord }: TextureProps) => {
  if (!textureRecord.url) {
    return null;
  }

  if (hasType<TextureRecord, ImageTextureRecord, TextureType>(textureRecord, "image")) {
    return <ImageTexture textureRecord={textureRecord} />;
  } else if (hasType<TextureRecord, CompressedImageTextureRecord, TextureType>(textureRecord, "compressedImage")) {
    return <CompressedImageTexture textureRecord={textureRecord} />;
  } else if (hasType<TextureRecord, VideoTextureRecord, TextureType>(textureRecord, "video")) {
    return <VideoTexture textureRecord={textureRecord} />;
  }

  return null;
});
