import type { Components, ThemeOptions } from "@mui/material";

export const MuiInputAdornment: Components<ThemeOptions>["MuiInputAdornment"] = {
  variants: [
    {
      props: { position: "start" },
      style: {
        marginRight: 0,
      },
    },
    {
      props: { position: "end" },
      style: {
        marginLeft: 0,
      },
    },
  ],
};
