import IModelSceneObject from "~/types/IModelSceneObject";
import { useGLTF } from "~/view-scene/utils";
import { Object3D } from "three";
import { forwardRef } from "react";
import { RenderObject } from "../../RenderObject";

type RenderModelProps = {
  dto: IModelSceneObject;
  url: string;
};

export const RenderModel = forwardRef<Object3D | null, RenderModelProps>(({ dto, url }, ref) => {
  const {
    isStatic,
    removeMetalness,
    overrideMaterial,
    material: materialId,
    useBasicMaterial,
    castShadow,
    receiveShadow,
    frustumCulling,
  } = dto;
  const { scene } = useGLTF(url);

  if (!scene) {
    return null;
  }

  scene.userData.entityId = dto.id;

  return (
    <RenderObject
      object={scene}
      isStatic={isStatic}
      materialId={!useBasicMaterial && overrideMaterial ? materialId : undefined}
      useBasicMaterial={useBasicMaterial}
      removeMetalness={removeMetalness}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
      frustumCulling={frustumCulling}
      ref={ref}
    />
  );
});
