import { Camera, OrthographicCamera, PerspectiveCamera } from "three";
import { OrthographicCameraControls } from "./OrthographicCameraControls";
import { PerspectiveCameraControls } from "./PerspectiveCameraControls";

type CameraControlsProps = {
  camera: Camera;
  enabled: boolean;
};

export function CameraControls({ camera, enabled }: CameraControlsProps) {
  return camera instanceof PerspectiveCamera ? (
    <PerspectiveCameraControls camera={camera} enabled={enabled} />
  ) : (
    <OrthographicCameraControls camera={camera as OrthographicCamera} enabled={enabled} />
  );
}
