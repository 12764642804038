import IWorkspace from "~/types/IWorkspace";
import { $selectedWorkspace, setActiveLayout, setSelectedWorkspaceId } from "../models";
import { useUnit } from "effector-react";
import { toMaxLength } from "~/common/utils/toMaxLength";
import { PanelItemContainer } from "./PanelItemContainer";
import { PanelItemName } from "./PanelItemName";
import { IconButton } from "@mui/material";
import { ProjectSettings } from "~/common/components/Icon";
import { palette } from "~/theme";
import React from "react";

type SpaceProps = {
  workspace: IWorkspace;
};

export function Workspace({ workspace }: SpaceProps) {
  const { name } = workspace;
  const selectedSpace = useUnit($selectedWorkspace);

  const handleSelectSpace = () => {
    setActiveLayout("workspace");
    setSelectedWorkspaceId(workspace._id ?? null);
  };

  const handleSettings = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedWorkspaceId(workspace._id ?? null);
    setActiveLayout("editSpace");
  };

  const isActive = selectedSpace?._id === workspace._id;
  return (
    <PanelItemContainer onClick={handleSelectSpace} isActive={isActive} sx={{ justifyContent: "space-between" }}>
      <PanelItemName>{toMaxLength(name, 25)}</PanelItemName>
      <IconButton onClick={handleSettings}>
        <ProjectSettings size="small" color={palette.primary.gray800} />
      </IconButton>
    </PanelItemContainer>
  );
}
