import { defaultUIEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { uiBlockValue, UIBlockValue } from "../values";

export class UIBlockSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: UIBlockValue = defaultUIEntity()) {
    super(uiBlockValue.name, id, label, value);
  }

  clone() {
    return new UIBlockSocket(this.id, this.label, this.value);
  }
}
