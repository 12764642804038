import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { AssetMaterialSocket, BooleanSocket } from "../../sockets";
import { AssetMaterialValue } from "../../values";

export class AssetMaterialEqualNode extends BaseNode {
  static readonly type = "logic/assetMaterialEqual";
  static readonly label = "Material =";
  static readonly pure = true;

  inputs = [new AssetMaterialSocket("a"), new AssetMaterialSocket("b")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext) {
    const a = context.readInput<AssetMaterialValue>("a");
    const b = context.readInput<AssetMaterialValue>("b");

    context.writeOutput<boolean>("result", a.assetId === b.assetId);
  }
}
