import { Icon, IconProps } from "./Icon";

export type WeightProps = IconProps;

export const Weight = (props: WeightProps) => (
  <Icon width="20px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M16.288 18.333H3.712a2.037 2.037 0 0 1-2.045-2.045c0-.152 0-.303.075-.455l1.819-7.878a2.274 2.274 0 0 1 2.197-1.743h8.484c1.061 0 1.97.758 2.197 1.743l1.819 7.878c0 .152.075.303.075.455 0 1.136-.909 2.045-2.045 2.045Z" />
      <path d="M10 6.212a2.273 2.273 0 1 0 0-4.545 2.273 2.273 0 0 0 0 4.545Z" />
    </g>
  </Icon>
);
