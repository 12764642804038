import { NodeContext } from "../../base";
import { BitwiseOperationNode } from "./BitwiseOperationNode";

export class BitwiseXorNode extends BitwiseOperationNode {
  static readonly type = "logic/bitwiseXor";
  static readonly label = "Bitwise XOR";

  constructor(context: NodeContext) {
    super(context, (a, b) => a ^ b);
  }
}
