import { useStoreMap } from "effector-react";
import { useMemo } from "react";
import { $allScriptsMap, getScriptVariables } from "~/entities/allScripts";
import { Select, SelectItem, SelectProps } from "~/hephaestus/components";
import { ScriptVariable } from "~/types/ScriptAsset";

export type ScriptVariableSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  scriptId: string | undefined;
  value: string | undefined;
  onlyPublic?: boolean;
};

export const ScriptVariableSelect = ({ scriptId, value, onlyPublic = false, ...rest }: ScriptVariableSelectProps) => {
  const scriptAsset = useStoreMap({
    store: $allScriptsMap,
    keys: [scriptId],
    fn: (scriptsMap, [scriptId]) => scriptsMap?.[scriptId ?? ""] ?? null,
  });

  const variables = useMemo(() => {
    return getScriptVariables(scriptAsset).filter(
      (variable: ScriptVariable) => !onlyPublic || variable.public
    ) as ScriptVariable[];
  }, [onlyPublic, scriptAsset]);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        Select variable
      </SelectItem>
      {variables.map((variable) => (
        <SelectItem key={variable.id} value={variable.id}>
          {variable.name}
        </SelectItem>
      ))}
    </Select>
  );
};
