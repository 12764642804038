import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode } from "../../base";
import { EventSocket, FlowSocket } from "../../sockets";
import { EventValue } from "~/types/Variable";
import { CustomEventBaseNode } from "./CustomEventBaseNode";
import { getComponentId, getEventId } from "../../utils";
import { NodeSpec } from "~/types/ScriptAsset";

/**
 * @deprecated
 */
export abstract class ScriptCustomEventCallNode extends CustomEventBaseNode {
  static type = "event/callScriptEvent";
  static label = "Call Script Event";
  static deprecated = true;

  inputs = [new FlowSocket("flow"), new EventSocket("event")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode): void {
    node.storage.eventId = getEventId(node.spec) ?? "";
    node.storage.componentId = getComponentId(node.spec) ?? "";
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const values: Record<string, any> = {};

    if (node.storage.eventId !== "" && node.storage.componentId !== "") {
      // Skip the first input socket, which is the flow socket
      for (let i = 2; i < node.inputSockets.length; i++) {
        const socket = node.inputSockets[i];
        values[socket.id] = context.readInput(socket.id);
      }

      const script = node.sceneContext.getScriptByComponent(node.storage.componentId);
      script?.events.call(node.storage.eventId, values);
    } else {
      const event = context.readInput<EventValue>("event");
      if (event.componentId && event.eventId) {
        const script = node.sceneContext.getScriptByComponent(event.componentId);
        script?.events.call(event.eventId, event.inputs ?? {});
      }
    }
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getEventInputsSockets(nodeSpec)];
  }
}
