import { defaultScene } from "~/entities/variable";
import { BaseSocket } from "../base";
import { sceneValue, SceneValue } from "../values";

export class SceneSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: SceneValue = defaultScene()) {
    super(sceneValue.name, id, label, value);
  }

  clone() {
    return new SceneSocket(this.id, this.label, this.value);
  }
}
