import { getCurrentUrl } from "~/common/utils";
import { API_HOST } from "~/config";
import { getAuthHeader, getAuthHeaderHG } from "./getAuthHeader";
import { WebAppInitData } from "@twa-dev/types";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = (ignoreIframeCheck: boolean = false) => ({
  ...contentTypeHeader,
  ...getAuthHeader(ignoreIframeCheck),
});

const getHeadersHG = (ignoreIframeCheck: boolean = false) => ({
  ...contentTypeHeader,
  ...getAuthHeaderHG(ignoreIframeCheck),
});

export type AnalyticsRecord = { telegramBotId: string; count: number; online: number };

export const getAnalytics = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/analytics`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = (await response.json()) as AnalyticsRecord[];
  }

  return { status, body };
};

export const getAdConfig = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/ad/ad-config`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const updateAdConfig = async (payload: any) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/ad/ad-config`, requestOptions);
  const status = response.status;

  const body = await response.json();

  return { status, body };
};

export const getAdClicksReport = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/analytics/ad`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};

export const getSentAdMessagesReport = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/analytics/sent-ad-messages`, requestOptions);
  const status = response.status;
  const blob = await response.blob();
  return { status, blob };
};

export const sendMessage = async (botId: string, message: string, users?: string[]) => {
  const payload = { message, users };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/${botId}/sendMessage`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const sendAdClick = async (gameId: string) => {
  const payload = { gameId };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/analytics/ad/click`, requestOptions);
  const status = response.status;

  return { status };
};

export const sendScore = async (score: number) => {
  const payload = { score, gameUrl: getCurrentUrl(), metadata: getTelegramMetadata() };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(true),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/sendScore`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const sendTWAScore = async (score: number, gameId: string) => {
  // shitty solution, but in the game app score is based on BotId, not GameId.
  function gameIdToBotId(gameId: string) {
    switch (gameId) {
      case "endlessSiege":
        return "endlessSiegeGameBot";
      case "endlessSiegeGamee":
        return "endlessSiegeGameeBot";
      case "dropAndMerge":
        return "dropNMergeGameBot";
      case "battalionCommander":
        return "battalionCommanderGameBot";
      case "osolitaireKlondike":
        return "osolitaire_bot";
    }
  }

  const response = await fetch(`${API_HOST}/telegram/twa/sendScore`, {
    method: "POST",
    headers: getHeaders(true),
    body: JSON.stringify({
      score,
      gameUrl: gameIdToBotId(gameId),
    }),
  });

  const status = response.status;
  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const updateScore = async (scoreIdTracker: string, score: number) => {
  const payload = { scoreIdTracker, score, gameUrl: getCurrentUrl(), metadata: getTelegramMetadata() };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/updateScore`, requestOptions);
  const status = response.status;

  return { status };
};

type GetPublicProfileBodyResponse = {
  name: string;
  globalScore: number;
  avatarUrl: string | undefined;
  wallets: [];
};

export const getPublicProfile = async (telegramId: string | number) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/profile/${telegramId}`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as GetPublicProfileBodyResponse;
  return { status, body };
};

export type TelegramWalletProfileParams = {
  telegramId: string | number;
  wallet: any;
  nonce: string;
};

export const getOrCreateTelegramProfile = async (params: any) => {
  const requestOptions = {
    method: "GET",
    headers: getHeadersHG(true),
    // body: JSON.stringify(params),
  };

  const response = await fetch(`https://api.hackney.games/auth/me`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as GetPublicProfileBodyResponse;
  return { status, body };
};

export type AuthByTelegram = {
  initData: string;
  initDataUnsafe: WebAppInitData;
  botId: string;
};

export const telegramAuthentication = async (payload: AuthByTelegram) => {
  const requestOptions = {
    method: "POST",
    headers: getHeadersHG(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`https://api.hackney.games/auth`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const attachWalletToTelegramProfile = async (params: TelegramWalletProfileParams) => {
  const requestOptions = {
    method: "POST",
    headers: getHeadersHG(true),
    body: JSON.stringify(params),
  };

  const response = await fetch(`https://api.hackney.games/tx/wallet`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

type GetPublicRatingBodyResponse = {
  rank: number;
  name: string;
  avatarUrl: string | undefined;
  globalScore: number;
};

export const getPublicRating = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/rating`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as GetPublicRatingBodyResponse[];
  return { status, body };
};

export const sendIncomeTx = async (boc: string) => {
  const payload = { boc: boc };

  const requestOptions = {
    method: "POST",
    headers: getHeadersHG(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`https://api.hackney.games/tx/income`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const getUserBalances = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeadersHG(),
  };

  const response = await fetch(`https://api.hackney.games/tx/balances`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

type PlatformTransaction = {
  id: string;
  date: number;
  currency: string;
  amount: number;
  type: string;
  hash: string;
};

export const getUserPlatfromTransactions = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeadersHG(true),
  };

  const response = await fetch(`https://api.hackney.games/tx`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as Array<PlatformTransaction>;
  return { status, body };
};

export const checkUserSubscribtion = async (telegramId: string | number, chatId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/isUser/${telegramId}/subscribedTo/${chatId}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const checkTx = async (txData: string) => {
  const payload = { onchainMessageHash: txData };

  const requestOptions = {
    method: "POST",
    headers: getHeadersHG(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`https://api.hackney.games/tx/find`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as PayForAdBodyResponse;
  return { status, body };
};

type PayForAdBodyResponse = {
  payLink: string;
};

export const payForAd = async (telegramId: string | number) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/ad/${telegramId}/pay`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as PayForAdBodyResponse;
  return { status, body };
};

export const sendGame = async (chatId: string, gameUrl: string) => {
  const payload = { chatId, gameUrl };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/sendGame`, requestOptions);
  const status = response.status;
  let body: any = undefined;

  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

const getTelegramMetadata = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    chatId: urlParams.get("chatId"),
    messageId: urlParams.get("messageId"),
    inlineMessageId: urlParams.get("inlineMessageId"),
  };
};

type AdEntry = {
  id: string;
  url: string;
  contactHandle: string;
  message: string;
  targetImpressions: number;
  status: string;
  payLink: string;
  amount: number;
};

export const getAds = async (telegramUserId: number) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/ad?telegramUserId=${telegramUserId}`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as Array<AdEntry>;
  return { status, body };
};

type PlaceOrderBodyDto = {
  telegramUserId: string;
  url: string;
  contactHandle: string;
  message: string;
  targetImpressions: number;
};

export const placeOrder = async (payload: PlaceOrderBodyDto) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/telegram/ad/order`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as AdEntry;
  return { status, body };
};

export type TelegramGame = {
  telegramBotId: string;
  telegramUrl: string;
  online: number;
};

export const getTopGames = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/topGames`, requestOptions);
  const status = response.status;
  let body: TelegramGame[] = [];

  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const getLastPlayed = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/telegram/lastPlayed`, requestOptions);
  const status = response.status;
  let body: TelegramGame[] = [];

  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};
