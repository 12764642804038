import { styled } from "@mui/material";
import { noop } from "lodash-es";
import { Framage } from "~/libs/react-framage";
import { Button, ButtonAppearance } from "./Button";
import { CloseButton } from "./CloseButton";
import { ModalImage } from "./ModalImage";
import { Text } from "./Text";
import { ImagesPreload } from "./ImagesPreload";
import { modalsConfig } from "./modalsConfig";

type ModalAppearance = "default";

export type ButtonConfig = {
  label: string;
  appearance?: ButtonAppearance;
};

export type ImageConfig = {
  src: string;
  label?: string;
  width?: string;
  height?: string;
};

export type TextConfig = {
  text: string;
};

export type TopTextConfig = {
  text: string;
};

export type ModalProps = {
  appearance?: ModalAppearance;
  showCloseButton?: boolean;
  topTexts: TopTextConfig[];
  images: ImageConfig[];
  texts: TextConfig[];
  buttons: ButtonConfig[];
  onCloseClick?: () => void;
  onButtonClick?: (index: number) => void;
};

export const Modal = ({
  appearance = "default",
  showCloseButton = false,
  topTexts,
  images,
  texts,
  buttons,
  onCloseClick = noop,
  onButtonClick = noop,
}: ModalProps) => {
  return (
    <Container>
      <ImagesPreload />
      <FramageContainer>
        <Framage
          src={modalsConfig[appearance]}
          alt="modalBackgroundImage"
          view={{ width: 414, height: 408 }}
          nineslice={{
            top: 85,
            bottom: 85,
            right: 95,
            left: 95,
          }}
        />
      </FramageContainer>

      <ContentContainer>
        {topTexts.map((item, i) => (
          <TopTextContainer key={`${i}_text`}>
            <Text text={item.text} />
          </TopTextContainer>
        ))}

        {images.map((item, i) => (
          <ModalImage key={`${i}_image`} src={item.src} label={item?.label} width={item?.width} height={item?.height} />
        ))}

        {texts.map((item, i) => (
          <TextContainer key={`${i}_text`}>
            <Text text={item.text} />
          </TextContainer>
        ))}

        {buttons.map((item, i) => (
          <Button key={`${i}_button`} {...item} index={i} onClick={onButtonClick} />
        ))}
      </ContentContainer>
      {showCloseButton && (
        <CloseButtonContainer>
          <CloseButton onClick={onCloseClick} />
        </CloseButtonContainer>
      )}
    </Container>
  );
};

const Container = styled("div")`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.3s;
  position: relative;
`;

const FramageContainer = styled("div")`
  position: absolute;
  inset: 0;

  & > react-framage {
    position: absolute;
    display: grid;
    inset: 0;
    width: auto;
    height: auto;
  }
`;

const CloseButtonContainer = styled("div")`
  position: absolute;
  z-index: 1001;
  right: 17px;
  top: 8px;
`;

const ContentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 48px 50px 48px;
  position: relative;
  z-index: 1000;
`;

const TopTextContainer = styled("div")`
  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  & img {
    margin: 0 4px;
  }
`;

const TextContainer = styled("div")`
  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin: 8px 0;

  & img {
    margin: 0 4px;
  }
`;
