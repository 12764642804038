import { keyBy } from "lodash-es";
import { ScriptEvent } from "~/types/ScriptAsset";

export abstract class EventsManager {
  readonly events: Record<string, ScriptEvent>;
  readonly eventsByName: Record<string, ScriptEvent> = {};

  constructor(events: ScriptEvent[]) {
    this.events = keyBy(events, "id");
    this.eventsByName = keyBy(events, "name");
  }

  abstract call(eventId: string, values: Record<string, any> | any[]): void;
  abstract callByName(name: string, values: Record<string, any> | any[]): void;
}
