import { Icon, IconProps } from "./Icon";

export type AlignRightProps = IconProps;

export const AlignRight = (props: AlignRightProps) => (
  <Icon width="13px" height="10px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 3.667H3.333M12 1H.667M12 6.333H.667M12 9H3.333"
    />
  </Icon>
);
