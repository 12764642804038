import { Vector3 } from "three";
import { VectorOperationNode } from "./VectorOperationNode";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { BaseBehaveNode } from "../../base";

const vectorA = new Vector3();
const vectorB = new Vector3();

export class VectorSumNode extends VectorOperationNode {
  static readonly type = "logic/vectorSum";
  static readonly label = "Vector +";

  constructor(context: any) {
    super(context, (a, b, node) => {
      vectorA.set(a.x, a.y, a.z);
      vectorB.set(b.x, b.y, b.z);
      vectorA.add(vectorB);

      return setVector3ValueFromXYZ(node.storage.output, vectorA);
    });
  }

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }
}
