import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";
import { EntityValue } from "../../values";

export class RemoveEntityNode extends BaseNode {
  static readonly type = "action/removeEntity";
  static readonly label = "Remove Entity";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = context.readInput<EntityValue>("entity");

    if (!entity.entityId) {
      context.commit("fail");
      return;
    }

    const removed = node.sceneContext.removeEntity(entity.entityId);

    context.commit(removed ? "success" : "fail");
  }
}
