import { Suspense, lazy } from "react";
import { PageLoader } from "~/hephaestus/components";

const Hephaestus = lazy(() => import("./Hephaestus"));

export const HephaestusLazy = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Hephaestus />
    </Suspense>
  );
};
