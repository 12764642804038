import { NodeContext } from "../../base";
import { TextSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToTextNode extends CastToNode {
  static readonly type = "logic/castToText";
  static readonly label = "Cast to Text";

  constructor(context: NodeContext) {
    super(context, new TextSocket("text"), "text");
  }
}
