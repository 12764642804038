import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { Box, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import IUser from "~/types/IUser";
import { stableSort } from "~/common/utils/stableSort";
import { descendingComparator } from "~/common/utils/descendingComparator";
import { EnhancedTableToolbar } from "./components/EnhancedTableToolbar";
import { EnhancedTableHead } from "./components/EnhancedTableHead";

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "emailConfirmed", numeric: false, disablePadding: false, label: "Email Confirmed" },
  { id: "isOnline", numeric: false, disablePadding: false, label: "Online" },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  { id: "workspace", numeric: false, disablePadding: false, label: "Workspaces" },
  { id: "lastLogin", numeric: false, disablePadding: false, label: "Last Login" },
];

export type UserTableProps = {
  users: IUser[];
  onlineUsersCount: number;
  selectedUser: IUser | null;
  setSelectedUser: Dispatch<SetStateAction<IUser | null>>;
  onCreate: VoidFunction;
  onEdit: (user: IUser | null) => void;
  onDelete: (user: IUser | null) => void;
};

export const UserTable = ({
  users,
  onlineUsersCount,
  selectedUser,
  setSelectedUser,
  onCreate,
  onEdit,
  onDelete,
}: UserTableProps) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const handleRequestSort = (event: MouseEvent, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (user: IUser | null) => selectedUser?._id === user?._id;

  const handleUserSelect = (event: MouseEvent<HTMLTableRowElement>, user: IUser | null) => {
    if (isSelected(user)) {
      setSelectedUser(null);
    } else {
      setSelectedUser(user);
    }
  };

  return (
    <>
      <Stack direction="row" marginBottom={2}>
        <Stack justifyContent="center" margin="0 auto">
          <Typography variant="h6">{`Users; registered: ${users.length}; online: ${onlineUsersCount}`} </Typography>
        </Stack>
        <EnhancedTableToolbar selectedUser={selectedUser} onCreate={onCreate} onEdit={onEdit} onDelete={onDelete} />
      </Stack>
      <TableContainer>
        <Table size="small">
          <EnhancedTableHead cells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {stableSort<IUser>(users, getComparator(order, orderBy)).map((user, index) => {
              const isItemSelected = isSelected(user);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleUserSelect(event, user)}
                  role="checkbox"
                  tabIndex={-1}
                  key={user._id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox" />
                  <TableCell component="th" id={labelId} scope="row" padding="none" width="15%">
                    {user.name}
                  </TableCell>
                  <TableCell width="15%">{user.email}</TableCell>
                  <TableCell>{user.emailConfirmed?.toString() ?? false}</TableCell>
                  <TableCell>{user.isOnline?.toString() ?? false}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <Box component="div" sx={{ display: "flex", flexWrap: "wrap" }}>
                      {user.workspaces?.map((workspace) => (
                        <Chip key={workspace._id} label={workspace.name} sx={{ margin: "4px" }} variant="filled" />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>{user.lastLogin}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}
