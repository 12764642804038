import { Suspense } from "react";
import { ErrorBoundary } from "~/view-scene/utils";
import { PreloadAsset } from "./PreloadAsset";
import { usePreloadConfigs } from "./usePreloadConfigs";

export function PreloadSystem() {
  const preloadConfigs = usePreloadConfigs();

  return (
    <>
      {preloadConfigs.map((preloadedConfig) => (
        <ErrorBoundary key={preloadedConfig.id} message={`Failed preloading Asset with id=${preloadedConfig.id}`}>
          <Suspense fallback={null}>
            <PreloadAsset config={preloadedConfig} />
          </Suspense>
        </ErrorBoundary>
      ))}
    </>
  );
}
