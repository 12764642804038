import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { createChart } from "./utils";
import { Chart } from "chart.js";
import { API_HOST } from "~/config";
import { AnalyticsParams } from "./TelegramAnalyticsPage";

export function DAUChart({ gameId, cohortReferer }: AnalyticsParams) {
  const { status, isLoading, data } = useQuery({
    queryKey: `dau_${gameId}_${cohortReferer}`,
    queryFn: () => fetchData(gameId, cohortReferer),
  });

  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (status !== "success") {
      return;
    }

    const dauData = formatData(data);
    if (chartRef.current) {
      chartRef.current.data.labels = dauData.labels;
      chartRef.current.data.datasets = dauData.datasets;
      if (chartRef.current?.options.scales?.y) {
        chartRef.current.options.scales.y.max = Math.floor(data.maxUniqueUsers + data.maxUniqueUsers / 10);
      }
      chartRef.current.update();
    } else {
      chartRef.current = createChart("dauChart", dauData);
    }
  }, [status, data]);

  return <canvas id="dauChart"></canvas>;
}

type DAUResponse = {
  _id: {
    day: number;
    month: number;
    year: number;
    referer: string;
  };
  uniqueUsers: number;
}[];

function formatData(data: DAUResponse) {
  if (!data) {
    return { labels: [], datasets: [], maxUniqueUsers: 0 };
  }

  const labels = Array.from(new Set(data.map((d) => `${d._id.day}/${d._id.month}/${d._id.year}`)));
  const referers = Array.from(new Set(data.map((d) => d._id.referer)));
  const dailyTotals = labels.map((label) => {
    const [day, month, year] = label.split("/").map(Number);
    const dailyEntries = data.filter((d) => d._id.day === day && d._id.month === month && d._id.year === year);
    return dailyEntries.reduce((sum, entry) => sum + entry.uniqueUsers, 0);
  });

  const maxUniqueUsers = Math.max(...dailyTotals);

  const datasets = referers.map((referer) => ({
    label: referer,
    data: labels.map((label) => {
      const [day, month, year] = label.split("/").map(Number);
      const dailyEntry = data.find(
        (d) => d._id.referer === referer && d._id.day === day && d._id.month === month && d._id.year === year
      );
      return dailyEntry ? dailyEntry.uniqueUsers : 0;
    }),
    backgroundColor: "rgba(147, 112, 219, 0.6)",
    borderColor: "rgba(153, 50, 204, 0.3)",
    borderWidth: 1,
  }));

  return { labels, datasets, maxUniqueUsers };
}

async function fetchData(gameId: string, cohortReferer?: string) {
  let getLink = `${API_HOST}/api/events/${gameId}/dau`;
  if (cohortReferer) getLink += `?cohortReferer=${cohortReferer}`;

  const response = await fetch(getLink, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": "fsdjkalfh98370[2h-9hr1p'/1;m12lkdi",
    },
  });

  if (!response.ok) {
    throw new Error("Failed loading reviews");
  }

  return await response.json();
}
