import { Graph as BehaveGraph, InputJSON, LinkJSON, Link } from "~/libs/behave-graph";
import { Graph as GraphSpec, NodeSpec } from "~/types/ScriptAsset";
import { Registry } from "../Registry";
import { BaseBehaveNode } from "../base";

// Purpose:
//  - loads a node graph
export function createBehaveGraph(graphSpec: GraphSpec, registry: Registry): BehaveGraph {
  const graph = new BehaveGraph(registry.behaveRegistry);

  const nodeSpecs = graphSpec.nodes ?? [];

  if (nodeSpecs.length === 0) {
    console.warn("createBehaveGraph: no nodes specified");
  }

  // create new BehaviorNode instances for each node in the json.
  for (let i = 0; i < nodeSpecs.length; i += 1) {
    readNodeJSON(graph, nodeSpecs[i], registry);
  }

  // connect up the graph edges from BehaviorNode inputs to outputs.  This is required to follow execution
  Object.values(graph.nodes).forEach((node) => {
    node.inputSockets.forEach((inputSocket) => {
      inputSocket.links.forEach((link) => {
        const upstreamNode = graph.nodes[link.nodeId];
        if (upstreamNode === undefined) {
          throw new Error(
            `node '${node.typeName}' specifies an input '${inputSocket.name}' whose link goes to ` +
              `a nonexistent upstream node id ${link.nodeId}`
          );
        }
        const upstreamOutputSocket = upstreamNode.outputSockets.find((socket) => socket.name === link.socketName);
        if (upstreamOutputSocket === undefined) {
          throw new Error(
            `node '${node.typeName}' specifies an input '${inputSocket.name}' whose link goes to ` +
              `a nonexistent output '${link.socketName}' on upstream node '${upstreamNode.typeName}'`
          );
        }

        // add, only if unique
        const upstreamLink = new Link(node.id, inputSocket.name);
        if (
          upstreamOutputSocket.links.findIndex(
            (value) => value.nodeId === upstreamLink.nodeId && value.socketName === upstreamLink.socketName
          ) < 0
        ) {
          upstreamOutputSocket.links.push(upstreamLink);
        }
      });
    });

    node.outputSockets.forEach((outputSocket) => {
      outputSocket.links.forEach((link) => {
        const downstreamNode = graph.nodes[link.nodeId];
        if (downstreamNode === undefined) {
          throw new Error(
            `node '${node.typeName}' specifies an output '${outputSocket.name}' whose link goes to ` +
              `a nonexistent downstream node id ${link.nodeId}`
          );
        }
        const downstreamInputSocket = downstreamNode.inputSockets.find((socket) => socket.name === link.socketName);
        if (downstreamInputSocket === undefined) {
          throw new Error(
            `node '${node.typeName}' specifies an output '${outputSocket.name}' whose link goes to ` +
              `a nonexistent input '${link.socketName}' on downstream node '${downstreamNode.typeName}'`
          );
        }

        // add, only if unique
        const downstreamLink = new Link(node.id, outputSocket.name);
        if (
          downstreamInputSocket.links.findIndex(
            (value) => value.nodeId === downstreamLink.nodeId && value.socketName === downstreamLink.socketName
          ) < 0
        ) {
          downstreamInputSocket.links.push(downstreamLink);
        }
      });
    });
  });

  return graph;
}

function readNodeJSON(graph: BehaveGraph, nodeSpec: NodeSpec, registry: Registry) {
  if (nodeSpec.type === undefined) {
    throw new Error("createBehaveGraph: no type for node");
  }

  const node = registry.createNode(nodeSpec);

  if (!node) {
    throw new Error(`createBehaveGraph: node type not found ${nodeSpec.type}`);
  }

  // old style
  if (nodeSpec.inputs !== undefined) {
    const inputsJson = nodeSpec.inputs;
    readNodeInputsJSON(graph, node, inputsJson);
  }
  if (nodeSpec.parameters !== undefined) {
    const parametersJson = nodeSpec.parameters;
    readNodeInputsJSON(graph, node, parametersJson);
  }
  if (nodeSpec.flows !== undefined) {
    const flowsJson = nodeSpec.flows;
    readNodeOutputLinksJSON(graph, node, flowsJson);
  }

  if (graph.nodes[node.id] !== undefined) {
    throw new Error(`multiple nodes with the same "unique id": ${node.id}`);
  }
  registry.initNode(node);
  graph.nodes[node.id] = node;
}

function readNodeInputsJSON(graph: BehaveGraph, node: BaseBehaveNode, inputsJson: { [key: string]: InputJSON }) {
  node.inputSockets.forEach((socket) => {
    // warn if no definition.
    if (inputsJson?.[socket.name] === undefined) {
      //Logger.warn(
      //  `createBehaveGraph: no input socket value or links for node socket: ${node.typeName}.${socket.name}`
      //);
      return;
    }

    const inputJson = inputsJson[socket.name];
    if (inputJson.value !== undefined) {
      // eslint-disable-next-line no-param-reassign
      socket.value = graph.registry.values.get(socket.valueTypeName).deserialize(inputJson.value);
    }

    if (inputJson.links !== undefined) {
      const linksJson = inputJson.links;
      linksJson.forEach((linkJson) => {
        socket.links.push(new Link(linkJson.nodeId, linkJson.socket));
      });
    }
  });

  // validate that there are no additional input sockets specified that were not read.
  Object.keys(inputsJson).forEach((inputName) => {
    const inputSocket = node.inputSockets.find((socket) => socket.name === inputName);
    if (inputSocket === undefined) {
      throw new Error(`node '${node.typeName}' specifies an input '${inputName}' that doesn't exist on its node type`);
    }
  });
}

function readNodeOutputLinksJSON(
  _graph: BehaveGraph,
  node: BaseBehaveNode,
  outputLinksJson: { [key: string]: LinkJSON }
) {
  node.outputSockets.forEach((socket) => {
    const outputLinkJson = outputLinksJson[socket.name];
    if (outputLinkJson !== undefined) {
      socket.links.push(new Link(outputLinkJson.nodeId, outputLinkJson.socket));
    }
  });

  // validate that there are no additional input sockets specified that were not read.
  Object.keys(outputLinksJson).forEach((outputName) => {
    const outputSocket = node.outputSockets.find((socket) => socket.name === outputName);
    if (outputSocket === undefined) {
      throw new Error(
        `node '${node.typeName}' specifies an output '${outputName}' that doesn't exist on its node type`
      );
    }
  });
}
