import { PerspectiveCamera } from "@react-three/drei";
import { forwardRef } from "react";
import { Camera as ThreeCamera } from "three";
import { PerspectiveCamera as PerspectiveCameraType } from "~/types/ICameraSceneObject";
import { useUnit } from "effector-react";
import { $zoomFactor } from "~/view-scene/CameraSystem";

export type RenderPerspectiveCameraProps = {
  default: boolean;
  camera: PerspectiveCameraType;
};

const userData = {
  customCamera: true,
};

export const RenderPerspectiveCamera = forwardRef<ThreeCamera, RenderPerspectiveCameraProps>(
  ({ camera, default: isDefault }, ref) => {
    const zoomFactor = useUnit($zoomFactor);

    return (
      <PerspectiveCamera
        ref={ref}
        fov={camera.fov}
        aspect={camera.aspect}
        far={camera.far}
        near={camera.near}
        zoom={camera.zoom * zoomFactor}
        userData={userData}
        makeDefault={isDefault}
      />
    );
  }
);
