import IImageAsset from "~/types/IImageAsset";
import { useTexture } from "~/view-scene/texture";
import { useEffect } from "react";
import { loadFinish } from "~/view-scene/sceneLoad";
import { ImagePreloadConfig } from "~/types/PreloadConfig";

type PreloadImageProps = {
  asset: IImageAsset;
  config: ImagePreloadConfig;
};

export function PreloadImage({ asset, config }: PreloadImageProps) {
  const texture = useTexture({
    name: asset.name,
    url: asset.url,
    type: asset.isCompressed ? "compressedImage" : "image",
    flipY: config.flipY,
  });

  useEffect(() => {
    if (!texture) {
      return;
    }

    loadFinish(asset.id);
  }, [texture]);

  return null;
}
