import { Box, Stack, styled } from "@mui/material";
import { Navigate } from "~/common/components";
import { palette } from "~/theme";

export function CollapsedPanelLogo() {
  return (
    <Navigate to="/projects">
      <LogoContainer>
        <Box
          component="img"
          src="/static/img/content/logo.svg"
          alt="/static/img/content/logo.svg"
          sx={{
            maxWidth: "30px",
            display: "block",
          }}
        />
      </LogoContainer>
    </Navigate>
  );
}

const LogoContainer = styled(Stack)`
  align-items: center;
  justify-content: center;
  height: 38px;
  background: ${palette.primary.gradient400};
  min-width: 48px;
  padding: 0 4px;
  max-width: 55px;
  border-radius: 12px;
  border-bottom: 1px solid ${palette.primary.gray40};

  img {
    opacity: 1;
  }
`;
