import { Box, css, ListItemText, styled } from "@mui/material";
import React, { useState } from "react";
import { useProfile } from "~/entities/profile";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { User } from "~/common/components/Icon";
import { HOST } from "~/config";
import { Navigate } from "./Navigate";

export function ProfileButton() {
  const { profile, logOut } = useProfile((state) => ({
    profile: state.profile,
    logOut: state.logOut,
  }));

  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);

  if (!profile) {
    return null;
  }

  const { avatarPreview, emailConfirmed } = profile;

  return (
    <>
      <Box
        component="div"
        onClick={handleOpenMenu}
        sx={{
          width: 40,
          borderRadius: "50%",
          overflow: "hidden",
          height: 40,
          flex: "0 0 40px",
          cursor: "pointer",
          "&:hover": {
            opacity: ".6",
          },
        }}
      >
        {avatarPreview ? <UserImage src={avatarPreview} /> : <UserIcon color="primary.white" />}
      </Box>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        disableAutoFocusItem
        anchorEl={menuOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
      >
        <MenuItem>
          <Navigate to="/profile/settings" requireAuth={true}>
            <ListItemText>Profile</ListItemText>
          </Navigate>
        </MenuItem>
        {HOST === "8xr" && emailConfirmed && (
          <MenuItem>
            <Navigate to="/projects" requireAuth={true}>
              <ListItemText>My Projects</ListItemText>
            </Navigate>
          </MenuItem>
        )}
        <MenuItem onClick={logOut}>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

const userStyles = () => css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserIcon = styled(User)`
  ${userStyles}
`;
const UserImage = styled("img")`
  ${userStyles}
`;
