import { ValueType } from "~/libs/behave-graph";
import { defaultStruct } from "~/entities/variable";

export type StructValue = Record<string, any>;

export const structValue = new ValueType(
  "struct",
  defaultStruct,
  (text: StructValue) => text,
  (value: StructValue) => value
);
