import { Graph as BehaveGraph, GraphEvaluator as BehaveGraphEvaluator } from "~/libs/behave-graph";
import { Graph as GraphSchema } from "~/types/ScriptAsset";
import type { MethodsManager } from "./MethodsManager";
import { Registry } from "./Registry";
import { BaseBehaveNode } from "./base";
import { ScriptContext } from "./types";
import type { SceneContext } from "./useSceneContext";
import { createBehaveGraph } from "./utils";

export class Graph {
  protected graph: BehaveGraph;
  graphEvaluator: BehaveGraphEvaluator;
  private nodesArray: BaseBehaveNode[] = [];

  constructor(
    protected graphSchema: GraphSchema,
    protected registry: Registry,
    protected sceneContext: SceneContext,
    protected scriptContext: ScriptContext,
    protected methods: MethodsManager
  ) {
    this.initRegistryNodes(graphSchema);
    this.graph = createBehaveGraph(graphSchema, registry);
    this.graphEvaluator = new BehaveGraphEvaluator(this.graph);
    this.initContext(this.graph);
    this.nodesArray = Object.values(this.graph.nodes) as BaseBehaveNode[];
  }

  get nodes() {
    return this.nodesArray;
  }

  findNode(nodeType: string) {
    for (const node of this.nodesArray) {
      if (node.typeName === nodeType) {
        return node;
      }
    }

    return null;
  }

  initContext(graph: BehaveGraph) {
    const nodes = Object.values(graph.nodes) as BaseBehaveNode[];

    for (const node of nodes) {
      node.scriptContext = this.scriptContext;
      node.sceneContext = this.sceneContext;
    }
  }

  initRegistryNodes(graph: GraphSchema) {
    graph.nodes.forEach((node) => {
      this.registry.registerNodeFor(node);
    });
  }

  executeAll() {
    this.graphEvaluator.executeAll();
  }
}
