import { NodeEvalContext } from "~/libs/behave-graph";
import { EnabledStatus } from "~/types/EnabledStatus";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, ComponentSocket, FlowSocket } from "../../sockets";
import { ComponentValue } from "../../values";

export class SetComponentEnabledNode extends BaseNode {
  static readonly type = "action/setComponentEnabled";
  static readonly label = "Set Modification Enabled";

  inputs = [new FlowSocket("flow"), new ComponentSocket("modification"), new BooleanSocket("enabled")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const enabled = context.readInput<boolean>("enabled");

    const componentValue = context.readInput<ComponentValue>("modification");

    if (!componentValue.entityId || !componentValue.componentId) {
      return;
    }

    node.sceneContext.updateComponent(componentValue.entityId, {
      id: componentValue.componentId,
      enabled: enabled ? EnabledStatus.enabled : EnabledStatus.disabled,
    });
  }
}
