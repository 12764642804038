import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";
import { Level } from "~/types/Log";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

export const sendLog = async (level: Level, message: any) => {
  const body = JSON.stringify({ level, message });
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/log`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};
