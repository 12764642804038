import { Icon, IconProps } from "./Icon";

export type NetworkSystemProps = IconProps;

export const NetworkSystem = (props: NetworkSystemProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_801_118)">
      <path
        d="M1.33333 7.99998H14.6667M1.33333 7.99998C1.33333 11.6819 4.3181 14.6666 7.99999 14.6666M1.33333 7.99998C1.33333 4.31808 4.3181 1.33331 7.99999 1.33331M14.6667 7.99998C14.6667 11.6819 11.6819 14.6666 7.99999 14.6666M14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 7.99999 1.33331M7.99999 1.33331C9.66751 3.15888 10.6152 5.528 10.6667 7.99998C10.6152 10.472 9.66751 12.8411 7.99999 14.6666M7.99999 1.33331C6.33247 3.15888 5.38483 5.528 5.33333 7.99998C5.38483 10.472 6.33247 12.8411 7.99999 14.6666"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_801_118">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
