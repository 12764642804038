import { isUndefined, omitBy } from "lodash-es";
import { API_HOST } from "../config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => {
  return { ...contentTypeHeader, ...getAuthHeader() };
};

// TODO: import from be
export type Tweet = {
  id: string;
  text: string;
  createdAt: string;
  author: {
    avatar?: string;
    name: string;
    username: string;
  } | null;
};

// TODO: import dto from be
export type GetTweetsPayload = {
  query: string;
  sinceId?: string;
};

export const getTweets = async (payload: GetTweetsPayload) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const query = new URLSearchParams(omitBy(payload, isUndefined)).toString();
  const response = await fetch(`${API_HOST}/twitter/search?${query}`, requestOptions);
  const result = await response.json();

  return result.tweets as Tweet[];
};
