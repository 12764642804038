import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const ASSETS_API_PATH = `${API_HOST}/import`;

export const importScene = async (file: File, workspaceId: string) => {
  const data = new FormData();
  data.append("file", file);
  data.append("workspaceId", workspaceId);

  const requestOptions: RequestInit = {
    method: "POST",
    headers: getAuthHeader()!,
    body: data,
  };

  const response = await fetch(`${ASSETS_API_PATH}`, requestOptions);
  const status = response.status;

  return { status };
};
