import { Object3D } from "three";
import { mapChildrenByIndex } from "~/common/utils/childrenNavigate";

export const findModel = (object: Object3D, entityId: string): Object3D | null => {
  return object.children.find((obj) => obj.userData.entityId === entityId) ?? null;
};

export const getChild = (object: Object3D, index: number): Object3D | null => {
  const map = mapChildrenByIndex(object);

  return map[index] ?? null;
};
