import { ValueDescriptor, Variable } from "~/types/Variable";

const valueRecord: Record<ValueDescriptor["type"], true> = {
  string: true,
  number: true,
  boolean: true,
  vector3: true,
  any: true,
  array: true,
  map: true,
  entity: true,
  audio: true,
  model: true,
  video: true,
  text: true,
  ui: true,
  uiOverlay: true,
  component: true,
  actionable: true,
  animation: true,
  trajectory: true,
  followTarget: true,
  constraint: true,
  asset: true,
  assetMaterial: true,
  assetModel: true,
  scene: true,
  uiEntity: true,
  uiBlock: true,
  uiImage: true,
  uiText: true,
  light: true,
  struct: true,
  event: true,
};

export const allValueTypes = Object.keys(valueRecord) as Variable["type"][];

export const valueTypesSet = new Set<Variable["type"]>(allValueTypes);
