import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import React from "react";

export function VRGuidance() {
  return (
    <Stack
      direction="column"
      sx={(theme) => ({
        position: "absolute",
        left: "50%",
        bottom: "20px",
        transform: "translateX(-50%)",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      <Box component="div">
        <Stack
          direction="row"
          spacing={2}
          alignItems="flex-end"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          <img src="/static/img/content/vr.svg" alt="/static/img/content/vr.svg" />
          <img src="/static/img/content/headphones-left.svg" alt="/static/img/content/headphones-left.svg" />
          <img src="/static/img/content/headphones-right.svg" alt="/static/img/content/headphones-right.svg" />
        </Stack>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            width: "100%",
            maxWidth: "361px",
            textAlign: "center",

            margin: "16px auto 0",
            color: palette.primary.gray800,
          }}
          variant="body1"
        >
          Look around and move naturally. Use controllers to move. Hand tracking is available.
        </Typography>
      </Box>
    </Stack>
  );
}
