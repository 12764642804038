import { styled, Typography, TypographyProps } from "@mui/material";
import { palette } from "~/theme";

export const MobileMenuHeaderItem = styled((props: TypographyProps) => (
  <Typography
    whiteSpace="nowrap"
    display="block"
    padding="20px 0"
    color={palette.primary.white}
    fontWeight={700}
    fontSize={36}
    lineHeight="45px"
    {...props}
  />
))(({}) => ({
  textDecoration: "none",
  cursor: "pointer",
  borderBottom: "1px solid " + palette.primary.gray200,
  "&:hover": {
    opacity: ".6",
  },
}));
