import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class TimeNode extends BaseNode {
  static readonly type = "time/time";
  static readonly label = "Time";
  static readonly category = "Time";

  outputs = [new NumberSocket("time")];

  eval(context: NodeEvalContext) {
    context.writeOutput<number>("time", Date.now());
  }
}
