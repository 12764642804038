import IImageSceneObject from "~/types/IImageSceneObject";
import IImageAsset from "~/types/IImageAsset";
import { RenderImageV1 } from "./RenderImageV1";
import { RenderImageV2 } from "./RenderImageV2";

export type RenderImageProps = {
  dto: IImageSceneObject;
  asset: IImageAsset;
};

export const RenderImage = ({ dto, asset }: RenderImageProps) => {
  if (dto.rendererVersion === "v1") {
    return <RenderImageV1 dto={dto} asset={asset} />;
  }

  return <RenderImageV2 dto={dto} asset={asset} />;
};
