import { createEvent, forward, sample } from "effector";
import { $entities, addEntity as storeAddEntity, deleteEntity as storeDeleteEntity } from "~/entities/entities";
import { cancelEntitySelection } from "./selectEntity";
import { sendAddEntity, sendDeleteEntity } from "~/api/socket";
import { editorUndoFactory } from "~/hephaestus/models";

export const deleteEntityAndUnselect = createEvent<string>();

const filteredDelete = sample({
  source: $entities,
  clock: deleteEntityAndUnselect,
  filter: (entities, id) => {
    const entity = entities?.find((entity) => entity.id === id);

    return Boolean(entity && entity.removable);
  },
  fn: (_entities, id) => id,
});

const [deleteWithUndo] = editorUndoFactory(
  [storeDeleteEntity, sendDeleteEntity],
  [storeAddEntity, sendAddEntity],
  (id) => {
    return $entities.getState()?.find((entity) => entity.id === id);
  }
);

forward({
  from: filteredDelete,
  to: deleteWithUndo,
});

forward({
  from: filteredDelete,
  to: cancelEntitySelection,
});
