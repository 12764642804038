import { useProfile } from "~/entities/profile";
import { useEffect } from "react";
import { usePermissions, permissionStatusSelector } from "view-scene/permissions";
import shallow from "zustand/shallow";
import { screenStreamSelector, micStreamSelector, useMediaStreamStore } from "./useMediaStreamStore";

export const useCurrentMedia = () => {
  const micStream = useMicStream();
  const screenStream = useMediaStreamStore(screenStreamSelector);

  return { micStream, screenStream };
};

const useMicStream = () => {
  const profile = useProfile((state) => state.profile);
  const permissionStatus = usePermissions(permissionStatusSelector("microphone"));

  const { getStream, removeStream } = useMediaStreamStore(
    (state) => ({ getStream: state.getStream, removeStream: state.removeStream }),
    shallow
  );

  const deviceName = useProfile((state) => state.profile?.preferredMicDevice ?? null);
  const micStream = useMediaStreamStore(micStreamSelector(deviceName));

  useEffect(() => {
    if (profile && permissionStatus === "granted") {
      const name = profile.preferredMicDevice;

      getStream(name);

      return () => {
        removeStream(name);
      };
    }
  }, [profile, permissionStatus]);

  return micStream;
};
