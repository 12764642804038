import { createEffect, createEvent, createStore } from "effector";
import IScene from "types/IScene";
import { getSceneById, getScenes } from "~/api/scene.api";
import { toHierarchy } from "~/common/utils";
import ISceneObject from "~/types/ISceneObject";

export const loadSceneDataFx = createEffect(async (id: string) => {
  const { status, body: sceneState } = await getSceneById(id);

  if (status !== 200) {
    throw new Error("Not found");
  }

  return sceneState;
});

export const resetSceneData = createEvent();

export const $sceneData = createStore<IScene | null>(null)
  .on(loadSceneDataFx.doneData, (_, payload) => payload)
  .reset(resetSceneData);

export const $sceneDataError = createStore<Error | null>(null)
  .on(loadSceneDataFx, () => null)
  .on(loadSceneDataFx.failData, (_, error) => error);

export const updateScene = createEvent<Partial<IScene>>();
$sceneData.on(updateScene, (state, scene) => {
  if (!state) {
    return state;
  }

  return { ...state, ...scene };
});

export const $sceneHierarchy = $sceneData.map((sceneState) =>
  sceneState ? toHierarchy<ISceneObject>(sceneState.objects) : null
);

export const loadScenesFx = createEffect(async () => {
  const { status, body: scenes } = await getScenes();

  if (status !== 200) {
    throw new Error("Not found");
  }

  return scenes as unknown as IScene[];
});

export const $scenes = createStore<IScene[] | null>(null).on(loadScenesFx.doneData, (_, payload) => payload);

export const $scenesError = createStore<Error | null>(null)
  .on(loadScenesFx, () => null)
  .on(loadScenesFx.failData, (_, error) => error);
