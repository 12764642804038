import { NodeEvalContext } from "~/libs/behave-graph";
import { FollowTargetComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, FollowTargetSocket } from "../../sockets";

export class FollowTargetPlayNode extends BaseNode {
  static readonly type = "action/followTargetPlay";
  static readonly label = "Follow Play";

  inputs = [new FlowSocket("flow"), new FollowTargetSocket("followTarget")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<FollowTargetComponentContext>(context, "followTarget")?.play();
  }
}
