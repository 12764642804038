export const spaces = [
  {
    name: "uac-challenge-december",
    link: "uac-challenge-december",
    posterLarge: "/static/img/content/slider/uac-challenge-december-poster-big.png",
    posterSmall: "/static/img/content/slider/uac-challenge-december-poster-big.png",
    scenes: ["zxk0rykurce", "pogrebok", "u26plgkcxs", "vl5irqdv3c"],
    creators: [
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Sam Vorontsov",
        role: "UAC Challenge Co-Founder",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Ilja Domnins",
        role: "UAC Challenge Co-Founder",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Bagratuni Asya",
        role: "UAC Challenge operations",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://snezhana.nyc",
        name: "Snezhana Paderina",
        role: "UAC Judge",
        contactText: "www.snezhana.nyc",
      },
      {
        contactLink: "https://www.linkedin.com/in/socialprescribing/",
        name: "Arun Nadarasa",
        role: "UAC Judge",
        contactText: "@socialprescribing",
      },
      {
        contactLink: "https://www.linkedin.com/in/parisa-risa-vatanka-pharmd-14b3b25/",
        name: "Parisa Vatanka",
        role: "UAC Judge",
        contactText: "@parisa",
      },
      {
        contactLink: "https://instagram.com/cg_nickday",
        name: "Ivan Durnev",
        role: "UAC Judge",
        contactText: "@cg_nickday",
      },
      {
        contactLink: "https://instagram.com/zaguz_in_digital",
        name: "Irina Zaguzina",
        role: "Artist, finalist",
        contactText: "@zaguz_in_digital",
      },
      {
        contactLink: "https://instagram.com/raw_nasyrov",
        name: "Ravil Nasyrov",
        role: "Artist, finalist",
        contactText: "@raw_nasyrov",
      },
      {
        contactLink: "https://instagram.com/Sergoroden",
        name: "Sergey Gorodetsky",
        role: "Artist, finalist",
        contactText: "@Sergoroden",
      },
      {
        contactLink: "",
        name: "Nikolay Getovz",
        role: "Artist, finalist",
        contactText: "",
      },
    ],
  },
  {
    name: "uac-challenge-september",
    link: "uac-challenge-september",
    posterLarge: "/static/img/content/slider/uac-challenge-september-poster-big.jpg",
    posterSmall: "/static/img/content/slider/uac-challenge-september-poster-big.jpg",
    scenes: ["tny4muuqj5", "59h1lwwl4c6", "66m8c3ks155", "jj4grwl0ec", "4okl7xfl3pn", "ra3k3nzes5e", "IvanDurnev"],
    creators: [
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Sam Vorontsov",
        role: "UAC Challenge Co-Founder",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Ilja Domnins",
        role: "UAC Challenge Co-Founder",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://www.instagram.com/unreal.artists.challenge/",
        name: "Bagratuni Asya",
        role: "UAC Challenge operations",
        contactText: "@unreal.artists.challenge",
      },
      {
        contactLink: "https://snezhana.nyc",
        name: "Snezhana Paderina",
        role: "Art direction",
        contactText: "www.snezhana.nyc",
      },
      {
        contactLink: "https://instagram.com/koshka.neon",
        name: "Anya Koshka Neon",
        role: "Art direction",
        contactText: "@koshka.neon",
      },
      {
        contactLink: "https://instagram.com/cg_nickday",
        name: "Ivan Durnev",
        role: "Artist, winner",
        contactText: "@cg_nickday",
      },
      {
        contactLink: "https://instagram.com/zaguz_in_digital",
        name: "Irina Zaguzina",
        role: "Artist, finalist",
        contactText: "@zaguz_in_digital",
      },
      {
        contactLink: "https://instagram.com/raw_nasyrov",
        name: "Ravil Nasyrov",
        role: "Artist, finalist",
        contactText: "@raw_nasyrov",
      },
      {
        contactLink: "https://instagram.com/Sergoroden",
        name: "Sergey Gorodetsky",
        role: "Artist, finalist",
        contactText: "@Sergoroden",
      },
      {
        contactLink: "",
        name: "Nikolay Getovz",
        role: "Artist, finalist",
        contactText: "",
      },
    ],
  },
  {
    name: "edel-extra",
    link: "digitalairstart",
    posterLarge: "/static/img/content/slider/edel-extra-poster-big.png",
    posterSmall: "/static/img/content/slider/edel-extra-poster-mobile.png",
    scenes: [
      "digitalairstart",
      "entfaltung",
      "daspace6",
      "da3disorientation",
      "da7fishswarmundtiger",
      "da2monique",
      "susannegoats",
      "da4tongue",
    ],
    creators: [
      {
        contactLink: "https://instagram.com/lana_brik",
        name: "Lana Novikova",
        role: "Artist curation",
        contactText: "@lana_brik",
      },
      {
        contactLink: "https://instagram.com/alexander_mrohs",
        name: "Alexander Mrohs",
        role: "Technical 3d artist",
        contactText: "@alexander_mrohs",
      },
      {
        contactLink: "https://instagram.com/sina.martina.neuberger",
        name: "Sina Martina Neuberger",
        role: "Artist",
        contactText: "@sina.martina.neuberger",
      },
      {
        contactLink: "https://instagram.com/elizabeth_thallauer",
        name: "Elizabeth Thallauer",
        role: "Artist",
        contactText: "@elizabeth_thallauer",
      },
      {
        contactLink: "https://instagram.com/insta_grebner",
        name: "Michael Graebner",
        role: "Artist",
        contactText: "@insta_grebner",
      },
      {
        contactLink: "https://instagram.com/paulbiessmann",
        name: "Paul Biesmann",
        role: "Artist",
        contactText: "@paulbiessmann",
      },
      {
        contactLink: "https://instagram.com/monique_s_desto",
        name: "Monique S. Desto",
        role: "Artist",
        contactText: "@monique_s_desto",
      },
      {
        contactLink: "https://instagram.com/the_anna_steward",
        name: "Anna Steward",
        role: "Artist",
        contactText: "@the_anna_steward",
      },
      {
        contactLink: "https://instagram.com/christine_pestova_",
        name: "Christine Pestova",
        role: "Digital AIR residency manager",
        contactText: "@christine_pestova_",
      },
    ],
  },
  {
    name: "joseph-boys",
    link: "tsehotdelki",
    posterLarge: "/static/img/content/slider/joseph-boyce-poster-big.png",
    posterSmall: "/static/img/content/slider/joseph-boyce-poster-mobile.png",
    scenes: ["tsehotdelki", "fieldv1", "edelextra", "arthouse", "olivie"],
    creators: [
      {
        contactLink: "https://instagram.com/paraseusse",
        name: "Olga Klimovitskaia",
        role: "Artist & Manager",
        contactText: "@paraseusse",
      },
      {
        contactLink: "https://instagram.com/wyxzii",
        name: "Ya Nzi",
        role: "3D Artist",
        contactText: "@wyxzii",
      },
      {
        contactLink: "https://instagram.com/danya_orl",
        name: "Danya Orlovsky",
        role: "3D Artist",
        contactText: "@danya_orl",
      },
      {
        contactLink: "https://instagram.com/lika_gomiashvili",
        name: "Angelica Gomiashvili",
        role: "Artist",
        contactText: "@lika_gomiashvili",
      },
      {
        contactLink: "https://instagram.com/soboleva_anastasiia",
        name: "Anastasia Soboleva",
        role: "Artist",
        contactText: "@soboleva_anastasiia",
      },
      {
        contactLink: "https://instagram.com/katyagranova",
        name: "Katya Granova",
        role: "Artist",
        contactText: "@katyagranova",
      },
      {
        contactLink: "https://instagram.com/christine_pestova_",
        name: "Christine Pestova",
        role: "Digital AIR residency manager",
        contactText: "@christine_pestova_",
      },
    ],
  },
  {
    name: "digital-air",
    link: "",
    posterLarge: "/static/img/content/slider/theodor-nymark-poster-big.png",
    posterSmall: "/static/img/content/slider/theodor-nymark-poster-mobile.png",
    scenes: ["lilyoftherocks", "otdelka", "otdelka2", "bandicoot"],
    creators: [
      {
        contactLink: "https://theodornymark.com/",
        name: "Theodor Nymark",
        role: "Artist curation",
        contactText: "theodornymark.com",
      },
      {
        contactLink: "https://instagram.com/c.haxholm",
        name: "Claus Haxholm",
        role: "VR & Sound Artist",
        contactText: "@c.haxholm",
      },
      {
        contactLink: "https://instagram.com/gunkspore",
        name: "Gunk Spore",
        role: "3D Artist",
        contactText: "@gunkspore",
      },
      {
        contactLink: "https://www.frejvolander.com/",
        name: "Frej Volander",
        role: "Artist",
        contactText: "frejvolander.com",
      },
      {
        contactLink: "https://instagram.com/bingqing__",
        name: "Bing Qing Dong",
        role: "3D Artist",
        contactText: "@bingqing__",
      },
      {
        contactLink: "https://instagram.com/christine_pestova_",
        name: "Christine Pestova",
        role: "Digital AIR residency manager",
        contactText: "@christine_pestova_",
      },
    ],
  },
];
