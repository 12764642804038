import { getAvailableSpeakerDevices } from "view-scene/utils/media/getAvailableSpeakerDevices";
import { getAvailableMicDevices } from "view-scene/utils/media/getAvailableMicDevices";
import { BuilderSettings } from "../types/Profile";

const FACE_ANIMATION_ENABLED_REF_NAME = "face_animation_enabled";
const PREFERRED_SPEAKER_REF_NAME = "preferred_speaker";
const PREFERRED_MIC_REF_NAME = "preferred_mic";
const PREFERRED_DPR_REF_NAME = "preferred_dpr";
const PREFERRED_LANGUAGE = "preferred_language";
const BUILDER_SETTINGS = "builder_settings";

function setFaceAnimationEnabled(faceAnimationEnabled: boolean) {
  localStorage.setItem(FACE_ANIMATION_ENABLED_REF_NAME, String(faceAnimationEnabled));
}

function getFaceAnimationEnabled() {
  const stringRepresentation = localStorage.getItem(FACE_ANIMATION_ENABLED_REF_NAME);
  if (stringRepresentation) {
    return JSON.parse(stringRepresentation);
  } else {
    return false;
  }
}

function setPreferredSpeaker(deviceId: string) {
  localStorage.setItem(PREFERRED_SPEAKER_REF_NAME, deviceId);
}

async function getPreferredSpeaker() {
  const savedSpeaker = localStorage.getItem(PREFERRED_SPEAKER_REF_NAME);
  const speakerDevices = await getAvailableSpeakerDevices();
  const speakerConnected = speakerDevices.some(device => device.value === savedSpeaker);

  if (speakerConnected) {
    return savedSpeaker;
  } else {
    const defaultDevice = speakerDevices.find(device => device.value === 'default');
    if (defaultDevice) {
      return defaultDevice.value;
    }
  }

  return undefined;
}

function setPreferredMic(deviceId: string) {
  localStorage.setItem(PREFERRED_MIC_REF_NAME, deviceId);
}

async function getPreferredMic() {
  const savedMic = localStorage.getItem(PREFERRED_MIC_REF_NAME);
  const micDevices = await getAvailableMicDevices();
  const micConnected = micDevices.some(device => device.value === savedMic);

  if (micConnected) {
    return savedMic;
  } else {
    const defaultDevice = micDevices.find(device => device.value === 'default');
    if (defaultDevice) {
      return defaultDevice.value;
    }
  }

  return undefined;
}

function setPreferredDpr(preferredDpr: number) {
  localStorage.setItem(PREFERRED_DPR_REF_NAME, '' + preferredDpr);
}

function getPreferredDpr() {
  const stringRepresentation = localStorage.getItem(PREFERRED_DPR_REF_NAME);
  if (stringRepresentation) {
    return parseInt(stringRepresentation);
  } else {
    return 2;
  }
}

function setPreferredLanguage(preferredLanguage: string) {
  localStorage.setItem(PREFERRED_LANGUAGE, '' + preferredLanguage);
}

function getPreferredLanguage() {
  const stringRepresentation = localStorage.getItem(PREFERRED_LANGUAGE);
  if (stringRepresentation) {
    return stringRepresentation;
  } else {
    return 'en';
  }
}

function setBuilderSettings(builderSettings: BuilderSettings[]) {
  const json = JSON.stringify(builderSettings);
  localStorage.setItem(BUILDER_SETTINGS, json);
}

function getBuilderSettings(): BuilderSettings[] {
  const storedData = localStorage.getItem(BUILDER_SETTINGS);
  return storedData ? JSON.parse(storedData) as BuilderSettings[] : [];
}

export {
  setFaceAnimationEnabled,
  getFaceAnimationEnabled,
  setPreferredSpeaker,
  getPreferredSpeaker,
  setPreferredMic,
  getPreferredMic,
  getPreferredDpr,
  setPreferredDpr,
  setPreferredLanguage,
  getPreferredLanguage,
  setBuilderSettings,
  getBuilderSettings,
}