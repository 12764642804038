import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { useProfile } from "~/entities/profile";
import { BlockchainNetwork } from "~/web3Helpers";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Modal } from "~/common/components";
import { useAttachTonkeeper } from "./useAttachTonkeeper";
import { useAttachMetamask } from "./useAttachMetamask";
import { useAttachPhantom } from "./useAttachPhantom";

export type ChooseWalletDialog = {
  open: boolean;
  network: BlockchainNetwork;
  onClose: VoidFunction;
};

export const AttachWalletDialog = ({ open, network, onClose }: ChooseWalletDialog) => {
  const { loadProfile } = useProfile();

  const [qrCode, setQrCode] = useState<string | null>(null);

  const onWalletAttached = async () => {
    await loadProfile();
    onClose();
  };

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const onError = (message?: string) => {
    setHasError(true);
    setErrorMessage(message ?? null);
  };

  const { requestAttachPhantom } = useAttachPhantom({ onWalletAttached, onError });

  const { requestAttachMetamask } = useAttachMetamask({ network, onWalletAttached, onError });

  const { requestAttachTonkeeper } = useAttachTonkeeper({
    onWalletConnected: (qrCodeUrl) => setQrCode(qrCodeUrl),
    onWalletAttached,
    onError,
  });

  useEffect(() => {
    return () => {
      setQrCode(null);
      // setHasError(false);
    };
  }, [open]);

  const networkToWallets = {
    [BlockchainNetwork.ethereum]: [
      {
        name: "MetaMask",
        icon: "/static/img/content/metamask.svg",
        onClick: requestAttachMetamask,
      },
    ],
    [BlockchainNetwork.polygon]: [
      {
        name: "MetaMask",
        icon: "/static/img/content/metamask.svg",
        onClick: requestAttachMetamask,
      },
    ],
    [BlockchainNetwork.solana]: [
      {
        name: "Phantom",
        icon: "/static/img/content/phantom.svg",
        onClick: requestAttachPhantom,
      },
    ],
    [BlockchainNetwork.ton]: [
      {
        name: "Tonkeeper",
        icon: "/static/img/content/tonkeeper.svg",
        onClick: requestAttachTonkeeper,
      },
    ],
  };

  const showWalletSelection = !hasError && !qrCode;
  const showQrCode = !hasError && qrCode;

  return (
    <Modal open={open} onClose={onClose} title="Connect Wallet">
      {showWalletSelection && (
        <Stack gap="16px">
          <Typography variant="subtitle2" color={palette.primary.gray800}>
            Please choose a wallet
          </Typography>
          {networkToWallets[network].map((wallet) => {
            return (
              <WalletButton
                key={wallet.name}
                onClick={wallet.onClick}
                /* 
                // @ts-ignore */
                startIcon={<WalletIcon component="img" src={wallet.icon} />}
              >
                {wallet.name}
              </WalletButton>
            );
          })}
        </Stack>
      )}
      {showQrCode && (
        <Stack gap="16px">
          <Typography variant="subtitle2" color={palette.primary.gray800}>
            Scan the QR code with you Tonkeeper
          </Typography>
          <Stack alignItems="center" width="100%">
            <Stack
              bgcolor="white"
              alignItems="center"
              justifyContent="center"
              width="260px"
              borderRadius="10px"
              padding="16px"
            >
              <QRCode value={qrCode} />
            </Stack>
          </Stack>
        </Stack>
      )}
      {hasError && (
        <Stack>
          <Typography variant="subtitle2" color={palette.primary.error100}>
            {errorMessage
              ? `${errorMessage}. Try to close this window and connect the wallet again.`
              : "Something went wrong. Try to close this window and connect the wallet again."}
          </Typography>
        </Stack>
      )}
    </Modal>
  );
};

const WalletButton = styled(Button)`
  justify-content: flex-start;
  padding: 20px 20px 20px 5px;
`;

const WalletIcon = styled(Box)`
  display: block;
  width: 32px;
  flex: 0 0 32px;
  height: 32px;
  margin-right: 8px;
`;
