import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket, EntitySocket } from "../../sockets";
import { EntityValue } from "../../values";

export class EntityIsNullNode extends BaseNode {
  static readonly type = "logic/entityIsNull";
  static readonly label = "Entity Is Null";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext) {
    const entity = context.readInput<EntityValue>("entity");

    context.writeOutput<boolean>("result", entity.entityId === null);
  }
}
