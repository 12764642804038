import { Box, styled } from "@mui/material";
import { palette } from "~/theme";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { IconButton } from "~/hephaestus/components/IconButton";
import { HOST } from "~/config";
import { Close, Hamburger } from "../../Icon";
import { BackToExplore } from "../../BackToExplore";
import { Logo } from "../../Logo";
import { Container } from "../../Container";
import { MobileMenu } from "./MobileMenu";

export function MobileHeader() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleMenu = () => {
    document.body.classList.toggle("is-hidden");
    setIsMenuOpened((prev) => !prev);
  };

  const closeMenu = () => setIsMenuOpened(false);

  const location = useLocation();

  const showExploreButton = location.pathname !== "/";

  return (
    <MobileHeaderContainer>
      <Box component="div">{showExploreButton && <BackToExplore />}</Box>
      <Logo
        sx={{
          position: "absolute",
          transform: "translate(-50%,0)",
          left: "50%",
          ...(HOST === "deversee" && { top: -13 }),
        }}
      />
      <IconButton variant="clear" onClick={() => toggleMenu()}>
        {isMenuOpened ? <Close /> : <Hamburger />}
      </IconButton>
      <MobileMenu open={isMenuOpened} onClose={closeMenu} />
    </MobileHeaderContainer>
  );
}

const MobileHeaderContainer = styled(Container)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 8;
  overflow: hidden;
  background-color: ${palette.primary.dark};
  height: 59px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
