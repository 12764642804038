import { encodeUIEntityId } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { UIEntitySocket } from "../../sockets";
import { UIEntityValue } from "../../values";

export class OverlayUIEntityNode extends BaseNode {
  static readonly type: string = "variable/overlayUIEntity";
  static readonly label: string = "Overlay UI Entity";
  static readonly category = "Variable";

  outputs = [new UIEntitySocket("uiEntity")];

  initNode(node: BaseBehaveNode) {
    const overlayId = node.metadata?.overlayId as string | undefined;
    const uiEntityId = node.metadata?.uiEntityId as string | undefined;

    const id =
      overlayId && uiEntityId
        ? encodeUIEntityId({
            type: "overlay",
            overlayId,
            uiEntityId,
          })
        : null;

    node.storage.output = {
      entityId: id,
    };
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    context.writeOutput<UIEntityValue>("uiEntity", node.storage.output);
  }
}
