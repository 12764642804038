import { attach, createStore, forward } from "effector";
import { addUserToWorkspace, deleteUserFromWorkspace, getWorkspaceUsers } from "~/api/workspace.api";
import { $workspace } from "~/entities/workspace";
import IWorkspace from "~/types/IWorkspace";
import { WorkspaceUser } from "~/api/types";

export const $workspaceUsers = createStore<WorkspaceUser[] | null>(null);

export const loadWorkspaceUsersFx = attach({
  source: $workspace,
  effect: async (workspace: IWorkspace | null) => {
    if (!workspace) {
      return [];
    }

    const workspaceId = workspace._id!;
    const { status, body: workspaceUsers } = await getWorkspaceUsers(workspaceId);

    if (status !== 200) {
      throw new Error("Not found");
    }

    return workspaceUsers as WorkspaceUser[];
  },
});

export const addUserToWorkspaceFx = attach({
  source: $workspace,
  effect: async (workspace: IWorkspace | null, email: string) => {
    if (!workspace) {
      return;
    }

    const workspaceId = workspace._id!;
    const { status } = await addUserToWorkspace(workspaceId, email.trim());

    if (status == 404) {
      throw new Error(`${email} is not a registered user.`);
    } else if (status == 409) {
      throw new Error(`${email} already invited to this workspace.`);
    }
  },
});

export const deleteUserFromWorkspaceFx = attach({
  source: $workspace,
  effect: async (workspace: IWorkspace | null, email: string) => {
    if (!workspace) {
      return;
    }

    const workspaceId = workspace._id!;
    const { status } = await deleteUserFromWorkspace(workspaceId, email);

    if (status == 404) {
      throw new Error(`${email} is not a registered user.`);
    }
  },
});

$workspaceUsers.on(loadWorkspaceUsersFx.doneData, (_, workspaceUsers) => workspaceUsers);

forward({ from: addUserToWorkspaceFx.doneData, to: loadWorkspaceUsersFx });
forward({ from: deleteUserFromWorkspaceFx.doneData, to: loadWorkspaceUsersFx });
