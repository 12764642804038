import { useUnit } from "effector-react";
import { $searchQuery } from "../models";
import { ProjectsListContainer } from "../ProjectsListContainer";
import { TutorialCard } from "./TutorialCard";

const tutorials = [
  {
    id: "tutorial_1",
    name: "Scene Navigation. Objects Manipulation",
    duration: "7:04",
    previewUrl: "/static/img/content/tutorials/tutorial_1.webp",
    url: "https://youtu.be/4x39Wkh0K_c",
  },
  {
    id: "tutorial_2",
    name: "Basic Models and Physics",
    duration: "7:41",
    previewUrl: "/static/img/content/tutorials/tutorial_2.webp",
    url: "https://youtu.be/tEjab9oM1qc",
  },
];

const tutorialsNew = [
  {
    id: "tutorial_1",
    name: "01. Local Variables",
    duration: "5:13",
    previewUrl: "/static/img/content/tutorials/tutorial_01_local_variables.webp",
    url: "https://www.youtube.com/watch?v=LYeXAka7AUo",
  },
  {
    id: "tutorial_2",
    name: "02. Gaming Storages",
    duration: "4:23",
    previewUrl: "/static/img/content/tutorials/tutorial_02_gaming_storage.webp",
    url: "https://www.youtube.com/watch?v=wl_46fGG0F8",
  },
  {
    id: "tutorial_3",
    name: "03. Gaming Conditions",
    duration: "3:30",
    previewUrl: "/static/img/content/tutorials/tutorial_03_gaming_conditions.webp",
    url: "https://www.youtube.com/watch?v=mMVgmCDI2W8",
  },
  {
    id: "tutorial_4",
    name: "04. Inventory",
    duration: "3:47",
    previewUrl: "/static/img/content/tutorials/tutorial_04_inventory.webp",
    url: "https://www.youtube.com/watch?v=FUCpWTea3rk",
  },
  {
    id: "tutorial_5",
    name: "05. Rigid Body Modification",
    duration: "4:50",
    previewUrl: "/static/img/content/tutorials/tutorial_05_rigid_body_modification.webp",
    url: "https://www.youtube.com/watch?v=TmA_FFAgpVw",
  },
  {
    id: "tutorial_6",
    name: "06. Coins Collection Mechanic",
    duration: "5:08",
    previewUrl: "/static/img/content/tutorials/tutorial_06_coins_collection_mechanic.webp",
    url: "https://www.youtube.com/watch?v=14-1GfwO5L8",
  },
];

export const Tutorials = () => {
  const searchQuery = useUnit($searchQuery);

  const filteredTutorials = tutorials.filter((tutorial) =>
    searchQuery ? tutorial.name?.toLowerCase().includes(searchQuery.toLowerCase()) : true
  );

  const filteredNewTutorials = tutorialsNew.filter((tutorial) =>
    searchQuery ? tutorial.name?.toLowerCase().includes(searchQuery.toLowerCase()) : true
  );

  return (
    <>
      <ProjectsListContainer>
        {filteredNewTutorials.map((tutorial) => (
          <TutorialCard key={tutorial.id} tutorial={tutorial} />
        ))}
      </ProjectsListContainer>
      <ProjectsListContainer>
        {filteredTutorials.map((tutorial) => (
          <TutorialCard key={tutorial.id} tutorial={tutorial} />
        ))}
      </ProjectsListContainer>
    </>
  );
};
