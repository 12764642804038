import { NodeSpec } from "~/types/ScriptAsset";
import { BaseNode } from "../../base";
import { variableToSocket } from "../../utils";
import { EventValue } from "../../values";

export abstract class DynamicCustomEventBaseNode extends BaseNode {
  protected getEvent(nodeSpec: NodeSpec) {
    const value = nodeSpec.parameters?.event?.value as EventValue | undefined;

    if (!value) {
      return null;
    }

    const script = this.context.scriptsMap[value.scriptId ?? ""];
    const event = script?.schema.events?.find((event) => event.id === value.eventId) ?? null;

    return event;
  }

  protected getEventInputsSockets(nodeSpec: NodeSpec) {
    const event = this.getEvent(nodeSpec);

    return event ? event.inputs.map((input) => variableToSocket(input, input.id)) : [];
  }
}
