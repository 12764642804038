import { Suspense, lazy } from "react";
import type { PerfProps } from "./Perf";

const Perf = lazy(() => import("./Perf").then((module) => ({ default: module.Perf })));

export const PerfLazy = (props: PerfProps) => {
  return (
    <Suspense fallback={null}>
      <Perf {...props} />
    </Suspense>
  );
};
