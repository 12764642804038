import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class GravityNode extends BaseNode {
  static readonly type = "physics/gravity";
  static readonly label = "Get gravity";

  inputs = [new EntitySocket("entity")];
  outputs = [new Vector3Socket("gravity")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");

    const gravity = entityContext?.getPhysicsBody()?.gravity;

    node.storage.output.x = gravity?.x ?? 0;
    node.storage.output.y = gravity?.y ?? 0;
    node.storage.output.z = gravity?.z ?? 0;

    context.writeOutput<Vector3Value>("gravity", node.storage.output);
  }
}
