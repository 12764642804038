import { MutableRefObject } from "react";
import { Camera, Object3D, OrthographicCamera, PerspectiveCamera } from "three";
import ICameraSceneObject from "~/types/ICameraSceneObject";
import { $zoomFactor, setEnabled, setTarget } from "~/view-scene/CameraSystem";
import { AnimatedProperty, PropertyType } from "~/view-scene/meta";
import { EntityContext } from "./EntityContext";

export class CameraContext extends EntityContext<ICameraSceneObject> {
  constructor(dto: ICameraSceneObject, public rootObjectRef: MutableRefObject<Camera | null>) {
    super(dto, rootObjectRef);
  }

  attachTo(target: Object3D) {
    setTarget(target);
  }

  setEnabled(enabled: boolean) {
    setEnabled(enabled);
  }

  get fov() {
    if (this.rootObjectRef.current instanceof PerspectiveCamera) {
      return this.rootObjectRef.current.fov ?? 0;
    }

    return 0;
  }

  set fov(fov: number) {
    if (isPerspective(this.rootObjectRef.current)) {
      this.rootObjectRef.current.fov = fov;
      this.rootObjectRef.current.updateProjectionMatrix();
    }
  }

  @PropertyType("number")
  @AnimatedProperty(["number", "current"])
  get zoom(): number {
    if (isPerspective(this.rootObjectRef.current) || isOrthographic(this.rootObjectRef.current)) {
      return this.rootObjectRef.current.zoom / $zoomFactor.getState();
    }

    return 0;
  }

  set zoom(zoom: number) {
    if (isPerspective(this.rootObjectRef.current) || isOrthographic(this.rootObjectRef.current)) {
      this.rootObjectRef.current.zoom = zoom * $zoomFactor.getState();
      this.rootObjectRef.current.updateProjectionMatrix();
    }
  }
}

const isPerspective = (camera: Camera | null | undefined): camera is PerspectiveCamera => {
  return Boolean((camera as any)?.isPerspectiveCamera);
};

const isOrthographic = (camera: Camera | null | undefined): camera is OrthographicCamera => {
  return Boolean((camera as any)?.isOrthographicCamera);
};
