import { Suspense, lazy, memo } from "react";
import type { NPCEntityProps } from "./NPCEntity";

const NPCEntity = lazy(() => import("./NPCEntity").then((module) => ({ default: module.NPCEntity })));

export const NPCEntityLazy = memo((props: NPCEntityProps) => {
  return (
    <Suspense fallback={null}>
      <NPCEntity {...props} />
    </Suspense>
  );
});
