import { MenuItem as MuiMenuItem, MenuItemProps, styled } from "@mui/material";
import { palette } from "~/theme";

const MenuItem = styled((props: MenuItemProps) => <MuiMenuItem classes={{ selected: "selected-color" }} {...props} />)(({}) => ({
  fontSize: "13px",
  borderBottom: "1px solid " + palette.primary.gray200,
  lineHeight: "19px",
  fontWeight: "400",
  color: palette.primary.white,
  background: "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #000000",
  "&:hover": {
    background: "linear-gradient(0deg, #ffffff80, #ffffff80), #000 !important",
  },
}));

export default MenuItem;
