import { TransformControlsMode } from "~/hephaestus/controls";
import { Object3D } from "three";
import { createEvent, createStore } from "effector";

export const updateTransformControlsSettings = createEvent<Partial<TransformControlsSettings>>();
export const clearTransformControlsSettings = updateTransformControlsSettings.prepend(() => ({
  targetId: undefined,
  onStart: undefined,
  onChange: undefined,
  onCommit: undefined,
}));

export type TransformControlsSettings = {
  targetId?: string;
  mode: TransformControlsMode;
  onStart?: (mode: TransformControlsMode, object: Object3D) => void;
  onChange?: (mode: TransformControlsMode, object: Object3D) => void;
  onCommit?: (mode: TransformControlsMode, object: Object3D) => void;
};

export const $transformControlsSettings = createStore<TransformControlsSettings>({
  mode: "translate",
}).on(updateTransformControlsSettings, (settings, newSettings) => ({
  ...settings,
  ...newSettings,
}));
