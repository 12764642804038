import { MutableRefObject, useEffect, useMemo } from "react";
import { SpriteSceneObject } from "~/types/sceneObject";
import IImageAsset from "~/types/IImageAsset";
import { TextureRequest, useTexture } from "~/view-scene/texture";
import { SpriteContext } from "~/view-scene/runtime/contexts";
import { AnimatedSprite as RenderAnimatedSprite } from "~/view-scene/libs/AnimatedSprite";
import { useTextureAspect } from "./useTextureAspect";
import { useAsset } from "~/entities/assets";

type RenderSpriteProps = {
  entity: SpriteSceneObject;
  contextRef?: MutableRefObject<SpriteContext>;
};

export function RenderSprite({ entity, contextRef }: RenderSpriteProps) {
  const asset = useAsset<IImageAsset>(entity.asset);
  return asset ? <RenderSpriteImpl entity={entity} asset={asset} contextRef={contextRef} /> : null;
}

type RenderSpriteImplProps = {
  entity: SpriteSceneObject;
  asset: IImageAsset;
  contextRef?: MutableRefObject<SpriteContext>;
};

function RenderSpriteImpl({ entity, asset, contextRef }: RenderSpriteImplProps) {
  const { isAnimated, horizontalFramesCount, verticalFramesCount, idleFrame, verticalOffset } = entity;

  const texture = useTexture({
    name: asset.name,
    url: asset.url,
    type: asset.isCompressed ? "compressedImage" : "image",
    flipY: true,
  } as TextureRequest);

  const sprite = useMemo(() => {
    if (!texture) {
      return null;
    }

    const sprite = isAnimated
      ? new RenderAnimatedSprite(texture, horizontalFramesCount, verticalFramesCount, idleFrame, verticalOffset)
      : new RenderAnimatedSprite(texture, 1, 1, 0, verticalOffset);

    if (contextRef) {
      contextRef.current.sprite = sprite;
    }

    return sprite;
  }, [isAnimated, texture, horizontalFramesCount, verticalFramesCount, idleFrame]);

  useEffect(() => {
    sprite?.setVerticalOffset(verticalOffset);
  }, [verticalOffset]);

  useEffect(() => {
    if (sprite) {
      contextRef?.current.events.emit("entityReady");
    }
  }, [sprite]);

  const aspect = useTextureAspect(
    texture,
    isAnimated ? horizontalFramesCount : 1,
    isAnimated ? verticalFramesCount : 1
  );

  if (!sprite) {
    return null;
  }

  return <primitive object={sprite} scale={aspect} />;
}
