import { useEffect } from "react";
import { SupportedTextures, TextureRequest, VideoTextureRequest } from "./types";
import { suspend } from "suspend-react";
import { textureManager } from "./TextureManager";

export function useTexture(textureRequest: TextureRequest | VideoTextureRequest | null) {
  let textureRecord = textureManager.getTextureRecord(textureRequest);

  if (!textureRecord) {
    textureRecord = textureManager.requestTexture(textureRequest!);
  }

  useEffect(() => {
    return () => {
      textureManager.removeReferenceToTexture(textureRecord.id);
    };
  }, []);

  return suspend(textureRecord.promise, [textureRecord.id]) as SupportedTextures | null;
}
