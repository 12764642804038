import { NodeEvalContext } from "~/libs/behave-graph";
import { FollowTargetComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FollowTargetSocket } from "../../sockets";

export class FollowTargetActiveNode extends BaseNode {
  static readonly type = "action/followTargetActive";
  static readonly label = "Follow Is Active";

  inputs = [new FollowTargetSocket("followTarget")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const followTarget = node.readComponent<FollowTargetComponentContext>(context, "followTarget");

    context.writeOutput<boolean>("result", followTarget?.isActive() ?? true);
  }
}
