import { memo } from "react";
import { PushToTalk } from "./PushToTalk";
import { useProfile } from "~/entities/profile";
import { useSceneData } from "~/common/stores/useSceneData";
import shallow from "zustand/shallow";

export const SceneInterface = memo(() => {
  const isLoggedIn = useProfile((state) => state.isAuthorised());
  const { networkSystem, communicationSystem } = useSceneData(
    (state) => ({
      networkSystem: state.sceneState?.networkSystem,
      communicationSystem: state.sceneState?.communicationSystem,
    }),
    shallow
  );

  const isMediaSystemEnabled =
    (isLoggedIn && networkSystem?.enableMultiplayer && communicationSystem?.voiceCommunicationEnabled) ?? false;

  return <>{isMediaSystemEnabled && <PushToTalk />}</>;
});
