import { Icon, IconProps } from "./Icon";

export type MenuProps = IconProps;

export const Menu = (props: MenuProps) => (
  <Icon width="20px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentcolor"
      d="M2 1a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM0 10a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM2 19a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"
    />
  </Icon>
);
