import { ValueType } from "~/libs/behave-graph";
import { defaultArray } from "~/entities/variable";

export type ArrayValue = Array<any>;

class ArrayType extends ValueType {
  constructor() {
    super(
      "array",
      defaultArray,
      (json: ArrayValue) => json,
      (value: ArrayValue) => value
    );
  }
}

export const arrayValue = new ArrayType();
