import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { ScriptVariableBaseNode } from "./ScriptVariableBaseNode";

export class ScriptVariableNode extends ScriptVariableBaseNode {
  static type = "variable/scriptVariable";
  static label = "Get Script Variable";
  static category: NodeCategory = "Variable";

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const componentId = node.spec.metadata?.componentId ?? "";
    const variableId = node.spec.metadata?.variableId ?? "";
    const script = node.sceneContext.getScriptByComponent(componentId);
    const value = script?.variables.getValue(variableId);

    context.writeOutput("value", value);
  }

  getOutputs(nodeSpec: NodeSpec): BaseSocket[] {
    const socket = this.getVariableSocket(nodeSpec);

    return socket ? [socket] : [];
  }
}
