import { BlockchainNetwork } from "../types";

export const networkToName = (network: BlockchainNetwork) => {
  switch (network) {
    case BlockchainNetwork.ethereum:
      return "Ethereum";
    case BlockchainNetwork.solana:
      return "Solana";
    case BlockchainNetwork.polygon:
      return "Polygon";
    case BlockchainNetwork.ton:
      return "TON";
    default:
      return "Unknown network";
  }
};
