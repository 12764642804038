import * as React from "react";
import { RefObject, useEffect, useRef } from "react";
import { PositionalAudio } from "three";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import { useFrame } from "@react-three/fiber";

export type RenderPositionalAudioHelperProps = {
  audioRef: RefObject<PositionalAudio>;
};

export function RenderPositionalAudioHelper({ audioRef }: RenderPositionalAudioHelperProps) {
  const helperRef = useRef<PositionalAudioHelper>();

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    helperRef.current = new PositionalAudioHelper(audio);
    audio.add(helperRef.current);

    return () => {
      const audio = audioRef.current;
      const helper = helperRef.current;
      if (audio && helper) {
        audio.remove(helper);
        helper.dispose();
      }
    };
  }, []);

  useFrame(() => helperRef.current?.update());

  return null;
}
