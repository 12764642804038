import { AnimationMixer, Box3, SkinnedMesh } from "three";
import { ErrorBoundary, useGLTF, vectorFlatLength } from "~/view-scene/utils";
import { MutableRefObject, Suspense, useMemo, useRef } from "react";
import { InstancedSkinnedMesh } from "~/view-scene/libs/InstancedSkinnedMesh";
import { useFrame } from "@react-three/fiber";
import { useFarLODAvatar, WALKING_THRESHOLD } from "~/view-scene/avatar";
import { useSceneData } from "~/common/stores/useSceneData";

const MAX_INSTANCES = 100;
const ANIMATIONS_UPDATE_FREQUENCY = 1 / 15;

let avatarDummy: SkinnedMesh | null = null;

let idleAnimationDuration = 0;
let runningAnimationDuration = 0;
const remoteUserIdToAnimationOffset: Map<string, number> = new Map();

type FarRemoteUsersProps = {
  remoteUserIdsRef: MutableRefObject<string[]>;
};

export function FarRemoteUsers({ remoteUserIdsRef }: FarRemoteUsersProps) {
  const farLODAvatar = useFarLODAvatar();

  const { animations } = useGLTF("/static/models/default_animations.glb");

  const positionOffsetY = useMemo(() => {
    const box = new Box3();
    box.setFromObject(farLODAvatar);
    return -(box.max.y - box.min.y) / 2;
  }, [farLODAvatar]);

  const rotationOffsetY = Math.PI;

  const instancedAvatars = useMemo(() => {
    let skinnedMesh: null | SkinnedMesh = null;

    farLODAvatar.traverse((o: any) => {
      if (o instanceof SkinnedMesh) {
        skinnedMesh = o;
      }
    });

    if (skinnedMesh == null) {
      return null;
    }

    avatarDummy = skinnedMesh as SkinnedMesh;

    const instancedAvatars = new InstancedSkinnedMesh(
      (skinnedMesh as SkinnedMesh).geometry,
      (skinnedMesh as SkinnedMesh).material,
      MAX_INSTANCES
    );
    instancedAvatars.copy(skinnedMesh as SkinnedMesh);
    instancedAvatars.bind((skinnedMesh as SkinnedMesh).skeleton, (skinnedMesh as SkinnedMesh).bindMatrix);

    // @ts-ignore
    instancedAvatars["isPoints"] = false;
    // @ts-ignore
    instancedAvatars["isMesh"] = true;
    instancedAvatars.frustumCulled = false;

    // Init All Instances
    avatarDummy.position.set(0, 0, 0);
    avatarDummy.updateMatrix();
    avatarDummy.skeleton.bones.forEach((b) => b.updateMatrixWorld());

    for (let index = 0; index < MAX_INSTANCES; index++) {
      instancedAvatars.setMatrixAt(index, avatarDummy.matrix);
      instancedAvatars.setBonesAt(index, avatarDummy.skeleton);
    }

    instancedAvatars.instanceMatrix.needsUpdate = true;
    // @ts-ignore
    if (instancedAvatars.skeleton && instancedAvatars.skeleton.bonetexture) {
      // @ts-ignore
      instancedAvatars.skeleton.bonetexture.needsUpdate = true;
    }

    return instancedAvatars;
  }, [farLODAvatar]);

  const idleAnimationMixer = useMemo(() => {
    const animationMixer = new AnimationMixer(farLODAvatar);

    const idleAnimationClip = animations.find((clip) => clip.name === "IDLE");
    if (!idleAnimationClip) {
      throw new Error(`Animation not found`);
    }

    idleAnimationDuration = idleAnimationClip.duration;
    const idleAction = animationMixer.clipAction(idleAnimationClip);
    idleAction.play();

    return animationMixer;
  }, [farLODAvatar, animations]);

  const runningAnimationMixer = useMemo(() => {
    const animationMixer = new AnimationMixer(farLODAvatar);

    const runningAnimationClip = animations.find((clip) => clip.name === "RUNNING");
    if (!runningAnimationClip) {
      throw new Error(`Animation not found`);
    }

    runningAnimationDuration = runningAnimationClip.duration;
    const runningAction = animationMixer.clipAction(runningAnimationClip);
    runningAction.play();

    return animationMixer;
  }, [farLODAvatar, animations]);

  const deltaSummer = useRef(0);

  useFrame((_, delta) => {
    if (!instancedAvatars || !avatarDummy) {
      return;
    }

    deltaSummer.current += delta;
    const shouldUpdateAnimations = deltaSummer.current >= ANIMATIONS_UPDATE_FREQUENCY;

    const remoteUsers = useSceneData.getState().remoteUsers;
    const farRemoteUsers = remoteUsers.filter((remoteUser) => remoteUserIdsRef.current.includes(remoteUser.id));
    const activeInstances = farRemoteUsers.length;

    instancedAvatars.count = activeInstances;

    if (activeInstances === 0) {
      return;
    }

    for (let index = 0; index < activeInstances; index++) {
      const farRemoteUser = farRemoteUsers[index];
      const farRemoteUserAvatar = farRemoteUser.avatar;

      // update positions
      avatarDummy.position.set(
        farRemoteUserAvatar.position[0],
        farRemoteUserAvatar.position[1] + positionOffsetY,
        farRemoteUserAvatar.position[2]
      );
      avatarDummy.rotation.set(0, farRemoteUserAvatar.head.rotation[1] + rotationOffsetY, 0);
      avatarDummy.updateMatrix();
      instancedAvatars.setMatrixAt(index, avatarDummy.matrix);

      // update animations
      if (shouldUpdateAnimations) {
        let mixer;

        const velocityLength = vectorFlatLength(farRemoteUserAvatar.velocity);

        let animationTimeOffset = remoteUserIdToAnimationOffset.get(farRemoteUser.id) ?? Math.random();
        animationTimeOffset += deltaSummer.current;
        remoteUserIdToAnimationOffset.set(farRemoteUser.id, animationTimeOffset);

        if (velocityLength >= WALKING_THRESHOLD) {
          mixer = runningAnimationMixer;
          mixer.setTime(animationTimeOffset % runningAnimationDuration);
        } else {
          mixer = idleAnimationMixer;
          mixer.setTime(animationTimeOffset % idleAnimationDuration);
        }

        avatarDummy.skeleton.bones.forEach((b) => b.updateMatrixWorld());
        instancedAvatars.setBonesAt(index, avatarDummy.skeleton);
      }
    }

    instancedAvatars.instanceMatrix.needsUpdate = true;

    if (shouldUpdateAnimations) {
      deltaSummer.current = 0;

      // @ts-ignore
      if (instancedAvatars.skeleton && instancedAvatars.skeleton.bonetexture) {
        // @ts-ignore
        instancedAvatars.skeleton.bonetexture.needsUpdate = true;
      }
    }
  });

  if (!instancedAvatars) {
    return null;
  }

  return (
    <ErrorBoundary message="Failed rendering FarRemoteUsers">
      <Suspense fallback={null}>
        <primitive object={instancedAvatars} />
      </Suspense>
    </ErrorBoundary>
  );
}
