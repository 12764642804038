import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authWithTelegram } from "~/api/auth.api";
import { ExternalGame } from "./ExternalGame";

export function TelegramWebAppGameRoute() {
  const { gameId } = useParams();

  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const run = async () => {
      await waitForTelegramSDK();
      window.Telegram?.WebApp.expand();
      window.Telegram?.WebApp.enableClosingConfirmation();

      const user = window.Telegram?.WebApp.initDataUnsafe.user;
      const userId = user?.id ?? null;

      if (userId) {
        const { body } = await authWithTelegram(userId);
        if (body?.jwt) {
          setAccessToken(body.jwt);
        }
      }
    };

    run();
  }, []);

  if (!gameId || !accessToken) {
    return null;
  }

  switch (gameId) {
    case "endlessSiege":
    case "dropAndMerge":
    case "battalionCommander":
    case "endlessSiegeGamee":
    case "osolitaireKlondike":
    case "osolitaireFreecell":
    case "osolitairePyramid":
    case "osolitaireRus":
    case "osolitaireGolf":
    case "osolitaireSpider":
    case "osolitaireYukon":
    case "osolitaireTriPeaks":
      return (
        <ExternalGame
          key={gameId}
          gameId={gameId}
          urlName={gameId}
          accessToken={accessToken}
          showAddLoadingScreen={true}
          forceFullHeight={true}
        />
      );
  }

  return null;
}

const waitForTelegramSDK = () => {
  return new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (window.Telegram) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
};
