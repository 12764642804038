export const shaders = {
  teleport_vert: `
    uniform float animNormTime;
    varying vec2 vUv;
    varying vec3 vPosition;
    attribute vec3 finalStatePosition;
    void main()
    {
      vUv = uv;
      vPosition = position;
      vec3 newPosition = position + animNormTime * (finalStatePosition - position);
      gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
    }
    `,
  teleport_frag: `
      uniform float time;
      varying vec2 vUv;
      void main() {
        vec3 col = 0.9 + 0.2*cos(time+vUv.xyx+vec3(0,2,4));
        gl_FragColor = vec4(col,1.0);
      }
    `,
};
