import { ValueDescriptor } from "~/types/Variable";

export const defaultBoolean = () => false;
export const defaultNumber = () => 0;
export const defaultString = () => "";
export const defaultVector3 = () => ({ x: 0, y: 0, z: 0 });
export const defaultAny = () => null;
export const defaultEntity = () => ({ entityId: null });
export const defaultArray = <T = any>() => [] as T[];
export const defaultMap = <T = any>() => ({} as Record<string, T>);
export const defaultComponent = () => ({ entityId: null, componentId: null });
export const defaultAsset = () => ({ assetId: null });
export const defaultScene = () => ({ sceneId: null });
export const defaultUIEntity = () => ({ entityId: null });
export const defaultUIOverlay = () => ({ overlayId: null });
export const defaultEvent = () => ({ entityId: null, componentId: null, scriptId: null, eventId: null, inputs: {} });
export const defaultStruct = () => ({});

export const getDefaultValue = (type: ValueDescriptor["type"]) => {
  return valuesMap[type]();
};

const valuesMap: Record<ValueDescriptor["type"], () => ValueDescriptor["value"]> = {
  boolean: defaultBoolean,
  number: defaultNumber,
  string: defaultString,
  vector3: defaultVector3,
  any: defaultAny,
  array: defaultArray,
  map: defaultMap,

  entity: defaultEntity,
  ui: defaultEntity,
  audio: defaultEntity,
  model: defaultEntity,
  video: defaultEntity,
  text: defaultEntity,
  light: defaultEntity,

  component: defaultComponent,
  actionable: defaultComponent,
  animation: defaultComponent,
  trajectory: defaultComponent,
  followTarget: defaultComponent,
  constraint: defaultComponent,

  asset: defaultAsset,
  assetMaterial: defaultAsset,
  assetModel: defaultAsset,

  scene: defaultScene,

  uiEntity: defaultUIEntity,
  uiBlock: defaultUIEntity,
  uiImage: defaultUIEntity,
  uiText: defaultUIEntity,

  struct: defaultStruct,
  uiOverlay: defaultUIOverlay,

  event: defaultEvent,
};
