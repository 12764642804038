import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ArraySocket, StringSocket } from "../../sockets";
import { EntityValue, entityValue } from "../../values";

export class FindEntitiesByNameNode extends BaseNode {
  static readonly type = "entity/findEntitiesByName";
  static readonly label = "Find Entities By Name";

  inputs = [new StringSocket("name")];
  outputs = [new ArraySocket("array", entityValue.name)];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const name = context.readInput<string>("name");
    const entities = node.sceneContext
      .getEntityContexts()
      .filter((entityContext) => {
        return entityContext.name && entityContext.name.indexOf(name) > -1;
      })
      .map((entityContext) => ({
        entityId: entityContext.id,
      }));

    context.writeOutput<Array<EntityValue>>("array", entities);
  }
}
