import { EventEmitter } from "eventemitter3";
import { Material } from "three";
import { MaterialRecord } from "./types";

export interface MaterialManager {
  on(event: "add", listener: (material: MaterialRecord) => void): this;
  on(event: "add", listener: (material: MaterialRecord) => void): this;

  off(event: "add", listener: (material: MaterialRecord) => void): this;
  off(event: "add", listener: (material: MaterialRecord) => void): this;

  emit(event: "add", material: MaterialRecord): boolean;
  emit(event: "add", material: MaterialRecord): boolean;
}

export class MaterialManager extends EventEmitter {
  materials: MaterialRecord[] = [];
  materialsMap: Record<string, MaterialRecord> = {};

  getMaterialRecord(materialAssetId: string) {
    return this.materialsMap[materialAssetId] ?? null;
  }

  requestMaterialAsset(materialAssetId: string) {
    const cachedMaterial = this.materialsMap[materialAssetId];

    if (cachedMaterial) {
      cachedMaterial.referenceCounter++;

      return cachedMaterial;
    } else {
      let resolver: MaterialRecord["resolver"];
      const promise = new Promise<Material>((resolve) => (resolver = resolve));

      const materialRecord: MaterialRecord = {
        id: materialAssetId,
        referenceCounter: 1,
        promise,
        resolver: resolver!,
      };

      this.materials.push(materialRecord);
      this.materialsMap[materialAssetId] = materialRecord;
      this.emit("add", materialRecord);

      return materialRecord;
    }
  }

  finishMaterialLoad(materialAssetId: string, material: Material) {
    const cachedMaterial = this.materialsMap[materialAssetId];

    if (cachedMaterial) {
      cachedMaterial.material = material;
      cachedMaterial.resolver(material);
    }
  }

  removeReferenceToMaterialAsset(materialAssetId: string) {
    const cachedMaterial = this.materialsMap[materialAssetId];

    if (cachedMaterial) {
      cachedMaterial.referenceCounter--;
    }
  }

  reset() {
    this.materials.forEach((record) => record.material?.dispose());
    this.materials = [];
    this.materialsMap = {};
  }
}

export const materialManager = new MaterialManager();
