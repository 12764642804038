import { Icon, IconProps } from "./Icon";

export type ConstraintProps = IconProps;

export const Constraint = (props: ConstraintProps) => (
  <Icon width="14px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      d="M13 13V1M1 13V1M3.33333 7H10.6667M10.6667 9L10.6667 5M3.33333 9L3.33333 5"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
