import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import videojs, { VideoJsPlayerOptions } from "video.js";
import { initialOptions } from "./constants";
import "./index.css";

export type VideoPlayersProps = {
  options: Partial<VideoJsPlayerOptions>;
};

export const VideoPlayers = ({ options }: VideoPlayersProps) => {
  const videoNode = useRef<HTMLVideoElement>(null);

  const videoPlayerRef = useRef<videojs.Player>();

  useEffect(() => {
    const element = videoNode.current;
    if (!element) {
      return;
    }

    videoPlayerRef.current = videojs(element, {
      ...initialOptions,
      ...options,
    });

    return () => {
      videoPlayerRef.current?.dispose();
    };
  }, []);

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "240px",
        flex: "0 0 240px",
        position: "relative",
      }}
    >
      <video ref={videoNode} className="video-js" />
    </Box>
  );
};
