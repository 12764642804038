import "typeface-roboto";
import { useEffect, useState } from "react";
import { extend } from "@react-three/fiber";
import ThreeMeshUI from "view-scene/libs/three-mesh-ui";
import { ThemeProvider } from "@mui/material";
import { defaultTheme, GlobalStyles } from "~/theme";
import { useProfile } from "~/entities/profile";
import { Router } from "./Router";

import "./global.css";
import { QueryClient, QueryClientProvider } from "react-query";

extend({
  UiBlock: ThreeMeshUI.Block,
  UiText: ThreeMeshUI.Text,
  UiInlineBlock: ThreeMeshUI.InlineBlock,
});

const queryClient = new QueryClient();
export const App = () => {
  const loadProfile = useProfile((state) => state.loadProfile);

  useEffect(() => {
    loadProfile().then(() => setProfileLoaded(true));
  }, [loadProfile]);

  const [profileLoaded, setProfileLoaded] = useState(false);

  if (!profileLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <Router />
      </ThemeProvider>
    </QueryClientProvider>
  );
};
