export const tips = [
  "Give us 5 seconds, we are creating a world here",
  "The world is under construction",
  "Here your best experience begins",
  "We are rendering the universe, be patient",
  "Experience is the teacher of all things ©&nbsp<b>Caesar</b>",
  "Wait a sec! The world was created not in a day",
  "All hope abandon, ye who enter here! ©&nbsp<b>Dante Alighieri</b>",
  "Make a deep breath. Repeat",
];
