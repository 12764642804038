import { NodeContext } from "../../base";
import { IsPlatformNode } from "./IsPlatformNode";

export class IsMobileNode extends IsPlatformNode {
  static readonly type = "action/isMobile";
  static readonly label = "Is Mobile";

  constructor(context: NodeContext) {
    super(context, (device) => device === "mobile");
  }
}
