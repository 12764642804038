import { styled } from "@mui/material";
import { palette } from "~/theme";
import { Twitter as TwitterIcon, TwitterProps as TwitterIconProps } from "./Icon";
import { Navigate } from "./Navigate";

export type TwitterProps = Pick<TwitterIconProps, "className" | "sx">;

export const Twitter = (props: TwitterProps) => {
  return (
    <Navigate to="https://twitter.com/8xr_engine" openInNewWindow={true}>
      <StyledTwitterIcon {...props} />
    </Navigate>
  );
};

const StyledTwitterIcon = styled(TwitterIcon)`
  width: 48px;
  height: 48px;
  transition: 0.4s ease;
  color: ${palette.primary.white};

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
  }
`;
