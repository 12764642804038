import { memo, ReactNode, Suspense, useRef } from "react";
import { Object3D } from "three";
import { useEntity } from "~/view-scene/utils/useEntity";
import { useOcclusionCulling } from "~/view-scene/occlusionCulling";
import { Lod } from "./Lod";
import { Model } from "./Model";
import IModelSceneObject from "~/types/IModelSceneObject";

export type ModelEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const ModelEntity = memo(({ entityId, children }: ModelEntityProps) => {
  const entity = useEntity<IModelSceneObject>(entityId);
  const ref = useRef<Object3D>(null);

  useOcclusionCulling(ref, entity);

  if (!entity) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      {entity.hasLod ? (
        <Lod entity={entity} children={children} ref={ref} />
      ) : (
        <Model entity={entity} children={children} ref={ref} />
      )}
    </Suspense>
  );
});
