import { defaultEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { playerSocketHandsId } from "~/view-scene/entities/PlayerEntity/constants";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket } from "../../sockets";
import { EntityValue } from "../../values";

/**
 * @deprecated
 */
export class ScenePlayerSocketHandsNode extends BaseNode {
  static readonly type: string = "variable/scenePlayerSocketHands";
  static readonly label: string = "Player hands socket";
  static readonly deprecated = true;
  static readonly category = "Variable";

  outputs = [new EntitySocket("socket")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = playerSocketHandsId;
    context.writeOutput<EntityValue>("socket", node.storage.output);
  }
}
