import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, NumberSocket } from "../../sockets";

export class SetCollisionMaskNode extends BaseNode {
  static readonly type = "physics/setCollisionMaks";
  static readonly label = "Set collision mask";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new NumberSocket("mask")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");
    const mask = context.readInput<number>("mask");

    entityContext?.getPhysicsBody()?.setCollisionMask(mask);
  }
}
