import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, PlayerSocket, SceneSocket } from "../../sockets";
import { SceneValue } from "../../values";

export class TeleportToSceneNode extends BaseNode {
  static readonly type = "logic/teleportToScene";
  static readonly label = "Teleport to scene";

  inputs = [
    new FlowSocket("flow"),
    new PlayerSocket("player"),
    new SceneSocket("scene"),
    new BooleanSocket("forceReload"),
  ];
  outputs = [new FlowSocket("flow"), new BooleanSocket("teleported")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const player = node.readEntity<PlayerContext>(context, "player");
    const scene = context.readInput<SceneValue>("scene");
    const forceReload = context.readInput<boolean>("forceReload");

    if (player && scene.sceneId) {
      player.teleportToScene(scene.sceneId, forceReload);
      context.writeOutput("teleported", true);
    } else {
      context.writeOutput("teleported", false);
    }
  }
}
