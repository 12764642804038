import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import SocialLoginButton from "~/common/components/SocialLoginButton";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import { getFacebookAuthUrl, getGoogleAuthUrl } from "~/api/auth.api";

export const LoginWithOauth = () => {
  const handleLoginWithGoogle = () => {
    window.location.href = getGoogleAuthUrl();
  };

  const handleLoginWithFacebook = () => {
    window.location.href = getFacebookAuthUrl();
  };

  return (
    <Box
      component="div"
      sx={{
        paddingTop: "24px",
        borderTop: "1px solid " + palette.primary.gray200,
      }}
    >
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "24px",
          width: "100%",
          color: palette.primary.gray800,
        }}
        variant="body1"
      >
        Continue with a Social account
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          gridGap: "24px",
          marginTop: "8px",
        }}
      >
        <SocialLoginButton
          onClick={handleLoginWithGoogle}
          sx={(theme) => ({
            padding: "15px 0",
            width: "50%",
            height: "48px",
            borderRadius: "38px",
            "&:hover path": {
              fill: palette.primary.violet,
              transition: ".4s ease",
            },
            "svg path": {
              transition: ".4s ease",
            },
          })}
          startIcon={
            <GoogleIcon
              sx={(theme) => ({
                width: 24,
                flex: "0 0 24px",
                height: 24,
                marginRight: "8px",
              })}
            />
          }
        >
          Google
        </SocialLoginButton>

        <SocialLoginButton
          onClick={handleLoginWithFacebook}
          sx={(theme) => ({
            padding: "15px 0",
            width: "50%",
            height: "48px",
            borderRadius: "38px",
            "&:hover path": {
              fill: palette.primary.violet,
              transition: ".4s ease",
            },
            "svg path": {
              transition: ".4s ease",
            },
          })}
          startIcon={
            <FacebookIcon
              sx={(theme) => ({
                width: 24,
                flex: "0 0 24px",
                height: 24,
                marginRight: "8px",
              })}
            />
          }
        >
          Facebook
        </SocialLoginButton>
      </Stack>
    </Box>
  );
};
