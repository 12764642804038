import { useEffect, useState } from "react";
import { useProfile } from "~/entities/profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setAccessToken } from "~/api/accessToken";
import { Modal } from "~/common/components";
import { palette } from "~/theme";
import { AuthFormState } from "./types";
import { LocalAuthProvider } from "./LocalAuthProvider";
import { HOST } from "~/config";
import { useRefererTracker } from "./useRefererTracker";

export function AuthManager() {
  const [formState, setFormState] = useState<AuthFormState>("login");

  const { isAuthorised, loadProfile, redirectPage, resetRedirectPage } = useProfile((state) => ({
    isAuthorised: state.isAuthorised,
    loadProfile: state.loadProfile,
    redirectPage: state.redirectPage,
    resetRedirectPage: state.resetRedirectPage,
  }));

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const open = Boolean(searchParams.get("auth")) && (!isAuthorised() || formState === "registrationSuccessful");

  useRefererTracker();

  useEffect(() => {
    if (open) {
      const formState = searchParams.get("auth") as AuthFormState;
      setFormState(formState);
    }
  }, [open]);

  const handleClose = (reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }

    const param = searchParams.get("auth");
    if (param) {
      searchParams.delete("auth");
      setSearchParams(searchParams);
    }

    if (formState === "registrationSuccessful") {
      navigate(redirectPage ? redirectPage : "/");
      resetRedirectPage();
    }
  };

  const handleSuccessfulLogin = async (accessToken: string) => {
    setAccessToken(accessToken);
    await loadProfile();

    handleClose();

    if (redirectPage) {
      navigate(redirectPage);
      resetRedirectPage();
    }
  };

  const handleSuccessfulRegistration = async (accessToken: string) => {
    setAccessToken(accessToken);
    await loadProfile();

    setFormState("registrationSuccessful");
  };

  const formStateToModalTitle = (formState: AuthFormState) => {
    switch (formState) {
      case "login":
        return "Log In";
      case "register":
        return "Register";
      case "registrationSuccessful":
        return "You’re almost there! 🎉";
      case "passwordReset":
        return "Forgot Password";
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={formStateToModalTitle(formState)}
      contentBackground={palette.primary.black}
    >
      <LocalAuthProvider
        formState={formState}
        isOauthEnabled={HOST === "8xr"}
        onFormStateChange={(formState) => setFormState(formState)}
        onLoginSuccess={handleSuccessfulLogin}
        onRegistrationSuccess={handleSuccessfulRegistration}
      />
    </Modal>
  );
}
