import { styled } from "@mui/material";
import { loadImage } from "./loadImage";

type SuspenseImageProps = {
  src: string;
  sx?: any;
};

export const SuspenseImage = (props: SuspenseImageProps) => {
  loadImage(props.src).read();

  return <Image {...props} />;
};

const Image = styled("img")``;
