import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class LockPointerNode extends BaseNode {
  static readonly type = "action/lockPointerNode";
  static readonly label = "Lock Pointer";

  async = true;

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const platform = node.sceneContext.getPlatform();
    if (platform === "desktop") {
      node.sceneContext
        .lockPointer()
        .then(() => {
          context.commit("flow");
          context.graphEvaluator.executeAll();
        })
        .catch(() => {
          context.commit("flow");
          context.graphEvaluator.executeAll();
        });
    } else {
      context.commit("flow");
      context.graphEvaluator.executeAll();
    }
  }
}
