import { useThree } from "@react-three/fiber";
import { useRef } from "react";
import { CameraContext, useEntityContext } from "../runtime/contexts";
import { defaultCameraDto } from "./constants";

export const useCameraContext = () => {
  const camera = useThree((state) => state.camera);
  const cameraRef = useRef(camera);

  useEntityContext("camera", () => new CameraContext(defaultCameraDto, cameraRef));
};
