import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotFoundPage, ServerErrorPage, UnauthorizedPage } from "~/pages/error";
import {
  AuthManager,
  EmailConfirmationCallback,
  ExternalAuthIFrame,
  OAuthLoginCallback,
  ResetPasswordModal,
} from "~/pages/auth";
import { HOST, WHITE_LABELING_PROFILE } from "~/config";
import { PrivateRoute, Right } from "~/entities/profile";
import { ProfileAvatarSelectionPage, ProfileSettingPage } from "~/pages/profile";
import { SpacePage } from "~/pages/spaces";
import ViewScene from "~/view-scene/ViewScene";
import { ViewSceneRoute } from "~/view-scene/ViewSceneRoute";
import { ViewEmbeddedScene } from "~/view-embedded-scene/ViewEmbeddedScene";
import { handleFacebookCb, handleGoogleCb } from "~/api/auth.api";
import { HephaestusLazy } from "~/hephaestus/HephaestusLazy";
import { UsersPage } from "~/pages/users";
import { ReportPage } from "~/pages/report";
import { HomePage } from "~/pages/home";
import { GameLandingPage } from "~/pages/GameLandingPage";
import { Projects } from "~/projects";
import { DogeverseLandingPage } from "~/pages/DogeverseLandingPage";
import { IconsPage } from "~/pages/dev/IconsPage";
import { ThirdPartyRoute } from "~/third-party/ThirdPartyRoute";
import {
  TelegramWebAppGameRoute,
  TelegramAdminPage,
  TelegramAdsPage,
  TelegramAnalyticsPage,
  TelegramGameRoute,
} from "~/pages/telegram";

export const DefaultRouter = () => {
  return (
    <BrowserRouter>
      <AuthManager />
      <ResetPasswordModal />

      <Routes>
        {HOST === "8xr" ? getDefaultRoutes() : getWhitelabeledRoutes()}

        <Route path="/profile/confirm-email" element={<EmailConfirmationCallback />} />
        <Route
          path="/profile/settings"
          element={
            <PrivateRoute>
              <ProfileSettingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/avatar"
          element={
            <PrivateRoute>
              <ProfileAvatarSelectionPage />
            </PrivateRoute>
          }
        />

        <Route path="/401" element={<UnauthorizedPage />} />
        <Route path="/500" element={<ServerErrorPage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

const getDefaultRoutes = () => (
  <>
    <Route path="/auth/external" element={<ExternalAuthIFrame />} />
    <Route path="/auth/google/redirect" element={<OAuthLoginCallback oAuthCb={handleGoogleCb} />} />
    <Route path="/auth/facebook/redirect" element={<OAuthLoginCallback oAuthCb={handleFacebookCb} />} />

    <Route path="/" element={<HomePage />} />

    <Route
      path="/projects"
      element={
        <PrivateRoute right={Right.authorisedUser}>
          <Projects />
        </PrivateRoute>
      }
    />

    <Route
      path="/space/:name"
      element={
        <PrivateRoute right={Right.authorisedUser}>
          <SpacePage />
        </PrivateRoute>
      }
    />

    <Route path="/s/:urlName/view" element={<ViewSceneRoute />} />

    <Route path="/telegram-games/:gameId" element={<TelegramWebAppGameRoute />} />
    <Route path="/telegram/:urlName" element={<TelegramGameRoute />} />
    <Route path="/telegram-ads" element={<TelegramAdsPage />} />
    <Route path="/telegram" element={<TelegramAdminPage />} />

    <Route path="/analytics" element={<TelegramAnalyticsPage />} />
    <Route path="/analytics/:gameId" element={<TelegramAnalyticsPage />} />
    <Route path="/analytics/:gameId/:cohortReferer" element={<TelegramAnalyticsPage />} />

    <Route path="/view-embedded/:urlName" element={<ViewEmbeddedScene />} />

    <Route
      path="/builder/:sceneId"
      element={
        <PrivateRoute right={Right.manageScenes}>
          <HephaestusLazy />
        </PrivateRoute>
      }
    />

    <Route
      path="/dev/icons"
      element={
        <PrivateRoute right={Right.admin}>
          <IconsPage />
        </PrivateRoute>
      }
    />

    <Route
      path="/users"
      element={
        <PrivateRoute right={Right.manageUser}>
          <UsersPage />
        </PrivateRoute>
      }
    />

    <Route
      path="/reports"
      element={
        <PrivateRoute right={Right.manageUser}>
          <ReportPage />
        </PrivateRoute>
      }
    />

    <Route path="/game/:url" element={<ThirdPartyRoute />}></Route>
  </>
);

const getWhitelabeledRoutes = () => {
  const landingPage = HOST === "dogeverse" ? <DogeverseLandingPage /> : <GameLandingPage />;

  return (
    <>
      <Route path="/" element={landingPage} />
      {WHITE_LABELING_PROFILE.scenes.map((scene) => (
        <Route key={scene.path} path={scene.path} element={<ViewScene key={scene.urlName} urlName={scene.urlName} />} />
      ))}
    </>
  );
};
