import { memo, useMemo, useState } from "react";
import { Sky as SkyImpl } from "three/examples/jsm/objects/Sky";
import { Vector3 } from "three";
import ISkySceneObject from "../../../types/ISkySceneObject";
import { EnabledStatus } from "~/types/EnabledStatus";
import { useToggleMatrixAutoUpdate } from "~/view-scene/utils";

type RenderSkyProps = {
  entity: ISkySceneObject;
};

export const RenderSky = memo(({ entity }: RenderSkyProps) => {
  const {
    isStatic,
    elevation = 0.6,
    azimuth = 0.1,
    distance = 1000,
    mieCoefficient = 0.005,
    mieDirectionalG = 0.8,
    rayleigh = 0.5,
    turbidity = 10,
    enabled,
  } = entity;

  const sunPosition = useMemo(() => {
    const sunPosition = new Vector3();
    const phi = Math.PI / 2 - elevation;
    const theta = azimuth;
    sunPosition.setFromSphericalCoords(1, phi, theta);
    return sunPosition;
  }, [elevation, azimuth]);

  const scale = useMemo(() => new Vector3().setScalar(distance), [distance]);

  const [sky] = useState(() => new SkyImpl());

  useToggleMatrixAutoUpdate(sky, isStatic);

  if (enabled === EnabledStatus.disabled) {
    return null;
  }

  return (
    <primitive
      object={sky}
      material-uniforms-mieCoefficient-value={mieCoefficient}
      material-uniforms-mieDirectionalG-value={mieDirectionalG}
      material-uniforms-rayleigh-value={rayleigh}
      material-uniforms-sunPosition-value={sunPosition}
      material-uniforms-turbidity-value={turbidity}
      scale={scale}
    />
  );
});
