import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberFloorNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberFloor";
  static readonly label = "Number Floor";

  constructor(context: NodeContext) {
    super(context, (value) => Math.floor(value));
  }
}
