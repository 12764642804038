import { Suspense, lazy } from "react";

const DesktopSource = lazy(() => import("./DesktopSource"));

export const DesktopSourceLazy = () => {
  return (
    <Suspense fallback={null}>
      <DesktopSource />
    </Suspense>
  );
};
