import { ReactNode } from "react";
import { hasType } from "~/common/utils/hasType";
import {
  BlockUIEntity,
  RewardedVideoButtonUIEntity,
  TextUIEntity,
  UIEntity,
  UIEntityType,
  VideoUIEntity,
} from "~/types/ui";
import { RenderUIBlock } from "./RenderUIBlock";
import { RenderUIImage } from "./RenderUIImage";
import { RenderUIText } from "./RenderUIText";
import { RenderUIVideo } from "./RenderUIVideo";
import { RenderUIRewardedVideoButton } from "./RenderUIRewardedVideoButton";

export type RenderUIElementSwitchProps = {
  entity: UIEntity;
  aspect: [number, number, number];
  children: ReactNode;
  onClick?: (entityId: string) => void;
  showBoxHelper?: boolean;
};

export const RenderUIElementSwitch = ({
  entity,
  aspect,
  children,
  onClick,
  showBoxHelper,
}: RenderUIElementSwitchProps) => {
  if (hasType<UIEntity, BlockUIEntity, UIEntityType>(entity, UIEntityType.BLOCK)) {
    return (
      <RenderUIBlock entity={entity} aspect={aspect} onClick={onClick} showBoxHelper={showBoxHelper}>
        {children}
      </RenderUIBlock>
    );
  } else if (hasType<UIEntity, TextUIEntity, UIEntityType>(entity, UIEntityType.TEXT)) {
    return (
      <RenderUIText entity={entity} onClick={onClick}>
        {children}
      </RenderUIText>
    );
  } else if (hasType<UIEntity, VideoUIEntity, UIEntityType>(entity, UIEntityType.IMAGE)) {
    return (
      <RenderUIImage entity={entity} aspect={aspect} onClick={onClick} showBoxHelper={showBoxHelper}>
        {children}
      </RenderUIImage>
    );
  } else if (hasType<UIEntity, VideoUIEntity, UIEntityType>(entity, UIEntityType.VIDEO)) {
    return (
      <RenderUIVideo entity={entity} aspect={aspect} onClick={onClick} showBoxHelper={showBoxHelper}>
        {children}
      </RenderUIVideo>
    );
  } else if (hasType<UIEntity, RewardedVideoButtonUIEntity, UIEntityType>(entity, UIEntityType.REWARDED_VIDEO_BUTTON)) {
    return (
      <RenderUIRewardedVideoButton entity={entity} aspect={aspect} showBoxHelper={showBoxHelper}>
        {children}
      </RenderUIRewardedVideoButton>
    );
  }

  return null;
};
