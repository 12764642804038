import { useFrame } from "@react-three/fiber";
import { RefObject, useRef } from "react";
import { Object3D } from "three";

export type RaycastScannerProps = {
  distance: number;
  objectRef: RefObject<Object3D | null>;
  onHover: () => void;
  onBlur: () => void;
};

export const RaycastScanner = ({ objectRef, distance, onHover, onBlur }: RaycastScannerProps) => {
  const hoveredRef = useRef(false);

  useFrame(({ raycaster }) => {
    const object = objectRef.current;

    if (!object) {
      return;
    }

    const intersections = raycaster.intersectObject(object);

    const isHover = intersections.length > 0 && intersections[0].distance <= distance;

    if (isHover !== hoveredRef.current) {
      hoveredRef.current = isHover;
      isHover ? onHover() : onBlur();
    }
  });

  return null;
};
