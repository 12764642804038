import { Suspense, lazy } from "react";
import type { ThirdPartyProps } from "./ThirdParty";

const ThirdParty = lazy(() => import("./ThirdParty"));

export const ThirdPartyLazy = (props: ThirdPartyProps) => {
  return (
    <Suspense fallback={null}>
      <ThirdParty {...props} />
    </Suspense>
  );
};
