// TODO: Это хак, пока не починят сборку behave-graph
import { Logger } from "behave-graph/dist/lib/Diagnostics/Logger";
import { Graph as BehaveGraph } from "behave-graph/dist/lib/Graphs/Graph";
import type { BaseBehaveNode } from "~/view-scene/script";

export class Graph extends BehaveGraph {}
export interface Graph {
  readonly nodes: { [id: string]: BaseBehaveNode };
}

export { writeNodeSpecsToJSON } from "behave-graph/dist/lib/Graphs/IO/writeNodeSpecsToJSON";
export { readGraphFromJSON } from "behave-graph/dist/lib/Graphs/IO/readGraphFromJSON";
export { GraphEvaluator } from "behave-graph/dist/lib/Graphs/Evaluation/GraphEvaluator";
export { Registry } from "behave-graph/dist/lib/Registry";
export { Node } from "behave-graph/dist/lib/Nodes/Node";
export { Link } from "behave-graph/dist/lib/Nodes/Link";
export type { NodeCategory } from "behave-graph/dist/lib/Nodes/NodeCategory";
export { NodeEvalContext } from "behave-graph/dist/lib/Nodes/NodeEvalContext";
export { Socket } from "behave-graph/dist/lib/Sockets/Socket";
export { EventEmitter } from "behave-graph/dist/lib/Events/EventEmitter";
export { ValueType } from "behave-graph/dist/lib/Values/ValueType";
export type { GraphJSON, NodeJSON } from "behave-graph/dist/lib/Graphs/IO/GraphJSON";
export type {
  NodeSpecJSON,
  OutputSocketSpecJSON,
  InputSocketSpecJSON,
} from "behave-graph/dist/lib/Graphs/IO/NodeSpecJSON";
export type { InputJSON } from "behave-graph/dist/lib/Graphs/IO/GraphJSON";
export type { LinkJSON } from "behave-graph/dist/lib/Graphs/IO/GraphJSON";

Logger.onVerbose.clear();
Logger.onWarn.clear();
Logger.onInfo.clear();
