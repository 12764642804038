import { forwardRef, memo, useEffect, useMemo, useState } from "react";
import { Audio, AudioLoader, Color, Layers, Mesh, MeshBasicMaterial, sRGBEncoding, VideoTexture } from "three";
import { defaultLayers } from "~/view-scene/layers";
import { exitCutscene } from "~/view-scene/runtime";
import { $audioManager } from "~/view-scene/audio";
import { useStoreMap } from "effector-react";
import { useLoader, useThree } from "@react-three/fiber";
import { Plane } from "@react-three/drei";
import { PLAYER_HEIGHT } from "~/config";

export type RenderVideo360Props = {
  url: string;
  layers?: Layers;
};

const blackMaterial = new MeshBasicMaterial({ color: new Color("black") });

export const RenderCutscene = memo(
  forwardRef<Mesh | null, RenderVideo360Props>(({ url, layers = defaultLayers }, ref) => {
    const audioListener = useStoreMap($audioManager, (state) => state.audioListener!);

    const [videoSize, setVideoSize] = useState([1, 1]);
    const [videoIsReady, setVideoIsReady] = useState(false);

    const viewport = useThree((state) => state.viewport.getCurrentViewport());
    const width = viewport.width;
    const height = (videoSize[1] * width) / videoSize[0];

    const video = useMemo(() => {
      const video = document.createElement("video");

      video.addEventListener(
        "loadedmetadata",
        () => {
          setVideoSize([video.videoWidth, video.videoHeight]);
          setVideoIsReady(true);
        },
        true
      );

      video.src = url;
      video.crossOrigin = "anonymous";
      video.playsInline = true;
      video.muted = true;
      video.defaultMuted = true;
      video.loop = false;
      return video;
    }, [url]);

    const audioBuffer = useLoader(AudioLoader, url);
    const audio = useMemo(() => {
      const audio = new Audio(audioListener);
      audio.setLoop(false);
      audio.setVolume(1);
      audio.setBuffer(audioBuffer);
      return audio;
    }, [audioListener]);

    const videoTexture = useMemo(() => {
      const texture = new VideoTexture(video);
      texture.encoding = sRGBEncoding;
      texture.flipY = true;
      return texture;
    }, [video]);

    useEffect(() => {
      if (!videoIsReady) {
        return;
      }

      const endedHandler = async () => {
        await exitCutscene();
      };

      video.addEventListener("ended", endedHandler);
      video.play().then(() => {
        audio.play();
      });

      return () => {
        video.pause();
        audio.pause();
        video.removeEventListener("ended", endedHandler);
      };
    }, [video, audio, videoIsReady]);

    return (
      <>
        <Plane args={[width, height, 16, 11]} layers={layers} ref={ref} position={[0, PLAYER_HEIGHT / 2, -0.01]}>
          <meshBasicMaterial map={videoTexture} />
        </Plane>
        <Plane args={[100, 100]} layers={layers} position={[0, PLAYER_HEIGHT / 2, -0.03]}>
          <primitive object={blackMaterial} />
        </Plane>
      </>
    );
  })
);
