import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class ActionNode extends BaseNode {
  static readonly type = "event/action";
  static readonly label = "Action";
  static category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onActionEvent = () => {
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.actionEvent.addListener(onActionEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.actionEvent.removeListener(onActionEvent);
    });
  }
}
