import { Vector3 } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3, setVector3ValueFromXYZ } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

const tmpVector = new Vector3();

export class VectorNormaliseNode extends BaseNode {
  static readonly type = "logic/vectorNormalise";
  static readonly label = "Vector Normalise";

  inputs = [new Vector3Socket("vector")];
  outputs = [new Vector3Socket("result")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const vector = context.readInput<Vector3Value>("vector");
    tmpVector.set(vector.x, vector.y, vector.z);
    tmpVector.normalize();

    context.writeOutput("result", setVector3ValueFromXYZ(node.storage.output, tmpVector));
  }
}
