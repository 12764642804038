import { useSceneData } from "~/common/stores/useSceneData";
import { useSceneType } from "~/view-scene/hooks";
import { InitSystems } from "~/view-scene/welcome-screen/InitSystems";
import { ShardQuestWelcomeScreen } from "~/view-scene/welcome-screen/ShardQuestWelcomeScreen";
import { DefaultWelcomeScreen } from "./DefaultWelcomeScreen";

export function WelcomeScreen() {
  const startOverlayEnabled =
    useSceneData((state) => state.sceneState?.platformInterfaceSystem.startOverlayEnabled) ?? true;
  const type = useSceneType();

  if (!startOverlayEnabled) {
    return <InitSystems />;
  }

  if (type === "shardQuest") {
    return <ShardQuestWelcomeScreen />;
  }

  return <DefaultWelcomeScreen />;
}
