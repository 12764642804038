import { ForwardedRef, forwardRef } from "react";
import { useMaterialAsset } from "~/view-scene/material";
import { RenderInstancedModel, RenderInstancedModelControls, RenderInstancedModelProps } from "./RenderInstancedModel";

type RenderInstancedModelWithOverrideMaterialProps = RenderInstancedModelProps & {
  materialAssetId: string;
};

export const RenderInstancedModelWithOverrideMaterial = forwardRef(
  (
    { materialAssetId, ...rest }: RenderInstancedModelWithOverrideMaterialProps,
    ref: ForwardedRef<RenderInstancedModelControls>
  ) => {
    const materialAsset = useMaterialAsset(materialAssetId);

    if (!materialAsset) {
      return null;
    }

    return <RenderInstancedModel material={materialAsset} {...rest} ref={ref} />;
  }
);
