type Hierarchical = {
  id: string;
  order: number;
  parentId?: string | null;
  children?: Hierarchical[];
  removed?: boolean;
};

export const rootId = "__root__";

export const toHierarchyIds = <T extends Hierarchical>(
  objects: T[],
  idFactory: (id: string) => string = (id) => id
) => {
  const sorted = objects.filter((obj) => !obj.removed).sort((a, b) => a.order - b.order);

  const map: Record<string, string[]> = {};

  for (let i = 0; i < sorted.length; i++) {
    const object = sorted[i];

    const parentId = object.parentId ? idFactory(object.parentId) : rootId;

    if (!map[parentId]) {
      map[parentId] = [];
    }

    map[parentId].push(idFactory(object.id));
  }

  return map;
};
