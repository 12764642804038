import { ErrorPageLayout, ErrorPageTitle, TrySuggestedScenes } from "./components";
import { HOST } from "~/config";

export const UnauthorizedPage = () => {
  return (
    <ErrorPageLayout>
      <ErrorPageTitle icon="/static/img/content/wonder-smile.webp" subtitle="You don’t have access" />
      {HOST === "8xr" && <TrySuggestedScenes />}
    </ErrorPageLayout>
  );
};
