import { Box, Modal, Stack, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { changePassword } from "~/api/profile.api";
import { palette } from "~/theme";
import { Button } from "~/hephaestus/components/Button";
import { TextField } from "~/hephaestus/components/TextField";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Close } from "~/common/components/Icon";

export type ChangePasswordPropsType = {
  open: boolean;
  onClose: VoidFunction;
};

export const ChangePasswordDialog = ({ open, onClose }: ChangePasswordPropsType) => {
  const [changePasswordForm, setChangePasswordForm] = useState({
    password: "",
    repeatPassword: "",
    isPasswordValid: true,
  });

  const handleChangePasswordRequest = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!changePasswordForm.isPasswordValid) {
      return;
    }

    const payload = { password: changePasswordForm.password };
    const { status } = await changePassword(payload);

    if (status !== 200) {
      return;
    }

    onClose();
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const isPasswordValid = password === changePasswordForm.repeatPassword;
    setChangePasswordForm({ ...changePasswordForm, isPasswordValid, password });
  };

  const handleRepeatPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const repeatPassword = event.target.value;
    const isPasswordValid = repeatPassword === changePasswordForm.password;
    setChangePasswordForm({ ...changePasswordForm, isPasswordValid, repeatPassword });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        background: palette.primary.black100,
        backdropFilter: "blur(20px)",
        zIndex: 9,
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={(theme: any) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 541,
          bgcolor: palette.primary.black600,
          border: "1px solid" + palette.primary.black100,
          borderRadius: "20px",
          p: "20px 24px",
          [theme.breakpoints.down("md")]: {
            width: "calc(100% - 90px)",
          },
        })}
        component="div"
      >
        <form onSubmit={handleChangePasswordRequest}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.gray600,
              }}
              variant="body1"
            >
              Change Password
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          <Typography
            sx={{
              fontWeight: "500",
              margin: "24px 0",
              color: palette.primary.gray800,
            }}
            variant="body2"
          >
            Choose a strong password and don't reuse it for other accounts
          </Typography>
          <Stack
            direction="column"
            sx={{
              gridGap: "23px",
            }}
          >
            <Box component="div">
              <TextField
                placeholder="Enter new password"
                fullWidth
                size="large"
                value={changePasswordForm.password}
                type="password"
                onChange={handlePasswordChange}
                error={!changePasswordForm.isPasswordValid}
                helperText={!changePasswordForm.isPasswordValid ? "Passwords do not match." : ""}
                autoComplete="new-password"
              />
            </Box>
          </Stack>
          <Stack
            direction="column"
            sx={{
              gridGap: "23px",
              marginTop: "24px",
            }}
          >
            <Typography sx={{ color: palette.primary.gray800 }} variant="body2">
              Password strength: <br />
              Use at least 6 characters. Don’t use a password from another site, or something too obvious like your
              pet’s name.
            </Typography>
            <Box component="div">
              <TextField
                placeholder="Repeat your password"
                fullWidth
                size="large"
                value={changePasswordForm.repeatPassword}
                type="password"
                onChange={handleRepeatPasswordChange}
                autoComplete="new-password"
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{
              marginTop: "24px",
            }}
            alignItems="center"
          >
            <Button variant="text" size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={!changePasswordForm.isPasswordValid} variant="accent" size="large">
              Register
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
