import { memo, MutableRefObject, ReactNode, useRef } from "react";
import { Color, Texture } from "three";
import { Block as TMUIBlock } from "../../libs/three-mesh-ui";
import { ThreeEvent } from "@react-three/fiber";
import { RenderBoxHelper } from "~/hephaestus/components/RenderBoxHelper";
import { useUIUpdate } from "./useUIUpdate";

export type BlockProps = {
  name?: string;
  width?: number;
  height?: number;
  padding?: number;
  margin?: number;
  fontFamily?: string;
  fontTexture?: string;
  fontColor?: Color;
  fontSize?: number;
  castShadow?: boolean;
  receiveShadow?: boolean;
  backgroundColor?: Color;
  backgroundTexture?: Texture;
  backgroundOpacity?: number;
  borderWidth?: number;
  borderOpacity?: number;
  backgroundSize?: "cover" | "stretch" | "contain";
  borderColor?: Color;
  borderRadius?: number | [topLeft: number, topRight: number, bottomRight: number, bottomLeft: number];
  justifyContent?: "start" | "end" | "center" | "space-between" | "space-around" | "space-evenly";
  alignItems?: "start" | "end" | "center" | "stretch";
  contentDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  textAlign?: "left" | "center" | "right" | "justify" | "justify-left" | "justify-center" | "justify-right";
  hiddenOverflow?: boolean;
  onClick?: () => void;
  showBoxHelper?: boolean;
  children?: ReactNode;
};

export const Block = memo(({ children, onClick, showBoxHelper = false, ...restProps }: BlockProps) => {
  const objectRef = useRef<TMUIBlock>() as MutableRefObject<TMUIBlock>;
  useUIUpdate(objectRef, restProps);

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <>
      <block onClick={handleClick} ref={objectRef}>
        {children}
      </block>
      {showBoxHelper && <RenderBoxHelper refObject={objectRef as any} color="#e70000" />}
    </>
  );
});
