import { Icon, IconProps } from "./Icon";

export type DragProps = IconProps;

export const Drag = (props: DragProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" {...props}>
    <path
      d="M1.5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM6.5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM0 7a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM0 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      fill="currentcolor"
    />
  </Icon>
);
