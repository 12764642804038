import { Icon, IconProps } from "./Icon";

export type MicrophoneProps = IconProps;

export const Microphone = (props: MicrophoneProps) => (
  <Icon width="18px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 10v1a8 8 0 1 1-16 0v-1m8 5a4 4 0 0 1-4-4V5a4 4 0 1 1 8 0v6a4 4 0 0 1-4 4Z"
    />
  </Icon>
);
