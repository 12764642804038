import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Mesh } from "three";

export type AttachSpriteProps = {
  sprite: Mesh;
};

export const AttachSprite = ({ sprite }: AttachSpriteProps) => {
  const camera = useThree((state) => state.camera);

  useEffect(() => {
    sprite.position.set(0, -0.08, -0.12);
    camera.add(sprite);

    return () => {
      camera.remove(sprite);
    };
  }, [sprite, camera]);

  return null;
};
