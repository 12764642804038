import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";
import { getEventId, getScriptId } from "../../utils";
import { CustomEventBaseNode } from "./CustomEventBaseNode";
import { EntityValue } from "../../values";

type Node = BaseBehaveNode<{ eventId: string; scriptId: string }>;

export abstract class ScriptCustomEventFindAndCallNode extends CustomEventBaseNode {
  static type = "event/findAndCallScriptEvent";
  static label = "Find And Call Script Event";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow")];

  initNode(node: Node): void {
    node.storage.scriptId = getScriptId(node.spec) ?? "";
    node.storage.eventId = getEventId(node.spec) ?? "";
  }

  eval(context: NodeEvalContext, node: Node) {
    const entity = context.readInput<EntityValue>("entity");
    const values: Record<string, any> = {};

    if (!entity?.entityId) {
      return;
    }

    // Skip the first and the second input sockets, which are the flow and entity
    for (let i = 2; i < node.inputSockets.length; i++) {
      const socket = node.inputSockets[i];
      const value = context.readInput(socket.id);

      values[socket.id] = value;
    }

    const script = node.sceneContext.getEntityScript(entity.entityId, node.storage.scriptId);
    script?.events.call(node.storage.eventId, values);
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getEventInputsSockets(nodeSpec)];
  }
}
