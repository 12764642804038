import { createEffect, createEvent, forward } from "effector";
import { Update } from "~/common/typeUtils/update";
import IAsset from "~/types/IAsset";
import IComponent from "~/types/IComponent";
import ISceneObject from "~/types/ISceneObject";
import { sendMessageFx } from "../sendMessage";
import ISkyboxAsset from "~/types/ISkyboxAsset";
import { IStorageAsset } from "~/types/IStorageAsset";
import { Storage } from "~/types/Storage";
import { IInventoryItemAsset } from "~/types/IInventoryItemAsset";

const sendMessageFactory = <TEventPayload>(eventName: string) => {
  const event = createEvent<TEventPayload>();

  forward({
    from: [
      event.map((payload) => ({
        event: eventName,
        payload,
      })),
    ],
    to: sendMessageFx,
  });

  return event;
};

export const sendJoinCollaboration = sendMessageFactory<string>("joinCollaboration");

export const sendUpdateSceneConfig = sendMessageFactory<any>("sceneConfigUpdate");

export const sendAddEntity = sendMessageFactory<ISceneObject>("sceneObjectAdd");
export const sendAddEntities = createEvent<ISceneObject[]>();

forward({
  from: sendAddEntities,
  to: createEffect((newEntities: ISceneObject[]) => {
    newEntities.forEach((entity) => sendAddEntity(entity));
  }),
});

export const sendUpdateEntity = sendMessageFactory<Partial<ISceneObject> & Pick<ISceneObject, "id" | "type">>(
  "sceneObjectUpdate"
);
export const sendDeleteEntity = sendMessageFactory<string>("sceneObjectDelete");
export const sendDeleteEntities = createEvent<string[]>();
forward({
  from: sendDeleteEntities,
  to: createEffect((ids: string[]) => {
    ids.forEach((id) => sendDeleteEntity(id));
  }),
});

export const sendUpdateAsset = sendMessageFactory<Update<IAsset>>("assetUpdate");
export const sendUpdateUIAsset = sendMessageFactory<Update<IAsset>>("uiUpdate");
export const sendUpdateSkyboxAsset = sendMessageFactory<Update<ISkyboxAsset>>("cubemapUpdate");
export const sendUpdateStorageAsset =
  sendMessageFactory<Update<Pick<IStorageAsset, "id" | "name"> & { storage: Update<Storage> }>>("storageUpdate");

export const sendUpdateInventoryItemAsset =
  sendMessageFactory<Update<Omit<IInventoryItemAsset, "storage">>>("inventoryItemUpdate");

export const sendAddComponent = sendMessageFactory<{ objectId: string; component: IComponent }>("componentAdd");
export const sendUpdateComponent = sendMessageFactory<{ objectId: string; component: IComponent }>("componentUpdate");
export const sendDeleteComponent = sendMessageFactory<{ objectId: string; component: { id: string } }>(
  "componentDelete"
);
