import create from "zustand";

type ForceRerender = {
  trigger: number;
  rerender: () => void;
};

// TODO: Crutch to rerender scene, after you and or remove something from it
export const useForceRerender = create<ForceRerender>((set, get) => ({
  trigger: 0,
  rerender: () => {
    set({
      trigger: get().trigger + 1,
    });
  },
}));
