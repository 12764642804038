import { EventPayload, createEvent, createStore, forward } from "effector";
import { noop } from "lodash-es";
import { updatePoint } from "./updatePoint";
import { updateTransformControlsSettings } from "~/hephaestus/SceneEditor/controls";
import { generateBufferPointId } from "../utils";

export const selectBufferPoint = createEvent<{
  entityId: string;
  pointIndex: number;
} | null>();

type SelectPayload = EventPayload<typeof selectBufferPoint>;

export const $selectedBufferPoint = createStore<SelectPayload>(null).on(selectBufferPoint, (_, payload) => payload);

forward({
  from: selectBufferPoint,
  to: updateTransformControlsSettings.prepend((payload: SelectPayload) => ({
    targetId: payload ? generateBufferPointId(payload.entityId, payload.pointIndex) : undefined,
    onChange: noop,
    onCommit: (mode, object) => {
      if (mode === "disabled") {
        return;
      }

      const { x, y, z } = object.position;

      updatePoint({ x, y, z });
    },
  })),
});
