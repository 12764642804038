import type { Components, ThemeOptions } from "@mui/material";
import { palette } from "../../palette";

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {}
}

export const MuiIconButton: Components<ThemeOptions>["MuiIconButton"] = {
  styleOverrides: {
    root: {
      textTransform: "initial",
      borderRadius: "8px",
      fontWeight: 400,
      whiteSpace: "nowrap",
    },
  },
  variants: [
    {
      props: { size: "small" },
      style: {
        height: "14px",
        width: "14px",
      },
    },
    {
      props: { size: "medium" },
      style: {
        height: "28px",
        width: "28px",
      },
    },
    {
      props: { size: "large" },
      style: {
        borderRadius: "32px",
        height: "40px",
        width: "40px",
      },
    },
    {
      // @ts-ignore
      props: { variant: "text" },
      style: {
        color: palette.primary.gray600,

        "&:hover": {
          color: palette.primary.white,
          backgroundColor: palette.primary.gray100,
        },
      },
    },
    {
      // @ts-ignore
      props: { variant: "outlined" },
      style: {
        backgroundColor: palette.primary.violet200,
        border: "1px solid " + palette.primary.violet600,

        "&:hover": {
          color: palette.primary.white,
          borderColor: palette.primary.violet,
          backgroundColor: palette.primary.violet,
        },
      },
    },
    {
      // @ts-ignore
      props: { variant: "accent" },
      style: {
        backgroundColor: palette.primary.violet,

        "&:hover": {
          backgroundColor: palette.primary.violet600,
        },
      },
    },
    {
      // @ts-ignore
      props: { variant: "contained" },
      style: {
        color: palette.primary.gray600,
        background: palette.primary.gray100,
        border: "1px solid " + palette.primary.gray100,

        "&:hover": {
          color: palette.primary.white,
          background: palette.primary.violet200,
          borderColor: palette.primary.violet,
        },
      },
    },
    {
      // @ts-ignore
      props: { variant: "clear" },
      style: {
        color: palette.primary.gray600,
        background: "transparent",
        border: "none",
        padding: 0,
        width: "auto",
        height: "auto",

        "&:hover": {
          color: palette.primary.white,
          background: "transparent",
        },
      },
    },
  ],
};
