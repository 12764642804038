import { defaultAsset } from "~/entities/variable";
import { BaseSocket } from "../base";
import { assetMaterialValue, AssetMaterialValue } from "../values";

export class AssetMaterialSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AssetMaterialValue = defaultAsset()) {
    super(assetMaterialValue.name, id, label, value);
  }

  clone() {
    return new AssetMaterialSocket(this.id, this.label, this.value);
  }
}
