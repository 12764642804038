import { Stack, styled } from "@mui/material";
import { useUnit } from "effector-react";
import { HeaderWithNavigation, Progress } from "~/common/components";
import { HOST, WHITE_LABELING_PROFILE } from "~/config";
import { $progress } from "../models";
import { Tips } from "./Tips";

export type DefaultLoadingScreenProps = {
  showHeader?: boolean;
};

export const DefaultLoadingScreen = ({ showHeader = true }: DefaultLoadingScreenProps) => {
  const progress = useUnit($progress);

  return (
    <Container>
      {showHeader && <HeaderWithNavigation enableNavigation={HOST === "8xr"} label="Explore" target="/" />}
      <ContentContainer>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Logo src={WHITE_LABELING_PROFILE.loadingScreenLogo} />
          <StyledProgress progress={progress} />
        </Stack>
      </ContentContainer>
      <TipsContainer>
        <StyledTips />
      </TipsContainer>
    </Container>
  );
};

const Container = styled(Stack)`
  background-color: #010101;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;

const ContentContainer = styled(Stack)`
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -75%);
`;

const Logo = styled("img")`
  width: auto;
  height: 300px;
`;

const StyledProgress = styled(Progress)`
  width: 180px;
  margin-top: -68px;
`;

const TipsContainer = styled(Stack)`
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  max-width: 500px;
`;

const StyledTips = styled(Tips)`
  margin: 0 30px 60px 30px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;
