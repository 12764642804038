import { Icon, IconProps } from "./Icon";

export type RewardedVideoSystemProps = IconProps;

export const RewardedVideoSystem = (props: RewardedVideoSystemProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M14.6666 7.99998H13.3333M12.7139 12.714L11.7711 11.7712M2.66665 7.99998H1.33331M4.2286 4.22876L3.28579 3.28595M7.99998 2.66665V1.33331M11.7711 4.22876L12.7139 3.28595M7.99998 14.6666V13.3333M3.28579 12.714L4.2286 11.7712M7.99998 4.66665L9.02998 6.75331L11.3333 7.08998L9.66665 8.71331L10.06 11.0066L7.99998 9.92331L5.93998 11.0066L6.33331 8.71331L4.66665 7.08998L6.96998 6.75331L7.99998 4.66665Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
