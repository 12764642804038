import { EventEmitter } from "eventemitter3";
import { TextureTiling } from "~/types/texture";
import { SupportedTextures, TextureRecord, TextureRequest, VideoTextureRequest } from "./types";
import { buildTextureRecordId } from "./utils";

export interface TextureManager {
  on(event: "add", listener: (texture: TextureRecord) => void): this;
  on(event: "update", listener: () => void): this;

  off(event: "add", listener: (texture: TextureRecord) => void): this;
  off(event: "update", listener: () => void): this;

  emit(event: "add", texture: TextureRecord): boolean;
  emit(event: "update"): boolean;
}

const defaultTextureTiling: TextureTiling = {
  repeatU: 1,
  repeatV: 1,
  offsetU: 0,
  offsetV: 0,
  mirrorU: false,
  mirrorV: false,
};

export class TextureManager extends EventEmitter {
  textures: TextureRecord[] = [];
  texturesMap: Record<string, TextureRecord> = {};
  noTextureRecord: TextureRecord = {
    id: -1,
    name: "no-texture",
    url: "no-texture",
    type: "image",
    flipY: false,
    referenceCounter: 1,
    gpuMemoryInBytes: 0,
    tiling: defaultTextureTiling,
    encoding: "srgb",
    promise: Promise.resolve(null!),
    resolver: () => {},
  };

  private updateInProgress = false;

  getTextureRecord(textureRequest: TextureRequest | VideoTextureRequest | null) {
    if (!textureRequest) {
      return this.noTextureRecord;
    }

    const id = buildTextureRecordId(textureRequest);

    return this.texturesMap[id] ?? null;
  }

  requestTexture(textureRequest: TextureRequest | VideoTextureRequest | null) {
    if (textureRequest) {
      return this.requestExistedTexture(textureRequest);
    }

    return this.noTextureRecord;
  }

  finishTextureLoad(textureId: number, texture: SupportedTextures, gpuMemoryInBytes: number = 0) {
    const textureRecord = this.texturesMap[textureId];

    if (textureRecord) {
      textureRecord.texture = texture;
      textureRecord.gpuMemoryInBytes = gpuMemoryInBytes;
      textureRecord.resolver(texture);
    }
  }

  removeReferenceToTexture(textureId: number) {
    const texture = this.texturesMap[textureId];

    if (texture) {
      texture.referenceCounter--;
    }
  }

  reset() {
    this.textures.forEach((record) => record.texture?.dispose());
    this.textures = [];
    this.texturesMap = {};
  }

  private requestExistedTexture(textureRequest: TextureRequest | VideoTextureRequest) {
    const textureId = buildTextureRecordId(textureRequest);
    const cachedTexture = this.texturesMap[textureId];

    if (cachedTexture) {
      cachedTexture.referenceCounter++;

      return cachedTexture;
    } else {
      let resolver: TextureRecord["resolver"];
      const promise = new Promise<SupportedTextures>((resolve) => (resolver = resolve));

      const textureRecord: TextureRecord = {
        id: textureId,
        referenceCounter: 1,
        gpuMemoryInBytes: 0,
        tiling: defaultTextureTiling,
        encoding: "srgb",
        promise,
        resolver: resolver!,
        ...textureRequest,
      };

      this.textures.push(textureRecord);
      this.texturesMap[textureId] = textureRecord;
      this.emit("add", textureRecord);
      this.update();

      return textureRecord;
    }
  }

  private update() {
    if (this.updateInProgress) {
      return;
    }

    this.updateInProgress = true;
    setTimeout(() => {
      this.emit("update");
      this.updateInProgress = false;
    }, 0);
  }
}

export const textureManager = new TextureManager();
