import { Mesh, Object3D } from "three";
import { useEffect } from "react";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from "three-mesh-bvh";

export default function useBVH(object: Object3D | null) {
  useEffect(() => {
    if (!object) {
      return;
    }

    object.traverse((el) => {
      if (el instanceof Mesh && !isTroikaText(el)) {
        el.raycast = acceleratedRaycast;

        const geometry = el.geometry;
        geometry.computeBoundsTree = computeBoundsTree;
        geometry.disposeBoundsTree = disposeBoundsTree;

        geometry.computeBoundsTree();

        return () => {
          if (geometry.boundsTree) {
            geometry.disposeBoundsTree();
          }
        };
      }
    });
  }, [object]);
}

const isTroikaText = (el: any) => {
  return el._private_font !== undefined;
};
