import { createStore } from "effector";
import { AudioListener } from "three";
import { SourceType } from "../types";
import { resetAudioSystem } from "./reset";

export type AudioManager = {
  isReady: boolean;
  audioListener?: AudioListener;
  audioContext?: AudioContext;
  audioNodes: Map<string, { sourceNode: MediaStreamAudioSourceNode; gainNode: GainNode }>;
  mediaStreamDestinationNode?: MediaStreamAudioDestinationNode;
  audioDestination?: ChannelMergerNode;
  outboundStream?: MediaStream;
  outboundGainNode?: GainNode;
  outboundAnalyser?: AnalyserNode;
  analyserLevels?: Uint8Array;
  audioContextNeedsToBeResumed?: boolean;
  muted: boolean;
  mixer?: Record<SourceType, GainNode>;
  mixerAnalyser?: AnalyserNode;
};

export const $audioManager = createStore<AudioManager>({
  isReady: false,
  muted: false,
  audioNodes: new Map<string, { sourceNode: MediaStreamAudioSourceNode; gainNode: GainNode }>(),
  audioContextNeedsToBeResumed: false,
}).reset(resetAudioSystem);
