import { Box, Button, styled } from "@mui/material";
import { palette } from "~/theme";
import { LabelTypography, LoadingOverlay } from "~/view-scene/games/shardQuest";
import { startSession } from "./models";

export const ShardQuestWelcomeScreen = () => {
  const handlePlay = () => {
    startSession();
  };

  return (
    <Container>
      <LoadingOverlay
        bottomSlot={
          <StyledButton onClick={handlePlay}>
            <LabelTypography>Play</LabelTypography>
          </StyledButton>
        }
      />
    </Container>
  );
};

const Container = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${palette.primary.black600};
  backdrop-filter: blur(4px);
`;

const StyledButton = styled(Button)`
  width: 243px;
  height: 36px;
  border-radius: 32px;
  background: ${palette.shardQuest.blue};
`;
