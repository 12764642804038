import ILight from "~/types/ILight";

export type RenderAmbientLightProps = {
  light: ILight;
};

export default function RenderAmbientLight({ light }: RenderAmbientLightProps) {
  const { color, intensity } = light;

  return <ambientLight color={color} intensity={intensity} />;
}
