import { Box, Stack } from "@mui/material";
import Button from "common/components/Button";
import { palette } from "~/theme";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Avatar from "~/common/components/Avatar";
import { useState } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { RemoteUsersMobileModal } from "./RemoteUsersMobileModal";
import shallow from "zustand/shallow";

export function RemoteUsers() {
  const remoteUsersCount = useSceneData((state) => state.getRemoteUsers().length);
  const first10RemoteUsers = useSceneData((state) => {
    const sortedArray = [...state.getRemoteUsers()].sort();
    sortedArray.length = 10;
    return sortedArray;
  }, shallow);

  const [showRemoteUsersMobileModal, setShowRemoteUsersMobileModal] = useState(false);
  const openRemoteUsersMobileModal = () => setShowRemoteUsersMobileModal(true);
  const closeRemoteUsersMobileModal = () => setShowRemoteUsersMobileModal(false);

  const hasOnlineUser = remoteUsersCount > 0;

  const numberOfRemoteUsersText = hasOnlineUser
    ? remoteUsersCount === 1
      ? `1 Player`
      : `${remoteUsersCount} Players`
    : "No Players";

  return (
    <>
      <RemoteUsersMobileModal
        open={showRemoteUsersMobileModal}
        onClose={closeRemoteUsersMobileModal}
        remoteUsersCount={remoteUsersCount}
        first10RemoteUsers={first10RemoteUsers}
      />

      <Stack
        direction="column"
        alignItems="flex-end"
        spacing={1.5}
        sx={(theme) => ({
          position: "relative",
          maxWidth: "fit-content",
          [theme.breakpoints.down("sm")]: {
            position: "absolute",
            top: -20,
            right: 0,
          },
        })}
      >
        <Box
          component="div"
          sx={(theme) => ({
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
          })}
        >
          <Button
            onClick={openRemoteUsersMobileModal}
            disabled={!hasOnlineUser}
            sx={{
              padding: "2px 10px 2px 6px",
              backgroundColor: palette.primary.black200,
              border: 0,
            }}
          >
            <VisibilityOutlinedIcon />
            {remoteUsersCount ? `${remoteUsersCount}` : "0"}
          </Button>
        </Box>
        <Box
          component="div"
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gridGap: 10,
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              fontSize: 16,
              fontWeight: 500,
              padding: "2px 12px 2px 8px",
              backgroundColor: palette.primary.black200,
              borderRadius: 32,
              backdropFilter: "blur(30px)",
              border: 0,
            }}
          >
            <VisibilityOutlinedIcon sx={{ marginRight: "10px" }} />
            {numberOfRemoteUsersText}
          </Box>
          <Stack
            direction="column"
            alignItems="flex-end"
            spacing={0.5}
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 200px)",
              "&::-webkit-scrollbar": {
                width: "0",
              },
              "&::-webkit-scrollbar-track": {
                background: palette.primary.gray200,
              },
              "&::-webkit-scrollbar-thumb": {
                background: palette.primary.gray600,
              },
            }}
          >
            {first10RemoteUsers.map((remoteUser, index) => {
              return (
                <Avatar
                  key={index}
                  text={remoteUser.name ?? "Anonymous"}
                  avatar={remoteUser.avatarPreview ?? "/static/img/content/avatar.webp"}
                />
              );
            })}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
