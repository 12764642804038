import { createEffect, forward } from "effector";
import { createModelVariant, deleteModelVariant } from "~/api/assets.api";
import { CreateAssetModelOptimisationPayload, DeleteModelAssetOptimisationPayload } from "~/api/types";
import IModelAsset from "~/types/IModelAsset";
import { updateAsset } from "./updateAsset";

export const createModelVariantFx = createEffect(async (payload: CreateAssetModelOptimisationPayload) => {
  await createModelVariant(payload);
});

export const deleteModelVariantFx = createEffect(
  async (payload: DeleteModelAssetOptimisationPayload): Promise<IModelAsset> => {
    return await deleteModelVariant(payload);
  }
);

forward({ from: deleteModelVariantFx.doneData, to: updateAsset });
