import { memo, RefObject, useEffect } from "react";
import { Group } from "three";
import { ComponentType } from "~/types/ComponentType";
import { IConstraintComponent } from "~/types/IConstraintComponent";
import { ConstraintComponentContext, EntityContext, useComponentContext } from "~/view-scene/runtime";
import { usePhysics } from "~/view-scene/physics";
import { useEntityManager } from "~/view-scene/runtime";
import { EnabledStatus } from "~/types/EnabledStatus";

type ConstraintComponentProps = {
  componentDto: IConstraintComponent;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const ConstraintComponent = memo(({ componentDto, contextRef }: ConstraintComponentProps) => {
  const { addConstraint, removeConstraint } = usePhysics((state) => ({
    addConstraint: state.addConstraint,
    removeConstraint: state.removeConstraint,
  }));
  const getEntityContext = useEntityManager((state) => state.getEntityContext);

  const context: ConstraintComponentContext = {
    id: componentDto.id,
    type: ComponentType.CONSTRAINT,
    uid: -1,
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  useEffect(() => {
    if (componentDto.enabled !== EnabledStatus.enabled) {
      return;
    }

    if (!componentDto.targetEntityId) {
      return;
    }

    const targetContext = getEntityContext(componentDto.targetEntityId);
    const entityPhysicsUid = contextRef.current?.getPhysicsBody()?.uid;
    const targetPhysicsUid = targetContext?.current?.getPhysicsBody()?.uid;

    if (entityPhysicsUid === undefined || targetPhysicsUid === undefined) {
      return;
    }

    const result = addConstraint({
      bodyUid: entityPhysicsUid,
      targetUid: targetPhysicsUid,
      disableCollisionsBetweenLinkedBodies: componentDto.disableCollisionsBetweenLinkedBodies,
      constraint: componentDto.constraint,
    });

    if (!result) {
      return;
    }

    const context = contextRef.current?.components[componentDto.id] as ConstraintComponentContext | undefined;

    if (context) {
      context.uid = result.uid;
    }

    return () => {
      removeConstraint({ uid: result.uid });
    };
  }, []);

  return null;
});
