import { createEvent, createStore, Event, forward, sample } from "effector";

export type CreateUndoRedoConfig = {
  limit?: number;
};

type Action = {
  redo: () => void;
  undo: () => void;
};

export const createUndoRedo = ({ limit = 20 }: CreateUndoRedoConfig = {}) => {
  const undo = createEvent();
  const redo = createEvent();
  const reset = createEvent();

  const push = createEvent<Action>();

  const $history = createStore({
    timeline: [] as any[],
    position: 0,
  });

  const $canUndo = $history.map(({ position }) => position > 0);
  const $canRedo = $history.map(({ timeline, position }) => position < timeline.length);

  const validUndo = sample({
    source: undo,
    filter: $canUndo,
  });

  const validRedo = sample({
    source: redo,
    filter: $canRedo,
  });

  $history
    .on(push, ({ timeline, position }, action) => {
      let nextPosition = position + 1;
      const timelineWithoutFuture = timeline.slice(0, position);
      let newTimeline = [...timelineWithoutFuture, action];

      if (newTimeline.length > limit) {
        newTimeline = newTimeline.slice(-limit);
        nextPosition = newTimeline.length;
      }

      return {
        timeline: [...timelineWithoutFuture, action],
        position: nextPosition,
      };
    })
    .on(validUndo, ({ timeline, position }) => {
      const action = timeline[position - 1];
      setTimeout(() => {
        action.undo();
      });

      return {
        timeline,
        position: position - 1,
      };
    })
    .on(validRedo, ({ timeline, position }) => {
      const action = timeline[position];

      setTimeout(() => {
        action.redo();
      });

      return {
        timeline,
        position: position + 1,
      };
    })
    .reset(reset);

  const register = <T, V>(event: Event<T>, redoPayloadFactory: (data: T) => V | undefined) => {
    const a = createEvent();
    const undo = createEvent<V>();
    const redo = createEvent<T>();

    forward({
      from: event,
      to: a.prepend((v: T) => {
        const redoValue = v;
        const undoValue = redoPayloadFactory(v);

        const action: Action = {
          redo: () => redo(redoValue),
          undo: () => {
            if (undoValue !== undefined) {
              undo(undoValue);
            }
          },
        };

        setTimeout(() => {
          push(action);
        });
      }),
    });

    return [undo, redo] as const;
  };

  return {
    $history: $history.map((v) => v),
    undo,
    redo,
    $canUndo,
    $canRedo,
    reset,
    register,
  };
};
