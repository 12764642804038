import { v4 as uuid } from "uuid";
import { ComponentType } from "~/types/ComponentType";
import { EnabledStatus } from "~/types/EnabledStatus";
import IRigidBodyComponent from "~/types/IRigidBodyComponent";

export const generateRigidBodyComponent = (): IRigidBodyComponent => ({
  id: uuid(),
  enabled: EnabledStatus.enabled,
  type: ComponentType.RIGID_BODY,
  rigidBodyType: "static",
  ghost: false,
  mass: 0,
  attachTo: null,
  enableNetworkSync: false,
});
