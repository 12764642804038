import { JSScriptAsset } from "~/types/JSScriptAsset";
import { ScriptAsset, ScriptVariable } from "~/types/ScriptAsset";

export const getScriptVariables = (script?: ScriptAsset | JSScriptAsset) => {
  if (!script) {
    return [];
  }

  const schema = script.schema as any;

  return (schema.parameters ?? schema.variables ?? []) as ScriptVariable[];
};
