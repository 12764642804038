import create from "zustand";
import { PhysicsManager } from "./PhysicsManager";
import { Object3D } from "three";
import { PhysicsBody } from "./PhysicsBody";
import { BodyConfig } from "./types";
import { $flags } from "~/entities/flags";
import { PhysicsSystem } from "~/types/PhysicsSystem";

type Physics = {
  physicsManager: PhysicsManager | null;
  movementSurfaces: Object3D[];

  load: (config: PhysicsSystem) => void;
  addRigidBody: (object: Object3D, meta: any, bodyConfig: BodyConfig) => PhysicsBody | null;
  removeRigidBody: (uid: number) => void;
  attachObjectToBody: (uid: number, object: Object3D) => void;
  addConstraint: (payload: AddConstraintPayload) => AddConstraintReturn | null;
  removeConstraint: (payload: RemoveConstraintPayload) => void;

  reset: () => void;
};

type AddConstraintPayload = Parameters<typeof PhysicsManager.prototype.addConstraint>[0];
type AddConstraintReturn = ReturnType<typeof PhysicsManager.prototype.addConstraint>;

type RemoveConstraintPayload = Parameters<typeof PhysicsManager.prototype.removeConstraint>[0];

export const usePhysics = create<Physics>((set, get) => ({
  physicsManager: null,

  movementSurfaces: [],

  load: async (config: PhysicsSystem) => {
    if (get().physicsManager) {
      return;
    }

    if (config.type !== "3d") {
      return;
    }

    const physicsManager = new PhysicsManager(config);
    await physicsManager.init();

    if ($flags.getState().physicsDebugger) {
      physicsManager.enableDebugger();
    }

    set(() => ({ physicsManager: physicsManager }));
  },

  addRigidBody: (object, meta, bodyConfig) => {
    const physicsManager = get().physicsManager;

    if (!physicsManager) {
      return null;
    }

    const physicsBody = physicsManager.addBody(object, meta, bodyConfig);

    get().movementSurfaces.push(object);

    return physicsBody;
  },
  attachObjectToBody: (uid, object) => {
    get().physicsManager?.attachObjectToBody(uid, object);
  },
  removeRigidBody: (uid) => {
    get().physicsManager?.removeBody(uid);
  },
  addConstraint: (payload) => {
    const physicsManager = get().physicsManager;

    if (!physicsManager) {
      return null;
    }

    return physicsManager.addConstraint(payload);
  },
  removeConstraint: (payload) => {
    const physicsManager = get().physicsManager;

    if (!physicsManager) {
      return null;
    }

    return physicsManager.removeConstraint(payload);
  },
  reset: () => {
    get().physicsManager?.destroy();
    set({ physicsManager: null, movementSurfaces: [] });
  },
}));
