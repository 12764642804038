import { NodeContext } from "../../base";
import { UISocket } from "../../sockets";
import { SceneEntityNode } from "./SceneEntityNode";

export class SceneUINode extends SceneEntityNode {
  static readonly type = "variable/sceneUI";
  static readonly label = "UI";

  constructor(context: NodeContext) {
    super(context, new UISocket("ui"));
  }
}
