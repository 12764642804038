import { MutableRefObject, useMemo, useRef } from "react";

const noDeps: any[] = [];

export const useEntityContext = <TRef>(
  ref: MutableRefObject<TRef>,
  init: () => Partial<TRef>,
  deps: any[] = noDeps
) => {
  const initFunctionRef = useRef(init);
  initFunctionRef.current = init;

  const refRef = useRef(ref);
  refRef.current = ref;

  useMemo(() => {
    const contextRef = refRef.current;

    if (!contextRef) {
      return;
    }

    if (!contextRef.current) {
      contextRef.current = {} as TRef;
    }

    const values = initFunctionRef.current();

    for (const key in values) {
      const descriptor = Object.getOwnPropertyDescriptor(values, key)!;
      Object.defineProperty(contextRef.current, key, descriptor);
    }
  }, deps);
};
