import Editor, { EditorProps, Monaco } from "@monaco-editor/react";
import "./CodeEditor.css";
import * as glsl from "./glsl";

export type CodeEditorProps = EditorProps & {
  language?: "glsl" | "javascript" | "json";
};

export function CodeEditor({ language = "glsl", ...rest }: CodeEditorProps) {
  function handleBeforeMount(monaco: Monaco) {
    if (language === "glsl") {
      monaco.languages.register({ id: "glsl" });
      monaco.languages.setLanguageConfiguration("glsl", glsl.conf);
      monaco.languages.setMonarchTokensProvider("glsl", glsl.language);
    }
  }

  return (
    <Editor
      height="calc(100% - 10px)"
      beforeMount={handleBeforeMount}
      defaultLanguage={language}
      language={language}
      theme="vs-dark"
      options={{
        minimap: {
          enabled: false,
        },
      }}
      {...rest}
    />
  );
}
