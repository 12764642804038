import { styled, Tabs as MuiTabs } from "@mui/material";
import { palette } from "~/theme";

const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    right: "auto",
    left: 0,
    backgroundColor: palette.primary.violet,
    borderRadius: "8px",
    width: "4px",
  },

  "& .MuiTabs-flexContainer ": {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  "& .MuiButtonBase-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    color: palette.primary.gray600,
    minHeight: "32px",
    padding: "12px 16px",
    fontSize: "16px",
    marginBottom: "8px",
    lineHeight: "20px",
    transition: ".4s ease",
    [theme.breakpoints.down("md")]: {
      padding: "12px 5px",
      minWidth: "auto",
      margin: "0 8px 0 0",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  "& .MuiButtonBase-root.Mui-selected ": {
    color: palette.primary.white,
    [theme.breakpoints.down("md")]: {
      overflow: "auto",
    },
  },
}));

export default Tabs;
