import { NodeContext } from "../../base";
import { NumberCompareNode } from "./NumberCompareNode";

export class NumberLessOrEqualNode extends NumberCompareNode {
  static readonly type = "logic/numberLessOrEqual";
  static readonly label = "Number <=";

  constructor(context: NodeContext) {
    super(context, (a, b) => a <= b);
  }
}
