import { createEffect } from "effector";
import { uploadScenePreview } from "~/api/scene.api";
import IScene from "~/types/IScene";

export const uploadScenePosterFx = createEffect(async ({ sceneId, previewFile }: { sceneId: string; previewFile: File }) => {
  const { status, body: scene } = await uploadScenePreview(sceneId, previewFile);

  if (status !== 200) {
    throw new Error("Failed to upload");
  }

  return scene as unknown as IScene;
});
