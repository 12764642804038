import { BaseSocket } from "../base";

export class FlowSocket extends BaseSocket {
  constructor(id: string = "flow", label: string = id) {
    super("flow", id, label);
  }

  clone() {
    return new FlowSocket(this.id, this.label);
  }
}
