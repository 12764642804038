import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "~/types/Variable";

export type { ComponentValue };

export const componentValue = new ValueType(
  "component",
  defaultComponent,
  (json: ComponentValue) => json,
  (value: ComponentValue) => value
);
