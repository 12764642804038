import { FC } from "react";
import {
  Actionable,
  CircleSmall,
  Clip,
  CodeSquare,
  Constraint,
  CubeOutline,
  Flash,
  IconProps,
  Link,
  NavigationPointer,
  Phone,
  Route,
  Touch,
} from "~/common/components/Icon";
import { ComponentType } from "~/types/ComponentType";

export type ComponentIconProps = IconProps & {
  type: ComponentType;
};

export const ComponentIcon = ({ type, ...rest }: ComponentIconProps) => {
  const Component = componentsMap[type];

  return Component ? <Component {...rest} /> : <CircleSmall {...rest} />;
};

const componentsMap: PartialRecord<ComponentType, FC<IconProps>> = {
  [ComponentType.RIGID_BODY]: CubeOutline,
  [ComponentType.CONSTRAINT]: Constraint,
  [ComponentType.LINK]: Link,
  [ComponentType.ANIMATION]: Flash,
  [ComponentType.SPRITE_ANIMATION]: Flash,
  [ComponentType.TRAJECTORY]: Route,
  [ComponentType.FOLLOW_TARGET]: NavigationPointer,
  [ComponentType.VIEW_IN_AR]: Phone,
  [ComponentType.SCRIPT]: CodeSquare,
  [ComponentType.JS_SCRIPT]: CodeSquare,
  [ComponentType.ACTIONABLE]: Actionable,
  [ComponentType.ATTACH_TO]: Clip,
  [ComponentType.INTERACTIVE]: Touch,
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};
