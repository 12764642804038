import { ChangeEvent } from "react";
import { AssetSelectorOnChangeEvent, ModelVariantChangeEvent } from "~/hephaestus/assets";
import {
  OnColorPickerChangeEvent,
  OnNumericFieldChangeEvent,
  OnSliderChangeEvent,
  OnVectorFieldChangeEvent,
  SelectChangeEvent,
} from "~/hephaestus/components";
import ICoordinates from "~/types/ICoordinates";
import type { GeometryInspectorChangeEvent } from "~/hephaestus/SceneEditor/entities/geometry";
import type { PhysicsShapeInspectorChangeEvent } from "~/hephaestus/SceneEditor/entities/physicsShape";

export const getChangeValue = (
  e:
    | OnVectorFieldChangeEvent
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | OnSliderChangeEvent
    | OnColorPickerChangeEvent
    | SelectChangeEvent
    | ModelVariantChangeEvent
    | AssetSelectorOnChangeEvent
    | OnNumericFieldChangeEvent
    | GeometryInspectorChangeEvent
    | PhysicsShapeInspectorChangeEvent,
  valueConverter?: (value: any) => number | string | boolean | ICoordinates
) => {
  let value;

  const inputElement = e as any;
  if (inputElement.target?.type && (inputElement.target?.value != null || inputElement.target?.checked != null)) {
    value = inputElement.target.type === "checkbox" ? inputElement.target.checked : inputElement.target.value;
  } else if (inputElement.target?.value != null) {
    value = inputElement.target.value;
  }

  if (valueConverter) {
    value = valueConverter(value);
  }

  return value;
};
