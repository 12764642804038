import { BODY_DATA_LENGTH, HEADER_LENGTH } from "./constants";

export const calcBytesSize = (length: number) => {
  return HEADER_LENGTH * 4 + length * BODY_DATA_LENGTH * 4;
};

export const calcLength = (bytes: number) => {
  const itemSize = BODY_DATA_LENGTH * 4;
  const headerSize = HEADER_LENGTH * 4;

  return (bytes - headerSize) / itemSize;
};
