import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { getTonConnect } from "./getTonConnect";

export class TonConnectWalletNode extends BaseNode {
  static readonly type = "web3/tonConnectWallet";
  static readonly label = "TonConnect wallet";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("manifest")];
  outputs = [
    new FlowSocket("success"),
    new StringSocket("address"),
    new StringSocket("chain"),
    new StringSocket("publicKey"),
    new FlowSocket("noWallet", "no wallet"),
  ];

  eval(context: NodeEvalContext) {
    const tonConnectUI = getTonConnect(context.readInput<string>("manifest"));

    async function getWallet() {
      await tonConnectUI.connectionRestored;

      const currentAccount = tonConnectUI.account;

      if (tonConnectUI.wallet && currentAccount) {
        context.writeOutput<string>("address", currentAccount.address);
        context.writeOutput<string>("chain", currentAccount.chain);
        context.writeOutput<string>("publicKey", currentAccount?.publicKey || "");
        context.commit("success");
        context.graphEvaluator.executeAll();
      } else {
        context.commit("noWallet");
        context.graphEvaluator.executeAll();
      }
    }

    getWallet();
  }
}
