import { createEvent, createStore } from "effector";
import { SourceType } from "../types";

type SetAudioSourceState = Record<SourceType, { volume: number }>;

export const setAudioSourceState = createEvent<Partial<SetAudioSourceState>>();

export const $audioSourceState = createStore<SetAudioSourceState>({
  remoteUserAudioSource: { volume: 1 },
  mediaVideo: { volume: 1 },
  sfx: { volume: 1 },
  video360: { volume: 0 },
}).on(setAudioSourceState, (state, payload) => ({
  ...state,
  ...payload,
}));
