import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class UpdateCameraViewMode extends BaseNode {
  static readonly type = "action/updateCameraViewMode";
  static readonly label = "Update Camera View Mode";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultVector3();
  }

  eval(_context: NodeEvalContext, node: BaseBehaveNode) {
    const cameraViewMode = node.readMetadata<"firstPerson" | "thirdPerson">("cameraViewMode", "thirdPerson");
    node.sceneContext.updateCameraMode(cameraViewMode);
  }
}
