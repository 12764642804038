import {
  BufferAttribute,
  BoxGeometry as ThreeBoxGeometry,
  BufferGeometry as ThreeBufferGeometry,
  ConeGeometry as ThreeConeGeometry,
  CylinderGeometry as ThreeCylinderGeometry,
  PlaneGeometry as ThreePlaneGeometry,
  SphereGeometry as ThreeSphereGeometry,
} from "three";
import { hasType } from "~/common/utils/hasType";
import {
  BoxGeometry,
  ConeGeometry,
  CylinderGeometry,
  Geometry,
  GeometryType,
  PlaneGeometry,
  SphereGeometry,
  BufferGeometry,
} from "~/types/Geometry";
import ICoordinates from "~/types/ICoordinates";

export const toGeometry = (geometryDto: Geometry): ThreeBufferGeometry | null => {
  if (hasType<Geometry, PlaneGeometry, GeometryType>(geometryDto, GeometryType.plane)) {
    const { width, height, widthSegments, heightSegments } = geometryDto;
    return new ThreePlaneGeometry(width, height, widthSegments, heightSegments);
  } else if (hasType<Geometry, ConeGeometry, GeometryType>(geometryDto, GeometryType.cone)) {
    const { height, radius, heightSegments, radialSegments } = geometryDto;
    return new ThreeConeGeometry(radius, height, radialSegments, heightSegments);
  } else if (hasType<Geometry, BoxGeometry, GeometryType>(geometryDto, GeometryType.box)) {
    const { width, height, depth, widthSegments, heightSegments, depthSegments } = geometryDto;
    return new ThreeBoxGeometry(width, height, depth, widthSegments, heightSegments, depthSegments);
  } else if (hasType<Geometry, SphereGeometry, GeometryType>(geometryDto, GeometryType.sphere)) {
    const { radius, widthSegments, heightSegments } = geometryDto;
    return new ThreeSphereGeometry(radius, widthSegments, heightSegments);
  } else if (hasType<Geometry, CylinderGeometry, GeometryType>(geometryDto, GeometryType.cylinder)) {
    const { height, radiusBottom, radiusTop, heightSegments, radialSegments } = geometryDto;
    return new ThreeCylinderGeometry(radiusTop, radiusBottom, height, radialSegments, heightSegments);
  } else if (hasType<Geometry, BufferGeometry, GeometryType>(geometryDto, GeometryType.buffer)) {
    const arr = pointsToTriangles(geometryDto.points);

    const vertices = new Float32Array(arr);
    const geometry = new ThreeBufferGeometry();

    geometry.setAttribute("position", new BufferAttribute(vertices, 3));

    return geometry;
  }

  return null;
};

const pointsToTriangles = (points: ICoordinates[]): number[] => {
  const arr: number[] = [];

  if (points.length < 3) {
    points.forEach((point) => {
      arr.push(point.x, point.y, point.z);
    });
  } else {
    for (let i = 2; i < points.length; i++) {
      arr.push(points[i - 2].x, points[i - 2].y, points[i - 2].z);
      arr.push(points[i - 1].x, points[i - 1].y, points[i - 1].z);
      arr.push(points[i].x, points[i].y, points[i].z);
    }
  }

  return arr;
};
