import { ListItemIcon, MenuList, MenuProps, Stack, styled, Typography } from "@mui/material";
import { Menu } from "~/hephaestus/components/Menu";
import { MenuItem } from "~/hephaestus/components/MenuItem";
import { Duplicate, Play, Trash } from "~/common/components/Icon";
import IScene from "~/types/IScene";
import ConfirmationDialog from "~/common/components/ConfirmationDialog";
import { MouseEvent, useState } from "react";
import { cloneScenesFx, deleteScenesFx } from "./models";

export type SceneActionsProps = Omit<MenuProps, "onClose"> & {
  scene: IScene;
  onClose: VoidFunction;
};

export function SceneActions({ scene, onClose, ...rest }: SceneActionsProps) {
  const handlePreview = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    window.open(`/s/${scene.urlName}/view`, "_blank")?.focus();
    onClose();
  };

  const handleClone = async () => {
    onClose();
    await cloneScenesFx(scene._id);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const openDeleteConfirmationDialog = (event: MouseEvent<HTMLElement>) => {
    onClose();
    setOpenConfirmDeleteDialog(true);
  };

  const closeDeleteConfirmationDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDelete = async () => {
    setOpenConfirmDeleteDialog(false);
    await deleteScenesFx(scene._id);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirmDeleteDialog}
        title="Confirm your action"
        content="This action cannot be reversed. Are you sure you want to remove the Scene?"
        onConfirm={handleDelete}
        onClose={closeDeleteConfirmationDialog}
      />
      <Menu onClose={onClose} {...rest}>
        <StyledMenuList>
          <StyledMenuItem onClick={handlePreview}>
            <MenuItemContainer>
              <ListItemIcon>
                <Play size="small" color="primary.gray600" />
              </ListItemIcon>
              <Typography color="primary.gray600" variant="body1">
                Preview Scene
              </Typography>
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClone}>
            <MenuItemContainer>
              <ListItemIcon>
                <Duplicate size="small" color="primary.gray600" />
              </ListItemIcon>
              <Typography color="primary.gray600" variant="body1">
                Duplicate
              </Typography>
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem onClick={openDeleteConfirmationDialog}>
            <MenuItemContainer>
              <ListItemIcon>
                <Trash size="small" color="primary.gray600" />
              </ListItemIcon>
              <Typography color="primary.gray600" variant="body1">
                Remove
              </Typography>
            </MenuItemContainer>
          </StyledMenuItem>
        </StyledMenuList>
      </Menu>
    </>
  );
}

const StyledMenuList = styled(MenuList)`
  padding: 0;
`;

const StyledMenuItem = styled(MenuItem)`
  min-height: 32px;
`;

const MenuItemContainer = styled(Stack)`
  flex-direction: row;
  align-items: flex-start;
  gap: 0;
`;
