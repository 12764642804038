import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { ModelContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetMaterialSocket, ModelSocket } from "../../sockets";
import { AssetMaterialValue } from "../../values";

export class ModelMaterialNode extends BaseNode {
  static readonly type = "action/modelGetMaterial";
  static readonly label = "Get Model Material";

  inputs = [new ModelSocket("model")];
  outputs = [new AssetMaterialSocket("material")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const model = node.readEntity<ModelContext>(context, "model");
    const material = model?.getMaterial();
    node.storage.output.assetId = material ?? null;
    context.writeOutput<AssetMaterialValue>("material", node.storage.output);
  }
}
