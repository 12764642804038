import { useUnit } from "effector-react";
import { Fragment } from "react";
import { $addons } from "./models";

export const Addons = () => {
  const addons = useUnit($addons);

  return (
    <>
      {addons.map((addon) => (
        <Fragment key={addon.id}>{addon.node}</Fragment>
      ))}
    </>
  );
};
