import { throttle } from "lodash-es";
import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket, NumberSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class ThrottleNode extends BaseNode {
  static readonly type = "time/throttle";
  static readonly label = "Throttle";
  static category: NodeCategory = "Time";

  async = true;

  inputs = [new FlowSocket("flow"), new NumberSocket("timeout", "timeout (ms)")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode) {
    node.storage.func = null;
    node.storage.throttler = null;
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    let timeIsCancelled = false;
    const timeout = context.readInput<number>("timeout");

    node.storage.func = () => {
      if (timeIsCancelled) {
        return;
      }

      context.commit("flow");
      context.graphEvaluator.executeAll();
    };

    if (!node.storage.throttler) {
      node.storage.throttler = throttle(() => {
        node.storage.func && node.storage.func();
      }, timeout);
    }

    node.storage.throttler();

    context.onAsyncCancelled.addListener(() => {
      timeIsCancelled = true;
    });
  }
}
