import { SliderOptions } from "~/types/IConstraintComponent";
import { NumericFieldWithAuto } from "../NumericField";
import { LabelGroup } from "../LabelGroup";
import { ConstraintInspectorBaseProps } from "./types";

export type SliderInspectorProps = ConstraintInspectorBaseProps<SliderOptions>;

export const SliderInspector = ({ value, onChange }: SliderInspectorProps) => {
  const handleChange = (e: { target: { name: string; value: any } }) => {
    onChange?.({
      target: {
        name: "constraint",
        value: {
          ...value,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <>
      <LabelGroup label="Lower Lin Limit">
        <NumericFieldWithAuto
          name="lowerLinLimit"
          value={value.lowerLinLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Upper Lin Limit">
        <NumericFieldWithAuto
          name="upperLinLimit"
          value={value.upperLinLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Lower Ang Limit">
        <NumericFieldWithAuto
          name="lowerAngLimit"
          value={value.lowerAngLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Upper Ang Limit">
        <NumericFieldWithAuto
          name="upperAngLimit"
          value={value.upperAngLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
    </>
  );
};
