import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { ModelContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetModelSocket, ModelSocket } from "../../sockets";
import { AssetModelValue } from "../../values";

export class ModelModelNode extends BaseNode {
  static readonly type = "action/modelModelAsset";
  static readonly label = "Get Model Asset";

  inputs = [new ModelSocket("model")];
  outputs = [new AssetModelSocket("assetModel")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const model = node.readEntity<ModelContext>(context, "model");
    const modelAsset = model?.getModel() ?? null;

    node.storage.output.assetId = modelAsset?.assetId ?? null;
    node.storage.output.variantId = modelAsset?.variantId ?? null;

    context.writeOutput<AssetModelValue>("assetModel", node.storage.output);
  }
}
