import { NodeContext } from "../../base";
import { VideoSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToVideoNode extends CastToNode {
  static readonly type = "logic/castToVideo";
  static readonly label = "Cast to Video";

  constructor(context: NodeContext) {
    super(context, new VideoSocket("video"), "video");
  }
}
