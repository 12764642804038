import { keyBy } from "lodash-es";
import { ScriptArguments } from "~/types/IScriptComponent";
import { ScriptVariable } from "~/types/ScriptAsset";
import { VariablesManager } from "../VariablesManager";
import { JSScriptExports } from "./types";

export class JSVariablesManager extends VariablesManager {
  private variables: Record<string, ScriptVariable>;

  constructor(variables: ScriptVariable[], private args: ScriptArguments, private exportObject: JSScriptExports) {
    super();
    this.variables = keyBy(variables, "id");
    this.initArguments();
  }

  getValue(variableId: string) {
    const variableName = this.getVariableName(variableId);

    return this.exportObject[variableName] ?? null;
  }

  setValue(variableId: string, value: any) {
    const variableName = this.getVariableName(variableId);

    if (this.exportObject[variableName]) {
      this.exportObject[variableName] = value;
    }
  }

  getValueByName(name: string) {
    return this.exportObject[name] ?? null;
  }

  setValueByName(name: string, value: any) {
    this.exportObject[name] = value;
  }

  private getVariableName(variableId: string) {
    return this.variables[variableId]?.name ?? "";
  }

  private initArguments() {
    Object.entries(this.args).forEach(([key, value]) => {
      this.setValue(key, value.value);
    });
  }
}
