import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket, NumberSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class TickNode extends BaseNode {
  static readonly type = "event/tick";
  static readonly label = "Tick";
  static category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow"), new NumberSocket("deltaSeconds", "delta (ms)")];

  initNode(node: BaseBehaveNode) {
    node.tickable = node.outputConnected("flow");
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    if (!node.outputConnected("flow")) {
      return;
    }

    const onTickEvent = (deltaSeconds: number) => {
      context.writeOutput("deltaSeconds", deltaSeconds);
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.tickEvent.addListener(onTickEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.tickEvent.removeListener(onTickEvent);
    });
  }
}
