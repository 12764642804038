import { SpringOptions } from "~/types/IConstraintComponent";
import { VectorFieldWithAuto } from "../VectorField";
import { NumericFieldWithAuto } from "../NumericField";
import { LabelGroup } from "../LabelGroup";
import { ConstraintInspectorBaseProps } from "./types";
import { Switch } from "../Switch";

export type SpringInspectorProps = ConstraintInspectorBaseProps<SpringOptions>;

export const SpringInspector = ({ value, onChange }: SpringInspectorProps) => {
  const handleChange = (e: { target: { name: string; value: any; checked?: boolean } }) => {
    const newValue = e.target.checked === undefined ? e.target.value : e.target.checked;

    onChange?.({
      target: {
        name: "constraint",
        value: {
          ...value,
          [e.target.name]: newValue,
        },
      },
    });
  };

  return (
    <>
      <LabelGroup label="Linear Lower Limit">
        <VectorFieldWithAuto
          name="linearLowerLimit"
          value={value.linearLowerLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Linear Upper Limit">
        <VectorFieldWithAuto
          name="linearUpperLimit"
          value={value.linearUpperLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Angular Lower Limit">
        <VectorFieldWithAuto
          name="angularLowerLimit"
          value={value.angularLowerLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label="Angular Upper Limit">
        <VectorFieldWithAuto
          name="angularUpperLimit"
          value={value.angularUpperLimit}
          nullValue={undefined}
          onChange={handleChange}
        />
      </LabelGroup>
      <LabelGroup label={"Enable\u00A0Spring"}>
        <Switch name="enableSpring" checked={value.enableSpring ?? false} onChange={handleChange} />
      </LabelGroup>
      {value.enableSpring ? (
        <>
          <LabelGroup label="Stiffness">
            <NumericFieldWithAuto
              name="stiffness"
              value={value.stiffness}
              nullValue={undefined}
              onChange={handleChange}
            />
          </LabelGroup>
          <LabelGroup label="Damping">
            <NumericFieldWithAuto name="damping" value={value.damping} nullValue={undefined} onChange={handleChange} />
          </LabelGroup>
        </>
      ) : null}
    </>
  );
};
