import { Box, styled, Typography } from "@mui/material";
import { palette } from "~/theme";

export const Circle = styled(Box)`
  width: 46px;
  height: 46px;
  display: block;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  border: 3px solid ${palette.primary.gray400};
  background-color: ${palette.primary.gray100};
`;

export const Description = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 136px;
  color: ${palette.primary.gray800};
`;
