import { AvatarAnimation } from "~/types/AvatarSystem";

const getApiHost = () => {
  if (window.__sceneData?.apiUrl !== undefined) {
    return window.__sceneData.apiUrl;
  }

  const api = process.env.REACT_APP_API;

  switch (api) {
    case "local":
      return "http://localhost:3001";
    case "dev":
      return "https://server.8xr.io";
    default:
      return "https://server.8xr.io";
  }
};

export const isPlayable = Boolean(window.__sceneData);

type Host =
  | "8xr"
  | "deversee"
  | "ticketmeta"
  | "legends-dance"
  | "dogeverse"
  | "digital-air"
  | "chimera-platfrom"
  | "haken-gallery"
  | "gameverse"
  | "centyverse";

const getHost = (): Host => {
  console.info("Active host:", window.location.hostname);

  switch (window.location.hostname) {
    case "meta.deversee.com":
      return "deversee";
    case "play.ticketmeta.io":
      return "ticketmeta";
    case "www.dogeverse.cool":
      return "dogeverse";
    case "play.digital-air.art":
      return "digital-air";
    case "play.chimeraplatform.art":
      return "chimera-platfrom";
    case "play.haken-gallery.co.uk":
      return "haken-gallery";
    case "gameverse.8xr.io":
      return "gameverse";
    case "enter.centyverse.com":
      return "centyverse";
    case "www.legends.dance":
    default:
      return "8xr";
  }
};

export const API_HOST = getApiHost();

export const HOST: Host = getHost();

export const NODE_ENV = process.env.NODE_ENV as "production" | "development" | undefined;

export const PLAYER_HEIGHT = 1.7;

export const ICE_SERVERS = [
  {
    urls: "turn:3.10.234.66:3478",
    credential: "password1",
    username: "username1",
  },
];

export const MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB = 300;

export const BASIS_TRANSCODER_URL = "https://8xr.io/assets/basis/";

export const FAR_LOD_AVATAR_DISTANCE = 15;

const getAllowedOrigins = () => {
  const api = process.env.REACT_APP_API;

  switch (api) {
    case "local":
      return ["*"];
    default:
      return [
        "https://8xr.io/",
        "https://meta.deversee.com/",
        "https://play.ticketmeta.io/",
        "https://play.krumpkings.io",
        "https://www.dogeverse.cool",
        "https://play.digital-air.art",
        "https://play.chimeraplatform.art",
        "https://play.haken-gallery.co.uk",
        "https://gameverse.8xr.io",
        "https://www.legends.dance",
        "https://enter.centyverse.com",
      ];
  }
};

export const ALLOWED_ORIGINS = getAllowedOrigins();

const getRootAppHost = () => {
  const api = process.env.REACT_APP_API;

  switch (api) {
    case "local":
      return "https://localhost:3006";
    default:
      return "https://8xr.io";
  }
};

export const ROOT_APP_HOST = getRootAppHost();

export const READY_PLAYER_ME_API = "https://8xr.readyplayer.me/avatar?frameApi&bodyType=fullbody";

const getWhiteLabelingProfile = (host: Host) => {
  switch (host) {
    case "deversee":
      return {
        logo: "/static/img/content/logo-deversee.png",
        loadingScreenLogo: "/static/img/content/logo-deversee-animated.webp",
        landing: {
          background: "/static/img/content/landing/deversee-landing.webp",
          title: "Deversee",
          description: "Ton virtual world",
          rootScene: "/university",
        },
        scenes: [
          { urlName: "deversee", path: "/university" },
          { urlName: "deversee-holders", path: "/holders" },
        ],
      };
    case "ticketmeta":
      return {
        logo: "/static/img/content/logo-ticketmeta.png",
        loadingScreenLogo: "/static/img/content/logo-ticketmeta-animated.webp",
        landing: {
          background: "/static/img/content/landing/ticketmeta-landing.webp",
          description:
            "First-Ever Metaverse Venice Carnival about to be broadcasted to the metaverse on February 26th, 7pm GMT (London) and 7pm GMT+9  (Seoul)." +
            "As an icing on the cake, this event will also feature a 360° immersive experience for viewers. You are not just about to view an event, you are about to enter into the atmosphere and experience of a Venice carnival. Gamification rewards, NFT giveaway!",
          rootScene: "/welcome",
        },
        scenes: [
          { urlName: "TicketSquare", path: "/welcome" },
          { urlName: "TicketInterior", path: "/holders" },
        ],
      };
    case "legends-dance":
      return {
        logo: "/static/img/content/logo-legends-dance.png",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "Dance-off Legends",
          description: "",
          rootScene: "/start",
        },
        scenes: [
          { urlName: "pbwvxrowcfo", path: "/start" },
          { urlName: "bdpw9src4y5", path: "/octagone" },
        ],
      };
    case "dogeverse":
      return {
        logo: "/static/img/content/logo-dogeverse.png",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "/static/img/content/landing/dogeverse_landing.webp",
          title: "DOGEVERSE",
          description: "",
          rootScene: "/mars",
        },
        scenes: [{ urlName: "dogeverse", path: "/mars" }],
      };
    case "digital-air":
      return {
        logo: "/static/img/content/logo_digital_air.png",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "Digital Air",
          description: "",
          rootScene: "/live",
        },
        scenes: [
          { urlName: "digitalairstart", path: "/live" },
          { urlName: "daspace6", path: "/daspace6" },
          { urlName: "susannegoats", path: "/susannegoats" },
          { urlName: "da3disorientation", path: "/da3disorientation" },
          { urlName: "entfaltung", path: "/entfaltung" },
          { urlName: "da4tongue", path: "/da4tongue" },
          { urlName: "da7fishswarmundtiger", path: "/da7fishswarmundtiger" },
        ],
      };
    case "chimera-platfrom":
      return {
        logo: "/static/img/content/logo_chimera.png",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "/static/img/content/landing/chimera-landing.webp",
          title: "Chimera Platform",
          description:
            "Chimera platform in partnership with 8XR metaverse graphic game engine presents Anastasiia Vorobiova’s solo show ‘The Cyber Cave’, an immersive web environment developed as part of ‘Solidarity Circles’ residency. In this project, the artist addresses practicesof prehistoric times to question the limitations of the Western worldview and to reflect  on the idea of a collective digital presence.",
          rootScene: "/cave",
        },
        scenes: [
          { urlName: "yryaddfhaa9", path: "/cave" },
          { urlName: "backyardsounding", path: "/backyardsounding" },
        ],
      };
    case "haken-gallery":
      return {
        logo: "/static/img/content/logo.svg",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "Haken Gallery",
          description:
            "Welcome to our HAKEN immersive gallery, where art comes to life through the power of virtual reality. Located in the virtual heart of London, our gallery offers a unique and fully interactive experience that brings together cutting-edge technology and stunning artwork. Step into our virtual space and explore exhibitions from around the world, showcasing a wide range of styles and mediums. From classic paintings and sculptures to contemporary installations and multimedia works, our immersive gallery offers something for every art lover.",
          rootScene: "/september",
        },
        scenes: [
          { urlName: "haken-august", path: "/august" },
          { urlName: "haken-july", path: "/july" },
          { urlName: "hakenaward", path: "/september" },
          { urlName: "haken-october", path: "/october" },
        ],
      };
    case "gameverse":
      return {
        logo: "/static/img/content/logo.svg",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "Gameverse Competition",
          description: "",
          rootScene: "/g1-MerryHappens",
        },
        scenes: [
          { urlName: "MerryHappens", path: "/g1-MerryHappens" },
          { urlName: "h7mhpyf4q4q", path: "/g1-maze" },
        ],
      };
    case "centyverse":
      return {
        logo: "/static/img/content/logo.svg",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "Centyverse",
          description: "Centyverse",
          rootScene: "/welcome",
        },
        scenes: [{ urlName: "CentryVerse", path: "/welcome" }],
      };
    default:
      return {
        logo: "/static/img/content/logo.svg",
        loadingScreenLogo: "/static/img/content/logo-animated.gif",
        landing: {
          background: "",
          title: "",
          description: "",
          rootScene: "",
        },
        scenes: [],
      };
  }
};

export const WHITE_LABELING_PROFILE = getWhiteLabelingProfile(HOST);

const DEFAULT_ANIMATIONS: Record<
  string,
  Pick<AvatarAnimation, "maleAsset" | "maleAnimationName" | "femaleAsset" | "femaleAnimationName">
> = {
  IDLE: {
    maleAsset: "641c2bd50ee08cd3f4d93cc1",
    maleAnimationName: "IDLE",
    femaleAsset: "641c2bc90ee08cd3f4d9396c",
    femaleAnimationName: "IDLE",
  },
  WALKING: {
    maleAsset: "641c2bd50ee08cd3f4d93cc1",
    maleAnimationName: "WALKING",
    femaleAsset: "641c2bc90ee08cd3f4d9396c",
    femaleAnimationName: "WALKING",
  },
  RUNNING: {
    maleAsset: "641c2bd50ee08cd3f4d93cc1",
    maleAnimationName: "RUNNING",
    femaleAsset: "641c2bc90ee08cd3f4d9396c",
    femaleAnimationName: "RUNNING",
  },
  JUMP: {
    maleAsset: "641c2bdf0ee08cd3f4d93eac",
    maleAnimationName: "JUMP_Armature",
    femaleAsset: "641c2bdf0ee08cd3f4d93eac",
    femaleAnimationName: "JUMP_Armature",
  },
};

export const getDefaultAnimationAssets = (id: string) => DEFAULT_ANIMATIONS[id];

export const fontFamilies = {
  roboto: {
    name: "Roboto",
    regular: {
      url: "/static/fonts/Roboto-Regular.ttf",
    },
    bold: {
      url: "/static/fonts/Roboto-Bold.ttf",
    },
  },
  story: {
    name: "Story",
    regular: {
      url: "/static/fonts/Story.otf",
    },
    bold: {
      url: "/static/fonts/Story_B.otf",
    },
  },
  bangers: {
    name: "Bangers",
    regular: {
      url: "/static/fonts/Bangers-Regular.ttf",
    },
    bold: {
      url: "/static/fonts/Bangers-Regular.ttf",
    },
  },
  notosans: {
    name: "Noto Sans",
    regular: {
      url: "/static/fonts/NotoSans-Regular.ttf",
    },
    bold: {
      url: "/static/fonts/NotoSans-Bold.ttf",
    },
  },
};

export type FontFamily = keyof typeof fontFamilies;

export type ExternalGameName =
  | "kingdomSurvivor"
  | "parkingJam"
  | "polygami"
  | "candyland"
  | "endlessSiege"
  | "endlessSiege2"
  | "memoryMatch"
  | "dropAndMerge"
  | "boxTower"
  | "kingSolitaire"
  | "sudokuMaster"
  | "riseUp"
  | "zombieAttack"
  | "desertWarrior"
  | "formulaRush"
  | "rudeRaces"
  | "crazyFishing"
  | "slingTomb"
  | "coinMatch"
  | "hangman"
  | "dungeon2048"
  | "furiousRoad"
  | "battleship"
  | "chess"
  | "chessSingle"
  | "wordCake"
  | "boltClicker"
  | "battalionCommander"
  | "royalFortress"
  | "endlessSiegeGamee"
  | "osolitaireKlondike"
  | "osolitaireFreecell"
  | "osolitairePyramid"
  | "osolitaireRus"
  | "osolitaireGolf"
  | "osolitaireSpider"
  | "osolitaireYukon"
  | "osolitaireTriPeaks";

export const getExternalGameConfig = (urlName: ExternalGameName) => {
  switch (urlName) {
    case "kingdomSurvivor":
      return {
        sceneId: "6463645f0467071f946ed2d7",
        path: "https://central.hackney.games/h5/kingdom-survivor/play.html",
      };
    case "parkingJam":
      return {
        sceneId: "6464ee92642a225c3bbed91b",
        path: "https://central.hackney.games/h5/parking-jam/play.html",
      };
    case "osolitaireKlondike":
      return {
        sceneId: "65cb99667fdda0411cb1a07d",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=klondike",
      };
    case "osolitaireFreecell":
      return {
        sceneId: "65d0f2c5d04b30c744a35bc8",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=freecell",
      };
    case "osolitairePyramid":
      return {
        sceneId: "65d0f454d04b30c744a397ca",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=pyramid",
      };
    case "osolitaireRus":
      return {
        sceneId: "65d0f477d04b30c744a3aa54",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=rus",
      };
    case "osolitaireGolf":
      return {
        sceneId: "65d0f491d04b30c744a3bad1",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=golf",
      };
    case "osolitaireSpider":
      return {
        sceneId: "65d0f4afd04b30c744a3cb71",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=spider",
      };
    case "osolitaireYukon":
      return {
        sceneId: "65d0f4c6d04b30c744a3d11a",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=yukon",
      };
    case "osolitaireTriPeaks":
      return {
        sceneId: "65d0f4e9d04b30c744a3d9d8",
        path: "https://central.hackney.games/h5/osolitaire/play.html?game=tripeaks",
      };
    case "boltClicker":
      return {
        sceneId: "653b8eee24cbfc0e02b9584c",
        path: "https://central.hackney.games/h5/bolt-clicker/play.html",
      };
    case "polygami":
      return {
        sceneId: "6464ff441f819eb14674c74b",
        path: "https://central.hackney.games/h5/polygami/play.html",
      };
    case "candyland":
      return {
        sceneId: "6465ddd631133501c36e3d96",
        path: "https://central.hackney.games/h5/candyland/play.html",
      };
    case "endlessSiegeGamee":
      return {
        sceneId: "653b7fbc6668bb1c4fba547e",
        path: "https://central.hackney.games/h5/endless-siege-gamee/play.html",
      };
    case "endlessSiege2":
      return {
        sceneId: "6525358630ad216e8f709d0a",
        path: "https://central.hackney.games/h5/endless-siege-2/play.html",
      };
    case "furiousRoad":
      return {
        sceneId: "649ad0767daf00e53abd2fff",
        path: "https://central.hackney.games/h5/furious-road/play.html",
      };
    case "memoryMatch":
      return {
        sceneId: "64a6c8eb7b0518e0050963f9",
        path: "https://central.hackney.games/h5/memory-match/play.html",
      };
    case "dropAndMerge":
      return {
        sceneId: "64a7f5856cfcde3b5bc5cbd0",
        path: "https://central.hackney.games/h5/drop-and-merge/play.html",
      };
    case "boxTower":
      return {
        sceneId: "64afc9b0bc765c2d44342e01",
        path: "https://central.hackney.games/h5/box-tower/play.html",
      };
    case "kingSolitaire":
      return {
        sceneId: "64b16de71f8c6b06a4a5a12e",
        path: "https://central.hackney.games/h5/king-solitaire/play.html",
      };
    case "sudokuMaster":
      return {
        sceneId: "64b5021ea357f5003af6db34",
        path: "https://central.hackney.games/h5/sudoku-master/play.html",
      };
    case "riseUp":
      return {
        sceneId: "64b5b7d9ad259cd82ed9b7e3",
        path: "https://central.hackney.games/h5/rise-up/play.html",
      };
    case "zombieAttack":
      return {
        sceneId: "64b671ab416ec38ef0b83647",
        path: "https://central.hackney.games/h5/zombie-attack/play.html",
      };
    case "desertWarrior":
      return {
        sceneId: "64b938bff07e42a8c392ee35",
        path: "https://central.hackney.games/h5/desert-warrior/play.html",
      };
    case "formulaRush":
      return {
        sceneId: "64be9427e3148c4c8a8d01b6",
        path: "https://central.hackney.games/h5/formula-rush/play.html",
      };
    case "rudeRaces":
      return {
        sceneId: "64c393c3024733e24dc4b541",
        path: "https://central.hackney.games/h5/rude-races/play.html",
      };
    case "crazyFishing":
      return {
        sceneId: "64d4b5e21c046bc4568edca7",
        path: "https://central.hackney.games/h5/crazy-fishing/play.html",
      };
    case "slingTomb":
      return {
        sceneId: "64dcd6d1bfe11df318d9bb5e",
        path: "https://central.hackney.games/h5/sling-tomb/play.html",
      };
    case "coinMatch":
      return {
        sceneId: "64e885313d8530747ddb2dd8",
        path: "https://central.hackney.games/h5/coin-match/play.html",
      };
    case "hangman":
      return {
        sceneId: "64ed18bd1479f2c06b33492a",
        path: "https://central.hackney.games/h5/hangman/play.html",
      };
    case "dungeon2048":
      return {
        sceneId: "64edff22a73b56a0ef78ada5",
        path: "https://central.hackney.games/h5/dungeon2048/play.html",
      };
    case "wordCake":
      return {
        sceneId: "653265f857f5c7a4f3dae12e",
        path: "https://central.hackney.games/h5/word-cake/play.html",
      };
    case "battalionCommander":
      return {
        sceneId: "65426566fb5bbd698597aada",
        path: "https://central.hackney.games/h5/battalion-commander/play.html",
      };
    case "battleship":
      return {
        sceneId: "6514367c926a8fbc3f929c85",
        path: "/games/battleship/index.html",
        multiplayer: true,
      };
    case "chess":
      return {
        sceneId: "6552430d153958b128637b2f",
        path: "/games/chess/index.html",
        multiplayer: true,
      };
    case "chessSingle":
      return {
        sceneId: "6562244561b4c95f55d6bdd9",
        path: "/games/chess/index_single.html",
        multiplayer: false,
      };
    default:
      return {
        sceneId: "6562244561b4c95f55d6bdd9",
        path: "/games/chess/index_single.html",
        multiplayer: false,
      };
  }
};
