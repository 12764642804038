import { memo, ReactNode, Suspense, useRef } from "react";
import { useStoreMap } from "effector-react";
import IAudioAsset from "~/types/IAudioAsset";
import { EnabledStatus } from "~/types/EnabledStatus";
import { AudioContext } from "~/view-scene/runtime";
import { $audioManager } from "~/view-scene/audio";
import { Entity } from "../Entity";
import { RenderAudio } from "./RenderAudio";
import IAudioSceneObject from "~/types/IAudioSceneObject";
import { useAsset } from "~/entities/assets";
import { useEntity } from "~/view-scene/utils/useEntity";

type AudioEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const AudioEntity = memo(({ entityId, children }: AudioEntityProps) => {
  const entity = useEntity<IAudioSceneObject>(entityId);
  const { asset: assetId, enabled } = entity;
  const contextRef = useRef({} as AudioContext);
  const asset = useAsset<IAudioAsset>(assetId);
  const isReady = useStoreMap($audioManager, (audioManager) => audioManager.isReady);
  const audioUrl = asset?.url;

  if (enabled !== EnabledStatus.enabled || !isReady || !audioUrl) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Entity entityId={entityId} context={contextRef}>
        <RenderAudio dto={entity} audioUrl={audioUrl} contextRef={contextRef} />
        {children}
      </Entity>
    </Suspense>
  );
});
