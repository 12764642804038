import { memo, useMemo } from "react";
import { BufferGeometry, Color, FloatType, Points, PointsMaterial, Texture, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { SnowSceneObject } from "~/types/SnowSceneObject";

const textureSize = 64.0;

type RenderSnowProps = {
  entity: SnowSceneObject;
};

export const RenderSnow = memo(({ entity }: RenderSnowProps) => {
  const { count, radius, groundLevel, speed, size, color } = entity;

  const snowParticles = useMemo(() => {
    const particles = [...Array(count).keys()].map(
      () =>
        new Vector3(
          randomNumber(radius * -1, radius),
          randomNumber(groundLevel, radius),
          randomNumber(radius * -1, radius)
        )
    );

    const snowGeometry = new BufferGeometry().setFromPoints(particles);

    const snowMaterial = new PointsMaterial({
      size: size,
      color: new Color(color),
      vertexColors: false,
      map: getTexture(),
      transparent: true,
      opacity: 0.8,
      fog: true,
      depthWrite: false,
    });

    return new Points(snowGeometry, snowMaterial);
  }, [count, radius, groundLevel, size, color]);

  const velocities = useMemo(
    () =>
      [...Array(count).keys()].map(
        () =>
          new Vector3(
            Math.floor(Math.random() * 9 - 4.5) * 0.01 * speed,
            Math.floor(Math.random() * 10 + 3) * -0.01 * speed,
            Math.floor(Math.random() * 6 - 3) * 0.01 * speed
          )
      ),
    [count, speed]
  );

  useFrame(({ clock: { elapsedTime } }) => {
    const positions = snowParticles.geometry.attributes.position.array as Array<number>;

    velocities.forEach((velocity, i) => {
      const velX = Math.sin(elapsedTime) * velocity.x;
      const velZ = Math.cos(elapsedTime) * velocity.z;

      const offset = i * 3;
      positions[offset] = positions[offset] + velX;
      positions[offset + 1] = positions[offset + 1] + velocity.y;
      positions[offset + 2] = positions[offset + 2] + velZ;

      if (positions[offset + 1] < groundLevel) {
        positions[offset + 1] = radius;
      }
    });

    snowParticles.geometry.attributes.position.needsUpdate = true;
  });

  return <primitive object={snowParticles} />;
});

const drawRadialGradation = (ctx: CanvasRenderingContext2D, canvasRadius: number, canvasW: number, canvasH: number) => {
  ctx.save();
  const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
  gradient.addColorStop(0, "rgba(255,255,255,1.0)");
  gradient.addColorStop(0.5, "rgba(255,255,255,0.5)");
  gradient.addColorStop(1, "rgba(255,255,255,0)");
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasW, canvasH);
  ctx.restore();
};

const getTexture = () => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d")!;

  const diameter = textureSize;
  canvas.width = diameter;
  canvas.height = diameter;
  const canvasRadius = diameter / 2;

  drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.type = FloatType;
  texture.needsUpdate = true;
  return texture;
};

const randomNumber = (min: number, max: number) => Math.random() * (max - min) + min;
