import { Icon, IconProps } from "./Icon";

export type DiscordProps = IconProps;

export const Discord = (props: DiscordProps) => (
  <Icon width="48px" height="48px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
    <path
      d="M17 34.5L15 38.5C12.4525 37.4385 9.8145 36.377 7.01 34.55C6.032 33.9127 5.439 32.83 5.4155 31.663C5.2935 25.672 6.699 19.649 10.2578 13.281C10.7235 12.4475 11.4995 11.8365 12.3995 11.517C14.5465 10.755 16.004 10.166 18.5 9.75L20 12.5C20 12.5 21.5 12 24 12C26.5 12 28 12.5 28 12.5L29.5 9.75C31.996 10.166 33.4535 10.755 35.6005 11.517C36.5005 11.8365 37.2765 12.4475 37.7422 13.281C41.301 19.649 42.7065 25.672 42.5848 31.663C42.561 32.83 41.968 33.9127 40.99 34.55C38.1855 36.377 35.5477 37.4385 33 38.5L31 34.5M14 32.5C14 32.5 19 35 24 35C29 35 34 32.5 34 32.5"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.75 29C19.5449 29 21 27.3211 21 25.25C21 23.1789 19.5449 21.5 17.75 21.5C15.9551 21.5 14.5 23.1789 14.5 25.25C14.5 27.3211 15.9551 29 17.75 29Z"
      fill="white"
    />
    <path
      d="M30.25 29C32.0449 29 33.5 27.3211 33.5 25.25C33.5 23.1789 32.0449 21.5 30.25 21.5C28.4551 21.5 27 23.1789 27 25.25C27 27.3211 28.4551 29 30.25 29Z"
      fill="white"
    />
  </Icon>
);
