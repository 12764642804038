import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntitySocket, FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntityValue } from "../../values";

export class CollisionStartNode extends BaseNode {
  static readonly type = "event/collisionStart";
  static readonly label = "Collision start";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow"), new EntitySocket("entity")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onCollisionStartEvent = (entityId: string) => {
      node.storage.output.entityId = entityId;
      context.writeOutput<EntityValue>("entity", node.storage.output);
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.collisionStart.addListener(onCollisionStartEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.collisionStart.removeListener(onCollisionStartEvent);
    });
  }
}
