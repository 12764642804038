import IComponent from "./IComponent";
import { RigidBodyType } from "./RigidBodyType";

export default interface IRigidBodyComponent extends IComponent {
  rigidBodyType: RigidBodyType;
  ghost: boolean;
  mass: number;
  activationState?: ActivationState;
  collisionGroup?: number;
  collisionMask?: number;
  body?: Body;
  attachTo: string | null;
  enableNetworkSync?: boolean;
}

export enum ActivationState {
  DEACTIVATE = 0,
  ENABLE_DEACTIVATION = 1,
  DISABLE_DEACTIVATION = 4,
}

type BodyAuto = {
  type: "auto";
};

type BodyAsset = {
  type: "asset";
  assetId?: string;
  variantStorageId?: string;
};

type BodyBoundingBox = {
  type: "boundingBox";
};

type BodyBoundingSphere = {
  type: "boundingSphere";
};

export type Body = BodyAuto | BodyAsset | BodyBoundingBox | BodyBoundingSphere;
