import { NodeContext } from "../../base";
import { BooleanOperationNode } from "./BooleanOperationNode";

export class BooleanEqualNode extends BooleanOperationNode {
  static readonly type = "logic/booleanEqual";
  static readonly label = "Boolean =";
  static readonly pure = true;

  constructor(context: NodeContext) {
    super(context, (a, b) => a === b);
  }
}
