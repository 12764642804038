import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class PlayerAnimationStopNode extends BaseNode {
  static readonly type = "action/playerAnimationStop";
  static readonly label = "Stop Player Animation";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(_context: NodeEvalContext, node: BaseBehaveNode) {
    const avatarAnimationId = node.readMetadata("avatarAnimationId", null);
    if (!avatarAnimationId) {
      return;
    }

    node.sceneContext.stopPlayerAnimation(avatarAnimationId);
  }
}
