import create from "zustand";
import { requestMicrophone } from "./utils";
import { Permission, PermissionCondition } from "./types";
import { isIOS } from "~/common/utils/isMobile";

const permissions: Permission[] = ["microphone"];

export type UsePermissionsType = {
  permissions: Record<Permission, PermissionCondition | null>;
  init: () => Promise<void>;
  setPermissionState: (permissionName: Permission, state: PermissionCondition | null) => void;
  requestPermission: (permission: Permission) => Promise<PermissionCondition>;
  requestPermissions: () => Promise<void>;
};

export const usePermissions = create<UsePermissionsType>((set, get) => ({
  permissions: {
    microphone: null,
  },
  init: async () => {
    for (const permission of permissions) {
      if (isIOS()) {
        get().setPermissionState(permission, "prompt");
      } else {
        // @ts-ignore
        navigator.permissions.query({ name: permission }).then(function (permissionStatus) {
          get().setPermissionState(permission, permissionStatus.state);
        });
      }
    }
  },
  setPermissionState: (permissionName: Permission, state: PermissionCondition | null) => {
    set({
      permissions: {
        ...get().permissions,
        [permissionName]: state,
      },
    });
  },
  requestPermission: async (permission: Permission) => {
    switch (permission) {
      case "microphone":
        const permissionState = await requestMicrophone();
        get().setPermissionState("microphone", permissionState);
        return permissionState;
    }
  },
  requestPermissions: async () => {
    for (const key in get().permissions) {
      const permission = key as Permission;
      const status = get().permissions[permission];

      if (status === null || status === "prompt") {
        get()
          .requestPermission(permission)
          .then((state) => {
            get().setPermissionState(permission, state);
          });
      }
    }
  },
}));

export const permissionStatusSelector = (permission: Permission) => (state: UsePermissionsType) => {
  return state.permissions[permission];
};
