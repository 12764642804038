import { Texture } from "three";
import { useMemo } from "react";

export function useTextureAspect(
  texture: Texture | null,
  horizontalFrames: number = 1,
  verticalFrames = 1
): [number, number, number] {
  return useMemo(() => {
    const textureImage = texture?.image;
    if (!textureImage) {
      return [1, 1, 1];
    }
    const height = textureImage.height / verticalFrames;
    const width = textureImage.width / horizontalFrames;

    if (width > height) {
      const imageAspect = width / height;
      return [imageAspect, 1, 1];
    } else {
      const imageAspect = height / width;
      return [1, imageAspect, 1];
    }
  }, [texture, horizontalFrames, verticalFrames]);
}
