import * as nodes from "./nodes";

import { BaseNodeClass } from "./base";

Object.values(nodes) as BaseNodeClass[];

export const availableNodes: BaseNodeClass[] = (Object.values(nodes) as BaseNodeClass[]).filter(
  (node) => typeof node === "function" && node.isNode
);
export const availableNodesMap = availableNodes.reduce((acc, NodeClass) => {
  acc[NodeClass.type] = NodeClass;

  return acc;
}, {} as Record<string, BaseNodeClass>);
