import { NodeEvalContext } from "~/libs/behave-graph";
import { AnimationComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnimationSocket, FlowSocket } from "../../sockets";

export class AnimationPlayNode extends BaseNode {
  static readonly type = "action/animationPlay";
  static readonly label = "Play animation";

  async = true;

  inputs = [new FlowSocket("flow"), new AnimationSocket("animation")];
  outputs = [new FlowSocket("flow"), new FlowSocket("onFinish")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const animationComponent = node.readComponent<AnimationComponentContext>(context, "animation");
    let canceled = false;

    animationComponent?.play();
    context.commit("flow");

    if (animationComponent && node.outputConnected("onFinish")) {
      animationComponent.events.once("finish", () => {
        if (canceled) {
          return;
        }

        context.commit("onFinish");
        context.graphEvaluator.executeAll();
      });
    }
  }
}
