import { memo, Suspense } from "react";
import { NearbyRemoteUserAvatar } from "./NearbyRemoteUserAvatar";
import { useStoreMap } from "effector-react";
import { $audioManager } from "~/view-scene/audio";
import { ErrorBoundary } from "~/view-scene/utils";
import { useMediaState } from "~/view-scene/media";
import { NearbyRemoteUserAudio } from "./NearbyRemoteUserAudio";

type NearbyRemoteUserProps = {
  userId: string;
};

export const NearbyRemoteUser = memo(({ userId }: NearbyRemoteUserProps) => {
  const isReady = useStoreMap($audioManager, (audioManager) => audioManager.isReady);
  const voiceChatActive = useMediaState((state) => state.voiceChatActive);

  const enableRemoteUserAudio = isReady && voiceChatActive;

  return (
    <ErrorBoundary message={`Failed rendering NearbyRemoteUser[${userId}]`}>
      <Suspense fallback={null}>
        <NearbyRemoteUserAvatar userId={userId}>
          {enableRemoteUserAudio && <NearbyRemoteUserAudio userId={userId} />}
        </NearbyRemoteUserAvatar>
      </Suspense>
    </ErrorBoundary>
  );
});
