import { API_HOST } from "~/config";
import { Storage, StorageData } from "~/types/Storage";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

export const getStorage = async (storageId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/storages/${storageId}`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();
  return { status, body: responseBody as { storage: Storage } };
};

export const getStorages = async (workspaceId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/storages?workspaceId=${workspaceId}`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();
  return { status, body: responseBody as { storages: Storage[] } };
};

export const getStoragesValues = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/storages/values`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();
  return { status, body: responseBody as { storageData: StorageData[] } };
};

export const resetStorages = async (workspaceId: string) => {
  const body = JSON.stringify({
    workspaceId,
  });
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/storages/reset`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();
  return { status, body: responseBody as { modifiedUsers: number } };
};
