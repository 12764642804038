import { createEvent, sample } from "effector";
import { $uiSettings, updateUISettings } from "./models";

export const $hierarchyPanelOpen = $uiSettings.map((settings) => settings?.panels.hierarchy.open ?? true);
export const $inspectorPanelOpen = $uiSettings.map((settings) => settings?.panels.inspector.open ?? true);

export const toggleHierarchyPanel = createEvent<boolean | undefined>();

sample({
  source: $uiSettings,
  clock: toggleHierarchyPanel,
  fn: (uiSettings, flag) => {
    if (!uiSettings) {
      return {};
    }

    return {
      panels: {
        ...uiSettings.panels,
        hierarchy: {
          ...uiSettings.panels.hierarchy,
          open: flag ?? !uiSettings.panels.hierarchy.open,
        },
      },
    };
  },
  target: updateUISettings,
});

export const toggleInspectorPanel = createEvent<boolean | undefined>();

sample({
  source: $uiSettings,
  clock: toggleInspectorPanel,
  fn: (uiSettings, flag) => {
    if (!uiSettings) {
      return {};
    }

    return {
      panels: {
        ...uiSettings.panels,
        inspector: {
          ...uiSettings.panels.inspector,
          open: flag ?? !uiSettings.panels.inspector.open,
        },
      },
    };
  },
  target: updateUISettings,
});
