import { NodeEvalContext } from "~/libs/behave-graph";
import { MapValue } from "~/types/Variable";
import { BaseNode } from "../../base";
import { AnySocket, FlowSocket, MapSocket, StringSocket } from "../../sockets";
import { anyValue } from "../../values";

export class MapSetNode extends BaseNode {
  static readonly type = "map/set";
  static readonly label = "Map Set";

  inputs = [new FlowSocket(), new MapSocket("map", anyValue.name), new StringSocket("key"), new AnySocket("value")];
  outputs = [new FlowSocket(), new MapSocket("map", anyValue.name)];

  eval(context: NodeEvalContext) {
    const map = context.readInput<MapValue>("map");
    const value = context.readInput<any>("value");
    const key = context.readInput<string>("key");

    context.writeOutput<MapValue>("map", {
      ...map,
      [key]: value,
    });
  }
}
