import { defaultAsset } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { TextContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { StringSocket, TextSocket } from "../../sockets";

export class TextContentNode extends BaseNode {
  static readonly type = "action/textContent";
  static readonly label = "Get content";

  inputs = [new TextSocket("text")];
  outputs = [new StringSocket("content")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultAsset();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const text = node.readEntity<TextContext>(context, "text");
    const content = text?.getContent() ?? "";

    context.writeOutput<string>("content", content);
  }
}
