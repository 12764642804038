import { defaultUIEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseSocket, BaseNode, NodeContext } from "../../base";
import { UIEntityValue } from "../../values";
import { FlowSocket, UIEntitySocket } from "../../sockets";

export class CastToUIEntityNode extends BaseNode {
  static readonly utility = true;

  inputs = [new FlowSocket("flow"), new UIEntitySocket("uiEntity")];

  constructor(context: NodeContext, private uiEntityType: string, private socket: BaseSocket) {
    super(context);
    this.outputs = [new FlowSocket("success"), new FlowSocket("fail"), socket];
  }

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultUIEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = null;

    const entityValue = context.readInput<UIEntityValue>("uiEntity");

    if (!entityValue?.entityId) {
      context.writeOutput(this.socket.name, node.storage.output);
      context.commit("fail");
      return;
    }

    const uiEntity = node.sceneContext.getUIEntityContext(entityValue.entityId);

    if (!uiEntity || uiEntity.type !== this.uiEntityType) {
      context.writeOutput(this.socket.name, node.storage.output);
      context.commit("fail");
      return;
    }

    node.storage.output.entityId = entityValue.entityId;
    context.writeOutput(this.socket.name, node.storage.output);
    context.commit("success");
  }
}
