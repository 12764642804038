import { BODY_DATA_LENGTH, BufferState, HEADER_LENGTH } from "./constants";
import { Data } from "./Data";
import { DataMessage, IDataMessenger } from "./IDataMessenger";
import { calcBytesSize } from "./utils";

export class ArrayBufferData extends Data {
  arrayBuffer: ArrayBuffer;

  constructor(maxBodies: number, private messenger: IDataMessenger) {
    super();
    this.maxBodies = maxBodies;
    this.arrayBuffer = new ArrayBuffer(calcBytesSize(maxBodies));
    this.initWrapperArrays();

    this.messenger.on("data", this.handleDataMessage);
  }

  ready() {
    this.setBufferState(BufferState.READY);
    this.messenger.sendData({ buffer: this.arrayBuffer });
  }

  consume() {
    this.setBufferState(BufferState.CONSUMED);
    this.messenger.sendData({ buffer: this.arrayBuffer });
  }

  dispose() {
    this.messenger.off("data", this.handleDataMessage);
  }

  private handleDataMessage = (data: DataMessage["data"]) => {
    this.arrayBuffer = data.buffer;
    this.initWrapperArrays();
  };

  private initWrapperArrays() {
    this.headerFloatArray = new Float32Array(this.arrayBuffer, 0, HEADER_LENGTH);
    this.headerIntArray = new Int32Array(this.arrayBuffer, 0, HEADER_LENGTH);
    this.objectMatricesFloatArray = new Float32Array(
      this.arrayBuffer,
      HEADER_LENGTH * 4,
      BODY_DATA_LENGTH * this.maxBodies
    );
    this.objectMatricesIntArray = new Int32Array(
      this.arrayBuffer,
      HEADER_LENGTH * 4,
      BODY_DATA_LENGTH * this.maxBodies
    );
  }
}
