import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { createChart } from "./utils";
import { Chart } from "chart.js";
import { API_HOST } from "~/config";
import { AnalyticsParams } from "./TelegramAnalyticsPage";

export function RetentionChart({ gameId, cohortReferer }: AnalyticsParams) {
  const { status, isLoading, data } = useQuery({
    queryKey: `retention_${gameId}_${cohortReferer}`,
    queryFn: () => fetchData(gameId, cohortReferer),
  });

  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (status !== "success") {
      return;
    }

    const dauData = formatData(data);
    if (chartRef.current) {
      chartRef.current.data.labels = dauData.labels;
      chartRef.current.data.datasets = dauData.datasets;
      if (chartRef.current?.options.scales?.y) {
        chartRef.current.options.scales.y.max = Math.floor(data.maxUniqueUsers + data.maxUniqueUsers / 10);
      }
      chartRef.current.update();
    } else {
      chartRef.current = createChart("retentionChart", dauData);
    }
  }, [status, data]);

  return <canvas id="retentionChart"></canvas>;
}

type RetentionData = {
  Day: number;
  Retention: string;
}[];

function formatData(data: RetentionData) {
  if (!data) {
    return { labels: [], datasets: [], maxUniqueUsers: 0 };
  }

  const labels = data.map((d) => `Day ${d.Day}`);
  const retentionRates = data.map((d) => parseFloat(d.Retention.replace("%", "")));

  const maxUniqueUsers = Math.max(...retentionRates);

  const datasets = [
    {
      label: "Retention Rate",
      data: retentionRates,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
  ];

  return { labels, datasets, maxUniqueUsers };
}

async function fetchData(gameId: string, cohortReferer?: string) {
  let getLink = `${API_HOST}/api/events/${gameId}/retention`;
  if (cohortReferer) getLink += `?cohortReferer=${cohortReferer}`;

  const response = await fetch(getLink, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": "fsdjkalfh98370[2h-9hr1p'/1;m12lkdi",
    },
  });

  if (!response.ok) {
    throw new Error("Failed loading reviews");
  }

  return await response.json();
}
