import { Stack } from "@mui/material";
import { AlignCenter, AlignJustify, AlignLeft, AlignRight } from "~/common/components/Icon";
import { Select, SelectProps, SelectItem } from "./Select";

export type TextAlignProps = Omit<SelectProps, "children">;

const items = [
  {
    value: "left",
    label: "Left",
    icon: <AlignLeft />,
  },
  {
    value: "center",
    label: "Center",
    icon: <AlignCenter />,
  },
  {
    value: "right",
    label: "Right",
    icon: <AlignRight />,
  },
  {
    value: "justify",
    label: "Justify",
    icon: <AlignJustify />,
  },
];

export const TextAlign = (props: TextAlignProps) => {
  return (
    <Select {...props}>
      {items.map((item) => (
        <SelectItem key={item.value} value={item.value}>
          <Stack direction="row" sx={{ gap: "4px" }} alignItems="center" justifyContent="flex-start">
            {item.icon}
            {item.label}
          </Stack>
        </SelectItem>
      ))}
    </Select>
  );
};
