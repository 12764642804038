import { Interactive } from "@react-three/xr";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Euler, Vector3 } from "three";
import { useThree } from "@react-three/fiber";
import { video360Controls } from "~/view-scene/ControlsSystem";
import { calcPositionInfront } from "~/common/utils/calcPositionInfront";
import { PlayPauseButton } from "./PlayPauseButton";
import { Progress } from "./Progress";
import { LeaveButton } from "./LeaveButton";
import { video360Layers } from "~/view-scene/layers";
import { Container } from "./Container";
import { useUnit } from "effector-react";
import { $currentVideo360 } from "~/view-scene/runtime";

type ControlsTransform = {
  position: Vector3;
  rotation: Euler;
};

export const Video360Interface = memo(() => {
  const [controlsTransform, setControlsTransform] = useState<ControlsTransform | null>(null);
  const controlsTransformRef = useRef(controlsTransform);
  controlsTransformRef.current = controlsTransform;
  const inControlsZone = useRef(false);
  const camera = useThree((state) => state.camera);
  const cameraRef = useRef(camera);
  cameraRef.current = camera;

  const currentVideo360 = useUnit($currentVideo360);

  const handleContainerHover = useCallback(() => {
    inControlsZone.current = true;
  }, []);

  const handleContainerBlur = useCallback(() => {
    inControlsZone.current = false;
  }, []);

  useEffect(() => {
    const handler = () => {
      if (!controlsTransformRef.current) {
        const position = calcPositionInfront(cameraRef.current, 1);
        const rotation = cameraRef.current.rotation.clone();

        setControlsTransform({
          position,
          rotation,
        });
      } else {
        if (!inControlsZone.current) {
          setControlsTransform(null);
        }
      }
    };

    video360Controls.toggleControls.on("start", handler);

    return () => {
      video360Controls.toggleControls.off("start", handler);
    };
  }, []);

  const allowControls = currentVideo360 && !currentVideo360.isStream;

  return controlsTransform ? (
    <Interactive onHover={handleContainerHover} onBlur={handleContainerBlur}>
      <group layers={video360Layers} position={controlsTransform.position} rotation={controlsTransform.rotation}>
        {allowControls && (
          <>
            <Container width={containerWidth} />
            <PlayPauseButton position={playButtonPosition} width={playButtonWidth} />
            <Progress position={progressPosition} width={progressWidth} />
          </>
        )}
        <LeaveButton position={leaveButtonPosition} width={leaveButtonWidth} />
      </group>
    </Interactive>
  ) : null;
});

const containerWidth = 1;
const containerHeight = containerWidth * 0.1;

const playButtonWidth = containerWidth * 0.06;
const playButtonMargin = containerWidth * 0.02;
const playButtonPosition = new Vector3(-containerWidth / 2 + playButtonWidth / 2 + playButtonMargin, 0, 0.01);

const progressWidth = containerWidth * 0.84;
const progressMarginLeft = containerWidth * 0.12;
const progressMarginTop = containerHeight * 0.046;
const progressPosition = new Vector3(
  -containerWidth / 2 + progressWidth / 2 + progressMarginLeft,
  progressMarginTop,
  0.01
);

const leaveButtonWidth = containerWidth * 0.1;
const leaveLeftMargin = containerWidth * 0.02;
const leaveButtonPosition = new Vector3(containerWidth / 2 + leaveLeftMargin + leaveButtonWidth / 2, 0, 0);
