import type { StyledComponent } from "@emotion/styled";
import { styled, Box, BoxProps } from "@mui/material";

export type IconProps = BoxProps<"svg"> & {
  size?: "small" | "medium" | "large";
};

// @ts-ignore
export const Icon = styled(({ size: _size, ...rest }: IconProps) => <Box component="svg" {...rest} />)`
  flex-shrink: 0;
  ${(props) => {
    switch (props.size) {
      case "small":
        return "width: 16px; height: 16px;";
      case "large":
        return "width: 28px; height: 28px;";
      default:
        return "width: 20px; height: 20px;";
    }
  }}
` as StyledComponent<IconProps>;
