import { NodeContext } from "../../base";
import { ModelSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToModelNode extends CastToNode {
  static readonly type = "logic/castToModel";
  static readonly label = "Cast to Model";

  constructor(context: NodeContext) {
    super(context, new ModelSocket("model"), "model");
  }
}
