import { defaultEntity } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, StringSocket, Vector3Socket } from "../../sockets";
import { EntityValue, Vector3Value } from "../../values";
import { Vector3 } from "three";

type Node = BaseBehaveNode<{ entity: EntityValue; position: Vector3 }>;

export class FindClosestEntityByTagNode extends BaseNode {
  static readonly type = "entity/findClosestEntityByTag";
  static readonly label = "Find Closest By Tag";

  inputs = [new Vector3Socket("position"), new StringSocket("tag")];
  outputs = [new EntitySocket("entity")];

  initNode(node: Node) {
    node.storage.entity = defaultEntity();
    node.storage.position = new Vector3();
  }

  eval(context: NodeEvalContext, node: Node) {
    const position = context.readInput<Vector3Value>("position");
    const tag = context.readInput<string>("tag");
    const entities = node.sceneContext.getEntityContexts().filter((entityContext) => entityContext.tags.has(tag));
    node.storage.position.set(position.x, position.y, position.z);
    let minDistance = Infinity;
    let closestEntityId = null;

    for (const entity of entities) {
      const distance = entity.rootObjectRef?.current?.position.distanceTo(node.storage.position) ?? Infinity;

      if (distance < minDistance) {
        minDistance = distance;
        closestEntityId = entity.id;
      }
    }

    node.storage.entity.entityId = closestEntityId;
    context.writeOutput<EntityValue>("entity", node.storage.entity);
  }
}
