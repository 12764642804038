import { useXR } from "@react-three/xr";
import { ReactNode, useMemo } from "react";
import { playerContext } from "./playerContext";
import { Group } from "three";
import { useThree } from "@react-three/fiber";

export type PlayerProviderProps = {
  xr: boolean;
  children?: ReactNode;
};

export const PlayerProvider = ({ xr, children }: PlayerProviderProps) => {
  if (xr) {
    return <XRProvider>{children}</XRProvider>;
  }

  return <CustomPlayerProvider>{children}</CustomPlayerProvider>;
};

type ProviderProps = {
  children?: ReactNode;
};

const XRProvider = ({ children }: ProviderProps) => {
  const player = useXR((state) => state.player);

  const contextValue = useMemo(() => {
    return {
      player,
    };
  }, []);

  return <playerContext.Provider value={contextValue}>{children}</playerContext.Provider>;
};

const CustomPlayerProvider = ({ children }: ProviderProps) => {
  const scene = useThree((state) => state.scene);

  const contextValue = useMemo(() => {
    const group = new Group();
    scene.add(group);

    return {
      player: group,
    };
  }, []);

  return <playerContext.Provider value={contextValue}>{children}</playerContext.Provider>;
};
