import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class GetMovementDirectionNode extends BaseNode {
  static readonly type = "action/getMovementDirection";
  static readonly label = "Get Movement Direction";

  outputs = [new NumberSocket("forward"), new NumberSocket("side")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const move = node.sceneContext.playerControls.move;

    context.writeOutput<number>("forward", move.forward);
    context.writeOutput<number>("side", move.side);
  }
}
