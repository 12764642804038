import { createEvent } from "effector";
import { Update } from "~/common/typeUtils/update";
import ISceneObject from "~/types/ISceneObject";
import IAsset from "~/types/IAsset";
import { AsyncProcess } from "~/api/asyncProcesses.api";
import { OnlineUser } from "~/api/builderOnlineUsersApi";
import IComponent from "~/types/IComponent";
import { SceneConfig } from "~/types/IScene";
import { Storage } from "~/types/Storage";

export const connected = createEvent<string>();
export const disconnected = createEvent();

export const sceneConfigUpdate = createEvent<SceneConfig>();

export const entityAdd = createEvent<ISceneObject>();
export const entityUpdate = createEvent<Update<ISceneObject>>();
export const entityDelete = createEvent<string>();

export const entityComponentAdd = createEvent<{ objectId: string; component: IComponent }>();
export const entityComponentUpdate = createEvent<{ objectId: string; component: IComponent }>();
export const entityComponentDelete = createEvent<{ objectId: string; componentId: string }>();

export const assetAdd = createEvent<IAsset>();
export const assetUpdate = createEvent<Update<IAsset>>();
export const assetDelete = createEvent<IAsset>();

export const storageAdd = createEvent<Storage>();
export const storageUpdate = createEvent<Update<Storage>>();
export const storageDelete = createEvent<{ id: string }>();

export const asyncProcessStarted = createEvent<AsyncProcess>();
export const asyncProcessCompleted = createEvent<AsyncProcess>();

export const onlineUserAdd = createEvent<OnlineUser>();
export const onlineUserDelete = createEvent<string>();
