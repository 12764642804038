import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { setReferer } from "./referer";

export function useRefererTracker() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const referer = searchParams.get("referer");
    if (referer) {
      setReferer(referer);
      searchParams.delete("referer");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
}
