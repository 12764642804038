import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, SocketsArray } from "../../base";
import { NumberSocket } from "../../sockets";

const labels = ["c", "d", "e", "f", "g", "h", "i", "j"];

export class NumberSumNode extends BaseNode {
  static readonly type = "logic/numberSum";
  static readonly label = "Number +";
  static readonly category: NodeCategory = "Logic";

  inputs = [
    new NumberSocket("a"),
    new NumberSocket("b"),
    new SocketsArray({
      id: "nums",
      SocketConstructor: NumberSocket,
      maxCount: labels.length,
      nameGenerator: (_id, index) => labels[index],
    }),
  ];
  outputs = [new NumberSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    let res = 0;

    for (const socket of node.inputSockets) {
      res += context.readInput<number>(socket.id);
    }

    context.writeOutput<number>("result", res);
  }
}
