import { useFrame } from "@react-three/fiber";
import { yukaManagerRef } from "./models";

export function YukaSystem() {
  useFrame((_, delta) => {
    yukaManagerRef.current?.update(delta);
  });

  return null;
}
