import { Object3D, Box3, Vector3 } from "three";

const v = new Vector3();

export const getBoundingBox = (object: Object3D) => {
  object.getWorldPosition(v); // probably this line fix setFromObject issue
  const box = new Box3().setFromObject(object, true);
  const dimensions = new Vector3().subVectors(box.max, box.min);
  const worldPosition = new Vector3().addVectors(box.min, box.max).multiplyScalar(0.5);

  return {
    box: {
      width: dimensions.x,
      height: dimensions.y,
      depth: dimensions.z,
    },
    worldPosition,
  };
};
