import { combine } from "effector";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { $itemsLoadProgress } from "./loadItems";
import { $resourcesLoadProgress } from "./loadResources";

const threshold = 99.9;
let intrvlId: any;
let initSec = 0;
let fkPrgrs = 0;

export const $progress = combine(
  $resourcesLoadProgress,
  $itemsLoadProgress,
  (resourcesLoadProgress, itemsLoadProgress) => {
    const percent = resourcesLoadProgress * 0.97 + itemsLoadProgress * 0.03;

    // Hack to fix the second load of the same scene
    if (itemsLoadProgress === 100 && resourcesLoadProgress === 0) {
      return 100;
    }

    return percent < threshold ? percent : 100;
  }
);

function intvl() {
  if (initSec >= 10) {
    clearInterval(intrvlId);
    return;
  }
  initSec++;
  fkPrgrs = ((2 ** initSec - 1) / 2 ** initSec) * 100;
}

export const fakeProgress = () => {
  if (!intrvlId) intrvlId = setInterval(intvl, 1000);
  return fkPrgrs;
};

$progress.watch((progress) => {
  if (progress === 100) {
    useSessionStatus.getState().updateStatus("loaded");
    if (intrvlId) clearInterval(intrvlId);
  }
});
