import { useMemo, useRef, useState } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import IModelSceneObject from "~/types/IModelSceneObject";
import { ModelContext, useEntityContext } from "~/view-scene/runtime";
import { Lod } from "./RenderLod";
import { findModel, getChild } from "./utils";

export const useLods = (dto: IModelSceneObject): Lod[] => {
  const getModelUrl = useSceneData((state) => state.getModelUrl);

  return useMemo(() => {
    const lodsArr = dto.lods ?? [];

    const lods: Lod[] = [
      {
        modelUrl: getModelUrl(dto.asset, dto.variantId),
        distance: 0,
      },
      ...lodsArr.map((lod) => ({
        modelUrl: getModelUrl(lod.asset, lod.variantId),
        distance: lod.distance ?? 0,
      })),
    ];

    return lods.sort((a, b) => a.distance - b.distance);
  }, [dto, getModelUrl]);
};

export const useContext = (configDto: IModelSceneObject) => {
  const [dto, updateDto] = useDto(configDto);
  const contextRef = useRef({} as ModelContext);

  useEntityContext(
    contextRef,
    () => ({
      type: "model" as const,
      getMaterial: () => {
        if (!dto.overrideMaterial) {
          return null;
        }

        return dto.material ?? null;
      },
      setMaterial: (assetId) => {
        if (assetId) {
          updateDto({
            overrideMaterial: true,
            material: assetId,
          });
        } else {
          updateDto({
            overrideMaterial: false,
            material: undefined,
          });
        }
      },
      getModel: () => {
        if (!dto.asset) {
          return null;
        }

        return {
          assetId: dto.asset,
          variantId: dto.variantId ?? null,
        };
      },
      setModel: (assetId, variantId) => {
        updateDto({
          asset: assetId ?? undefined,
          variantId: variantId ?? undefined,
        });
      },
      getChild: (id) => {
        const object = contextRef.current.rootObjectRef.current;

        if (!object) {
          return null;
        }

        const model = findModel(object, contextRef.current.id);

        return model ? getChild(model, parseInt(id)) ?? null : null;
      },
    }),
    [dto]
  );

  return {
    dto,
    contextRef,
  };
};

export const useDto = (configDto: IModelSceneObject) => {
  const [dtoOverride, setDtoOverride] = useState<Partial<IModelSceneObject>>({});

  const dto = useMemo(() => {
    return {
      ...configDto,
      ...dtoOverride,
    };
  }, [configDto, dtoOverride]);

  return [dto, setDtoOverride] as const;
};
