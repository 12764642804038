import { createEvent, createStore } from "effector";
import { Object3D } from "three";
import { PLAYER_HEIGHT } from "~/config";
import { CameraMode } from "~/types/ControlsSystem";
import { Vector2 } from "~/types/Vector2";
import { resetCameraSystem } from "./reset";

export const firstPersonCameraRadius = 0.0001;
const thirdPersonCameraRadius = PLAYER_HEIGHT; // aprox. player height, or we need to change radius dynamically based on phi

export type CameraStateType = {
  phi: number;
  theta: number;
  radius: number;
  cameraMode: CameraMode;
  enabled: boolean;
  sensitivity: Vector2;
  target?: Object3D;

  thirdPersonCameraRadius: number;
  maxPhi: number;
  minPhi: number;
  lockPhi: boolean;
  lockTheta: boolean;
  switchCameraMode: boolean;
};

export const setCameraState = createEvent<Partial<CameraStateType>>();

export const requestCameraZoomIn = createEvent();
export const requestCameraZoomOut = createEvent();

export const requestToggleCameraMode = createEvent();

export const requestCameraMovement = createEvent<{ side: number; forward: number }>();

export const setTheta = createEvent<number>();

export const setTarget = createEvent<Object3D>();
export const setEnabled = createEvent<boolean>();

export const $cameraState = createStore<CameraStateType>({
  theta: 0,
  phi: 0,
  enabled: true,
  radius: thirdPersonCameraRadius,

  lockPhi: false,
  lockTheta: false,
  switchCameraMode: true,
  maxPhi: (3 * Math.PI) / 8,
  minPhi: (-3 * Math.PI) / 8,
  sensitivity: {
    x: 0.01,
    y: 0.01,
  },
  thirdPersonCameraRadius: thirdPersonCameraRadius,
  cameraMode: "thirdPerson",
})
  .on(setCameraState, (state, newState) => ({
    ...state,
    ...newState,
  }))
  .on(setTarget, (state, target) => ({
    ...state,
    target,
  }))
  .on(setEnabled, (state, enabled) => ({
    ...state,
    enabled,
  }))
  .on(setTheta, (state, theta) => ({
    ...state,
    theta,
  }))
  .on(requestCameraZoomIn, (state) => {
    if (!state.switchCameraMode) {
      return;
    }

    return {
      ...state,
      radius: firstPersonCameraRadius,
      cameraMode: "firstPerson",
    };
  })
  .on(requestCameraZoomOut, (state) => {
    if (!state.switchCameraMode) {
      return;
    }

    return {
      ...state,
      radius: state.thirdPersonCameraRadius,
      cameraMode: "thirdPerson",
    };
  })
  .on(requestToggleCameraMode, (state) => {
    if (!state.switchCameraMode) {
      return;
    }

    const newCameraMode = state.cameraMode === "thirdPerson" ? "firstPerson" : "thirdPerson";
    return {
      ...state,
      radius: newCameraMode === "thirdPerson" ? state.thirdPersonCameraRadius : firstPersonCameraRadius,
      cameraMode: newCameraMode,
    };
  })
  .on(requestCameraMovement, (state, { side, forward }) => {
    let { theta, phi, sensitivity, minPhi, maxPhi, lockPhi, lockTheta } = state;

    if (lockPhi && lockTheta) {
      return;
    }

    if (!lockTheta) {
      theta -= (side / 0.1) * (sensitivity.x / 2);
    }

    if (!lockPhi) {
      phi += ((forward * -1) / 0.1) * (sensitivity.y / 2);
      phi = Math.min(maxPhi, Math.max(minPhi, phi));
    }

    return {
      ...state,
      phi,
      theta,
    };
  })
  .reset(resetCameraSystem);
