import { Select, SelectProps, SelectItem } from "./Select";
import { capitalize } from "lodash-es";

const items = ["regular", "bold"].map((weight) => ({
  value: weight,
  label: capitalize(weight),
}));

export type FontWeightProps = Omit<SelectProps, "children">;

export const FontWeight = (props: FontWeightProps) => {
  return (
    <Select {...props}>
      {items.map((item) => (
        <SelectItem key={item.value} value={item.value}>
          {item.label}
        </SelectItem>
      ))}
    </Select>
  );
};
