import { styled } from "@mui/material";

export const SectionLabel = styled("h4")`
  color: white;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
`;
