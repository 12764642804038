import { isDesktopSafari } from "~/common/utils/isDesktopSafari";
import isMobile from "~/common/utils/isMobile";

export function playHTMLMedia(media: HTMLVideoElement | HTMLAudioElement) {
  if (isMobile()) {
    document.addEventListener("touchstart", () => media.play(), { once: true });
  } else if (isDesktopSafari()) {
    document.addEventListener("keypress", () => media.play(), { once: true });
  } else {
    media.play();
  }
}
