import { createEvent, createStore } from "effector";
import { ReactNode } from "react";

export type Addon = {
  id: string;
  node: ReactNode;
};

export const addAddon = createEvent<Addon>();
export const removeAddon = createEvent<string>();

export const $addons = createStore<Addon[]>([])
  .on(addAddon, (state, addon) => [...state, addon])
  .on(removeAddon, (state, id) => state.filter((addon) => addon.id !== id));
