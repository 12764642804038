import { Icon, IconProps } from "./Icon";

export type EyeFilledProps = IconProps;

export const EyeFilled = (props: EyeFilledProps) => (
  <Icon width="24px" height="17px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17" {...props}>
    <path
      fill="currentcolor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.026 8.668c.047.2.13.33.296.588C1.694 11.394 5.777 16.8 12 16.8c6.223 0 10.306-5.406 11.678-7.544.166-.259.25-.388.296-.588.035-.15.035-.386 0-.536-.047-.2-.13-.329-.296-.588C22.306 5.406 18.223 0 12 0 5.777 0 1.694 5.406.322 7.544c-.166.259-.25.388-.296.588-.035.15-.035.386 0 .536ZM12 12a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z"
    />
  </Icon>
);
