import { createEvent, Event, forward } from "effector";
import { createUndoRedo } from "~/common/utils/undoRedo";

export const {
  undo: editorUndo,
  redo: editorRedo,
  $canUndo: $editorCanUndo,
  $canRedo: $editorCanRedo,
  register: editorUndoRedoRegister,
} = createUndoRedo();

export const editorUndoFactory = <TDo, TUndo>(
  onDo: Event<TDo> | Event<TDo>[],
  onUndo: Event<TUndo> | Event<TUndo>[],
  producer: (payload: TDo) => TUndo | undefined
) => {
  const event = createEvent<TDo>();

  const [undo, redo] = editorUndoRedoRegister(event, producer);

  forward({
    from: [event, redo],
    to: onDo,
  });

  forward({
    from: undo,
    to: onUndo,
  });

  return [event, undo, redo] as const;
};
