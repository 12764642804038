import { Fragment, memo, useEffect, useState } from "react";
import ThreeMeshUI from "../../libs/three-mesh-ui";
import ITwitterBoardSceneObject from "../../../types/ITwitterBoardSceneObject";
import { Block, BlockProps } from "../../ui/RenderUIElement/Block";

import type { Tweet } from "./models";
import { pixelRate } from "./constants";
import { TweetItem } from "./TweetItem";
import { Color } from "three";

export type TwitterBoardProps = BlockProps & {
  tweets: Tweet[];
  dto: ITwitterBoardSceneObject;
};

export const TwitterBoard = memo<TwitterBoardProps>(({ tweets, dto, ...restProps }) => {
  useEffect(() => {
    ThreeMeshUI.update();
  }, [tweets]);

  const padding = 16 * pixelRate;
  const tweetWidth = dto.width - padding * 2;
  const tweetMarginBottom = 16 * pixelRate;
  const tweetHeight = dto.height / dto.amount - tweetMarginBottom;

  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    setTrigger((v) => v + 1);
  }, [tweets, dto]);

  return (
    <Block
      key={trigger}
      width={dto.width}
      height={dto.height}
      contentDirection="row"
      alignItems="start"
      backgroundOpacity={0}
      backgroundColor={new Color("white")}
      fontFamily="Roboto"
      fontTexture="Roboto"
      {...restProps}
    >
      <Block key="padding-left" width={padding} height={dto.height} borderWidth={0} backgroundOpacity={0} />
      <Block width={tweetWidth} height={tweetHeight + tweetMarginBottom} borderWidth={0} backgroundOpacity={0}>
        {tweets.map((tweet) => (
          <Fragment key={tweet.id}>
            <TweetItem dto={dto} tweet={tweet} width={tweetWidth} height={tweetHeight} />
            <Block key="tweet-item-margin-bottom" height={tweetMarginBottom} width={tweetWidth} backgroundOpacity={0} />
          </Fragment>
        ))}
      </Block>
      <Block key="padding-right" width={padding} height={dto.height} borderWidth={0} backgroundOpacity={0} />
    </Block>
  );
});
