import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberAbsNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberAbs";
  static readonly label = "Number Abs";

  constructor(context: NodeContext) {
    super(context, (value) => Math.abs(value));
  }
}
