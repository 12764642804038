import { NodeEvalContext } from "~/libs/behave-graph";
import { AudioContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AudioSocket, FlowSocket, NumberSocket } from "../../sockets";

export class AudioSetTimeNode extends BaseNode {
  static readonly type = "action/audioSetTime";
  static readonly label = "Set audio current time";

  inputs = [new FlowSocket("flow"), new AudioSocket("audio"), new NumberSocket("time")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const audio = node.readEntity<AudioContext>(context, "audio");
    const time = context.readInput<number>("time");

    audio?.setCurrentTime(time);
  }
}
