import { NodeContext } from "../../base";
import { FollowTargetSocket } from "../../sockets/FollowTargetSocket";
import { SceneComponentNode } from "./SceneComponentNode";

export class SceneFollowTargetNode extends SceneComponentNode {
  static readonly type = "variable/sceneFollowTarget";
  static readonly label = "FollowTarget";

  constructor(context: NodeContext) {
    super(context, new FollowTargetSocket("follow"));
  }
}
