import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type ModelValue = EntityValue;

export const modelValue = new ValueType(
  "model",
  defaultEntity,
  (text: ModelValue) => text,
  (value: ModelValue) => value
);
