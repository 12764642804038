import { NodeEvalContext } from "~/libs/behave-graph";
import { ButtonConfig, HyperModalConfig, ImageConfig, TextConfig, hyperModalBridge } from "~/view-scene/hyperModal";
import { BaseBehaveNode, BaseNode, BaseSocket, SocketsArray } from "../../base";
import { AssetImageSocket, BooleanSocket, FlowSocket, StringSocket } from "../../sockets";
import IImageAsset from "~/types/IImageAsset";

export class HyperModalNode extends BaseNode {
  static readonly type = "hyper/modal";
  static readonly label = "Hyper Modal";

  async = true;

  inputs = [
    new FlowSocket("open"),
    new BooleanSocket("showCloseButton", "show close button"),
    new SocketsArray({
      id: "topTexts",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `top text ${index + 1}`,
    }),
    new SocketsArray({
      id: "imageAssets",
      SocketConstructor: AssetImageSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `image asset ${index + 1}`,
    }),
    new SocketsArray({
      id: "imageWidths",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `image width ${index + 1}`,
    }),
    new SocketsArray({
      id: "imageHeights",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `image height ${index + 1}`,
    }),
    new SocketsArray({
      id: "imageLabels",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `image label ${index + 1}`,
    }),
    new SocketsArray({
      id: "texts",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `text ${index + 1}`,
    }),
    new SocketsArray({
      id: "buttonLabels",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `button label ${index + 1}`,
    }),
    new SocketsArray({
      id: "buttonAppearances",
      SocketConstructor: StringSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `button appearance ${index + 1}`,
    }),
    new FlowSocket("close"),
  ];
  outputs = [
    new FlowSocket("onCloseClick", "on close click"),
    new SocketsArray({
      id: "buttonClicks",
      SocketConstructor: FlowSocket,
      maxCount: 5,
      nameGenerator: (_id, index) => `button click ${index + 1}`,
    }),
  ];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const close = context.readInput<boolean>("close");

    if (close) {
      hyperModalBridge.emit("close");
      return;
    }

    const showCloseButton = context.readInput<boolean>("showCloseButton");

    const topTextSockets = getInputArraySockets(node, "topTexts");
    const imageAssetSockets = getInputArraySockets<AssetImageSocket>(node, "imageAssets");
    const imageWidthSockets = getInputArraySockets(node, "imageWidths");
    const imageHeightSockets = getInputArraySockets(node, "imageHeights");
    const imageLabelSockets = getInputArraySockets(node, "imageLabels");
    const textSockets = getInputArraySockets(node, "texts");
    const buttonLabelSockets = getInputArraySockets(node, "buttonLabels");
    const buttonAppearanceSockets = getInputArraySockets(node, "buttonAppearances");

    const topTexts = topTextSockets.map((topTextSocket) => {
      const textConfig: TextConfig = {
        text: topTextSocket.value,
      };

      return textConfig;
    });

    const images = imageAssetSockets.map((assetSocket: AssetImageSocket, index) => {
      const assetId = assetSocket.value.assetId;
      const asset = node.sceneContext.getAsset(assetId);

      if (!asset) {
        throw new Error("Asset not found", assetId);
      }

      const src = (asset as IImageAsset).url;

      const widthSocket = imageWidthSockets[index];
      const width = widthSocket ? widthSocket.value : undefined;

      const heightSocket = imageHeightSockets[index];
      const height = heightSocket ? heightSocket.value : undefined;

      const labelSocket = imageLabelSockets[index];
      const label = labelSocket ? labelSocket.value : undefined;

      const imageConfig: ImageConfig = {
        src,
        label,
        width,
        height,
      };

      return imageConfig;
    });

    const texts = textSockets.map((textSocket) => {
      const textConfig: TextConfig = {
        text: textSocket.value,
      };

      return textConfig;
    });

    const buttons = buttonLabelSockets.map((buttonLabelSocket, index) => {
      const buttonAppearanceSocket = buttonAppearanceSockets[index];
      const buttonAppearance = buttonAppearanceSocket ? buttonAppearanceSocket.value : undefined;

      const buttonConfig: ButtonConfig = {
        label: buttonLabelSocket.value,
        appearance: buttonAppearance,
      };

      return buttonConfig;
    });

    const closeSockets = getOutputArraySockets(node, "buttonClicks");

    const modalConfig: HyperModalConfig = {
      showCloseButton: showCloseButton,
      topTexts: topTexts,
      images: images,
      texts: texts,
      buttons: buttons,
      onCloseClick: () => {
        context.commit("onCloseClick");
        context.graphEvaluator.executeAll();
      },
      onButtonClick: (index: number) => {
        const socket = closeSockets[index];

        if (!socket) {
          return;
        }

        context.commit(socket.id);
        context.graphEvaluator.executeAll();
      },
    };

    hyperModalBridge.emit("open", modalConfig);
  }
}

const getInputArraySockets = <T extends BaseSocket = BaseSocket>(node: BaseBehaveNode, id: string) =>
  node.inputSockets.filter((socket) => socket.producedBy && socket.producedBy.id === id) as T[];

const getOutputArraySockets = <T extends BaseSocket = BaseSocket>(node: BaseBehaveNode, id: string) =>
  node.outputSockets.filter((socket) => socket.producedBy && socket.producedBy.id === id) as T[];
