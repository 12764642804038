import { SphereGeometry } from "~/types/Geometry";
import { LabelGroup, SliderInput } from "~/hephaestus/components";
import { GeometryInspectorBaseProps } from "./types";
import { geometryChange } from "./utils";

export type SphereGeometryInspectorProps = GeometryInspectorBaseProps<SphereGeometry>;

export function SphereGeometryInspector({ geometry, onChange }: SphereGeometryInspectorProps) {
  const { radius, heightSegments, widthSegments } = geometry;

  return (
    <>
      <LabelGroup label="Radius">
        <SliderInput
          name="radius"
          min={0}
          max={1000}
          step={0.5}
          value={radius}
          onChange={geometryChange("radius", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Height Segments">
        <SliderInput
          name="heightSegments"
          min={0}
          max={100}
          step={1}
          value={heightSegments}
          onChange={geometryChange("heightSegments", geometry, onChange)}
        />
      </LabelGroup>
      <LabelGroup label="Width Segments">
        <SliderInput
          name="widthSegments"
          min={0}
          max={100}
          step={1}
          value={widthSegments}
          onChange={geometryChange("widthSegments", geometry, onChange)}
        />
      </LabelGroup>
    </>
  );
}
