import { BackSide, Mesh, MeshBasicMaterial, MeshStandardMaterial, PlaneGeometry } from "three";
import { ForwardedRef, forwardRef, useMemo } from "react";

const whiteMaterial = new MeshStandardMaterial({ color: "white", side: BackSide });

type RenderScreenShareWithBasicGeometryPreview = {
  width: number;
  height: number;
  screenShareMaterial: MeshBasicMaterial;
};

export const RenderScreenShareWithBasicGeometryPreview = forwardRef(
  ({ width, height, screenShareMaterial }: RenderScreenShareWithBasicGeometryPreview, ref: ForwardedRef<Mesh>) => {
    const geometry = useMemo(() => new PlaneGeometry(width, height), [width, height]);

    return (
      <>
        <mesh geometry={geometry} material={screenShareMaterial} ref={ref} />
        <mesh geometry={geometry} material={whiteMaterial} position={[0, 0, -0.01]} />
      </>
    );
  }
);
