import { memo, useEffect } from "react";
import { useUnmount } from "react-use";
import shallow from "zustand/shallow";
import { playerControls } from "~/view-scene/ControlsSystem";
import { toggleStream } from "./utils";
import { useCurrentMedia } from "./useCurrentMedia";
import { useMediaState } from "./useMediaState";
import { usePermissions } from "~/view-scene/permissions";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";

export const MediaSystem = memo(() => {
  const updateStatus = useSessionStatus((state) => state.updateStatus);
  const { joinVoiceChat, enabled, muted, toggleEnable, reset } = useMediaState(
    (state) => ({
      joinVoiceChat: state.joinVoiceChat,
      enabled: state.enabled,
      muted: state.muted,
      toggleEnable: state.toggleEnable,
      reset: state.reset,
    }),
    shallow
  );
  const { micStream } = useCurrentMedia();

  const requestPermission = usePermissions((state) => state.requestPermission, shallow);

  useEffect(() => {
    const startHandler = () => {
      if (useMediaState.getState().voiceChatActive) {
        toggleEnable(true);
      }
    };

    const endHandler = () => {
      if (useMediaState.getState().voiceChatActive) {
        toggleEnable(false);
      } else {
        const permission = usePermissions.getState().permissions.microphone;
        if (permission === "granted") {
          joinVoiceChat();
        } else {
          updateStatus("onpause");
          requestPermission("microphone").then((permission) => {
            if (permission === "granted") {
              joinVoiceChat();
            }
          });
        }
      }
    };

    playerControls.talk.on("start", startHandler);
    playerControls.talk.on("end", endHandler);

    return () => {
      playerControls.talk.off("start", startHandler);
      playerControls.talk.off("end", endHandler);
    };
  }, []);

  useEffect(() => {
    const isOn = !muted && enabled;

    micStream && toggleStream(micStream, isOn);
  }, [enabled, muted, micStream]);

  useUnmount(() => {
    reset();
  });

  return null;
});
