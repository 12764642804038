import { memo } from "react";
import { Stars } from "@react-three/drei";
import { EnabledStatus } from "~/types/EnabledStatus";
import { useEntity } from "~/view-scene/utils/useEntity";
import IStarsSceneObject from "~/types/IStarsSceneObject";

type StarsEntityProps = {
  entityId: string;
};

export const StarsEntity = memo(({ entityId }: StarsEntityProps) => {
  const entity = useEntity<IStarsSceneObject>(entityId);

  const { radius = 100, depth = 50, count = 5000, saturation = 0, factor = 4, fade = false, enabled } = entity;

  if (enabled === EnabledStatus.disabled) {
    return null;
  }

  return <Stars radius={radius} depth={depth} count={count} saturation={saturation} factor={factor} fade={fade} />;
});
