import { Mesh, MeshBasicMaterial } from "three";
import { memo, useMemo } from "react";
import IPrimitiveSceneObject from "~/types/IPrimitiveSceneObject";
import { RenderObject } from "../../RenderObject";
import { toGeometry } from "../geometry";

const primitiveDefaultMaterial = new MeshBasicMaterial({ color: "white" });

export type RenderPrimitiveProps = {
  dto: IPrimitiveSceneObject;
};

export const RenderPrimitive = memo(({ dto }: RenderPrimitiveProps) => {
  const { isStatic, geometry: geometryDto, material: materialId, castShadow, receiveShadow, frustumCulling } = dto;

  const model = useMemo(() => {
    const geometry = toGeometry(geometryDto);
    if (!geometry) {
      return null;
    }

    return new Mesh(geometry, primitiveDefaultMaterial);
  }, [geometryDto]);

  if (!model) {
    return null;
  }

  return (
    <RenderObject
      object={model}
      isStatic={isStatic}
      materialId={materialId}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
      frustumCulling={frustumCulling}
    />
  );
});
