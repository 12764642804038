import { v4 as uuid } from "uuid";
import {
  DynamicSimpleMoveJoystick,
  DynamicSimpleMoveJoystickProps,
} from "~/view-scene/ControlsSource/MobileSource/DynamicSimpleMoveJoystick";
import { addAddon, removeAddon } from "~/view-scene/addons";

export const addMoveJoystick = (options: DynamicSimpleMoveJoystickProps) => {
  const id = uuid();

  addAddon({
    id,
    node: <DynamicSimpleMoveJoystick {...options} />,
  });

  return {
    id,
    dispose: () => {
      removeAddon(id);
    },
  };
};

export const addons = {
  addMoveJoystick,
};

export type Addons = typeof addons;
