import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket, StringSocket } from "../../sockets";

export class StringEqualNode extends BaseNode {
  static readonly type = "logic/stringEqual";
  static readonly label = "String =";

  inputs = [new StringSocket("a"), new StringSocket("b")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext) {
    const a = context.readInput<string>("a");
    const b = context.readInput<string>("b");

    context.writeOutput<boolean>("result", a === b);
  }
}
