import { Icon, IconProps } from "./Icon";

export type SpawnPointProps = IconProps;

export const SpawnPoint = (props: SpawnPointProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m10.667 9.333 4 2-6.428 3.214c-.088.044-.132.066-.177.075a.334.334 0 0 1-.123 0c-.046-.009-.09-.03-.177-.075l-6.428-3.214 4-2M8 11.333V6m0 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
    />
  </Icon>
);
