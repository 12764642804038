import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class TelegramAuthNode extends BaseNode {
  static readonly type = "hackneyapi/telegramAuth";
  static readonly label = "Telegram Auth";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("botName")];
  outputs = [new FlowSocket("flow", "success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext) {
    async function doTelegramAuth(botName: string) {
      window.HackneyGamesSDK?.setup({ botName: botName });
      const answer = await window.HackneyGamesSDK?.telegramAuth();
      console.log("Auth answer: " + answer);
      context.commit("flow");
      context.graphEvaluator.executeAll();
    }

    doTelegramAuth(context.readInput<string>("botName")).catch((error) => {
      console.error("Error during user authentication :", error);
      context.commit("fail");
      context.graphEvaluator.executeAll();
    });
  }
}
