import { useStoreMap, useUnit } from "effector-react";
import { $currentCutscene } from "~/view-scene/runtime";
import { $audioManager } from "~/view-scene/audio";
import { Cutscene } from "./Cutscene";
import { useAsset } from "~/entities/assets";
import IVideoAsset from "~/types/IVideoAsset";

export function CutsceneSystem() {
  const audioSystemReady = useStoreMap($audioManager, (audioManager) => audioManager.isReady);
  const currentCutscene = useUnit($currentCutscene);
  const videoAsset = useAsset<IVideoAsset>(currentCutscene?.asset);

  if (!videoAsset || !audioSystemReady) {
    return null;
  }

  return <Cutscene url={videoAsset.url} />;
}
