import { Icon, IconProps } from "./Icon";

export type InventoryProps = IconProps;

export const Inventory = (props: InventoryProps) => (
  <Icon width="20px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M18 11V15.8C18 16.9201 18 17.4802 17.782 17.908C17.5903 18.2843 17.2843 18.5903 16.908 18.782C16.4802 19 15.9201 19 14.8 19H5.2C4.0799 19 3.51984 19 3.09202 18.782C2.71569 18.5903 2.40973 18.2843 2.21799 17.908C2 17.4802 2 16.9201 2 15.8V11M7 8H13M7.28571 12H12.7143C14.8467 12 15.913 12 16.7355 11.6039C17.552 11.2107 18.2107 10.552 18.6039 9.73553C19 8.91297 19 7.84674 19 5.71429C19 4.11494 19 3.31527 18.7029 2.69835C18.408 2.08603 17.914 1.59197 17.3017 1.29709C16.6847 1 15.8851 1 14.2857 1H5.71429C4.11494 1 3.31527 1 2.69835 1.29709C2.08603 1.59197 1.59197 2.08603 1.29709 2.69835C1 3.31527 1 4.11494 1 5.71429C1 7.84674 1 8.91297 1.39612 9.73553C1.7893 10.552 2.44803 11.2107 3.26447 11.6039C4.08703 12 5.15326 12 7.28571 12Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
