import { NodeEvalContext } from "~/libs/behave-graph";
import { ImageUIEntity } from "~/types/ui";
import { UIImageContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetImageSocket, FlowSocket, UIEntitySocket } from "../../sockets";
import { AssetImageValue, UIImageValue } from "../../values";

export class UIImageSetImageNode extends BaseNode {
  static readonly type = "action/uIImageSetImage";
  static readonly label = "UI Image Set Image";

  inputs = [new FlowSocket("flow"), new UIEntitySocket("uiEntity"), new AssetImageSocket("image")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = context.readInput<UIImageValue>("uiEntity");
    const image = context.readInput<AssetImageValue>("image");

    if (image.assetId && entity.entityId) {
      node.sceneContext.getUIEntityContext<UIImageContext>(entity.entityId)?.updateEntity<ImageUIEntity>({
        asset: image.assetId,
      });
    }
  }
}
