import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => {
  return { ...contentTypeHeader, ...getAuthHeader() };
};

const path = "profile";

export const getMe = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/${path}/me`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const updateMyName = async (name) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ name }),
  };

  const response = await fetch(`${API_HOST}/${path}/name`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const updateMySex = async (sex) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ sex }),
  };

  const response = await fetch(`${API_HOST}/${path}/sex`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const updateMyAvatar = async (payload) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/avatar`, requestOptions);
  const status = response.status;
  return { status };
};

export const uploadCustomAvatar = async (file) => {
  const data = new FormData();
  data.append("avatar", file);
  const requestOptions = {
    method: "POST",
    headers: getAuthHeader(),
    body: data,
  };

  const response = await fetch(`${API_HOST}/${path}/avatar/upload`, requestOptions);
  const status = response.status;
  return { status };
};

export const updateMyEmail = async (payload) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/email`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const changePassword = async (payload) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/password`, requestOptions);
  const status = response.status;
  return { status };
};

export async function attachWallet(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/wallet`, requestOptions);
  const status = response.status;
  return { status };
}

export async function attachTonWallet(payload) {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/${path}/tonWallet`, requestOptions);
  const status = response.status;
  return { status };
}

export async function detachWallet(walletAddress) {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/${path}/wallet/${walletAddress}`, requestOptions);
  const status = response.status;
  return { status };
}

export const sendFeedback = async (feedback) => {
  const body = JSON.stringify(feedback);
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/${path}/feedback`, requestOptions);
  const status = response.status;

  return { status };
};

export const updateStorage = async (payload) => {
  const body = JSON.stringify(payload);
  const requestOptions = {
    method: "PATCH",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/${path}/storage`, requestOptions);
  const status = response.status;

  return { status };
};

export const updateStorages = async (payload) => {
  const body = JSON.stringify(payload);
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/${path}/storage/batchUpdate`, requestOptions);
  const status = response.status;

  return { status };
};
