import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class PlayerAnimationPlayNode extends BaseNode {
  static readonly type = "action/playerAnimationPlay";
  static readonly label = "Play Player Animation";

  async = true;
  interruptibleAsync = true;

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow"), new FlowSocket("onFinish"), new FlowSocket("onAnimationTick")];

  initNode(node: BaseBehaveNode) {
    node.tickable = node.outputConnected("onAnimationTick");
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const player = node.sceneContext.getEntityContext<PlayerContext>("player");
    const avatarAnimationId = node.readMetadata("avatarAnimationId", null);
    if (!avatarAnimationId || !player) {
      return;
    }

    node.sceneContext.playPlayerAnimation(avatarAnimationId);
    context.commit("flow");

    const handleAnimationTick = () => {
      context.commit("onAnimationTick");
    };

    if (node.outputConnected("onAnimationTick")) {
      node.scriptContext.scriptEvents.tickEvent.addListener(handleAnimationTick);
    }

    const handleAnimationFinished = (animationId: string) => {
      if (animationId !== avatarAnimationId) {
        return;
      }

      if (node.outputConnected("onFinish")) {
        context.commit("onFinish");
      }

      if (node.outputConnected("onAnimationTick")) {
        node.scriptContext.scriptEvents.tickEvent.removeListener(handleAnimationTick);
      }
    };

    const handleAnimationStopped = (animationId: string) => {
      if (animationId !== avatarAnimationId) {
        return;
      }

      player.events.off("avatarAnimationFinished", handleAnimationFinished);

      if (node.outputConnected("onAnimationTick")) {
        node.scriptContext.scriptEvents.tickEvent.removeListener(handleAnimationTick);
      }
    };

    player.events.once("avatarAnimationFinished", ({ animationId }) => handleAnimationFinished(animationId));
    player.events.once("avatarAnimationStopped", ({ animationId }) => handleAnimationStopped(animationId));
  }
}
