import { BaseSocket } from "../base";
import { booleanValue } from "../values/booleanValue";

export class BooleanSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: boolean = false) {
    super(booleanValue.name, id, label, value);
  }

  clone() {
    return new BooleanSocket(this.id, this.label, this.value);
  }
}
