import { Icon, IconProps } from "./Icon";

export type TouchProps = IconProps;

export const Touch = (props: TouchProps) => (
  <Icon width="22px" height="32px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 32" {...props}>
    <path
      fill="currentcolor"
      d="M4 7a5 5 0 1 1 9.584 2 1 1 0 0 0 1.833.8 7 7 0 1 0-12.834 0A1 1 0 1 0 4.416 9 4.98 4.98 0 0 1 4 7Z"
    />
    <path
      fill="currentcolor"
      d="M10.542 5.259c-.494-.487-.997-.76-1.693-.759-.696-.001-1.175.272-1.668.76-.494.486-.654 1.149-.657 1.84l.01 12.977a.314.314 0 0 1-.551.144l-1.398-2.098a2.732 2.732 0 0 0-1.552-1.161 2.233 2.233 0 0 0-1.677.085A2.21 2.21 0 0 0 .22 18.274a2.775 2.775 0 0 0-.11 1.85c.012.045.03.09.051.133l3.225 6.244c.014.027.03.054.047.08.568.88 1.266 1.67 2.068 2.347a10.434 10.434 0 0 0 2.25 1.359c1.872.825 3.9 1.238 5.948 1.212 1.428 0 2.644-.24 5.008-1.639.271-.167 1.665-1.027 2.456-2.311a4.72 4.72 0 0 0 .626-1.41c.087-.363.141-.733.163-1.105.033-.336.049-.696.049-1.07V19.98c0-1.069-.098-1.625-.463-2.293-1.055-1.69-3.107-3.19-5.693-3.567-1.958-.283-3.427-.146-4.67.336V7.1c-.003-.69-.14-1.353-.632-1.84Z"
    />
  </Icon>
);
