import { createEffect } from "effector";
import { io } from "socket.io-client";
import { API_HOST } from "~/config";
import { getAccessToken } from "../accessToken";
import { setSocket } from "./socket";
import { subscribeFx } from "./subscribe";
import { sendJoinCollaboration } from "~/api/socket/events";

export const connectFx = createEffect((sceneId: string) => {
  const socket = io(API_HOST, {
    withCredentials: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 1000,
    extraHeaders: {
      Authorization: "Bearer " + getAccessToken(),
    },
  });

  setSocket(socket);
  subscribeFx({ sceneId, socket }).then(() => sendJoinCollaboration(sceneId));

  return socket;
});
