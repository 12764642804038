import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { modelValue, ModelValue } from "../values";

export class ModelSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: ModelValue = defaultEntity()) {
    super(modelValue.name, id, label, value);
  }

  clone() {
    return new ModelSocket(this.id, this.label, this.value);
  }
}
