import { ScriptMethod } from "~/types/ScriptAsset";
import { Method } from "./Method";
import { Registry } from "./Registry";
import { MethodCallPayload } from "./ScriptEvents";
import { ScriptContext } from "./types";
import type { SceneContext } from "./useSceneContext";

export class MethodsManager {
  private methods: Record<string, Method>;

  constructor(
    methods: ScriptMethod[],
    private registry: Registry,
    private sceneContext: SceneContext,
    private scriptContext: ScriptContext
  ) {
    this.methods = this.initMethods(methods);
    this.init();
  }

  init() {
    this.scriptContext.scriptEvents.methodCall.addListener(this.methodCallListener);
  }

  methodCallListener = (payload: MethodCallPayload) => {
    const method = this.methods[payload.id];

    if (!method) {
      return;
    }

    method.call(payload.values, payload.callback);
  };

  dispose() {
    this.scriptContext.scriptEvents.methodCall.removeListener(this.methodCallListener);
  }

  initMethods(methods: ScriptMethod[]) {
    const methodsMap: Record<string, Method> = {};

    for (const method of methods) {
      methodsMap[method.id] = new Method(method, this.registry, this.sceneContext, this.scriptContext, this);
    }

    return methodsMap;
  }
}
