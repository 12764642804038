import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket, NumberSocket } from "../../sockets";

export class NumberNotEqualNode extends BaseNode {
  static readonly type = "logic/numberNotEqual";
  static readonly label = "Number !=";
  static readonly category: NodeCategory = "Logic";

  inputs = [new NumberSocket("a"), new NumberSocket("b"), new NumberSocket("epsilon")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext) {
    const a = context.readInput<number>("a");
    const b = context.readInput<number>("b");
    const epsilon = context.readInput<number>("epsilon");

    context.writeOutput<boolean>("result", Math.abs(a - b) > epsilon);
  }
}
