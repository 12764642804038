import { createEvent } from "effector";
import { action } from "./actionSource";
import { actionsList, EditorAction } from "../editorKeyMap";

type ActionEvent = typeof action;
type ActionsMap = Record<EditorAction, ActionEvent>;

export const editorHotkey = actionsList.reduce((acc, actionName) => {
  acc[actionName] = createEvent() as ActionEvent;

  return acc;
}, {} as ActionsMap);

action.watch((actionEvent) => {
  editorHotkey[actionEvent.action](actionEvent);
});
