import { createEffect } from "effector";
import { Socket } from "socket.io-client";
import { Update } from "~/common/typeUtils/update";
import ISceneObject from "~/types/ISceneObject";
import {
  assetAdd,
  assetDelete,
  assetUpdate,
  asyncProcessCompleted,
  asyncProcessStarted,
  connected,
  disconnected,
  entityAdd,
  entityComponentAdd,
  entityComponentDelete,
  entityComponentUpdate,
  entityDelete,
  entityUpdate,
  onlineUserAdd,
  onlineUserDelete,
  sceneConfigUpdate,
  storageAdd,
  storageDelete,
  storageUpdate,
} from "./events";
import IAsset from "~/types/IAsset";
import { AsyncProcess } from "~/api/asyncProcesses.api";
import { OnlineUser } from "~/api/builderOnlineUsersApi";
import IComponent from "~/types/IComponent";
import { SceneConfig } from "~/types/IScene";
import { Storage } from "~/types/Storage";

export const subscribeFx = createEffect(({ sceneId, socket }: { sceneId: string; socket: Socket }) => {
  socket.on("connect", () => connected(sceneId));
  socket.on("disconnect", () => disconnected());

  socket.on("remoteSceneConfigUpdate", (config: SceneConfig) => sceneConfigUpdate(config));

  socket.on("remoteSceneObjectAdd", (entity: ISceneObject) => entityAdd(entity));
  socket.on("remoteSceneObjectUpdate", (update: Update<ISceneObject>) => entityUpdate(update));
  socket.on("remoteSceneObjectDelete", (id: string) => entityDelete(id));

  socket.on("remoteComponentAdd", (payload: { objectId: string; component: IComponent }) =>
    entityComponentAdd(payload)
  );
  socket.on("remoteComponentUpdate", (payload: { objectId: string; component: IComponent }) =>
    entityComponentUpdate(payload)
  );
  socket.on("remoteComponentDelete", (payload: { objectId: string; component: IComponent }) =>
    entityComponentDelete({ objectId: payload.objectId, componentId: payload.component.id })
  );

  socket.on("remoteAssetAdd", (asset: IAsset) => assetAdd(asset));
  socket.on("remoteAssetUpdate", (asset: Update<IAsset>) => assetUpdate(asset));
  socket.on("remoteAssetDelete", (asset: IAsset) => assetDelete(asset));

  socket.on("remoteStorageAdd", (storage: Storage) => storageAdd(storage));
  socket.on("remoteStorageUpdate", (storage: Storage) => storageUpdate(storage));
  socket.on("remoteStorageDelete", (payload: { id: string }) => storageDelete(payload));

  socket.on("remoteAsyncProcessStarted", (process: AsyncProcess) => asyncProcessStarted(process));
  socket.on("remoteAsyncProcessCompleted", (process: AsyncProcess) => asyncProcessCompleted(process));

  socket.on("remoteOnlineUserAdd", (user: OnlineUser) => onlineUserAdd(user));
  socket.on("remoteOnlineUserDelete", (userId: string) => onlineUserDelete(userId));
});

export const unsubscribeFx = createEffect((socket: Socket) => {
  socket.offAny();
});
