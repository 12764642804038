import { v4 as uuid } from "uuid";
import { defaultComponent } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { ComponentType } from "~/types/ComponentType";
import { EnabledStatus } from "~/types/EnabledStatus";
import { ConstraintOptions, IConstraintComponent } from "~/types/IConstraintComponent";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ConstraintSocket, EntitySocket, FlowSocket } from "../../sockets";
import { ConstraintValue, EntityValue } from "../../values";

export class AddConstraintNode extends BaseNode {
  static readonly type = "constraints/addConstraint";
  static readonly label = "Add Constraint";

  inputs = [new FlowSocket("flow"), new EntitySocket("a", "entity"), new EntitySocket("b", "target")];
  outputs = [new FlowSocket("flow"), new ConstraintSocket("constraint")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultComponent();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const a = context.readInput<EntityValue>("a");
    const b = context.readInput<EntityValue>("b");
    const constraint = node.metadata.constraint as any as ConstraintOptions;
    const componentId = uuid();

    if (!a.entityId || !b.entityId) {
      return;
    }

    const constraintComponent: IConstraintComponent = {
      id: componentId,
      type: ComponentType.CONSTRAINT,
      enabled: EnabledStatus.enabled,
      targetEntityId: b.entityId,
      disableCollisionsBetweenLinkedBodies: false,
      constraint,
    };

    node.sceneContext.addComponent(a.entityId, constraintComponent);

    node.storage.output.entityId = a.entityId;
    node.storage.output.componentId = componentId;

    context.writeOutput<ConstraintValue>("constraint", node.storage.output);
  }

  createDefaultNodeSpec(): NodeSpec {
    return {
      ...super.createDefaultNodeSpec(),
      metadata: {
        constraint: {
          type: "spring",
        } as any,
      },
    };
  }
}
