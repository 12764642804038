import { WalletInfo } from "@tonconnect/ui";

export const availableWallets: WalletInfo[] = [
  {
    name: "Wallet",
    appName: "telegram-wallet",
    imageUrl: "/static/img/content/ton-wallets/wallet.png",
    aboutUrl: "https://wallet.tg/",
    platforms: ["ios", "android", "macos", "windows", "linux"],
    bridgeUrl: "https://bridge.ton.space/bridge",
    universalLink: "https://t.me/wallet?attach=wallet",
    injected: false,
    embedded: false,
  },
  {
    name: "Tonkeeper",
    appName: "tonkeeper",
    imageUrl: "/static/img/content/ton-wallets/tonconnect-icon.png",
    aboutUrl: "https://tonkeeper.com",
    tondns: "tonkeeper.ton",
    platforms: ["ios", "android", "chrome", "firefox", "macos"],
    bridgeUrl: "https://bridge.tonapi.io/bridge",
    universalLink: "https://app.tonkeeper.com/ton-connect",
    deepLink: "tonkeeper-tc://",
    jsBridgeKey: "tonkeeper",
    injected: false,
    embedded: false,
  },
  {
    name: "MyTonWallet",
    appName: "mytonwallet",
    imageUrl: "/static/img/content/ton-wallets/mytonwallet.png",
    aboutUrl: "https://mytonwallet.io",
    platforms: ["chrome", "windows", "macos", "linux", "ios", "android", "firefox"],
    jsBridgeKey: "mytonwallet",
    bridgeUrl: "https://tonconnectbridge.mytonwallet.org/bridge/",
    universalLink: "https://connect.mytonwallet.org",
    injected: false,
    embedded: false,
  },
  {
    name: "OpenMask",
    appName: "openmask",
    imageUrl: "/static/img/content/ton-wallets/openmask.png",
    aboutUrl: "https://www.openmask.app/",
    platforms: ["chrome"],
    jsBridgeKey: "openmask",
    injected: false,
    embedded: false,
  },
  {
    name: "Tonhub",
    appName: "tonhub",
    imageUrl: "/static/img/content/ton-wallets/tonhub.png",
    aboutUrl: "https://tonhub.com",
    platforms: ["ios", "android"],
    jsBridgeKey: "tonhub",
    injected: false,
    embedded: false,
    bridgeUrl: "https://connect.tonhubapi.com/tonconnect",
    universalLink: "https://tonhub.com/ton-connect",
  },
  {
    name: "DeWallet",
    appName: "dewallet",
    imageUrl: "/static/img/content/ton-wallets/dewallet.png",
    aboutUrl: "https://delabwallet.com",
    platforms: ["chrome"],
    jsBridgeKey: "dewallet",
    injected: false,
    embedded: false,
  },
  {
    name: "XTONWallet",
    appName: "xtonwallet",
    imageUrl: "/static/img/content/ton-wallets/xtonwallet.png",
    aboutUrl: "https://xtonwallet.com",
    platforms: ["chrome", "firefox"],
    jsBridgeKey: "xtonwallet",
    injected: false,
    embedded: false,
  },
  {
    name: "TON Wallet",
    appName: "tonwallet",
    imageUrl: "/static/img/content/ton-wallets/tonwallet.png",
    aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
    platforms: ["chrome"],
    jsBridgeKey: "tonwallet",
    injected: false,
    embedded: false,
  },
];
