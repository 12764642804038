import { createEvent, createStore } from "effector";
import { useSceneData, SceneData } from "~/common/stores/useSceneData";
import { CustomButton } from "~/types/InputSystem";

const getCustomButtons = (sceneData: SceneData) => {
  if (!sceneData.sceneState) {
    return null;
  }

  return sceneData.sceneState.inputSystem.customButtons;
};

const setCustomButtons = createEvent<CustomButton[] | null>();
export const $customButtons = createStore(getCustomButtons(useSceneData.getState())).on(
  setCustomButtons,
  (_, payload) => payload
);

useSceneData.subscribe((sceneData) => {
  if ($customButtons.getState()) {
    return;
  }

  setCustomButtons(getCustomButtons(sceneData));
});
