import { useFrame } from "@react-three/fiber";
import { intersection } from "lodash-es";
import { useState } from "react";
import type { ObstacleAvoidanceBehavior, Vehicle } from "yuka";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import { useEntityManager } from "~/view-scene/runtime";
import { useYuka } from "~/view-scene/yukaSystem";
import { useUnmount } from "react-use";

type UseObstaclesAvoidingBehaviorProps = {
  vehicle: Vehicle | null;
  obstacleTags: string[];
};

export function useObstaclesAvoidingBehavior({ vehicle, obstacleTags }: UseObstaclesAvoidingBehaviorProps) {
  const [obstacleAvoidanceBehavior, setObstacleAvoidanceBehavior] = useState<ObstacleAvoidanceBehavior | null>(null);
  const yuka = useYuka();

  const obstacleEntitiesIDs = useSceneData(
    (state) => state.sceneEntities.filter((e) => intersection(e.tags, obstacleTags).length > 0).map((e) => e.id),
    shallow
  );

  useFrame(() => {
    if (!vehicle) {
      return;
    }

    // TODO entity context is stored in the Map, modifying map does not trigger a rerender.
    const obstacleVehicles = useEntityManager
      .getState()
      .getEntityContexts(obstacleEntitiesIDs)
      .filter((o) => o.current?.vehicle != null && o.current.vehicle !== vehicle)
      .map((o) => o.current!.vehicle!);

    if (obstacleVehicles.length > 0) {
      let oAB: ObstacleAvoidanceBehavior;
      if (!obstacleAvoidanceBehavior) {
        oAB = new yuka.ObstacleAvoidanceBehavior();
        vehicle.steering.add(oAB);
        setObstacleAvoidanceBehavior(oAB);
      } else {
        oAB = obstacleAvoidanceBehavior;
      }
      oAB.obstacles.length = 0;
      oAB.obstacles.push(...obstacleVehicles);
    } else {
      if (obstacleAvoidanceBehavior) {
        obstacleAvoidanceBehavior.obstacles.length = 0;
      }
    }
  });

  useUnmount(() => {
    if (vehicle && obstacleAvoidanceBehavior) {
      vehicle.steering.remove(obstacleAvoidanceBehavior);
    }
  });
}
