import { ComponentType } from "~/types/ComponentType";
import ISceneObject from "~/types/ISceneObject";
import IScriptComponent from "~/types/IScriptComponent";

const scriptTypes = new Set([ComponentType.SCRIPT, ComponentType.JS_SCRIPT]);

export const getScriptAssetId = (entities: ISceneObject[], entityId: string | null, componentId: string | null) => {
  const entity = entities.find((entity) => entity.id === entityId);

  const component = entity?.components.find((component) => component.id === componentId);

  if (!component || !scriptTypes.has(component.type)) {
    return null;
  }

  return (component as IScriptComponent).assetId ?? null;
};
