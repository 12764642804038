import { forwardRef, memo, ReactNode } from "react";
import { Object3D } from "three";
import { Entity } from "../Entity";
import { RenderLod } from "./RenderLod";
import { useContext, useLods } from "./hooks";
import IModelSceneObject from "~/types/IModelSceneObject";

type LodProps = {
  entity: IModelSceneObject;
  children?: ReactNode;
};

export const Lod = memo(
  forwardRef<Object3D, LodProps>(({ entity, children }, ref) => {
    // TODO review this approach
    const { dto, contextRef } = useContext(entity);
    const lods = useLods(dto);

    return (
      <Entity entityId={entity.id} context={contextRef} ref={ref}>
        {lods.length > 0 && <RenderLod dto={dto} lods={lods} />}
        {children}
      </Entity>
    );
  })
);
