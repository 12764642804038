import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { disableNativeUndoRedoKey } from "~/hotkeys";

export type TextFieldProps = MuiTextFieldProps & {
  disableNativeUndoRedo?: boolean;
};

export const TextField = ({ disableNativeUndoRedo = false, inputProps, ...rest }: TextFieldProps) => {
  const enrichedInputProps = {
    ...inputProps,
    [`data-${disableNativeUndoRedoKey}`]: disableNativeUndoRedo ? "true" : "",
  };

  return <MuiTextField inputProps={enrichedInputProps} {...rest} />;
};
