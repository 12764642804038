import { SvgIcon } from "@mui/material";
import React from "react";
import { Navigate } from "~/common/components";

type SocialNetworkLinkProps = {
  href: string;
  Icon: typeof SvgIcon;
};

export function SocialNetworkLink({ href, Icon }: SocialNetworkLinkProps) {
  return (
    <Navigate
      to={href}
      openInNewWindow={true}
      sx={{
        opacity: ".6",
        transition: ".4s ease",
        "&:hover": {
          opacity: "1",
        },
      }}
    >
      <Icon
        sx={{
          color: "white",
        }}
      />
    </Navigate>
  );
}
