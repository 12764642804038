import { NodeContext } from "../../base";
import { BooleanUnaryOperationNode } from "./BooleanUnaryOperationNode";

export class BooleanNotNode extends BooleanUnaryOperationNode {
  static readonly type = "logic/booleanNot";
  static readonly label = "Boolean NOT";

  constructor(context: NodeContext) {
    super(context, (value) => !value);
  }
}
