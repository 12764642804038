import { Box, Button, IconButton, InputAdornment, Stack, styled, Typography } from "@mui/material";
import { TextField } from "~/hephaestus/components/TextField";
import ErrorAlert from "~/common/components/ErrorAlert";
import { palette } from "~/theme";
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import * as authApi from "~/api/auth.api";
import { validateEmail } from "~/common/utils/validateEmail";
import { PasswordHide, PasswordShow } from "~/common/components/Icon";

type LoginWithEmailPasswordProps = {
  onSuccess: (token: string) => void;
  onFormStateChange: (formState: "login" | "register" | "passwordReset") => void;
};

export function LoginWithEmailPassword({ onSuccess, onFormStateChange }: LoginWithEmailPasswordProps) {
  const [loginForm, setLoginForm] = useState({
    email: "",
    isEmailValid: true,
    password: "",
    errorMessage: "",
    authError: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  const actionsContainerRef = useRef<HTMLDivElement>();
  const [actionsContainerWidth, setActionsContainerWidth] = useState(600);
  useEffect(() => {
    const actionsContainer = actionsContainerRef.current;
    if (!actionsContainer) {
      return;
    }

    // because of iframe usage
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const contentRect = entry.contentRect;
        setActionsContainerWidth(contentRect.width);
      }
    });

    resizeObserver.observe(actionsContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleFormLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loginForm.isEmailValid) {
      return;
    }

    const payload = { email: loginForm.email, password: loginForm.password };
    const { status, body } = await authApi.loginWithEmailPassword(payload);

    if (status !== 200) {
      const errorMessage = body.message;
      setLoginForm({ ...loginForm, authError: true, errorMessage });
      return;
    }

    const { accessToken } = body;

    await onSuccess(accessToken);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    const isEmailValid = validateEmail(email);
    setLoginForm({ ...loginForm, email, isEmailValid });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setLoginForm({ ...loginForm, password });
  };

  const handleCloseErrorAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setLoginForm((prev) => ({ ...prev, authError: false, errorMessage: "" }));
  };

  return (
    <>
      <form onSubmit={(e) => handleFormLogin(e)}>
        <Stack direction="column" gap="23px">
          <Box component="div">
            <TextField
              fullWidth
              size="large"
              type="email"
              placeholder="Email"
              value={loginForm.email}
              autoComplete="email"
              onChange={handleEmailChange}
              error={!loginForm.isEmailValid}
            />
          </Box>
          <Box component="div">
            <TextField
              fullWidth
              size="large"
              type={showPassword ? "text" : "password"}
              value={loginForm.password}
              placeholder="Password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleShowPassword}>
                      {showPassword ? <PasswordShow /> : <PasswordHide />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {loginForm.authError && <ErrorAlert onClose={handleCloseErrorAlert}>{loginForm.errorMessage}</ErrorAlert>}
        </Stack>
        <ActionsContainer ref={actionsContainerRef}>
          <Stack direction="row" alignItems="center" gap="16px">
            <Button variant="contained" size="large" onClick={() => onFormStateChange("register")}>
              Create an account
            </Button>
            {actionsContainerWidth >= 450 && (
              <ForgotPassword variant="body1" onClick={() => onFormStateChange("passwordReset")}>
                Forgot password?
              </ForgotPassword>
            )}
          </Stack>
          <Button type="submit" variant="accent" size="large" disabled={!loginForm.isEmailValid}>
            Login
          </Button>
        </ActionsContainer>
        {actionsContainerWidth < 450 && (
          <Stack alignItems="center" justifyContent="center" width="100%" paddingBottom="24px">
            <ForgotPassword variant="body1" onClick={() => onFormStateChange("passwordReset")}>
              Forgot password?
            </ForgotPassword>
          </Stack>
        )}
      </form>
    </>
  );
}

const ForgotPassword = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: ${palette.primary.gray600};
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    color: ${palette.primary.gray800};
  }
`;

const ActionsContainer = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
  padding-bottom: 24px;
  align-items: center;
`;
