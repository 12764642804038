import { useStore, useUnit } from "effector-react";
import { RefObject, memo, useEffect } from "react";
import { useUnmount } from "react-use";
import { useSceneData } from "~/common/stores/useSceneData";
import { $storages } from "~/entities/storages";
import { ComponentType } from "~/types/ComponentType";
import IScriptComponent from "~/types/IScriptComponent";
import { ScriptAsset } from "~/types/ScriptAsset";
import { EntityContext, ScriptComponentContext, useComponentContext } from "~/view-scene/runtime";
import { $initialized, registerBehaveScript, unregisterScript } from "~/view-scene/script";

type ScriptComponentProps = {
  componentDto: IScriptComponent;
  contextRef: RefObject<EntityContext>;
};

export const ScriptComponent = memo(({ componentDto, contextRef }: ScriptComponentProps) => {
  const getAsset = useSceneData((state) => state.getAsset);
  const storages = useUnit($storages);
  const asset = getAsset<ScriptAsset>(componentDto.assetId);
  const initialized = useStore($initialized);

  useEffect(() => {
    if (asset && initialized && storages) {
      const schema = asset.schema;

      registerBehaveScript({
        id: componentDto.id,
        name: asset.name ?? "__unknown__",
        entityContextRef: contextRef,
        args: componentDto.arguments ?? {},
        schema,
      });
    }
  }, [asset, initialized]);

  useUnmount(() => {
    unregisterScript(componentDto.id);
  });

  const context: ScriptComponentContext = {
    id: componentDto.id,
    name: asset?.name ?? "__unknown__",
    type: ComponentType.SCRIPT,
    scriptId: componentDto.assetId ?? "",
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  return null;
});
