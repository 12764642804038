import { NodeContext } from "../../base";
import { NumberCompareNode } from "./NumberCompareNode";

export class NumberMoreNode extends NumberCompareNode {
  static readonly type = "logic/numberMore";
  static readonly label = "Number >";

  constructor(context: NodeContext) {
    super(context, (a, b) => a > b);
  }
}
