import { ErrorPageLayout, ErrorPageTitle } from "./components";
import { Box, Stack, Typography } from "@mui/material";
import { BackToExplore } from "~/common/components";
import { palette } from "~/theme";

export const BuilderSceneNotFoundPage = () => {
  return (
    <ErrorPageLayout>
      <ErrorPageTitle icon="/static/img/content/smile-sadness.webp" subtitle="Can't find this page" />
      <Box
        component="div"
        sx={{
          maxWidth: "426px",
          margin: "0 auto 64px",
        }}
      >
        <Stack
          direction="column"
          alignItems="left"
          sx={{
            marginTop: "24px",
            gridGap: "24px",
          }}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "18px",
              width: "100%",
              color: palette.primary.gray600,
            })}
            variant="body1"
          >
            Oops, something went wrong. This scene may have been deleted or made unavailable.
          </Typography>
          <BackToExplore
            text="Back to My Projects"
            url="/projects"
            sx={{
              justifyContent: "left",
            }}
          />
        </Stack>
      </Box>
    </ErrorPageLayout>
  );
};
