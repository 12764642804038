import { Stack, styled } from "@mui/material";
import { cardMargin, cardSize, cardSizeMobileM, sidePadding, sidePaddingMobile } from "./constants";

export const HomePageContainer = styled(Stack)`
  --card-size-s: ${cardSize}px;
  --card-size-m: ${cardSize * 2 + cardMargin * 2}px;
  --card-size-l: ${cardSize * 3 + cardMargin * 4}px;
  --card-margin: ${cardMargin}px;

  background: radial-gradient(
      160.35% 93.17% at 50% 50%,
      rgba(245, 211, 253, 0.4) 0%,
      rgba(228, 167, 253, 0.4) 14.58%,
      rgba(201, 109, 255, 0.4) 28.12%,
      rgba(153, 76, 255, 0.4) 40.63%,
      rgba(115, 35, 255, 0.4) 53.12%,
      rgba(69, 17, 165, 0.4) 64.58%,
      rgba(38, 8, 94, 0.4) 76.04%,
      rgba(25, 2, 62, 0.4) 88.02%,
      rgba(9, 1, 27, 0.4) 100%
    ),
    #000000;

  padding: 16px ${sidePadding}px 8px ${sidePadding}px;
  min-height: calc(100vh - 32px);

  @media (max-width: 600px) {
    --card-size-s: ${cardSizeMobileM}px;
    --card-size-m: ${cardSizeMobileM}px;
    --card-size-l: ${cardSizeMobileM * 2 + cardMargin * 2}px;

    padding: 8px ${sidePaddingMobile}px;
    min-height: calc(100vh - 16px);
  }
`;
