import { $sceneData } from "../sceneData";
import { createEffect, createStore } from "effector";
import { geUserWorkspaces } from "~/api/workspace.api";
import IWorkspace from "~/types/IWorkspace";

export const $workspace = $sceneData.map((sceneData) => (sceneData ? sceneData.workspace : null));

export const loadWorkspacesFx = createEffect(async () => {
  const { status, body: workspaces } = await geUserWorkspaces();

  if (status !== 200) {
    throw new Error("Not found");
  }

  return workspaces as unknown as IWorkspace[];
});

export const $workspaces = createStore<IWorkspace[] | null>(null).on(loadWorkspacesFx.doneData, (_, payload) => payload);

export const $workspacesError = createStore<Error | null>(null)
  .on(loadWorkspacesFx, () => null)
  .on(loadWorkspacesFx.failData, (_, error) => error);
