import { CSSProperties, useMemo } from "react";
import { useParams } from "react-router-dom";

type PositionStyles = Pick<CSSProperties, "left" | "right" | "bottom" | "top">;

export const useSizes = () => {
  const params = useParams();

  return useMemo(() => {
    let joystickSize = 110;
    let stickSize = joystickSize * 0.54;

    if (isHyper(params)) {
      joystickSize = 160;
      stickSize = joystickSize * 0.16;
    }

    let buttonSize = 60;
    let customButtonSize = 48;
    let margin = 16;
    let deltaAngle = Math.PI / 4;

    if (window.matchMedia(`(min-width: 600px)`).matches) {
      joystickSize = 150;
      stickSize = joystickSize * 0.54;

      if (isHyper(params)) {
        joystickSize = 160;
        stickSize = joystickSize * 0.16;
      }

      buttonSize = 80;
      margin = 16;
    }

    const joystickRadius = joystickSize / 2;
    const buttonRadius = buttonSize / 2;
    const item0Angle = calcItem0Angle(joystickRadius, buttonRadius, margin);
    const radius = joystickRadius + buttonRadius + margin;

    const calcOffsets = (index: number, side: "left" | "right", buttonRadius: number) => {
      const angle = item0Angle + deltaAngle * index;
      const b = radius * Math.cos(angle);
      const a = radius * Math.sin(angle);

      const horizontalOffset = joystickRadius - b + margin - buttonRadius;
      const verticalOffset = joystickRadius + a + margin - buttonRadius;

      if (side === "left") {
        return {
          left: `${horizontalOffset}px`,
          bottom: `${verticalOffset}px`,
        };
      } else {
        return {
          right: `${horizontalOffset}px`,
          bottom: `${verticalOffset}px`,
        };
      }
    };

    const calcButtonPosition = (index: number, side: "left" | "right"): PositionStyles => {
      return calcOffsets(index, side, buttonRadius);
    };

    const calcCustomButtonPosition = (index: number, side: "left" | "right"): PositionStyles => {
      return calcOffsets(index, side, customButtonSize / 2);
    };

    return {
      joystickSize,
      stickSize,
      buttonSize,
      customButtonSize,
      margin,
      calcButtonPosition,
      calcCustomButtonPosition,
    };
  }, []);
};

const calcItem0Angle = (joystickRadius: number, buttonRadius: number, margin: number) => {
  const b = joystickRadius - buttonRadius;
  const r = joystickRadius + buttonRadius + margin;

  const angle = Math.acos(b / r);

  return angle;
};

const isHyper = (params: any) => {
  return (
    params.urlName?.includes("hyper") ||
    window.__sceneData?.urlName?.includes("hyper") ||
    window.__sceneData?.urlName === "hyper"
  );
};
