import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmEmail } from "~/api/auth.api";
import { useProfile } from "~/entities/profile";

export const EmailConfirmationCallback = () => {
  const { loadProfile } = useProfile();

  const [searchParams] = useSearchParams();

  const confirmationCode = searchParams.get("code");

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { status } = await confirmEmail({ code: confirmationCode });

      if (status !== 200) {
        navigate("/401");
        return;
      }

      await loadProfile();

      navigate("/profile/settings");
    })();
  }, [confirmationCode]);

  return null;
};
