import { NodeContext } from "../../base";
import { VideoSocket } from "../../sockets";
import { SceneEntityNode } from "./SceneEntityNode";

export class SceneVideoNode extends SceneEntityNode {
  static readonly type = "variable/sceneVideo";
  static readonly label = "Video";

  constructor(context: NodeContext) {
    super(context, new VideoSocket("video"));
  }
}
