import { EventEmitter } from "~/libs/behave-graph";
import { InteractionEvent } from "~/types/InteractionEvent";

export type MethodCallPayload = {
  id: string;
  values: MethodCallValues;
  callback: (values: MethodCallValues) => void;
};

export type MethodCallValues = Record<string, any>;

export type EventCallPayload = {
  id: string;
  values: Record<string, any>;
};

export class ScriptEvents {
  public readonly startEvent = new EventEmitter<void>();
  public readonly gameSessionStartedEvent = new EventEmitter<void>();
  public readonly tickEvent = new EventEmitter<number>();
  public readonly actionEvent = new EventEmitter<void>();
  public readonly interactionEvent = new EventEmitter<InteractionEvent>();
  public readonly collisionStart = new EventEmitter<string>();
  public readonly collisionEnd = new EventEmitter<string>();
  public readonly storageUpdate = new EventEmitter<string>();
  public readonly cameraControls = new EventEmitter<DirectionEvent>();
  public readonly movementControls = new EventEmitter<DirectionEvent>();
  public readonly controlsAction = new EventEmitter<ControlsActionEvent>();
  public readonly rewardedVideoWatchedEvent = new EventEmitter<boolean>();
  public readonly methodCall = new EventEmitter<MethodCallPayload>();
  public readonly eventCall = new EventEmitter<EventCallPayload>();
}

export type DirectionEvent = { forward: number; side: number };
export type ControlsActionEvent = { action: string; down: boolean };
