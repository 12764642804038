import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class CutscenePlayNode extends BaseNode {
  static readonly type = "action/cutscenePlay";
  static readonly label = "Play Cutscene";

  async = true;

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const cutsceneId = node.readMetadata("cutsceneId", null);
    if (!cutsceneId) {
      return;
    }

    node.sceneContext.playCutscene(cutsceneId).then(() => {
      context.commit("flow");
      context.graphEvaluator.executeAll();
    });
  }
}
