import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "./componentValue";

export type FollowTargetValue = ComponentValue;

export const followTargetValue = new ValueType(
  "followTarget",
  defaultComponent,
  (json: FollowTargetValue) => json,
  (value: FollowTargetValue) => value
);
