import { Stack, styled, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { $searchQuery, $selectedWorkspace } from "../models";
import { NoSpace } from "~/common/components/Icon";
import { SceneCard } from "./SceneCard";
import { $scenes, loadScenesFx } from "./models";
import { useEffect } from "react";
import { ProjectsListContainer } from "../ProjectsListContainer";

export const Scenes = () => {
  const [selectedSpace, searchQuery, scenes] = useUnit([$selectedWorkspace, $searchQuery, $scenes]);

  useEffect(() => {
    loadScenesFx();
  }, []);

  const filteredScenes = scenes
    .filter((scene) =>
      selectedSpace ? (scene.workspace as unknown as string | undefined) === selectedSpace._id : true
    )
    .filter((scene) => (searchQuery ? scene.name?.toLowerCase().includes(searchQuery.toLowerCase()) : true));

  return (
    <ProjectsListContainer>
      {filteredScenes.length > 0 ? (
        filteredScenes.map((scene) => <SceneCard key={scene._id} scene={scene} />)
      ) : (
        <NoScenesPlaceholder />
      )}
    </ProjectsListContainer>
  );
};

function NoScenesPlaceholder() {
  return (
    <Stack direction="column" alignItems="center" justifyContent="flex-start" width="100%" paddingTop="100px">
      <Stack width="229px" gap="28px" alignItems="center" textAlign="center">
        <NoSpaceIcon color="primary.gray300" />
        <Typography fontWeight={400} fontSize="12px" lineHeight="18px" color="primary.gray300">
          So far, you have no Scenes created. Select a Project and click the ‘+ New Scene’ button at the top to become a
          Creator.
        </Typography>
      </Stack>
    </Stack>
  );
}

const NoSpaceIcon = styled(NoSpace)`
  height: 106.666px;
  width: 106.666px;
`;
