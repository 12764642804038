import { defaultUIOverlay } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { UIOverlaySocket } from "../../sockets";
import { UIOverlayValue } from "../../values";

export class OverlayNode extends BaseNode {
  static readonly type: string = "variable/overlay";
  static readonly label: string = "Overlay";
  static readonly category = "Variable";

  outputs = [new UIOverlaySocket("overlay")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultUIOverlay();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.overlayId = null;

    const overlayId = node.metadata?.overlayId as string | undefined;

    if (!overlayId) {
      context.writeOutput<UIOverlayValue>("overlay", node.storage.output);
      return;
    }

    node.storage.output.overlayId = overlayId;

    context.writeOutput<UIOverlayValue>("overlay", node.storage.output);
  }
}
