import "reflect-metadata";

import { ValueDescriptor } from "~/types/Variable";

const propertyTypeKey = "property-type";

export const PropertyType = (type: ValueDescriptor["type"]) => {
  return (target: any, propertyKey: string) => {
    setPropertyType(type, target, propertyKey);
  };
};

export const getPropertyType = (target: any, propertyKey: string) => {
  return Reflect.getMetadata(propertyTypeKey, target, propertyKey) ?? [];
};

export const setPropertyType = (type: ValueDescriptor["type"], target: any, propertyKey: string) => {
  Reflect.defineMetadata(propertyTypeKey, type, target, propertyKey);
};
