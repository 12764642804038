import { styled } from "@mui/material";
import { SuspenseImage } from "./SuspenseImage";
import { Text } from "./Text";

type ModalImageProps = {
  src: string;
  label?: string;
  width?: string;
  height?: string;
};

export const ModalImage = ({ src, label, width = undefined, height = undefined }: ModalImageProps) => {
  return (
    <Container>
      <ImageContainer>
        <SuspenseImage src={src} sx={{ width, height }} />
      </ImageContainer>

      {label && (
        <TextContainer>
          <Text text={label} />
        </TextContainer>
      )}
    </Container>
  );
};

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ImageContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TextContainer = styled("div")`
  font-family: "Noto Sans", sans-serif;
  color: #ffffff;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
  text-align: center;

  & img {
    margin: 0 6px;
  }
`;
