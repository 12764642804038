import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode, NodeContext } from "../../base";
import { NumberSocket } from "../../sockets";

export class NumberUnaryMathOperationNode extends BaseNode {
  static readonly utility = true;
  static readonly category: NodeCategory = "Logic";

  inputs = [new NumberSocket("value")];
  outputs = [new NumberSocket("result")];

  constructor(context: NodeContext, private operation: (a: number) => number) {
    super(context);
  }

  eval(context: NodeEvalContext) {
    const value = context.readInput<number>("value");
    context.writeOutput<number>("result", this.operation(value));
  }
}
