export enum ComponentType {
  LINK = "link",
  RIGID_BODY = "rigidbody",
  FOLLOW_TARGET_STEERING = "followTargetSteering",
  TRAJECTORY = "trajectory",
  ANIMATION = "animation",
  SPRITE_ANIMATION = "spriteAnimation",
  TWEEN_ANIMATION = "tweenAnimation",
  FOLLOW_TARGET = "followTarget",
  VIEW_IN_AR = "viewInAR",
  SCRIPT = "script",
  JS_SCRIPT = "jsScript",
  ACTIONABLE = "actionable",
  CONSTRAINT = "constraint",
  ATTACH_TO = "attachTo",
  DYNAMIC_VISIBILITY = "dynamicVisibility",
  INTERACTIVE = "interactive",
}
