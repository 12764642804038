import { ReactNode, Suspense } from "react";
import IScene from "~/types/IScene";
import { RenderPostprocessingLazy } from "../RenderPostprocessing";
import { PlayerProvider } from "../player";

export type CommonContextProps = {
  scene: IScene;
  children: ReactNode;
};

export const CommonContext = ({ scene, children }: CommonContextProps) => {
  const { postprocessing } = scene;

  return (
    <PlayerProvider xr={false}>
      <Suspense fallback={null}>
        {postprocessing?.enabled && <RenderPostprocessingLazy dto={postprocessing} />}
      </Suspense>
      {children}
    </PlayerProvider>
  );
};
