export enum BlockchainNetwork {
  ethereum = "eth",
  solana = "sol",
  polygon = "polygon",
  ton = "ton",
}

export enum Web3Wallet {
  metamask = "metamask",
  phantom = "phantom",
  tonkeeper = "tonkeeper",
}
