import { createEffect, createEvent } from "effector";
import { deleteAsset as deleteAssetAPI } from "~/api/assets.api";
import { $assets } from "./assets";

export const deleteAsset = createEvent<string>();

$assets.on(deleteAsset, (state, assetId) => {
  if (!state) {
    return state;
  }

  return state.filter((asset) => asset.id !== assetId);
});

export const deleteAssetFx = createEffect(async (assetId: string) => {
  await deleteAssetAPI(assetId);
  return assetId;
});
