import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberAsinNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberAsin";
  static readonly label = "Arcsin";

  constructor(context: NodeContext) {
    super(context, (value) => Math.asin(value));
  }
}
