import { createEvent } from "effector";
import { Update } from "~/common/typeUtils";
import { Storage } from "~/types/Storage";
import { $storages } from "./storages";

export const updateStorage = createEvent<Update<Storage>>();

$storages.on(updateStorage, (storages, update) => {
  if (!storages) {
    return;
  }

  return storages.map((storage) => (storage.id === update.id ? { ...storage, ...update } : storage));
});
