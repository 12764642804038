import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => {
  return { ...contentTypeHeader, ...getAuthHeader() };
};

export const createWorkspace = async (reqBody) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(reqBody),
  };
  const response = await fetch(`${API_HOST}/workspaces`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const updateWorkspace = async (workspaceId, reqBody) => {
  const requestOptions = {
    method: "PATCH",
    headers: getHeaders(),
    body: JSON.stringify(reqBody),
  };

  const response = await fetch(`${API_HOST}/workspaces/${workspaceId}`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const deleteWorkspace = async (workspaceId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/workspaces/${workspaceId}`, requestOptions);
  const status = response.status;

  return { status };
};

export const geUserWorkspaces = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/workspaces`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const getWorkspaceUsers = async (workspaceId) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/workspaces/${workspaceId}/users`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const addUserToWorkspace = async (workspaceId, email) => {
  const requestBody = JSON.stringify({ email });

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: requestBody,
  };

  const response = await fetch(`${API_HOST}/workspaces/${workspaceId}/users`, requestOptions);
  const status = response.status;

  let body = {};
  if (status !== 200) {
    body = await response.json();
  }

  return { status, body };
};

export const deleteUserFromWorkspace = async (workspaceId, email) => {
  const requestBody = JSON.stringify({ email });

  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
    body: requestBody,
  };

  const response = await fetch(`${API_HOST}/workspaces/${workspaceId}/users`, requestOptions);
  const status = response.status;

  return { status };
};

export const adminGetWorkspaces = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/workspaces`, requestOptions);
};

export const getWorkspace = async (workspaceId) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/workspaces/${workspaceId}`, requestOptions);
};

export const adminCreateWorkspace = async (workspace) => {
  const body = JSON.stringify(workspace);
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };
  return await fetch(`${API_HOST}/admin/workspaces`, requestOptions);
};

export const adminUpdateWorkspace = async (workspace) => {
  const workspaceId = workspace._id;
  const body = JSON.stringify(workspace);
  const requestOptions = {
    method: "PATCH",
    headers: getHeaders(),
    body,
  };
  return await fetch(`${API_HOST}/admin/workspaces/${workspaceId}`, requestOptions);
};

export const adminDeleteWorkspace = async (workspaceId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };
  return await fetch(`${API_HOST}/admin/workspaces/${workspaceId}`, requestOptions);
};
