import { useUnit } from "effector-react";
import { $audioManager, $audioSourceState } from "./models";
import { useEffect } from "react";
import { SourceType } from "./types";

export const useAudioVolume = () => {
  const { isReady, mixer, muted } = useUnit($audioManager);
  const audioSourceState = useUnit($audioSourceState);

  useEffect(() => {
    if (!isReady || !mixer) {
      return;
    }

    for (const source in audioSourceState) {
      const sourceTypes = source as SourceType;
      const volume = audioSourceState[sourceTypes].volume * (muted ? 0 : 1);

      mixer[sourceTypes].gain.value = volume;
    }
  }, [audioSourceState, muted, mixer, isReady]);
};
