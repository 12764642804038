import { Suspense, lazy, memo } from "react";
import type { RenderPostprocessingProps } from "./RenderPostprocessing";

const RenderPostprocessing = lazy(() =>
  import("./RenderPostprocessing").then((module) => ({ default: module.RenderPostprocessing }))
);

export const RenderPostprocessingLazy = memo((props: RenderPostprocessingProps) => {
  return (
    <Suspense fallback={null}>
      <RenderPostprocessing {...props} />
    </Suspense>
  );
});
