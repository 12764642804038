import { useCallback } from "react";
import { useUnmount } from "react-use";
import { userActions } from "~/view-scene/ControlsSystem";
import { StaticJoystickProps } from "../joystick";

export const useMove = () => {
  const handleChange: NonNullable<StaticJoystickProps["onChange"]> = useCallback((value) => {
    userActions.setMove(value);
  }, []);

  useUnmount(() => {
    userActions.setMove({ side: 0, forward: 0 });
  });

  return {
    handleChange,
  };
};
