import { Select, SelectItem, SelectProps } from "./Select";
import { $entities } from "~/entities/entities";
import { useStoreMap } from "effector-react";
import { SceneObjectType } from "~/types/SceneObjectType";

export type EntitySelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
  typeFilter?: SceneObjectType[];
};

export const EntitySelect = ({ emptyLabel = "Select entity", typeFilter, value, ...rest }: EntitySelectProps) => {
  const entities = useStoreMap({
    store: $entities,
    keys: [typeFilter],
    fn: (entities, [typeFilter]) => {
      if (!entities) {
        return null;
      }
      const items = typeFilter ? entities.filter((entity) => typeFilter.includes(entity.type)) : entities;

      return items?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
  });

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {entities?.map((entity) => (
        <SelectItem key={entity.id} value={entity.id}>
          {entity.name}
        </SelectItem>
      ))}
    </Select>
  );
};
