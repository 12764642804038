import { NodeEvalContext } from "~/libs/behave-graph";
import { TextUIEntity } from "~/types/ui";
import { UITextContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket, UITextSocket } from "../../sockets";
import { UITextValue } from "../../values";

export class UITextSetContentNode extends BaseNode {
  static readonly type = "action/uiTextSetContent";
  static readonly label = "UI Text Set Content";

  inputs = [new FlowSocket("flow"), new UITextSocket("uiText"), new StringSocket("content")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const content = context.readInput<string>("content");
    const imageValue = context.readInput<UITextValue>("uiText");

    node.sceneContext.getUIEntityContext<UITextContext>(imageValue.entityId ?? "")?.updateEntity<TextUIEntity>({
      content,
    });
  }
}
