import { defaultComponent } from "~/entities/variable";
import { BaseSocket } from "../base";
import { constraintValue, ConstraintValue } from "../values";

export class ConstraintSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: ConstraintValue = defaultComponent()) {
    super(constraintValue.name, id, label, value);
  }

  clone() {
    return new ConstraintSocket(this.id, this.label, this.value);
  }
}
