import { useFrame } from "@react-three/fiber";
import { removeAll, update } from "@tweenjs/tween.js";
import { useUnmount } from "react-use";

export const TweenAnimationSystem = () => {
  useUnmount(() => {
    removeAll();
  });

  useFrame((_, delta) => {
    update();
  });

  return null;
};
