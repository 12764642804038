import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberRoundNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberRound";
  static readonly label = "Number Round";

  constructor(context: NodeContext) {
    super(context, (value) => Math.round(value));
  }
}
