import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { AnySocket, MapSocket, StringSocket } from "../../sockets";
import { MapValue, anyValue } from "../../values";

export class MapGetNode extends BaseNode {
  static readonly type = "map/get";
  static readonly label = "Map Get";

  inputs = [new MapSocket("map", anyValue.name), new StringSocket("key")];
  outputs = [new AnySocket("value")];

  eval(context: NodeEvalContext) {
    const map = context.readInput<MapValue>("map");
    const key = context.readInput<string>("key");

    context.writeOutput("value", map[key]);
  }
}
