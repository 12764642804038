import { memo } from "react";
import { LinearEncoding } from "three";
import { Block, BlockProps } from "./Block";
import { useTexture } from "~/view-scene/texture";
import IVideoAsset from "~/types/IVideoAsset";

export type VideoProps = BlockProps & {
  videoAsset?: IVideoAsset;
};

export const Video = memo<VideoProps>(({ videoAsset, ...restProps }) => {
  if (!videoAsset || !videoAsset.url) {
    return null;
  }

  return <RenderVideo videoAsset={videoAsset} {...restProps} />;
});

export type RenderVideoProps = BlockProps & {
  videoAsset: IVideoAsset;
};
const RenderVideo = ({ videoAsset, ...restProps }: RenderVideoProps) => {
  const videoTexture = useTexture({
    name: videoAsset.name,
    type: "video",
    url: videoAsset.url,
    isStream: false,
    loop: true,
    flipY: true,
    autoplay: true,
  });

  if (!videoTexture) {
    return null;
  }

  videoTexture.encoding = LinearEncoding;

  return <Block backgroundOpacity={1} backgroundTexture={videoTexture} {...restProps} />;
};
