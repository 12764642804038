import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket, NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class VectorNotEqualNode extends BaseNode {
  static readonly type = "logic/vectorNotEqual";
  static readonly label = "Vector !=";

  inputs = [new Vector3Socket("a"), new Vector3Socket("b"), new NumberSocket("epsilon")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext) {
    const a = context.readInput<Vector3Value>("a");
    const b = context.readInput<Vector3Value>("b");
    const epsilon = context.readInput<number>("epsilon");

    const equal = Math.abs(a.x - b.x) < epsilon && Math.abs(a.y - b.y) < epsilon && Math.abs(a.z - b.z) < epsilon;
    context.writeOutput<boolean>("result", !equal);
  }
}
