import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket, NumberSocket } from "../../sockets";
import { BaseNode } from "../../base";

export class DelayNode extends BaseNode {
  static readonly type = "time/delay";
  static readonly label = "Delay";
  static category: NodeCategory = "Time";

  async = true;

  inputs = [new FlowSocket("flow"), new NumberSocket("duration", "duration (ms)")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext) {
    let timeIsCancelled = false;
    const duration = context.readInput<number>("duration");

    setTimeout(() => {
      if (timeIsCancelled) {
        return;
      }

      context.commit("flow");
      context.finish();
      context.graphEvaluator.executeAll();
    }, duration);

    context.onAsyncCancelled.addListener(() => {
      timeIsCancelled = true;
    });
  }
}
