import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { EnabledStatus } from "~/types/EnabledStatus";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, EntitySocket, FlowSocket } from "../../sockets";

export class SetEnabledNode extends BaseNode {
  static readonly type = "action/setEnabled";
  static readonly label = "Set enabled";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new BooleanSocket("enabled")];
  outputs = [new FlowSocket("flow")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const enabled = context.readInput<boolean>("enabled");

    const entity = node.readEntity(context, "entity");
    if (!entity) {
      return;
    }

    const entityDescriptor = node.sceneContext.getEntityDescriptor(entity.id);
    if (!entityDescriptor) {
      return;
    }

    node.sceneContext.updateEntityDescriptor({
      ...entityDescriptor,
      enabled: enabled ? EnabledStatus.enabled : EnabledStatus.disabled,
    });
  }
}
