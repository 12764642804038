import { BaseSocket } from "../base";
import { numberValue } from "../values";

export class NumberSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: number = 0) {
    super(numberValue.name, id, label, value);
  }

  clone() {
    return new NumberSocket(this.id, this.label, this.value);
  }
}
