import { createStore } from "effector";
import { useUnit } from "effector-react";
import { EventEmitter } from "eventemitter3";
import { TouchEvent, useMemo } from "react";

export interface CanvasEvents {
  on(event: "touchStart", listener: (event: TouchEvent) => void): this;
  on(event: "touchEnd", listener: (event: TouchEvent) => void): this;
  on(event: "touchMove", listener: (event: TouchEvent) => void): this;

  once(event: "touchStart", listener: (event: TouchEvent) => void): this;
  once(event: "touchEnd", listener: (event: TouchEvent) => void): this;
  once(event: "touchMove", listener: (event: TouchEvent) => void): this;

  off(event: "touchStart", listener: (event: TouchEvent) => void): this;
  off(event: "touchEnd", listener: (event: TouchEvent) => void): this;
  off(event: "touchMove", listener: (event: TouchEvent) => void): this;

  emit(event: "touchStart", payload: TouchEvent): boolean;
  emit(event: "touchEnd", payload: TouchEvent): boolean;
  emit(event: "touchMove", payload: TouchEvent): boolean;
}

export class CanvasEvents extends EventEmitter {}

export const $canvasEvents = createStore(new CanvasEvents());

export const useCanvasEvents = () => {
  const canvasEvents = useUnit($canvasEvents);

  return useMemo(() => {
    return {
      onTouchStart: (e: TouchEvent) => canvasEvents.emit("touchStart", e),
      onTouchEnd: (e: TouchEvent) => canvasEvents.emit("touchEnd", e),
      onTouchMove: (e: TouchEvent) => canvasEvents.emit("touchMove", e),
    };
  }, []);
};
