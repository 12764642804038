import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";
import { Vector3 } from "three";

const tmpVector = new Vector3();

export class VectorLengthNode extends BaseNode {
  static readonly type = "logic/vectorLength";
  static readonly label = "Vector Length";

  inputs = [new Vector3Socket("vector")];
  outputs = [new NumberSocket("length")];

  eval(context: NodeEvalContext) {
    const vector = context.readInput<Vector3Value>("vector");
    tmpVector.set(vector.x, vector.y, vector.z);
    context.writeOutput<number>("length", tmpVector.length());
  }
}
