import { ScreenOverlayItem } from "~/types/ScreenOverlay";
import { useAsset } from "~/entities/assets";
import { UIAsset } from "~/types/ui";
import { RenderUIElement, UIEntitiesProvider } from "../RenderUIElement";
import { ErrorBoundary } from "~/view-scene/utils";
import { useMemo } from "react";
import { $aspectRatio } from "../models";
import { rootId, toHierarchyIds } from "~/common/utils/toHierarchyIds";
import { buildUiEntityId } from "../utils";
import { useUnit } from "effector-react";

export type RenderOverlayProps = {
  overlay: ScreenOverlayItem;
};

export const RenderOverlay = ({ overlay }: RenderOverlayProps) => {
  const asset = useAsset<UIAsset>(overlay.asset);
  const aspectRatio = useUnit($aspectRatio);

  const hierarchy = useMemo(
    () => toHierarchyIds(asset?.entities ?? [], (id) => buildUiEntityId(overlay.id, id)),
    [asset]
  );

  return (
    <UIEntitiesProvider hierarchy={hierarchy}>
      <group scale={[overlay.scale.x, overlay.scale.y, 1]} position={[0, 0, overlay.layer ?? 0]}>
        {(hierarchy[rootId] ?? []).map((entityId) => (
          <ErrorBoundary key={entityId}>
            <RenderUIElement entityId={entityId} aspect={aspectRatio} />
          </ErrorBoundary>
        ))}
      </group>
    </UIEntitiesProvider>
  );
};
