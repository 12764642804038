import { isArray, set, cloneDeep } from "lodash-es";
import { ReactNode } from "react";
import { hasType } from "~/common/utils/hasType";
import { AssetSelectorOnChangeEvent } from "~/hephaestus/assets";
import { DeviceIcon, EntityIcon, PrimitiveIcon } from "~/hephaestus/components";
import { getChangeValue } from "~/hephaestus/utils";
import { AssetType } from "~/types/AssetType";
import ILightSceneObject from "~/types/ILightSceneObject";
import IPlatformSceneObject from "~/types/IPlatformSceneObject";
import IPrimitiveSceneObject from "~/types/IPrimitiveSceneObject";
import ISceneObject from "~/types/ISceneObject";
import { SceneObjectType } from "~/types/SceneObjectType";
import { updateEntity } from "./models";

export const entityTypeToAssetType = (entityType: SceneObjectType) => {
  switch (entityType) {
    case SceneObjectType.IMAGE:
      return AssetType.image;
    case SceneObjectType.AUDIO:
      return AssetType.audio;
    case SceneObjectType.VIDEO:
      return AssetType.video;
    case SceneObjectType.GLB:
      return AssetType.glb;
    case SceneObjectType.INSTANCED_MESH:
      return AssetType.glb;
    case SceneObjectType.UI:
      return AssetType.ui;
  }

  return null;
};

export const handleEntityChange =
  <T extends ISceneObject = ISceneObject>(
    entity: T,
    fieldName: keyof T | string[],
    valueConverter?: Parameters<typeof getChangeValue>[1]
  ) =>
  (e: Parameters<typeof getChangeValue>[0]) => {
    const value = getChangeValue(e, valueConverter);
    const updatedEntity = cloneDeep(entity);

    if (isArray(fieldName)) {
      set(updatedEntity, fieldName, value);
    } else {
      updatedEntity[fieldName] = value as any;
    }

    updateEntity({
      id: entity.id,
      entity: updatedEntity,
    });
  };

export const handleAssetChange = <T extends ISceneObject>(
  entity: T,
  fieldName: keyof T,
  { assetSelection }: AssetSelectorOnChangeEvent
) => {
  const updatedEntity: T = cloneDeep(entity);
  updatedEntity[fieldName] = assetSelection.asset?.id as any;
  updateEntity({
    id: entity.id,
    entity: updatedEntity,
  });
};

export const getIconByEntity = (entity: ISceneObject): ReactNode => {
  if (hasType<ISceneObject, IPrimitiveSceneObject, SceneObjectType>(entity, SceneObjectType.PRIMITIVE)) {
    return <PrimitiveIcon size="small" type={entity.geometry.type} color="primary.gray600" />;
  } else if (hasType<ISceneObject, IPlatformSceneObject, SceneObjectType>(entity, SceneObjectType.PLATFORM)) {
    return <DeviceIcon size="small" type={entity.platform} color="primary.gray600" />;
  } else {
    return <EntityIcon size="small" type={entity.type} color="primary.gray600" />;
  }
};

export const getTypeLabelByEntity = (entity: ISceneObject): string => {
  switch (entity.type) {
    case SceneObjectType.LIGHT:
      return `${(entity as ILightSceneObject).light.lightType} light`;
    case SceneObjectType.PRIMITIVE:
      return `${(entity as IPrimitiveSceneObject).geometry.type}`;
    case SceneObjectType.GROUP:
      return "Group";
    case SceneObjectType.BILLBOARD:
      return "Billboard";
    case SceneObjectType.GLB:
      return "Model";
    case SceneObjectType.INSTANCED_MESH:
      return "Instanced Mesh";
    case SceneObjectType.SCREEN_SHARE:
      return "Screen Share";
    case SceneObjectType.TRIGGER_ZONE:
      return "Trigger Zone";
    case SceneObjectType.TRIGGER_VOLUME:
      return "Trigger Volume";
    case SceneObjectType.TWITTER_BOARD:
      return "Twitter Board";
    case SceneObjectType.NPC:
      return "Character";
    case SceneObjectType.SPAWN_POINT:
      return "Spawn Point";
    case SceneObjectType.UI:
      return "UI";
    case SceneObjectType.PLATFORM: {
      const platform = (entity as IPlatformSceneObject).platform;
      return `${platform[0].toUpperCase() + platform.slice(1)} Only`;
    }
    default:
      return entity.type;
  }
};
