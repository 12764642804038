import { NodeEvalContext } from "behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";

export class CopyToClipboardNode extends BaseNode {
  static readonly type = "other/copyToClipboard";
  static readonly label = "Copy To clipboard";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("text")];
  outputs = [new FlowSocket("success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const text = context.readInput<string>("text");

    navigator.clipboard
      .writeText(text)
      .then(() => {
        context.commit("success");
        context.graphEvaluator.executeAll();
      })
      .catch(() => {
        context.commit("fail");
        context.graphEvaluator.executeAll();
      });
  }
}
