import type { Components, ThemeOptions } from "@mui/material";
import { palette } from "../../palette";

export const MuiTooltip: Components<ThemeOptions>["MuiTooltip"] = {
  styleOverrides: {
    tooltip: {
      padding: "5px 8px",
      background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #000000",
      border: "1px solid " + palette.primary.gray40,
      borderRadius: "8px",
      whiteSpace: "nowrap",
      transition: ".4s ease",
      fontSize: "12px",
      lineHeight: "16px",
      color: palette.primary.gray600,
    },
  },
};
