import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, FlowSocket, UIOverlaySocket } from "../../sockets";
import { UIOverlayValue } from "../../values";

export class SetOverlayVisibilityNode extends BaseNode {
  static readonly type: string = "action/setOverlayVisibilityNode";
  static readonly label: string = "Set Overlay Visibility";

  inputs = [new FlowSocket("flow"), new UIOverlaySocket("overlay"), new BooleanSocket("visible")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const visible = context.readInput<boolean>("visible");
    const { overlayId } = context.readInput<UIOverlayValue>("overlay");

    if (!overlayId) {
      return;
    }

    node.sceneContext.updateOverlay({
      id: overlayId,
      enabled: visible,
    });
  }
}
