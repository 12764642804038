import {useEffect, useState} from "react";

export function useMicrophone(micDevice?: string | null) {
  const [micStream, setMicStream] = useState<MediaStream>();

  useEffect(() => {
    const constraints = {
      audio: {
        deviceId: micDevice ? {exact: micDevice} : undefined,
        // echoCancellation: true
      }
    };
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      setMicStream(stream);
    })
  }, [micDevice]);

  useEffect(() => {
    return () => {
      micStream?.getTracks().forEach(track => track.stop());
    }
  }, [micStream]);

  return micStream;
}