import { NodeEvalContext } from "~/libs/behave-graph";
import { FollowTargetComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, FollowTargetSocket } from "../../sockets";
import { EntityValue } from "../../values";

export class FollowTargetSetTarget extends BaseNode {
  static readonly type = "action/followTargetSetTarget";
  static readonly label = "Follow Set Target";

  inputs = [new FlowSocket("flow"), new FollowTargetSocket("followTarget"), new EntitySocket("target")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = context.readInput<EntityValue>("target");
    const componentContext = node.readComponent<FollowTargetComponentContext>(context, "followTarget");

    if (entity?.entityId) {
      componentContext?.setTarget(entity?.entityId);
    }
  }
}
