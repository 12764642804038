import { useContext, useEffect } from "react";
import { NetworkSystemCtx } from "~/view-scene/network";
import { playPlayerAnimation, playPlayerJumpAnimation, stopPlayerAnimation } from "../models";

export const useBroadcastAnimations = () => {
  const { send } = useContext(NetworkSystemCtx)!;

  useEffect(() => {
    const jumpSubscription = playPlayerJumpAnimation.watch(() => send("playAnimation", { animationName: "JUMP" }));

    const playAnimationSubscription = playPlayerAnimation.watch((animationName) =>
      send("playAnimation", { animationName })
    );

    const stopAnimationSubscription = stopPlayerAnimation.watch((animationName) =>
      send("stopAnimation", { animationName })
    );

    return () => {
      jumpSubscription.unsubscribe();
      playAnimationSubscription.unsubscribe();
      stopAnimationSubscription.unsubscribe();
    };
  }, []);
};
