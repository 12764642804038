import { Action, Direction, InstantAction, userActions } from "./userActions";
import { BaseControls } from "./baseControls";

export class Video360Controls extends BaseControls {
  cameraActive: boolean = true;

  toggleControls = new Action();
  exit = new InstantAction();
  look = new InstantAction<Direction>();

  unSubscriptions: (() => void)[] = [];

  activate() {
    this.unSubscriptions.push(
      this.pipeAction(userActions.exit, this.exit),
      this.pipeAction(userActions.look, this.look, () => this.cameraActive),
      this.pipeAction(userActions.action, this.toggleControls),
      this.pipeAction(userActions.keyQ, this.exit)
    );
  }

  deactivate() {
    for (const unsubscribe of this.unSubscriptions) {
      unsubscribe();
    }
  }

  update() {}

  reset() {}
}

export const video360Controls = new Video360Controls();
