import { NodeEvalContext } from "~/libs/behave-graph";
import * as telegramApi from "~/api/telegram.api";
import { BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class SendScoreNode extends BaseNode {
  static readonly type = "telegram/SendScoreNode";
  static readonly label = "Telegram Send Score";

  inputs = [new FlowSocket("flow"), new NumberSocket("score")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext) {
    const score = context.readInput<number>("score");
    telegramApi.sendScore(score);
  }
}
