import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { ArraySocket, StringSocket } from "../../sockets";
import { EntityValue, entityValue } from "../../values";

export class FindEntitiesByTagNode extends BaseNode {
  static readonly type = "entity/findEntitiesByTag";
  static readonly label = "Find Entities By Tag";

  inputs = [new StringSocket("tag")];
  outputs = [new ArraySocket("array", entityValue.name)];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const tag = context.readInput<string>("tag");
    const entities = node.sceneContext
      .getEntityContexts()
      .filter((entityContext) => entityContext.tags.has(tag))
      .map((entityContext) => ({
        entityId: entityContext.id,
      }));

    context.writeOutput<Array<EntityValue>>("array", entities);
  }
}
