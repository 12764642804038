import { Matrix4, Quaternion, Vector3 } from "three";
import { OcclusionItem } from "../models";

const sortPositionA = new Vector3();
const sortPositionB = new Vector3();
const sortModelView = new Matrix4();
const positionA = new Vector3();
const positionB = new Vector3();
const tmpScale = new Vector3();
const tmpRotation = new Quaternion();

export const depthSort = (a: OcclusionItem, b: OcclusionItem, viewMatrix: Matrix4) => {
  a.matrix.decompose(positionA, tmpRotation, tmpScale);
  b.matrix.decompose(positionB, tmpRotation, tmpScale);

  sortPositionA.copy(positionA);
  sortPositionB.copy(positionB);

  sortModelView.copy(viewMatrix).multiply(a.matrix);
  sortPositionA.applyMatrix4(sortModelView);
  sortModelView.copy(viewMatrix).multiply(b.matrix);
  sortPositionB.applyMatrix4(sortModelView);

  return sortPositionB.z - sortPositionA.z;
};

export const depthSortSimple = (a: OcclusionItem, b: OcclusionItem, viewPosition: Vector3) => {
  const distanceA = a.position.distanceTo(viewPosition);
  const distanceB = b.position.distanceTo(viewPosition);

  return distanceA - distanceB;
};
