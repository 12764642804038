import { Stack, StackProps, styled, Typography } from "@mui/material";
import { ChangeEvent, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Checkbox } from "../Checkbox";

export type CollisionGroupInspectorProps = Pick<StackProps, "sx" | "className" | "alignItems" | "justifyContent"> & {
  value: number;
  name?: string;
  items: CollisionGroupItem[];
  onChange: (e: CollisionGroupInspectorEvent) => void;
};

export type CollisionGroupItem = {
  mask: number;
  label: string;
};

export type CollisionGroupInspectorEvent = {
  target: {
    name: string;
    value: number;
  };
};

export const CollisionGroupInspector = ({
  value,
  name,
  items,
  sx,
  className,
  alignItems,
  justifyContent,
  onChange,
}: CollisionGroupInspectorProps) => {
  const uid = useMemo(() => uuid(), []);

  const handleChange = (item: CollisionGroupItem) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked ? value | item.mask : value & ~item.mask;

    onChange({
      target: {
        name: name ?? "",
        value: newValue,
      },
    });
  };

  return (
    <Container direction="column" sx={sx} className={className} alignItems={alignItems} justifyContent={justifyContent}>
      {items.map((item, index) => (
        <Stack key={index} direction="row" justifyContent="space-between" alignItems="center">
          <Label htmlFor={getId(uid, index)}>
            <Typography variant="body1">{item.label}</Typography>
          </Label>
          <Checkbox id={getId(uid, index)} checked={Boolean(item.mask & value)} onChange={handleChange(item)} />
        </Stack>
      ))}
    </Container>
  );
};

const Container = styled(Stack)`
  grid-gap: 8px;
`;

const Label = styled("label")`
  cursor: pointer;
`;

const getId = (uid: string, index: number) => `${uid}-${index}`;
