import { useSceneData } from "~/common/stores/useSceneData";
import { useSceneType } from "~/view-scene/hooks";
import { DefaultWelcomeScreen } from "./DefaultWelcomeScreen";
import { ShardQuestWelcomeScreen } from "./ShardQuestWelcomeScreen";
import { InitSystems } from "./InitSystems";

export function WelcomeScreen() {
  const startOverlayEnabled =
    useSceneData((state) => state.sceneState?.platformInterfaceSystem.startOverlayEnabled) ?? true;
  const type = useSceneType();

  if (!startOverlayEnabled) {
    return <InitSystems />;
  }

  if (type === "shardQuest") {
    return <ShardQuestWelcomeScreen />;
  }

  return <DefaultWelcomeScreen />;
}
