import { Slider as MuiSlider, SliderProps, styled } from "@mui/material";
import { palette } from "~/theme";

export const Slider = styled((props: SliderProps) => <MuiSlider {...props} />)(() => ({
  flex: 1,
  color: palette.primary.gray200,
  width: "100%",
  "&.MuiSlider-colorSecondary": {
    height: "2px",
  },
  ".MuiSlider-thumb": {
    color: palette.primary.gray800,
    width: "13px",
    height: "12px",
  },
  ".MuiSlider-thumb:hover, .MuiSlider-thumb.Mui-focusVisible": {
    boxShadow: "none !important",
  },
  ".MuiSlider-thumb:after": {
    width: "16px",
    height: "16px",
  },
}));
