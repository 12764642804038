import { NodeContext } from "../../base";
import { ActionableSocket } from "../../sockets/ActionableSocket";
import { SceneComponentNode } from "./SceneComponentNode";

export class SceneActionableNode extends SceneComponentNode {
  static readonly type = "variable/sceneActionable";
  static readonly label = "Actionable";

  constructor(context: NodeContext) {
    super(context, new ActionableSocket("actionable"));
  }
}
