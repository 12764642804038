import { Select as MuiSelect, SelectProps as MuiSelectProps, styled } from "@mui/material";
import { DownArrow } from "~/common/components/Icon";
import { palette } from "~/theme";

export type SelectChangeEvent<TValue = any> = {
  target: {
    name: string;
    value: TValue;
  };
};

export type SelectProps<TValue = any> = Omit<MuiSelectProps<TValue>, "onChange"> & {
  onChange?: (e: SelectChangeEvent<TValue>) => void;
};

export const Select = <TValue, V>({ children, onChange, sx, ...rest }: SelectProps<TValue>) => (
  <MuiSelect
    onChange={onChange as any}
    MenuProps={{
      PaperProps: {
        sx: {
          border: "1px solid " + palette.primary.gray20,
          background: palette.primary.gradient600,
          borderRadius: "12px",
          padding: "4px",
          maxWidth: "100%",
          width: "auto",
        },
      },
    }}
    IconComponent={SelectIcon}
    sx={{
      fontFamily: "Plus Jakarta Sans !important",
      color: palette.primary.gray600,
      display: "flex",
      textTransform: "inherit",
      padding: "4px 0 4px 0",
      transition: ".4s ease",
      "&:hover": {
        color: palette.primary.violet,
        borderColor: palette.primary.violet,
        backgroundColor: palette.primary.violet200 + " !important",
      },
      minWidth: "105px",
      width: "100%",
      flex: 1,
      maxHeight: "28px",
      backgroundColor: palette.primary.gray40 + " !important",
      border: "1px solid " + palette.primary.gray40,
      ul: {
        border: "1px solid " + palette.primary.gray20,
        background: palette.primary.gradient600,
        padding: "4px",
        borderRadius: "12px",
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </MuiSelect>
);

const SelectIcon = () => <DownArrowIcon size="small" />;

const DownArrowIcon = styled(DownArrow)`
  top: 6px;
  right: 7px;
  position: absolute;
  pointer-events: none;
`;
