import { createEffect, createStore } from "effector";
import { keyBy } from "lodash-es";
import { createStruct } from "~/api/assets.api";
import { IStructAsset } from "~/types/IStructAsset";
import { $assets } from "../assets";

export const createStructFx = createEffect(async ({ workspaceId }: { workspaceId: string }) => {
  const { body } = await createStruct(workspaceId);

  return body as IStructAsset;
});

export const $structs = createStore<IStructAsset[]>([]).on($assets, (state, assets) => {
  const structs = (assets?.filter((asset) => asset.type === "struct") as IStructAsset[]) ?? [];

  if (state.length !== structs.length) {
    return structs;
  }

  for (let i = 0; i < structs.length; i++) {
    if (state[i] !== structs[i]) {
      return structs;
    }
  }

  return state;
});

export const $structsMap = $structs.map((structs) => keyBy(structs, "id"));
