import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class InventoryItemSetNode extends BaseNode {
  static readonly type = "variable/inventoryItemSet";
  static readonly label = "Set Inventory Item";

  inputs = [new FlowSocket("flow"), new NumberSocket("count")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const storageId = node.readMetadata("storageId", null);
    const fieldId = node.readMetadata("fieldId", null);
    if (!storageId || !fieldId) {
      return;
    }
    const values: Record<string, any> = {};
    values[fieldId] = context.readInput<number>("count");
    node.sceneContext.updateUserStorageData(storageId, values);
  }
}
