import { fontFamilies } from "~/config";

type FontFamily = keyof typeof fontFamilies;
type FontWeight = "regular" | "bold";

export const getFontUrl = (fontFamily: string, fontWeight: string) => {
  const font = fontFamilies[fontFamily as FontFamily];

  if (!font) {
    return null;
  }

  const weight = font[fontWeight as FontWeight];

  if (!weight) {
    return null;
  }

  return weight.url;
};

export const getDefaultFontUrl = () => fontFamilies.story.regular.url;
