import { memo, ReactNode, useEffect, useMemo } from "react";
import { UIAsset } from "~/types/ui";
import { useAsset } from "~/entities/assets";
import { buildUiEntityId, RenderUIElement } from "~/view-scene/ui";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Entity } from "../Entity";
import { useUIEntityContext } from "./hooks";
import { UIEntitiesProvider } from "~/view-scene/ui/RenderUIElement/UIEntitiesContext";
import { rootId, toHierarchyIds } from "~/common/utils/toHierarchyIds";
import { addEntities, removeEntities } from "~/view-scene/runtime";

export type UIEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const UIEntity = memo(({ entityId, children }: UIEntityProps) => {
  const entity = useEntity(entityId);
  const asset = useAsset<UIAsset>(entity.asset);

  const { contextRef } = useUIEntityContext(entityId);

  const hierarchy = useMemo(
    () => toHierarchyIds(asset?.entities ?? [], (id) => buildUiEntityId(entityId, id)),
    [asset]
  );

  useEffect(() => {
    const entities = asset?.entities ?? [];
    const entitiesStatePayload = entities.map((entity) => {
      return {
        id: buildUiEntityId(entityId, entity.id),
        entity: { ...entity },
      };
    });
    addEntities({
      entities: entitiesStatePayload,
    });

    return () => {
      removeEntities(entities.map((entity) => buildUiEntityId(entityId, entity.id)));
    };
  }, []);

  return (
    <Entity entityId={entityId} context={contextRef}>
      <UIEntitiesProvider hierarchy={hierarchy}>
        {(hierarchy[rootId] ?? []).map((entityId) => (
          <RenderUIElement key={entityId} entityId={entityId} />
        ))}
      </UIEntitiesProvider>
      {children}
    </Entity>
  );
});
