import { Modal } from "common/components/Modal";
import { RemoteUser } from "view-scene/network/types";
import { palette } from "~/theme";
import Avatar from "common/components/Avatar";
import { Stack } from "@mui/material";
import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

type RemoteUsersMobileModalProps = {
  open: boolean;
  onClose: VoidFunction;
  remoteUsersCount: number;
  first10RemoteUsers: RemoteUser[];
};

export function RemoteUsersMobileModal({
  open,
  onClose,
  remoteUsersCount,
  first10RemoteUsers,
}: RemoteUsersMobileModalProps) {
  const modalTitle =
    remoteUsersCount > 0 ? (remoteUsersCount === 1 ? `1 Player` : `${remoteUsersCount} Players`) : "No Players";

  return (
    <Modal open={open} onClose={onClose} TitleIcon={VisibilityOutlinedIcon} title={modalTitle}>
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={0.5}
        minWidth={327}
        sx={{
          overflow: "auto",
          maxHeight: "calc(100vh - 200px)",
          "&::-webkit-scrollbar": {
            width: "0",
          },
          "&::-webkit-scrollbar-track": {
            background: palette.primary.gray200,
          },
          "&::-webkit-scrollbar-thumb": {
            background: palette.primary.gray600,
          },
        }}
      >
        {first10RemoteUsers.map((remoteUser, index) => {
          return (
            <Avatar
              key={index}
              text={remoteUser.name}
              avatar={remoteUser.avatarPreview ?? "/static/img/content/avatar.webp"}
            />
          );
        })}
      </Stack>
    </Modal>
  );
}
