import { ValueType } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { AssetValue } from "./assetValue";

export type AssetImageValue = AssetValue;

export const assetImageValue = new ValueType(
  "assetImage",
  defaultAsset,
  (json: AssetImageValue) => json,
  (value: AssetImageValue) => value
);
