import { Icon, IconProps } from "./Icon";

export type FancyLogoProps = IconProps;

export const FancyLogo = (props: FancyLogoProps) => (
  <Icon width="76px" height="33px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 33" {...props}>
    <path
      d="M18.0264 16.5C16.2015 14.0667 13.2861 12.5 10.0146 12.5C6.74319 12.5 3.82782 14.0667 2.00293 16.5C0.745534 14.8333 0 12.7556 0 10.5C0 4.97778 4.48433 0.5 10.0146 0.5C15.5449 0.5 20.0293 4.97778 20.0293 10.5C20.0293 12.7556 19.2837 14.8333 18.0264 16.5Z"
      fill="url(#paint0_linear_4_193)"
      fillOpacity="0.8"
    />
    <path
      d="M18.0264 16.5C19.2837 18.1667 20.0293 20.2444 20.0293 22.5C20.0293 28.0222 15.5449 32.5 10.0146 32.5C4.48433 32.5 0 28.0222 0 22.5C0 20.2444 0.745534 18.1667 2.00293 16.5C3.82782 18.9333 6.74319 20.5 10.0146 20.5C13.2861 20.5 16.2015 18.9333 18.0264 16.5Z"
      fill="url(#paint1_linear_4_193)"
      fillOpacity="0.8"
    />
    <path
      d="M48.3707 31.5556H41.7277L38.4228 26.6889C37.0208 24.6556 35.7078 22.6333 34.3947 20.5556C33.0817 22.6333 31.7687 24.6556 30.3666 26.6889L27.0173 31.5556H20.5411L31.1789 16.2L21.1865 1.43334H27.7962L30.8006 5.92223C32.1136 7.86667 33.3376 9.81112 34.5283 11.7222C34.7697 11.3474 35.0116 10.9709 35.2544 10.5929L35.2556 10.5911L35.2557 10.591C36.2434 9.05336 37.2469 7.49137 38.3004 5.92223L41.3493 1.43334H47.7921L37.8331 16.0667L48.3707 31.5556Z"
      fill="url(#paint2_linear_4_193)"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.6384 20.2667H62.0129L69.4794 31.5556H76L67.9883 19.5889C72.5171 18.4556 75.0208 15.1111 75.0208 10.7111C75.0208 5.21111 71.1262 1.44444 64.3496 1.44444H50.9189V31.5556H56.6384V20.2667ZM64.0492 15.6556H56.6384V6.14444H64.0492C67.3874 6.14444 69.2568 8.08889 69.2568 10.8778C69.2568 13.7111 67.3985 15.6556 64.0492 15.6556Z"
      fill="url(#paint3_linear_4_193)"
      fillOpacity="0.8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4_193"
        x1="0"
        y1="32.5"
        x2="78.6908"
        y2="9.84751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8235E3" />
        <stop offset="1" stopColor="#D3C1FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_193"
        x1="0"
        y1="32.5"
        x2="78.6908"
        y2="9.84751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8235E3" />
        <stop offset="1" stopColor="#D3C1FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4_193"
        x1="0"
        y1="32.5"
        x2="78.6908"
        y2="9.84751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8235E3" />
        <stop offset="1" stopColor="#D3C1FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4_193"
        x1="0"
        y1="32.5"
        x2="78.6908"
        y2="9.84751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8235E3" />
        <stop offset="1" stopColor="#D3C1FF" />
      </linearGradient>
    </defs>
  </Icon>
);
