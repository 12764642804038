import { NodeContext } from "../../base";
import { BitwiseOperationNode } from "./BitwiseOperationNode";

export class BitwiseOrNode extends BitwiseOperationNode {
  static readonly type = "logic/bitwiseOr";
  static readonly label = "Bitwise OR";

  constructor(context: NodeContext) {
    super(context, (a, b) => a | b);
  }
}
