import { createEvent } from "effector";
import { AssetType } from "~/types/AssetType";
import IAsset from "~/types/IAsset";

export type AssetSelection = {
  asset?: IAsset;
  variantId?: string;
};

export type AssetModalVariant = "editor" | "scriptEditor" | "uiEditor";

export type AssetModalState = {
  open: boolean;
  mode: "browse" | "assetSelection" | "cubemapImageSelection";
  selectorId?: string;
  insertAssetTypes?: AssetType[] | null;
  assetTypes?: AssetType[] | null;
  selectedAssetId?: string | null;
  variant?: "editor" | "scriptEditor" | "uiEditor";
  onlyUsed?: boolean;
  viewMode?: "table" | "grid";
  onSelectionCallback?: (assetSelection: AssetSelection) => void;
};

export const openAssetsModal = createEvent<AssetModalState>();
export const closeAssetsModal = createEvent();
