import {
  CSSProperties,
  ReactNode,
  RefObject,
  TouchEvent,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSizes } from "../hooks";
import { RenderJoystick } from "./RenderJoystick";
import { JoystickValue, Position } from "./types";
import { calcPosition, getTouchPosition } from "./utils";

export type DynamicJoystickProps = {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  placeholder?: ReactNode;
  onChange?: (value: JoystickValue) => void;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
};

export const DynamicJoystick = forwardRef<HTMLDivElement, DynamicJoystickProps>(
  ({ className, style, children, placeholder = null, onChange, onTouchStart, onTouchEnd }, ref) => {
    const joystickRef = useRef<HTMLDivElement>(null);
    const stickRef = useRef<HTMLDivElement>(null);
    const ownRef = useRef<HTMLDivElement>(null);
    const containerRef = (ref as RefObject<HTMLDivElement>) ?? ownRef;
    const [joystickPosition, setJoystickPosition] = useState<Position | null>(null);
    const sizes = useSizes();

    const renderPosition = useMemo(() => {
      if (!containerRef.current || !joystickPosition) {
        return { x: 0, y: 0 };
      }

      const containerRect = containerRef.current.getBoundingClientRect();

      return {
        x: joystickPosition.x - containerRect.x,
        y: joystickPosition.y - containerRect.y,
      };
    }, [joystickPosition]);

    const handleTouchStart = useCallback(
      (e: TouchEvent) => {
        e.preventDefault();
        const touchPosition = getTouchPosition(e);

        setJoystickPosition({
          x: touchPosition.x - sizes.joystickSize / 2,
          y: touchPosition.y - sizes.joystickSize / 2,
        });
        onTouchStart?.();
      },
      [sizes, onTouchStart]
    );

    const handleTouchMove = useCallback(
      (e: TouchEvent) => {
        e.preventDefault();

        if (!joystickPosition) {
          return;
        }

        const touchPosition = getTouchPosition(e);
        const result = calcPosition(touchPosition, joystickPosition, sizes.joystickSize);

        if (stickRef.current) {
          stickRef.current.style.left = result.stickPosition.x + "px";
          stickRef.current.style.top = result.stickPosition.y + "px";
        }

        onChange?.(result.value);
      },
      [sizes, joystickPosition, onChange]
    );

    const handleTouchEnd = useCallback(
      (e: TouchEvent) => {
        e.preventDefault();

        if (stickRef.current) {
          stickRef.current.style.left = "";
          stickRef.current.style.top = "";
        }

        setJoystickPosition(null);
        onChange?.({ side: 0, forward: 0 });
        onTouchEnd?.();
      },
      [sizes, onChange, onTouchEnd]
    );

    return (
      <div
        className={className}
        style={{ position: "relative", ...style }}
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <RenderJoystick
          ref={joystickRef}
          stickRef={stickRef}
          style={{
            top: renderPosition.y,
            left: renderPosition.x,
            display: joystickPosition ? "flex" : "none",
          }}
        >
          {children}
        </RenderJoystick>
        {!joystickPosition && placeholder}
      </div>
    );
  }
);
