import { Dispatch, SetStateAction } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import { palette } from "~/theme";
import { Checked } from "~/common/components/Icon";
import { Navigate } from "~/common/components";

type TermsAndConditionsProps = {
  agreedTermsAndConditions: boolean;
  setAgreedTermsAndConditions: Dispatch<SetStateAction<boolean>>;
  agreedPrivacyPolicy: boolean;
  setAgreedPrivacyPolicy: Dispatch<SetStateAction<boolean>>;
};

export function TermsAndConditions({
  agreedTermsAndConditions,
  setAgreedTermsAndConditions,
  agreedPrivacyPolicy,
  setAgreedPrivacyPolicy,
}: TermsAndConditionsProps) {
  return (
    <Stack
      direction="column"
      sx={{
        padding: "0 12px",
        gridGap: "24px",
        marginTop: "24px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={agreedTermsAndConditions}
              onChange={() => setAgreedTermsAndConditions(!agreedTermsAndConditions)}
              checkedIcon={<Checked color="primary.white" size="small" />}
              sx={{
                width: "20px",
                borderRadius: "6px",
                marginRight: "8px",
                height: "20px",
                overflow: "hidden",
                border: "1px solid" + palette.primary.violet,
                background: "transparent",
                "&.Mui-checked": {
                  background: palette.primary.violet,
                },
              }}
            />
          }
          label={
            <Box
              component="div"
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "20px",
                color: palette.primary.white,
                textDecoration: "none",
                transition: ".4s ease",
              }}
            >
              I have read and agree to these
              <Navigate
                to="https://www.8xr.io/utility/standard-terms-and-conditions"
                openInNewWindow={true}
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  marginLeft: "5px",
                  lineHeight: "20px",
                  color: palette.primary.violet,
                  borderBottom: "1px solid " + palette.primary.violet600,
                  textDecoration: "none",
                  transition: ".4s ease",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              >
                terms and conditions
              </Navigate>
            </Box>
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={agreedPrivacyPolicy}
              onChange={() => setAgreedPrivacyPolicy(!agreedPrivacyPolicy)}
              checkedIcon={<Checked color="primary.white" size="small" />}
              sx={{
                width: "20px",
                borderRadius: "6px",
                marginRight: "8px",
                height: "20px",
                overflow: "hidden",
                border: "1px solid" + palette.primary.violet,
                background: "transparent",
                "&.Mui-checked": {
                  background: palette.primary.violet,
                },
              }}
            />
          }
          label={
            <Box
              component="div"
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "20px",
                color: palette.primary.white,
                textDecoration: "none",
                transition: ".4s ease",
              }}
            >
              I agree to 8XR
              <Navigate
                to="https://8xr.io/utility/privacy-policy"
                openInNewWindow={true}
                sx={{
                  fontWeight: 500,
                  fontSize: "16px",
                  marginLeft: "5px",
                  lineHeight: "20px",
                  color: palette.primary.violet,
                  borderBottom: "1px solid " + palette.primary.violet600,
                  textDecoration: "none",
                  transition: ".4s ease",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              >
                Privacy Policy
              </Navigate>
            </Box>
          }
        />
      </FormGroup>
    </Stack>
  );
}
