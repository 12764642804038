import { memo, ReactNode, useRef } from "react";
import { Entity } from "../Entity";
import IPrimitiveSceneObject from "~/types/IPrimitiveSceneObject";
import { RenderPrimitive } from "./RenderPrimitive";
import { useOcclusionCulling } from "~/view-scene/occlusionCulling";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Object3D } from "three";

type PrimitiveEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const PrimitiveEntity = memo(({ entityId, children }: PrimitiveEntityProps) => {
  const entity = useEntity<IPrimitiveSceneObject>(entityId);
  const ref = useRef<Object3D>(null);

  useOcclusionCulling(ref, entity);

  if (!entity) {
    return null;
  }

  return (
    <Entity entityId={entityId} ref={ref}>
      <RenderPrimitive dto={entity} />
      {children}
    </Entity>
  );
});
