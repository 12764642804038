import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { getTonConnect } from "./getTonConnect";

export class TonConnectOpenNode extends BaseNode {
  static readonly type = "web3/tonConnectOpen";
  static readonly label = "Open TonConnect modal";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("manifest")];
  outputs = [new FlowSocket("flow", "success"), new FlowSocket("fail")];

  eval(context: NodeEvalContext) {
    const tonConnectUI = getTonConnect(context.readInput<string>("manifest"));

    async function connectToWallet() {
      await tonConnectUI.connectionRestored;

      if (tonConnectUI.connected) {
        console.error("Wallet already connected");
        context.commit("fail");
        context.graphEvaluator.executeAll();
      } else {
        await tonConnectUI.openModal();

        const unsubscribeModal = tonConnectUI.onModalStateChange((state) => {
          if (state.status === "closed") {
            unsubscribeModal();

            if (tonConnectUI.wallet) {
              context.commit("flow");
              context.graphEvaluator.executeAll();
            } else {
              context.commit("fail");
              context.graphEvaluator.executeAll();
            }
          }
        });
      }
    }

    connectToWallet();
  }
}
