import { ReactNode, TouchEvent, useCallback, useEffect, useRef } from "react";
import { Box, BoxProps, styled } from "@mui/material";
import { RenderJoystick } from "./RenderJoystick";
import { calcPosition, getTouchPosition } from "./utils";
import { JoystickValue, Position } from "./types";
import { useSizes } from "../hooks";

export type StaticJoystickProps = Pick<BoxProps, "className" | "sx" | "style"> & {
  children: ReactNode;
  onChange?: (value: JoystickValue) => void;
  onTouchEnd?: () => void;
};

export const StaticJoystick = ({ className, style, sx, children, onChange, onTouchEnd }: StaticJoystickProps) => {
  const joystickRef = useRef<HTMLDivElement>(null);
  const stickRef = useRef<HTMLDivElement>(null);
  const joystickPosition = useRef<Position>({ x: 0, y: 0 });
  const sizes = useSizes();

  useEffect(() => {
    const calcPosition = () => {
      joystickPosition.current = joystickRef.current?.getBoundingClientRect() ?? { x: 0, y: 0 };
    };

    window.addEventListener("resize", calcPosition);
    calcPosition();

    return () => {
      window.removeEventListener("resize", calcPosition);
    };
  }, []);

  const handleTouch = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      const touchPosition = getTouchPosition(e);
      const result = calcPosition(touchPosition, joystickPosition.current, sizes.joystickSize);

      if (stickRef.current) {
        stickRef.current.style.left = result.stickPosition.x + "px";
        stickRef.current.style.top = result.stickPosition.y + "px";
      }

      onChange?.(result.value);
    },
    [sizes, onChange]
  );

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();

      if (stickRef.current) {
        stickRef.current.style.left = "";
        stickRef.current.style.top = "";
      }

      onChange?.({ side: 0, forward: 0 });
      onTouchEnd?.();
    },
    [sizes, onChange, onTouchEnd]
  );

  return (
    <Container
      className={className}
      style={style}
      sx={{ width: sizes.joystickSize, height: sizes.joystickSize, ...sx }}
      onTouchStart={handleTouch}
      onTouchMove={handleTouch}
      onTouchEnd={handleTouchEnd}
    >
      <RenderJoystick ref={joystickRef} stickRef={stickRef}>
        {children}
      </RenderJoystick>
    </Container>
  );
};

const Container = styled(Box)``;
