import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { FlowSocket, MapSocket, StringSocket } from "../../sockets";
import { MapValue, anyValue } from "../../values";

export class MapHasNode extends BaseNode {
  static readonly type = "map/has";
  static readonly label = "Map Has";

  inputs = [new FlowSocket(), new MapSocket("map", anyValue.name), new StringSocket("key")];
  outputs = [new FlowSocket("has"), new FlowSocket("dontHas", "don't has")];

  eval(context: NodeEvalContext) {
    const map = context.readInput<MapValue>("map");
    const key = context.readInput<string>("key");

    if (map[key] === undefined) {
      context.commit("dontHas");
    } else {
      context.commit("has");
    }
  }
}
