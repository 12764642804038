import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BooleanSocket, FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class FlipFlopNode extends BaseNode {
  static readonly type = "flow/flipFlop";
  static readonly label = "Flip Flop";
  static category: NodeCategory = "Flow";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("a"), new FlowSocket("b"), new BooleanSocket("isA")];

  initNode(node: BaseBehaveNode) {
    node.storage.isA = false;
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    context.writeOutput<boolean>("isA", node.storage.isA);
    context.commit(node.storage.isA ? "a" : "b");
    node.storage.isA = !node.storage.isA;
  }
}
