import { BlockchainNetwork } from "../types";

export const networkToIcon = (network: BlockchainNetwork) => {
  switch (network) {
    case BlockchainNetwork.ethereum:
      return "/static/img/content/ethereum.svg";
    case BlockchainNetwork.solana:
      return "/static/img/content/solana.svg";
    case BlockchainNetwork.polygon:
      return "/static/img/content/polygon.svg";
    case BlockchainNetwork.ton:
      return "/static/img/content/ton.svg";
    default:
      return "";
  }
};
