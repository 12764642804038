import { createEvent, sample } from "effector";
import { $sceneData, updateScene } from "~/entities/sceneData";
import ISceneObject from "~/types/ISceneObject";

export const addEntities = createEvent<ISceneObject[]>();
export const addEntity = addEntities.prepend((entity: ISceneObject) => [entity]);
export const updateEntity = createEvent<{
  id: string;
  entity: Partial<ISceneObject>;
}>();
export const deleteEntities = createEvent<string[]>();
export const deleteEntity = deleteEntities.prepend((id: string) => [id]);

export const $entities = $sceneData.map((sceneData) => (sceneData ? sceneData.objects : null));

sample({
  source: $sceneData,
  clock: addEntities,
  fn: (sceneData, entities) => {
    if (!sceneData) {
      return {};
    }

    return {
      ...sceneData,
      objects: [...sceneData.objects, ...entities],
    };
  },
  target: updateScene,
});

sample({
  source: $sceneData,
  clock: updateEntity,
  fn: (sceneData, { id, entity }) => {
    if (!sceneData) {
      return {};
    }

    return {
      ...sceneData,
      objects: sceneData.objects.map((object) => (object.id === id ? { ...object, ...entity } : object)),
    };
  },
  target: updateScene,
});

sample({
  source: $sceneData,
  clock: deleteEntities,
  fn: (sceneData, ids) => {
    if (!sceneData) {
      return {};
    }

    const idsSet = new Set(ids);

    return {
      ...sceneData,
      objects: sceneData.objects.filter((entity) => !idsSet.has(entity.id)),
    };
  },
  target: updateScene,
});
