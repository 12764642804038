import { useMemo } from "react";
import { AnimationAction, AnimationMixer } from "three";
import { useGLTF } from "~/view-scene/utils";
import { AvatarAnimation } from "~/types/AvatarSystem";
import { getVariantUrl, useAssets } from "~/entities/assets";
import IModelAsset from "~/types/IModelAsset";

export type AnimationsConfig = {
  mixer: AnimationMixer;
  sex: "male" | "female";
  avatarAnimations: AvatarAnimation[];
};

export const useAnimations = ({ mixer, sex, avatarAnimations }: AnimationsConfig) => {
  const animationAssetIdToAvatarAnimationsMap = useMemo(
    () =>
      avatarAnimations.reduce((map, animation) => {
        const animationAssetId = sex === "male" ? animation.maleAsset : animation.femaleAsset;
        if (animationAssetId) {
          const bucket = map.get(animationAssetId);
          if (bucket) {
            bucket.push(animation);
          } else {
            map.set(animationAssetId, [animation]);
          }
        }
        return map;
      }, new Map<string, AvatarAnimation[]>()),
    [avatarAnimations, sex]
  );

  const assetIds = useMemo(
    () => [...animationAssetIdToAvatarAnimationsMap.keys()],
    [animationAssetIdToAvatarAnimationsMap]
  );
  const animationAssets = useAssets<IModelAsset>(assetIds);

  const animationUrlToAvatarAnimations = useMemo(
    () =>
      Object.keys(animationAssets).reduce((map, animationAssetId) => {
        const animationAsset = animationAssets[animationAssetId];
        if (animationAsset) {
          const animationUrl = getVariantUrl(animationAsset);
          const avatarAnimations = animationAssetIdToAvatarAnimationsMap.get(animationAssetId);
          if (animationUrl && avatarAnimations) {
            map.set(animationUrl, [...avatarAnimations]);
          }
        }
        return map;
      }, new Map<string, AvatarAnimation[]>()),
    [animationAssets, animationAssetIdToAvatarAnimationsMap]
  );

  const animationUrls = useMemo(() => [...animationUrlToAvatarAnimations.keys()], [animationUrlToAvatarAnimations]);
  const animationModels = useGLTF(animationUrls);

  return useMemo(() => {
    const actions: Record<string, AnimationAction> = {};

    animationUrls.forEach((animationUrl, index) => {
      const animationModel = animationModels[index];
      const avatarAnimations = animationUrlToAvatarAnimations.get(animationUrl);
      if (animationModel && avatarAnimations) {
        avatarAnimations.forEach((avatarAnimation) => {
          const animationClipName =
            sex === "male" ? avatarAnimation.maleAnimationName : avatarAnimation.femaleAnimationName;
          const clip = animationModel.animations.find((clip) => clip.name === animationClipName);
          if (!clip) {
            throw new Error(`useEmotion: Animation clip "${animationClipName}" not found in "${animationUrl}"`);
          }
          actions[avatarAnimation.id] = mixer.clipAction(clip);
        });
      }
    });

    return actions;
  }, [mixer, animationUrls, animationModels, animationUrlToAvatarAnimations]);
};
