import { palette } from "~/theme";
import { Box } from "@mui/material";

type CheckboxLabelProps = {
  label: string;
};

export function CheckboxLabel({ label }: CheckboxLabelProps) {
  return (
    <Box
      component="div"
      sx={{
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "16px",
        color: palette.primary.violet600,
        textDecoration: "none",
        textTransform: "uppercase",
        transition: ".4s ease",
      }}
    >
      {label}
    </Box>
  );
}
