import { Stack, styled } from "@mui/material";
import { useUnit } from "effector-react";
import { Progress } from "~/common/components";
import { fakeProgress, $progress } from "../models";
import { HyperTips } from "./HyperTips";

export const HyperLoadingScreen = () => {
  // const progress = useUnit($progress);
  const hyperLogo = "/static/img/content/hyper-logo-farming.png";

  return (
    <Container>
      <ContentContainer>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Logo src={hyperLogo} />
          <StyledProgress progress={fakeProgress()} />
        </Stack>
      </ContentContainer>
      <TipsContainer>
        <StyledTips />
      </TipsContainer>
    </Container>
  );
};

const Container = styled(Stack)`
  background-color: #010101;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;

const ContentContainer = styled(Stack)`
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -75%);
`;

const Logo = styled("img")`
  width: 326px;
  height: auto;
  padding-bottom: 80px;
`;

const StyledProgress = styled(Progress)`
  width: 180px;
  margin-top: -68px;
`;

const TipsContainer = styled(Stack)`
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  max-width: 500px;
`;

const StyledTips = styled(HyperTips)`
  margin: 0 30px 60px 30px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;
