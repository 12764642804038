import { NodeEvalContext } from "~/libs/behave-graph";
import { AudioContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AudioSocket, FlowSocket } from "../../sockets";

export class AudioPauseNode extends BaseNode {
  static readonly type = "action/audioPause";
  static readonly label = "Pause audio";

  inputs = [new FlowSocket("flow"), new AudioSocket("audio")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readEntity<AudioContext>(context, "audio")?.pause();
  }
}
