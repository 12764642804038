import { ValueDescriptor } from "~/types/Variable";
import { valueTypesSet } from "../constants";

export const isValueDescriptor = (variable: ValueDescriptor | any): variable is ValueDescriptor => {
  return variable && variable.type && isValueDescriptorType(variable.type);
};

export const isValueDescriptorType = (type: string | ValueDescriptor["type"]): type is ValueDescriptor["type"] => {
  return valueTypesSet.has(type as any);
};
