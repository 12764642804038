import { NodeSpec } from "~/types/ScriptAsset";
import { SocketConstructorClass } from "./BaseSocket";

const keyPrefix = "__sockets_array__";

const defaultNameGenerator = (_id: string, index: number) => index.toString();
const defaultIdGenerator = (id: string) => id;

export type SocketsArrayConfig = {
  id: string;
  SocketConstructor: SocketConstructorClass;
  maxCount?: number;
  nameGenerator?: (id: string, index: number) => string;
  idGenerator?: (id: string) => string;
};

export class SocketsArray {
  readonly id: string;
  readonly key: string;
  readonly maxCount: number;

  private SocketConstructor: SocketsArrayConfig["SocketConstructor"];
  private nameGenerator: NonNullable<SocketsArrayConfig["nameGenerator"]>;
  private idGenerator: NonNullable<SocketsArrayConfig["idGenerator"]>;

  constructor({
    id,
    SocketConstructor,
    nameGenerator = defaultNameGenerator,
    idGenerator = defaultIdGenerator,
    maxCount = Infinity,
  }: SocketsArrayConfig) {
    this.id = id;
    this.SocketConstructor = SocketConstructor;
    this.nameGenerator = nameGenerator;
    this.idGenerator = idGenerator;
    this.maxCount = maxCount;

    this.key = `${keyPrefix}${id}`;
  }

  produceSockets(nodeSpec: NodeSpec) {
    const ids = (nodeSpec.metadata?.[this.key] ?? []) as any as string[];
    const sockets = ids.map((id, i) => {
      const socket = new this.SocketConstructor(this.idGenerator(id), this.nameGenerator(id, i));
      socket.producedBy = this;

      return socket;
    });

    return sockets;
  }

  clone() {
    return new SocketsArray({
      id: this.id,
      SocketConstructor: this.SocketConstructor,
      nameGenerator: this.nameGenerator,
      idGenerator: this.idGenerator,
      maxCount: this.maxCount,
    });
  }
}
