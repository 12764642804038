import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, LightSocket, NumberSocket } from "../../sockets";
import ILightSceneObject from "~/types/ILightSceneObject";

export class LightGetIntensityNode extends BaseNode {
  static readonly type = "action/lightGetIntensity";
  static readonly label = "Get Light Intensity";

  inputs = [new FlowSocket("flow"), new LightSocket("light")];
  outputs = [new FlowSocket("flow"), new NumberSocket("intensity")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "light");
    if (!entity) {
      context.writeOutput<number>("intensity", 0);
      return;
    }

    const entityDescriptor = node.sceneContext.getEntityDescriptor<ILightSceneObject>(entity.id);
    if (!entityDescriptor) {
      context.writeOutput<number>("intensity", 0);
      return;
    }

    context.writeOutput<number>("intensity", entityDescriptor.light.intensity);
  }
}
