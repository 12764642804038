import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberSinNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberSin";
  static readonly label = "Sin";

  constructor(context: NodeContext) {
    super(context, (value) => Math.sin(value));
  }
}
