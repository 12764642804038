import { Stack, StackProps, styled } from "@mui/material";
import { useStore } from "effector-react";
import { palette } from "~/theme";
import { $loadItems } from "./models";

export type LoadDebuggerProps = Pick<StackProps, "sx" | "className">;

export const LoadDebugger = (props: LoadDebuggerProps) => {
  const { total, loaded, items } = useStore($loadItems);

  return total ? (
    <Container {...props}>
      <div>
        {loaded} / {total}
      </div>
      <Stack>
        {Object.entries(items).map(([key, value]) => (
          <Stack key={key} sx={{ color: value.loaded ? "green" : "red" }}>
            {value.id} | ${value.type} | {value.loaded ? "loaded" : "not loaded"}
          </Stack>
        ))}
      </Stack>
    </Container>
  ) : null;
};

const Container = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: ${palette.primary.gray800};
  color: ${palette.primary.white};
`;
