import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AnySocket, ArraySocket, FlowSocket, NumberSocket } from "../../sockets";
import { anyValue } from "../../values";

export class ArrayForEachNode extends BaseNode {
  static readonly type = "array/forEach";
  static readonly label = "For Each";

  inputs = [new FlowSocket(), new ArraySocket("array", anyValue.name)];
  outputs = [new FlowSocket("loop"), new AnySocket("item"), new NumberSocket("index"), new FlowSocket("complete")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const array = context.readInput<any[]>("array");

    const iterate = (index: number) => {
      if (index >= array.length) {
        context.commit("complete");
        return;
      }

      context.writeOutput("item", array[index]);
      context.writeOutput("index", index);
      context.commit("loop", () => {
        iterate(index + 1);
      });
    };

    iterate(0);
  }
}
