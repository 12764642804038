import { useEffect } from "react";
import { $audioManager, initAudioManager } from "view-scene/audio";
import { isDesktop } from "~/common/utils/isDesktop";
import { isPlayable } from "~/config";
import { lockPointer } from "../PointerLocker";
import useSessionStatus from "../stores/useSessionStatus";

const clickEvent = isPlayable ? "click" : "touchstart";

export const InitSystems = () => {
  const updateStatus = useSessionStatus((state) => state.updateStatus);

  useEffect(() => {
    const handler = () => {
      document.removeEventListener(clickEvent, handler);
      document.removeEventListener("keypress", handler);

      if (!$audioManager.getState().isReady) {
        initAudioManager();
      }

      if (isDesktop()) {
        lockPointer();
      }
    };

    updateStatus("in_progress");

    document.addEventListener(clickEvent, handler);
    document.addEventListener("keypress", handler);
  }, []);

  return null;
};
