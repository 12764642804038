import type { Components, ThemeOptions } from "@mui/material";

declare module "@mui/material/Autocomplete" {
  interface AutocompletePropsSizeOverrides {
    large: true;
  }
}

export const MuiAutocomplete: Components<ThemeOptions>["MuiAutocomplete"] = {
  styleOverrides: {
    inputRoot: {
      padding: 0,
    },
  },
  variants: [
    {
      props: { size: "large" },
      style: {
        "& .MuiAutocomplete-input.MuiAutocomplete-input.MuiAutocomplete-input": {
          padding: "8px 16px",
        },
      },
    },
  ],
};
