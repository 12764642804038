import { Typography, styled } from "@mui/material";

export const LabelTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

export const TipTypography = styled(Typography)`
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
`;
