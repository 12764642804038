import { NodeSpec, ScriptMethod } from "~/types/ScriptAsset";
import { BaseNode, NodeContext } from "../../base";
import { variableToSocket } from "../../utils";

export abstract class MethodBaseNode extends BaseNode {
  static utility = true;

  protected methodsMap: Partial<Record<string, ScriptMethod>>;

  constructor(context: NodeContext) {
    super(context);
    this.methodsMap = {}; // context.methodsMap; TODO
  }

  protected getMethod(nodeSpec: NodeSpec) {
    const methodId = nodeSpec.metadata?.methodId as string | undefined;

    return this.methodsMap[methodId ?? ""] ?? null;
  }

  protected getMethodInputSockets(nodeSpec: NodeSpec) {
    const method = this.getMethod(nodeSpec);

    return method ? method.inputs.map((input) => variableToSocket(input)) : [];
  }

  protected getMethodOutputSockets(nodeSpec: NodeSpec) {
    const method = this.getMethod(nodeSpec);

    return method ? method.outputs.map((output) => variableToSocket(output)) : [];
  }
}
