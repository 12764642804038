type ExtendedAnalyser = AnalyserNode & { dispose: () => void };

export const createAnalyser = (stream: MediaStream): ExtendedAnalyser => {
  const audioContext = new AudioContext();
  const microphone = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser() as ExtendedAnalyser;
  analyser.fftSize = 512;
  analyser.minDecibels = -127;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;
  microphone.connect(analyser);

  analyser.dispose = () => {
    analyser.disconnect();
    microphone.disconnect();
    audioContext.close();
  };

  return analyser;
};
