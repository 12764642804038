import { palette } from "~/theme";
import { Box, Stack, styled } from "@mui/material";
import { Navigate } from "~/common/components";

export function PanelLogo() {
  return (
    <Navigate to="/projects">
      <LogoContainer>
        <Box
          component="img"
          src="/static/img/content/logo.svg"
          alt="/static/img/content/logo.svg"
          sx={{
            maxWidth: "30px",
            display: "block",
          }}
        />
      </LogoContainer>
    </Navigate>
  );
}

const LogoContainer = styled(Stack)`
  align-items: center;
  justify-content: center;
  height: 27.25px;
  background: ${palette.primary.gray40};
  min-width: 48px;
  border-radius: 8px;
`;
