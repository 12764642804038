import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { Color, DirectionalLight, DirectionalLightHelper } from "three";
import IDirectionalLight from "../../../types/IDirectionalLight";
import { RenderLightHelper } from "./RenderLightHelper";

export type RenderDirectionalLightProps = {
  light: IDirectionalLight;
  drawHelper: boolean;
};

export default function RenderDirectionalLight({ light, drawHelper }: RenderDirectionalLightProps) {
  const {
    target,
    color,
    intensity,
    castShadow,
    shadowBias,
    normalBias,
    resolution,
    near,
    far,
    left,
    right,
    top,
    bottom,
  } = light;

  const directionalLightRef = useRef<DirectionalLight>() as MutableRefObject<DirectionalLight>;

  const directionalLight = useMemo(() => {
    const light = new DirectionalLight(color, intensity);
    light.shadow.mapSize.width = resolution * 1024;
    light.shadow.mapSize.height = resolution * 1024;

    light.shadow.camera.near = near;
    light.shadow.camera.far = far;
    light.shadow.camera.left = left;
    light.shadow.camera.right = right;
    light.shadow.camera.top = top;
    light.shadow.camera.bottom = bottom;
    return light;
  }, [resolution, near, far, left, right, top, bottom]);

  useEffect(() => {
    directionalLight.color = new Color(color);
    directionalLight.intensity = intensity;
    directionalLight.castShadow = castShadow;
    directionalLight.shadow.bias = shadowBias;
    directionalLight.shadow.normalBias = normalBias;
  }, [directionalLight, color, intensity, castShadow, shadowBias, normalBias]);

  return (
    <>
      <primitive object={directionalLight} />
      <primitive object={directionalLight.target} position={[target.x, target.y, target.z]} />
      {drawHelper && <RenderLightHelper lightRef={directionalLightRef} helperConstructor={DirectionalLightHelper} />}
    </>
  );
}
