import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class GameSessionStartedNode extends BaseNode {
  static readonly type = "event/gameSessionStarted";
  static readonly label = "Game session started";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onGameSessionStartedEvent = () => {
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.gameSessionStartedEvent.addListener(onGameSessionStartedEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.gameSessionStartedEvent.removeListener(onGameSessionStartedEvent);
    });
  }
}
