import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = {
  "Content-Type": "application/json",
};

const getHeaders = () => ({
  ...contentTypeHeader,
  ...getAuthHeader(),
});

const ASSETS_API_PATH = `${API_HOST}/assets`;

export const getAsset = async (id) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  return await fetch(`${ASSETS_API_PATH}/${id}`, requestOptions);
};

export const getAssets = async (workspaceId, type) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const query = type ? `?type=${type}&workspaceId=${workspaceId}` : `?workspaceId=${workspaceId}`;
  const response = await fetch(`${ASSETS_API_PATH}${query}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const uploadAsset = async (name, type, file, workspaceId) => {
  const data = new FormData();
  data.append("type", type);
  data.append("name", name);
  data.append("asset", file);
  data.append("workspaceId", workspaceId);
  const requestOptions = {
    method: "POST",
    headers: getAuthHeader(),
    body: data,
  };

  const response = await fetch(`${ASSETS_API_PATH}`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};

export const uploadAssetThumbnail = async (assetId, file) => {
  const data = new FormData();
  data.append("asset", file);
  const requestOptions = {
    method: "POST",
    headers: getAuthHeader(),
    body: data,
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}/thumbnail`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};

export async function createSkybox(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/skybox`, { workspaceId });
}

export async function createMaterial(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/material`, { workspaceId });
}

export async function createScript(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/script`, { workspaceId });
}

export async function createJSScript(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/jsScript`, { workspaceId });
}

export async function createUI(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/ui`, { workspaceId });
}

export async function createStorage(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/storage`, { workspaceId });
}

export async function createInventoryItem(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/inventoryItem`, { workspaceId });
}

export async function createStruct(workspaceId) {
  return postRequest(`${ASSETS_API_PATH}/struct`, { workspaceId });
}

const postRequest = async (path, body) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };

  const response = await fetch(path, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};

export const cloneAsset = async (assetId) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}/clone`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};

export const deleteAsset = async (assetId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}`, requestOptions);
  const status = response.status;

  return { status };
};

export const createModelVariant = async ({ assetId, ...body }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}/variant`, requestOptions);
  const status = response.status;

  return { status };
};

export const deleteModelVariant = async ({ variantId, assetId }) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}/variant/${variantId}`, requestOptions);

  return await response.json();
};

export const compressImageAsset = async ({ assetId, ...rest }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(rest),
  };

  const response = await fetch(`${ASSETS_API_PATH}/${assetId}/compressImage`, requestOptions);
  const status = response.status;

  return { status };
};
