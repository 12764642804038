import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BooleanSocket, FlowSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";

export class RewardedVideoWatchedNode extends BaseNode {
  static readonly type = "event/rewardedVideoWatched";
  static readonly label = "Rewarded Video Was Watched";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow"), new BooleanSocket("viewed")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onRewardedVideoWatched = (viewed: boolean) => {
      context.writeOutput<boolean>("viewed", viewed);
      context.commit("flow");
    };

    node.scriptContext.scriptEvents.rewardedVideoWatchedEvent.addListener(onRewardedVideoWatched);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.rewardedVideoWatchedEvent.removeListener(onRewardedVideoWatched);
    });
  }
}
