import EventEmitter from "eventemitter3";
import { $flags } from "~/entities/flags";
import { BaseAdsProvider } from "./BaseAdsProvider";
import { sendInfo } from "../models";

const scriptId = "kukushka-script";
const adLoadTimeout = 5000;

const adsDebugMode = $flags.getState().adsDebug;

export class KukushkaAdsProvider extends BaseAdsProvider {
  private events = new EventEmitter();

  constructor(private userId: string, private gameKey: string) {
    super();
    this.type = "Kukushka";
  }

  async init() {
    await this.loadScript();
    const surveyMaster = window.surveyMaster!;
    surveyMaster.setUserId(this.userId);
    surveyMaster.init(this.gameKey);
    surveyMaster.setDebugMode(adsDebugMode);

    surveyMaster.addCallback("onSurveyStart", () => {
      this.events.emit("onSurveyStart");
    });

    surveyMaster.addCallback("onSuccess", () => {
      this.events.emit("onSuccess");
    });

    surveyMaster.addCallback("onFail", () => {
      this.events.emit("onFail");
    });

    surveyMaster.addCallback("onSurveyAvailable", () => {
      this.events.emit("onAvailable");
    });

    surveyMaster.addCallback("onSurveyUnavailable", () => {
      this.events.emit("onUnavailable");
    });
  }

  start() {
    sendInfo({ provider: this.type, action: "start" });
    this.isPlaying = true;
    this.runAd();
  }

  dispose() {}

  async hasAds() {
    sendInfo({ provider: this.type, action: "hasAdsRequest" });

    const result = await Promise.race([
      this.waitEvent("onAvailable", true),
      this.waitEvent("onUnavailable", false),
      this.wait(adLoadTimeout, false),
    ]);

    window.surveyMaster.hasSurvey();
    sendInfo({ provider: this.type, action: "hasAdsResult", result });

    return result;
  }

  private async runAd() {
    const surveyMaster = window.surveyMaster!;
    surveyMaster.showSurvey();

    const result = await Promise.race([this.waitEvent("onSuccess", true), this.waitEvent("onFail", false)]);

    if (result) {
      this.emit("completed");
      this.emit("finished", true);
    } else {
      this.emit("closed");
      this.emit("finished", false);
    }

    sendInfo({ provider: this.type, action: "viewed", result });
  }

  private async loadScript() {
    return new Promise<void>((resolve) => {
      if (document.getElementById(scriptId)) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://survey.kykyshka.ru/surveyApi.js";
      script.async = true;
      script.onload = () => resolve();
      const firstScriptElement = document.getElementsByTagName("script")[0];
      firstScriptElement.parentNode?.insertBefore(script, firstScriptElement);
    });
  }

  private wait<TTimeoutValue = any>(timeout: number, value: TTimeoutValue) {
    return new Promise<TTimeoutValue>((resolve) => {
      setTimeout(() => {
        resolve(value);
      }, timeout);
    });
  }

  private async waitEvent<TResolveValue = any>(event: string, resolveValue: TResolveValue) {
    return new Promise<TResolveValue>((resolve) => {
      this.events.once(event, () => {
        resolve(resolveValue);
      });
    });
  }
}
