import React from "react";
import { palette } from "~/theme";
import { Typography, TypographyProps } from "@mui/material";

export type LabelProps = TypographyProps & {
  label: string;
};

export function Label({ label, sx }: LabelProps) {
  return (
    <Typography
      sx={{
        fontWeight: "400",
        fontSize: "12px",
        letterSpacing: 0,
        lineHeight: "16px",
        color: palette.primary.gray600,
        ...sx,
      }}
      variant="body1"
    >
      {label}
    </Typography>
  );
}
