import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class LinearVelocityGetNode extends BaseNode {
  static readonly type = "physics/linearVelocity";
  static readonly label = "Get Linear Velocity";

  inputs = [new EntitySocket("entity")];
  outputs = [new Vector3Socket("linearVelocity")];

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entityContext = node.readEntity(context, "entity");

    const linearVelocity = entityContext?.getPhysicsBody()?.leanerVelocity;

    node.storage.output.x = linearVelocity ? linearVelocity[0] : 0;
    node.storage.output.y = linearVelocity ? linearVelocity[1] : 0;
    node.storage.output.z = linearVelocity ? linearVelocity[2] : 0;

    context.writeOutput<Vector3Value>("linearVelocity", node.storage.output);
  }
}
