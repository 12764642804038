import { Icon, IconProps } from "./Icon";

export type CutsceneSystemProps = IconProps;

export const CutsceneSystem = (props: CutsceneSystemProps) => (
  <Icon width="16px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" {...props}>
    <path
      d="M6.00001 1L5.33334 4.33333M10.6667 1L10 4.33333M14.6667 4.33333H1.33334M4.53334 13H11.4667C12.5868 13 13.1468 13 13.5747 12.782C13.951 12.5903 14.2569 12.2843 14.4487 11.908C14.6667 11.4802 14.6667 10.9201 14.6667 9.8V4.2C14.6667 3.0799 14.6667 2.51984 14.4487 2.09202C14.2569 1.71569 13.951 1.40973 13.5747 1.21799C13.1468 1 12.5868 1 11.4667 1H4.53334C3.41324 1 2.85319 1 2.42536 1.21799C2.04904 1.40973 1.74308 1.71569 1.55133 2.09202C1.33334 2.51984 1.33334 3.0799 1.33334 4.2V9.8C1.33334 10.9201 1.33334 11.4802 1.55133 11.908C1.74308 12.2843 2.04904 12.5903 2.42536 12.782C2.85319 13 3.41324 13 4.53334 13ZM7.43517 10.8532L10.3562 8.90583C10.5416 8.78218 10.6344 8.72036 10.6667 8.64244C10.6949 8.57431 10.6949 8.49775 10.6667 8.42962C10.6344 8.3517 10.5416 8.28988 10.3562 8.16623L7.43517 6.2189C7.21071 6.06926 7.09848 5.99444 7.00541 6C6.92434 6.00484 6.84944 6.04492 6.80044 6.1097C6.74419 6.18405 6.74419 6.31894 6.74419 6.5887V10.4834C6.74419 10.7531 6.74419 10.888 6.80044 10.9624C6.84944 11.0271 6.92434 11.0672 7.00541 11.0721C7.09848 11.0776 7.21071 11.0028 7.43517 10.8532Z"
      stroke="white"
      strokeOpacity="0.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
