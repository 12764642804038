import { getAccessToken, getAccessTokenHG } from "./accessToken";
import { isLoadedInsideIframe } from "~/common/utils/isLoadedInsideIframe";

export const getAuthHeader = (ignoreIframeCheck: boolean = false) => {
  if (!ignoreIframeCheck && isLoadedInsideIframe) {
    return null;
  }

  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }

  return { Authorization: "Bearer " + accessToken };
};

export const getAuthHeaderHG = (ignoreIframeCheck: boolean = false) => {
  if (!ignoreIframeCheck && isLoadedInsideIframe) {
    return null;
  }

  const accessToken = getAccessTokenHG();
  if (!accessToken) {
    return null;
  }

  return { Authorization: "Bearer " + accessToken };
};
