import { MutableRefObject, useEffect, useRef } from "react";
import { difference } from "./utils";
import { Block as TMUIBlock, InlineBlock as TMUIInlineBlock, Text as TMUIText } from "~/view-scene/libs/three-mesh-ui";

export function useUIUpdate(
  objectRef: MutableRefObject<TMUIBlock | TMUIInlineBlock | TMUIText>,
  newUpdate: Record<string, any>
) {
  const prevUpdateRef = useRef<Record<string, any>>({});

  useEffect(() => {
    const update = difference(newUpdate, prevUpdateRef.current);
    prevUpdateRef.current = newUpdate;

    if (Object.keys(update).length !== 0) {
      objectRef.current.set(update);
      objectRef.current.updateLayout();
      objectRef.current.updateInner();
    }
  }, [newUpdate]);
}
