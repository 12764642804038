import { Box, styled } from "@mui/material";
import { useUnit } from "effector-react";
import { Progress, TelegramPattern } from "~/common/components";
import { palette } from "~/theme";
import { LabelTypography, LoadingOverlay } from "~/view-scene/games/shardQuest";
import { $progress } from "../models";

export type ShardQuestLoadingScreenProps = {};

export const ShardQuestLoadingScreen = () => {
  const progress = useUnit($progress);

  return (
    <Container>
      <LoadingOverlay
        bottomSlot={
          <ProgressContainer>
            <StyledProgress progress={progress} />
            <LabelTypography sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              Loading
            </LabelTypography>
          </ProgressContainer>
        }
      />
    </Container>
  );
};

const Container = styled(TelegramPattern)`
  background-color: #010101;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/static/img/content/telegram_pattern_1.webp"),
    linear-gradient(194.04deg, #53beb8 0%, #65a8e9 100%);
`;

const ProgressContainer = styled(Box)`
  position: relative;
  width: 243px;
  height: 36px;
`;

const StyledProgress = styled(Progress)`
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: ${palette.primary.black100};
  backdrop-filter: blur(4px);

  & > .MuiBox-root {
    background: ${palette.shardQuest.blue};
  }
`;
