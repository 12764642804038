import { Box, Stack, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useProfile } from "~/entities/profile";
import { updateMyAvatar } from "~/api/profile.api";
import { palette } from "~/theme";
import { READY_PLAYER_ME_API } from "~/config";
import { HeaderWithNavigation } from "~/common/components";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ProfileAvatarSelectionPage = () => {
  const loadProfile = useProfile((state) => state.loadProfile);
  const selectedRef = useRef(false);

  const navigate = useNavigate();

  const [isAvatarUploadInProgress, setIsAvatarUploadInProgress] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [searchParams] = useSearchParams();
  const referer = searchParams.has("referer");

  const handleSuccess = () => {
    if (referer) {
      window.close();
    } else {
      navigate("/profile/settings");
    }
  };

  useEffect(() => {
    async function subscribe(event: Event) {
      const iframe = iframeRef.current;
      if (!iframe) {
        return;
      }

      const json = parse(event);

      if (json?.source !== "readyplayerme") {
        return;
      }

      // Subscribe to all events sent from Ready Player Me once frame is ready
      if (json.eventName === "v1.frame.ready") {
        if (!iframe?.contentWindow) {
          return;
        }

        iframe.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*"
        );
      }

      // Get avatar GLB URL
      if (json.eventName === "v1.avatar.exported") {
        if (!selectedRef.current) {
          selectedRef.current = true;
          const avatarUrl = json.data.url;

          setIsAvatarUploadInProgress(true);
          await updateMyAvatar({ avatarUrl });
          await loadProfile();
          setIsAvatarUploadInProgress(false);

          handleSuccess();
        }
      }
    }

    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);

    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  }, []);

  return (
    <Stack direction="column" height="100%" minHeight="100vh" width="100%" minWidth="100vw">
      <HeaderWithNavigation enableNavigation={true} label="Profile" target="/profile/settings" />
      <Stack flexGrow={1}>
        {!isAvatarUploadInProgress ? (
          <ReadyPlayerMeIFrame
            title="Ready Player Me Avatar Selection"
            src={READY_PLAYER_ME_API}
            allow="camera *; microphone *"
            ref={iframeRef}
          />
        ) : (
          <InProgressContainer>
            <Logo src="/static/img/content/logo-animated.gif" />
            <Container>
              <Text>Please wait while we create your avatar. This process may take a few moments.</Text>
            </Container>
          </InProgressContainer>
        )}
      </Stack>
    </Stack>
  );
};

function parse(event: any) {
  try {
    if (event.data) {
      return JSON.parse(event.data);
    }
    return null;
  } catch (error) {
    return null;
  }
}

const Logo = styled("img")`
  width: 300px;
  height: 300px;
`;

const Container = styled(Box)`
  padding: 8px;
`;

const Text = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${palette.primary.gray800};

  & b {
    font-weight: 600;
  }
`;

const ReadyPlayerMeIFrame = styled("iframe")`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 14px;
  border: none;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

const InProgressContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: black;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
