import { Button, Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { useProfile } from "~/entities/profile";
import { useState } from "react";
import { Navigate } from "~/common/components";
import { useSceneData } from "~/common/stores/useSceneData";

const LAST_AVATAR_REMINDER_DATE = "last_avatar_reminder_date";

export function SetupAvatarReminder() {
  const hasAvatar = useProfile((state) => state.profile?.avatarUrl != null);
  const isCustomModel = useSceneData((state) => state.sceneState?.avatarSystem.avatarSource === "customModel");

  const [display, setDisplay] = useState(() => {
    if (hasAvatar || isCustomModel) {
      return false;
    }

    const lastReminderRecord = localStorage.getItem(LAST_AVATAR_REMINDER_DATE);
    if (lastReminderRecord) {
      const lastReminder = new Date(lastReminderRecord);
      const now = new Date();
      return now.getTime() - lastReminder.getTime() > 24 * 60 * 60 * 1000;
    }

    return true;
  });

  const dismissAvatarReminder = () => {
    localStorage.setItem(LAST_AVATAR_REMINDER_DATE, new Date().toString());
    setDisplay(false);
  };

  if (!display) {
    return null;
  }

  return (
    <SetupAvatarReminderContainer>
      <SetupAvatarReminderImg src="/static/img/content/setup-avatar.webp" />
      <SetupAvatarReminderText>
        Take 1 min to create your full-body avatar and make your game unique. Be everyone or be yourself!
      </SetupAvatarReminderText>
      <SetupAvatarActions>
        <RoundedButton variant="contained" onClick={dismissAvatarReminder}>
          Dismiss
        </RoundedButton>
        <Navigate to="/profile/avatar?referer=Scene" requireAuth={true} openInNewWindow={true}>
          <RoundedButton variant="accent">Set Avatar</RoundedButton>
        </Navigate>
      </SetupAvatarActions>
    </SetupAvatarReminderContainer>
  );
}

const SetupAvatarReminderContainer = styled(Stack)`
  position: absolute;
  top: 70px;
  right: 20px;
  width: 276px;
  height: 223px;
  border-radius: 12px;
  padding: 16px;
  background-color: ${palette.primary.black900};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const SetupAvatarReminderImg = styled("img")`
  width: 276px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
`;

const SetupAvatarReminderText = styled(Typography)`
  display: block;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 500;
  color: ${palette.primary.white};
  width: 100%;
`;

const SetupAvatarActions = styled(Stack)`
  flex-direction: row;
  justify-content: right;
  gap: 12px;
  width: 100%;
`;

const RoundedButton = styled(Button)`
  border-radius: 15px;
`;
