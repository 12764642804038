import { memo } from "react";
import { Color } from "three";
import { Block } from "../../ui/RenderUIElement/Block";
import { Image } from "../../ui/RenderUIElement/Image";
import { Tweet } from "./models";
import { backgroundColor, pixelRate } from "./constants";
import { TwitterText } from "./TwitterText";
import ITwitterBoardSceneObject from "types/ITwitterBoardSceneObject";

const fontSize = 15 * pixelRate;
const averageSymbolWidth = fontSize / 1.45;

export type TweetItemProps = {
  dto: ITwitterBoardSceneObject;
  tweet: Tweet;
  width: number;
  height: number;
};

export const TweetItem = memo<TweetItemProps>(({ dto, tweet, width, height }) => {
  const avatarSize = 48 * pixelRate;
  const avatarBorderRadius = avatarSize / 2;
  const contentWidth = width - avatarSize;
  const paddingTop = 16 * pixelRate;
  const imageMarginRight = 24 * pixelRate;
  const titleHeight = fontSize;
  const textMarginTop = 8 * pixelRate;
  const symbolsInLine = contentWidth / averageSymbolWidth;
  const fontColor = new Color(dto.textColor);
  const nicknameFontColor = new Color(dto.usernameColor);

  const nameMaxLength = Math.floor(symbolsInLine / 2);
  const usernameMaxLength = Math.floor(symbolsInLine / 2);

  return (
    <Block
      width={width}
      height={height}
      justifyContent="start"
      alignItems="stretch"
      fontColor={fontColor}
      backgroundOpacity={0}
    >
      <Block key="padding-top" height={paddingTop} backgroundOpacity={0} />
      <Block height={height} contentDirection="row" alignItems="start" backgroundOpacity={0}>
        <Image
          src={tweet.author?.avatar}
          width={avatarSize}
          height={avatarSize}
          borderRadius={avatarBorderRadius}
          backgroundColor={backgroundColor}
        />
        <Block key="image-margin-right" width={imageMarginRight} height={height} backgroundOpacity={0} />
        <Block width={contentWidth} height={height} alignItems="stretch" hiddenOverflow backgroundOpacity={0}>
          <Block
            key="title"
            height={titleHeight}
            contentDirection="row"
            justifyContent="start"
            textAlign="left"
            backgroundOpacity={0}
          >
            <TwitterText content={tweet.author?.name ?? ""} maxLength={nameMaxLength} />
            <TwitterText content=" " />
            <TwitterText
              content={tweet.author?.username ? `@${tweet.author?.username}` : ""}
              fontColor={nicknameFontColor}
              maxLength={usernameMaxLength}
            />
          </Block>
          <Block key="text-margin-top" height={textMarginTop} backgroundOpacity={0} />
          <Block key="text" height={0.1} textAlign="left" backgroundOpacity={0}>
            <TwitterText content={tweet.text} />
          </Block>
        </Block>
      </Block>
    </Block>
  );
});
