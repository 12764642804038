import { Detailed } from "@react-three/drei";
import { forwardRef } from "react";
import { Object3D } from "three";
import IModelSceneObject from "~/types/IModelSceneObject";
import { RenderModel } from "./RenderModel";

export type Lod = {
  modelUrl?: string | null;
  distance: number;
};

export type RenderLodProps = {
  dto: IModelSceneObject;
  lods: Lod[];
};

export const RenderLod = forwardRef<Object3D, RenderLodProps>(({ lods, dto }, ref) => {
  return (
    <Detailed distances={lods.map((lod) => lod.distance)} ref={ref}>
      {lods.map((lod, index) =>
        lod.modelUrl ? (
          <group key={index}>
            <RenderModel dto={dto} url={lod.modelUrl} />
          </group>
        ) : (
          <group key={index} />
        )
      )}
    </Detailed>
  );
});
