import { AnimationMixer, Event, LoopOnce, Object3D } from "three";
import { FiniteStateMachine, vectorFlatLength } from "~/view-scene/utils";
import { useFrame } from "@react-three/fiber";

import { RUNNING_THRESHOLD, WALKING_THRESHOLD } from "../constants";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAnimations } from "./useAnimations";
import { useSceneData } from "~/common/stores/useSceneData";
import shallow from "zustand/shallow";
import { useEmotionAnimations } from "./useEmotionAnimations";
import EventEmitter from "eventemitter3";

export function useAvatarAnimations(
  avatar: Object3D,
  sex: "male" | "female",
  autoRotate: boolean,
  leanerVelocityGetter: () => [number, number, number],
  rotationGetter: () => [number, number, number],
  animationUpdateFrequency = 1 / 60
) {
  const events = useMemo(() => new EventEmitter(), []);

  const mixer = useMemo(() => new AnimationMixer(avatar), [avatar]);

  const avatarAnimations = useSceneData((state) => state.sceneState?.avatarSystem.animations ?? [], shallow);

  const avatarActions = useAnimations({
    mixer,
    sex,
    avatarAnimations,
  });

  const emotionActions = useEmotionAnimations(mixer);

  const stopEmotionAnimationAction = useCallback(() => {
    for (let emotionAction in emotionActions) {
      if (emotionActions[emotionAction].isRunning()) {
        emotionActions[emotionAction].stop();
      }
    }
  }, [emotionActions]);

  const actions = useMemo(() => ({ ...avatarActions, ...emotionActions }), [avatarActions, emotionActions]);

  useEffect(() => {
    const handleFinish = (event: Event) => {
      const animationId = Object.keys(actions).find((aId) => actions[aId] === event.action);
      events.emit("avatarAnimationFinished", { animationId });
    };
    mixer.addEventListener("finished", handleFinish);

    return () => {
      mixer.removeEventListener("finished", handleFinish);
    };
  }, [mixer, events, actions]);

  const animationStateMachine = useMemo(() => {
    const animationState = new FiniteStateMachine(
      {
        IDLE: {
          enter: () => {
            const idleAction = actions["IDLE"]!;
            if (!idleAction) {
              return;
            }

            if (animationState.prevState) {
              idleAction.time = 0;
              idleAction.enabled = true;
              const prevAction = actions[animationState.prevState]!;
              idleAction.crossFadeFrom(prevAction, 0.5, true);
              idleAction.play();
            } else {
              idleAction.play();
            }
          },
          update: () => {
            const velocity = leanerVelocityGetter();
            const velocityLength = vectorFlatLength(velocity);

            if (velocityLength > WALKING_THRESHOLD) {
              animationState.transition("WALKING");
            }
          },
        },
        WALKING: {
          enter: () => {
            const walkingAction = actions["WALKING"];
            if (!walkingAction) {
              return;
            }

            stopEmotionAnimationAction();

            if (animationState.prevState) {
              walkingAction.enabled = true;

              const prevAction = actions[animationState.prevState]!;

              if (animationState.prevState === "RUNNING") {
                const ratio = walkingAction.getClip().duration / prevAction.getClip().duration;
                walkingAction.time = prevAction.time * ratio;
              } else {
                walkingAction.time = 0.0;
              }

              walkingAction.setEffectiveWeight(25);
              walkingAction.crossFadeFrom(prevAction, 0.3, true);
              walkingAction.play();
            } else {
              walkingAction.play();
            }
          },
          update: () => {
            const velocity = leanerVelocityGetter();
            const velocityLength = vectorFlatLength(velocity);

            if (velocityLength <= WALKING_THRESHOLD) {
              animationState.transition("IDLE");
            } else if (velocityLength >= RUNNING_THRESHOLD) {
              animationState.transition("RUNNING");
            }
          },
        },
        RUNNING: {
          enter: () => {
            const runningAction = actions["RUNNING"]!;
            if (!runningAction) {
              return;
            }

            stopEmotionAnimationAction();

            if (animationState.prevState) {
              runningAction.enabled = true;

              const prevAction = actions[animationState.prevState]!;

              if (animationState.prevState === "WALKING") {
                const ratio = runningAction.getClip().duration / prevAction.getClip().duration;
                runningAction.time = prevAction.time * ratio;
              } else {
                runningAction.time = 0.0;
              }

              runningAction.setEffectiveWeight(25);
              runningAction.crossFadeFrom(prevAction, 0.3, true);
              runningAction.play();
            } else {
              runningAction.play();
            }
          },
          update: () => {
            const velocity = leanerVelocityGetter();
            const velocityLength = vectorFlatLength(velocity);

            if (velocityLength < RUNNING_THRESHOLD) {
              animationState.transition("WALKING");
            } else if (velocityLength < WALKING_THRESHOLD) {
              animationState.transition("IDLE");
            }
          },
        },
      },
      "IDLE",
      {}
    );
    animationState.transition("IDLE");

    return animationState;
  }, [actions, events, stopEmotionAnimationAction]);

  const deltaSummer = useRef(0);

  useFrame((_, delta) => {
    deltaSummer.current += delta;
    if (deltaSummer.current < animationUpdateFrequency) {
      return;
    }

    mixer.update(deltaSummer.current);
    deltaSummer.current = 0;

    animationStateMachine.update();

    if (!autoRotate) {
      return;
    }

    const head = avatar.getObjectByName("Head");
    if (!head) {
      return;
    }

    const velocity = leanerVelocityGetter();
    const velocityLength = vectorFlatLength(velocity);

    const [rotX, rotY, rotZ] = rotationGetter();

    if (velocityLength >= WALKING_THRESHOLD) {
      avatar.rotation.set(0, rotY, 0);
      head.rotation.set(-rotX - 0.2, 0, -rotZ);
    } else if (Math.abs(rotY) < (3 * Math.PI) / 8) {
      head.rotation.set(-rotX - 0.2, rotY, -rotZ);
    } else {
      head.rotation.set(0, 0, 0);
    }
  });

  const controls = useMemo(() => {
    return {
      actions,
      jump: () => {
        const jumpingAction = actions["JUMP"];
        if (!jumpingAction) {
          return;
        }

        stopEmotionAnimationAction();

        jumpingAction.reset();
        jumpingAction.time = 0.5;
        jumpingAction.setEffectiveWeight(50);
        jumpingAction.fadeOut(1.8);
        jumpingAction.setLoop(LoopOnce, Infinity);

        jumpingAction.play();
      },
      idle: () => {
        animationStateMachine.transition("IDLE");
      },
      playAnimation: (animationId: string) => {
        const animationAction = actions[animationId];
        if (!animationAction) {
          return;
        }

        stopEmotionAnimationAction();

        animationAction.reset();
        animationAction.time = 0;
        animationAction.setEffectiveWeight(50);
        animationAction.fadeIn(0.25);
        animationAction.setLoop(LoopOnce, Infinity);

        animationAction.play();
      },
      stopAnimation: (animationId: string) => {
        const animationAction = actions[animationId];
        if (!animationAction) {
          return;
        }

        if (animationAction.isRunning()) {
          animationAction.stop();
          events.emit("avatarAnimationStopped", { animationId });
        }
      },
    };
  }, [actions, animationStateMachine, stopEmotionAnimationAction]);

  return { controls, events };
}
