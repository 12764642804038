import { Icon, IconProps } from "./Icon";

export type CommunicationSystemProps = IconProps;

export const CommunicationSystem = (props: CommunicationSystemProps) => (
  <Icon width="12px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14" {...props}>
    <path
      d="M11.3333 7V7.66667C11.3333 10.6122 8.94551 13 5.99999 13C3.05447 13 0.666656 10.6122 0.666656 7.66667V7M5.99999 10.3333C4.52723 10.3333 3.33332 9.13943 3.33332 7.66667V3.66667C3.33332 2.19391 4.52723 1 5.99999 1C7.47275 1 8.66666 2.19391 8.66666 3.66667V7.66667C8.66666 9.13943 7.47275 10.3333 5.99999 10.3333Z"
      stroke="white"
      strokeOpacity="0.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
