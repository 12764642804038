import { Stack, StackProps, styled } from "@mui/material";
import React from "react";

type ProjectCardProps = StackProps & {
  previewUrl: string;
};

export const ProjectCard = styled(({ previewUrl, ...rest }: ProjectCardProps) => (
  <Stack
    {...rest}
    sx={{
      backgroundImage: `url("${previewUrl}")`,
    }}
  />
))`
  height: 150px;
  width: 268px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  transition: 1s cubic-bezier(0.46, 1.48, 0.18, 0.81);

  &:hover {
    transform: scale(1.05);
  }
`;
