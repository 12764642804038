import { sample, createEffect, createEvent, createStore } from "effector";
import { getAsyncProcesses, AsyncProcess, AsyncProcessStatus } from "~/api/asyncProcesses.api";
import { showToast } from "~/hephaestus/Toast/models";

export const registerAsyncProcess = createEvent<AsyncProcess>();
export const updateAsyncProcessStatus = createEvent<{ id: string; status: AsyncProcessStatus }>();
export const deleteAsyncProcess = createEvent<string>();

export const loadAsyncProcessesFx = createEffect(async (sceneId: string) => {
  const { status, body: asyncProcesses } = await getAsyncProcesses(sceneId);

  if (status !== 200) {
    throw new Error("Not found");
  }

  return asyncProcesses;
});

export const $asyncProcesses = createStore<AsyncProcess[] | null>(null).on(loadAsyncProcessesFx.doneData, (_, payload) => payload);
$asyncProcesses
  .on(registerAsyncProcess, (state, process) => {
    if (!state) {
      return null;
    }
    return [...state, process];
  })
  .on(updateAsyncProcessStatus, (state, { id, status }) => {
    if (!state) {
      return null;
    }

    return state.map((process) => ({
      ...process,
      status: process.id === id ? status : process.status,
    }));
  })
  .on(deleteAsyncProcess, (state, id) => {
    if (!state) {
      return null;
    }

    return state.filter((process) => process.id !== id);
  });

sample({
  source: $asyncProcesses,
  clock: updateAsyncProcessStatus,
  filter: (processes, { id }) => processes?.find((it) => it.id === id)?.type === "optimise",
  fn: (processes, { id }) => {
    const updatedProcess = processes!.find((it) => it.id === id)!;

    showToast({ title: `${updatedProcess.name} completed` });
  },
});
