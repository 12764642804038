import { Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Card } from "./Card";
import { SceneCard } from "./types";

type ScenesSectionProps = {
  headerText?: string;
  scenes: SceneCard[];
};

export function ScenesSection({ headerText, scenes }: ScenesSectionProps) {
  const theme = useTheme();

  let cardsPerRow = 4;

  const greaterThanLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));

  if (greaterThanLarge) {
    cardsPerRow = 4;
  } else if (greaterThanMid) {
    cardsPerRow = 3;
  } else if (smallToMid) {
    cardsPerRow = 2;
  } else if (lessThanSmall) {
    cardsPerRow = 1;
  }

  return (
    <ScenesSectionContainer>
      {headerText && <ScenesHeading>{headerText}</ScenesHeading>}
      <ScenesContainer>
        {scenes.map((scene) => (
          <Card key={scene.url} scene={scene} cardsPerRow={cardsPerRow} />
        ))}
      </ScenesContainer>
    </ScenesSectionContainer>
  );
}

const ScenesSectionContainer = styled(Stack)`
  padding-top: 32px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

const ScenesHeading = styled(Typography)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: white;
  text-align: left;
`;

const ScenesContainer = styled(Stack)`
  padding-top: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;

  &::after {
    content: "";
    flex: auto;
  }
`;
