import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode, NodeContext } from "../../base";
import { DeviceMode } from "~/types/DeviceMode";
import { BooleanSocket } from "../../sockets";

export class IsPlatformNode extends BaseNode {
  static utility = true;

  constructor(context: NodeContext, private condition: (device: DeviceMode) => boolean) {
    super(context);
  }

  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    context.writeOutput<boolean>("result", this.condition(node.sceneContext.getPlatform()));
  }
}
