import { ValueType } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { AssetValue } from "./assetValue";

export type AssetMaterialValue = AssetValue;

export const assetMaterialValue = new ValueType(
  "assetMaterial",
  defaultAsset,
  (json: AssetMaterialValue) => json,
  (value: AssetMaterialValue) => value
);
