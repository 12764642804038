import { useEffect, useMemo, useState } from "react";
import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import {
  Button,
  Collapsible,
  Divider,
  LabelGroup,
  NumericField,
  Select,
  Switch,
  TextField,
} from "~/hephaestus/components";
import { AdCampaignDto, AdPlacementDto, AdPlacementType } from "./types";

const botIds = [
  "catalog",
  "slingTombGameBot",
  "rudeRacesGameBot",
  "crazyFishingGameBot",
  "formulaRushGameBot",
  "desertWarriorGameBot",
  "zombieAttackGameBot",
  "riseUpGameBot",
  "sudokuMasterGameBot",
  "kingSolitaireGameBot",
  "boxTowerGameBot",
  "dropNMergeGameBot",
  "memoryMatchGameBot",
  "deverseeGameBot",
  "furiousRoadGameBot",
  "candylandGameBot",
  "kingdomSurvivorGameBot",
  "endlessSiegeGameBot",
  "parkingJamGameBot",
  "polygamiGameBot",
  "shardQuestBot",
  "tonSpacesBot",
  "BtlshipGameBot",
  "Ch8ssBot",
  "endlessSiegeGameBot2",
  "wordCakeGameBot",
  "tongochi_bot", // tongochi_bot
  "314159265", // sidus
  "endlessSiegeGameeBot",
  "boltClickerBot",
  "battalionCommanderGameBot",
  "kingSolitaireGameBot",
  "gatto",
];

const locales = ["ru", "en"];

const generateNewBeforePlayPlacement = (): AdPlacementDto => ({
  type: "beforePlay",
  enabled: true,
  url: "https://t.me/gam8s",
  message: "</u>Sponsor of the game</u><a href='https://t.me/gam8s'>Games in Telegram. Play games with friends 🎮</a>",
});

const generateNewAfterSessionPlacement = (): AdPlacementDto => ({
  type: "afterSession",
  enabled: true,
  url: "https://t.me/gam8s",
  message: "</u>Sponsor of the game</u><a href='https://t.me/gam8s'>Games in Telegram. Play games with friends 🎮</a>",
});

const generateNewAdCampaign = (priority: number): AdCampaignDto => ({
  name: "Ad Campaign",
  enabled: true,
  priority,
  locales: [],
  botIds: [],
  excludedBotIds: [],
  placements: [generateNewBeforePlayPlacement(), generateNewAfterSessionPlacement()],
  targetImpressions: 100,
  currentImpressions: 0,
});

type EditAdCampaignDialogProps = {
  open: boolean;
  adCampaign: AdCampaignDto | null;
  onSave: (adCampaign: AdCampaignDto) => void;
  onClose: VoidFunction;
};

export const EditAdCampaignDialog = ({ open, adCampaign, onSave, onClose }: EditAdCampaignDialogProps) => {
  const [updatedAdCampaign, setUpdatedAdCampaign] = useState(adCampaign ?? generateNewAdCampaign(0));
  useEffect(() => {
    setUpdatedAdCampaign(adCampaign ?? generateNewAdCampaign(0));
  }, [adCampaign]);

  const beforePlayPlacement = useMemo(
    () => updatedAdCampaign.placements.find((p) => p.type === "beforePlay")!,
    [updatedAdCampaign]
  );
  const afterSessionPlacement = useMemo(
    () => updatedAdCampaign.placements.find((p) => p.type === "afterSession")!,
    [updatedAdCampaign]
  );

  const handleAdUpdate = (event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const updated = { ...updatedAdCampaign, [event.target.name]: value };
    setUpdatedAdCampaign(updated);
  };

  const handleAdPlacementUpdate = (adPlacementType: AdPlacementType, event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const updated = {
      ...updatedAdCampaign,
      placements: updatedAdCampaign.placements.map((p) =>
        p.type === adPlacementType
          ? {
              ...p,
              [event.target.name]: value,
            }
          : p
      ),
    };
    setUpdatedAdCampaign(updated);
  };

  const isNew = updatedAdCampaign?.id == null;

  const dialogTitle = isNew ? "Edit Ad Campaign" : "Create Ad Campaign";

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h5" color="secondary">
            {dialogTitle}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "600px", padding: 4 }}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="body2">Campaign Settings</Typography>
            <LabelGroup label="Priority" labelMaxWidth="100px">
              <NumericField
                variant="outlined"
                fullWidth={true}
                placeholder="Priority"
                name="priority"
                value={updatedAdCampaign?.priority ?? 0}
                onChange={handleAdUpdate}
              />
            </LabelGroup>
            <LabelGroup label="Name" labelMaxWidth="100px">
              <TextField
                variant="outlined"
                fullWidth={true}
                placeholder="Name"
                name="name"
                value={updatedAdCampaign?.name ?? "Ad Campaign"}
                onChange={handleAdUpdate}
              />
            </LabelGroup>
            <LabelGroup label="Active" labelMaxWidth="100px">
              <Switch name="enabled" checked={updatedAdCampaign?.enabled ?? false} onChange={handleAdUpdate} />
            </LabelGroup>
            <LabelGroup label="Locales" labelMaxWidth="100px">
              <Select
                name="locales"
                multiple
                placeholder="Locales"
                displayEmpty={true}
                value={updatedAdCampaign?.locales ?? []}
                onChange={handleAdUpdate}
                renderValue={(selected: string[]) => (
                  <Stack flexWrap="wrap" direction="row">
                    {selected?.length > 0
                      ? selected.map((locale) => (
                          <Chip key={locale} label={locale} sx={{ margin: "2px" }} size="small" variant="outlined" />
                        ))
                      : "All Locales"}
                  </Stack>
                )}
                sx={{
                  maxHeight: "auto",
                }}
              >
                {locales.map((locale) => (
                  <MenuItem key={locale} value={locale}>
                    {locale}
                  </MenuItem>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup label="Bots" labelMaxWidth="100px">
              <Select
                name="botIds"
                fullWidth
                multiple
                placeholder="Bots"
                displayEmpty={true}
                value={updatedAdCampaign?.botIds ?? []}
                onChange={handleAdUpdate}
                renderValue={(selected: string[]) => (
                  <Stack flexWrap="wrap" direction="row">
                    {selected?.length > 0
                      ? selected.map((botId) => (
                          <Chip key={botId} label={botId} sx={{ margin: "4px" }} size="small" variant="outlined" />
                        ))
                      : "All Bots"}
                  </Stack>
                )}
                sx={{
                  maxHeight: "auto",
                }}
              >
                {botIds.map((botId) => (
                  <MenuItem key={botId} value={botId}>
                    {botId}
                  </MenuItem>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup label="Excluded Bots" labelMaxWidth="100px">
              <Select
                name="excludedBotIds"
                fullWidth
                multiple
                placeholder="Excluded Bots"
                displayEmpty={true}
                value={updatedAdCampaign?.excludedBotIds ?? []}
                onChange={handleAdUpdate}
                renderValue={(selected: string[]) => (
                  <Stack flexWrap="wrap" direction="row">
                    {selected?.length > 0
                      ? selected.map((botId) => (
                          <Chip key={botId} label={botId} sx={{ margin: "4px" }} size="small" variant="outlined" />
                        ))
                      : "No Excluded"}
                  </Stack>
                )}
                sx={{
                  maxHeight: "auto",
                }}
              >
                {botIds.map((botId) => (
                  <MenuItem key={botId} value={botId}>
                    {botId}
                  </MenuItem>
                ))}
              </Select>
            </LabelGroup>
            <LabelGroup label="Target Impression" labelMaxWidth="100px">
              <NumericField
                variant="outlined"
                placeholder="Target Impression"
                size="small"
                name="targetImpressions"
                value={updatedAdCampaign?.targetImpressions ?? 0}
                fullWidth={true}
                onChange={handleAdUpdate}
              />
            </LabelGroup>
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography variant="body2">Ad Placements</Typography>
            <Collapsible
              id="beforePlay"
              title="Before Play"
              headerContent={
                <Stack direction="row" alignItems="center" sx={{ gap: "4px" }}>
                  <Switch
                    name="enabled"
                    checked={beforePlayPlacement.enabled ?? false}
                    onChange={(e) => handleAdPlacementUpdate("beforePlay", e)}
                  />
                </Stack>
              }
              expandCollapseEnabled={false}
              sx={{
                padding: "4px",
              }}
            >
              <LabelGroup label="URL" labelMaxWidth="100px">
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="URL"
                  name="url"
                  value={beforePlayPlacement.url ?? ""}
                  onChange={(e) => handleAdPlacementUpdate("beforePlay", e)}
                />
              </LabelGroup>
              <LabelGroup label="Message" labelMaxWidth="100px">
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Message"
                  multiline={true}
                  rows={4}
                  name="message"
                  value={beforePlayPlacement.message ?? ""}
                  onChange={(e) => handleAdPlacementUpdate("beforePlay", e)}
                />
              </LabelGroup>
            </Collapsible>
            <Collapsible
              id="afterPlay"
              title="After Play"
              headerContent={
                <Stack direction="row" alignItems="center" sx={{ gap: "4px" }}>
                  <Switch
                    name="enabled"
                    checked={afterSessionPlacement.enabled ?? false}
                    onChange={(e) => handleAdPlacementUpdate("afterSession", e)}
                  />
                </Stack>
              }
              expandCollapseEnabled={false}
              sx={{
                padding: "4px",
              }}
            >
              <LabelGroup label="URL" labelMaxWidth="100px">
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="URL"
                  name="url"
                  value={afterSessionPlacement.url ?? ""}
                  onChange={(e) => handleAdPlacementUpdate("afterSession", e)}
                />
              </LabelGroup>
              <LabelGroup label="Message" labelMaxWidth="100px">
                <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Message"
                  multiline={true}
                  rows={4}
                  name="message"
                  value={afterSessionPlacement.message ?? ""}
                  onChange={(e) => handleAdPlacementUpdate("afterSession", e)}
                />
              </LabelGroup>
            </Collapsible>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 2, paddingRight: 4 }}>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="accent" onClick={() => onSave(updatedAdCampaign)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
