import { FC } from "react";
import {
  Airplay,
  Audio,
  AvatarSystem,
  Billboard,
  Cube,
  FaceId,
  Folder,
  Globe,
  IconProps,
  ImageEntity,
  Lightbulb,
  Perspective,
  Pin,
  SpawnPoint,
  Sprite,
  Teleport,
  Text,
  Tv,
  UI,
  Video,
  Video360,
} from "~/common/components/Icon";
import { PartialRecord } from "~/common/typeUtils";
import { SceneObjectType } from "~/types/SceneObjectType";

export type EntityIconProps = IconProps & {
  type: SceneObjectType;
};

export const EntityIcon = ({ type, ...rest }: EntityIconProps) => {
  const Component = componentsMap[type] ?? Folder;

  return <Component {...rest} />;
};

const componentsMap: PartialRecord<SceneObjectType, FC<IconProps>> = {
  entity: Folder,
  player: AvatarSystem,
  billboard: Billboard,
  glb: Cube,
  text: Text,
  teleport: Teleport,
  image: ImageEntity,
  audio: Audio,
  video: Video,
  video360: Video360,
  screenShare: Airplay,
  triggerZone: Pin,
  triggerVolume: Pin,
  occlusionVolume: Cube,
  blockingVolume: Cube,
  killVolume: Cube,
  sky: Globe,
  water: Globe,
  cloud: Globe,
  stars: Globe,
  mirror: Globe,
  snow: Globe,
  twitterBoard: Tv,
  instancedMesh: Perspective,
  npc: FaceId,
  light: Lightbulb,
  spawnPoint: SpawnPoint,
  ui: UI,
  sprite: Sprite,
  camera: Video,
};
