import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

// TODO: import dto from be
export type OnlineUser = {
  userId: string;
  socketId: string;
  name: string;
  avatarPreview?: string;
};

export const getBuilderOnlineUsers = async (sceneId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/builder/${sceneId}/online-users`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody as OnlineUser[] };
};
