const ACCESS_TOKEN_REF_NAME = "access_token";

export function setAccessToken(accessToken: string) {
  localStorage.setItem(ACCESS_TOKEN_REF_NAME, accessToken);
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_REF_NAME);
}

const ACCESS_TOKEN_HG_REF_NAME = "access_token_hg";

export function setAccessTokenHG(accessToken: string) {
  localStorage.setItem(ACCESS_TOKEN_HG_REF_NAME, accessToken);
}

export function getAccessTokenHG() {
  return localStorage.getItem(ACCESS_TOKEN_HG_REF_NAME);
}
