import { NodeContext } from "../../base";
import { TrajectorySocket } from "../../sockets/TrajectorySocket";
import { SceneComponentNode } from "./SceneComponentNode";

export class SceneTrajectoryNode extends SceneComponentNode {
  static readonly type = "variable/sceneTrajectory";
  static readonly label = "Trajectory";

  constructor(context: NodeContext) {
    super(context, new TrajectorySocket("trajectory"));
  }
}
