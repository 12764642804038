import { NodeEvalContext } from "~/libs/behave-graph";
import { SpriteContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetImageSocket, EntitySocket, FlowSocket } from "../../sockets";
import { AssetImageValue } from "../../values";

export class SpriteSetImageNode extends BaseNode {
  static readonly type = "action/spriteSetImage";
  static readonly label = "Set Sprite Image";

  inputs = [new FlowSocket("flow"), new EntitySocket("sprite"), new AssetImageSocket("image")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const spriteContext = node.readEntity<SpriteContext>(context, "sprite");
    const image = context.readInput<AssetImageValue>("image");
    if (spriteContext && image.assetId) {
      const entityDescriptor = node.sceneContext.getEntityDescriptor(spriteContext.id);
      if (!entityDescriptor) {
        return;
      }

      node.sceneContext.updateEntityDescriptor({
        ...entityDescriptor,
        asset: image.assetId,
      });
    }
  }
}
