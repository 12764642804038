export const boxVertices = computeBoundingBoxPositions();

function computeBoundingBoxPositions() {
  var width = 1.0;
  var height = 1.0;
  var depth = 1.0;
  var x = -0.5;
  var y = -0.5;
  var z = -0.5;

  var fbl = { x: x, y: y, z: z + depth };
  var fbr = { x: x + width, y: y, z: z + depth };
  var ftl = { x: x, y: y + height, z: z + depth };
  var ftr = { x: x + width, y: y + height, z: z + depth };
  var bbl = { x: x, y: y, z: z };
  var bbr = { x: x + width, y: y, z: z };
  var btl = { x: x, y: y + height, z: z };
  var btr = { x: x + width, y: y + height, z: z };

  var positions = new Float32Array([
    //front
    fbl.x,
    fbl.y,
    fbl.z,
    fbr.x,
    fbr.y,
    fbr.z,
    ftl.x,
    ftl.y,
    ftl.z,
    ftl.x,
    ftl.y,
    ftl.z,
    fbr.x,
    fbr.y,
    fbr.z,
    ftr.x,
    ftr.y,
    ftr.z,

    //right
    fbr.x,
    fbr.y,
    fbr.z,
    bbr.x,
    bbr.y,
    bbr.z,
    ftr.x,
    ftr.y,
    ftr.z,
    ftr.x,
    ftr.y,
    ftr.z,
    bbr.x,
    bbr.y,
    bbr.z,
    btr.x,
    btr.y,
    btr.z,

    //back
    fbr.x,
    bbr.y,
    bbr.z,
    bbl.x,
    bbl.y,
    bbl.z,
    btr.x,
    btr.y,
    btr.z,
    btr.x,
    btr.y,
    btr.z,
    bbl.x,
    bbl.y,
    bbl.z,
    btl.x,
    btl.y,
    btl.z,

    //left
    bbl.x,
    bbl.y,
    bbl.z,
    fbl.x,
    fbl.y,
    fbl.z,
    btl.x,
    btl.y,
    btl.z,
    btl.x,
    btl.y,
    btl.z,
    fbl.x,
    fbl.y,
    fbl.z,
    ftl.x,
    ftl.y,
    ftl.z,

    //top
    ftl.x,
    ftl.y,
    ftl.z,
    ftr.x,
    ftr.y,
    ftr.z,
    btl.x,
    btl.y,
    btl.z,
    btl.x,
    btl.y,
    btl.z,
    ftr.x,
    ftr.y,
    ftr.z,
    btr.x,
    btr.y,
    btr.z,

    //bottom
    bbl.x,
    bbl.y,
    bbl.z,
    bbr.x,
    bbr.y,
    bbr.z,
    fbl.x,
    fbl.y,
    fbl.z,
    fbl.x,
    fbl.y,
    fbl.z,
    bbr.x,
    bbr.y,
    bbr.z,
    fbr.x,
    fbr.y,
    fbr.z,
  ]);

  return positions;
}
