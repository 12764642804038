import { palette } from "~/theme";
import { Box, Stack, Typography } from "@mui/material";
import { Container } from "./Container";
import { Navigate } from "./Navigate";
import { Twitter } from "./Twitter";
import { Instagram } from "./Instagram";
import { Discord } from "./Discord";

export function Footer() {
  return (
    <Stack
      sx={(theme) => ({
        padding: "50px 0 32px 0",
        [theme.breakpoints.down("md")]: {
          padding: "42px 0 24px 0",
        },
      })}
    >
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            gridGap: "20px",
            [theme.breakpoints.down("md")]: {
              gridGap: 50,
            },
          })}
        >
          <Navigate to="https://www.8xr.io/" openInNewWindow={true}>
            <Typography
              sx={(theme) => ({
                fontSize: "16px",
                lineHeight: "20px",
                paddingTop: "12px",
                color: palette.primary.white,
                display: "block",
                fontWeight: "700",
                [theme.breakpoints.down("md")]: {
                  fontSize: "13px",
                },
              })}
              variant="body1"
            >
              Powered by 8XR 2023
            </Typography>
          </Navigate>
          <Stack
            direction="row"
            sx={(theme) => ({
              position: "absolute",
              left: "50%",
              transform: "translate(-50%,0)",
              gridGap: "32px",
              [theme.breakpoints.down("md")]: {
                gridGap: "18px",
                position: "static",
                transform: "none",
              },
            })}
          >
            <Twitter />
            <Instagram />
            <Discord />
          </Stack>
          <Box
            component="div"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          />
        </Stack>
      </Container>
    </Stack>
  );
}
