import { memo } from "react";
import { useThree } from "@react-three/fiber";
import ISceneFog from "types/ISceneFog";

type SceneFogProps = {
  dto: ISceneFog;
};

export const SceneFog = memo(({ dto }: SceneFogProps) => {
  const { type, color, near, far, density } = dto;
  const { scene } = useThree();

  if (type === "linear") {
    return <fog attach="fog" args={[color, near, far]} />;
  } else if (type === "exponential-squared") {
    return <fogExp2 attach="fog" args={[color, density]} />;
  } else {
    scene.fog = null;
  }

  return null;
});
