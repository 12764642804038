import { useEffect } from "react";
import { remoteCollisions } from "./models";
import { useEntityManager } from "~/view-scene/runtime";
import { ActivationState } from "~/types/IRigidBodyComponent";
import { CollisionEvent } from "./types";

export function useHandleRemoteCollisions(subscribeToRemoteUpdates: boolean) {
  useEffect(() => {
    if (!subscribeToRemoteUpdates) {
      return;
    }

    const subscription = remoteCollisions.watch((collisionEvents) => {
      collisionEvents.forEach((collisionEvent) => handleRemoteCollisionEvent(collisionEvent));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [subscribeToRemoteUpdates]);
}

function handleRemoteCollisionEvent(collisionEvent: CollisionEvent) {
  const physicsBody = useEntityManager.getState().getEntityContext(collisionEvent.entityId)?.current?.getPhysicsBody();
  if (!physicsBody) {
    return;
  }

  const initialType = physicsBody.rigidBodyType;
  const initialActivationState = physicsBody.activationState;
  physicsBody.forceActivationState(ActivationState.DISABLE_DEACTIVATION);
  physicsBody.setVelocity(0, 0, 0);
  physicsBody.setAngularVelocity(0, 0, 0);
  physicsBody.setRigidBodyType("kinematic");

  const position = collisionEvent.position;
  physicsBody.setPosition(position[0], position[1], position[2]);

  physicsBody.nextTick(() => {
    physicsBody.setRigidBodyType(initialType);
    physicsBody.forceActivationState(initialActivationState);

    const leanerVelocity = collisionEvent.leanerVelocity;
    physicsBody.setVelocity(leanerVelocity[0], leanerVelocity[1], leanerVelocity[2]);

    const angularVelocity = collisionEvent.angularVelocity;
    physicsBody.setAngularVelocity(angularVelocity[0], angularVelocity[1], angularVelocity[2]);

    if (initialType === "dynamic") {
      physicsBody.activate(true);
    }
  });
}
