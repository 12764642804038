import { RefObject, useMemo } from "react";
import { createEqualizer } from "./createEqualizer";

export const useEqualizer = (equalizerRef: RefObject<AnalyserNode | null>) => {
  return useMemo(() => {
    const update = createEqualizer();

    return () => update(equalizerRef.current);
  }, []);
};
