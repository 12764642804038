import { Paper, Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { Lock } from "~/common/components/Icon";
import { BackToExplore, HeaderWithNavigation } from "~/common/components";
import { HOST } from "~/config";

export function NoAccessScreen() {
  return (
    <Container>
      <HeaderWithNavigation enableNavigation={HOST === "8xr"} label="Explore" target="/" />
      <MessageContainer>
        <LockIcon />
        <Typography fontSize="20px" lineHeight="28px" fontWeight={600}>
          No access
        </Typography>
        <Typography fontSize="14px" lineHeight="19.6px" fontWeight={500} align="center" color={palette.primary.gray800}>
          Sorry, but you do not have access to the requested content.
        </Typography>
        <BackToExplore text="Go to Explore" />
      </MessageContainer>
    </Container>
  );
}

const Container = styled(Paper)(({ theme }) => ({
  position: "absolute",
  width: "100vw",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: "100vh",
  minHeight: "550px",
  backgroundSize: "cover",
  [theme.breakpoints.down("lg")]: {
    overflow: "auto",
    minHeight: "700px",
    height: "100vh",
    WebkitOverflowScrolling: "touch",
  },
}));

const MessageContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "416px",
  height: "196px",
  borderRadius: "12px",
  padding: "32px",
  gap: "16px",
  border: "1px solid #FFFFFF1A",
  backgroundColor: palette.primary.black800,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 140px)",
  },
}));

const LockIcon = styled(Lock)`
  width: 42.67px;
  height: 48px;
`;
