import { useSceneData } from "~/common/stores/useSceneData";
import shallow from "zustand/shallow";
import { useAsset } from "~/entities/assets";
import IModelAsset from "~/types/IModelAsset";

export function useAvatarUrl(avatarUrl?: string | null) {
  const avatarSystem = useSceneData((state) => state.sceneState?.avatarSystem, shallow);
  const customAvatarAsset = useAsset<IModelAsset>(avatarSystem?.customAvatarAsset);

  if (avatarSystem?.avatarSource === "rpm" && avatarUrl) {
    return avatarUrl;
  } else if (avatarSystem?.avatarSource === "customModel" && avatarSystem?.customAvatarAsset && customAvatarAsset) {
    const variant = customAvatarAsset.variants.find((v) => v.id === avatarSystem?.customAvatarAssetVariant);
    if (variant) {
      return variant.url;
    }
  }

  return "/static/models/default-avatar.glb";
}
