import { escapeFileName } from "./escapeFileName";

export const downloadFile = (blob: Blob, filename: string) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", escapeFileName(filename));

  const parentNode = document.body;
  parentNode.appendChild(link);
  link.click();
  parentNode.removeChild(link);
};
