import { NoToneMapping, sRGBEncoding } from "three";
import { memo, Suspense } from "react";
import { Stack } from "@mui/material";
import { Canvas, Props as CanvasProps } from "@react-three/fiber";
import { Environment } from "view-scene/Environment";
import { useSceneData } from "common/stores/useSceneData";
import { AdaptiveDpr, AdaptiveEvents, FirstPersonControls } from "@react-three/drei";
import shallow from "zustand/shallow";
import {
  AudioSystem,
  RaycasterSetup,
  RenderPostprocessingLazy,
  RenderSceneEntities,
  SceneBackgroundColor,
  SceneFog,
} from "~/view-scene";
import useSessionStatus from "view-scene/stores/useSessionStatus";
import { CameraSystem } from "~/view-scene/CameraSystem";

export const RenderEmbeddedScene = memo(() => {
  const mode = useSessionStatus((state) => state.mode);

  const sceneState = useSceneData((state) => state.sceneState, shallow);

  if (!sceneState) {
    return null;
  }

  const { fog, skybox, backgroundColor, objects, environment, postprocessing } = sceneState;

  const glConfig: NonNullable<CanvasProps["gl"]> = {
    outputEncoding: sRGBEncoding,
    toneMapping: NoToneMapping,
    localClippingEnabled: true,
  };

  return (
    <Stack sx={{ position: "absolute", width: "100%", height: "100%", zIndex: -1 }}>
      <Canvas
        style={{ background: "#1d1d1d" }}
        shadows={false}
        gl={glConfig}
        performance={{ min: 0.7 }}
        dpr={[0.4, 2.0]}
      >
        <RaycasterSetup />
        <CameraSystem />

        <FirstPersonControls lookSpeed={0.03} />

        <AdaptiveDpr pixelated />
        <AdaptiveEvents />

        <AudioSystem />

        {mode !== "vr" && (
          <Suspense fallback={null}>
            {postprocessing?.enabled && <RenderPostprocessingLazy dto={postprocessing} />}
          </Suspense>
        )}

        {fog && <SceneFog dto={fog} />}
        {backgroundColor && <SceneBackgroundColor dto={backgroundColor} />}

        <Suspense fallback={null}>
          {skybox && <Environment background="only" environmentAssetId={skybox} />}
          {environment && <Environment background={false} environmentAssetId={environment} />}
        </Suspense>

        <RenderSceneEntities entities={objects} />
      </Canvas>
    </Stack>
  );
});
