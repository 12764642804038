import { RefObject } from "react";
import { ScriptArguments } from "~/types/IScriptComponent";
import { JSScriptSchema } from "~/types/JSScriptAsset";
import { EntityContext } from "~/view-scene/runtime";
import { Script } from "../Script";
import type { SceneContext } from "../useSceneContext";
import { JSEventsManager } from "./JSEventsManager";
import { JSVariablesManager } from "./JSVariableManager";
import { JSScriptExports } from "./types";
import { initJSScript } from "./utils";

export class JSScript extends Script {
  private export!: JSScriptExports;
  private scriptCode!: Function;

  constructor(
    id: string,
    private args: ScriptArguments,
    readonly code: string,
    schema: JSScriptSchema,
    entityContextRef: RefObject<EntityContext>,
    private sceneContext: SceneContext
  ) {
    super(id);

    const res = initJSScript(code, {
      sceneContext: this.sceneContext,
      entity: entityContextRef.current!,
    });

    if (!res) {
      throw new Error(`Failed to init script ${id}`);
    }

    this.scriptCode = res.scriptCode;
    this.export = res.exportObject;

    this.variables = new JSVariablesManager(schema.variables, args, this.export);
    this.events = new JSEventsManager(schema.events, this.export);

    this.hasTick = this.export.tick !== undefined;
  }

  init() {}

  start() {
    this.export.start?.();
  }

  tick(delta: number) {
    this.export.tick?.(delta);
  }

  gameSessionStarted() {}

  storageUpdate(storageId: string) {}

  dispose() {
    this.export.dispose?.();
  }
}
