import { BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class GetInviteLinkNode extends BaseNode {
  static readonly type = "hackneyapi/getBasicInvite";
  static readonly label = "Telegram Get Invite";

  inputs = [new FlowSocket("flow"), new StringSocket("tMeURL")];
  outputs = [new FlowSocket("flow"), new StringSocket("inviteUrl")];

  eval(context: NodeEvalContext) {
    const urlBase = context.readInput<string>("tMeURL");
    const telegramUserId = window.Telegram?.WebApp.initDataUnsafe.user?.id;

    if (!urlBase || !telegramUserId) {
      console.log("URL or User Telegram Id is not set");
      return;
    }

    const inviteUrl = urlBase + "?startapp=referal=" + telegramUserId;
    context.writeOutput<string>("inviteUrl", inviteUrl);
  }
}
