import { useGLTF as useDreiGLTF } from "@react-three/drei";
import { WebGLRenderer } from "three";
import { GLTF } from "three-stdlib";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import { BASIS_TRANSCODER_URL } from "~/config";
import GLTFMeshGpuInstancingExtension from "./GLTFMeshGpuInstancingExtension";

export const useGLTF = <T extends string | string[]>(url: T): T extends any[] ? GLTF[] : GLTF => {
  return useDreiGLTF(url, true, true, (loader) => {
    if (!window.ktx2Loader) {
      window.ktx2Loader = getKtxLoader();
    }

    loader.setKTX2Loader(window.ktx2Loader);
    loader.register((parser) => new GLTFMeshGpuInstancingExtension(parser));
  });
};

const getKtxLoader = () => {
  const renderer = new WebGLRenderer();

  const loader = new KTX2Loader();

  loader.setTranscoderPath(BASIS_TRANSCODER_URL);
  loader.detectSupport(renderer);

  return loader;
};
