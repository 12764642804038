import { BackSide, DoubleSide, FrontSide } from "three";
import { MaterialSide } from "~/types/material";

export function toMaterialSide(side: MaterialSide) {
  if (side === MaterialSide.DOUBLE) {
    return DoubleSide;
  } else if (side === MaterialSide.BACK) {
    return BackSide;
  } else {
    return FrontSide;
  }
}
