import { EventEmitter } from "eventemitter3";
import {
  BoxGeometry,
  CapsuleGeometry,
  ConeGeometry,
  CylinderGeometry,
  Matrix4,
  Mesh,
  Object3D,
  Quaternion,
  SphereGeometry,
  Vector3,
} from "three";
import { calcRelativeTransformation } from "../utils/calcRelativeTransformation";
import { iterateGeometries } from "../libs/three-to-ammo";
import { DefaultBufferSize } from "../libs/AmmoDebugDrawer";
import { Messenger } from "./Messenger";
import { PhysicsBody } from "./PhysicsBody";
import {
  AddConstraintMessage,
  BodyConfig,
  BodyReadyMessage,
  CommonOptions,
  ComplexShapeOptions,
  InitializedBodyConfig,
  InitMessage,
  PrimitiveShapeOptions,
  RemoveConstraintMessage,
  ShapeOptions,
  ShapeType,
  Vector,
} from "./types";
import { DebuggerData } from "./DebuggerData";
import { Indexer } from "./Indexer";
import { Collisions } from "./Collisions";
import {
  getDefaultActivationState,
  getDefaultCollisionGroup,
  getDefaultCollisionMask,
  getDefaultMass,
  getDefaultType,
} from "./utils";
import { isSharedArrayBufferSupports } from "~/common/utils/isSharedArrayBufferSupports";
import { ArrayBufferData, SharedArrayBufferData, Data, COLLISIONS_NUMBER, calcBytesSize } from "./data";

const initTimeout = 20000; // 20 sec

const tmpMatrix = new Matrix4();
const tmpMatrixParent = new Matrix4();
const tmpPosition = new Vector3();
const tmpRotation = new Quaternion();
const tmpScale = new Vector3();

const tmpGetObjectTransformMatrix = new Matrix4();
const tmpGetObjectTransformPosition = new Vector3();
const tmpGetObjectTransformRotation = new Quaternion();
const tmpGetObjectTransformScale = new Vector3();

export type PhysicsManagerConfig = Pick<PhysicsManager, "maxBodies" | "gravity">;

export class PhysicsManager extends EventEmitter {
  gravity: Vector;
  maxBodies: number;
  debuggerData: DebuggerData | null = null;

  private worker: Worker;
  private messenger: Messenger;
  private debuggerSharedArrayBuffer!: SharedArrayBuffer;

  private uidGenerator: Indexer;
  private constraintsUidGenerator: Indexer;
  private data: Data;
  private collisions: Collisions;
  private uids: number[] = [];
  private objectsData: Record<
    number,
    {
      object: Object3D;
      body: PhysicsBody;
      meta: any;
    }
  > = {};
  private tmpCollisionArr: number[] = new Array(COLLISIONS_NUMBER).fill(-1);

  private readonly primitiveShapeTypes: PrimitiveShapeOptions["type"][] = [
    "box",
    "sphere",
    "cylinder",
    "cone",
    "capsule",
  ];

  constructor({ maxBodies = 1000, gravity = { x: 0, y: -9.81, z: 0 } }: Partial<PhysicsManagerConfig> = {}) {
    super();
    this.maxBodies = maxBodies;
    this.gravity = gravity;

    this.worker = new Worker(new URL("./worker/ammo.worker", import.meta.url));
    this.messenger = new Messenger(this.worker);
    this.data = this.initData();
    this.collisions = new Collisions(this.data.maxBodies);
    this.uidGenerator = new Indexer(maxBodies);
    this.constraintsUidGenerator = new Indexer(maxBodies * 4);
  }

  async init() {
    return new Promise<void>((resolve, reject) => {
      this.messenger.sendInit({
        dataTransfer: this.getTransferConfig(),
        gravity: this.gravity,
      });

      this.messenger.once("ready", () => {
        resolve();
        clearTimeout(timeoutId);
      });

      const timeoutId = setTimeout(() => {
        reject(new Error("Worker timed out"));
      }, initTimeout);

      this.messenger.on("bodyReady", this.handleBodyReady.bind(this));
    });
  }

  enableDebugger() {
    if (!this.debuggerData) {
      this.debuggerSharedArrayBuffer = this.initDebuggerBuffer();
      this.debuggerData = new DebuggerData(this.debuggerSharedArrayBuffer);

      this.messenger.sendInitDebugger({
        enabled: true,
        sharedArrayBuffer: this.debuggerSharedArrayBuffer,
      });
    }
  }

  attachObjectToBody(uid: number, object: Object3D) {
    if (this.objectsData[uid]) {
      this.objectsData[uid] = {
        ...this.objectsData[uid],
        object,
      };
    }
  }

  update() {
    if (!this.data.isReady()) {
      return;
    }

    for (const uid of this.uids) {
      const objectData = this.objectsData[uid];

      switch (objectData.body.rigidBodyType) {
        // three -> ammo
        case "kinematic": {
          const transform = this.getObjectTransform(objectData.object);
          this.data.setTransform(uid, transform);
          break;
        }
        // ammo -> three
        case "dynamic": {
          this.data.applyTransform(uid, tmpMatrix);
          const parentTransform = this.getParentMatrix(objectData.object);

          parentTransform.invert();
          parentTransform.multiply(tmpMatrix);
          objectData.object.position.setFromMatrixPosition(parentTransform);
          objectData.object.quaternion.setFromRotationMatrix(parentTransform);

          this.data.applyLeanerVelocity(uid, objectData.body.leanerVelocity);
          this.data.applyAngularVelocity(uid, objectData.body.angularVelocity);
          break;
        }
      }

      this.data.applyCollisions(uid, this.tmpCollisionArr);

      for (let i = 0; i < this.tmpCollisionArr.length; i++) {
        const uid = this.tmpCollisionArr[i];

        if (uid === -1) {
          break;
        }

        if (this.collisions.isNew(uid)) {
          const unmarkedUid = this.collisions.unmarkIsNew(uid);
          const otherObjectData = this.objectsData[unmarkedUid];

          if (!otherObjectData) {
            continue;
          }

          objectData.body.emit("collisionStart", otherObjectData);
        }
      }

      this.data.applyEndedCollisions(uid, this.tmpCollisionArr);

      for (let i = 0; i < this.tmpCollisionArr.length; i++) {
        const uid = this.tmpCollisionArr[i];

        if (uid === -1) {
          break;
        }

        const otherObjectData = this.objectsData[uid];

        if (!otherObjectData) {
          continue;
        }

        objectData.body.emit("collisionEnd", otherObjectData);
      }

      /* Uncomment if "collision" event is required
      const collisionObjects: Object3D[] = [];

      for (let i = 0; i < this.tmpCollisionArr.length; i++) {
        const uid = this.tmpCollisionArr[i];

        if (uid !== -1) {
          collisionObjects.push(this.objects[uid]);
        }
      }

      if (collisionObjects.length > 0) {
        object.physicsBody!.emit("collision", { objects: collisionObjects });
      }
      */
    }

    this.data.consume();
  }

  destroy() {
    this.messenger.destroy();
    this.worker.terminate();
  }

  addBody(object: Object3D, meta: any, config: BodyConfig) {
    const initializedConfig = this.initializeBodyConfig(config);
    const { ignoreScale = false } = config;

    const matrix = this.getObjectTransform(object);

    if (ignoreScale) {
      const scale = new Vector3(1, 1, 1);
      matrix.scale(scale);
    }

    const uid = this.uidGenerator.getNext();
    const physicsBody = new PhysicsBody(
      uid,
      initializedConfig.type,
      initializedConfig.ghost,
      this.messenger,
      initializedConfig
    );
    this.objectsData[uid] = {
      object,
      meta,
      body: physicsBody,
    };

    this.messenger.sendAddBody({
      uid,
      transform: matrix.elements,
      mass: initializedConfig.mass,
      type: initializedConfig.type,
      ghost: initializedConfig.ghost,
      activationState: initializedConfig.activationState,
      collisionGroup: initializedConfig.collisionGroup,
      collisionMask: initializedConfig.collisionMask,
    });

    this.sendShape(uid, config.shape, config.type);
    this.messenger.sendFinishBody({
      uid,
    });

    return physicsBody;
  }

  removeBody(uid: number) {
    if (!this.objectsData[uid]) {
      return;
    }

    this.objectsData[uid].body.destroy();
    this.messenger.sendRemoveBody({ uid });
    this.uidGenerator.release(uid);
    this.uids.splice(this.uids.indexOf(uid), 1);
    delete this.objectsData[uid];
  }

  getStepDuration() {
    return this.data.getStepDuration();
  }

  private sendShape(uid: number, shapeConfig?: BodyConfig["shape"], type?: BodyConfig["type"]) {
    const autoDetect = !shapeConfig || shapeConfig.type === "auto";
    const objectData = this.objectsData[uid];
    let objectShapeOptions: ShapeOptions | null = null;
    let hasChildShape = false;

    if (autoDetect) {
      if (this.objectIsMesh(objectData.object)) {
        objectShapeOptions = this.meshToShapeOptions(objectData.object, null, type);
      }
    } else {
      objectShapeOptions = this.isPrimitiveShape(shapeConfig)
        ? shapeConfig
        : {
            ...shapeConfig,
            ...this.extractData(objectData.object),
          };
    }

    if (objectShapeOptions) {
      this.messenger.sendAddShape({
        bodyUid: uid,
        shape: objectShapeOptions,
      });
    }

    // with autodetect also traverse via children
    if (autoDetect) {
      this.traverse(objectData.object, (child) => {
        if (this.objectIsMesh(child)) {
          hasChildShape = true;
          const options = this.meshToShapeOptions(child, objectData.object, type);

          this.messenger.sendAddShape({
            bodyUid: uid,
            shape: options,
          });
        }
      });
    }

    if (!objectShapeOptions && !hasChildShape) {
      console.warn(`Object "${objectData.object.name}" has no shape!`);
    }
  }

  addConstraint(data: Omit<AddConstraintMessage["data"], "uid">) {
    const uid = this.constraintsUidGenerator.getNext();

    this.messenger.sendAddConstraint({
      ...data,
      uid,
    });

    return {
      uid,
    };
  }

  removeConstraint(data: RemoveConstraintMessage["data"]) {
    this.messenger.sendRemoveConstraint(data);
  }

  setWorldGravity(gravity: Vector) {
    this.gravity = gravity;
    this.messenger.sendSetWorldGravity({ gravity });
  }

  private initializeBodyConfig(config: BodyConfig) {
    const type = config.type ?? getDefaultType();
    const ghost = config.ghost ?? false;

    const initializedBodyConfig: InitializedBodyConfig = {
      ...config,
      type,
      ghost,
      mass: config.mass ?? getDefaultMass(type),
      activationState: config.activationState ?? getDefaultActivationState(type),
      collisionGroup: config.collisionGroup ?? getDefaultCollisionGroup(type),
      collisionMask: config.collisionMask ?? getDefaultCollisionMask(),
    };

    return initializedBodyConfig;
  }

  private traverse(obj: Object3D, callback: (obj: Object3D) => void) {
    for (const child of obj.children) {
      if (!this.isSceneEntity(child)) {
        callback(child);
        this.traverse(child, callback);
      }
    }
  }

  private isSceneEntity(obj: Object3D) {
    return Boolean(obj.userData.eightXRId);
  }

  private meshToShapeOptions(
    mesh: Mesh,
    rootObject: Object3D | null = null,
    type?: BodyConfig["type"]
  ): PrimitiveShapeOptions | ComplexShapeOptions {
    const shapeType: ShapeType = this.geometryTypeToShapeType(mesh.geometry.type);
    const offset = rootObject ? this.getChildOffset(mesh, rootObject) : undefined;

    switch (shapeType) {
      case "box": {
        const boxMesh = mesh as Mesh<BoxGeometry>;
        return {
          type: "box",
          width: boxMesh.geometry.parameters.width,
          height: boxMesh.geometry.parameters.height,
          depth: boxMesh.geometry.parameters.depth,
          offset,
        };
      }

      case "sphere": {
        const sphereMesh = mesh as Mesh<SphereGeometry>;
        return {
          type: "sphere",
          radius: sphereMesh.geometry.parameters.radius,
          offset,
        };
      }

      case "cylinder": {
        const cylinderMesh = mesh as Mesh<CylinderGeometry>;
        return {
          type: "cylinder",
          radius: cylinderMesh.geometry.parameters.radiusTop,
          height: cylinderMesh.geometry.parameters.height,
          offset,
        };
      }

      case "cone": {
        const coneMesh = mesh as Mesh<ConeGeometry>;
        return {
          type: "cone",
          // @ts-expect-error Property 'radius' had to be.
          radius: coneMesh.geometry.parameters.radius,
          height: coneMesh.geometry.parameters.height,
          offset,
        };
      }

      case "capsule": {
        const capsuleMesh = mesh as Mesh<CapsuleGeometry>;
        return {
          type: "capsule",
          radius: capsuleMesh.geometry.parameters.radius,
          height: capsuleMesh.geometry.parameters.length,
          offset,
        };
      }

      default: {
        const data = this.extractData(mesh);
        const isDynamicObject = type === "dynamic";

        return {
          type: isDynamicObject ? "hull" : "concaveMesh",
          ...data,
          offset,
        };
      }
    }
  }

  private getChildOffset(object: Object3D, rootObject: Object3D): Required<CommonOptions["offset"]> {
    const matrix = calcRelativeTransformation(object, rootObject);
    matrix.decompose(tmpPosition, tmpRotation, tmpScale);

    return {
      position: {
        x: tmpPosition.x,
        y: tmpPosition.y,
        z: tmpPosition.z,
      },
      quaternion: {
        x: tmpRotation.x,
        y: tmpRotation.y,
        z: tmpRotation.z,
        w: tmpRotation.w,
      },
      scale: {
        x: tmpScale.x,
        y: tmpScale.y,
        z: tmpScale.z,
      },
      margin: 0.01,
    };
  }

  private geometryTypeToShapeType(geometryType: string): ShapeType {
    return geometryTypeToShapeTypeMap[geometryType] ?? "concaveMesh";
  }

  private extractData(object: Object3D) {
    const vertices: Float32Array[] = [];
    const matrices: number[][] = [];
    const indexes: Uint16Array[] = [];

    iterateGeometries(object, {}, (vertexArray: Float32Array, matrixArray: number[], indexArray: Uint16Array) => {
      vertices.push(vertexArray);
      matrices.push(matrixArray);
      indexes.push(indexArray);
    });

    return { vertices, matrices, indexes };
  }

  private isPrimitiveShape(shape: Required<BodyConfig>["shape"]): shape is PrimitiveShapeOptions {
    return this.primitiveShapeTypes.includes(shape.type as any);
  }

  private initData(): Data {
    if (isSharedArrayBufferSupports()) {
      const sharedArrayBuffer = new SharedArrayBuffer(calcBytesSize(this.maxBodies));
      return new SharedArrayBufferData(sharedArrayBuffer);
    } else {
      return new ArrayBufferData(this.maxBodies, this.messenger);
    }
  }

  private getTransferConfig(): InitMessage["data"]["dataTransfer"] {
    if (this.data instanceof SharedArrayBufferData) {
      return {
        type: "sharedArrayBuffer",
        sharedArrayBuffer: this.data.sharedArrayBuffer,
      };
    } else {
      return {
        type: "postMessage",
        maxBodies: this.data.maxBodies,
      };
    }
  }

  private initDebuggerBuffer() {
    return new SharedArrayBuffer(4 + 2 * DefaultBufferSize * 4);
  }

  private getObjectTransform(object: Object3D) {
    object.getWorldPosition(tmpGetObjectTransformPosition);
    object.getWorldQuaternion(tmpGetObjectTransformRotation);
    object.getWorldScale(tmpGetObjectTransformScale);
    tmpGetObjectTransformMatrix.identity();

    tmpGetObjectTransformMatrix.compose(
      tmpGetObjectTransformPosition,
      tmpGetObjectTransformRotation,
      tmpGetObjectTransformScale
    );

    return tmpGetObjectTransformMatrix;
  }

  private handleBodyReady(data: BodyReadyMessage["data"]) {
    this.uids.push(data.uid);
  }

  private objectIsMesh(object: Object3D): object is Mesh {
    return Boolean((object as any).isMesh);
  }

  private getParentMatrix(object: Object3D) {
    const parent = object.parent;

    if (!parent) {
      return tmpMatrixParent.identity();
    } else {
      return this.getObjectTransform(parent);
    }
  }
}

const geometryTypeToShapeTypeMap: Record<string, ShapeType> = {
  BoxGeometry: "box",
  CapsuleGeometry: "capsule",
  CircleGeometry: "concaveMesh",
  ConeGeometry: "cone",
  CylinderGeometry: "cylinder",
  DodecahedronGeometry: "concaveMesh",
  ExtrudeGeometry: "hacd",
  IcosahedronGeometry: "concaveMesh",
  LatheGeometry: "concaveMesh",
  OctahedronGeometry: "concaveMesh",
  PlaneGeometry: "plane",
  PolyhedronGeometry: "concaveMesh",
  RingGeometry: "concaveMesh",
  ShapeGeometry: "concaveMesh",
  SphereGeometry: "sphere",
  TetrahedronGeometry: "concaveMesh",
  TorusGeometry: "concaveMesh",
  TorusKnotGeometry: "concaveMesh",
  TubeGeometry: "concaveMesh",
  WireframeGeometry: "concaveMesh",
};
