import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class SetWorldGravityNode extends BaseNode {
  static readonly type = "physics/setWorldGravityNode";
  static readonly label = "Set world gravity";

  inputs = [new FlowSocket("flow"), new Vector3Socket("gravity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const gravity = context.readInput<Vector3Value>("gravity");
    node.sceneContext.getPhysicsManager().setWorldGravity(gravity);
  }
}
