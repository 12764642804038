import { memo, useEffect } from "react";
import { usePhysics } from "~/view-scene/physics";
import { RigidBodyComponentProps } from "./types";
import { hasMesh } from "./utils";
import { useDynamicRigidBodyNetworkingSync } from "./useDynamicRigidBodyNetworkingSync";

export type AutoRigidBodyProps = RigidBodyComponentProps;

export const AutoRigidBody = memo(({ componentDto, contextRef }: AutoRigidBodyProps) => {
  const { addRigidBody, removeRigidBody } = usePhysics((state) => ({
    addRigidBody: state.addRigidBody,
    removeRigidBody: state.removeRigidBody,
  }));

  useEffect(() => {
    let object = contextRef.current?.rootObjectRef.current;
    const entityId = contextRef.current?.id;

    if (componentDto.attachTo) {
      object = contextRef.current?.getChild?.(componentDto.attachTo) ?? null;
    }

    if (!object) {
      return;
    }

    if (!hasMesh(object)) {
      return;
    }

    const physicsBody = addRigidBody(
      object,
      { entityId, rigidBodyId: componentDto.id },
      {
        type: componentDto.rigidBodyType,
        ghost: componentDto.ghost,
        mass: componentDto.mass,
        activationState: componentDto.activationState,
        collisionGroup: componentDto.collisionGroup,
        collisionMask: componentDto.collisionMask,
      }
    );

    if (contextRef.current) {
      contextRef.current.physicsBodies[componentDto.id] = physicsBody!;
    }

    return () => {
      if (physicsBody) {
        removeRigidBody(physicsBody.uid);
        if (contextRef.current) {
          delete contextRef.current.physicsBodies[componentDto.id];
        }
      }
    };
  }, []);

  useDynamicRigidBodyNetworkingSync(componentDto, contextRef);

  return null;
});
