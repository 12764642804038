import { createEvent } from "effector";
import { $storages } from "./storages";

export const deleteStorage = createEvent<string>();

$storages.on(deleteStorage, (storages, storageId) => {
  if (!storages) {
    return storages;
  }

  return storages.filter((storage) => storage.id !== storageId);
});
