import { useUnit } from "effector-react";
import { $activeLayout } from "~/projects/models";
import { CreateWorkspace } from "./CreateWorkspace";
import { EditWorkspace } from "./EditWorkspace";
import { Stack, styled } from "@mui/material";
import { palette } from "~/theme";

export function ManageWorkspace() {
  const activeLayout = useUnit($activeLayout);

  return <Container>{activeLayout === "createSpace" ? <CreateWorkspace /> : <EditWorkspace />}</Container>;
}

const Container = styled(Stack)`
  width: 642px;
  gap: 30px;
  padding-top: 10px;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.primary.gray200};
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.primary.gray600};
  }
`;
