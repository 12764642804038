import { Stack, styled } from "@mui/material";
import { useProfile } from "~/entities/profile";
import { Logo } from "./Logo";
import { HOST } from "~/config";
import { LoginButton } from "./LoginButton";
import { ProfileButton } from "./ProfileButton";
import { BackToExplore } from "./BackToExplore";

type HeaderWithNavigationProps = {
  showLogo?: boolean;
  enableNavigation: boolean;
  label: string;
  target: string;
};

export function HeaderWithNavigation({ showLogo = true, enableNavigation, label, target }: HeaderWithNavigationProps) {
  const isLoggedIn = useProfile((state) => state.isAuthorised());

  return (
    <HeaderContainer>
      <Stack direction="row" gap="16px" alignItems="center">
        {showLogo && (
          <Stack
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                order: 2,
                position: "absolute",
                left: "50%",
                transform: "translate(-50%,0)",
                ...(HOST !== "8xr" ? { top: 10 } : undefined),
              },
            })}
          >
            <Logo />
          </Stack>
        )}
        <Stack
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              order: 1,
            },
          })}
        >
          {enableNavigation && <BackToExplore text={label} url={target} />}
        </Stack>
      </Stack>
      {isLoggedIn ? <ProfileButton /> : <LoginButton />}
    </HeaderContainer>
  );
}

const HeaderContainer = styled(Stack)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  max-height: 64px;
  min-height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  backdrop-filter: blur(20px);
`;
