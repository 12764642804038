import { TextField } from "~/hephaestus/components/TextField";
import { Chip, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import { palette } from "~/theme";
import { Close } from "@mui/icons-material";

export type TagsFieldProps = {
  name: string;
  tags: string[];
  onChange: (tags: string[]) => void;
};

export function TagsField({ name, tags, onChange }: TagsFieldProps) {
  const [newTag, setNewTag] = useState("");

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    const newTag = (e.target as HTMLInputElement).value;

    if (!tags.includes(newTag)) {
      onChange([...tags, newTag]);
    }

    setNewTag("");
    e.preventDefault();
  };

  const handleDelete = (tag: string) => {
    const updatedTags = tags.filter((t) => t !== tag);
    onChange(updatedTags);
  };

  return (
    <Stack direction="column" spacing={1} width="100%">
      <TextField
        name={name}
        placeholder="Write a Tag, Press Enter"
        fullWidth
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyDown={handleAddTag}
      />
      {tags.length > 0 && (
        <Stack direction="row" gap={0.5} flexWrap="wrap">
          {tags.map((tag) => (
            <StyledChip
              key={tag}
              label={tag}
              size="small"
              deleteIcon={<CloseIcon />}
              onDelete={() => handleDelete(tag)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

const StyledChip = styled(Chip)`
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${palette.primary.gray800};
  background-color: ${palette.primary.gradient400};
  border-radius: 4px;
`;

const CloseIcon = styled(Close)`
  width: 14px;
  height: 14px;
`;
