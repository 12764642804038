import { MutableRefObject, useEffect, useRef } from "react";
import { PositionalAudio } from "three";
import IAudioSceneObject from "~/types/IAudioSceneObject";
import { $audioManager, addAudio, removeAudio } from "~/view-scene/audio";
import { RenderPositionalAudioHelper } from "./RenderPositionalAudioHelper";
import { useStoreMap } from "effector-react";
import { Audio as ThreeAudio } from "three/src/audio/Audio";
import { playHTMLMedia } from "~/view-scene/utils";

type RenderPositionalAudioProps = {
  dto: IAudioSceneObject;
  audio: HTMLAudioElement | AudioBuffer;
  drawHelper?: boolean;
  audioRef?: MutableRefObject<PositionalAudio | ThreeAudio | null>;
};

export const RenderPositionalAudio = ({
  dto: { autoplay, loop, volume, distanceModel, maxDistance, refDistance, rolloffFactor },
  audio,
  drawHelper = false,
  audioRef,
}: RenderPositionalAudioProps) => {
  const audioListener = useStoreMap($audioManager, (audioManager) => audioManager.audioListener!);

  const positionalAudioRef = useRef<PositionalAudio>(null);

  useEffect(() => {
    const positionalAudio = positionalAudioRef.current;
    if (!positionalAudio) {
      return;
    }

    if (audioRef) {
      audioRef.current = positionalAudio;
    }

    if (audio instanceof HTMLAudioElement) {
      positionalAudio.setMediaElementSource(audio);
      if (autoplay) {
        playHTMLMedia(audio);
      }
    } else if (audio instanceof AudioBuffer) {
      positionalAudio.setBuffer(audio);
      if (autoplay) {
        positionalAudio.play();
      }
    }

    positionalAudio.panner.panningModel = "equalpower";

    addAudio({ sourceType: "mediaVideo", node: positionalAudio });

    return () => {
      positionalAudio.stop();
      removeAudio(positionalAudio);
    };
  }, [audio]);

  useEffect(() => {
    const positionalAudio = positionalAudioRef.current;
    if (!positionalAudio) {
      return;
    }
    positionalAudio.setVolume(volume);
    positionalAudio.setLoop(loop);
    positionalAudio.setDistanceModel(distanceModel);
    positionalAudio.setMaxDistance(maxDistance);
    positionalAudio.setRefDistance(refDistance);
    positionalAudio.setRolloffFactor(rolloffFactor);
  }, [distanceModel, maxDistance, refDistance, rolloffFactor, volume, loop]);

  return (
    <>
      {/* 
      // @ts-ignore */}
      <positionalAudio args={[audioListener]} ref={positionalAudioRef} />
      {drawHelper && <RenderPositionalAudioHelper audioRef={positionalAudioRef} />}
    </>
  );
};
