import { createEvent, createStore } from "effector";

export type ActiveControlsMode = {
  mode: "camera" | "transform";
};

export const $activeControlsMode = createStore<ActiveControlsMode>({ mode: "camera" });

export const activateControlsMode = createEvent<ActiveControlsMode>();
$activeControlsMode.on(activateControlsMode, (_, mode) => mode);

export const $cameraControlsActive = $activeControlsMode.map((activeControls) => "camera" === activeControls.mode);
