import { useCallback, useRef } from "react";
import useAnimationFrame from "~/common/hooks/useAnimationFrame";
import { userActions } from "~/view-scene/ControlsSystem";
import { StaticJoystickProps } from "../joystick";
import { JoystickValue } from "../joystick/types";

const joystickSensitivity = 0.04;

export const useLook = () => {
  const lastValueRef = useRef<JoystickValue | null>(null);

  const handleChange: NonNullable<StaticJoystickProps["onChange"]> = useCallback((value) => {
    lastValueRef.current = value;
  }, []);

  const handleTouchEnd = useCallback(() => {
    lastValueRef.current = null;
  }, []);

  useAnimationFrame((deltaTime) => {
    if (lastValueRef.current) {
      const { side, forward } = lastValueRef.current;

      const normalisedSide = Math.sign(side) * Math.min(Math.abs(side), 1) * joystickSensitivity * deltaTime;
      const normalisedForward = Math.sign(forward) * Math.min(Math.abs(forward), 1) * joystickSensitivity * deltaTime;

      userActions.look.emit("fire", { side: normalisedSide, forward: normalisedForward });
    }
  });

  return {
    handleChange,
    handleTouchEnd,
  };
};
