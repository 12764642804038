import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { FlowSocket } from "../../sockets";
import { MethodBaseNode } from "./MethodBaseNode";

export class MethodCallNode extends MethodBaseNode {
  static type = "method/call";
  static label = "Call Function";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  async = true;

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    // TODO
    const methodId = node.spec.metadata!.methodId;
    node.scriptContext.scriptEvents.methodCall.emit({
      id: methodId,
      values: {},
      callback: () => {},
    });
  }

  getInputs(nodeSpec: NodeSpec) {
    return [...super.getInputs(nodeSpec), ...this.getMethodInputSockets(nodeSpec)];
  }

  getOutputs(nodeSpec: NodeSpec) {
    return [...super.getOutputs(nodeSpec), ...this.getMethodOutputSockets(nodeSpec)];
  }

  getLabel(nodeSpec: NodeSpec) {
    const method = this.getMethod(nodeSpec);

    return `Call ${method?.name ?? "function"}`;
  }
}
