import { NodeSpec } from "~/types/ScriptAsset";
import { BaseNode } from "../../base";
import { getEventId, getScriptId, variableToSocket } from "../../utils";

export abstract class CustomEventBaseNode extends BaseNode {
  protected getEvent(nodeSpec: NodeSpec) {
    const scriptId = getScriptId(nodeSpec);
    const eventId = getEventId(nodeSpec);

    const script = this.context.scriptsMap[scriptId ?? ""] ?? null;
    const event = script?.schema.events?.find((event) => event.id === eventId) ?? null;

    return event;
  }

  protected getEventInputsSockets(nodeSpec: NodeSpec) {
    const event = this.getEvent(nodeSpec);

    return event ? event.inputs.map((input) => variableToSocket(input, input.id)) : [];
  }
}
