import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { entityValue, EntityValue } from "../values";

export class EntitySocket extends BaseSocket {
  constructor(id: string, label: string = id, value: EntityValue = defaultEntity()) {
    super(entityValue.name, id, label, value);
  }

  clone() {
    return new EntitySocket(this.id, this.label, this.value);
  }
}
