import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, StringSocket } from "../../sockets";

export class SetUserAmplitudeNode extends BaseNode {
  static readonly type = "analytics/setUserAmplitudeNode";
  static readonly label = "Set User Amplitude";

  async = true;

  inputs = [new FlowSocket("flow"), new StringSocket("userId")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const userId = context.readInput<string>("userId");

    if (window.amplitude) window.amplitude.setUserId(userId);
  }
}
