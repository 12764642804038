import { ReactNode, useEffect } from "react";
import useSessionStatus from "../stores/useSessionStatus";
import { startSession } from "./models";

export type SceneTransitionGuardProps = {
  children: ReactNode;
};

export const SceneTransitionGuard = ({ children }: SceneTransitionGuardProps) => {
  const { sceneTransition, sessionStatus, setSceneTransition } = useSessionStatus((state) => ({
    sceneTransition: state.sceneTransition,
    sessionStatus: state.sessionStatus,
    setSceneTransition: state.setSceneTransition,
  }));

  useEffect(() => {
    if (sceneTransition && sessionStatus === "ready") {
      startSession();
      setSceneTransition(false);
    }
  }, [sessionStatus]);

  if (sceneTransition) {
    return null;
  }

  return <>{children}</>;
};
