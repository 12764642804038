import { memo, ReactNode } from "react";
import { Entity } from "../Entity";
import { RenderWater } from "./RenderWater";
import IWaterSceneObject from "~/types/IWaterSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";

export type WaterEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const WaterEntity = memo(({ entityId, children }: WaterEntityProps) => {
  const entity = useEntity<IWaterSceneObject>(entityId);

  return (
    <Entity entityId={entityId}>
      <RenderWater dto={entity} />
      {children}
    </Entity>
  );
});
