import { NodeEvalContext } from "~/libs/behave-graph";
import { TrajectoryComponentContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, TrajectorySocket } from "../../sockets";

export class TrajectoryInvertNode extends BaseNode {
  static readonly type = "action/trajectoryInvert";
  static readonly label = "Trajectory Invert";

  inputs = [new FlowSocket("flow"), new TrajectorySocket("trajectory")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.readComponent<TrajectoryComponentContext>(context, "trajectory")?.invert();
  }
}
