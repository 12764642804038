import { NodeEvalContext } from "~/libs/behave-graph";
import { SpriteContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, NumberSocket } from "../../sockets";

export class SpriteSetVerticalOffsetNode extends BaseNode {
  static readonly type = "action/spriteSetVerticalOffset";
  static readonly label = "Set Sprite Vertical Offset";

  inputs = [new FlowSocket("flow"), new EntitySocket("sprite"), new NumberSocket("verticalOffset")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const spriteContext = node.readEntity<SpriteContext>(context, "sprite");
    const verticalOffset = context.readInput<number>("verticalOffset");
    if (spriteContext) {
      spriteContext.verticalOffset = verticalOffset;
    }
  }
}
