import { memo, useEffect, useRef } from "react";
import { Color, MeshPhongMaterial } from "three";
import { IPhongMaterial } from "~/types/material";
import { useTextureMap } from "~/view-scene/texture";
import { materialManager } from "../MaterialManager";
import { MaterialRecord } from "../types";
import { toMaterialSide } from "../utils";

type PhongMaterialAssetProps = {
  materialRecord: MaterialRecord;
  material: IPhongMaterial;
};

export const PhongMaterialAsset = memo(({ materialRecord, material }: PhongMaterialAssetProps) => {
  const materialRef = useRef<MeshPhongMaterial | null>(null);

  useEffect(() => {
    const phongMaterial = new MeshPhongMaterial();
    materialRef.current = phongMaterial;

    materialManager.finishMaterialLoad(materialRecord.id, phongMaterial);
    return () => {
      phongMaterial.dispose();
    };
  }, []);

  const {
    displacementScale,
    color,
    transparent,
    opacity,
    side,
    emissive,
    emissiveIntensity,
    specular,
    shininess,
    bumpMap,
    bumpScale,
    fog,
  } = material;

  useEffect(() => {
    if (!materialRef.current) {
      return;
    }

    materialRef.current.displacementScale = displacementScale;
    materialRef.current.color = new Color(color);
    materialRef.current.specular = new Color(specular);
    materialRef.current.transparent = transparent;
    materialRef.current.opacity = opacity;
    materialRef.current.shininess = shininess;
    materialRef.current.side = toMaterialSide(side);
    materialRef.current.emissive = new Color(emissive);
    materialRef.current.emissiveIntensity = emissiveIntensity;

    if (bumpMap) {
      materialRef.current.bumpScale = bumpScale;
    }

    materialRef.current.fog = fog;

    materialRef.current.needsUpdate = true;
  }, [
    displacementScale,
    color,
    transparent,
    opacity,
    side,
    emissive,
    emissiveIntensity,
    specular,
    shininess,
    bumpMap,
    bumpScale,
    fog,
  ]);

  const { texture, normalMap, displacementMap, alphaMap, specularMap, emissiveMap } = material;

  const toTextureMap = useTextureMap(texture);
  const toNormalMap = useTextureMap(normalMap);
  const toDisplacementMap = useTextureMap(displacementMap);
  const toAlphaMap = useTextureMap(alphaMap);
  const toBumpMap = useTextureMap(bumpMap);
  const toSpecularMap = useTextureMap(specularMap);
  const toEmissiveMap = useTextureMap(emissiveMap);

  useEffect(() => {
    if (!materialRef.current) {
      return;
    }

    materialRef.current.map = toTextureMap;
    materialRef.current.normalMap = toNormalMap;
    materialRef.current.displacementMap = toDisplacementMap;
    materialRef.current.alphaMap = toAlphaMap;
    materialRef.current.bumpMap = toBumpMap;
    materialRef.current.specularMap = toSpecularMap;
    materialRef.current.emissiveMap = toEmissiveMap;
    materialRef.current.needsUpdate = true;
  }, [toTextureMap, toNormalMap, toDisplacementMap, toAlphaMap, toBumpMap, toSpecularMap, toEmissiveMap]);

  return null;
});
