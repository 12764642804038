import { Paper } from "@mui/material";
import { SceneTransitionGuard } from "~/view-scene/welcome-screen";
import { JoinScene } from "./JoinScene";

export function DefaultWelcomeScreen() {
  return (
    <SceneTransitionGuard>
      <Paper
        sx={(theme) => ({
          position: "absolute",
          width: "100vw",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100vh",
          backgroundSize: "cover",
          [theme.breakpoints.down("lg")]: {
            overflow: "auto",
            height: "100vh",
            WebkitOverflowScrolling: "touch",
          },
        })}
      >
        <JoinScene />
      </Paper>
    </SceneTransitionGuard>
  );
}
