import { Box, Typography, styled } from "@mui/material";
import { ReactNode } from "react";
import { palette } from "~/theme";
import { TipTypography } from "./typography";

export type LoadingOverlayProps = {
  bottomSlot: ReactNode;
};

export const LoadingOverlay = ({ bottomSlot }: LoadingOverlayProps) => {
  return (
    <Container>
      <TopBlock>
        <Icon />
        <Tips sx={{ marginTop: "10px" }}>
          <TipTypography textAlign="center" color={palette.primary.gray800}>
            Collect crystals in the mine, the more the better!
          </TipTypography>
        </Tips>
      </TopBlock>
      <BottomBlock>
        <Box component="div" sx={{ marginBottom: "62px" }}>
          {bottomSlot}
        </Box>
        <LogoContainer>
          <Logo src="/static/img/content/logo-8xr-labeled-new.svg" />
        </LogoContainer>
      </BottomBlock>
    </Container>
  );
};

const Container = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const TopBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 15vh;
  left: 50%;
  transform: translateX(-50%);
`;

const Icon = styled(Box)`
  width: 248px;
  height: 248px;
  background-image: url("/static/img/content/shard-quest/icon.webp");
  border-radius: 32px;
`;

const Tips = styled(Box)`
  box-sizing: border-box;
  width: 243px;
  padding: 8px;
  background: ${palette.primary.black200};
  border: 1px solid ${palette.primary.gray200};
  backdrop-filter: blur(4px);
  border-radius: 8px;
`;

const BottomBlock = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled(Box)`
  height: 80px;
  display: flex;
  align-self: stretch;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgba(255, 255, 255, 0.6);
  border-top: 1px solid ${palette.primary.black200};
  backdrop-filter: blur(4px);
  align-items: center;
  justify-content: center;
`;

const Logo = styled("img")`
  background-image: url("/static/img/content/logo-new.svg");
`;
