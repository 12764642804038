import { Icon, IconProps } from "./Icon";

export type PauseProps = IconProps;

export const Pause = (props: PauseProps) => (
  <Icon width="12px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" {...props}>
    <rect width="2" height="16" fill="currentcolor" rx="1" />
    <rect width="2" height="16" x="10" fill="currentcolor" rx="1" />
  </Icon>
);
