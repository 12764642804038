import { useLoader, useThree } from "@react-three/fiber";
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader";
import { BASIS_TRANSCODER_URL } from "~/config";

export const useBasisLoader = (url: string) => {
  const { gl } = useThree();

  return useLoader(BasisTextureLoader, url, (loader) => {
    loader.setTranscoderPath(BASIS_TRANSCODER_URL);
    loader.detectSupport(gl);
  });
};
