import { useStoreMap, useUnit } from "effector-react";
import { $scripts } from "~/entities/scripts";
import { Select, SelectItem, SelectProps } from "~/hephaestus/components";

export type ScriptSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | undefined;
  ignoreDefault?: boolean;
};

export const ScriptSelect = ({ value, ignoreDefault = false, ...rest }: ScriptSelectProps) => {
  const scripts = useStoreMap({
    store: $scripts,
    keys: [ignoreDefault],
    fn: (scripts, [ignoreDefault]) => {
      if (!ignoreDefault) {
        return scripts;
      }

      return scripts ? scripts.filter((script) => !script.isPublic) : null;
    },
  });

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        Select script
      </SelectItem>
      {scripts?.map((script) => (
        <SelectItem key={script.id} value={script.id}>
          {script.name}
        </SelectItem>
      ))}
    </Select>
  );
};
