import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { ArraySocket, NumberSocket } from "../../sockets";
import { anyValue } from "../../values";

export class ArrayLengthNode extends BaseNode {
  static readonly type = "array/length";
  static readonly label = "Array Length";

  inputs = [new ArraySocket("array", anyValue.name)];
  outputs = [new NumberSocket("length")];

  eval(context: NodeEvalContext) {
    const arr = context.readInput<any[]>("array");

    context.writeOutput<number>("length", arr.length);
  }
}
