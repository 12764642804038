import { memo, MutableRefObject, useMemo, useRef } from "react";
import IAudioSceneObject from "~/types/IAudioSceneObject";
import { RenderThreeAudio } from "./RenderThreeAudio";
import { RenderPositionalAudio } from "./RenderPositionalAudio";
import { useLoader } from "@react-three/fiber";
import { Audio as ThreeAudio, AudioLoader, PositionalAudio } from "three";
import { AudioContext, useEntityContext } from "~/view-scene/runtime";
import { playHTMLMedia } from "~/view-scene/utils";

type RenderAudioProps = {
  dto: IAudioSceneObject;
  audioUrl: string;
  drawHelper?: boolean;
  contextRef: MutableRefObject<AudioContext>;
};

export const RenderAudio = memo((props: RenderAudioProps) => {
  return props.dto.preload ? <RenderPreloadedAudio {...props} /> : <RenderLazyAudio {...props} />;
});

export const RenderLazyAudio = memo(({ dto, audioUrl, drawHelper = false, contextRef }: RenderAudioProps) => {
  const { isPositional, loop } = dto;
  const audioRef = useRef<PositionalAudio | ThreeAudio | null>(null);

  const audio = useMemo(() => {
    return Object.assign(new Audio(audioUrl), {
      preload: "auto",
      crossOrigin: "anonymous",
      playsInline: true,
      loop: loop,
    });
  }, [audioUrl, isPositional]);

  useEntityContext(contextRef, () => ({
    type: "audio" as const,
    play: () => playHTMLMedia(audio),
    pause: () => audio.pause(),
    stop: () => {
      audio.pause();
      audio.currentTime = 0;
    },
    setVolume: (volume) => {
      audio.volume = volume;
    },
    getCurrentTime: () => audio.currentTime ?? -1,
    setCurrentTime: (time) => {
      audio.currentTime = time;
    },
    getDuration: () => {
      const duration = audio.duration;
      return duration === undefined || isNaN(duration) ? -1 : duration;
    },
  }));

  if (isPositional) {
    return <RenderPositionalAudio dto={dto} audio={audio} drawHelper={drawHelper} audioRef={audioRef} />;
  } else {
    return <RenderThreeAudio dto={dto} audio={audio} audioRef={audioRef} />;
  }
});

export const RenderPreloadedAudio = memo(({ dto, audioUrl, drawHelper = false, contextRef }: RenderAudioProps) => {
  const audioBuffer = useLoader(AudioLoader, audioUrl);
  const audioRef = useRef<PositionalAudio | ThreeAudio | null>(null);

  useEntityContext(contextRef, () => ({
    type: "audio" as const,
    play: () => audioRef.current?.play(),
    pause: () => audioRef.current?.pause(),
    stop: () => {
      if (audioRef.current?.isPlaying) {
        audioRef.current?.stop();
      }
    },
    setVolume: (volume) => audioRef.current?.setVolume(volume),
    getCurrentTime: () => -1,
    setCurrentTime: (time) => {
      const audio = audioRef.current;
      if (audio) {
        audio.offset = time;
      }
    },
    getDuration: () => {
      const duration = audioRef.current?.buffer?.duration;
      return duration === undefined || isNaN(duration) ? -1 : duration;
    },
  }));

  if (dto.isPositional) {
    return <RenderPositionalAudio dto={dto} audio={audioBuffer} drawHelper={drawHelper} audioRef={audioRef} />;
  } else {
    return <RenderThreeAudio dto={dto} audio={audioBuffer} audioRef={audioRef} />;
  }
});
