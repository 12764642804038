import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, NodeSocket } from "../../base";
import { FlowSocket } from "../../sockets";
import { StorageBaseNode } from "./StorageBaseNode";

export class StorageSetNode extends StorageBaseNode {
  static type = "variable/storageSet";
  static label = "Storage Set";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const values: Record<string, any> = {};

    for (const field of node.storage.storage.fields) {
      if (node.connectedInputs.has(field.id)) {
        const value = context.readInput(field.id);

        if (value !== undefined) {
          values[field.id] = value;
        }
      }
    }

    node.sceneContext.updateUserStorageData(node.storage.storage.id, values);
  }

  getInputs(nodeSpec: NodeSpec): NodeSocket[] {
    return [...super.getInputs(nodeSpec), ...this.getStorageSockets(nodeSpec)];
  }
}
