import { defaultVector3 } from "~/entities/variable";
import { BaseSocket } from "../base";
import { vector3Value, Vector3Value } from "../values/vector3Value";

export class Vector3Socket extends BaseSocket {
  constructor(id: string, label: string = id, value: Vector3Value = defaultVector3()) {
    super(vector3Value.name, id, label, value);
  }

  clone() {
    return new Vector3Socket(this.id, this.label, this.value);
  }
}
