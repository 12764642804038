import { Socket } from "~/libs/behave-graph";
import type { SocketsArray } from "./SocketsArray";

export class BaseSocket extends Socket {
  hidden = false;
  producedBy: SocketsArray | null = null;

  constructor(public type: string, public id: string, public label: string = id, value?: any) {
    super(type, id, value);
  }

  clone() {
    return new BaseSocket(this.type, this.id, this.label, this.value);
  }
}

export type SocketConstructorClass = { new (id: string, label?: string, value?: any): BaseSocket };
