import { Box, Stack, Typography, styled } from "@mui/material";
import { palette } from "~/theme";

export function DesktopGuidance() {
  return (
    <Container direction="column">
      <Box component="div">
        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: "100%",
            left: 0,
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Stack spacing={2} alignItems="flex-end" direction="row">
            <img src="/static/img/content/mouse.svg" alt="/static/img/content/mouse.svg" />
            <img src="/static/img/content/keyword.svg" alt="/static/img/content/keyword.svg" />
            <img src="/static/img/content/space.svg" alt="/static/img/content/space.svg" />
          </Stack>
        </Stack>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            width: "100%",
            maxWidth: "271px",
            textAlign: "center",

            margin: "16px auto 0",
            color: palette.primary.gray800,
          }}
          variant="body1"
        >
          Use mouse / trackpad to rotate. Use keyboard to move and jump. Press Shift to switch run and walk.
        </Typography>
      </Box>
    </Container>
  );
}

const Container = styled(Stack)`
  position: absolute;
  left: 0;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;
