import { v4 as uuid } from "uuid";
import { cloneHierarchical } from "~/common/utils/cloneHierarchical";
import ISceneObject from "~/types/ISceneObject";

export const cloneEntity = (entityId: string, entities: ISceneObject[]) => {
  const newEntities = cloneHierarchical(entityId, entities);

  for (const entity of newEntities) {
    entity.components.forEach((component) => {
      component.id = uuid();
    });
  }

  return newEntities;
};
