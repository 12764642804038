import { memo, ReactNode, useEffect, useRef } from "react";
import { useEntity } from "~/view-scene/utils/useEntity";
import NavMeshSceneObject from "~/types/NavMeshSceneObject";
import { useSceneData } from "~/common/stores/useSceneData";
import { Entity } from "~/view-scene/entities/Entity";
import { Group } from "three";
import { EnabledStatus } from "~/types/EnabledStatus";
import { loadFinish } from "~/view-scene/sceneLoad";
import { useNavMesh } from "./useNavMesh";
import { NavMeshContext } from "~/view-scene/runtime";

export type NavMeshEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const NavMeshEntity = memo(({ entityId, children }: NavMeshEntityProps) => {
  const entity = useEntity<NavMeshSceneObject>(entityId);
  const assetId = entity.asset;
  const url = useSceneData((state) => state.getModelUrl(assetId, entity.variantId));

  const navMesh = useNavMesh(url);

  const entityRef = useRef<Group>(null);

  const contextRef = useRef({
    type: "navMesh",
    navMesh: null,
  } as NavMeshContext);

  useEffect(() => {
    if (entity.enabled !== EnabledStatus.enabled || url == null) {
      loadFinish(entity.id);
      return;
    }

    if (!navMesh) {
      return;
    }

    contextRef.current.navMesh = navMesh;

    loadFinish(entity.id);
  }, [url, navMesh]);

  if (entity.enabled !== EnabledStatus.enabled) {
    return null;
  }

  return (
    <Entity entityId={entityId} ref={entityRef} context={contextRef}>
      {children}
    </Entity>
  );
});
