import { ValueDescriptor } from "~/types/Variable";

export const getValueLabel = (type: ValueDescriptor["type"]) => {
  return typeLabel[type];
};

export const typeLabel: Record<ValueDescriptor["type"], string> = {
  boolean: "Boolean",
  number: "Number",
  string: "String",
  vector3: "Vector 3",
  any: "Any",
  array: "Array",
  map: "Map",

  entity: "Entity",
  audio: "Audio",
  model: "Model",
  video: "Video",
  text: "Text",
  light: "Light",
  ui: "UI",
  uiOverlay: "UI Overlay",

  component: "Modification",
  actionable: "Actionable",
  animation: "Animation",
  trajectory: "Trajectory",
  followTarget: "Follow",
  constraint: "Constraint",

  asset: "Asset",
  assetMaterial: "Material",
  assetModel: "Model asset",

  scene: "Scene",

  uiEntity: "UI Entity",
  uiBlock: "UI Block",
  uiImage: "UI Image",
  uiText: "UI Text",

  struct: "Struct",

  event: "Event",
};
