import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { lightValue, LightValue } from "../values";

export class LightSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: LightValue = defaultEntity()) {
    super(lightValue.name, id, label, value);
  }

  clone() {
    return new LightSocket(this.id, this.label, this.value);
  }
}
