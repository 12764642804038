import { createEvent, createStore } from "effector";
import { useStoreMap } from "effector-react";
import { keyBy } from "lodash-es";
import { RefObject } from "react";
import { Group } from "three";
import ISceneObject from "~/types/ISceneObject";

export type EntityContext = {
  id: string;
  entity: ISceneObject;
  rootObjectRef: RefObject<Group>;
};

export const putContext = createEvent<EntityContext>();
export const removeContext = createEvent<string>();

export const $entityContexts = createStore([] as EntityContext[])
  .on(putContext, (state, context) => {
    let updated = false;

    const newState = state.map((contextState) => {
      if (contextState.id === context.id) {
        updated = true;
        return context;
      }

      return contextState;
    });

    if (updated) {
      return newState;
    }

    return [...state, context];
  })
  .on(removeContext, (state, id) => state.filter((context) => context.id !== id));

export const $entityContextsMap = $entityContexts.map((contexts) => keyBy(contexts, "id"));

export const useEntityContext = (id: string | null) => {
  const context = useStoreMap({
    store: $entityContextsMap,
    keys: [id],
    fn: (contexts, [id]) => (id ? contexts[id] : null),
  });

  return context ?? null;
};
