import { NodeContext } from "../../base";
import { AnimationSocket } from "../../sockets/AnimationSocket";
import { SceneComponentNode } from "./SceneComponentNode";

export class SceneAnimationNode extends SceneComponentNode {
  static readonly type = "variable/sceneAnimation";
  static readonly label = "Animation";

  constructor(context: NodeContext) {
    super(context, new AnimationSocket("animation"));
  }
}
