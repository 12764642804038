import { defaultUIEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { uiEntityValue, UIEntityValue } from "../values";

export class UIEntitySocket extends BaseSocket {
  constructor(id: string, label: string = id, value: UIEntityValue = defaultUIEntity()) {
    super(uiEntityValue.name, id, label, value);
  }

  clone() {
    return new UIEntitySocket(this.id, this.label, this.value);
  }
}
