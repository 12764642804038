import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";

export class SetPlayerMovementSpeedModificator extends BaseNode {
  static readonly type = "logic/setPlayerMovementSpeedModificator";
  static readonly label = "Set Movement Speed Modificator";

  inputs = [new FlowSocket("flow"), new NumberSocket("modificator")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const playerContext = node.sceneContext.getEntityContext<PlayerContext>("player");

    if (playerContext) {
      playerContext.movementSpeed = context.readInput<number>("modificator");
    }
  }
}
