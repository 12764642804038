import { HingeOptions } from "~/types/IConstraintComponent";
import { LabelGroup } from "../LabelGroup";
import { ConstraintInspectorBaseProps } from "./types";
import { VectorField } from "../VectorField";

export type HingeInspectorProps = ConstraintInspectorBaseProps<HingeOptions>;

export const HingeInspector = ({ value, onChange }: HingeInspectorProps) => {
  const handleChange = (e: { target: { name: string; value: any } }) => {
    onChange?.({
      target: {
        name: "hinge",
        value: {
          ...value,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <>
      <LabelGroup label="Axis">
        <VectorField name="axis" value={value.axis} onChange={handleChange} />
      </LabelGroup>
      <LabelGroup label="Target Axis">
        <VectorField name="targetAxis" value={value.targetAxis} onChange={handleChange} />
      </LabelGroup>
      <LabelGroup label="Pivot">
        <VectorField name="pivot" value={value.pivot} onChange={handleChange} />
      </LabelGroup>
      <LabelGroup label="Target Pivot">
        <VectorField name="targetPivot" value={value.targetPivot} onChange={handleChange} />
      </LabelGroup>
    </>
  );
};
