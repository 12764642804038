import { Select, SelectItem, SelectProps } from "./Select";
import { useUnit } from "effector-react";
import { $inventoryItems } from "~/entities/assets";

export type InventoryItemSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  value: string | null;
  emptyLabel?: string;
};

export const InventoryItemSelect = ({
  emptyLabel = "Select Inventory Item",
  value,
  ...rest
}: InventoryItemSelectProps) => {
  const inventoryItems = useUnit($inventoryItems);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        {emptyLabel}
      </SelectItem>
      {inventoryItems?.map((inventoryItem) => (
        <SelectItem key={inventoryItem.id} value={inventoryItem.id}>
          {inventoryItem.name}
        </SelectItem>
      ))}
    </Select>
  );
};
