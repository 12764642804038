import { memo, RefObject, useEffect } from "react";
import { Group } from "three";
import { EntityContext, useEntityManager, NewContextEventValue } from "~/view-scene/runtime";
import { IAttachToComponent } from "~/types/IAttachToComponent";

type AttachToComponentProps = {
  componentDto: IAttachToComponent;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const AttachToComponent = memo(({ componentDto, objectRef, contextRef }: AttachToComponentProps) => {
  const { getEntityContext, events } = useEntityManager((state) => ({
    getEntityContext: state.getEntityContext,
    events: state.events,
  }));

  useEffect(() => {
    const targetId = componentDto.targetId;
    const parentId = contextRef.current?.parentId;
    const object = contextRef.current?.rootObjectRef?.current;

    if (!targetId || !parentId || !object) {
      return;
    }

    const attach = (contextRef: RefObject<EntityContext>) => {
      const child = contextRef.current?.getChild?.(targetId);

      if (child) {
        child.add(object);
      }
    };

    const parentContext = getEntityContext(parentId);

    if (parentContext) {
      return attach(parentContext);
    } else {
      const handler = ({ entityId, contextRef }: NewContextEventValue) => {
        if (entityId === parentId) {
          attach(contextRef);
          events.off("newContext", handler);
        }
      };

      events.on("newContext", handler);
    }
  }, []);

  return null;
});
