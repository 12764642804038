import { EventEmitter } from "eventemitter3";

export interface AnimationComponentEvents {
  emit(event: "finish", value?: void): boolean;
  emit(event: "finish", value?: void): boolean;

  on(event: "finish", listener: () => void): this;
  on(event: "finish", listener: () => void): this;

  once(event: "finish", listener: () => void): this;
  once(event: "finish", listener: () => void): this;

  off(event: "finish", listener: () => void): this;
  off(event: "finish", listener: () => void): this;
}

export class AnimationComponentEvents extends EventEmitter {}
