import { NodeSpec } from "~/types/ScriptAsset";
import { Storage } from "~/types/Storage";
import { BaseBehaveNode, BaseNode, NodeContext } from "../../base";
import { getSockets } from "./utils";

export abstract class StorageBaseNode extends BaseNode {
  static utility = true;

  protected storagesMap: Record<string, Storage>;

  constructor(context: NodeContext) {
    super(context);
    this.storagesMap = context.storagesMap;
  }

  initNode(node: BaseBehaveNode) {
    node.storage.storage = this.getStorage(node.spec);
  }

  protected getStorageId(nodeSpec: NodeSpec) {
    return nodeSpec.metadata?.storageId ?? null;
  }

  protected getStorage(nodeSpec: NodeSpec) {
    const storageId = this.getStorageId(nodeSpec);

    return this.storagesMap[storageId ?? ""] ?? null;
  }

  protected getStorageSockets(nodeSpec: NodeSpec) {
    const storage = this.getStorage(nodeSpec);

    if (!storage) {
      return [];
    }

    return storage ? getSockets(storage) : [];
  }
}
