import type { Components, ThemeOptions } from "@mui/material";

export const MuiOutlinedInput: Components<ThemeOptions>["MuiOutlinedInput"] = {
  styleOverrides: {
    root: {
      borderRadius: "8px",
    },
    input: {
      padding: "4px 8px",

      "&.MuiInputBase-inputAdornedStart": {
        paddingLeft: "6px",
      },

      "&.MuiInputBase-inputAdornedEnd": {
        paddingRight: "6px",
      },
    },
  },
};
