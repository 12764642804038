import { applyTiling, mapTextureEncoding } from "../utils";
import { Texture } from "three";
import { TextureEncoding, TextureTiling } from "~/types/texture";

export function useCommonTextureProperties(
  texture: Texture | null,
  flipY: boolean,
  encoding?: TextureEncoding,
  tiling?: TextureTiling
) {
  if (!texture) {
    return;
  }

  texture.flipY = flipY;
  texture.encoding = mapTextureEncoding(encoding);
  applyTiling(texture, tiling);

  texture.needsUpdate = true;
}
