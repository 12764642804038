import { useCallback } from "react";
import { Stack, StackProps, styled } from "@mui/material";
import { SceneObjectType } from "~/types/SceneObjectType";
import { ComponentSelect, ComponentSelectProps } from "./ComponentSelect";
import { ComponentType } from "~/types/ComponentType";
import { EntitySelect, EntitySelectProps } from "./EntitySelect";

export type ComponentSelectorProps = Pick<StackProps, "className" | "sx"> & {
  name?: string;
  value?: ComponentSelectorValue;
  entityEmptyLabel?: string;
  entityTypeFilter?: SceneObjectType[];
  componentEmptyLabel?: string;
  componentTypeFilter?: ComponentType[];
  onChange?: (event: ComponentSelectorEvent) => void;
};

type ComponentSelectorEvent = {
  target: {
    name?: string;
    value: ComponentSelectorValue;
  };
};

type ComponentSelectorValue = {
  entityId: string | null;
  componentId: string | null;
};

export const ComponentSelector = ({
  name,
  value,
  entityEmptyLabel = "Select Entity",
  entityTypeFilter,
  componentEmptyLabel = "Select Component",
  componentTypeFilter,
  onChange,
  ...rest
}: ComponentSelectorProps) => {
  const handleEntityChange: NonNullable<EntitySelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            entityId: e.target.value,
            componentId: null,
          },
        },
      });
    },
    [name, onChange]
  );
  const handleComponentChange: NonNullable<ComponentSelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            entityId: value?.entityId ?? null,
            componentId: e.target.value,
          },
        },
      });
    },
    [name, value, onChange]
  );

  return (
    <Container {...rest}>
      <EntitySelect
        value={value?.entityId ?? null}
        emptyLabel={entityEmptyLabel}
        typeFilter={entityTypeFilter}
        onChange={handleEntityChange}
      />
      <ComponentSelect
        value={value?.componentId ?? null}
        entityId={value?.entityId}
        emptyLabel={componentEmptyLabel}
        typeFilter={componentTypeFilter}
        onChange={handleComponentChange}
      />
    </Container>
  );
};

const Container = styled(Stack)``;
