import { useStoreMap } from "effector-react";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { suspend } from "suspend-react";
import { PositionalAudio, VideoTexture as ThreeVideoTexture } from "three";
import { isAndroid } from "~/common/utils/isMobile";
import { $audioManager, addAudio, removeAudio } from "~/view-scene/audio";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { playHTMLMedia } from "~/view-scene/utils";
import { textureManager } from "../TextureManager";
import { VideoTextureRecord } from "../types";
import { useCommonTextureProperties } from "./useCommonTextureProperties";

const importHls = () => import("hls.js");

type HLSModule = Awaited<ReturnType<typeof importHls>>;

type VideoTextureProps = {
  textureRecord: VideoTextureRecord;
};

const videoCache: Record<string, HTMLVideoElement> = {};

export const VideoTexture = memo(({ textureRecord }: VideoTextureProps) => {
  const {
    id,
    encoding,
    url,
    isStream,
    loop,
    flipY,
    volume,
    audioAttachTo,
    autoplay,
    tiling,
    audioSourceType = "mediaVideo",
    distanceModel,
    maxDistance,
    refDistance,
    rolloffFactor,
  } = textureRecord;

  const HlsModule = suspend(importHls(), ["hls"]) as HLSModule;
  const Hls = HlsModule.default;
  const [canPlay, setCanPlay] = useState(false);
  const sessionStarted = useSessionStatus((s) => s.sessionStatus === "in_progress" || s.sessionStatus === "onpause");
  const audioListener = useStoreMap($audioManager, (audioManager) => audioManager.audioListener);

  const htmlVideo = useMemo(() => {
    if (!videoCache[id]) {
      const video = Object.assign(document.createElement("video"), {
        crossOrigin: "anonymous",
        playsInline: true,
        muted: volume === 0,
        defaultMuted: volume === 0,
        loop: loop,
      });

      videoCache[id] = video;
    }

    return videoCache[id];
  }, [loop, volume]);

  useEffect(() => {
    if (!htmlVideo || !url) {
      return;
    }

    const event = "loadedmetadata";
    const handler = () => setCanPlay(true);

    if (isStream) {
      if (htmlVideo.canPlayType("application/x-mpegurl") && !isAndroid()) {
        htmlVideo.addEventListener(event, handler, true);
        const source = document.createElement("source");
        source.setAttribute("src", url);
        source.setAttribute("type", "application/x-mpegurl");
        htmlVideo.appendChild(source);
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.on(Hls.Events.MEDIA_ATTACHED, () => hls.loadSource(url));
        hls.on(Hls.Events.MANIFEST_PARSED, handler);
        hls.attachMedia(htmlVideo);
      }
    } else {
      htmlVideo.addEventListener(event, handler, true);
      htmlVideo.src = url;
    }

    return () => {
      htmlVideo.removeEventListener(event, handler);
    };
  }, [htmlVideo, isStream, url]);

  const positionalAudioRef = useRef<PositionalAudio>();

  useEffect(() => {
    if (!htmlVideo || !canPlay || !autoplay || !sessionStarted) {
      return;
    }

    playHTMLMedia(htmlVideo);

    return () => {
      htmlVideo.pause();
    };
  }, [htmlVideo, canPlay, autoplay, sessionStarted]);

  useEffect(() => {
    if (!htmlVideo || !audioListener || !volume || volume === 0 || !audioAttachTo) {
      return;
    }

    const audio = new PositionalAudio(audioListener);
    positionalAudioRef.current = audio;
    audio.setMediaElementSource(htmlVideo);

    audio.setDistanceModel(distanceModel ?? "inverse");
    audio.setMaxDistance(maxDistance ?? 10000);
    audio.setRefDistance(refDistance ?? 1);
    audio.setRolloffFactor(rolloffFactor ?? 4);
    audio.setVolume(volume);
    audio.panner.panningModel = "equalpower";

    addAudio({ sourceType: audioSourceType, node: audio });

    audioAttachTo.add(audio);

    return () => {
      if (audio) {
        removeAudio(audio);
        audioAttachTo.remove(audio);
      }
    };
  }, [htmlVideo, audioListener, audioAttachTo]);

  useEffect(() => {
    const audio = positionalAudioRef.current;
    if (volume && audio) {
      audio.setDistanceModel(distanceModel ?? "inverse");
      audio.setMaxDistance(maxDistance ?? 10000);
      audio.setRefDistance(refDistance ?? 1);
      audio.setRolloffFactor(rolloffFactor ?? 4);
      audio.setVolume(volume);
    }
  }, [distanceModel, maxDistance, refDistance, rolloffFactor, volume]);

  const texture = useMemo(() => new ThreeVideoTexture(htmlVideo), [htmlVideo]);

  useCommonTextureProperties(texture, flipY, encoding, tiling);

  useMemo(() => {
    textureManager.finishTextureLoad(id, texture);
  }, [texture]);

  useEffect(() => {
    return () => {
      texture.dispose();
    };
  }, []);

  return null;
});
