import { Icon, IconProps } from "./Icon";

export type PersonProps = IconProps;

export const Person = (props: PersonProps) => (
  <Icon width="14px" height="32px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 32" {...props}>
    <path
      fill="currentcolor"
      d="M9.852 2.757c0 1.523-1.212 3.309-2.707 3.309-1.495 0-2.707-1.786-2.707-3.309C4.438 1.234 5.65 0 7.145 0 8.64 0 9.852 1.234 9.852 2.757ZM7 6.985h-.048c-2.282.013-4.014.5-5.058.985-.74.345-1.105 1.076-1.24 1.849L.045 13.28a3.101 3.101 0 0 0 .703 2.547l1.145 1.351a2.85 2.85 0 0 1 .684 1.904l-.245 11.668c-.014.627.396 1.223 1.055 1.247.569.02 1.177-.102 1.533-.567.614-.802 1.341-7.272 1.636-10.487.005-.048.046-.082.097-.082h.298A.1.1 0 0 0 7 20.85a.1.1 0 0 0 .048.012h.298c.05 0 .092.034.097.082.295 3.215 1.022 9.685 1.636 10.487.356.465.964.588 1.533.567.66-.024 1.069-.62 1.056-1.247l-.246-11.668a2.85 2.85 0 0 1 .684-1.904l1.145-1.351a3.1 3.1 0 0 0 .703-2.547l-.607-3.462c-.136-.773-.5-1.504-1.24-1.849-1.045-.485-2.777-.972-5.059-.985H7Z"
    />
  </Icon>
);
