import { ConstraintOptions, ConstraintType } from "~/types/IConstraintComponent";

export const generateConstraint = (type: ConstraintType): ConstraintOptions | null => {
  switch (type) {
    case "fixed":
    case "spring":
    case "lock":
    case "slider":
      return {
        type,
      };
    case "hinge": {
      return {
        type: "hinge",
        pivot: { x: 0, y: 0, z: 0 },
        targetPivot: { x: 0, y: 0, z: 0 },
        axis: { x: 0, y: 0, z: 0 },
        targetAxis: { x: 0, y: 0, z: 0 },
      };
    }
    case "coneTwist": {
      return {
        type: "coneTwist",
        pivot: { x: 0, y: 0, z: 0 },
        targetPivot: { x: 0, y: 0, z: 0 },
      };
    }
    case "pointToPoint": {
      return {
        type: "pointToPoint",
        pivot: { x: 0, y: 0, z: 0 },
        targetPivot: { x: 0, y: 0, z: 0 },
      };
    }
    default:
      return null;
  }
};
