import { Paper } from "@mui/material";
import React, { useState } from "react";
import { JoinScene } from "./JoinScene";
import { Settings } from "./Settings";
import { HeaderWithNavigation } from "~/common/components";
import { HOST } from "~/config";
import { SceneTransitionGuard } from "./SceneTransitionGuard";

export const DefaultWelcomeScreen = () => {
  const [page, setPage] = useState<"default" | "settings">("default");

  const handleOpenSettings = () => setPage("settings");
  const handleCloseSettings = () => setPage("default");

  return (
    <SceneTransitionGuard>
      <Paper
        sx={(theme) => ({
          position: "absolute",
          width: "100vw",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100vh",
          backgroundSize: "cover",
          [theme.breakpoints.down("lg")]: {
            overflow: "auto",
            height: "100vh",
            WebkitOverflowScrolling: "touch",
          },
        })}
      >
        <HeaderWithNavigation enableNavigation={HOST === "8xr"} label="Explore" target="/" />
        {page === "default" && <JoinScene onOpenSettings={handleOpenSettings} />}
        {page === "settings" && <Settings onClose={handleCloseSettings} />}
      </Paper>
    </SceneTransitionGuard>
  );
};
