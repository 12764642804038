import { NodeEvalContext, NodeCategory } from "~/libs/behave-graph";
import { v4 as uuid } from "uuid";
import { NodeSpec } from "~/types/ScriptAsset";
import { flatten } from "lodash-es";
import { BaseBehaveNode } from "./BaseBehaveNode";
import { BaseSocket } from "./BaseSocket";
import { NodeContext, NodeMeta, NodeSocket } from "./types";
import { isBaseSocket } from "./utils";

export abstract class BaseNode {
  static type: string = "Undefined";
  static label: string = "Undefined";
  static category: NodeCategory = "Action";
  static deprecated = false;
  static utility = false;
  static pure = false;

  static isNode = true;

  type: string = "";
  label: string = "";
  category: NodeCategory = "Action";
  deprecated = false;
  utility = false;
  pure = false;

  inputs: NodeSocket[] = [];
  outputs: NodeSocket[] = [];

  constructor(protected context: NodeContext) {
    const constructor = (this as any).__proto__.constructor;

    this.type = constructor.type;
    this.label = constructor.label;
    this.category = constructor.category;
    this.deprecated = constructor.deprecated;
    this.utility = constructor.utility;
    this.pure = constructor.pure;
  }

  protected async = false;
  protected evaluateOnStartup = false;
  protected interruptibleAsync = false;

  abstract eval(context: NodeEvalContext, node: BaseBehaveNode): void;

  produceBehaveNode(nodeSpec: NodeSpec) {
    const node = new BaseBehaveNode(
      this.category,
      nodeSpec.type,
      this.getFlattenInputs(nodeSpec),
      this.getFlattenOutputs(nodeSpec),
      (context) => {
        this.eval(context, node);
      }
    );

    node.id = nodeSpec.id;
    node.label = nodeSpec?.label ?? node.label;
    node.metadata = nodeSpec?.metadata ?? node.metadata;
    node.spec = nodeSpec;
    node.evaluateOnStartup = this.evaluateOnStartup;
    node.interruptibleAsync = this.interruptibleAsync;
    node.async = this.async;
    node.storage = {};

    return node;
  }

  initNode(_node: BaseBehaveNode) {}

  createDefaultNodeSpec(): NodeSpec {
    return {
      type: this.type,
      id: uuid(),
    };
  }

  getInputs(_nodeSpec: NodeSpec): NodeSocket[] {
    return this.inputs.map((input) => input.clone());
  }

  getOutputs(_nodeSpec: NodeSpec): NodeSocket[] {
    return this.outputs.map((output) => output.clone());
  }

  getFlattenInputs(nodeSpec: NodeSpec): BaseSocket[] {
    return flatten(
      this.getInputs(nodeSpec).map((input) => {
        if (isBaseSocket(input)) {
          return input;
        }

        return input.produceSockets(nodeSpec);
      })
    );
  }

  getFlattenOutputs(nodeSpec: NodeSpec): BaseSocket[] {
    return flatten(
      this.getOutputs(nodeSpec).map((output) => {
        if (isBaseSocket(output)) {
          return output;
        }

        return output.produceSockets(nodeSpec);
      })
    );
  }

  getLabel(_nodeSpec: NodeSpec) {
    return this.label;
  }

  getMeta(_nodeSpec: NodeSpec): NodeMeta {
    return emptyMeta;
  }
}

const emptyMeta: NodeMeta = {
  resources: {
    assets: [],
  },
};

export type BaseNodeClass = { new (context: NodeContext): BaseNode } & {
  type: string;
  label: string;
  category: NodeCategory;
  deprecated: boolean;
  utility: boolean;
  isNode: boolean;
};
