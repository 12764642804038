import { isIOS } from "~/common/utils/isMobile";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { FlowSocket } from "../../sockets";

export class CallToActionNode extends BaseNode {
  static readonly type = "playable/callToAction";
  static readonly label = "Call To Action";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow")];

  eval(_: NodeEvalContext, node: BaseBehaveNode) {
    const platform = isIOS() ? "ios" : "android";
    const url = node.sceneContext.getTargetPlatforms().find((p) => p.type === platform)?.storeUrl;

    if (!url) {
      console.error("No url found for platform", platform);
      return;
    }

    if (window.playableSDK) {
      window.playableSDK?.open(url);
    } else {
      console.log("playableSDK not initialized", url);
    }
  }
}
