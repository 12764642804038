import { createEffect, createEvent, createStore } from "effector";
import { lockPointer } from "~/view-scene/PointerLocker";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { IVideo360SceneObject } from "~/types/IVideo360SceneObject";
import { useSceneData } from "~/common/stores/useSceneData";
import { resetRuntime } from "../reset";

export const enterVideo360 = createEffect(async (id: string) => {
  resetVideo360State();

  const sceneState = useSceneData.getState().sceneState;
  if (!id || !sceneState) {
    setCurrentVideo360(null);
    return;
  }
  const video360Entity = sceneState.objects.find((e) => e.id === id) ?? null;
  setCurrentVideo360((video360Entity as IVideo360SceneObject) || null);
});

export const exitVideo360 = createEffect(async () => {
  const isDesktop = useSessionStatus.getState().mode === "desktop";

  if (isDesktop) {
    await lockPointer();
  }

  setCurrentVideo360(null);
});

const setCurrentVideo360 = createEvent<IVideo360SceneObject | null>();

export const $currentVideo360 = createStore<IVideo360SceneObject | null>(null)
  .on(setCurrentVideo360, (_, entity) => entity)
  .reset(resetRuntime);

type Video360State = {
  position: number;
  duration: number;
  play: boolean;
  initialized: boolean;
  video: HTMLVideoElement | null;
};

export const setVideo360State = createEvent<Partial<Video360State>>();
const resetVideo360State = createEvent();

export const $video360State = createStore<Video360State>({
  position: 0,
  duration: -1,
  play: false,
  initialized: false,
  video: null,
})
  .on(setVideo360State, (state, newState) => ({ ...state, ...newState }))
  .reset(resetVideo360State, resetRuntime);
