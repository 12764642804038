import { Stack, styled } from "@mui/material";
import { Container } from "../Container";
import { Logo } from "../Logo";
import { UserMenu } from "../UserMenu";
import { Categories } from "../Categories";

export const MobileHeader = () => {
  return (
    <Stack>
      <Container direction="row" justifyContent="space-between" alignItems="center">
        <SearchDummy />
        <Logo />
        <UserMenu sx={{ marginRight: "10px" }} />
      </Container>
      <Categories sx={{ marginTop: "16px" }} />
    </Stack>
  );
};

const SearchDummy = styled("div")`
  width: 45px;
  height: 45px;
  margin-left: 10px;
`;
