import { useAsset } from "~/entities/assets";
import IImageAsset from "~/types/IImageAsset";
import { hasType } from "~/common/utils/hasType";
import IAsset from "~/types/IAsset";
import { AssetType } from "~/types/AssetType";
import { PreloadImage } from "./PreloadImage";
import { FailedAssetPreload } from "./FailedAssetPreload";
import { PreloadConfig } from "~/types/PreloadConfig";

type PreloadAssetProps = {
  config: PreloadConfig;
};

export function PreloadAsset({ config }: PreloadAssetProps) {
  const asset = useAsset(config.id);

  if (asset && hasType<IAsset, IImageAsset, AssetType>(asset, AssetType.image)) {
    return <PreloadImage asset={asset} config={config} />;
  }

  return <FailedAssetPreload assetId={config.id} />;
}
