import "reflect-metadata";

const propertyLabeKey = "property-label";

export const PropertyLabel = (label: string) => {
  return (target: any, propertyKey: string) => {
    setPropertyLabel(label, target, propertyKey);
  };
};

export const getPropertyLabel = (target: any, propertyKey: string): string | undefined => {
  return Reflect.getMetadata(propertyLabeKey, target, propertyKey);
};

export const setPropertyLabel = (label: string, target: any, propertyKey: string) => {
  Reflect.defineMetadata(propertyLabeKey, label, target, propertyKey);
};
