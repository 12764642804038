import { Color } from "three";
import { memo, Suspense, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useNotificationsState } from "./useNotificationsState";
import { UINotification } from "./types";
import { Block } from "../RenderUIElement/Block";
import { Image } from "../RenderUIElement/Image";
import { Text } from "../RenderUIElement/Text";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { useUnit } from "effector-react";
import { $aspectRatio } from "../models";

export function UINotifications() {
  const [_, aspectY] = useUnit($aspectRatio);

  const getNextNotification = useNotificationsState((state) => state.getNextNotification);

  const notificationOnScreenRef = useRef(false);

  const [activeNotification, setActiveNotification] = useState<UINotification>();

  useFrame(() => {
    if (notificationOnScreenRef.current) {
      return;
    }

    const notification = getNextNotification();
    if (!notification) {
      setActiveNotification(undefined);
      return;
    }

    notificationOnScreenRef.current = true;
    setActiveNotification(notification);

    setTimeout(() => (notificationOnScreenRef.current = false), notification.duration);
  });

  const mode = useSessionStatus((state) => state.mode);
  const notificationOffsetY = mode === "desktop" ? 0.46 : 0.45;

  if (!activeNotification) {
    return null;
  }

  return (
    <group position={[0, notificationOffsetY * aspectY, 0]}>
      {activeNotification && (
        <RenderNotification key={JSON.stringify(activeNotification)} notification={activeNotification} />
      )}
    </group>
  );
}

type RenderNotificationProps = {
  notification: UINotification;
};

const RenderNotification = memo(({ notification }: RenderNotificationProps) => {
  const height = 0.4;
  const widthPerCharacter = 0.075;
  const fontSize = 0.14;
  const iconSize = 0.3;
  const padding = 0.08;

  const numberOfCharacters = notification.text.length ?? 0;
  const width = numberOfCharacters * widthPerCharacter + iconSize;
  const textWidth = width - iconSize - padding * 2;

  return (
    <Suspense fallback={null}>
      <Block
        width={width}
        height={height}
        contentDirection="row"
        justifyContent="center"
        alignItems="center"
        backgroundColor={new Color("#000000")}
        backgroundOpacity={0.8}
        padding={padding}
        borderRadius={0.1}
      >
        <Image src={notification?.icon} width={iconSize} height={iconSize} />
        <Block width={textWidth} height={height} backgroundOpacity={0} justifyContent="center" alignItems="center">
          <Text
            fontFamily="Roboto"
            fontTexture="Roboto"
            fontSize={fontSize}
            fontColor={new Color("white")}
            content={notification.text}
          />
        </Block>
      </Block>
    </Suspense>
  );
});
