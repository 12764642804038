import { ValueType } from "~/libs/behave-graph";
import { defaultComponent } from "~/entities/variable";
import { ComponentValue } from "./componentValue";

export type ActionableValue = ComponentValue;

export const actionableValue = new ValueType(
  "actionable",
  defaultComponent,
  (json: ActionableValue) => json,
  (value: ActionableValue) => value
);
