import { useMemo } from "react";
import { CanvasTexture, Mesh, MeshBasicMaterial, PlaneGeometry } from "three";
import { useTexture } from "~/common/utils/useTexture";
import { Layer } from "~/view-scene/layers";

export type SpriteOptions = {
  width?: number;
  height?: number;
};

const defaultOptions: SpriteOptions = {
  width: 1,
};

export const useSprite = (url: string, options = defaultOptions, layer: Layer) => {
  const texture = useTexture(url) as CanvasTexture;

  return useMemo(() => {
    const { width = 1 } = options;
    const imageAspect = texture.image.height / texture.image.width;
    const height = width * imageAspect;

    const geometry = new PlaneGeometry(width, height);
    const spriteMaterial = new MeshBasicMaterial({ color: 0xffffff, transparent: true, map: texture });
    const sprite = new Mesh(geometry, spriteMaterial);
    sprite.layers.set(layer);

    return sprite;
  }, []);
};
