import { Icon, IconProps } from "./Icon";

export type ChatProps = IconProps;

export const Chat = (props: ChatProps) => (
  <Icon width="20px" height="21px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.5 10.5S7.813 12 10 12c2.188 0 3.5-1.5 3.5-1.5m-.75-5h.01m-5.51 0h.01M5 16v2.335c0 .533 0 .8.11.937a.5.5 0 0 0 .39.188c.176 0 .384-.167.8-.5l2.385-1.908c.488-.39.731-.585 1.002-.724.241-.122.497-.212.762-.267.299-.061.61-.061 1.235-.061H14.2c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C19 13.72 19 12.88 19 11.2V5.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C16.72 1 15.88 1 14.2 1H5.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C1 3.28 1 4.12 1 5.8V12c0 .93 0 1.395.102 1.777a3 3 0 0 0 2.122 2.12C3.605 16 4.07 16 5 16Zm8.25-10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm-5.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </Icon>
);
