import { Socket } from "socket.io-client";
import { PostMessage, SocketMessage } from "./types";

export class Bridge {
  constructor(private socket: Socket, private iframe: HTMLIFrameElement) {
    this.listen();
  }

  dispose() {
    this.unlisten();
  }

  private listen() {
    this.socket.onAny(this.handleSocketEvent);
    window.addEventListener("message", this.handleWindowEvent);
  }

  private unlisten() {
    this.socket.offAny(this.handleSocketEvent);
    window.removeEventListener("message", this.handleWindowEvent);
  }

  private handleSocketEvent = (event: string, message: SocketMessage) => {
    if (event.startsWith("game:")) {
      const postMessage: PostMessage = {
        event: event,
        message: message,
      };
      this.iframe.contentWindow?.postMessage(postMessage, "*");
    }
  };

  private handleWindowEvent = (event: MessageEvent) => {
    const postMessage = event.data as PostMessage;
    const eventName = postMessage.event || "";

    if (eventName.startsWith("game:")) {
      const socketMessage: SocketMessage = postMessage.message;
      this.socket.emit(eventName, socketMessage);
    }
  };
}
