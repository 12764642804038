import { useCallback } from "react";
import { Stack, StackProps } from "@mui/material";
import { SceneObjectType } from "~/types/SceneObjectType";
import { SceneUIEntitySelect, SceneUIEntitySelectProps } from "./SceneUIEntitySelect";
import { EntitySelect, EntitySelectProps } from "./EntitySelect";

export type SceneUIEntitySelectorProps = Pick<StackProps, "className" | "sx"> & {
  name?: string;
  value?: SceneUIEntitySelectorValue;
  onChange?: (event: SceneUIEntitySelectorEvent) => void;
};

type SceneUIEntitySelectorEvent = {
  target: {
    name?: string;
    value: SceneUIEntitySelectorValue;
  };
};

type SceneUIEntitySelectorValue = {
  entityId: string | null;
  uiEntityId: string | null;
};

export const SceneUIEntitySelector = ({ name, value, onChange, ...rest }: SceneUIEntitySelectorProps) => {
  const handleEntityChange: NonNullable<EntitySelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            entityId: e.target.value,
            uiEntityId: null,
          },
        },
      });
    },
    [name, onChange]
  );
  const handleUIEntityChange: NonNullable<SceneUIEntitySelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            entityId: value?.entityId ?? null,
            uiEntityId: e.target.value,
          },
        },
      });
    },
    [name, value, onChange]
  );

  return (
    <Stack {...rest}>
      <EntitySelect
        value={value?.entityId ?? null}
        emptyLabel="Select Entity"
        typeFilter={[SceneObjectType.UI]}
        onChange={handleEntityChange}
      />
      <SceneUIEntitySelect
        value={value?.uiEntityId ?? null}
        entityId={value?.entityId}
        onChange={handleUIEntityChange}
      />
    </Stack>
  );
};
