import { useThree } from "@react-three/fiber";
import { MutableRefObject, ReactNode, Suspense, useEffect } from "react";
import { useProfile } from "~/entities/profile";
import { IPlayerSceneObject } from "~/types/IPlayerSceneObject";
import { useAvatar, useAvatarAnimations, useAvatarUrl } from "~/view-scene/avatar";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { playPlayerAnimation, playPlayerJumpAnimation, stopPlayerAnimation } from "./models";
import { usePlayerAvatarContext } from "./usePlayerAvatarContext";

type RenderPlayerProps = {
  visible: boolean;
  entity: IPlayerSceneObject;
  contextRef: MutableRefObject<PlayerContext>;
  children?: ReactNode;
};

const playerRotation: [number, number, number] = [0, 0, 0];

export function RenderPlayer({ visible, entity, contextRef, children }: RenderPlayerProps) {
  const player = usePlayer();
  const camera = useThree((state) => state.camera);
  contextRef.current.rootObjectRef.current = player;

  const { sex, avatarUrl: userAvatarUrl } = useProfile((state) => ({
    sex: state.profile?.sex ?? "male",
    avatarUrl: state.profile?.avatarUrl,
  }));

  const avatarUrl = useAvatarUrl(userAvatarUrl);
  const { avatarContainer, avatar } = useAvatar(avatarUrl);

  const { controls, events } = useAvatarAnimations(
    avatar,
    sex,
    entity.avatarBehavior === "auto",
    () => contextRef.current?.getPhysicsBody()?.leanerVelocity ?? [0, 0, 0],
    () => {
      playerRotation[0] = camera.rotation.x;
      playerRotation[1] = camera.rotation.y;
      playerRotation[2] = camera.rotation.z;

      return playerRotation;
    }
  );

  const sessionStatus = useSessionStatus((state) => state.sessionStatus);

  useEffect(() => {
    if (sessionStatus === "ready" && avatar && camera) {
      setTimeout(() => {
        avatar.rotation.set(0, camera.rotation.y, 0);
      }, 50);
    }
  }, [sessionStatus]);

  usePlayerAvatarContext(avatar);

  useEffect(() => {
    player.add(avatarContainer);

    const jumpSubscription = playPlayerJumpAnimation.watch(() => controls.jump());
    const playAnimationSubscription = playPlayerAnimation.watch((animationId) => controls.playAnimation(animationId));
    const stopAnimationSubscription = stopPlayerAnimation.watch((animationId) => controls.stopAnimation(animationId));

    const handleAvatarAnimationStopped = (animationId: string) =>
      contextRef.current?.events.emit("avatarAnimationStopped", { animationId });

    events.on("avatarAnimationStopped", ({ animationId }) => handleAvatarAnimationStopped(animationId));

    const handleAvatarAnimationFinished = (animationId: string) =>
      contextRef.current?.events.emit("avatarAnimationFinished", { animationId });

    events.on("avatarAnimationFinished", ({ animationId }) => handleAvatarAnimationFinished(animationId));

    return () => {
      player.remove(avatarContainer);
      jumpSubscription.unsubscribe();
      playAnimationSubscription.unsubscribe();
      stopAnimationSubscription.unsubscribe();
      events.off("avatarAnimationStopped", handleAvatarAnimationStopped);
      events.off("avatarAnimationFinished", handleAvatarAnimationFinished);
    };
  }, []);

  useEffect(() => {
    avatarContainer.visible = visible;
  }, [visible]);

  return (
    <Suspense fallback={null}>
      <primitive object={player}>{children}</primitive>
    </Suspense>
  );
}
