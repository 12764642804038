import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type UIValue = EntityValue;

export const uiValue = new ValueType(
  "ui",
  defaultEntity,
  (text: UIValue) => text,
  (value: UIValue) => value
);
