import { MathUtils } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class RotationNode extends BaseNode {
  static readonly type = "action/rotation";
  static readonly label = "Get rotation";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new Vector3Socket("rotation")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const entity = node.readEntity(context, "entity");
    const rotation = entity?.rootObjectRef.current?.rotation;

    if (rotation) {
      node.storage.output.x = MathUtils.radToDeg(rotation.x);
      node.storage.output.y = MathUtils.radToDeg(rotation.y);
      node.storage.output.z = MathUtils.radToDeg(rotation.z);
    }

    context.writeOutput<Vector3Value>("rotation", node.storage.output);
  }
}
