import { API_HOST } from "~/config";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({ ...contentTypeHeader, ...getAuthHeader() });

export const sendPVPGameResult = async (gameId: string, winner: string, loser: string) => {
  const body = JSON.stringify({ gameId, winner, loser });
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  const response = await fetch(`${API_HOST}/multiplayer/pvpGameResult`, requestOptions);
  const status = response.status;
  const responseBody = await response.json();

  return { status, body: responseBody };
};
