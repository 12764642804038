import { BaseNode } from "../../base";
import { FlowSocket, NumberSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class InvitesAcceptedNode extends BaseNode {
  static readonly type = "hackneyapi/invitesAccepted";
  static readonly label = "Invites Accepted";

  async = true;

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow"), new NumberSocket("accepted"), new FlowSocket("fail")];

  eval(context: NodeEvalContext) {
    async function getAcceptedInvites() {
      const answer = await window.HackneyGamesSDK?.getReferals();
      context.writeOutput<number>("accepted", answer.body.refs.length);
      context.commit("flow");
      context.graphEvaluator.executeAll();
    }

    getAcceptedInvites().catch((error) => {
      console.error("Error during server request :", error);
      context.commit("fail");
      context.graphEvaluator.executeAll();
    });
  }
}
