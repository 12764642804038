import { Box, FormControl, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useProfile } from "~/entities/profile";
import { palette } from "~/theme";
import { IconButton } from "~/hephaestus/components/IconButton";
import Select from "common/components/Select";
import { Button } from "~/hephaestus/components/Button";
import { Close } from "~/common/components/Icon";
import MenuItem from "common/components/MenuItem";
import { SpeakersSetup } from "./SpeakersSetup";
import { MicSetup } from "./MicSetup";
import { SetupAvatar } from "./SetupAvatar";
import { isMobileVR } from "~/common/utils/isMobile";

type SettingsProps = {
  onClose: VoidFunction;
};

export function Settings({ onClose }: SettingsProps) {
  const { profile, updateProfile } = useProfile((state) => ({
    isAuthorised: state.isAuthorised,
    profile: state.profile,
    updateProfile: state.updateProfile,
  }));

  const [selectedMicDevice, setSelectedMicDevice] = useState<string | null | undefined>(profile?.preferredMicDevice);
  const [selectedSpeakerDevice, setSelectedSpeakerDevice] = useState<string | null | undefined>(
    profile?.preferredSpeakerDevice
  );
  const [language, setLanguage] = useState("en");
  const [preferredDpr, setPreferredDpr] = useState(2);

  useEffect(() => {
    if (profile?.language) {
      setLanguage(profile.language);
    }

    if (profile?.preferredDpr) {
      setPreferredDpr(profile.preferredDpr);
    }
  }, [profile]);

  const handleResolutionChange = (event: any) => {
    const preferredDpr = parseInt(event.target.value);
    setPreferredDpr(preferredDpr);
  };

  const handleLanguageChange = (event: any) => {
    const language = event.target.value;
    setLanguage(language);
  };

  const applySettings = () => {
    if (profile) {
      updateProfile({
        ...profile,
        language,
        preferredMicDevice: selectedMicDevice,
        preferredSpeakerDevice: selectedSpeakerDevice,
        preferredDpr: preferredDpr as any,
      });
    }

    onClose();
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={(theme) => ({
          padding: "0 24px",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            padding: "0 16px",
          },
        })}
      >
        <Box
          component="div"
          sx={(theme) => ({
            display: "block",
            padding: "20px  20px 17px 24px",
            border: "1px solid " + palette.primary.black400,
            backgroundColor: palette.primary.black400,
            borderRadius: "20px",
            backdropFilter: "blur(5px)",
            width: "100%",
            maxWidth: "356px",
            [theme.breakpoints.down("sm")]: {
              width: "calc(100% - 44px)",
            },
          })}
        >
          <Stack direction="row" spacing={2} paddingBottom="16px" justifyContent="space-between">
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "20px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.gray600,
              }}
              variant="body1"
            >
              Scene settings
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
          {!isMobileVR() && <SetupAvatar />}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              paddingBottom: "18px",
              marginBottom: "8px",
              borderBottom: "1px solid " + palette.primary.gray200,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.white,
              }}
              variant="body1"
            >
              Content Language
            </Typography>
            <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
              <FormControl fullWidth>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  sx={{
                    maxHeight: "24px",
                    fontSize: "12px",
                    backgroundColor: palette.primary.gray100,
                    border: "1px solid " + palette.primary.gray400,
                  }}
                >
                  <MenuItem value="en" key="en">
                    English
                  </MenuItem>
                  <MenuItem value="ru" key="ru">
                    Russian
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <SpeakersSetup selectedSpeakerDevice={selectedSpeakerDevice} selectSpeakerDevice={setSelectedSpeakerDevice} />
          <MicSetup selectedMicDevice={selectedMicDevice} selectMicDevice={setSelectedMicDevice} />
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "28px",
              width: "100%",
              display: "block",
              textTransform: "uppercase",
              color: palette.primary.gray600,
            }}
            variant="body1"
          >
            Advanced
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "18px",
              paddingBottom: "18px",
              borderBottom: "1px solid " + palette.primary.gray200,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                width: "100%",
                color: palette.primary.white,
              }}
              variant="body1"
            >
              Resolution
            </Typography>
            <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
              <FormControl fullWidth>
                <Select
                  value={"" + preferredDpr}
                  onChange={handleResolutionChange}
                  sx={{
                    maxHeight: "24px",
                    fontSize: "12px",
                    backgroundColor: palette.primary.gray100,
                    border: "1px solid " + palette.primary.gray400,
                  }}
                >
                  <MenuItem value="1" key="medium">
                    Medium
                  </MenuItem>
                  <MenuItem value="2" key="high">
                    High
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
            <Button size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button size="large" variant="accent" onClick={applySettings}>
              Save
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
