import { memo, useEffect, useMemo } from "react";
import { useSceneData } from "~/common/stores/useSceneData";
import { usePhysics } from "~/view-scene/physics";
import { copyTransformation } from "~/view-scene/utils/copyTransformation";
import { useGLTF } from "~/view-scene/utils/useGLTF";
import { RigidBodyComponentProps } from "./types";
import { hasMesh } from "./utils";
import useBVH from "~/view-scene/utils/useBVH";

export type AssetRigidBodyProps = RigidBodyComponentProps;

export const AssetRigidBody = memo(({ componentDto, contextRef }: AssetRigidBodyProps) => {
  const getModelUrl = useSceneData((state) => state.getModelUrl);
  const url = useMemo(() => {
    if (componentDto.body?.type !== "asset") {
      return null;
    }

    return getModelUrl(componentDto.body.assetId, componentDto.body.variantStorageId);
  }, [componentDto, getModelUrl]);

  if (!url) {
    return null;
  }

  return <InitRigidBody url={url} componentDto={componentDto} contextRef={contextRef} />;
});

type InitRigidBodyProps = AssetRigidBodyProps & {
  url: string;
};

const InitRigidBody = ({ url, componentDto, contextRef }: InitRigidBodyProps) => {
  const { addRigidBody, attachObjectToBody, removeRigidBody } = usePhysics((state) => ({
    addRigidBody: state.addRigidBody,
    attachObjectToBody: state.attachObjectToBody,
    removeRigidBody: state.removeRigidBody,
  }));

  const { scene } = useGLTF(url);

  useBVH(scene);

  useEffect(() => {
    if (scene) {
      if (!hasMesh(scene)) {
        return;
      }

      const object = contextRef.current?.rootObjectRef.current;

      if (!object) {
        return;
      }

      copyTransformation(object, scene);
      scene.userData["eightXRId"] = object.userData.eightXRId;
      const physicsBody = addRigidBody(
        scene,
        { entityId: contextRef.current.id, rigidBodyId: componentDto.id },
        {
          type: componentDto.rigidBodyType,
          ghost: componentDto.ghost,
          mass: componentDto.mass,
          activationState: componentDto.activationState,
          collisionGroup: componentDto.collisionGroup,
          collisionMask: componentDto.collisionMask,
        }
      );
      if (physicsBody?.uid) {
        attachObjectToBody(physicsBody.uid, object);
      }
      contextRef.current.physicsBodies[componentDto.id] = physicsBody!;

      return () => {
        if (physicsBody) {
          removeRigidBody(physicsBody.uid);
          if (contextRef.current) {
            delete contextRef.current.physicsBodies[componentDto.id];
          }
        }
      };
    }
  }, [scene]);

  return null;
};
