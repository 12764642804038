import { Socket } from "socket.io-client";
import { useCurrentMedia } from "view-scene/media";
import { useEffect } from "react";
import { ICE_SERVERS } from "~/config";
import { $webRTCConnections, addWebRTCConnection, removeWebRTCConnection, updateWebRTCConnection } from "./models";

export function useWebRTC(socket: Socket | undefined) {
  const { micStream, screenStream } = useCurrentMedia();

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("joinVoiceConversation", async (msg) => {
      const { remoteUserId, shouldCreateOffer } = msg;

      const connection = new RTCPeerConnection({ iceServers: ICE_SERVERS });

      addMediaStreamToPeerConnection(micStream, connection);
      addMediaStreamToPeerConnection(screenStream, connection);

      addWebRTCConnection({
        id: remoteUserId,
        connection,
        streams: [],
      });

      if (shouldCreateOffer) {
        const offer = await connection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        });

        await connection.setLocalDescription(offer);

        socket.emit("relaySessionDescription", {
          peerSocketID: remoteUserId,
          sessionDescription: offer,
        });
      }

      connection.onicecandidate = (event) => {
        const candidate = event.candidate;

        if (!candidate) {
          return;
        }

        socket.emit("relayICECandidate", {
          peerSocketID: remoteUserId,
          iceCandidate: {
            sdpMLineIndex: candidate.sdpMLineIndex,
            candidate: candidate.candidate,
          },
        });
      };

      connection.ontrack = (event) => {
        const webRTCConnection = $webRTCConnections
          .getState()
          .find((webRTCConnection) => webRTCConnection.id === remoteUserId);

        if (!webRTCConnection) {
          return;
        }

        const mediaStream = event.streams[0];

        updateWebRTCConnection({
          id: remoteUserId,
          streams: [...webRTCConnection.streams, mediaStream],
        });

        mediaStream.onremovetrack = () => {
          if (mediaStream.getTracks().length === 0) {
            return;
          }

          const webRTCConnection = $webRTCConnections
            .getState()
            .find((webRTCConnection) => webRTCConnection.id === remoteUserId);

          if (webRTCConnection) {
            updateWebRTCConnection({
              id: remoteUserId,
              streams: webRTCConnection.streams.filter((stream) => stream.id !== mediaStream.id),
            });
          }
        };
      };
    });

    const webRTCConnections = $webRTCConnections.getState();

    for (const webRTCConnection of webRTCConnections) {
      const { id, connection } = webRTCConnection;

      addMediaStreamToPeerConnection(micStream, connection);
      addMediaStreamToPeerConnection(screenStream, connection);

      connection.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true }).then((offer) => {
        connection.setLocalDescription(offer).then(() => {
          socket.emit("relaySessionDescription", { peerSocketID: id, sessionDescription: offer });
        });
      });
    }

    return () => {
      socket.removeListener("joinVoiceConversation");

      const webRTCConnections = $webRTCConnections.getState();

      for (const webRTCConnection of webRTCConnections) {
        try {
          webRTCConnection.connection.getSenders().forEach((sender) => webRTCConnection.connection.removeTrack(sender));
        } catch {
          console.warn("Failed to remove track of WebRTC connection");
        }
      }
    };
  }, [socket, micStream, screenStream]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("sessionDescription", async (msg) => {
      const { peerSocketID, sessionDescription } = msg;

      const webRTCConnection = $webRTCConnections
        .getState()
        .find((webRTCConnection) => webRTCConnection.id === peerSocketID);

      if (!webRTCConnection) {
        return;
      }

      const desc = new RTCSessionDescription(sessionDescription);

      const connection = webRTCConnection.connection;
      if (!connection) {
        console.warn("No peer connection exist while setting sessionDescription.");
        return;
      }

      await connection.setRemoteDescription(desc);

      if (connection.signalingState === "stable") {
        return;
      }

      const remoteSessionDescription = await connection.createAnswer();
      await connection.setLocalDescription(remoteSessionDescription);

      socket.emit("relaySessionDescription", {
        peerSocketID: peerSocketID,
        sessionDescription: remoteSessionDescription,
      });
    });

    socket.on("iceCandidate", async (msg) => {
      const { peerSocketID, iceCandidate } = msg;

      const webRTCConnection = $webRTCConnections
        .getState()
        .find((webRTCConnection) => webRTCConnection.id === peerSocketID);

      if (!webRTCConnection) {
        console.warn("No peer connection exist while setting iceCandidate.");
        return;
      }

      const rtcIceCandidate = new RTCIceCandidate(iceCandidate);
      await webRTCConnection.connection.addIceCandidate(rtcIceCandidate);
    });

    socket.on("leaveVoiceConversation", (msg) => {
      const socketID = msg.socketID;
      removeWebRTCConnection(socketID);
    });

    return () => {
      socket.removeListener("sessionDescription");
      socket.removeListener("iceCandidate");
      socket.removeListener("leaveVoiceConversation");
    };
  }, [socket]);

  const joinVoiceConversation = () => socket?.emit("joinVoiceConversation");

  const leaveVoiceConversation = () => socket?.emit("leaveVoiceConversation");

  return { joinVoiceConversation, leaveVoiceConversation };
}

function addMediaStreamToPeerConnection(mediaStream: MediaStream | null, peerConnection: RTCPeerConnection) {
  mediaStream?.getTracks().forEach((track) => {
    const senders = peerConnection.getSenders();
    if (senders.find((sender) => sender.track && sender.track.id === track.id)) {
      return;
    }

    peerConnection.addTrack(track, mediaStream);
  });
}
