import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { videoValue, VideoValue } from "../values";

export class VideoSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: VideoValue = defaultEntity()) {
    super(videoValue.name, id, label, value);
  }

  clone() {
    return new VideoSocket(this.id, this.label, this.value);
  }
}
