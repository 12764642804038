import { NodeEvalContext } from "behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { StringSocket } from "../../sockets";

type NodeType = BaseBehaveNode<{ urlParams: URLSearchParams }>;

export class UrlParameterNode extends BaseNode {
  static readonly type = "url/parameter";
  static readonly label = "Get URL Parameter";

  inputs = [new StringSocket("name")];
  outputs = [new StringSocket("value")];

  initNode(node: NodeType) {
    node.storage.urlParams = new URLSearchParams(window.location.search);
  }

  eval(context: NodeEvalContext, node: NodeType) {
    const name = context.readInput<string>("name");
    const value = node.storage.urlParams.get(name);

    context.writeOutput<string>("value", value ?? "");
  }
}
