import { useStoreMap } from "effector-react";
import { useMemo } from "react";
import { $allScriptsMap } from "~/entities/allScripts";
import { Select, SelectItem, SelectProps } from "~/hephaestus/components";

export type ScriptEventSelectProps = Omit<SelectProps<string>, "children" | "value"> & {
  scriptId: string | undefined;
  value: string | undefined;
  onlyPublic?: boolean;
};

export const ScriptEventSelect = ({ scriptId, value, onlyPublic = false, ...rest }: ScriptEventSelectProps) => {
  const scriptAsset = useStoreMap({
    store: $allScriptsMap,
    keys: [scriptId],
    fn: (scriptsMap, [scriptId]) => scriptsMap?.[scriptId ?? ""] ?? null,
  });

  const events = useMemo(() => {
    return (scriptAsset?.schema.events ?? []).filter((event) => !onlyPublic || event.public);
  }, [onlyPublic, scriptAsset?.schema.events]);

  return (
    <Select displayEmpty value={value ?? ""} {...rest}>
      <SelectItem key="null" value="">
        Select event
      </SelectItem>
      {events.map((event) => (
        <SelectItem key={event.id} value={event.id}>
          {event.name}
        </SelectItem>
      ))}
    </Select>
  );
};
