export type UIEntityIdContext = "scene" | "overlay";

export type SceneUIEntityId = {
  type: "scene";
  entityId: string;
  uiEntityId: string;
};

export type OverlayUIEntityId = {
  type: "overlay";
  overlayId: string;
  uiEntityId: string;
};

export type UIEntityId = SceneUIEntityId | OverlayUIEntityId;

export const encodeUIEntityId = (id: UIEntityId) => {
  switch (id.type) {
    case "scene":
      return `scene:${id.entityId}:${id.uiEntityId}`;
    case "overlay":
      return `overlay:${id.overlayId}:${id.uiEntityId}`;
  }
};

export const decodeUIEntityId = (id: string): UIEntityId => {
  const [type, contextId, uiEntityId] = id.split(":") as [UIEntityIdContext, string, string];

  switch (type) {
    case "scene":
      return { type, entityId: contextId, uiEntityId };
    case "overlay":
      return { type, overlayId: contextId, uiEntityId };
  }
};
