import { useStoreMap } from "effector-react";
import { useContext, useEffect } from "react";
import { INetworkSystemCtx, NetworkSystemCtx } from "view-scene/network";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import { useProfile } from "~/entities/profile";
import { IPlayerSceneObject } from "~/types/IPlayerSceneObject";
import { $cameraState, setTarget } from "~/view-scene/CameraSystem";
import { useDIContext } from "~/view-scene/diContext";
import { usePlayer } from "~/view-scene/player";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { BroadcastPlayerData } from "./BroadcastPlayerData";
import { RenderPlayer } from "./RenderPlayer";
import { useAddPlayerPhysics } from "./useAddPlayerPhysics";
import { useHandlePlayerMovements } from "./useHandlePlayerMovements";
import { usePlayerContext } from "./usePlayerContext";
import { useSockets } from "./useSockets";
import { useSpawnPosition } from "./useSpawnPosition";

export type PlayerProps = {
  entity: IPlayerSceneObject;
};

export const Player = ({ entity }: PlayerProps) => {
  const { toSceneEntity } = useDIContext();
  const networkSystem = useContext(NetworkSystemCtx) as INetworkSystemCtx;
  const isAuthorised = useProfile((state) => state.isAuthorised);
  const player = usePlayer();
  const cameraMode = useStoreMap($cameraState, (state) => state.cameraMode);
  const mode = useSessionStatus((state) => state.mode);

  const multiplayerEnabled = useSceneData(
    (state) => state.sceneState?.networkSystem?.enableMultiplayer ?? false,
    shallow
  );

  const { contextRef } = usePlayerContext(entity);

  useAddPlayerPhysics(contextRef);

  useHandlePlayerMovements(contextRef);

  useSpawnPosition(contextRef);

  useSockets();

  useEffect(() => {
    setTarget(player);
  }, [player, entity.enabled]);

  const readyForMultiplayer = networkSystem?.isConnected && isAuthorised() && multiplayerEnabled;

  return (
    <>
      {mode !== "vr" && (
        <RenderPlayer entity={entity} visible={cameraMode === "thirdPerson"} contextRef={contextRef}>
          {entity.children?.map((childEntity) => toSceneEntity(childEntity))}
        </RenderPlayer>
      )}
      {readyForMultiplayer && <BroadcastPlayerData contextRef={contextRef} />}
    </>
  );
};
