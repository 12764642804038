import { useFrame, useThree } from "@react-three/fiber";
import { memo, useEffect, useRef } from "react";
import { ControlsSystemFollow } from "~/types/ControlsSystem";
import { userActions } from "~/view-scene/ControlsSystem";
import { PlayerContext, useEntityManager } from "~/view-scene/runtime";
import { CameraThirdPerson } from "./CameraThirdPerson";

export type CameraControlsFollowProps = {
  controlsSystem: ControlsSystemFollow;
};

export const CameraControlsFollow = memo(({ controlsSystem }: CameraControlsFollowProps) => {
  const { camera } = useThree();
  const { waitForEntityContext } = useEntityManager();
  const controllerRef = useRef<CameraThirdPerson>();

  useEffect(() => {
    let controller: any;

    waitForEntityContext<PlayerContext>("player").then((playerContext) => {
      const initThirdPersonCamera = () => {
        const controller = new CameraThirdPerson();

        controller.config.distance = controlsSystem.distance;
        controller.config.height = controlsSystem.height;
        controller.config.follow_speed = controlsSystem.followSpeed;
        controller.config.rotation_passive_speed = controlsSystem.rotationPassiveSpeed;
        controller.config.rotation_active_speed = controlsSystem.rotationActiveSpeed;
        controller.config.camera_speed = controlsSystem.cameraSpeed;
        controller.config.stick_passive_factor = controlsSystem.stickPassiveFactor;
        controller.config.stick_active_factor = controlsSystem.stickActiveFactor;
        controller.config.attach_to_pawn = controlsSystem.attachToPawn;

        controller.set_target(playerContext.current!.rootObjectRef.current!);
        controller.set_camera(camera);
        controller.init_camera_position();

        controllerRef.current = controller;
      };

      if (playerContext.current?.positionInitialized) {
        initThirdPersonCamera();
      } else {
        playerContext.current?.events.once("positionInitialized", initThirdPersonCamera);
      }
    });

    return () => {
      controller && controller.cleanup();
    };
  }, []);

  useFrame((_, delta) => {
    if (controllerRef.current) {
      controllerRef.current.direction.set(userActions.move.side, userActions.move.forward);
      controllerRef.current.step(delta);
    }
  });

  return null;
});
