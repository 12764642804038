import { Controllers, Hands, useController, XRController } from "@react-three/xr";
import { useFrame } from "@react-three/fiber";
import { memo, ReactNode, useCallback, useMemo } from "react";
import { Action, userActions } from "~/view-scene/ControlsSystem";
import { LeftButton, RightButton } from "./constants";
import getControllerJoystickState from "./getControllerJoystickState";
import { useUnit } from "effector-react";
import { $sceneMode } from "~/view-scene/runtime";
import { $customButtons } from "~/view-scene/customButtons";
import { SceneInterface } from "./SceneInterface";
import { Video360Interface } from "./Video360Interface";
import { useInitControllers } from "./useInitControllers";
import { CutsceneInterface } from "./CutsceneInterface";
import { VRCustomButtons } from "~/types/InputSystem";

type ButtonsMap = Record<number, (active: boolean) => void>;

export const VRSource = memo(() => {
  const leftController = useController("left");
  const rightController = useController("right");
  const sceneMode = useUnit($sceneMode);

  useInitControllers();

  const { left, right } = useCustomButtonsMap();

  const updateLeft = useButtonsState(leftController, {
    ...left,
    [LeftButton.yButton]: stateChangeHandler(userActions.jump),
  });
  const updateRight = useButtonsState(rightController, {
    ...right,
    [RightButton.bButton]: stateChangeHandler(userActions.talk),
    [RightButton.trigger]: stateChangeHandler(userActions.action),
    [RightButton.grip]: stateChangeHandler(userActions.console),
  });

  useFrame(() => {
    if (!leftController || !rightController) {
      return;
    }

    updateLeft();
    updateRight();

    const leftControllerJoystickState = getControllerJoystickState(leftController);
    const rightControllerJoystickState = getControllerJoystickState(rightController);

    const side = Math.min(leftControllerJoystickState.side + rightControllerJoystickState.side, 1);
    const forward = Math.min(leftControllerJoystickState.forward + rightControllerJoystickState.forward, 1);
    userActions.setMove({ forward, side });
  });

  let sourceInterface: ReactNode;

  switch (sceneMode) {
    case "cutscene":
      sourceInterface = <CutsceneInterface />;
      break;
    case "video360":
      sourceInterface = <Video360Interface />;
      break;
    case "scene":
    default:
      sourceInterface = <SceneInterface />;
      break;
  }

  return (
    <>
      <Controllers />
      <Hands />
      {sourceInterface}
    </>
  );
});

const useButtonsState = (controller: XRController | undefined, buttonsMap: ButtonsMap) => {
  const { indexes, prevStates } = useMemo(() => {
    const indexes = Object.keys(buttonsMap).map((key) => Number(key));
    const prevStates = indexes.reduce((acc, index) => {
      acc[index] = false;

      return acc;
    }, {} as Record<number, boolean>);

    return {
      indexes,
      prevStates,
    };
  }, []);

  return useCallback(() => {
    if (!controller) {
      return;
    }

    for (const index of indexes) {
      const button = controller.inputSource.gamepad.buttons[index];

      if (button) {
        const currState = button.value === 1;

        if (currState !== prevStates[index]) {
          buttonsMap[index](currState);
        }

        prevStates[index] = currState;
      }
    }
  }, [controller]);
};

const useCustomButtonsMap = () => {
  const customButtons = useUnit($customButtons);

  return useMemo(() => {
    const left: ButtonsMap = {};
    const right: ButtonsMap = {};

    customButtons?.forEach((button) => {
      if (!button.vrButton) {
        return;
      }

      const { controller, index } = customButtonsMap[button.vrButton as VRCustomButtons];

      if (controller === "left") {
        left[index] = stateChangeHandler(userActions.customAction, button.id);
      } else {
        right[index] = stateChangeHandler(userActions.customAction, button.id);
      }
    });

    return {
      left,
      right,
    };
  }, [customButtons]);
};

const customButtonsMap: Record<
  VRCustomButtons,
  { controller: "left"; index: LeftButton } | { controller: "right"; index: RightButton }
> = {
  a: { controller: "right", index: RightButton.aButton },
  rightStick: { controller: "right", index: RightButton.stickButton },
  x: { controller: "left", index: LeftButton.xButton },
  leftStick: { controller: "left", index: LeftButton.stickButton },
};

const stateChangeHandler =
  <TValue extends any = any>(action: Action<TValue>, payload?: TValue) =>
  (state: boolean) => {
    if (state) {
      action.emit("start", payload);
    } else {
      action.emit("end", payload);
    }
  };
