type Hierarchical = {
  id: string;
  order: number;
  parentId?: string | null;
  children?: Hierarchical[];
  removed?: boolean;
};

export const toHierarchy = <T extends Hierarchical>(objects: T[]) => {
  const sorted = objects.filter((obj) => !obj.removed).sort((a, b) => a.order - b.order);

  const map = new Map<string, number>();
  let node: T;
  const roots: T[] = [];

  for (let i = 0; i < sorted.length; i++) {
    const object = sorted[i];
    map.set(object.id, i);
    object.children = [];
  }

  for (let i = 0; i < sorted.length; i++) {
    node = sorted[i];
    if (node.parentId != null) {
      const parentIndex = map.get(node.parentId);
      if (parentIndex != null) {
        sorted[parentIndex]?.children?.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};
