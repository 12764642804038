import IPointLight from "~/types/IPointLight";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { Color, PointLight, PointLightHelper } from "three";
import { RenderLightHelper } from "./RenderLightHelper";

export type RenderPointLightProps = {
  light: IPointLight;
  drawHelper: boolean;
};

export default function RenderPointLight({ light, drawHelper }: RenderPointLightProps) {
  const { color, intensity, castShadow, distance, decay, shadowBias, normalBias, radius, resolution } = light;

  const pointLightRef = useRef<PointLight>() as MutableRefObject<PointLight>;

  const pointLight = useMemo(() => {
    const light = new PointLight(color, intensity, distance, decay);
    light.shadow.mapSize.width = resolution * 1024;
    light.shadow.mapSize.height = resolution * 1024;
    return light;
  }, [resolution]);

  useEffect(() => {
    pointLight.color = new Color(color);
    pointLight.intensity = intensity;
    pointLight.castShadow = castShadow;
    pointLight.distance = distance;
    pointLight.decay = decay;
    pointLight.shadow.bias = shadowBias;
    pointLight.shadow.normalBias = normalBias;
    pointLight.shadow.radius = radius;
  }, [pointLight, color, intensity, distance, decay, castShadow, shadowBias, normalBias, radius]);

  return (
    <>
      <primitive object={pointLight} ref={pointLightRef} />
      {drawHelper && <RenderLightHelper lightRef={pointLightRef} helperConstructor={PointLightHelper} />}
    </>
  );
}
