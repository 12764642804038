import { Box3, Matrix4, Object3D, Vector3 } from "three";

export const getBoundingBoxMatrix = (mesh: Object3D) => {
  const box = new Box3().setFromObject(mesh);
  const dimensions = new Vector3().subVectors(box.max, box.min);
  const matrix = new Matrix4();
  matrix.makeScale(dimensions.x, dimensions.y, dimensions.z);
  const position = dimensions.addVectors(box.min, box.max).multiplyScalar(0.5);
  matrix.setPosition(position);

  return { matrix, position };
};
