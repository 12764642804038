import { attach } from "effector";
import { $socket } from "./socket";

export type SendMessagePayload = {
  event: string;
  payload: any;
};

export const sendMessageFx = attach({
  source: $socket,
  effect: (socket, payload: SendMessagePayload) => {
    if (!socket) {
      return;
    }

    socket.emit(payload.event, payload.payload);
  },
});
