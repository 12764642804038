import { memo, ReactNode } from "react";
import { Entity } from "../Entity";
import useSessionStatus from "view-scene/stores/useSessionStatus";
import { RenderMirror } from "./RenderMirror";
import IMirrorSceneObject from "~/types/IMirrorSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";

export type MirrorEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const MirrorEntity = memo(({ entityId, children }: MirrorEntityProps) => {
  const entity = useEntity<IMirrorSceneObject>(entityId);
  const mode = useSessionStatus((state) => state.mode);

  // TODO I'm not sure MeshReflectorMaterial is supported in VR
  if (mode === "vr") {
    return <Entity entityId={entityId}>{children}</Entity>;
  }

  const resolution = mode === "desktop" ? 1024 * 2 : 1024;

  return (
    <Entity entityId={entityId}>
      <RenderMirror dto={entity} resolution={resolution} />
      {children}
    </Entity>
  );
});
