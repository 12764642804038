import { Icon, IconProps } from "./Icon";

export type LineHeightProps = IconProps;

export const LineHeight = (props: LineHeightProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.5 8.833h5m-6.667 3.334 3.56-7.832c.193-.424.29-.636.423-.702a.417.417 0 0 1 .368 0c.134.066.23.278.423.702l3.56 7.832M15.5 15.5H.5m15-15H.5"
    />
  </Icon>
);
