import { useFrame } from "@react-three/fiber";
import { MutableRefObject, useContext } from "react";
import { NetworkSystemCtx } from "~/view-scene/network";
import { usePlayer } from "~/view-scene/player";
import { PlayerContext } from "~/view-scene/runtime";
import { updateAvatarPositionMessage } from "./constants";
import { useBroadcastAnimations } from "./useBroadcastAnimations";
import { applyBaseData } from "./utils";

export type BroadcastCommonProps = {
  contextRef: MutableRefObject<PlayerContext | null>;
};

export const BroadcastCommon = ({ contextRef }: BroadcastCommonProps) => {
  const { send } = useContext(NetworkSystemCtx)!;

  const player = usePlayer();

  useBroadcastAnimations();

  useFrame((state) => {
    const { camera } = state;

    applyBaseData(updateAvatarPositionMessage, player, contextRef.current, camera);

    send("updateAvatarPosition", updateAvatarPositionMessage);
  });

  return null;
};
