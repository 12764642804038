import { styled } from "@mui/material";
import { palette } from "~/theme";
import { Discord as DiscordIcon, DiscordProps as DiscordIconProps } from "./Icon";
import { Navigate } from "./Navigate";

export type DiscordProps = Pick<DiscordIconProps, "className" | "sx">;

export const Discord = (props: DiscordIconProps) => {
  return (
    <Navigate to="https://discord.gg/Dfp3u9e2WN" openInNewWindow={true}>
      <StyledDiscordIcon {...props} />
    </Navigate>
  );
};

const StyledDiscordIcon = styled(DiscordIcon)`
  width: 48px;
  height: 48px;
  transition: 0.4s ease;
  color: ${palette.primary.white};

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
  }
`;
