import { Icon, IconProps } from "./Icon";

export type CheckCircleProps = IconProps;

export const CheckCircle = (props: CheckCircleProps) => (
  <Icon width="16px" height="16px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#a)">
      <path
        d="m5 8 2 2 4-4m3.667 2A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0Z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentcolor" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </Icon>
);
