import { createEffect } from "effector";
import { uploadAsset } from "~/api/assets.api";
import { AssetType } from "~/types/AssetType";
import IAsset from "~/types/IAsset";

export type UploadAssetRequestPayload = { name: string; type: AssetType; file: File; workspaceId: string };

export const uploadAssetFx = createEffect(async ({ name, type, file, workspaceId }: UploadAssetRequestPayload) => {
  const { body } = await uploadAsset(name, type, file, workspaceId);

  return body as IAsset;
});
