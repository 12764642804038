import { memo, RefObject, useEffect, useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { ComponentType } from "~/types/ComponentType";
import { AnimationComponentContext, EntityContext, useComponentContext } from "~/view-scene/runtime";
import { AnimationComponentEvents } from "../AnimationComponentEvents";
import { SpriteAnimationComponent as SpriteAnimationComponentDescriptor } from "~/types/component";
import { Group, Object3D } from "three";
import { AnimatedSprite, SpriteAnimation } from "~/view-scene/libs/AnimatedSprite";
import { useUpdateEffect } from "react-use";

type RenderSpriteAnimationProps = {
  component: SpriteAnimationComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef?: RefObject<EntityContext>;
};

export const RenderSpriteAnimation = memo(({ component, objectRef, contextRef }: RenderSpriteAnimationProps) => {
  const { animationName, frameStart, frameEnd, frameDuration, autoplay, loop } = component;

  const events = useMemo(() => new AnimationComponentEvents(), []);

  const playRef = useRef(autoplay);
  const spriteAnimationRef = useRef<SpriteAnimation>();

  function prepareSpriteAnimation() {
    const animatedSprite = findAnimatedSprite(objectRef.current);

    if (!animatedSprite || frameDuration === 0) {
      return;
    }

    spriteAnimationRef.current = new SpriteAnimation(animatedSprite, frameStart, frameEnd, frameDuration);
    if (autoplay) {
      if (loop) {
        spriteAnimationRef.current.playLoop();
      } else {
        spriteAnimationRef.current.playOnce();
      }
    }

    spriteAnimationRef.current.addEventListener("finished", () => {
      if (!loop) {
        events.emit("finish");
        playRef.current = false;
      }
    });
  }

  useEffect(() => {
    const animatedSprite = findAnimatedSprite(objectRef.current);

    if (animatedSprite) {
      prepareSpriteAnimation();
    } else {
      contextRef?.current?.events.once("entityReady", () => {
        prepareSpriteAnimation();
      });
    }
  }, []);

  useUpdateEffect(() => {
    prepareSpriteAnimation();
  }, [frameStart, frameEnd, frameDuration]);

  const context: AnimationComponentContext = {
    id: component.id,
    type: ComponentType.ANIMATION,
    events,
    get duration() {
      return (frameEnd - frameStart) * frameDuration;
    },
    get time() {
      return -1;
    },
    set time(value: number) {},
    name: animationName ?? "__UNKNOWN__NAME__",
    play() {
      if (!playRef.current) {
        if (loop) {
          spriteAnimationRef.current?.playLoop();
        } else {
          spriteAnimationRef.current?.playOnce();
        }
      }

      playRef.current = true;
    },
    pause() {
      playRef.current = false;
      spriteAnimationRef.current?.pause();
    },
    stop() {
      playRef.current = false;
      spriteAnimationRef.current?.stop();
    },
    setLoop(flag) {
      spriteAnimationRef.current?.setLoop(flag);
    },
    reset() {
      spriteAnimationRef.current?.stop();
    },
  };

  useComponentContext(contextRef, component.id, () => context, []);

  useFrame((_, delta) => {
    if (!spriteAnimationRef.current || !playRef.current) {
      return;
    }
    spriteAnimationRef.current.update(delta);
  });

  return null;
});

const findAnimatedSprite = (object?: Object3D | null): AnimatedSprite | null => {
  let animatedSprite: AnimatedSprite | undefined = undefined;
  object?.traverse((o) => {
    if (!animatedSprite && o instanceof AnimatedSprite) {
      animatedSprite = o;
    }
  });

  return animatedSprite ?? null;
};
