import { EntityContext } from "~/view-scene/runtime";
import { Vector3Value } from "../../values";

export const setPosition = (entity: EntityContext, position: Vector3Value) => {
  const physicsBody = entity.getPhysicsBody();
  const vehicle = entity.vehicle;

  if (vehicle) {
    vehicle.position.set(position.x, position.y, position.z);
  }

  if (!physicsBody) {
    entity.rootObjectRef.current?.position.set(position.x, position.y, position.z);

    return;
  }

  switch (physicsBody.rigidBodyType) {
    case "kinematic": {
      entity.rootObjectRef.current?.position.set(position.x, position.y, position.z);
      break;
    }
    case "dynamic": {
      physicsBody.moveTo(position.x, position.y, position.z);
      break;
    }
    case "static": {
      console.error(new Error("You can't set position of the entity with Static rigid body"));
      break;
    }
  }
};
