import { FormControlLabel, Stack } from "@mui/material";
import { Checkbox } from "../Checkbox";
import IRotate from "~/types/IRotate";
import { ChangeEvent } from "react";
import { CheckboxLabel } from "./CheckboxLabel";

export type BooleanVectorFieldProps = {
  name: string;
  value: IRotate;
  onChange: (e: OnBooleanVectorFieldChangeEvent) => void;
};

export type OnBooleanVectorFieldChangeEvent = {
  target: {
    name: string;
    value: IRotate;
  };
};

export function BooleanVectorField({ name, value, onChange }: BooleanVectorFieldProps) {
  const { x, y, z } = value;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        name: name,
        value: {
          ...value,
          [e.target.name]: e.target.checked,
        },
      },
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        gridGap: "12px",
      }}
    >
      <FormControlLabel
        control={<Checkbox name="x" checked={x} onChange={handleChange} sx={{ marginRight: "8px" }} />}
        label={<CheckboxLabel label="x" />}
      />
      <FormControlLabel
        control={<Checkbox name="y" checked={y} onChange={handleChange} sx={{ marginRight: "8px" }} />}
        label={<CheckboxLabel label="y" />}
      />
      <FormControlLabel
        control={<Checkbox name="z" checked={z} onChange={handleChange} sx={{ marginRight: "8px" }} />}
        label={<CheckboxLabel label="z" />}
      />
    </Stack>
  );
}
