import { Stack } from "@mui/material";
import { ComponentType, useCallback } from "react";
import { Close } from "~/common/components/Icon";
import { Button } from "./Button";
import { IconButton } from "./IconButton";

type WithAutoFieldProps<TValue> = {
  value: TValue;
  name: string;

  onChange: (e: ChangeEvent<TValue>) => void;
};

export type WithAutoFieldAddProps<TValue> = {
  value: TValue | null | undefined;
  nullValue?: TValue | null | undefined;
  onChange?: (e: ChangeEvent<TValue | null | undefined>) => void;
};

type WithAutoFieldOptions<TValue> = {
  defaultValue: TValue;
};

type ChangeEvent<TValue> = {
  target: {
    name: string;
    value: TValue;
  };
};

export const withAutoField =
  <TValue extends any>({ defaultValue }: WithAutoFieldOptions<TValue>) =>
  <TProps extends WithAutoFieldProps<TValue>>(Component: ComponentType<TProps>) => {
    return ({
      value,
      name,
      nullValue,
      onChange,
      ...restProps
    }: Omit<TProps, "value" | "onChange"> & WithAutoFieldAddProps<TValue>) => {
      const handleAutoClick = useCallback(() => {
        onChange?.({
          target: {
            name,
            value: defaultValue,
          },
        });
      }, [onChange, name]);

      const handleClearClick = useCallback(() => {
        onChange?.({
          target: {
            name,
            value: nullValue,
          },
        });
      }, [onChange, nullValue, name]);

      return (
        <Stack direction="row">
          {value === nullValue ? (
            <Button variant="contained" onClick={handleAutoClick}>
              Auto
            </Button>
          ) : (
            <>
              <Component {...(restProps as TProps)} value={value} name={name} onChange={onChange} />
              <IconButton onClick={handleClearClick}>
                <Close size="small" />
              </IconButton>
            </>
          )}
        </Stack>
      );
    };
  };
