import { styled } from "@mui/material";

const closeButtonImage = "/static/img/content/hyper/closeButton.png";
export type ButtonAppearance = "default";

export type CloseButtonProps = {
  onClick?: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  return (
    <Container {...props}>
      <Img src={closeButtonImage} />
    </Container>
  );
};

const Img = styled("img")`
  width: 40px;
  height: 40px;
`;

const Container = styled("div")``;
