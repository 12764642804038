import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { diveIn } from "./models";

export function VRDiveIn() {
  const updateStatus = useSessionStatus((state) => state.updateStatus);
  const gl = useThree((state) => state.gl);

  useEffect(() => {
    const subscription = diveIn.watch(async () => {
      const optionalFeatures = ["local-floor", "bounded-floor", "hand-tracking"];
      const session = await (navigator as any)["xr"].requestSession("immersive-vr", { optionalFeatures });
      await gl.xr.setSession(session);

      session.addEventListener("end", () => updateStatus("onpause"), { once: true });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
}
