import { useTexture } from "common/utils/useTexture";
import { CanvasTexture, PlaneGeometry, RepeatWrapping, Vector3 } from "three";
import { useMemo } from "react";
import { Water as WaterImpl } from "three/examples/jsm/objects/Water";
import { useFrame } from "@react-three/fiber";
import IWaterSceneObject from "types/IWaterSceneObject";
import { useToggleMatrixAutoUpdate } from "~/view-scene/utils";

export type RenderWaterProps = {
  dto: IWaterSceneObject;
};

export function RenderWater({ dto }: RenderWaterProps) {
  const { width, height, sunColor, waterColor, distortionScale, isStatic } = dto;

  const texture = useTexture("/static/images/water.jpeg") as CanvasTexture;
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;

  const waterGeometry = useMemo(() => new PlaneGeometry(width, height), [width, height]);

  const water = useMemo<WaterImpl | null>(() => {
    if (!texture) {
      return null;
    }

    return new WaterImpl(waterGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: texture,
      sunDirection: new Vector3(),
      sunColor,
      waterColor,
      distortionScale,
    });
  }, [texture, waterGeometry, sunColor, waterColor, distortionScale]);

  useFrame((_, deltaTime) => {
    if (!water) {
      return;
    }

    const material = water.material;
    material.uniforms["time"].value += (deltaTime * 8) / 60.0;
  });

  useToggleMatrixAutoUpdate(water, isStatic);

  return water && <primitive object={water} />;
}
