import { useNavigate } from "react-router-dom";
import { Button, ListItemText, MenuList, Stack } from "@mui/material";
import * as sceneAPI from "~/api/scene.api";
import { Plus } from "~/common/components/Icon";
import { Menu } from "~/hephaestus/components/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ChangeEvent, useRef, useState } from "react";
import { palette } from "~/theme";
import { $selectedWorkspace } from "../models";
import { generateDefaultScene } from "~/api/generateDefaultScene";
import { TemplatesModal } from "./TemplatesModal";
import { useUnit } from "effector-react";
import { openTemplatesModal } from "./models";
import { importScene } from "~/api/import.api";
import { Loader } from "~/hephaestus/components";

const menuItemStyles = {
  padding: "12px",
  borderRadius: "8px",
};

const menuItemTextStyles = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
};

export const AddScene = () => {
  const selectedSpace = useUnit($selectedWorkspace);

  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);
  const navigate = useNavigate();

  const handleAddSceneFromScratch = async () => {
    const payload = generateDefaultScene(selectedSpace?._id);
    payload.workspace = selectedSpace?._id;
    const scene = await sceneAPI.createSceneFromScratch(payload);
    navigate(`/builder/${scene._id}`);
  };

  const handleAddSceneFromTemplate = () => {
    openTemplatesModal();
    handleCloseMenu();
  };

  const [importInProgress, setImportInProgress] = useState(false);
  const importSceneFileRef = useRef<HTMLInputElement>(null);
  const handleImportSceneClick = () => {
    importSceneFileRef.current?.click();
    handleCloseMenu();
  };
  const handleImportFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const workspaceId = selectedSpace?._id;
    if (!workspaceId) {
      return;
    }

    const file = event.target.files?.[0];
    if (file) {
      setImportInProgress(true);

      importScene(file, workspaceId).then(() => {
        if (importSceneFileRef.current) {
          importSceneFileRef.current.files = null;
        }
        setImportInProgress(false);
      });
    }
  };

  return (
    <>
      <Menu
        anchorEl={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOpen)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            marginTop: "8px",
            minWidth: "134px",
            maxWidth: "134px",
          },
        }}
      >
        <MenuList sx={{ padding: 0 }}>
          <MenuItem sx={menuItemStyles} onClick={handleAddSceneFromTemplate}>
            <ListItemText sx={menuItemTextStyles} primaryTypographyProps={{ sx: { color: palette.primary.gray600 } }}>
              From Template
            </ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyles} onClick={handleAddSceneFromScratch}>
            <ListItemText sx={menuItemTextStyles} primaryTypographyProps={{ sx: { color: palette.primary.gray600 } }}>
              From Scratch
            </ListItemText>
          </MenuItem>

          <MenuItem sx={menuItemStyles} onClick={handleImportSceneClick}>
            <ListItemText sx={menuItemTextStyles} primaryTypographyProps={{ sx: { color: palette.primary.gray600 } }}>
              Import File
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <input type="file" style={{ display: "none" }} ref={importSceneFileRef} onChange={handleImportFileSelected} />
      <TemplatesModal />
      {importInProgress ? (
        <Stack width="73px" alignItems="center" justifyContent="center">
          <Loader size={22} />
        </Stack>
      ) : (
        <Button onClick={handleOpenMenu} variant="contained" startIcon={<Plus size="small" />}>
          Add scene
        </Button>
      )}
    </>
  );
};
