import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { FlowSocket, PlayerSocket } from "../../sockets";
import { BaseBehaveNode, BaseNode } from "../../base";
import { PlayerValue } from "../../values";

export class PlayerCollisionEndNode extends BaseNode {
  static readonly type = "event/playerCollisionEnd";
  static readonly label = "Player Collision End";
  static readonly category: NodeCategory = "Event";

  async = true;
  evaluateOnStartup = true;
  interruptibleAsync = true;

  outputs = [new FlowSocket("flow"), new PlayerSocket("player")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultEntity();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const onCollisionEndEvent = (entityId: string) => {
      const entity = node.sceneContext.getEntityContext(entityId);

      if (entity?.type === "player") {
        node.storage.output.entityId = entityId;
        context.writeOutput<PlayerValue>("player", node.storage.output);
        context.commit("flow");
      }
    };

    node.scriptContext.scriptEvents.collisionEnd.addListener(onCollisionEndEvent);

    context.onAsyncCancelled.addListener(() => {
      node.scriptContext.scriptEvents.collisionEnd.removeListener(onCollisionEndEvent);
    });
  }
}
