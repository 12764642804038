import { memo, useEffect, useRef } from "react";
import { Color, MeshStandardMaterial } from "three";
import { IStandardMaterial } from "~/types/material";
import { useTextureMap } from "~/view-scene/texture";
import { materialManager } from "../MaterialManager";
import { MaterialRecord } from "../types";
import { toMaterialSide } from "../utils";

type StandardMaterialAssetProps = {
  materialRecord: MaterialRecord;
  material: IStandardMaterial;
};

export const StandardMaterialAsset = memo(({ materialRecord, material }: StandardMaterialAssetProps) => {
  const materialRef = useRef<MeshStandardMaterial>(null!);

  useEffect(() => {
    const standardMaterial = new MeshStandardMaterial();
    materialRef.current = standardMaterial;
    materialManager.finishMaterialLoad(materialRecord.id, standardMaterial);
    return () => {
      standardMaterial.dispose();
    };
  }, []);

  const { displacementScale, roughness, color, transparent, opacity, side, emissive, emissiveIntensity, metalness } =
    material;

  useEffect(() => {
    materialRef.current.color = new Color(color);
    materialRef.current.displacementScale = displacementScale;
    materialRef.current.roughness = roughness;
    materialRef.current.transparent = transparent;
    materialRef.current.opacity = opacity;
    materialRef.current.metalness = metalness;
    materialRef.current.side = toMaterialSide(side);
    materialRef.current.emissive = new Color(emissive);
    materialRef.current.emissiveIntensity = emissiveIntensity;

    materialRef.current.needsUpdate = true;
  }, [displacementScale, roughness, color, transparent, opacity, side, emissive, emissiveIntensity, metalness]);

  const { texture, normalMap, displacementMap, alphaMap, roughnessMap, metalnessMap, emissiveMap } = material;
  const toTextureMap = useTextureMap(texture);
  const toNormalMap = useTextureMap(normalMap);
  const toDisplacementMap = useTextureMap(displacementMap);
  const toAlphaMap = useTextureMap(alphaMap);
  const toRoughnessMap = useTextureMap(roughnessMap);
  const toEmissiveMap = useTextureMap(emissiveMap);
  const toMetalnessMap = useTextureMap(metalnessMap);

  useEffect(() => {
    materialRef.current.map = toTextureMap;
    materialRef.current.normalMap = toNormalMap;
    materialRef.current.displacementMap = toDisplacementMap;
    materialRef.current.alphaMap = toAlphaMap;
    materialRef.current.roughnessMap = toRoughnessMap;
    materialRef.current.emissiveMap = toEmissiveMap;
    materialRef.current.metalnessMap = toMetalnessMap;
    materialRef.current.needsUpdate = true;
  }, [toTextureMap, toNormalMap, toDisplacementMap, toAlphaMap, toRoughnessMap, toEmissiveMap, toMetalnessMap]);

  return null;
});
