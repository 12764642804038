import { useUnmount } from "react-use";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import ISceneObject from "~/types/ISceneObject";

export const useEntity = <TEntity extends ISceneObject = ISceneObject>(entityId: string) => {
  const entity = useSceneData((state) => state.getEntity<TEntity>(entityId)!, shallow);
  const removeEntity = useSceneData((state) => state.removeEntity);
  const removed = entity?.removed ?? false;

  useUnmount(() => {
    if (!removed) {
      return;
    }

    removeEntity(entityId);
  });

  return entity;
};
