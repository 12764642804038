import { Icon, IconProps } from "./Icon";

export type GravityProps = IconProps;

export const Gravity = (props: GravityProps) => (
  <Icon width="20px" height="20px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228 228" {...props}>
    <path
      stroke="currentcolor"
      strokeWidth={5}
      d="M180.618 76.994c-5.516-13.305-15.146-22.275-27.116-25.259-13.18-3.277-27.551 1.391-39.147 12.557C102.76 53.119 88.397 48.45 75.209 51.736c-11.97 2.983-21.6 11.954-27.116 25.259-5.344 12.875-6.192 28.367-2.388 43.626 3.804 15.255 11.825 28.54 22.589 37.402 11.118 9.152 23.827 12.541 35.801 9.569 3.184-.796 8.319-1.822 10.261-2.207 1.946.386 7.077 1.411 10.261 2.207a35.524 35.524 0 0 0 8.588 1.054c9.308 0 18.725-3.635 27.213-10.623 10.764-8.862 18.785-22.147 22.589-37.402 3.803-15.26 2.955-30.752-2.389-43.627zm-5.605 41.632c-3.377 13.558-10.422 25.291-19.831 33.039-9.059 7.458-19.227 10.273-28.572 7.933-4.19-1.041-11.182-2.393-11.475-2.449l-.78-.153-.78.153c-.294.056-7.286 1.407-11.475 2.449-9.376 2.34-19.517-.474-28.572-7.933-9.409-7.748-16.453-19.481-19.831-33.039-3.382-13.554-2.67-27.221 1.999-38.479 4.495-10.832 12.131-18.082 21.507-20.418a26.54 26.54 0 0 1 6.442-.788c9.553 0 19.517 4.966 27.647 14.028l3.064 3.414 3.064-3.414c9.988-11.125 22.725-16.087 34.088-13.24 9.376 2.336 17.012 9.585 21.507 20.418 4.668 11.258 5.38 24.925 1.998 38.479z"
    />
    <path
      stroke="currentcolor"
      strokeWidth={5}
      d="m108.011 50.517 2.425-.024c11.946-.109 22.488-6.08 28.202-15.971 5.709-9.891 5.609-22.006-.273-32.408L137.17 0l-2.425.024c-11.946.109-22.488 6.08-28.202 15.971-5.709 9.891-5.609 22.006.273 32.408l1.195 2.114zm5.665-30.405c3.84-6.654 10.696-10.917 18.649-11.717 3.285 7.286 3.02 15.355-.82 22.01-3.84 6.654-10.696 10.917-18.649 11.717-3.285-7.286-3.02-15.355.82-22.01zM118.473 212.95v-33.292h-8.235v33.292l-17.82-17.82-5.822 5.822 23.642 23.642v.583h.583l3.535 3.534 3.534-3.534h.583v-.583l23.642-23.642-5.822-5.822z"
    />
  </Icon>
);
