import { useEffect, useState } from "react";
import { Container, Grid, Paper } from "@mui/material";
import { confirmUserEmail, createUser, deleteUser, getOnlineUsersCount, getUsers, updateUser } from "~/api/user.api";
import { adminGetWorkspaces } from "~/api/workspace.api";
import IUser from "~/types/IUser";
import IWorkspace from "~/types/IWorkspace";
import { Footer, Header } from "~/common/components";
import { UserTable } from "./UserTable";
import { EditUserDialog } from "./EditUserDialog";
import { WorkspaceFilter } from "./WorkspaceFilter";
import { Role } from "~/entities/profile";

export const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const loadUsersAndOnlineUsers = async () => {
    const getUsersResponse = await getUsers();
    const getUsersResult = await getUsersResponse.json();
    if (getUsersResult && getUsersResult.length > 0) {
      setUsers(getUsersResult);
    }

    const getOnlineUsersResponse = await getOnlineUsersCount();
    const getOnlineUsersResult = await getOnlineUsersResponse.json();
    if (getOnlineUsersResult && getOnlineUsersResult.onlineUsersCount) {
      setOnlineUsersCount(getOnlineUsersResult.onlineUsersCount);
    }
  };

  useEffect(() => {
    loadUsersAndOnlineUsers();
  }, []);

  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);

  const loadWorkspaces = async () => {
    const response = await adminGetWorkspaces();
    const result = await response.json();
    if (result && result.length > 0) {
      setWorkspaces(result);
    }
  };

  useEffect(() => {
    loadWorkspaces();
  }, []);

  const handleEditDialogClose = () => {
    setSelectedUser(null);
    setEditDialogOpen(false);
  };

  const handleCreateUserClick = () => {
    setSelectedUser(userBody);
    setEditDialogOpen(true);
  };

  const handleEditUserClick = () => {
    setEditDialogOpen(true);
  };

  const handleDeleteUserClick = async () => {
    if (selectedUser?._id) {
      await deleteUser(selectedUser._id);
      await loadUsersAndOnlineUsers();
    }
  };

  const reloadWorkspaces = async () => {
    await loadWorkspaces();
    await loadUsersAndOnlineUsers();
  };

  const handleSaveUser = async (user: IUser) => {
    if (user._id) {
      await updateUser(user);
    } else {
      await createUser(user);
    }
    await loadUsersAndOnlineUsers();
    setSelectedUser(null);
    setEditDialogOpen(false);
  };

  const handleConfirmEmail = async (userID: string) => {
    const { status, body } = await confirmUserEmail(userID);
    if (status === 200) {
      setSelectedUser(body);
    }
  };

  return (
    <>
      {selectedUser && (
        <EditUserDialog
          open={editDialogOpen}
          user={selectedUser}
          workspaces={workspaces}
          onConfirmEmail={handleConfirmEmail}
          onSave={handleSaveUser}
          onClose={handleEditDialogClose}
        />
      )}
      <>
        <Header />
        <Container maxWidth="xl">
          <Grid container spacing={4} sx={{ marginTop: 2 }}>
            <Grid item xs={2}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <WorkspaceFilter workspaces={workspaces} reloadWorkspaces={reloadWorkspaces} />
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <UserTable
                  users={users}
                  onlineUsersCount={onlineUsersCount}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  onCreate={handleCreateUserClick}
                  onEdit={handleEditUserClick}
                  onDelete={handleDeleteUserClick}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </>
    </>
  );
};

const userBody = {
  name: "",
  email: "",
  role: Role.user,
  storageData: [],
};
