import { defaultArray } from "~/entities/variable";
import { BaseSocket } from "../base";
import { ArrayValue, arrayValue } from "../values";

export class ArraySocket extends BaseSocket {
  constructor(id: string, public valueType: string, label: string = id, value: ArrayValue = defaultArray()) {
    super(arrayValue.name, id, label, value);
  }

  clone() {
    return new ArraySocket(this.id, this.valueType, this.label, this.value);
  }
}
