import { memo, RefObject, useMemo } from "react";
import { Group } from "three";
import ILinkComponent from "~/types/ILinkComponent";
import { useOpenLinkHelper } from "~/view-scene/OpenLinkHelper/useOpenLinkHelper";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import { useProfile } from "~/entities/profile";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { lockPointer, unlockPointer } from "~/view-scene/PointerLocker";
import { playerControls } from "~/view-scene/ControlsSystem";
import { DeviceMode } from "~/types/DeviceMode";

type LinkComponentProps = {
  componentDto: ILinkComponent;
  objectRef: RefObject<Group>;
};

export const LinkComponent = memo(({ componentDto, objectRef }: LinkComponentProps) => {
  const { url, visibleDistance = 5, includeReferer } = componentDto;

  const mode = useSessionStatus((state) => state.mode);

  const referer = useProfile((state) => state.profile?.tags?.["referer"]);

  let refererParam = "";
  if (referer && includeReferer) {
    refererParam = `?referer=${referer}`;
  }

  const openLinkHelper = useOpenLinkHelper((state) => state.openLinkHelper);

  const handleLinkAction = () => {
    if (mode === "desktop") {
      unlockPointer();
    }

    playerControls.cameraActive = false;

    const linkWithReferer = `${url}${refererParam}`;

    openLinkHelper(
      "Open Link",
      `You are about to open ${url} in a new window. Would you like to proceed?`,
      linkWithReferer,
      () => {
        if (mode === "desktop") {
          lockPointer();
        }
        playerControls.cameraActive = true;
      }
    );
  };

  const icon = useMemo(() => getIcon(mode), [mode]);

  return (
    <ActionIcon
      icon={icon}
      visibleDistance={visibleDistance}
      onActionButtonReleased={handleLinkAction}
      objectRef={objectRef}
    />
  );
});

function getIcon(mode: DeviceMode) {
  switch (mode) {
    case "desktop":
      return "/static/img/viewer/link-component/sprite-desktop.png";
    case "mobile":
      return "/static/img/viewer/link-component/sprite-mobile.png";
    case "vr":
      return "/static/img/viewer/link-component/sprite-vr.png";
  }
}
