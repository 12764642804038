import { attach, createEffect, createEvent, createStore, forward, sample } from "effector";
import { $assets } from "~/entities/assets";
import { $sceneData } from "~/entities/sceneData";
import { GridHelperSettings, UISettings } from "./types";
import { addInitialUISettings, getUISettings, saveUISettings } from "./utils";

export const loadUISettings = createEvent<UISettings>();
export const $uiSettings = createStore<UISettings | null>(null).on(loadUISettings, (_, settings) => settings);
export const $sceneMode = $uiSettings.map((uiSettings) => uiSettings?.sceneMode ?? "3d");
export const loadUISettingsFx = createEffect((id: string): UISettings => {
  const existingSettings = getUISettings(id);
  return existingSettings ? existingSettings : addInitialUISettings(id);
});

sample({
  source: { sceneData: $sceneData, assets: $assets },
  clock: loadUISettingsFx.doneData,
  fn: ({ sceneData, assets }, uiSettings) => {
    const updatedUISettings: UISettings = { ...uiSettings };

    if (
      uiSettings.selectedEntityId &&
      !sceneData?.objects.some((entity) => entity.id === uiSettings.selectedEntityId)
    ) {
      updatedUISettings.selectedEntityId = undefined;
      updatedUISettings.activeInspector = "scene";
    }

    if (uiSettings.selectedAssetId && !assets?.some((asset) => asset.id === uiSettings.selectedAssetId)) {
      updatedUISettings.selectedAssetId = undefined;
      updatedUISettings.activeInspector = "scene";
    }

    return updatedUISettings;
  },
  target: loadUISettings,
});

const saveUISettingsFx = attach({
  source: $uiSettings,
  effect: (settings: UISettings | null, newSettings: Partial<UISettings>) => {
    if (!settings) {
      return;
    }

    saveUISettings({ ...settings, ...newSettings });
  },
});

export const updateUISettings = createEvent<Partial<UISettings>>();

$uiSettings.on(updateUISettings, (state, newSettings) => {
  if (!state) {
    return state;
  }

  return { ...state, ...newSettings };
});

forward({ from: updateUISettings, to: saveUISettingsFx });

export const updateGridHelperSettings = createEvent<Partial<GridHelperSettings>>();

sample({
  source: $uiSettings,
  clock: updateGridHelperSettings,
  fn: (uiSettings, gridHelperSettingsUpdate) => {
    if (uiSettings) {
      return { gridHelper: { ...uiSettings.gridHelper, ...gridHelperSettingsUpdate } };
    }

    return {};
  },
  target: updateUISettings,
});

export const expandInspectorContainer = createEvent<string>();

export const collapseInspectorContainer = createEvent<string>();

sample({
  source: $uiSettings,
  clock: expandInspectorContainer,
  fn: (uiSettings, expandedContainerId) => {
    if (uiSettings) {
      if (uiSettings.expandedInspectorContainers.includes(expandedContainerId)) {
        return uiSettings;
      } else {
        return { expandedInspectorContainers: [...uiSettings.expandedInspectorContainers, expandedContainerId] };
      }
    }

    return {};
  },
  target: updateUISettings,
});

sample({
  source: $uiSettings,
  clock: collapseInspectorContainer,
  fn: (uiSettings, collapsedContainerId) => {
    if (uiSettings) {
      const existingExpandedContainers = uiSettings.expandedInspectorContainers;
      if (!existingExpandedContainers.includes(collapsedContainerId)) {
        return {};
      } else {
        return {
          expandedInspectorContainers: existingExpandedContainers.filter(
            (containerId) => containerId !== collapsedContainerId
          ),
        };
      }
    }

    return {};
  },
  target: updateUISettings,
});

export const toggleSceneMode = createEvent();

sample({
  source: $uiSettings,
  clock: toggleSceneMode,
  fn: (uiSettings) => {
    if (uiSettings) {
      return {
        ...uiSettings,
        sceneMode: uiSettings.sceneMode === "2d" ? "3d" : "2d",
      };
    }

    return {};
  },
  target: updateUISettings,
});
