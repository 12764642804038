import { useMediaQuery, useTheme } from "@mui/material";
import { MobileHeader } from "./MobileHeader";
import { DesktopHeader } from "./DesktopHeader";

export const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isSmallScreen) {
    return <MobileHeader />;
  } else {
    return <DesktopHeader />;
  }
};
