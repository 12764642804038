import { defaultAsset } from "~/entities/variable";
import { BaseSocket } from "../base";
import { assetVideoValue, AssetVideoValue } from "../values";

export class AssetVideoSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AssetVideoValue = defaultAsset()) {
    super(assetVideoValue.name, id, label, value);
  }

  clone() {
    return new AssetVideoSocket(this.id, this.label, this.value);
  }
}
