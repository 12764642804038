import { useCallback } from "react";
import { Stack, StackProps } from "@mui/material";
import { OverlayUIEntitySelect, OverlayUIEntitySelectProps } from "./OverlayUIEntitySelect";
import { OverlaySelect, OverlaySelectProps } from "./OverlaySelect";

export type OverlayUIEntitySelectorProps = Pick<StackProps, "className" | "sx"> & {
  name?: string;
  value?: OverlayUIEntitySelectorValue;
  onChange?: (event: OverlayUIEntitySelectorEvent) => void;
};

type OverlayUIEntitySelectorEvent = {
  target: {
    name?: string;
    value: OverlayUIEntitySelectorValue;
  };
};

type OverlayUIEntitySelectorValue = {
  overlayId: string | null;
  uiEntityId: string | null;
};

export const OverlayUIEntitySelector = ({ name, value, onChange, ...rest }: OverlayUIEntitySelectorProps) => {
  const handleOverlayChange: NonNullable<OverlaySelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            overlayId: e.target.value,
            uiEntityId: null,
          },
        },
      });
    },
    [name, onChange]
  );
  const handleUIEntityChange: NonNullable<OverlayUIEntitySelectProps["onChange"]> = useCallback(
    (e) => {
      onChange?.({
        target: {
          name,
          value: {
            overlayId: value?.overlayId ?? null,
            uiEntityId: e.target.value,
          },
        },
      });
    },
    [name, value, onChange]
  );

  return (
    <Stack {...rest}>
      <OverlaySelect value={value?.overlayId ?? null} emptyLabel="Select Overlay" onChange={handleOverlayChange} />
      <OverlayUIEntitySelect
        value={value?.uiEntityId ?? null}
        overlayId={value?.overlayId}
        onChange={handleUIEntityChange}
      />
    </Stack>
  );
};
