import { useCallback, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { TransformControls as TransformControlsImpl } from "@react-three/drei";
import { Object3D } from "three";
import { TransformControlsMode } from "./types";
import { useUnit } from "effector-react";
import { $sceneMode } from "~/hephaestus/builderUIState";

export type TransformControlsProps = {
  targetId?: string;
  size?: number;
  mode: TransformControlsMode;
  onStart?: (mode: TransformControlsMode, object: Object3D) => void;
  onChange?: (mode: TransformControlsMode, object: Object3D) => void;
  onCommit?: (mode: TransformControlsMode, object: Object3D) => void;
};

export function TransformControls({ targetId, size = 1, mode, onStart, onChange, onCommit }: TransformControlsProps) {
  const sceneMode = useUnit($sceneMode);

  const scene = useThree((state) => state.scene);

  const [targetObject, setTargetObject] = useState<Object3D>();

  const handleMouseDown = useCallback(() => {
    if (targetObject && targetObject.name === targetId) {
      onStart?.(mode, targetObject);
    }
  }, [targetObject, targetId, mode, onStart]);

  const handleMouseUp = useCallback(() => {
    if (targetObject && targetObject.name === targetId) {
      onCommit?.(mode, targetObject);
    }
  }, [targetObject, targetId, mode, onCommit]);

  const handleChange = useCallback(() => {
    if (targetObject && targetObject.name === targetId) {
      onChange?.(mode, targetObject);
    }
  }, [targetObject, targetId, mode, onChange]);

  useFrame(() => {
    if (targetId) {
      const newTarget = scene.getObjectByName(targetId);
      if (newTarget && newTarget !== targetObject) {
        setTargetObject(newTarget);
      }
    } else {
      setTargetObject(undefined);
    }
  });

  if (!targetObject || mode === "disabled") {
    return null;
  }

  return (
    <TransformControlsImpl
      mode={mode}
      object={targetObject}
      size={size}
      showZ={sceneMode === "3d"}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onChange={handleChange}
    />
  );
}
