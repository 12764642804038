import { forward } from "effector";
import { entityAdd, entityDelete, entityUpdate } from "~/api/socket";
import { addEntity, deleteEntity, updateEntity } from "~/entities/entities";

forward({
  from: entityAdd,
  to: addEntity,
});

forward({
  from: entityUpdate.map(({ id, ...body }) => ({ id, entity: body })),
  to: updateEntity,
});

forward({
  from: entityDelete,
  to: deleteEntity,
});
