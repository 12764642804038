import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { BooleanSocket } from "../../sockets";

export class BooleanConstantNode extends BaseNode {
  static readonly type = "logic/booleanConstant";
  static readonly label = "Boolean";
  static readonly category: NodeCategory = "Logic";
  static readonly pure = true;

  inputs = [new BooleanSocket("a")];
  outputs = [new BooleanSocket("value")];

  eval(context: NodeEvalContext) {
    context.writeOutput<boolean>("value", context.readInput<boolean>("a"));
  }
}
