import { memo, MutableRefObject, Ref, useEffect, useImperativeHandle, useMemo } from "react";
import { Mesh, MeshBasicMaterial, PlaneGeometry, VideoTexture } from "three";
import { IVideoSceneObject } from "~/types/IVideoSceneObject";
import { playHTMLMedia, useToggleMatrixAutoUpdate } from "~/view-scene/utils";
import { useTexture } from "~/view-scene/texture";

type RenderVideoProps = {
  dto: IVideoSceneObject;
  videoUrl: string;
  assetName: string;
  controls?: Ref<VideoControls>;
  videoRef?: MutableRefObject<HTMLVideoElement | null>;
};

export type VideoControls = {
  play: (startedTime?: Date) => void;
  pause: () => void;
  stop: () => void;
  isPlaying: () => boolean;
};

export const RenderVideo = memo(({ dto, assetName, videoUrl, videoRef, controls }: RenderVideoProps) => {
  const { loop, autoplay, isSynced, playAudio, distanceModel, maxDistance, refDistance, rolloffFactor, isStatic } = dto;

  const object = useMemo(() => {
    const geometry = new PlaneGeometry(dto.width, dto.height, 1, 1);
    const mesh = new Mesh(geometry);
    mesh.visible = dto.autoplay;

    return mesh;
  }, [dto]);

  const videoTexture = useTexture({
    name: assetName,
    url: videoUrl,
    type: "video",
    isStream: dto.isStream,
    loop,
    flipY: true,
    audioAttachTo: object,
    volume: playAudio ? 1 : 0,
    autoplay: !isSynced && autoplay,
    distanceModel,
    maxDistance,
    refDistance: 16,
    rolloffFactor: 2,
  }) as VideoTexture | undefined;

  const material = useMaterial(videoTexture);

  useImperativeHandle(
    controls,
    () => ({
      play: (startedTime?: Date) => {
        const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
        if (!htmlVideo) {
          return;
        } else if (startedTime) {
          const now = new Date();
          const videoDuration = htmlVideo.duration;
          const deltaInSeconds = Math.abs(startedTime.getTime() - now.getTime()) / 1000;
          if (loop) {
            htmlVideo.currentTime = deltaInSeconds % videoDuration;
            playHTMLMedia(htmlVideo);
            object.visible = true;
          } else if (deltaInSeconds < videoDuration) {
            htmlVideo.currentTime = deltaInSeconds;
            playHTMLMedia(htmlVideo);
            object.visible = true;
          }
        } else {
          playHTMLMedia(htmlVideo);
          object.visible = true;
        }
      },
      pause: () => {
        const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
        htmlVideo?.pause();
      },
      stop: () => {
        const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
        if (htmlVideo) {
          object.visible = false;
          htmlVideo.pause();
          htmlVideo.currentTime = 0;
        }
      },
      isPlaying: () => {
        const htmlVideo = videoTexture?.source.data as HTMLVideoElement | undefined;
        if (htmlVideo) {
          return htmlVideo.currentTime > 0 && !htmlVideo.paused && !htmlVideo.ended && htmlVideo.readyState > 2;
        }
        return false;
      },
    }),
    [videoTexture, object]
  );

  useEffect(() => {
    if (material) {
      object.material = material;
    }
  }, [object, material]);

  useToggleMatrixAutoUpdate(object, isStatic);

  if (videoRef) {
    videoRef.current = (videoTexture?.source.data as HTMLVideoElement | undefined) ?? null;
  }

  return <primitive object={object} />;
});

const useMaterial = (texture?: VideoTexture) => {
  return useMemo(() => {
    if (!texture) {
      return null;
    }

    const material = new MeshBasicMaterial();
    material.map = texture;

    return material;
  }, [texture]);
};
