import { Mesh, MeshBasicMaterial } from "three";
import { ForwardedRef, forwardRef } from "react";
import { RenderScreenSharePreviewWithCustomGeometry } from "./RenderScreenSharePreviewWithCustomGeometry";
import { RenderScreenShareWithBasicGeometryPreview } from "./RenderScreenShareWithBasicGeometryPreview";

type RenderScreenSharePreviewProps = {
  width: number;
  height: number;
  useCustomGeometry: boolean;
  geometryAsset?: string | null;
  screenShareMaterial: MeshBasicMaterial;
};

export const RenderScreenSharePreview = forwardRef(
  (
    { width, height, screenShareMaterial, useCustomGeometry, geometryAsset }: RenderScreenSharePreviewProps,
    ref: ForwardedRef<Mesh>
  ) => {
    if (useCustomGeometry && geometryAsset) {
      return (
        <RenderScreenSharePreviewWithCustomGeometry
          geometryAsset={geometryAsset}
          screenShareMaterial={screenShareMaterial}
          ref={ref}
        />
      );
    } else {
      return (
        <RenderScreenShareWithBasicGeometryPreview
          width={width}
          height={height}
          screenShareMaterial={screenShareMaterial}
          ref={ref}
        />
      );
    }
  }
);
