import { NodeContext } from "../../base";
import { NumberCompareNode } from "./NumberCompareNode";

export class NumberMoreOrEqualNode extends NumberCompareNode {
  static readonly type = "logic/numberMoreOrEqual";
  static readonly label = "Number >=";

  constructor(context: NodeContext) {
    super(context, (a, b) => a >= b);
  }
}
