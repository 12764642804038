import { getDefaultValue } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { StructSocket } from "../../sockets";
import { StructBaseNode } from "./StructBaseNode";
import { StructValue } from "../../values";

export class StructSplitNode extends StructBaseNode {
  static type = "struct/split";
  static label = "Struct Split";

  inputs = [new StructSocket("struct")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const structValue = context.readInput<StructValue>("struct");
    const struct = this.getStruct(node.spec);

    if (!structValue) {
      return;
    }

    for (const field of struct.fields) {
      const value = structValue[field.id] ?? field.initialValue ?? getDefaultValue(field.type);

      context.writeOutput(field.id, value);
    }
  }

  getOutputs(nodeSpec: NodeSpec): BaseSocket[] {
    return this.getStructSockets(nodeSpec);
  }
}
