import create from "zustand";
import { toggleValue } from "./utils";

export type UseMediaStateType = {
  voiceChatActive: boolean;
  muted: boolean;
  enabled: boolean;

  joinVoiceChat: () => void;
  toggleMute: (flag?: boolean) => void;
  toggleEnable: (flag?: boolean) => void;

  reset: () => void;
};

export const useMediaState = create<UseMediaStateType>((set, get) => ({
  voiceChatActive: false,
  muted: false,
  enabled: false,

  joinVoiceChat: () => {
    set({ voiceChatActive: true });
  },

  toggleMute: (flag?: boolean) => {
    set({ muted: toggleValue(get().muted, flag) });
  },

  toggleEnable: (flag?: boolean) => {
    set({ enabled: toggleValue(get().enabled, flag) });
  },

  reset: () => {
    set({
      voiceChatActive: false,
      muted: false,
      enabled: false,
    });
  },
}));
