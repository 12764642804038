import { Material } from "three";

export function disposeMaterial(material: Material) {
  material.dispose();
  for (const key in material) {
    const value = (material as any)[key];
    if (value && typeof value === "object" && "minFilter" in value) {
      value.dispose();
    }
  }
}
