import { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import { TextField } from "~/hephaestus/components/TextField";
import { Button } from "~/hephaestus/components/Button";
import { useProfile } from "~/entities/profile";
import { updateMyName } from "~/api/profile.api";
import ErrorAlert from "~/common/components/ErrorAlert";
import { useOnScreen } from "~/common/hooks/useOnScreen";
import { ChangePasswordDialog } from "./ChangePasswordDialog";

export type PersonalInfoProps = {
  onScreen: VoidFunction;
};

export const PersonalInfo = ({ onScreen }: PersonalInfoProps) => {
  const { profile, loadProfile } = useProfile((state) => ({
    profile: state.profile,
    loadProfile: state.loadProfile,
  }));

  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const handleChangePasswordDialogClose = () => setChangePasswordDialogOpen(false);
  const handleChangePasswordClick = () => setChangePasswordDialogOpen(true);

  const [errorAlert, setErrorAlert] = useState({
    open: false,
    message: "",
  });

  const handleCloseErrorAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlert({ open: false, message: "" });
  };

  const handleNameChange = async (event: any) => {
    const value = event.target.value;

    const { status } = await updateMyName(value);
    if (status === 200) {
      await loadProfile();
    }
  };

  const ref = useRef();

  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      onScreen();
    }
  }, [isVisible]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <ChangePasswordDialog open={changePasswordDialogOpen} onClose={handleChangePasswordDialogClose} />
      <Box
        component="div"
        id="personal-info"
        ref={ref}
        sx={{
          border: "1px solid " + palette.primary.gray100,
          backgroundColor: palette.primary.black400,
          padding: "20px 24px 24px",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24px",
            color: palette.primary.gray600,
            [theme.breakpoints.down("lg")]: {
              fontSize: "18px",
              lineHeight: "22px",
            },
          })}
          variant="body1"
        >
          Personal info
        </Typography>
        <Stack
          direction="column"
          sx={{
            gridGap: "24px",
            padding: "24px 0",
          }}
        >
          <Box component="div">
            <TextField
              fullWidth
              type="text"
              size="large"
              value={profile.name ?? ""}
              onChange={handleNameChange}
              name="user"
              placeholder="Nickname"
              id="custom-css-outlined-input"
            />
          </Box>
          <Box component="div">
            <TextField
              fullWidth
              disabled={true}
              size="large"
              value={profile.email ?? ""}
              type="Email"
              name="Email"
              placeholder="Email"
            />
          </Box>
          {errorAlert.open && <ErrorAlert onClose={handleCloseErrorAlert}>{errorAlert.message}</ErrorAlert>}
        </Stack>
        <Box
          component="div"
          sx={{
            maxWidth: "fit-content",
          }}
        >
          <Button size="large" variant="contained" onClick={handleChangePasswordClick}>
            Change password
          </Button>
        </Box>
      </Box>
    </>
  );
};
