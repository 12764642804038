import { Icon, IconProps } from "./Icon";

export type AvatarSystemProps = IconProps;

export const AvatarSystem = (props: AvatarSystemProps) => (
  <Icon width="14px" height="14px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      d="M10.5677 9.90213C11.4412 10.341 12.1902 11.0452 12.7368 11.9258C12.8451 12.1002 12.8992 12.1874 12.9179 12.3081C12.956 12.5535 12.7882 12.8551 12.5596 12.9522C12.4472 13 12.3207 13 12.0677 13M9.36768 7.31934C10.2567 6.87753 10.8677 4.76012 10.8677 3.7C10.8677 2.63988 10.2567 1.72247 9.36768 1.28066M8.16768 3.7C8.16768 5.19117 6.95884 7.6 5.46768 7.6C3.97651 7.6 2.76768 5.19117 2.76768 3.7C2.76768 2.20883 3.97651 1 5.46768 1C6.95884 1 8.16768 2.20883 8.16768 3.7ZM1.30321 11.763C2.2598 10.3267 3.7693 9.4 5.46768 9.4C7.16605 9.4 8.67555 10.3267 9.63214 11.763C9.8417 12.0777 9.94648 12.235 9.93442 12.4359C9.92502 12.5924 9.82245 12.784 9.69741 12.8786C9.53683 13 9.31597 13 8.87426 13H2.06109C1.61938 13 1.39852 13 1.23794 12.8786C1.1129 12.784 1.01033 12.5924 1.00094 12.4359C0.988873 12.235 1.09365 12.0777 1.30321 11.763Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
