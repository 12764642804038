import { Button, Stack, Typography } from "@mui/material";
import { useSceneData } from "~/common/stores/useSceneData";
import { palette } from "~/theme";
import { startSession } from "~/view-scene/welcome-screen";

export function JoinScene() {
  const sceneState = useSceneData((state) => state.sceneState);

  const handleStartSession = async () => {
    startSession();
  };

  if (!sceneState) {
    return null;
  }

  const { name, description } = sceneState;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      flexGrow={1}
      gap="16px"
      sx={(theme) => ({
        margin: "0 24px",
        [theme.breakpoints.down("md")]: {
          margin: "0 16px",
        },
      })}
    >
      <Typography
        variant="h1"
        sx={(theme) => ({
          color: palette.primary.white,
          [theme.breakpoints.down("md")]: {
            fontSize: "36px",
            lineHeight: "45px",
          },
        })}
      >
        {name}
      </Typography>
      <Typography
        sx={(theme) => ({
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "28px",
          width: "100%",
          maxWidth: "561px",
          color: palette.primary.gray800,
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
        })}
        variant="body1"
      >
        {description}
      </Typography>
      <Button
        size="large"
        variant="accent"
        onClick={handleStartSession}
        sx={{
          width: "130px",
        }}
      >
        Start playing
      </Button>
    </Stack>
  );
}
