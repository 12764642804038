import { TonConnectUI, WalletsListManager } from "@tonconnect/ui";
import { availableWallets } from "./availableWallets";

WalletsListManager.prototype.getWallets = async function () {
  return availableWallets;
};

let tonConnectUI: TonConnectUI | null = null;

export function getTonConnect(manifest: string) {
  if (!tonConnectUI) {
    tonConnectUI = new TonConnectUI({
      manifestUrl: manifest, //"https://central.hackney.games/icons/tonConnectManifest.json",
      buttonRootId: "ton-connect",
    });
  }

  return tonConnectUI;
}
