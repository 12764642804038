import { Stack, styled } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import IUser from "~/types/IUser";
import { IconButton } from "~/hephaestus/components/IconButton";
import ConfirmationDialog from "~/common/components/ConfirmationDialog";
import { useState } from "react";

export type EnhancedTableToolbarProps = {
  selectedUser: IUser | null;
  onCreate: VoidFunction;
  onEdit: (user: IUser | null) => void;
  onDelete: (user: IUser | null) => void;
};

export const EnhancedTableToolbar = ({ selectedUser, onCreate, onEdit, onDelete }: EnhancedTableToolbarProps) => {
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const openDeleteConfirmationDialog = () => setOpenConfirmDeleteDialog(true);

  const closeDeleteConfirmationDialog = () => setOpenConfirmDeleteDialog(false);

  const handleDelete = async () => {
    setOpenConfirmDeleteDialog(false);
    await onDelete(selectedUser);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirmDeleteDialog}
        title={`Confirm deletion of ${selectedUser?.email ?? ""}`}
        content="This action can not be reverted."
        onConfirm={handleDelete}
        onClose={closeDeleteConfirmationDialog}
      />
      <Stack direction="row">
        <MenuButton onClick={() => onCreate()} variant="outlined">
          <AddIcon />
        </MenuButton>
        <MenuButton
          disabled={selectedUser == null}
          onClick={() => onEdit(selectedUser)}
          variant={selectedUser == null ? "text" : "outlined"}
        >
          <EditIcon />
        </MenuButton>
        <MenuButton
          disabled={selectedUser == null}
          onClick={openDeleteConfirmationDialog}
          variant={selectedUser == null ? "text" : "outlined"}
        >
          <DeleteIcon />
        </MenuButton>
      </Stack>
    </>
  );
};

const MenuButton = styled(IconButton)`
  margin-right: 10px;
`;
