import React from "react";
import { AuthFormState } from "~/pages/auth/types";
import { Login } from "./Login";
import { Register } from "./Register";
import { RegistrationSuccessful } from "./RegistrationSuccessful";
import { RequestPasswordReset } from "./RequestPasswordReset";

type LocalAuthProviderProps = {
  formState: AuthFormState;
  isOauthEnabled?: boolean;
  onLoginSuccess: (token: string) => void;
  onRegistrationSuccess: (token: string) => void;
  onFormStateChange: (formState: AuthFormState) => void;
};

export function LocalAuthProvider({
  formState,
  isOauthEnabled = true,
  onLoginSuccess,
  onRegistrationSuccess,
  onFormStateChange,
}: LocalAuthProviderProps) {
  return (
    <>
      {formState === "login" && (
        <Login isOauthEnabled={isOauthEnabled} onSuccess={onLoginSuccess} onFormStateChange={onFormStateChange} />
      )}
      {formState === "register" && <Register isOauthEnabled={isOauthEnabled} onSuccess={onRegistrationSuccess} />}
      {formState === "registrationSuccessful" && <RegistrationSuccessful />}
      {formState === "passwordReset" && <RequestPasswordReset />}
    </>
  );
}
