import { Box, Stack, Typography } from "@mui/material";
import { useSceneData } from "common/stores/useSceneData";
import useSessionStatus from "view-scene/stores/useSessionStatus";
import { palette } from "~/theme";
import { Button } from "~/hephaestus/components/Button";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Settings } from "~/common/components/Icon";
import { WorksOn } from "~/common/components";
import { DesktopGuidance } from "./DesktopGuidance";
import { VRGuidance } from "./VRGuidance";
import { MobileGuidance } from "./MobileGuidance";
import { RemoteUsers } from "./RemoteUsers";
import { HOST } from "~/config";
import { SetupAvatarReminder } from "./SetupAvatarReminder";
import { isMobileVR } from "~/common/utils/isMobile";
import { startSession } from "../models";
import { useProfile } from "~/entities/profile";

export type JoinSceneType = {
  onOpenSettings: VoidFunction;
};

export function JoinScene({ onOpenSettings }: JoinSceneType) {
  const sceneState = useSceneData((state) => state.sceneState);
  const isLoggedIn = useProfile((state) => state.isAuthorised());

  const { mode } = useSessionStatus((state) => ({
    mode: state.mode,
  }));

  const handleStartSession = async () => {
    startSession();
  };

  if (!sceneState) {
    return null;
  }

  const { name, description, networkSystem } = sceneState;

  const showRemoteUsers = HOST === "8xr" && isLoggedIn && networkSystem.enableMultiplayer;

  return (
    <>
      {!isMobileVR() && <SetupAvatarReminder />}
      <Box
        component="div"
        sx={(theme) => ({
          overflow: "hidden",
          position: "relative",
          height: "100%",
          [theme.breakpoints.down("md")]: {
            height: "calc(100% - 188px)",
            overflow: "auto",
          },
          [theme.breakpoints.down("sm")]: {
            overflow: "auto",
          },
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={(theme) => ({
            padding: "0 24px",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
              padding: "0 16px",
            },
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={(theme) => ({
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                position: "relative",
              },
            })}
          >
            <Box component="div">
              <Typography
                variant="h1"
                padding="12px 0"
                sx={(theme) => ({
                  color: palette.primary.white,
                  [theme.breakpoints.down("md")]: {
                    fontSize: "36px",
                    lineHeight: "45px",
                  },
                })}
              >
                {name}
              </Typography>

              <Typography
                sx={(theme) => ({
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "28px",
                  width: "100%",
                  maxWidth: "561px",
                  color: palette.primary.gray800,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: "100%",
                  },
                })}
                variant="body1"
              >
                {description}
              </Typography>

              <WorksOn />

              <Stack
                sx={{
                  marginTop: "30px",
                  gridGap: "16px",
                }}
                direction="row"
                flexWrap="wrap"
              >
                <div>
                  <IconButton onClick={onOpenSettings} variant="contained" size="large">
                    <Settings />
                  </IconButton>
                </div>
                <div>
                  <Button size="large" variant="accent" onClick={handleStartSession}>
                    Start playing
                  </Button>
                </div>
              </Stack>
            </Box>
            {showRemoteUsers && <RemoteUsers />}
          </Stack>
        </Stack>

        {mode === "mobile" && <MobileGuidance />}
        {mode === "desktop" && <DesktopGuidance />}
        {mode === "vr" && <VRGuidance />}
      </Box>
    </>
  );
}
