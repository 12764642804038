export enum AssetType {
  glb = "glb",
  skybox = "skybox",
  hdri = "hdri",
  image = "image",
  audio = "audio",
  video = "video",
  material = "material",
  animation = "animation",
  script = "script",
  jsScript = "jsScript",
  ui = "ui",
  storage = "storage",
  inventoryItem = "inventoryItem",
  struct = "struct",
}
