import { memo, useEffect } from "react";
import { ComponentType } from "~/types/ComponentType";
import { RigidBodyComponentContext, subscribeCollisions, useComponentContext } from "~/view-scene/runtime";
import { loadFinish } from "~/view-scene/sceneLoad";
import { AssetRigidBody } from "./AssetRigidBody";
import { AutoRigidBody } from "./AutoRigidBody";
import { BoundingBoxRigidBody } from "./BoundingBoxRigidBody";
import { RigidBodyComponentProps } from "./types";

export const RigidBodyComponent = memo(({ componentDto, contextRef }: RigidBodyComponentProps) => {
  const bodyType = componentDto.body?.type ?? "auto";

  useEffect(() => {
    const unsubscribe = subscribeCollisions(contextRef, componentDto.id);
    loadFinish(componentDto.id);

    return () => {
      unsubscribe();
    };
  }, []);

  const context: RigidBodyComponentContext = {
    id: componentDto.id,
    type: ComponentType.RIGID_BODY,
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  if (bodyType === "auto") {
    return <AutoRigidBody componentDto={componentDto} contextRef={contextRef} />;
  } else if (bodyType === "asset") {
    return <AssetRigidBody componentDto={componentDto} contextRef={contextRef} />;
  } else if (bodyType === "boundingBox") {
    return <BoundingBoxRigidBody componentDto={componentDto} contextRef={contextRef} />;
  } else if (bodyType === "boundingSphere") {
    return <BoundingBoxRigidBody componentDto={componentDto} contextRef={contextRef} />;
  }

  return null;
});
