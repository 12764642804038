import { Button, Divider, Stack, styled } from "@mui/material";
import { TextField } from "~/hephaestus/components/TextField";
import { UserRecord } from "./UserRecord";
import { palette } from "~/theme";
import { WorkspaceUser } from "~/api/types";
import { useProfile } from "~/entities/profile";
import { ChangeEvent, useState } from "react";

type ManageProjectMembersProps = {
  members: WorkspaceUser[];
  onUserAdd: (invitationEmail: string) => Promise<{
    success: boolean;
    errorMessage?: string;
  }>;
  showDivider?: boolean;
  onUserDelete: (email: string) => Promise<void>;
};

export function ManageProjectMembers({
  members,
  onUserAdd,
  onUserDelete,
  showDivider = false,
}: ManageProjectMembersProps) {
  const profile = useProfile((state) => state.profile);

  const [invitationEmail, setInvitationEmail] = useState("");
  const [invitationInProgress, setInvitationInProgress] = useState(false);
  const [error, setError] = useState<string>();

  const handleInvitationEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvitationEmail(e.target.value);
    setError(undefined);
  };

  const addUser = async () => {
    setError(undefined);
    setInvitationInProgress(true);
    const result = await onUserAdd(invitationEmail);

    setInvitationInProgress(false);
    if (result.success) {
      setInvitationEmail("");
    } else {
      setError(result.errorMessage);
    }
  };

  const getSortedMembers = () => {
    if (!members) {
      return [];
    }

    return [...members.filter((w) => w.id === profile?.id), ...members.filter((w) => w.id !== profile?.id)];
  };

  return (
    <>
      <Stack direction="row" alignItems="start" justifyContent="space-between" gap="12px">
        <TextField
          placeholder="Enter email"
          fullWidth
          value={invitationEmail}
          error={error != null}
          helperText={error}
          onChange={handleInvitationEmailChange}
        />
        <Button variant="accent" onClick={addUser} disabled={invitationEmail === "" || invitationInProgress}>
          Invite
        </Button>
      </Stack>
      {showDivider && <Divider />}
      <UserContainer>
        {getSortedMembers().map((user) => (
          <UserRecord key={user.id} user={user} onDelete={onUserDelete} />
        ))}
      </UserContainer>
    </>
  );
}

const UserContainer = styled(Stack)`
  flex-direction: column;
  overflow: auto;
  gap: 5px;
  max-height: 250px;

  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.primary.gray200};
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: ${palette.primary.gray600};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${palette.primary.white};
    transition: 0.4s ease;
    cursor: pointer;
  }
`;
