import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket } from "../../sockets";
import { ComponentType } from "~/types/ComponentType";

export class AnimationStopAllNode extends BaseNode {
  static readonly type = "action/animationStopAll";
  static readonly label = "Stop All Animations";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");

    if (!entity?.components) {
      return;
    }

    Object.values(entity.components).forEach((component) => {
      if (component.type === ComponentType.ANIMATION || component.type === ComponentType.SPRITE_ANIMATION) {
        component.stop();
      }
    });
  }
}
