import { Footer, Header } from "~/common/components";
import { Button, Stack, styled, Typography } from "@mui/material";
import { LabelGroup, Select, SelectItem, TextField } from "~/hephaestus/components";
import React, { useEffect, useState } from "react";
import { AnalyticsRecord, getAnalytics, sendMessage } from "~/api/telegram.api";

const botIds = [
  "catalog",
  "slingTombGameBot",
  "rudeRacesGameBot",
  "crazyFishingGameBot",
  "formulaRushGameBot",
  "desertWarriorGameBot",
  "zombieAttackGameBot",
  "riseUpGameBot",
  "sudokuMasterGameBot",
  "kingSolitaireGameBot",
  "boxTowerGameBot",
  "dropNMergeGameBot",
  "memoryMatchGameBot",
  "deverseeGameBot",
  "furiousRoadGameBot",
  "candylandGameBot",
  "kingdomSurvivorGameBot",
  "endlessSiegeGameBot",
  "endlessSiegeGameBot2",
  "parkingJamGameBot",
  "polygamiGameBot",
  "shardQuestBot",
  "tonSpacesBot",
  "endlessSiegeGameeBot",
  "boltClickerBot",
  "battalionCommanderGameBot",
  "osolitaire_bot",
  "kingSolitaireGameBot",
];

export function TelegramAdminPage() {
  const [botId, setBotId] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("");
  const [users, setUsers] = useState<string | null>(null);

  const [analytics, setAnalytics] = useState<AnalyticsRecord[]>([]);

  useEffect(() => {
    getAnalytics().then((analyticsResponse) => {
      if (analyticsResponse.status === 200) {
        setAnalytics(analyticsResponse.body);
      }
    });
  }, []);

  const handleSendMessage = async () => {
    if (botId && message) {
      const response = await sendMessage(botId, message, users?.split(","));
      setMessage("");
      setUsers(null);
      alert(
        `Status: ${response.status}; ${
          response.status === 200 ? "Messages Scheduled:" + response.body?.messagesScheduled : ""
        }`
      );
    }
  };

  return (
    <Stack direction="column" height="100%" minHeight="100vh">
      <Header />
      <Container>
        <AnalyticsSection>
          <SectionName variant="h3">Analytics</SectionName>
          <SectionName variant="subtitle1">
            Online: {analytics.map((a) => a.online).reduce((partialSum, a) => partialSum + a, 0)}
          </SectionName>
          <Stack padding="4px 10px 4px 10px">
            {analytics
              .sort((a, b) => b.online - a.online)
              .map((analytics) => (
                <Typography key={analytics.telegramBotId} variant="body2">
                  {botIdToName(analytics.telegramBotId)}:{" "}
                  <b>
                    {analytics.count} ({analytics.online})
                  </b>
                </Typography>
              ))}
          </Stack>
        </AnalyticsSection>
        <SendMessageSection>
          <SectionName variant="h3">Send Message</SectionName>
          <LabelGroup label="Bot">
            <Select name="textAlign" displayEmpty={true} value={botId ?? ""} onChange={(e) => setBotId(e.target.value)}>
              <SelectItem key="" value="">
                None
              </SelectItem>
              {botIds.map((option) => (
                <SelectItem key={option} value={option}>
                  {botIdToName(option)}
                </SelectItem>
              ))}
            </Select>
          </LabelGroup>
          <Stack direction="row" justifyContent="space-between" alignItems="center" padding="4px 10px 4px 10px">
            <TextField
              placeholder="Enter your message, use @username as a placeholder for Telegram Username."
              name="multilingualTexts"
              value={message}
              fullWidth
              multiline
              rows={6}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" padding="4px 10px 4px 10px">
            <TextField
              placeholder="Optional list of comma separated user ids"
              name="users"
              value={users ?? ""}
              fullWidth
              onChange={(e) => setUsers(e.target.value)}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" padding="4px 10px 4px 10px">
            <Button size="medium" variant="accent" onClick={handleSendMessage}>
              Send
            </Button>
          </Stack>
        </SendMessageSection>
      </Container>
      <Footer />
    </Stack>
  );
}

const botIdToName = (botId: string) =>
  botId
    .split(/(?=[A-Z])/)
    .join(" ")
    .replace(/\b\w/g, (match) => match.toUpperCase());

const SectionName = styled(Typography)`
  padding: 4px 10px 4px 10px;
`;

const SendMessageSection = styled(Stack)`
  margin: 20px 0;
  width: 450px;
  flex-direction: column;
  gap: 10px;
`;

const AnalyticsSection = SendMessageSection;

const Container = styled(Stack)`
  height: 100%;
  flex-grow: 1;
  padding: 0 48px;
  width: calc(100vw - 48px * 2);
  @media (max-width: 600px) {
    padding: 0 16px;
    width: calc(100vw - 16px * 2);
  }
`;
