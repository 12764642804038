import { Button, FormControl, Stack, styled } from "@mui/material";
import { useUnit } from "effector-react";
import { useFormik } from "formik";
import { noop, throttle, isEmpty } from "lodash-es";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import * as Yup from "yup";
import { resetStorages } from "~/api/storage.api";
import { WorkspaceUser } from "~/api/types";
import { addUserToWorkspace, deleteUserFromWorkspace, getWorkspaceUsers } from "~/api/workspace.api";
import { ManageProjectMembers } from "~/common/components";
import ConfirmationDialog from "~/common/components/ConfirmationDialog";
import { Trash } from "~/common/components/Icon";
import { palette } from "~/theme";
import IWorkspace from "~/types/IWorkspace";
import { $selectedWorkspace, deleteWorkspaceFx, updateWorkspaceFx } from "../models";
import { InputGroup, InputLabel, ManageAccessContainer, SectionName, StyledTextField } from "./components";
import { Title } from "./components/Title";

const throttledUpdateWorkspace = throttle((workspace: IWorkspace) => updateWorkspaceFx(workspace), 3000, {
  trailing: true,
});

const validationSchema = Yup.object({
  name: Yup.string().min(3).required("Name is required"),
  description: Yup.string().optional(),
});

export function EditWorkspace() {
  const selectedSpace = useUnit($selectedWorkspace);

  const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUser[]>([]);

  useEffect(() => {
    getWorkspaceUsers(selectedSpace?._id).then(({ status, body }) => {
      if (status === 200) {
        setWorkspaceUsers(body);
      }
    });
  }, [selectedSpace?._id]);

  const formik = useFormik({
    initialValues: {
      name: selectedSpace?.name ?? "",
      description: selectedSpace?.description ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: noop,
  });

  useUpdateEffect(() => {
    formik.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        const updatedWorkspace = {
          ...selectedSpace,
          name: formik.values.name,
          description: formik.values.description,
        };
        throttledUpdateWorkspace(updatedWorkspace);
      } else {
        formik.setErrors(errors);
      }
    });
  }, [formik.values]);

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [resetStoragesConfirmationDialog, setResetStoragesConfirmationDialog] = useState(false);

  if (!selectedSpace) {
    return null;
  }

  const handleUserAdd = async (email: string) => {
    const { status, body } = await addUserToWorkspace(selectedSpace._id, email);
    if (status === 200) {
      getWorkspaceUsers(selectedSpace?._id).then(({ status, body }) => {
        if (status === 200) {
          setWorkspaceUsers(body);
        }
      });
      return { success: true };
    } else {
      return { success: false, errorMessage: (body as any).message };
    }
  };

  const handleUserDelete = async (email: string) => {
    await deleteUserFromWorkspace(selectedSpace._id, email);
    getWorkspaceUsers(selectedSpace?._id).then(({ status, body }) => {
      if (status === 200) {
        setWorkspaceUsers(body);
      }
    });
  };

  return (
    <>
      <ConfirmationDialog
        open={deleteConfirmationDialogOpen}
        title="Confirm your action"
        content="This action cannot be reversed. Are you sure you want to remove this Project?"
        onConfirm={() => deleteWorkspaceFx(selectedSpace._id!)}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
      />
      <ConfirmationDialog
        open={resetStoragesConfirmationDialog}
        title="Confirm your action"
        content="It will reset all Storages and all Inventory Items. It will affect all users. Are you sure you want to reset it?"
        onConfirm={() => {
          resetStorages(selectedSpace._id!);
          setResetStoragesConfirmationDialog(false);
        }}
        onClose={() => setResetStoragesConfirmationDialog(false)}
      />
      <Title title="Project Settings" />
      <Stack gap="16px">
        <InputGroup>
          <InputLabel>Project Title</InputLabel>
          <FormControl error={Boolean(formik.errors.name)}>
            <StyledTextField
              placeholder="Enter Space Title"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.name)}
              helperText={formik.errors.name}
            />
          </FormControl>
        </InputGroup>
        <InputGroup>
          <InputLabel>Project Description</InputLabel>
          <FormControl error={Boolean(formik.errors.description)}>
            <StyledTextField
              placeholder="Enter description for your project"
              multiline
              name="description"
              minRows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.description)}
              helperText={formik.errors.description}
            />
          </FormControl>
        </InputGroup>
      </Stack>
      <Stack gap="16px">
        <SectionName>Members</SectionName>
        <ManageAccessContainer>
          <ManageProjectMembers members={workspaceUsers} onUserAdd={handleUserAdd} onUserDelete={handleUserDelete} />
        </ManageAccessContainer>
      </Stack>
      <Stack gap="12px" direction="row" onClick={() => setDeleteConfirmationDialogOpen(true)}>
        <DangerButton variant="outlined" fullWidth startIcon={<Trash size="small" color="primary.gray800" />}>
          Archive Project
        </DangerButton>
      </Stack>
      <Stack gap="12px" direction="row" onClick={() => setResetStoragesConfirmationDialog(true)}>
        <DangerButton variant="outlined" fullWidth startIcon={<Trash size="small" color="primary.gray800" />}>
          Reset all Storages and Inventory items
        </DangerButton>
      </Stack>
    </>
  );
}

const DangerButton = styled(Button)`
  background: ${palette.primary.red2000};
  border: 1px solid ${palette.primary.red600};
  color: ${palette.primary.gray800};

  &:hover {
    background-color: ${palette.primary.gray200};
  }
`;
