import { palette } from "~/theme";
import { css, Stack, styled, Typography } from "@mui/material";
import { useProfile } from "~/entities/profile";
import { User } from "../../Icon";
import { Navigate } from "../../Navigate";

export const UserAvatar = () => {
  const profile = useProfile((state) => state.profile);

  if (!profile) {
    return null;
  }

  const { name, avatarPreview } = profile;

  return (
    <Navigate to="/profile/settings" requireAuth={true}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          cursor: "pointer",
          borderRadius: 32,
          backgroundColor: palette.primary.gray100,
          maxWidth: "fit-content",
          color: palette.primary.white,
          fontSize: 16,
          transition: ".4s ease",
          backdropFilter: "blur(20px)",
          textTransform: "inherit",
          padding: "5px 38px 5px 5px",
          gridGap: "8px",
          fontWeight: 500,
          border: "1px solid " + palette.primary.gray100,
          "&:hover": {
            opacity: ".6",
          },
        }}
      >
        {avatarPreview ? <UserImage src={avatarPreview} /> : <UserIcon color="primary.white" />}
        <Stack direction="column">
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "30px",
              width: "100%",
              color: palette.primary.white,
            }}
            variant="body1"
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
    </Navigate>
  );
};

const userStyles = () => css`
  width: 60px;
  height: 60px;
  border: 1px solid ${palette.primary.black200};
  border-radius: 50%;
`;

const UserIcon = styled(User)`
  ${userStyles}
`;
const UserImage = styled("img")`
  ${userStyles}
`;
