import { ConeTwistOptions } from "~/types/IConstraintComponent";
import { LabelGroup } from "../LabelGroup";
import { ConstraintInspectorBaseProps } from "./types";
import { VectorField } from "../VectorField";

export type ConeTwistInspectorProps = ConstraintInspectorBaseProps<ConeTwistOptions>;

export const ConeTwistInspector = ({ value, onChange }: ConeTwistInspectorProps) => {
  const handleChange = (e: { target: { name: string; value: any } }) => {
    onChange?.({
      target: {
        name: "constraint",
        value: {
          ...value,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <>
      <LabelGroup label="Pivot">
        <VectorField name="pivot" value={value.pivot} onChange={handleChange} />
      </LabelGroup>
      <LabelGroup label="Target Pivot">
        <VectorField name="targetPivot" value={value.targetPivot} onChange={handleChange} />
      </LabelGroup>
    </>
  );
};
