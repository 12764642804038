import { memo, ReactNode, useRef } from "react";
import ITwitterBoardSceneObject from "../../../types/ITwitterBoardSceneObject";
import { ErrorBoundary } from "../../utils/ErrorBoundary";
import { Entity } from "../Entity";
import { TwitterBoard } from "./TwitterBoard";
import { useDisplayTweets } from "./models";
import { useOcclusionCulling } from "~/view-scene/occlusionCulling";
import { useEntity } from "~/view-scene/utils/useEntity";
import { Object3D } from "three";

type TwitterBoardEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const TwitterBoardEntity = memo(({ entityId }: TwitterBoardEntityProps) => {
  const entity = useEntity<ITwitterBoardSceneObject>(entityId);

  const ref = useRef<Object3D>(null);

  const displayTweets = useDisplayTweets({
    filter: entity.filter,
    amount: entity.amount,
    timeout: entity.timeout * 1000,
  });

  useOcclusionCulling(ref, entity);

  return (
    <Entity entityId={entityId} ref={ref}>
      <ErrorBoundary>
        <TwitterBoard dto={entity} tweets={displayTweets} />
      </ErrorBoundary>
    </Entity>
  );
});
