import { IconButton, IconButtonProps } from "./IconButton";
import { Tooltip, TooltipProps } from "@mui/material";

export type ToolbarIconButtonProps = IconButtonProps & Partial<Pick<TooltipProps, "title">>;

export const ToolbarIconButton = ({ title = "", ...rest }: ToolbarIconButtonProps) => (
  <Tooltip title={title}>
    <span>
      <IconButton {...rest} />
    </span>
  </Tooltip>
);
