import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, StringSocket } from "../../sockets";

export class NameNode extends BaseNode {
  static readonly type = "action/name";
  static readonly label = "Get Name";
  static readonly pure = true;

  inputs = [new EntitySocket("entity")];
  outputs = [new StringSocket("name")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const name = node.readEntity(context, "entity")?.name ?? "";

    context.writeOutput<string>("name", name);
  }
}
