import { BaseSocket } from "../base";
import { anyValue, AnyValue } from "../values";

export class AnySocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AnyValue = null) {
    super(anyValue.name, id, label, value);
  }

  clone() {
    return new AnySocket(this.id, this.label, this.value);
  }
}
