import { memo, ReactNode } from "react";
import { TextUIEntity } from "~/types/ui";
import { Text } from "./Text";
import { Color } from "three";

export type RenderUITextProps = {
  entity: TextUIEntity;
  children?: ReactNode;
  onClick?: (entityId: string) => void;
};

export const RenderUIText = memo(({ entity, children, onClick }: RenderUITextProps) => {
  const { id, name, enabled, content, font, fontSize, fontColor } = entity;

  if (!enabled) {
    return null;
  }

  return (
    <Text
      onClick={onClick ? () => onClick(id) : undefined}
      name={name}
      content={content}
      fontFamily={font}
      fontTexture={font}
      fontSize={fontSize}
      fontColor={new Color(fontColor)}
    >
      {children}
    </Text>
  );
});
