import { CSSProperties, HTMLProps, forwardRef } from "react";

export type StickProps = HTMLProps<HTMLDivElement>;

const stickStyles: CSSProperties = {
  boxSizing: "border-box",
  position: "absolute",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(255, 255, 255, 0.4)",
  border: `3px solid rgba(255, 255, 255, 0.6)`,
};

export const Stick = forwardRef<HTMLDivElement, StickProps>(({ className, style, ...restProps }, ref) => {
  return (
    <div
      className={["JoystickStick", className].join(" ")}
      style={{ ...stickStyles, ...style }}
      ref={ref}
      {...restProps}
    />
  );
});
