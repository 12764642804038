import { Box, FormControl, MenuItem, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAvailableSpeakerDevices } from "view-scene/utils/media/getAvailableSpeakerDevices";
import { palette } from "~/theme";
import { Switch } from "common/components/Switch";
import isMobile, { isMobileVR } from "common/utils/isMobile";
import Select from "common/components/Select";
import Button from "common/components/Button";
import useSessionStatus from "../../stores/useSessionStatus";

type SpeakersSetupProps = {
  selectedSpeakerDevice: string | null | undefined;
  selectSpeakerDevice: (selectedSpeakerDevice: string) => void;
};

export function SpeakersSetup({ selectedSpeakerDevice, selectSpeakerDevice }: SpeakersSetupProps) {
  const { disableSound, toggleDisableSound } = useSessionStatus((state) => ({
    disableSound: state.disableSound,
    toggleDisableSound: state.toggleDisableSound,
  }));

  const handleToggleDisableSound = () => toggleDisableSound();

  const [speakerDevices, setSpeakerDevices] = useState<{ value: string; label: string }[]>();

  const [audio] = useState(
    Object.assign(new Audio("/static/audio/test-audio.mp3"), {
      loop: true,
      playsInline: true,
      preload: "auto",
      crossOrigin: "anonymous",
    })
  );
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    getAvailableSpeakerDevices().then((speakerDevices) => setSpeakerDevices(speakerDevices));

    return () => {
      setIsPlaying(false);
      audio.pause();
    };
  }, []);

  const handlePauseAudio = () => {
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handlePlayAudio = async () => {
    if (!selectedSpeakerDevice) {
      return;
    }

    // Swithing the audio sync is only supported in Chrome at the time of writing this.
    // It also seems to have some limitations and it only works on audio elements. We are piping all our media
    // through the Audio Context and that doesn't seem to work.

    // To workaround that we need to use a MediaStreamAudioDestinationNode that is set as the source of the audio
    // element where we switch the sink. This is very hacky but there don't seem to have any better alternatives at
    // the time of writing this. https://stackoverflow.com/a/67043782
    try {
      // @ts-ignore
      await audio.setSinkId(selectedSpeakerDevice);
    } catch (e) {
      console.log("setSinkId is not available");
    }
    setIsPlaying(true);
    await audio.play();
  };

  const handleSpeakerChange = (event: any) => {
    const speakerDeviceId = event.target.value;
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    }
    selectSpeakerDevice(speakerDeviceId);
  };

  const playPauseButtonClick = isPlaying ? handlePauseAudio : handlePlayAudio;
  const playPauseButtonText = isPlaying ? "Pause Audio" : "Play Audio";

  return (
    <>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "28px",
          width: "100%",
          display: "block",
          textTransform: "uppercase",
          color: palette.primary.gray600,
        }}
        variant="body1"
      >
        Audio
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          marginBottom: "12px",
        }}
        justifyContent="space-between"
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            width: "100%",
            color: palette.primary.white,
          }}
          variant="body1"
        >
          Speaker
        </Typography>
        <Switch checked={!disableSound} onChange={handleToggleDisableSound} />
      </Stack>
      {!isMobile() && !isMobileVR() && (
        <>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: "12px",
            }}
          >
            {speakerDevices && (
              <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
                <FormControl fullWidth>
                  <Select
                    value={selectedSpeakerDevice}
                    onChange={handleSpeakerChange}
                    sx={{
                      maxHeight: "24px",
                      fontSize: "12px",
                      backgroundColor: palette.primary.gray100,
                      border: "1px solid " + palette.primary.gray400,
                    }}
                  >
                    {speakerDevices.map((device) => (
                      <MenuItem value={device.value} key={device.label}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box component="div">
              <Button
                onClick={playPauseButtonClick}
                sx={{
                  fontSize: "12px",
                  padding: "1.5px 10px",
                  border: "0px",
                }}
              >
                {playPauseButtonText}
              </Button>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
}
