import { cn } from "bem-cn-css-modules";
import { useStore } from "effector-react";
import Muuri from "muuri";
import { useEffect, useMemo, useRef } from "react";
import { Game } from "~/common/data/games";
import { GameCard } from "../GameCard";
import { $categoryFilter } from "../models";
import styles from "./Grid.module.css";

const cnGrid = cn("Grid", styles);

export type GridProps = {
  games: Game[];
  className?: string;
  muuriRef?: React.MutableRefObject<Muuri | null>;
  onGameClick?: (game: Game) => void;
};

export const Grid = ({ games, className, muuriRef, onGameClick }: GridProps) => {
  const categoryFilter = useStore($categoryFilter);
  const containerRef = useRef<HTMLDivElement>(null);
  const ownMuuriRef = useRef<Muuri | null>(null);

  useEffect(() => {
    ownMuuriRef.current = new Muuri(containerRef.current!, {
      items: `.${cnGrid("Item")}`,
      layout: { fillGaps: true },
    });
    if (muuriRef) {
      muuriRef.current = ownMuuriRef.current;
    }

    ownMuuriRef.current.layout();

    return () => {
      ownMuuriRef.current?.destroy();
    };
  }, []);

  useEffect(() => {
    ownMuuriRef.current?.filter((item) => {
      if (!categoryFilter) {
        return true;
      }

      return item.getElement()?.dataset.categories?.includes(categoryFilter) ?? false;
    });
  }, [categoryFilter]);

  const render = useMemo(() => {
    return (
      <div className={cnGrid(null, [className])} ref={containerRef}>
        {games.map((game) => (
          <GameCard className={cnGrid("Item")} key={game.id} game={game} onClick={onGameClick} />
        ))}
      </div>
    );
  }, []);

  return render;
};
