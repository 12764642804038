import { combine, createEffect, createEvent, createStore, forward } from "effector";
import IWorkspace from "~/types/IWorkspace";
import { CreateWorkspaceBody } from "~/api/types";
import { createWorkspace, deleteWorkspace, geUserWorkspaces, updateWorkspace } from "~/api/workspace.api";

export const loadWorkspacesFx = createEffect(async () => {
  const { status, body: workspaces } = await geUserWorkspaces();

  if (status !== 200) {
    throw new Error("Not found");
  }

  return workspaces as unknown as IWorkspace[];
});

export const $workspaces = createStore<IWorkspace[] | null>(null).on(
  loadWorkspacesFx.doneData,
  (_, payload) => payload
);

export const setSelectedWorkspaceId = createEvent<string | null>();

export const $selectedWorkspaceId = createStore<string | null>(null);
$selectedWorkspaceId.on(setSelectedWorkspaceId, (_, id) => id);

export const $selectedWorkspace = combine(
  $workspaces,
  $selectedWorkspaceId,
  (workspaces, selectedWorkspace) => workspaces?.find((w) => w._id === selectedWorkspace) ?? null
);

export const setSearchQuery = createEvent<string | null>();

export const setActiveLayout = createEvent<"myScenes" | "tutorials" | "workspace" | "createSpace" | "editSpace">();

export const $searchQuery = createStore<string | null>(null);
$searchQuery.on(setSearchQuery, (_, query) => query);
$searchQuery.on(setSelectedWorkspaceId, () => null);
$searchQuery.on(setActiveLayout, () => null);

export const $activeLayout = createStore<"myScenes" | "tutorials" | "workspace" | "createSpace" | "editSpace">(
  "myScenes"
);
$activeLayout.on(setActiveLayout, (_, layout) => layout);

export const createWorkspaceFx = createEffect(async (requestBody: CreateWorkspaceBody): Promise<IWorkspace> => {
  const { body } = await createWorkspace(requestBody);
  return body as IWorkspace;
});

export const updateWorkspaceFx = createEffect(async (requestBody: IWorkspace): Promise<IWorkspace> => {
  const { body } = await updateWorkspace(requestBody._id!, requestBody);
  return body as IWorkspace;
});

export const deleteWorkspaceFx = createEffect(async (id: string): Promise<void> => {
  await deleteWorkspace(id);
});

forward({
  from: [createWorkspaceFx.doneData, deleteWorkspaceFx.doneData, updateWorkspaceFx.doneData],
  to: loadWorkspacesFx,
});

forward({
  from: createWorkspaceFx.doneData,
  to: [
    setSelectedWorkspaceId.prepend((workspace) => workspace._id ?? null),
    setActiveLayout.prepend((workspace) => {
      if (workspace._id) {
        return "workspace";
      } else {
        return "myScenes";
      }
    }),
  ],
});

forward({
  from: deleteWorkspaceFx.doneData,
  to: [setSelectedWorkspaceId.prepend(() => null), setActiveLayout.prepend(() => "myScenes")],
});
