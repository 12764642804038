import { memo, MutableRefObject, RefObject } from "react";
import { Group } from "three";
import { TweenAnimationComponent as TweenAnimationComponentDescriptor } from "~/types/component";
import type { EntityContext } from "~/view-scene/runtime";
import { RenderTweenAnimation } from "./RenderTweenAnimation";

type TweenAnimationComponentProps = {
  componentDto: TweenAnimationComponentDescriptor;
  entityId: string;
  objectRef: RefObject<Group>;
  contextRef: MutableRefObject<EntityContext>;
};

export const TweenAnimationComponent = memo(
  ({ componentDto, entityId, objectRef, contextRef }: TweenAnimationComponentProps) => {
    return (
      <RenderTweenAnimation
        component={componentDto}
        entityId={entityId}
        objectRef={objectRef}
        contextRef={contextRef}
      />
    );
  }
);
