import { CSSProperties, HTMLProps, forwardRef } from "react";

export type CircleProps = HTMLProps<HTMLDivElement>;

const circleStyles: CSSProperties = {
  position: "fixed",
  boxSizing: "border-box",
  borderRadius: "50%",
  background: "rgba(0, 0, 0, 0.0)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `3px solid rgba(255, 255, 255, 0.4)`,
};

export const Circle = forwardRef<HTMLDivElement, CircleProps>(({ style, className, ...restProps }, ref) => {
  return (
    <div
      className={["JoystickCircle", className].join(" ")}
      style={{ ...circleStyles, ...style }}
      {...restProps}
      ref={ref}
    />
  );
});
