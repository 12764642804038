import { createContext } from "react";
import { toHierarchyIds } from "~/common/utils/toHierarchyIds";

export type UIEntitiesContextValue = {
  hierarchy: ReturnType<typeof toHierarchyIds>;
  entitySizes: Map<string, { x: number; y: number }>;
  setEntitySize: (entityId: string, size: { x: number; y: number }) => void;
};

export const UIEntitiesContext = createContext<UIEntitiesContextValue>(null!);
