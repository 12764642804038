import { Box, Button, Stack, Tooltip } from "@mui/material";
import { palette } from "~/theme";
import { PrivateComponent, Right, useProfile } from "~/entities/profile";
import { HOST } from "~/config";
import { DesktopHeaderMenuItem } from "./DesktopHeaderMenuItem";
import { Logo } from "../../Logo";
import { Container } from "../../Container";
import { Navigate } from "../../Navigate";
import { LoginButton } from "../../LoginButton";
import { ProfileButton } from "../../ProfileButton";

export const DesktopHeader = () => {
  const { isLoggedIn, emailConfirmed } = useProfile((state) => ({
    isLoggedIn: state.isAuthorised(),
    emailConfirmed: state.profile?.emailConfirmed ?? false,
  }));

  return (
    <Box
      component="div"
      sx={{
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 8,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap="20px"
        minHeight="60px"
        padding="5px 0"
        bgcolor={palette.primary.dark}
      >
        <Container>
          <Stack alignItems="center" direction="row" justifyContent="space-between" gap="20px">
            <Stack direction="row" alignItems="center" gap="33px">
              <Logo />
              <Navigate to="/">
                <DesktopHeaderMenuItem text="Explore" />
              </Navigate>
              {HOST === "8xr" && (
                <Stack alignItems="center" direction="row" gap="33px">
                  <PrivateComponent accessRight={Right.admin}>
                    <Navigate to="/users" requireAuth={true}>
                      <DesktopHeaderMenuItem text="Users" />
                    </Navigate>
                  </PrivateComponent>
                  <PrivateComponent accessRight={Right.admin}>
                    <Navigate to="/telegram" requireAuth={true}>
                      <DesktopHeaderMenuItem text="Telegram" />
                    </Navigate>
                  </PrivateComponent>
                  <PrivateComponent accessRight={Right.admin}>
                    <Navigate to="/telegram-ads" requireAuth={true}>
                      <DesktopHeaderMenuItem text="Telegram Ads" />
                    </Navigate>
                  </PrivateComponent>
                  <PrivateComponent accessRight={Right.admin}>
                    <Navigate to="/reports" requireAuth={true}>
                      <DesktopHeaderMenuItem text="Reports" />
                    </Navigate>
                  </PrivateComponent>
                  <Navigate to="https://engine.8xr.io/" openInNewWindow={true}>
                    <DesktopHeaderMenuItem text="About" />
                  </Navigate>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              {isLoggedIn ? (
                <>
                  {HOST === "8xr" &&
                    (emailConfirmed ? (
                      <Navigate to="/projects" requireAuth={true}>
                        <Button variant="outlined" size="large">
                          My Projects
                        </Button>
                      </Navigate>
                    ) : (
                      <Tooltip title="Confirm your email to become a Creator" placement="bottom">
                        <span>
                          <Button variant="outlined" size="large" disabled>
                            My Projects
                          </Button>
                        </span>
                      </Tooltip>
                    ))}
                  <ProfileButton />
                </>
              ) : (
                <LoginButton />
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};
