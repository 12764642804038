import { $audioManager } from "./audioManager";
import { attach } from "effector";
import { removeStreamFromOutboundAudio } from "./removeStreamFromOutboundAudio";

type AddStreamToOutboundAudioPayload = {
  id: string;
  mediaStream: MediaStream;
};

export const addStreamToOutboundAudio = attach({
  source: $audioManager,
  effect: async (
    { audioContext, audioNodes, outboundGainNode },
    { id, mediaStream }: AddStreamToOutboundAudioPayload
  ) => {
    if (audioNodes?.has(id)) {
      await removeStreamFromOutboundAudio(id);
    }

    const sourceNode = audioContext?.createMediaStreamSource(mediaStream);
    const gainNode = audioContext?.createGain();
    if (sourceNode && gainNode) {
      sourceNode.connect(gainNode);
      if (outboundGainNode) {
        gainNode.connect(outboundGainNode);
      }
      audioNodes.set(id, { sourceNode, gainNode });
    }
  },
});
