import {
  generateAmbientLightEntity,
  generateDirectionalLightEntity,
} from "~/hephaestus/SceneEditor/entities/light/utils";
import { generatePlaneEntity } from "~/hephaestus/SceneEditor/entities/primitive/utils";
import { generateSpawnPointEntity } from "~/hephaestus/SceneEditor/entities/spawnPoint/utils";
import { generateRigidBodyComponent } from "~/hephaestus/SceneEditor/entities/entityComponents/rigidBody/utils";
import { generatePlayerEntity } from "~/hephaestus/SceneEditor/entities/player/utils";

// TODO: find a better place for this function. It definitely shouldn't be here
export const generateDefaultScene = (workspaceId?: string | null) => {
  const player = defaultPlayer();
  const ambientLightEntity = defaultAmbientLightEntity();
  const directionalLight = defaultDirectionalLightEntity();
  const plane = defaultPlane();
  const spawnPoint = defaultSpawnPoint();

  return {
    name: "Unnamed scene",
    urlName: Math.random().toString(36).slice(2),
    description: "",
    deviceOptimisedVersionSupported: false,
    skybox: null,
    environment: null,
    workspace: workspaceId,
    objects: [player, ambientLightEntity, directionalLight, plane, spawnPoint],
    backgroundColor: {
      enabled: false,
      color: "#fff",
    },
    fog: {
      type: "none",
      color: "#fff",
      near: 1,
      far: 10,
      density: 0.00025,
    },
    postprocessing: {
      enabled: false,
      effects: [],
    },
    state: "draft",
    authors: [],
    targetPlatforms: [],
  };
};

const defaultAmbientLightEntity = () => {
  const entity = generateAmbientLightEntity();
  entity.light.intensity = 0.3;

  return {
    ...entity,
    order: getNextEntityOrder(),
  };
};

const defaultDirectionalLightEntity = () => {
  const entity = generateDirectionalLightEntity();
  entity.position = {
    x: 0,
    y: 10,
    z: 10,
  };
  entity.light.intensity = 0.8;

  return {
    ...entity,
    order: getNextEntityOrder(),
  };
};

const defaultPlane = () => {
  const entity = generatePlaneEntity();
  const rigidBody = generateRigidBodyComponent();

  entity.components.push(rigidBody);

  return {
    ...entity,
    geometry: {
      ...entity.geometry,
      width: 10,
      height: 10,
    },
    order: getNextEntityOrder(),
  };
};

const defaultSpawnPoint = () => {
  const entity = generateSpawnPointEntity();
  entity.rotation = {
    x: 0,
    y: Math.PI,
    z: 0,
  };

  return {
    ...entity,
    order: getNextEntityOrder(),
  };
};

const defaultPlayer = () => {
  return {
    ...generatePlayerEntity(),
    order: getNextEntityOrder(),
  };
};

let prevOrder = Number.MAX_SAFE_INTEGER / 100000000;
const getNextEntityOrder = () => {
  prevOrder *= 2;

  return prevOrder;
};
