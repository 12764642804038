import { Object3D, Vector3, WebGLRenderer } from "three";
import { UpdateAvatarPositionMessage } from "~/view-scene/network";
import { PlayerContext } from "~/view-scene/runtime";
import { zeroVector3 } from "./constants";
import { XRController } from "@react-three/xr";

export const updateArray3FromVector3 = (
  array3: [number, number, number],
  vector3: { x: number; y: number; z: number }
) => {
  array3[0] = vector3.x;
  array3[1] = vector3.y;
  array3[2] = vector3.z;
};

const playerPosition = new Vector3();

export const applyBaseData = (
  target: UpdateAvatarPositionMessage,
  player: Object3D,
  playerContext: PlayerContext | null,
  camera: Object3D
) => {
  playerPosition.copy(player.position);
  const cameraRotation = camera.rotation;

  updateArray3FromVector3(target.position, playerPosition);
  updateArray3FromVector3(target.head.rotation, cameraRotation);

  target.head.position = zeroVector3;
  target.velocity = playerContext?.getPhysicsBody()?.leanerVelocity ?? zeroVector3;
};

export const applyXRData = (
  target: UpdateAvatarPositionMessage,
  gl: WebGLRenderer,
  xrFrame: any,
  leftController?: XRController,
  rightController?: XRController
) => {
  if (!gl.xr.isPresenting || !xrFrame) {
    const referenceSpace = gl.xr.getReferenceSpace();
    if (referenceSpace) {
      const xrRigidTransform = xrFrame.getViewerPose(referenceSpace)?.transform;
      if (xrRigidTransform) {
        updateArray3FromVector3(target.head.position, xrRigidTransform.position);
      }
    }

    if (leftController) {
      const controller = leftController.controller;
      if (!target.leftController) {
        target.leftController = {
          position: [...zeroVector3],
          rotation: [...zeroVector3],
        };
      }
      updateArray3FromVector3(target.leftController.position, controller.position);
      updateArray3FromVector3(target.leftController.rotation, controller.rotation);
    }

    if (rightController) {
      const controller = rightController.controller;
      if (!target.rightController) {
        target.rightController = {
          position: [...zeroVector3],
          rotation: [...zeroVector3],
        };
      }
      updateArray3FromVector3(target.rightController.position, controller.position);
      updateArray3FromVector3(target.rightController.rotation, controller.rotation);
    }
  }
};
