import { MeshReflectorMaterial } from "@react-three/drei";
import IMirrorSceneObject from "~/types/IMirrorSceneObject";

export type RenderMirrorProps = {
  dto: IMirrorSceneObject;
  resolution?: number;
};

export function RenderMirror({ dto, resolution = 1024 }: RenderMirrorProps) {
  const { isStatic, width, height, color } = dto;

  return (
    <mesh matrixAutoUpdate={!isStatic}>
      <planeGeometry args={[width, height]} />
      <MeshReflectorMaterial resolution={resolution} mirror={1} color={color} />
    </mesh>
  );
}
