import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";
import { CameraControlsImpl } from "./CameraControlsImpl";
import { KeyboardKeyHold } from "hold-event";
import { EventDispatcher, OrthographicCamera } from "three";

interface DispatcherEvent {
  type: string;

  [key: string]: any;
}

const KEYCODE = {
  W: 87,
  A: 65,
  S: 83,
  D: 68,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
};

const wKey = new KeyboardKeyHold(KEYCODE.W, 16.666);
const aKey = new KeyboardKeyHold(KEYCODE.A, 16.666);
const sKey = new KeyboardKeyHold(KEYCODE.S, 16.666);
const dKey = new KeyboardKeyHold(KEYCODE.D, 16.666);

type OrthographicCameraControlsProps = {
  camera: OrthographicCamera;
  enabled?: boolean;
};

export function OrthographicCameraControls({ camera, enabled = true }: OrthographicCameraControlsProps) {
  const { gl, set } = useThree();

  const controls = useMemo(() => {
    const controls = new CameraControlsImpl(camera, gl.domElement);
    controls.azimuthRotateSpeed = 0;
    controls.polarRotateSpeed = 0;
    controls.mouseButtons.left = CameraControlsImpl.ACTION.NONE;
    controls.mouseButtons.right = CameraControlsImpl.ACTION.TRUCK;
    controls.mouseButtons.wheel = CameraControlsImpl.ACTION.ZOOM;
    return controls;
  }, [camera]);

  useEffect(() => {
    set({ controls: controls as unknown as EventDispatcher });
  }, [controls]);

  useEffect(() => {
    if (controls) {
      controls.enabled = enabled;
    }
  }, [controls, enabled]);

  useEffect(() => {
    const aKeyCb = (e: DispatcherEvent | undefined) => controls?.truck(-0.01 * e?.deltaTime, 0, false);
    aKey.addEventListener("holding", aKeyCb);

    const dKeyCb = (e: DispatcherEvent | undefined) => controls?.truck(0.01 * e?.deltaTime, 0, false);
    dKey.addEventListener("holding", dKeyCb);

    const wKeyCb = (e: DispatcherEvent | undefined) => controls?.truck(0, -0.01 * e?.deltaTime, false);
    wKey.addEventListener("holding", wKeyCb);

    const sKeyCb = (e: DispatcherEvent | undefined) => controls?.truck(0, 0.01 * e?.deltaTime, false);
    sKey.addEventListener("holding", sKeyCb);

    return () => {
      aKey.removeEventListener("holding", aKeyCb);
      dKey.removeEventListener("holding", dKeyCb);
      wKey.removeEventListener("holding", wKeyCb);
      sKey.removeEventListener("holding", sKeyCb);
    };
  }, [controls]);

  useFrame((_, delta) => controls?.update(delta));

  return <primitive object={controls} />;
}
