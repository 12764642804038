import { Role, Right } from "./types";

export const roleToRight = new Map([
  [Role.user, [Right.authorisedUser, Right.manageScenes, Right.manageAssets]],
  [
    Role.admin,
    [
      Right.admin,
      Right.authorisedUser,
      Right.manageScenes,
      Right.manageAssets,
      Right.manageUser,
      Right.manageWorkspaces,
    ],
  ],
]);
