import { NodeContext } from "../../base";
import { BitwiseOperationNode } from "./BitwiseOperationNode";

export class BitwiseAndNode extends BitwiseOperationNode {
  static readonly type = "logic/bitwiseAnd";
  static readonly label = "Bitwise AND";

  constructor(context: NodeContext) {
    super(context, (a, b) => a & b);
  }
}
