import { NodeEvalContext } from "~/libs/behave-graph";
import { ModelContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { AssetModelSocket, FlowSocket, ModelSocket } from "../../sockets";
import { AssetModelValue } from "../../values";

export class ModelSetModelNode extends BaseNode {
  static readonly type = "action/ModelSetModel";
  static readonly label = "Set model asset";

  inputs = [new FlowSocket("flow"), new ModelSocket("model"), new AssetModelSocket("assetModel")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const model = node.readEntity<ModelContext>(context, "model");
    const assetModel = context.readInput<AssetModelValue>("assetModel");

    model?.setModel(assetModel.assetId, assetModel.variantId ?? null);
  }
}
