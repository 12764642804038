import { Icon, IconProps } from "./Icon";

export type JoystickMoveProps = IconProps;

export const JoystickMove = (props: JoystickMoveProps) => (
  <Icon width="36px" height="36px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <path
      fill="currentcolor"
      d="M14.425 4h7.15a.5.5 0 0 0 .312-.89L18.312.25a.5.5 0 0 0-.624 0l-3.575 2.86a.5.5 0 0 0 .312.89ZM14.425 32h7.15a.5.5 0 0 1 .312.89l-3.575 2.86a.5.5 0 0 1-.624 0l-3.575-2.86a.5.5 0 0 1 .312-.89ZM32 14.425v7.15a.5.5 0 0 0 .89.312l2.86-3.575a.5.5 0 0 0 0-.624l-2.86-3.575a.5.5 0 0 0-.89.312ZM4 21.575v-7.15a.5.5 0 0 0-.89-.312L.25 17.688a.5.5 0 0 0 0 .624l2.86 3.575a.5.5 0 0 0 .89-.312Z"
    />
  </Icon>
);
