import { encodeUIEntityId } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { UIEntitySocket } from "../../sockets";
import { UIEntityValue } from "../../values";

export class SceneUIEntityNode extends BaseNode {
  static readonly type: string = "variable/sceneUIEntity";
  static readonly label: string = "Scene UI Entity";
  static readonly category = "Variable";

  outputs = [new UIEntitySocket("uiEntity")];

  initNode(node: BaseBehaveNode) {
    const entityId = node.spec.metadata?.entityId as string | undefined;
    const uiEntityId = node.spec.metadata?.uiEntityId as string | undefined;

    const id =
      entityId && uiEntityId
        ? encodeUIEntityId({
            type: "scene",
            entityId,
            uiEntityId,
          })
        : null;

    node.storage.output = {
      entityId: id,
    };
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    context.writeOutput<UIEntityValue>("uiEntity", node.storage.output);
  }
}
