import { memo, useEffect, useRef } from "react";
import { PositionalAudio } from "three";
import { useStoreMap } from "effector-react";
import { $audioManager, addAudio, removeAudio } from "~/view-scene/audio";
import { $webRTCConnections } from "../../models";

type NearbyRemoteUserAudioProps = {
  userId: string;
};

export const NearbyRemoteUserAudio = memo(({ userId }: NearbyRemoteUserAudioProps) => {
  const audioStream = useStoreMap($webRTCConnections, (webRTCConnections) =>
    webRTCConnections
      .find((webRTCConnection) => webRTCConnection.id === userId)
      ?.streams.find((stream) => stream.getAudioTracks().length > 0)
  );

  const audioListener = useStoreMap($audioManager, (audioManager) => audioManager.audioListener)!;

  const positionalAudioRef = useRef<PositionalAudio>(null);

  useEffect(() => {
    const positionalAudio = positionalAudioRef.current;
    if (!positionalAudio || !audioStream) {
      return;
    }

    positionalAudio.setMediaStreamSource(audioStream);
    positionalAudio.setVolume(1);
    positionalAudio.panner.panningModel = "equalpower";

    addAudio({ sourceType: "remoteUserAudioSource", node: positionalAudio });

    return () => {
      removeAudio(positionalAudio);
    };
  }, [audioStream]);

  if (!audioStream) {
    return null;
  }

  return <positionalAudio args={[audioListener]} ref={positionalAudioRef} />;
});
