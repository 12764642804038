import { palette } from "~/theme";
import { css, Stack, styled } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useProfile } from "~/entities/profile";
import { LogoutButton } from "../../LogoutButton";
import { Navigate } from "../../Navigate";
import { Inst, Twitter } from "../../Icon";
import { Container } from "../../Container";
import { UserAvatar } from "./UserAvatar";
import { MobileMenuHeaderItem } from "./MobileMenuHeaderItem";

type MobileMenuProps = {
  open: boolean;
  onClose: VoidFunction;
};

export function MobileMenu({ open, onClose }: MobileMenuProps) {
  const { isAuthorised, logOut } = useProfile((state) => ({
    isAuthorised: state.isAuthorised(),
    logOut: state.logOut,
  }));

  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenLoginDialogClick = () => {
    onClose();

    const param = searchParams.get("auth");
    if (!param) {
      searchParams.append("auth", "login");
      setSearchParams(searchParams);
    }
  };

  const menuStyles = open
    ? {
        transitionDelay: "0.005s",
        transform: "translateY(0)",
      }
    : {
        transform: "translateY(120%)",
      };

  return (
    <MenuContainer sx={{ ...menuStyles }}>
      <Stack flexGrow="0.5" justifyContent="center">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="60px 0 20px 0"
          sx={{
            borderBottom: "1px solid " + palette.primary.gray200,
          }}
        >
          {isAuthorised && (
            <>
              <UserAvatar />
              <LogoutButton onLogout={logOut} />
            </>
          )}
        </Stack>

        {!isAuthorised && <MobileMenuHeaderItem onClick={handleOpenLoginDialogClick}>Log In</MobileMenuHeaderItem>}

        <Navigate to="/" openInNewWindow={true}>
          <MobileMenuHeaderItem>Explore</MobileMenuHeaderItem>
        </Navigate>

        <Navigate to="/profile/settings">
          <MobileMenuHeaderItem>Profile</MobileMenuHeaderItem>
        </Navigate>

        <Navigate to="https://engine.8xr.io/" openInNewWindow={true}>
          <MobileMenuHeaderItem>About</MobileMenuHeaderItem>
        </Navigate>
      </Stack>
      <Stack direction="row" justifyContent="center" flexGrow="0.5" gap="20px" alignItems="center">
        <Navigate to="https://twitter.com/8xr_engine" openInNewWindow={true}>
          <TwitterIcon />
        </Navigate>
        <Navigate to="https://www.instagram.com/8xr.engine/" openInNewWindow={true}>
          <InstagramIcon />
        </Navigate>
      </Stack>
    </MenuContainer>
  );
}

const MenuContainer = styled(Container)`
  position: fixed;
  top: 59px;
  left: 0;
  transition: 0.5s ease;
  height: calc(100vh - 59px);
  background-color: ${palette.primary.dark};
`;

const iconStyles = () => css`
  color: ${palette.primary.white};
  width: 36px;
  height: 36px;
  transition: 0.4s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const TwitterIcon = styled(Twitter)`
  ${iconStyles};
`;

const InstagramIcon = styled(Inst)`
  ${iconStyles};
`;
