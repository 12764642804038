import { memo, ReactNode, useEffect, useRef } from "react";
import { Group } from "three";
import { IKillVolumeSceneObject } from "~/types/IKillVolumeSceneObject";
import { usePhysics } from "~/view-scene/physics";
import { useEntity } from "~/view-scene/utils/useEntity";
import {
  EntityContext,
  getPlayerSpawnTransform,
  getSpawnPoint,
  PlayerContext,
  subscribeCollisions,
  useEntityManager,
} from "~/view-scene/runtime";
import { Entity } from "./Entity";
import { setTheta } from "../CameraSystem";

type KillVolumeEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const KillVolumeEntity = memo(({ entityId, children }: KillVolumeEntityProps) => {
  const entity = useEntity<IKillVolumeSceneObject>(entityId);

  const entityRef = useRef<Group>(null);
  const context = useRef<EntityContext>({} as EntityContext);
  const physicsManager = usePhysics((state) => state.physicsManager);
  const getEntityContext = useEntityManager((state) => state.getEntityContext);

  useEffect(() => {
    if (!physicsManager || !entityRef.current) {
      return;
    }

    entityRef.current.userData["eightXRId"] = entity.id;
    const body = physicsManager.addBody(
      entityRef.current,
      { entityId, rigidBodyId: "root" },
      {
        type: entity.rigidBodyType,
        ghost: true,
        shape: entity.shape,
        collisionGroup: entity.collisionGroup,
        collisionMask: entity.collisionMask,
      }
    );

    context.current.physicsBodies["root"] = body;
    subscribeCollisions(context);

    if (body) {
      const startHandler = ({ object }: any) => {
        const entityId = object.userData.eightXRId;
        const entityContext = getEntityContext(entityId);

        if (entityContext?.current?.type === "player") {
          const playerContext = entityContext.current as PlayerContext;
          const spawnPoint = getSpawnPoint();
          const { position, rotation } = getPlayerSpawnTransform(spawnPoint, playerContext.getYOffset() ?? 0);
          playerContext.teleport(position);
          setTheta(Math.PI + rotation.y);
        }
      };

      body.on("collisionStart", startHandler);

      return () => {
        body.off("collisionStart", startHandler);
        physicsManager.removeBody(body.uid);
      };
    }
  }, [physicsManager]);

  return (
    <Entity entityId={entityId} ref={entityRef} context={context}>
      {children}
    </Entity>
  );
});
