import { createEvent, createStore } from "effector";
import { ToastType } from "~/types/ToastType";

export type ToastState = {
  isOpen: boolean;
  title: string;
  timeout?: number;
  isLoading?: boolean;
  type?: ToastType;
  content?: string;
};

export const $toast = createStore<ToastState>({
  isOpen: false,
  timeout: 3000,
  type: "info",
  title: "",
});

export const showToast = createEvent<Partial<ToastState>>();
export const hideToast = createEvent();

$toast
  .on(showToast, (state, { type = "info", timeout = 3000, title = "", isLoading, content }) => {
    if (state.isLoading && isLoading === undefined) return state;

    if (timeout && !isLoading) {
      setTimeout(() => hideToast(), timeout);
    }

    return {
      ...state,
      type,
      title,
      content,
      isLoading,
      isOpen: isLoading ?? true,
    };
  })
  .on(hideToast, (state) => ({
    ...state,
    isLoading: false,
    isOpen: false,
  }));
