import { Stack, StackProps, Typography } from "@mui/material";
import { ReactNode } from "react";
import { palette } from "~/theme";

export type SectionHeaderProps = Pick<StackProps, "sx" | "className" | "style"> & {
  title: string | ReactNode;
  suffix?: ReactNode;
};

export const SectionHeader = ({ title, suffix = null, sx, className, style }: SectionHeaderProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    className={className}
    style={style}
    sx={{
      padding: "8px 10px 4px 10px",
      position: "relative",
      cursor: "default",
      borderTop: "1px solid " + palette.primary.gray40,
      gridGap: "10px",
      marginBottom: "-4px",
      ...sx,
    }}
  >
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        gridGap: "8px",
      }}
    >
      {typeof title === "string" ? (
        <Typography fontWeight="600" variant="body1">
          {title}
        </Typography>
      ) : (
        title
      )}
    </Stack>
    {suffix}
  </Stack>
);
