export const difference = <TFields extends Record<string, any>>(a: TFields, b: TFields) => {
  const result: TFields = {} as TFields;

  for (const key in a) {
    if (a[key] !== b[key]) {
      result[key] = a[key];
    }
  }

  return result;
};
