import { NodeCategory, NodeEvalContext } from "~/libs/behave-graph";
import { BaseNode } from "../../base";
import { NumberSocket } from "../../sockets";

export class NumberConstantNode extends BaseNode {
  static readonly type = "logic/numberConstant";
  static readonly label = "Number";
  static readonly category: NodeCategory = "Variable";

  inputs = [new NumberSocket("a")];
  outputs = [new NumberSocket("value")];

  eval(context: NodeEvalContext) {
    const num = context.readInput<number>("a");
    context.writeOutput<number>("value", num);
  }
}
