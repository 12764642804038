import { attach, createEvent, createStore } from "effector";
import { rewardedVideoEvents } from "./events";
import { BaseAdsProvider } from "./providers";
import { info } from "~/entities/logs";
import { useProfile } from "~/entities/profile";

export const setProviders = createEvent<BaseAdsProvider[]>();
export const $providers = createStore<BaseAdsProvider[]>([]).on(setProviders, (_, providers) => providers);

export const onLoadStart = createEvent();
export const onLoadEnd = createEvent();

export const sendInfo = info.prepend((message: Object) => ({
  ...message,
  type: "rewardedVideo",
  userId: useProfile.getState().profile?.id ?? "unknown",
}));

export const watchRewardedVideo = attach({
  source: $providers,
  effect: (providers) => {
    if (providers.length === 0) {
      return;
    }

    onLoadStart();
    let index = 0;

    const next = () => {
      index++;
      watchNext();
    };

    const watchNext = async () => {
      if (index >= providers.length) {
        rewardedVideoEvents.emit("noAds");
        onLoadEnd();
        return;
      }

      const provider = providers[index];

      if (!(await provider.hasAds())) {
        next();
        return;
      }

      const unsubscribe = () => {
        provider.off("finished", finishHandler);
        provider.off("error", errorHandler);
      };

      const finishHandler = (viewed: boolean) => {
        unsubscribe();
        rewardedVideoEvents.emit("rewardedVideoWatched", viewed);
      };

      const errorHandler = () => {
        unsubscribe();
        next();
      };

      provider.on("finished", finishHandler);
      provider.on("error", errorHandler);

      provider.start();
      onLoadEnd();
    };

    watchNext();
  },
});
