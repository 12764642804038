import { palette } from "~/theme";
import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type PanelHeaderProps = {
  label?: string;
  icon?: ReactNode;
};

export function PanelHeader({ label = "Inspector", icon }: PanelHeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        gridGap: "8px",
      }}
    >
      {icon}
      <Typography
        sx={(theme) => ({
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          width: "100%",
          color: palette.primary.white,
          [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
          },
          textTransform: "capitalize",
        })}
        variant="body1"
      >
        {label}
      </Typography>
    </Stack>
  );
}
