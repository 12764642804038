import { memo, ReactNode } from "react";
import IInstancedMeshSceneObject from "../../../types/IInstancedMeshSceneObject";
import { Entity } from "../Entity";
import { InstancedModel } from "./InstancedModel";
import { useEntity } from "~/view-scene/utils/useEntity";

type InstancedMeshEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const InstancedModelEntity = memo(({ entityId }: InstancedMeshEntityProps) => {
  const entity = useEntity<IInstancedMeshSceneObject>(entityId);

  return (
    <Entity entityId={entityId}>
      <InstancedModel entity={entity} />
    </Entity>
  );
});
