import { MathUtils } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

export class AddRotationNode extends BaseNode {
  static readonly type = "action/addRotation";
  static readonly label = "Add rotation";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new Vector3Socket("rotation")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");
    const vector = context.readInput<Vector3Value>("rotation");

    if (!entity || !vector) {
      return;
    }

    entity.rootObjectRef.current?.rotateX(MathUtils.degToRad(vector.x));
    entity.rootObjectRef.current?.rotateY(MathUtils.degToRad(vector.y));
    entity.rootObjectRef.current?.rotateZ(MathUtils.degToRad(vector.z));
  }
}
