import { Button, Stack, styled, Typography } from "@mui/material";
import { Modal } from "common/components/Modal";

type ConfirmationDialogProps = {
  title: string;
  content: string;
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

export default function ConfirmationDialog({ title, content, open, onClose, onConfirm }: ConfirmationDialogProps) {
  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Stack>
        <Typography
          variant="body2"
          lineHeight="24px"
          color="primary.gray800"
          width="100%"
          padding="24px 0"
          fontWeight="500"
        >
          {content}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop="24px" alignItems="center">
          <CancelButton variant="text" onClick={onClose}>
            Cancel
          </CancelButton>
          <ConfirmButton variant="accent" onClick={onConfirm}>
            Confirm
          </ConfirmButton>
        </Stack>
      </Stack>
    </Modal>
  );
}

const CancelButton = styled(Button)`
  font-size: 16px;
  padding: 5px 12px;
`;

const ConfirmButton = styled(Button)`
  font-size: 16px;
  padding: 5px 12px;
`;
