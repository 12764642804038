import { TextureRequest } from "./types";
import { TextureEncoding, TextureTiling } from "~/types/texture";
import {
  ClampToEdgeWrapping,
  LinearEncoding,
  MirroredRepeatWrapping,
  RepeatWrapping,
  sRGBEncoding,
  Texture,
} from "three";

export const applyTiling = (texture: Texture, tiling?: TextureTiling): Partial<Texture> => {
  if (tiling) {
    const { repeatU, repeatV, offsetU, offsetV, mirrorU, mirrorV } = tiling;
    texture.wrapS = mirrorU ? MirroredRepeatWrapping : RepeatWrapping;
    texture.wrapT = mirrorV ? MirroredRepeatWrapping : RepeatWrapping;
    texture.repeat.set(repeatU, repeatV);
    texture.offset.set(offsetU, offsetV);
  } else {
    texture.wrapS = ClampToEdgeWrapping;
    texture.wrapT = ClampToEdgeWrapping;
    texture.repeat.set(1, 1);
    texture.offset.set(0, 0);
  }

  return texture;
};

export const mapTextureEncoding = (encoding?: TextureEncoding) => {
  return encoding === "linear" ? LinearEncoding : sRGBEncoding;
};

export function buildTextureRecordId(request: TextureRequest) {
  // TODO add support for encoding, flipY, tilling
  const params = `${request.url}`;
  return hashCode(params);
}

function hashCode(s: string) {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}
