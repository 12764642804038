import { NodeContext } from "../../base";
import { NumberUnaryMathOperationNode } from "./NumberUnaryMathOperationNode";

export class NumberTanNode extends NumberUnaryMathOperationNode {
  static readonly type = "logic/numberTan";
  static readonly label = "Tan";

  constructor(context: NodeContext) {
    super(context, (value) => Math.tan(value));
  }
}
