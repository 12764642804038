import { NodeEvalContext } from "~/libs/behave-graph";
import { NodeSpec } from "~/types/ScriptAsset";
import { BaseBehaveNode, BaseSocket } from "../../base";
import { StructSocket } from "../../sockets";
import { StructValue } from "../../values";
import { StructBaseNode } from "./StructBaseNode";

export class StructMakeNode extends StructBaseNode {
  static type = "struct/make";
  static label = "Struct Make";

  outputs = [new StructSocket("struct")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const structValue: Record<string, any> = {};

    for (const field of node.storage.struct.fields) {
      const value = context.readInput(field.id);

      if (value !== undefined) {
        structValue[field.id] = value;
      }
    }

    context.writeOutput<StructValue>("struct", structValue);
  }

  getInputs(nodeSpec: NodeSpec): BaseSocket[] {
    return this.getStructSockets(nodeSpec);
  }
}
