import { ValueType } from "~/libs/behave-graph";
import { defaultEntity } from "~/entities/variable";
import { EntityValue } from "./entityValue";

export type PlayerValue = EntityValue;

export const playerValue = new ValueType(
  "player",
  defaultEntity,
  (text: PlayerValue) => text,
  (value: PlayerValue) => value
);
