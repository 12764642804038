import { NodeEvalContext } from "~/libs/behave-graph";
import { PlayerContext } from "~/view-scene/runtime";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, PlayerSocket } from "../../sockets";

export class PlayerMovementStateNode extends BaseNode {
  static readonly type = "logic/playerMovementState";
  static readonly label = "Movement State";

  inputs = [new PlayerSocket("player")];
  outputs = [new BooleanSocket("enabled")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const player = node.readEntity<PlayerContext>(context, "player");

    context.writeOutput<boolean>("enabled", player?.getMovementState() ?? false);
  }
}
