import { NodeEvalContext } from "behave-graph";
import { AnySocket, FlowSocket, StringSocket } from "../../sockets";
import { BaseNode } from "../../base";
import { AnyValue } from "../../values";

export class LogNode extends BaseNode {
  static type = "action/log";
  static label = "Log";

  inputs = [new FlowSocket(), new StringSocket("label"), new AnySocket("value")];
  outputs = [new FlowSocket()];

  eval(context: NodeEvalContext) {
    const label = context.readInput<string>("label");
    const value = context.readInput<AnyValue>("value");
    const parts = [label, value].filter((v) => v !== undefined && v !== null);

    console.log(...parts);
  }
}
