import { ScriptEvent } from "~/types/ScriptAsset";
import { EventsManager } from "../EventsManager";
import { Graph } from "../Graph";
import { ScriptContext } from "../types";

export class BehaveEventsManager extends EventsManager {
  constructor(events: ScriptEvent[], private scriptContext: ScriptContext, private graph: Graph) {
    super(events);
  }

  call(eventId: string, values: Record<string, any> | any[]) {
    this.callEventById(eventId, values);
  }

  callByName(name: string, values: Record<string, any> | any[]): void {
    const event = this.eventsByName[name];

    if (!event) {
      return;
    }

    this.callEventById(event.id, values);
  }

  private callEventById(eventId: string, values: Record<string, any> | any[]) {
    setTimeout(() => {
      let valuesMap = Array.isArray(values) ? this.valuesToMap(eventId, values) : values;

      this.scriptContext.scriptEvents.eventCall.emit({ id: eventId, values: valuesMap });
      this.graph.executeAll();
    }, 0);
  }

  private valuesToMap(eventId: string, values: any[]): Record<string, any> {
    const event = this.events[eventId];
    const valuesMap: Record<string, any> = {};

    if (event) {
      event.inputs.forEach((input, index) => {
        valuesMap[input.id] = values[index];
      });
    }

    return valuesMap;
  }
}
