import { createEvent, createStore } from "effector";
import { ScriptExecutor } from "../ScriptExecutor";

export const init = createEvent<ConstructorParameters<typeof ScriptExecutor>[0]>();
export const dispose = createEvent();

export const $scriptExecutor = createStore<ScriptExecutor | null>(null)
  .on(init, (executor, options) => {
    if (!executor) {
      return new ScriptExecutor(options);
    }
  })
  .on(dispose, (executor) => {
    executor?.dispose();

    return null;
  });

export const $initialized = $scriptExecutor.map((executor) => Boolean(executor));
