import create from "zustand";
import { RefObject } from "react";
import type { ComponentContext, EntityContext, UIContext, UIEntitiesManager, UIEntityContext } from "./types";
import { EntityManagerEvents, NewContextEventValue } from "./entityManagerEvents";
import { resetRuntime } from "./reset";

type EntityManagerType<TEntityContext extends EntityContext = EntityContext> = {
  entityIdToObjectRef: Map<string, RefObject<TEntityContext>>;
  overlayContexts: Map<string, RefObject<UIEntitiesManager>>;
  events: EntityManagerEvents;

  addEntityContext: <TContext extends EntityContext>(entityId: string, objectRef: RefObject<TContext>) => void;
  getEntityContext: <TContext extends EntityContext>(entityId: string) => RefObject<TContext> | undefined;
  getEntityContextByName: <TContext extends EntityContext>(name: string) => RefObject<TContext> | undefined;
  getEntityContexts: (ids: string[]) => RefObject<EntityContext>[];
  getAllEntityContexts: () => RefObject<EntityContext>[];
  getComponentContext: <TContext extends ComponentContext>(
    entityId: string,
    componentId: string
  ) => TContext | undefined;
  removeEntityContext: (entityId: string) => void;
  getUIEntityContext: <TContext extends UIEntityContext>(entityId: string, uiEntityId: string) => TContext | undefined;
  registerOverlayContext: (overlayId: string, contextRef: RefObject<UIEntitiesManager>) => void;
  getOverlayUIEntityContext: <TContext extends UIEntityContext>(
    overlayId: string,
    uiEntityId: string
  ) => TContext | undefined;

  reset: () => void;

  waitForEntityContext: <TContext extends EntityContext>(id: string) => Promise<RefObject<TContext>>;
};

export const useEntityManager = create<EntityManagerType>((set, get) => ({
  entityIdToObjectRef: new Map<string, RefObject<EntityContext>>(),
  overlayContexts: new Map<string, RefObject<UIEntitiesManager>>(),
  events: new EntityManagerEvents(),

  addEntityContext: <TContext extends EntityContext>(entityId: string, objectRef: RefObject<TContext>) => {
    get().entityIdToObjectRef.set(entityId, objectRef);
    get().events.emit("newContext", { entityId, contextRef: objectRef });
  },

  getEntityContext: <TContext extends EntityContext>(entityId: string) => {
    return get().entityIdToObjectRef.get(entityId) as RefObject<TContext> | undefined;
  },

  getEntityContextByName: <TContext extends EntityContext>(name: string) => {
    for (const context of get().entityIdToObjectRef.values()) {
      if (context.current?.name === name) {
        return context as RefObject<TContext>;
      }
    }
  },

  getAllEntityContexts: () => {
    return [...get().entityIdToObjectRef.values()];
  },

  getEntityContexts: (ids: string[]) => {
    const result: RefObject<EntityContext>[] = [];

    for (let [key, value] of get().entityIdToObjectRef.entries()) {
      if (ids.includes(key)) {
        result.push(value);
      }
    }

    return result;
  },

  getComponentContext: <TContext extends ComponentContext>(entityId: string, componentId: string) => {
    const entityContext = get().entityIdToObjectRef.get(entityId);

    if (!entityContext) {
      return undefined;
    }

    return entityContext.current?.components[componentId] as TContext | undefined;
  },

  getUIEntityContext: <TContext extends UIEntityContext>(entityId: string, uiEntityId: string) => {
    const uiContext = get().entityIdToObjectRef.get(entityId)?.current as UIContext | undefined;

    if (!uiContext) {
      return undefined;
    }

    return uiContext.getUIEntityContext<TContext>(uiEntityId) ?? undefined;
  },

  removeEntityContext: (entityId: string) => get().entityIdToObjectRef.delete(entityId),

  getOverlayUIEntityContext: <TContext extends UIEntityContext>(overlayId: string, uiEntityId: string) => {
    const overlayContext = get().overlayContexts.get(overlayId)?.current as UIEntitiesManager | undefined;

    if (!overlayContext) {
      return undefined;
    }

    return overlayContext.getUIEntityContext<TContext>(uiEntityId) ?? undefined;
  },

  registerOverlayContext: (overlayId: string, contextRef: RefObject<UIEntitiesManager>) => {
    get().overlayContexts.set(overlayId, contextRef);
  },

  reset: () => {
    set({
      entityIdToObjectRef: new Map<string, RefObject<EntityContext>>(),
      overlayContexts: new Map<string, RefObject<UIEntitiesManager>>(),
      events: new EntityManagerEvents(),
    });
  },

  waitForEntityContext: <TContext extends EntityContext>(id: string) => {
    return new Promise<RefObject<TContext>>((resolve) => {
      const context = get().getEntityContext<TContext>(id);

      if (context) {
        return resolve(context);
      }

      const handler = ({ entityId, contextRef }: NewContextEventValue) => {
        if (entityId === id) {
          get().events.off("newContext", handler);
          resolve(contextRef as RefObject<TContext>);
        }
      };

      get().events.on("newContext", handler);
    });
  },
}));

resetRuntime.watch(() => {
  useEntityManager.getState().reset();
});
