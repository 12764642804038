import { useSceneData } from "~/common/stores/useSceneData";
import IImageAsset from "~/types/IImageAsset";
import ITeleportSceneObject from "~/types/ITeleportSceneObject";
import { useTexture } from "~/common/utils/useTexture";
import { CanvasTexture } from "three";

export function useTeleportPreview(dto: ITeleportSceneObject) {
  const { getScene, getAsset } = useSceneData((state) => ({
    getScene: state.getScene,
    getAsset: state.getAsset,
  }));

  const { preview, scene: sceneId } = dto;

  let teleportPreviewUrl = "/static/images/no_img_available.png";

  if (preview.asset) {
    const previewImage = getAsset<IImageAsset>(preview.asset);
    if (previewImage?.url) {
      teleportPreviewUrl = previewImage.url;
    }
  } else if (sceneId) {
    const scene = getScene(sceneId);
    if (scene?.previewUrl) {
      teleportPreviewUrl = scene.previewUrl;
    }
  }

  return useTexture(teleportPreviewUrl) as CanvasTexture;
}
