import { NodeSpec, InputSpec } from "~/types/ScriptAsset";

export const unlinkNodes = (node: NodeSpec, nodeIds: Set<string>) => {
  if (!node.parameters) {
    return node;
  }

  const modifiedNode: NodeSpec = {
    ...node,
    parameters: Object.keys(node.parameters).reduce((acc, key) => {
      const input = node.parameters![key];

      acc[key] = {
        ...input,
        links: input.links?.filter((link) => !nodeIds.has(link.nodeId)),
      };

      return acc;
    }, {} as { [key: string]: InputSpec }),
  };

  return modifiedNode;
};
