import "reflect-metadata";
import { memo, ReactNode } from "react";
import { EnabledStatus } from "~/types/EnabledStatus";
import { useEntity } from "~/view-scene/utils/useEntity";
import IPlatformSceneObject from "~/types/IPlatformSceneObject";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { Entity } from "~/view-scene/entities/Entity";

type PlatformEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const PlatformEntity = memo(({ entityId, children }: PlatformEntityProps) => {
  const entity = useEntity<IPlatformSceneObject>(entityId);
  const mode = useSessionStatus((state) => state.mode);

  const { platform, enabled } = entity;

  if (enabled === EnabledStatus.disabled || mode !== platform) {
    return null;
  }

  return <Entity entityId={entityId}>{children}</Entity>;
});
