import { CSSProperties, ReactNode, RefObject, forwardRef } from "react";
import { Circle, CircleProps } from "../Circle";
import { Stick } from "../Stick";
import { useSizes } from "../hooks";

export type RenderJoystickProps = Pick<CircleProps, "className" | "style"> & {
  stickRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

const stickContainerStyles: CSSProperties = {
  position: "absolute",
  width: 0,
  height: 0,
};

export const RenderJoystick = forwardRef<HTMLDivElement, RenderJoystickProps>(
  ({ className, style, children, stickRef }, ref) => {
    const { joystickSize, stickSize } = useSizes();

    return (
      <Circle
        className={className}
        style={{ width: `${joystickSize}px`, height: `${joystickSize}px`, ...style }}
        ref={ref}
      >
        <div ref={stickRef} style={stickContainerStyles}>
          <Stick style={{ width: `${stickSize}px`, height: `${stickSize}px`, transform: "translate(-50%, -50%)" }}>
            {children}
          </Stick>
        </div>
      </Circle>
    );
  }
);
