import { defaultComponent } from "~/entities/variable";
import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseSocket, BaseNode, NodeContext } from "../../base";
import { ComponentValue } from "../../values";
import { ComponentSocket } from "../../sockets";

export class SceneComponentNode extends BaseNode {
  static readonly type: string = "variable/sceneComponent";
  static readonly label: string = "Modification";
  static readonly category = "Variable";

  constructor(context: NodeContext, private socket: BaseSocket = new ComponentSocket("modification")) {
    super(context);
    this.outputs = [socket];
  }

  initNode(node: BaseBehaveNode) {
    node.storage.output = defaultComponent();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.entityId = node.metadata?.entityId ?? null;
    node.storage.output.componentId = node.metadata?.componentId ?? null;

    context.writeOutput<ComponentValue>(this.socket.name, node.storage.output);
  }
}
