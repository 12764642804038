import { Hud, OrthographicCamera } from "@react-three/drei";
import { VRHud } from "../VRHud";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import shallow from "zustand/shallow";
import { useSceneData } from "~/common/stores/useSceneData";
import { UINotifications } from "../notifications";
import { useStore } from "effector-react";
import { $sceneMode } from "~/view-scene/runtime";
import { $uiSystem } from "../models";
import { useAspectRatio } from "./useAspectRatio";
import { RenderOverlays } from "./RenderOverlays";
import { useInitOverlays } from "./useInitOverlays";
import { useThree } from "@react-three/fiber";

export function ScreenOverlay() {
  const startOverlayEnabled =
    useSceneData((state) => state.sceneState?.platformInterfaceSystem.startOverlayEnabled) ?? true;
  const { sessionInProgress, device } = useSessionStatus((state) => ({
    sessionInProgress: state.sessionStatus === "in_progress",
    device: state.mode,
  }));
  const screenOverlay = useSceneData((state) => state.sceneState?.screenOverlay, shallow);
  const sceneMode = useStore($sceneMode);
  const uiSystem = useStore($uiSystem);
  useInitOverlays(screenOverlay);
  const sceneIsReady = (startOverlayEnabled && sessionInProgress) || !startOverlayEnabled;

  if (!uiSystem.isReady || !sceneIsReady || sceneMode !== "scene") {
    return null;
  }

  return device === "vr" ? <VRScreenOverlay /> : <DefaultScreenOverlay />;
}

function VRScreenOverlay() {
  return (
    <VRHud>
      <VRScreenOverlayImpl />
    </VRHud>
  );
}

function VRScreenOverlayImpl() {
  const screenOverlay = useSceneData((state) => state.sceneState?.screenOverlay, shallow);

  useAspectRatio();

  return (
    <>
      {screenOverlay && <RenderOverlays overlays={screenOverlay.items} />}
      <UINotifications />
    </>
  );
}

function DefaultScreenOverlay() {
  // Render HUD only after three-fiber became active. It is a hack to prevent state.internal references missing
  const isActive = useThree((state) => state.internal.active);

  const isPostprocessingEnabled = useSceneData((state) => state.sceneState?.postprocessing.enabled, shallow);
  const renderPriority = isPostprocessingEnabled ? 2 : 1;

  return isActive ? (
    <Hud renderPriority={renderPriority}>
      <DefaultScreenOverlayImpl />
    </Hud>
  ) : null;
}

function DefaultScreenOverlayImpl() {
  const screenOverlay = useSceneData((state) => state.sceneState?.screenOverlay, shallow);

  useAspectRatio();

  return (
    <>
      <ambientLight />
      <OrthographicCamera name="hudcam" makeDefault position={[0, 0, 100]} zoom={100} />
      <group position={[0, 0, 0]}>
        {screenOverlay && <RenderOverlays overlays={screenOverlay.items} />}
        <UINotifications />
      </group>
    </>
  );
}
