import { useMemo } from "react";
import { ScreenOverlayItem } from "~/types/ScreenOverlay";
import { RenderOverlay } from "./RenderOverlay";

export type RenderOverlaysProps = {
  overlays: ScreenOverlayItem[];
};

export const RenderOverlays = ({ overlays }: RenderOverlaysProps) => {
  const availableOverlays = useMemo(() => overlays.filter((overlay) => overlay.enabled && overlay.asset), [overlays]);

  return (
    <>
      {availableOverlays.map((overlay) => (
        <RenderOverlay key={overlay.id} overlay={overlay} />
      ))}
    </>
  );
};
