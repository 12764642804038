import { memo, ReactNode, useContext, useEffect, useMemo, useRef } from "react";
import IScreenShareSceneObject from "types/IScreenShareSceneObject";
import { Entity } from "../Entity";
import { FrontSide, Mesh, MeshBasicMaterial } from "three";
import { INetworkSystemCtx, NetworkSystemCtx } from "view-scene/network/NetworkSystemCtx";
import { SCREEN_STREAM_KEY, useMediaStreamStore } from "view-scene/media/useMediaStreamStore";
import { RenderLocalStream } from "./RenderLocalStream";
import { RenderRemoteStream } from "./RenderRemoteStream";
import { RenderScreenSharePreview } from "./RenderScreenSharePreview";
import { ActionIcon } from "~/view-scene/common/ActionIcon";
import useSessionStatus from "../../stores/useSessionStatus";
import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useCurrentMedia } from "~/view-scene/media";
import { useEntity } from "~/view-scene/utils/useEntity";

type ScreenShareEntityProps = {
  entityId: string;
  children?: ReactNode;
};

export const ScreenShareEntity = memo(({ entityId, children }: ScreenShareEntityProps) => {
  const entity = useEntity<IScreenShareSceneObject>(entityId);

  const { id, width, height, useCustomGeometry, geometryAsset, visibleDistance, remoteUserID } = entity;

  const { screenStream } = useCurrentMedia();

  const { sessionId, send } = useContext(NetworkSystemCtx) as INetworkSystemCtx;

  const mode = useSessionStatus((state) => state.mode);

  const streamingInProgress = remoteUserID != null;
  const isLocalStreamPlaying = remoteUserID != null && remoteUserID === sessionId;

  const actionIcon = streamingInProgress
    ? "/static/img/viewer/screen-share-entity/stop-sprite-desktop.png"
    : "/static/img/viewer/screen-share-entity/start-sprite-desktop.png";

  const previewImage =
    mode === "desktop"
      ? "/static/img/viewer/screen-share-entity/placeholder-desktop.png"
      : "/static/img/viewer/screen-share-entity/placeholder-mobile-and-vr.png";

  const screenSharePlaceholderImage = useTexture(previewImage);

  const { requestScreenStream, removeStream } = useMediaStreamStore((state) => ({
    requestScreenStream: state.requestScreenStream,
    removeStream: state.removeStream,
  }));

  const screenShareRef = useRef<Mesh>(null);

  const handleScreenShareAction = async () => {
    if (streamingInProgress) {
      send("stopScreenSharing", { screenShareEntityID: id });
      if (isLocalStreamPlaying) {
        removeStream(SCREEN_STREAM_KEY);
      }
    } else {
      await requestScreenStream();
      send("startScreenSharing", { screenShareEntityID: id });
    }
  };

  const screenShareMaterial = useMemo(
    () =>
      new MeshBasicMaterial({
        color: "white",
        side: FrontSide,
        map: screenSharePlaceholderImage,
      }),
    []
  );

  useEffect(() => {
    if (!streamingInProgress) {
      screenShareMaterial.map = screenSharePlaceholderImage;
      screenShareMaterial.needsUpdate = true;
    }
  }, [streamingInProgress]);

  const renderStream = () => {
    if (!streamingInProgress) {
      return null;
    }

    if (isLocalStreamPlaying) {
      return <RenderLocalStream screenShareMaterial={screenShareMaterial} />;
    } else {
      return <RenderRemoteStream remoteUserID={remoteUserID} screenShareMaterial={screenShareMaterial} />;
    }
  };

  useFrame(() => {
    if (isLocalStreamPlaying && screenStream && !screenStream.active) {
      send("stopScreenSharing", { screenShareEntityID: id });
      // TODO should be moved to stream store
      removeStream(SCREEN_STREAM_KEY);
    }
  });

  return (
    <>
      <Entity entityId={entityId}>
        <RenderScreenSharePreview
          width={width}
          height={height}
          screenShareMaterial={screenShareMaterial}
          useCustomGeometry={useCustomGeometry}
          geometryAsset={geometryAsset}
          ref={screenShareRef}
        />
        {renderStream()}
        {children}
      </Entity>
      {mode === "desktop" && (
        <ActionIcon
          icon={actionIcon}
          visibleDistance={visibleDistance}
          onActionButtonReleased={handleScreenShareAction}
          objectRef={screenShareRef}
        />
      )}
    </>
  );
});
