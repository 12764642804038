import ICoordinates from "~/types/ICoordinates";
import { MathUtils } from "three";

export const vector3RadToDeg = (vector: ICoordinates) => {
  return {
    x: MathUtils.radToDeg(vector.x),
    y: MathUtils.radToDeg(vector.y),
    z: MathUtils.radToDeg(vector.z),
  } as ICoordinates;
};
