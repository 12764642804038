export enum LeftButton {
  trigger = 0,
  grip = 1,
  // hz chto eto za knopka
  unknown = 2,
  stickButton = 3,
  xButton = 4,
  yButton = 5,
}

export enum RightButton {
  trigger = 0,
  grip = 1,
  // hz chto eto za knopka
  unknown = 2,
  stickButton = 3,
  aButton = 4,
  bButton = 5,
}
