import { Easing, remove, Tween } from "@tweenjs/tween.js";
import { capitalize, cloneDeep, get } from "lodash-es";
import { MutableRefObject } from "react";
import { degToRad, radToDeg } from "~/common/utils/degToRad";
import { ComponentType } from "~/types/ComponentType";
import { TweenAnimationComponent, TweenAnimationKeyframe, TweenAnimationKeyframeValue } from "~/types/component";
import { AnimationComponentContext, EntityContext, useEntityManager } from "~/view-scene/runtime";
import { AnimationComponentEvents } from "../AnimationComponentEvents";
import { Vector3Value } from "~/types/Variable";

export class TweenAnimationContext implements AnimationComponentContext {
  id: string;
  name: string;
  animationName: string;
  type: ComponentType.TWEEN_ANIMATION;
  events: AnimationComponentEvents;
  duration: number;
  keyframes: TweenAnimationKeyframe[];
  easing: string;
  helper: string;
  time: number = 0;
  delay: number = 0;
  repeatDelay: number = 0;
  yoyo: boolean = false;
  loop: boolean = false;
  autoplay: boolean = false;

  private _tween: Tween<any> | null = null;
  private _initialValues: any = null;

  constructor(dto: TweenAnimationComponent, private entityContext: MutableRefObject<EntityContext>) {
    this.id = dto.id;
    this.type = ComponentType.TWEEN_ANIMATION;
    this.name = dto.animationName;
    this.animationName = dto.animationName;
    this.duration = dto.duration;
    this.yoyo = dto.yoyo;
    this.loop = dto.loop;
    this.autoplay = dto.autoplay;
    this.delay = dto.delay;
    this.repeatDelay = dto.repeatDelay;
    this.events = new AnimationComponentEvents();
    this.keyframes = dto.keyframes;
    this.easing = dto.easing;
    this.helper = dto.helper;

    if (this.autoplay) {
      this.play();
    }
  }

  play() {
    if (!this._tween) {
      this._tween = this.initTween();
    }

    if (!this._tween.isPlaying()) {
      this._tween.start();
    } else if (this._tween.isPaused()) {
      this._tween.resume();
    }
  }

  pause() {
    this._tween?.pause();
  }

  stop() {
    this._tween?.stop();
    this.reset();
    this.removeTween();
  }

  setLoop(flag: boolean) {
    this.loop = flag;
  }

  reset() {
    if (!this._initialValues) {
      return;
    }

    this.applyValues(this._initialValues);
  }

  dispose() {
    this.events.removeAllListeners();
    this.removeTween();
  }

  private initTween(): Tween<any> {
    const initialValues = this.getInitialValues();
    this._initialValues = cloneDeep(initialValues);
    const targetValues = this.getTargetValues();

    return new Tween(initialValues)
      .yoyo(this.yoyo)
      .easing(this.getEasing())
      .repeat(this.loop ? Infinity : this.yoyo ? 1 : 0)
      .delay(this.delay)
      .repeatDelay(this.repeatDelay)
      .to(targetValues, this.duration)
      .onUpdate((values) => {
        this.applyValues(values);
      })
      .onComplete(() => {
        this.events.emit("finish");
      });
  }

  private removeTween() {
    if (this._tween) {
      remove(this._tween);
      this._tween = null;
      this._initialValues = null;
    }
  }

  private getInitialValues() {
    return this.getValues("from");
  }

  private getTargetValues() {
    return this.getValues("to");
  }

  private getValues(stage: "from" | "to") {
    const values: any = {};

    for (const keyframe of this.keyframes) {
      const value = this.getValue(keyframe[stage], keyframe.property, keyframe.offset);

      if (value !== undefined) {
        values[keyframe.property] = value;
      }
    }

    return cloneDeep(values);
  }

  private getValue(value: TweenAnimationKeyframeValue, property: string, offset?: TweenAnimationKeyframeValue) {
    switch (value.type) {
      case "current":
        return this.getContextValue(this.entityContext.current ?? ({} as any), property, offset);
      case "entity":
        const entityContext = useEntityManager.getState().getEntityContext(value.value.entityId ?? "");

        if (!entityContext || !entityContext.current) {
          throw new Error(`Entity with id ${value.value.entityId} not found`);
        }

        return this.getContextValue(entityContext.current, property, offset);
      default:
        return value.value;
    }
  }

  private getContextValue(context: EntityContext, property: string, offset?: TweenAnimationKeyframeValue) {
    const value = (context as any)[property];

    if (value === undefined) {
      throw new Error(`Property ${property} is not defined in context`);
    }

    // TODO: CLASS_CONTEXTS
    // TODO: Property map decorator
    if (property === "rotation") {
      radToDeg(value);
      if (offset) {
        radToDeg(offset.value as Vector3Value);
      }
    }

    if (offset?.type === "number") {
      return value + offset?.value ?? 0;
    } else if (offset?.type === "vector3") {
      value.x += offset?.value?.x ?? 0;
      value.y += offset?.value?.y ?? 0;
      value.z += offset?.value?.z ?? 0;
      return value;
    } else {
      return value;
    }
  }

  private setContextValue(context: EntityContext, property: string, value: any) {
    // TODO: CLASS_CONTEXTS
    // TODO: Property map decorator
    if (property === "rotation") {
      degToRad(value);
    }

    (context as any)[property] = value;
  }

  private applyValues(values: any) {
    for (const keyframe of this.keyframes) {
      const value = values[keyframe.property];

      this.setContextValue(this.entityContext.current!, keyframe.property, value);
    }
  }

  private getEasing() {
    const helper = this.easing === "linear" ? "none" : this.helper;

    return get(Easing, [capitalize(this.easing), capitalize(helper)]);
  }
}
