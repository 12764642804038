import { API_HOST } from "~/config";
import { v4 as uuid } from "uuid";
import { getAuthHeader } from "./getAuthHeader";

const contentTypeHeader = { "Content-Type": "application/json" };

const getHeaders = () => {
  return { ...contentTypeHeader, ...getAuthHeader() };
};

export const getScenes = async (metaName) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const query = metaName ? `?workspace=${metaName}` : "";
  const response = await fetch(`${API_HOST}/scenes${query}`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const getMyScenes = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/my-scenes`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const getScene = async (urlName) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/scenes/${urlName}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const hasSceneAccess = async (sceneId) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/scenes/${sceneId}/hasAccess`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export const getSceneById = async (sceneId) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(`${API_HOST}/builder/scenes/${sceneId}`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { status, body };
};

export const checkSceneUrlNameExistence = async (urlName) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  return await fetch(`${API_HOST}/scenes/${urlName}/exist`, requestOptions);
};

export const createSceneFromScratch = async (scene) => {
  const body = JSON.stringify(scene);
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  return await fetch(`${API_HOST}/scenes`, requestOptions).then((res) => res.json());
};

export const createSceneFromTemplate = async (sceneId, workspaceId) => {
  const body = JSON.stringify({ sceneId, workspaceId });
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body,
  };

  return await fetch(`${API_HOST}/scenes/createFromTemplate`, requestOptions).then((res) => res.json());
};

export const updateScene = async (scene) => {
  const sceneId = scene._id;
  const body = JSON.stringify(scene);
  const requestOptions = {
    method: "PATCH",
    headers: getHeaders(),
    body,
  };

  return await fetch(`${API_HOST}/scenes/${sceneId}`, requestOptions);
};

export const cloneScene = async (sceneId) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/scenes/${sceneId}/clone`, requestOptions);
  const status = response.status;
  const body = response.json();

  return { status, body };
};

export const deleteScene = async (sceneId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/scenes/${sceneId}`, requestOptions);
  const status = response.status;

  return { status };
};

export const uploadScenePreview = async (sceneId, previewFile) => {
  const data = new FormData();
  data.append("preview", previewFile);
  const requestOptions = {
    method: "POST",
    headers: getAuthHeader(),
    body: data,
  };
  const response = await fetch(`${API_HOST}/scenes/${sceneId}/preview`, requestOptions);
  const status = response.status;
  const body = response.json();

  return { status, body };
};

export const draftScene = async (scene) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  return await fetch(`${API_HOST}/scenes/${scene._id}/draft`, requestOptions);
};

export const publishScene = async (scene) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  return await fetch(`${API_HOST}/scenes/${scene._id}/publish`, requestOptions);
};

export const duplicateScene = async (sceneId) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
  };

  return await fetch(`${API_HOST}/scenes/${sceneId}/duplicate`, requestOptions);
};

export const uploadComponentAsset = async (sceneId, componentId, blob) => {
  const data = new FormData();
  data.append("asset", blob, `${uuid().toString()}.usdz`);
  const requestOptions = {
    method: "POST",
    headers: getAuthHeader(),
    body: data,
  };

  return await fetch(`${API_HOST}/scenes/${sceneId}/components/${componentId}/asset`, requestOptions);
};
