import { Stack, styled, Typography } from "@mui/material";
import { Logout } from "~/common/components/Icon";
import { palette } from "~/theme";

type LogoutButtonProps = {
  onLogout: VoidFunction;
};

export function LogoutButton({ onLogout }: LogoutButtonProps) {
  return (
    <Stack
      onClick={onLogout}
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        transition: ".4s ease",
        cursor: "pointer",
        "&:hover": {
          opacity: ".6",
        },
      })}
    >
      <Typography
        sx={(theme) => ({
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "20px",
          color: theme.palette.common.white,
        })}
        variant="body1"
      >
        Logout
      </Typography>
      <LogoutIcon />
    </Stack>
  );
}

const LogoutIcon = styled(Logout)`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  color: ${palette.primary.white};
`;
