import { NodeContext } from "../../base";
import { LightSocket } from "../../sockets";
import { CastToNode } from "./CastToNode";

export class CastToLightNode extends CastToNode {
  static readonly type = "logic/castToLight";
  static readonly label = "Cast to Light";

  constructor(context: NodeContext) {
    super(context, new LightSocket("video", "light"), "light");
  }
}
