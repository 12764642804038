import { keyBy } from "lodash-es";
import { ScriptSchema, NodeSpec } from "~/types/ScriptAsset";
import { Registry } from "./Registry";

export class Sanitizer {
  constructor(private registry: Registry) {}

  sanitize(schema: ScriptSchema) {
    const nodesMap = keyBy(schema.graph.nodes, "id");
    this.sanitizeNodes(schema.graph.nodes, nodesMap);
  }

  private sanitizeNodes(nodes: NodeSpec[], nodesMap: Record<string, NodeSpec>) {
    for (const node of nodes) {
      this.sanitizeNode(node, nodesMap);
    }
  }

  private sanitizeNode = (node: NodeSpec, nodesMap: Record<string, NodeSpec>) => {
    const nodeInstance = this.registry.getNode(node.type);
    const inputs = nodeInstance.getFlattenInputs(node);

    if (node.parameters) {
      for (const [socketName, input] of Object.entries(node.parameters)) {
        const foundInput = inputs.find((input) => input.id === socketName);

        if (!foundInput) {
          delete node.parameters[socketName];
          continue;
        }

        if (input.links) {
          input.links = input.links.filter((link) => {
            const node = nodesMap[link.nodeId];

            if (!node) {
              return false;
            }

            const foundOutput = this.registry
              .getNode(node.type)
              .getFlattenOutputs(node)
              .find((output) => output.id === link.socket);

            return Boolean(foundOutput);
          });
        }
      }
    }
  };
}
