import {Object3D, Vector3} from "three";

const object1Position = new Vector3();
const object2Position = new Vector3();

export function getDistance(object1: Object3D, object2: Object3D) {
  object1.getWorldPosition(object1Position);
  object2.getWorldPosition(object2Position);

  const dx = object1Position.x - object2Position.x;
  const dy = object1Position.y - object2Position.y;
  const dz = object1Position.z - object2Position.z;

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}