import { combine, createEvent, forward, sample } from "effector";
import { $sceneData } from "~/entities/sceneData";
import { $uiSettings, loadUISettings, updateUISettings } from "~/hephaestus/builderUIState";
import {
  $transformControlsSettings,
  activateControlsMode,
  clearTransformControlsSettings,
  getTransformValue,
  TransformControlsMode,
  transformModeToField,
  updateTransformControlsSettings,
} from "~/hephaestus/SceneEditor/controls";
import { $entities } from "~/entities/entities";
import { EnabledStatus } from "~/types/EnabledStatus";
import { noop } from "lodash-es";
import { updateEntity } from "./updateEntity";
import { Object3D } from "three";

export const $selectedEntity = combine(
  $sceneData,
  $uiSettings,
  (sceneData, uiSettings) => sceneData?.objects.find((entity) => entity.id === uiSettings?.selectedEntityId) ?? null
);

export const selectEntity = createEvent<string>();
forward({
  from: selectEntity,
  to: [updateUISettings.prepend((selectedEntityId) => ({ activeInspector: "entity", selectedEntityId }))],
});

sample({
  source: $transformControlsSettings,
  clock: loadUISettings,
  fn: (state, builderUIState) => {
    if (builderUIState.selectedEntityId) {
      return {
        ...state,
        onStart: () => activateControlsMode({ mode: "transform" }),
        targetId: builderUIState.selectedEntityId,
        onCommit: (mode: TransformControlsMode, object: Object3D) => {
          activateControlsMode({ mode: "camera" });

          const entityId = builderUIState.selectedEntityId;
          if (!entityId) {
            return;
          }

          if (mode === "translate") {
            const { x, y, z } = object.position;
            updateEntity({ id: entityId, entity: { position: { x, y, z } } });
          } else if (mode === "rotate") {
            const { x, y, z } = object.rotation;
            updateEntity({ id: entityId, entity: { rotation: { x, y, z } } });
          } else {
            const { x, y, z } = object.scale;
            updateEntity({ id: entityId, entity: { scale: { x, y, z } } });
          }
        },
      };
    }

    return state;
  },
  target: updateTransformControlsSettings,
});

sample({
  source: $entities,
  clock: selectEntity,
  filter: (entities, entityId) => {
    if (!entities) {
      return false;
    }
    const entity = entities.find((entity) => entity.id === entityId)!;
    return entity.enabled === EnabledStatus.enabled;
  },
  fn: (entities, entityId) => ({
    targetId: `${entityId}`,
    onChange: noop,
    onCommit: (mode: TransformControlsMode, object: Object3D) => {
      if (mode === "disabled") {
        return;
      }
      const value = getTransformValue(object, mode);
      const field = transformModeToField(mode);
      updateEntity({
        id: entityId,
        entity: {
          [field]: value,
        },
      });
    },
  }),
  target: updateTransformControlsSettings,
});

export const selectOnScene = createEvent<string>();
forward({ from: selectOnScene, to: selectEntity });

export const cancelEntitySelection = createEvent();
forward({
  from: cancelEntitySelection,
  to: [
    updateUISettings.prepend(() => ({
      activeInspector: "scene",
      selectedEntityId: undefined,
    })),
    clearTransformControlsSettings,
  ],
});
