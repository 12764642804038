import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";
import { setPosition } from "./utils";

export class SetPositionNode extends BaseNode {
  static readonly type = "action/translate"; // legacy key name
  static readonly label = "Set position";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new Vector3Socket("position")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");
    const position = context.readInput<Vector3Value>("position");

    if (!entity) {
      return;
    }

    setPosition(entity, position);
  }
}
