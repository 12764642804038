import { styled } from "@mui/material";
import { palette } from "~/theme";
import { Inst as InstIcon, InstProps as InstagramIconProps } from "./Icon";
import { Navigate } from "./Navigate";

export type InstagramProps = Pick<InstagramIconProps, "className" | "sx">;

export const Instagram = (props: InstagramProps) => {
  return (
    <Navigate to="https://www.instagram.com/8xr.engine/" openInNewWindow={true}>
      <StyledInstagramIcon {...props} />
    </Navigate>
  );
};

const StyledInstagramIcon = styled(InstIcon)`
  width: 48px;
  height: 48px;
  transition: 0.4s ease;
  color: ${palette.primary.white};

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
  }
`;
