import { MathUtils } from "three";
import { NodeEvalContext } from "~/libs/behave-graph";
import { defaultVector3 } from "~/entities/variable";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

/**
 * @deprecated use RotationNode
 */
export class GetRotationNode extends BaseNode {
  static readonly type = "action/getRotate";
  static readonly label = "Get rotation";
  static readonly deprecated = true;

  inputs = [new FlowSocket("flow"), new EntitySocket("entity")];
  outputs = [new FlowSocket("flow"), new Vector3Socket("rotation")];

  initNode(node: BaseBehaveNode): void {
    node.storage.output = defaultVector3();
  }

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    node.storage.output.x = node.storage.output.y = node.storage.output.z = 0;

    const entity = node.readEntity(context, "entity");

    if (!entity) {
      return;
    }

    const rotation = entity.rootObjectRef.current?.rotation;

    if (rotation) {
      node.storage.output.x = MathUtils.radToDeg(rotation.x);
      node.storage.output.y = MathUtils.radToDeg(rotation.y);
      node.storage.output.z = MathUtils.radToDeg(rotation.z);
    }

    context.writeOutput<Vector3Value>("rotation", node.storage.output);
  }
}
