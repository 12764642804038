import { Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import React from "react";
import { Plus } from "~/common/components/Icon";
import { useProfile } from "~/entities/profile";
import { Navigate } from "~/common/components";

export function SetupAvatar() {
  const { avatarPreview, hasAvatar } = useProfile((state) => ({
    avatarPreview: state.profile?.avatarPreview,
    hasAvatar: state.profile?.avatarUrl != null,
  }));

  return (
    <Stack direction="column" gap="16px" marginBottom="28px">
      <HeaderLabel variant="body1">Avatar</HeaderLabel>
      <Stack direction="row" gap="14px" alignItems="center" justifyContent="space-between">
        <Navigate to="/profile/avatar?referer=Scene" requireAuth={true} openInNewWindow={true}>
          <AvatarContainer sx={{ ...(avatarPreview && { backgroundImage: `url("${avatarPreview}")` }) }}>
            {!hasAvatar && <Plus color={palette.primary.gray400} />}
          </AvatarContainer>
        </Navigate>
        <HintText variant="body1">
          {hasAvatar
            ? "Tap on your avatar to personalize it."
            : "Personalize your profile with an avatar of your choice, or the default will be set."}
        </HintText>
      </Stack>
    </Stack>
  );
}

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${palette.primary.white};
  width: 100%;
`;

const AvatarContainer = styled(Stack)`
  border: 1px solid ${palette.primary.gray200};
  background-color: ${palette.primary.gray100};
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 72px;
  min-height: 72px;
  transition: 0.4s ease;
  opacity: 0.9;

  &:hover {
    transform: scale(1.04);
    opacity: 1;
  }
`;

const HintText = styled(HeaderLabel)`
  display: block;
  border-radius: 8px;
  padding: 10px;
  background-color: ${palette.primary.gray100};
`;
