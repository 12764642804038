import { NodeContext } from "../../base";
import { UIBlockSocket } from "../../sockets/UIBlockSocket";
import { CastToUIEntityNode } from "./CastToUIEntityNode";

export class CastToUIBlockNode extends CastToUIEntityNode {
  static readonly type = "logic/castToUIBlock";
  static readonly label = "Cast to UI Block";

  constructor(context: NodeContext) {
    super(context, "block", new UIBlockSocket("uiBlock"));
  }
}
