import { Suspense, lazy } from "react";

const OpenLinkHelper = lazy(() => import("./OpenLinkHelper").then((module) => ({ default: module.OpenLinkHelper })));

export const OpenLinkHelperLazy = () => {
  return (
    <Suspense fallback={null}>
      <OpenLinkHelper />
    </Suspense>
  );
};
