import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Mesh } from "three";
import { RenderDummyBall } from "./RenderDummyBall";
import { RenderPreviewBall } from "./RenderPreviewBall";

export type RenderFloatingBallProps = {
  previewUrl?: string | null;
};

export const RenderFloatingBall = ({ previewUrl }: RenderFloatingBallProps) => {
  const ref = useRef<Mesh>(null);
  const timeRef = useRef(0);

  useFrame((_, delta) => {
    if (ref.current) {
      timeRef.current += delta;
      ref.current.position.y = Math.cos(timeRef.current) * 0.2;
    }
  });

  if (previewUrl) {
    return <RenderPreviewBall ref={ref} previewUrl={previewUrl} />;
  }

  return <RenderDummyBall ref={ref} />;
};
