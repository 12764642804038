import { Camera, Vector3 } from "three";

const cameraPosition = new Vector3();

export const calcPositionInfront = (camera: Camera, distance: number) => {
  const cwd = new Vector3();
  camera.getWorldDirection(cwd);

  cwd.multiplyScalar(distance);
  camera.getWorldPosition(cameraPosition);
  cwd.add(cameraPosition);

  return cwd;
};
