import "reflect-metadata";
import { TweenAnimationKeyframeValue } from "~/types/component";

export const AnimatedProperty = <TType extends TweenAnimationKeyframeValue["type"], TDefaultType extends TType>(
  types: TType | TType[],
  defaultType?: TDefaultType
) => {
  return (target: any, propertyKey: string) => {
    const typesArr = Array.isArray(types) ? types : [types];

    addAnimatedProperties([propertyKey], target);
    setAnimatedPropertyTypes(typesArr, target, propertyKey);

    if (defaultType) {
      setDefaultAnimatedPropertyType(defaultType, target, propertyKey);
    }
  };
};

const animatedPropertiesKey = "animated-properties";

export const getAnimatedProperties = (target: any): string[] => {
  return Reflect.getMetadata(animatedPropertiesKey, target) ?? [];
};

export const setAnimatedProperties = (properties: string[], target: any) => {
  Reflect.defineMetadata(animatedPropertiesKey, properties, target);
};

export const addAnimatedProperties = (properties: string[], target: any) => {
  const animatedProperties = getAnimatedProperties(target);
  animatedProperties.push(...properties);

  setAnimatedProperties([...new Set(animatedProperties)], target);
};

const animatedPropertyTypesKey = "animated-property-type";

export const setAnimatedPropertyTypes = (
  types: TweenAnimationKeyframeValue["type"][],
  target: any,
  propertyKey: string
) => {
  Reflect.defineMetadata(animatedPropertyTypesKey, types, target, propertyKey);
};

export const getAnimatedPropertyTypes = (target: any, propertyKey: string): TweenAnimationKeyframeValue["type"][] => {
  return Reflect.getMetadata(animatedPropertyTypesKey, target, propertyKey) ?? [];
};

const defaultAnimatedPropertyType = "default-animated-property-type";

export const setDefaultAnimatedPropertyType = (
  type: TweenAnimationKeyframeValue["type"],
  target: any,
  propertyKey: string
) => {
  Reflect.defineMetadata(defaultAnimatedPropertyType, type, target, propertyKey);
};

export const getDefaultAnimatedPropertyType = (
  target: any,
  propertyKey: string
): TweenAnimationKeyframeValue["type"] | undefined => {
  return Reflect.getMetadata(defaultAnimatedPropertyType, target, propertyKey);
};
