import { Box, FormControl, MenuItem, Slider, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import shallow from "zustand/shallow";
import useAnimationFrame from "common/hooks/useAnimationFrame";
import { getAvailableMicDevices } from "view-scene/utils/media/getAvailableMicDevices";
import { palette } from "~/theme";
import { Switch } from "common/components/Switch";
import isMobile, { isMobileVR } from "common/utils/isMobile";
import Select from "common/components/Select";
import { useMediaState } from "view-scene/media";
import { useMicrophone } from "./useMicrophone";

type MicSetupProps = {
  selectedMicDevice: string | null | undefined;
  selectMicDevice: (selectedMicDevice: string) => void;
};

export function MicSetup({ selectedMicDevice, selectMicDevice }: MicSetupProps) {
  const micStream = useMicrophone(selectedMicDevice);

  const { muted, toggleMute } = useMediaState(
    (state) => ({
      muted: state.muted,
      toggleMute: state.toggleMute,
    }),
    shallow
  );

  const [micDevices, setMicDevices] = useState<{ value: string; label: string }[]>();

  useEffect(() => {
    getAvailableMicDevices().then((micDevices) => setMicDevices(micDevices));
  }, []);

  const handleMicChange = async (event: any) => {
    const micDeviceId = event.target.value;
    selectMicDevice(micDeviceId);
  };

  const micAnalyserRef = useRef<any>(null);

  useEffect(() => {
    if (!micStream) {
      return;
    }
    const audioContext = new AudioContext();
    const microphone = audioContext.createMediaStreamSource(micStream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;
    microphone.connect(analyser);
    micAnalyserRef.current = analyser;
  }, [micStream]);

  const sliderRef = useRef<any>();

  useAnimationFrame(() => {
    const slider = sliderRef.current;
    const micAnalyser = micAnalyserRef.current;

    if (!micAnalyser || !slider) {
      return;
    }

    const volumes = new Uint8Array(micAnalyser.frequencyBinCount);
    micAnalyser.getByteFrequencyData(volumes);

    let volumeSum = 0;
    for (const volume of volumes) {
      volumeSum += volume;
    }
    const averageVolume = volumeSum / volumes.length;
    const thumb = slider.getElementsByClassName("MuiSlider-thumb")[0];
    thumb.style = `left: ${averageVolume}%;`;
  });

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          marginBottom: "12px",
        }}
        justifyContent="space-between"
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            width: "100%",
            color: palette.primary.white,
          }}
          variant="body1"
        >
          Microphone
        </Typography>
        <Switch checked={!muted} onChange={() => toggleMute()} />
      </Stack>
      {!isMobile() && !isMobileVR() && (
        <>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{
              paddingBottom: "18px",
              marginBottom: "8px",
              borderBottom: "1px solid " + palette.primary.gray200,
            }}
          >
            {micDevices && (
              <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
                <FormControl fullWidth>
                  <Select
                    value={selectedMicDevice}
                    onChange={handleMicChange}
                    sx={{
                      maxHeight: "24px",
                      fontSize: "12px",
                      backgroundColor: palette.primary.gray100,
                      border: "1px solid " + palette.primary.gray400,
                    }}
                  >
                    {micDevices.map((device) => (
                      <MenuItem value={device.value} key={device.value}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box
              component="div"
              sx={(theme) => ({
                minWidth: 129,
                [theme.breakpoints.down("sm")]: {
                  minWidth: 79,
                  width: "30%",
                },
              })}
            >
              <Slider disabled={true} min={0} max={100} aria-label="micro" color="secondary" ref={sliderRef} />
            </Box>
          </Stack>
        </>
      )}
    </>
  );
}
