import { styled } from "@mui/material";
import { noop } from "lodash-es";
import { ChangeEvent, ComponentType, FocusEvent, HTMLProps, useCallback, useEffect, useState } from "react";
import { TextField, TextFieldProps } from "../TextField";
import { WithAutoFieldAddProps, withAutoField } from "../withAutoField";
import { addZeroPrefix, clamp, valueToDisplayValue, valueToNumber } from "./utils";

export type NumericFieldProps = Omit<TextFieldProps, "type" | "min" | "max" | "step" | "onChange"> & {
  min?: number;
  max?: number;
  step?: number;
  value?: HTMLProps<HTMLInputElement>["value"];
  context?: any;
  hideSpinButtons?: boolean;
  onChange?: (e: OnNumericFieldChangeEvent) => void;
};

export type OnNumericFieldChangeEvent = {
  target: {
    name: string;
    value: number;
    type: "number";
    context?: any;
  };
};

export const NumericField = styled(
  ({
    name = "",
    min,
    max,
    step = 0.00001,
    value,
    context,
    hideSpinButtons: _hideSpinButtons,
    InputProps,
    onChange = noop,
    onBlur,
    ...rest
  }: NumericFieldProps) => {
    const [displayValue, setDisplayValue] = useState(valueToDisplayValue(value));

    const changeValue = useCallback(
      (value: number) => {
        onChange({
          target: {
            name,
            value,
            type: "number",
            context,
          },
        });
      },
      [name, context, onChange]
    );

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setDisplayValue(e.target.value);

        if (e.target.value === "") {
          return;
        }

        const parsedValue = parseFloat(e.target.value);
        const value = clamp(parsedValue, min, max);

        changeValue(value);
      },
      [min, max, changeValue]
    );

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(e);
        }

        const newValue = valueToNumber(value);
        const oldDisplayValue = valueToDisplayValue(value);
        const newDisplayValue = valueToDisplayValue(newValue);

        if (newDisplayValue !== oldDisplayValue) {
          changeValue(newValue);
          setDisplayValue(newDisplayValue);
        }
      },
      [value, changeValue, onBlur]
    );

    useEffect(() => {
      setDisplayValue((displayValue) => {
        if (addZeroPrefix(displayValue) !== value?.toString()) {
          return valueToDisplayValue(value);
        }

        return displayValue;
      });
    }, [value]);

    return (
      <TextField
        type="number"
        name={name}
        value={displayValue}
        InputProps={{ ...InputProps, inputProps: { min, max, step } }}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        {...rest}
      />
    );
  }
)`
  ${(props) =>
    props.hideSpinButtons &&
    `
    .MuiInputBase-input::-webkit-outer-spin-button,
    .MuiInputBase-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .MuiInputBase-input {
      -moz-appearance: textfield;
    }
  `}
`;

export const NumericFieldWithAuto = withAutoField({ defaultValue: 0 })(NumericField as any) as ComponentType<
  Omit<NumericFieldProps, "value" | "onChange"> & WithAutoFieldAddProps<NumericFieldProps["value"]>
>;
