import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, EntitySocket, StringSocket } from "../../sockets";

export class EntityHasTagNode extends BaseNode {
  static readonly type = "logic/entityHasTag";
  static readonly label = "Entity Has Tag";
  static readonly pure = true;

  inputs = [new EntitySocket("entity"), new StringSocket("tag")];
  outputs = [new BooleanSocket("result")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");
    const tag = context.readInput<string>("tag");

    context.writeOutput<boolean>("result", entity?.tags.has(tag) ?? false);
  }
}
