import { ValueType } from "~/libs/behave-graph";
import { defaultAsset } from "~/entities/variable";
import { AssetValue } from "~/types/Variable";

export type { AssetValue };

export const assetValue = new ValueType(
  "asset",
  defaultAsset,
  (json: AssetValue) => json,
  (value: AssetValue) => value
);
