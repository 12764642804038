import { VideoUIEntity } from "~/types/ui";
import { Video } from "./Video";
import { useAsset } from "~/entities/assets";
import { Color } from "three";
import { memo, ReactNode } from "react";
import IVideoAsset from "~/types/IVideoAsset";
import { useWidthHeight } from "~/view-scene/ui/RenderUIElement/useWidthHeight";

export type RenderUIVideoProps = {
  entity: VideoUIEntity;
  aspect: [number, number, number];
  children?: ReactNode;
  onClick?: (entityId: string) => void;
  showBoxHelper?: boolean;
};

export const RenderUIVideo = memo(({ entity, aspect, children, onClick, showBoxHelper }: RenderUIVideoProps) => {
  const {
    id,
    parentId,
    name,
    enabled,
    width,
    height,
    sizeUnits,
    relativeToParent,
    asset,
    contentDirection,
    padding,
    margin,
    borderRadius,
    borderWidth,
    borderColor,
    borderOpacity,
    justifyContent,
    alignItems,
    textAlign,
    backgroundOpacity,
    backgroundSize,
  } = entity;

  const videoAsset = useAsset<IVideoAsset>(asset);

  const { elementWidth, elementHeight } = useWidthHeight(
    id,
    parentId,
    width,
    height,
    aspect,
    sizeUnits,
    relativeToParent
  );

  if (!videoAsset || !enabled) {
    return null;
  }

  return (
    <Video
      name={name}
      onClick={onClick ? () => onClick(id) : undefined}
      videoAsset={videoAsset}
      width={elementWidth}
      height={elementHeight}
      margin={margin}
      padding={padding}
      contentDirection={contentDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={textAlign}
      backgroundOpacity={backgroundOpacity}
      borderRadius={borderRadius}
      borderOpacity={borderOpacity}
      borderWidth={borderWidth}
      borderColor={borderColor ? new Color(borderColor) : undefined}
      showBoxHelper={showBoxHelper}
      backgroundSize={backgroundSize}
    >
      {children}
    </Video>
  );
});
