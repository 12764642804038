import { Button as MuiButton, ButtonProps, Icon, styled } from "@mui/material";

import { palette } from "~/theme";

const Button = styled((props: ButtonProps) => (
  <MuiButton
    startIcon={
      props.startIcon ? (
        <Icon
          sx={{
            display: "block",
            width: "24px",
            position: "relative",
            height: "24px",
          }}
        >
          <img src={"" + props.startIcon} />
        </Icon>
      ) : null
    }
    {...props}
  />
))(() => ({
  display: "flex",
  whiteSpace: "nowrap",
  borderRadius: 32,
  width: "100%",
  gridGap: "4px",
  cursor: "pointer",
  backdropFilter: "blur(30px)",
  color: palette.primary.white,
  fontSize: 16,
  transition: ".4s ease",
  textTransform: "inherit",
  fontWeight: 500,
  border: "1px solid ",
  "&.stroke-width": {
    minWidth: "52px",
  },
  "&.stroke-width svg path": {
    fill: "none",
    strokeOpacity: 1,
    stroke: palette.primary.white,
  },
  "&.stroke-width:hover svg path": {
    fill: "none",
    strokeOpacity: 1,
    stroke: palette.primary.white,
  },
  "&:hover svg path": {
    fill: palette.primary.violet,
    transition: ".4s ease",
  },
  "svg path": {
    transition: ".4s ease",
  },
}));

export default Button;
