import { NodeContext } from "../../base";
import { TextSocket } from "../../sockets";
import { SceneEntityNode } from "./SceneEntityNode";

export class SceneTextNode extends SceneEntityNode {
  static readonly type = "variable/sceneText";
  static readonly label = "Text";

  constructor(context: NodeContext) {
    super(context, new TextSocket("text"));
  }
}
