import { EventEmitter } from "eventemitter3";
import { DataMessage, IDataMessenger } from "./data";
import {
  AddBodyMessage,
  AddShapeMessage,
  BodyReadyMessage,
  BodyRpcMessage,
  EnableDebuggerMessage,
  FinishBodyMessage,
  InitDebuggerMessage,
  InitMessage,
  ReadyMessage,
  TickMessage,
  RemoveBodyMessage,
  ResponseMessage,
  AddConstraintMessage,
  RemoveConstraintMessage,
  SetWorldGravityMessage,
} from "./types";

export interface Messenger {
  once(event: "ready", listener: (e: ReadyMessage["data"]) => void): this;
  once(event: "bodyReady", listener: (e: BodyReadyMessage["data"]) => void): this;
  once(event: "tick", listener: (e: TickMessage["data"]) => void): this;

  on(event: "ready", listener: (e: ReadyMessage["data"]) => void): this;
  on(event: "data", listener: (e: DataMessage["data"]) => void): this;
  on(event: "bodyReady", listener: (e: BodyReadyMessage["data"]) => void): this;
  on(event: "tick", listener: (e: TickMessage["data"]) => void): this;

  off(event: "ready", listener: (e: ReadyMessage["data"]) => void): this;
  off(event: "data", listener: (e: DataMessage["data"]) => void): this;
  off(event: "bodyReady", listener: (e: BodyReadyMessage["data"]) => void): this;
  off(event: "tick", listener: (e: TickMessage["data"]) => void): this;
}

export class Messenger extends EventEmitter implements IDataMessenger {
  constructor(private worker: Worker) {
    super();

    worker.onmessage = this.messageHandler.bind(this);
  }

  destroy() {
    this.removeAllListeners();
  }

  sendInit(data: InitMessage["data"]) {
    this.worker.postMessage({
      type: "init",
      data,
    });
  }

  sendInitDebugger(data: InitDebuggerMessage["data"]) {
    this.worker.postMessage({
      type: "initDebugger",
      data,
    });
  }

  sendEnableDebugger(data: EnableDebuggerMessage["data"]) {
    this.worker.postMessage({
      type: "enableDebugger",
      data,
    });
  }

  sendAddBody(data: AddBodyMessage["data"]) {
    this.worker.postMessage({
      type: "addBody",
      data,
    });
  }

  sendFinishBody(data: FinishBodyMessage["data"]) {
    this.worker.postMessage({
      type: "finishBody",
      data,
    });
  }

  sendRemoveBody(data: RemoveBodyMessage["data"]) {
    this.worker.postMessage({
      type: "removeBody",
      data,
    });
  }

  sendAddShape(data: AddShapeMessage["data"]) {
    this.worker.postMessage({
      type: "addShape",
      data,
    });
  }

  sendAddConstraint(data: AddConstraintMessage["data"]) {
    this.worker.postMessage({
      type: "addConstraint",
      data,
    });
  }

  sendRemoveConstraint(data: RemoveConstraintMessage["data"]) {
    this.worker.postMessage({
      type: "removeConstraint",
      data,
    });
  }

  sendBodyRpc(data: BodyRpcMessage["data"]) {
    this.worker.postMessage({
      type: "bodyRpc",
      data,
    });
  }

  sendData(data: DataMessage["data"]) {
    this.worker.postMessage({
      type: "data",
      data,
    });
  }

  sendSetWorldGravity(data: SetWorldGravityMessage["data"]) {
    this.worker.postMessage({
      type: "setWorldGravity",
      data,
    });
  }

  private messageHandler = (e: MessageEvent<ResponseMessage>) => {
    const { type, data } = e.data;

    this.emit(type, data);
  };
}
