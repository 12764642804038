import { useEffect, useRef } from "react";
import { Box, FormControl, MenuItem, Stack, Typography } from "@mui/material";
import { palette } from "~/theme";
import Select from "~/common/components/Select";
import { useOnScreen } from "~/common/hooks/useOnScreen";
import { useProfile } from "~/entities/profile";

export type SceneSettingsProps = {
  onScreen: VoidFunction;
};

export const SceneSettings = ({ onScreen }: SceneSettingsProps) => {
  const { profile, updateProfile } = useProfile((state) => ({
    profile: state.profile,
    updateProfile: state.updateProfile,
  }));

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible) {
      onScreen();
    }
  }, [isVisible]);

  if (!profile) {
    return null;
  }

  const { language, preferredDpr } = profile;

  const handleLanguageChange = (event: any) => {
    const language = event.target.value;
    updateProfile({ ...profile, language });
  };

  const handleResolutionChange = (event: any) => {
    const preferredDpr = parseInt(event.target.value);
    if (preferredDpr === 1 || preferredDpr === 2) {
      updateProfile({ ...profile, preferredDpr });
    }
  };

  return (
    <Box
      component="div"
      id="scene-settings"
      ref={ref}
      sx={{
        border: "1px solid " + palette.primary.gray100,
        backgroundColor: palette.primary.black400,
        padding: "20px 24px 28px 24px",
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={(theme) => ({
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "24px",
          color: palette.primary.gray600,
          [theme.breakpoints.down("lg")]: {
            fontSize: "18px",
            lineHeight: "22px",
          },
        })}
        variant="body1"
      >
        Scene settings
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          marginTop: "16px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: palette.primary.white,
          }}
          variant="body1"
        >
          Resolution
        </Typography>
        <Box component="div" sx={{ minWidth: "95", maxWidth: "50%" }}>
          <FormControl fullWidth>
            <Select
              value={"" + preferredDpr}
              onChange={handleResolutionChange}
              sx={{
                height: "40px",
                fonts: "16px",
                border: "1px solid " + palette.primary.gray400,
                backgroundColor: palette.primary.gray100,
              }}
            >
              <MenuItem value="1" key="medium">
                Medium
              </MenuItem>
              <MenuItem value="2" key="high">
                High
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};
