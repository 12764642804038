import { memo, useEffect, useMemo } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { CameraControlsImpl } from "./CameraControlsImpl";
import { EventDispatcher, PerspectiveCamera } from "three";
import { useUnit } from "effector-react";
import { KeyboardKeyHold } from "hold-event";
import { $sceneMode } from "~/hephaestus/builderUIState";

const KEYCODE = {
  W: 87,
  A: 65,
  S: 83,
  D: 68,
  Q: 81,
  E: 69,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
};

const wKey = new KeyboardKeyHold(KEYCODE.W, 16.666);
const aKey = new KeyboardKeyHold(KEYCODE.A, 16.666);
const sKey = new KeyboardKeyHold(KEYCODE.S, 16.666);
const dKey = new KeyboardKeyHold(KEYCODE.D, 16.666);
const qKey = new KeyboardKeyHold(KEYCODE.Q, 16.666);
const eKey = new KeyboardKeyHold(KEYCODE.E, 16.666);

type PerspectiveCameraControlsProps = {
  camera: PerspectiveCamera;
  enabled: boolean;
};
export const PerspectiveCameraControls = memo(({ camera, enabled }: PerspectiveCameraControlsProps) => {
  const { set, gl } = useThree(({ set, gl }) => ({ set, gl }));

  const sceneMode = useUnit($sceneMode);

  const controls = useMemo(() => {
    const controls = new CameraControlsImpl(camera, gl.domElement);
    controls.minDistance = controls.maxDistance = 0.1;
    controls.dollyToCursor = true;
    controls.infinityDolly = true;
    controls.azimuthRotateSpeed = 0.35;
    controls.polarRotateSpeed = 0.35;
    controls.mouseButtons.left = CameraControlsImpl.ACTION.NONE;
    controls.mouseButtons.right = CameraControlsImpl.ACTION.NONE;
    controls.mouseButtons.wheel = CameraControlsImpl.ACTION.DOLLY;
    controls.saveState();

    // it's a hack to catch camera direction in controls state
    controls.dollyPublic(0.005, 0, 0);
    return controls;
  }, [camera, gl.domElement]);

  useEffect(() => {
    set({ controls: controls as unknown as EventDispatcher });
  }, [controls]);

  useEffect(() => {
    controls.enabled = enabled;
  }, [controls, enabled]);

  useEffect(() => {
    controls.mouseButtons.right =
      sceneMode === "3d" ? CameraControlsImpl.ACTION.ROTATE : CameraControlsImpl.ACTION.NONE;

    if (sceneMode === "2d") {
      controls.reset();
    }
  }, [sceneMode, controls, camera]);

  useEffect(() => {
    const aKeyCb = (e: any) => controls.truck(-0.005 * e.deltaTime, 0, false);
    aKey.addEventListener("holding", aKeyCb);

    const dKeyCb = (e: any) => controls.truck(0.005 * e.deltaTime, 0, false);
    dKey.addEventListener("holding", dKeyCb);

    const wKeyCb = () => controls.dollyPublic(-0.1, 0, 0);
    wKey.addEventListener("holding", wKeyCb);

    const sKeyCb = () => controls.dollyPublic(0.1, 0, 0);
    sKey.addEventListener("holding", sKeyCb);

    const qKeyCb = (e: any) => controls.truck(0, 0.005 * e.deltaTime, false);
    qKey.addEventListener("holding", qKeyCb);

    const eKeyCb = (e: any) => controls.truck(0, -0.005 * e.deltaTime, false);
    eKey.addEventListener("holding", eKeyCb);

    return () => {
      aKey.removeEventListener("holding", aKeyCb);
      dKey.removeEventListener("holding", dKeyCb);
      wKey.removeEventListener("holding", wKeyCb);
      sKey.removeEventListener("holding", sKeyCb);
      qKey.removeEventListener("holding", qKeyCb);
      eKey.removeEventListener("holding", eKeyCb);
    };
  }, [controls]);

  useFrame((_, delta) => controls.update(delta), -1);

  return null;
});
