import { createEvent, createStore } from "effector";
import { CollisionEvent, ScreenShareEntityState, VideoEntityState, WebRTCConnection } from "./types";
import { Update } from "~/common/typeUtils";

export const $webRTCConnections = createStore<WebRTCConnection[]>([]);
export const addWebRTCConnection = createEvent<WebRTCConnection>();
export const updateWebRTCConnection = createEvent<Update<WebRTCConnection>>();
export const removeWebRTCConnection = createEvent<string>();
export const cleanupWebRTCConnections = createEvent();
$webRTCConnections
  .on(addWebRTCConnection, (state, newConnection) => [...state, newConnection])
  .on(updateWebRTCConnection, (state, updatedConnection) =>
    state.map((connection) =>
      connection.id === updatedConnection.id ? { ...connection, ...updatedConnection } : connection
    )
  )
  .on(removeWebRTCConnection, (state, connectionId) => {
    const webRTCConnection = state.find((connection) => connection.id === connectionId);
    if (webRTCConnection) {
      try {
        webRTCConnection.connection.close();
      } catch (e) {
        console.warn("Failed to close WebRTC connection.");
      }
    }
    return state.filter((connection) => connection.id !== connectionId);
  })
  .on(cleanupWebRTCConnections, (state) => {
    state.forEach((webRTCConnection) => {
      try {
        webRTCConnection.connection.close();
      } catch (e) {
        console.warn("Failed to close WebRTC connection.");
      }
    });
    return [];
  });

export const playRemotePlayerAnimation = createEvent<{
  userId: string;
  animation: string;
}>();

export const stopRemotePlayerAnimation = createEvent<{
  userId: string;
  animation: string;
}>();

export const remoteCollisions = createEvent<CollisionEvent[]>();

export const remoteVideoEntitiesUpdate = createEvent<VideoEntityState[]>();

export const remoteScreenShareEntitiesUpdate = createEvent<ScreenShareEntityState[]>();
