import { NodeContext } from "../../base";
import { AssetVideoSocket } from "../../sockets";
import { AssetNode } from "./AssetNode";

export class AssetVideoNode extends AssetNode {
  static readonly type = "variable/assetVideo";
  static readonly label = "Video Asset";

  constructor(context: NodeContext) {
    super(context, new AssetVideoSocket("video"));
  }
}
