import { memo } from "react";
import { LinearEncoding, Texture } from "three";
import { Block, BlockProps } from "./Block";
import { useTexture } from "~/common/utils/useTexture";

export type ImageProps = BlockProps & {
  src?: string;
};

export const Image = memo<ImageProps>(({ src, ...restProps }) => {
  const backgroundTexture = useTexture(src) as Texture | undefined;
  if (backgroundTexture) {
    backgroundTexture.encoding = LinearEncoding;
  }

  if (!backgroundTexture) {
    return null;
  }

  return <Block backgroundOpacity={1} backgroundTexture={backgroundTexture} {...restProps} />;
});
