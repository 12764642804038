import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { palette } from "~/theme";
import { HeaderWithNavigation, Logo, Navigate } from "~/common/components";
import { HOST, WHITE_LABELING_PROFILE } from "~/config";
import React from "react";

export function GameLandingPage() {
  const { landing } = WHITE_LABELING_PROFILE;

  return (
    <>
      <Background />
      <HeaderWithNavigation enableNavigation={HOST === "8xr"} label="Explore" target="/" showLogo={false} />
      <Content>
        <Logo />
        {landing.title && (
          <Typography fontSize="20px" lineHeight="28px" fontWeight={600}>
            {landing.title}
          </Typography>
        )}
        <Typography fontSize="14px" lineHeight="19.6px" fontWeight={500} align="center" color={palette.primary.gray800}>
          {landing.description}
        </Typography>
        <Navigate to={landing.rootScene}>
          <Button size="large" variant="accent">
            Start Playing
          </Button>
        </Navigate>
      </Content>
    </>
  );
}

const Background = styled(Box)(({ theme }) => ({
  position: "absolute",
  filter: "blur(1px)",
  width: "100vw",
  backgroundImage: `url(${WHITE_LABELING_PROFILE.landing.background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  height: "100vh",
  minHeight: "550px",
  [theme.breakpoints.down("lg")]: {
    overflow: "auto",
    minHeight: "700px",
    height: "100vh",
    WebkitOverflowScrolling: "touch",
  },
}));

const Content = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "416px",
  minHeight: "196px",
  borderRadius: "12px",
  padding: "32px",
  gap: "16px",
  border: "1px solid #FFFFFF1A",
  backgroundColor: palette.primary.black900,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 140px)",
  },
}));
