import create from "zustand";
import { DeviceMode } from "~/types/DeviceMode";
import isMobile, { isMobileVR } from "~/common/utils/isMobile";
import { $flags } from "~/entities/flags";

const statusesInOrder = ["loading", "data_loaded", "loaded", "ready", "no_access", "in_progress", "onpause"] as const;
const statusPositions = statusesInOrder.reduce((acc, status, index) => {
  acc[status] = index;

  return acc;
}, {} as Record<Status, number>);

export type Status = typeof statusesInOrder[number];

type UseSessionStatus = {
  sessionStatus: Status;
  mode: DeviceMode;
  disableSound: boolean;
  supportedModes: DeviceMode[];
  sceneTransition: boolean;

  toggleDisableSound: (flag?: boolean) => void;
  shouldDisplayLoadingScreen: () => boolean;
  shouldDisplayScene: () => boolean;
  shouldDisplayWelcomeScene: () => boolean;
  shouldDisplayNoAccessScreen: () => boolean;
  statusAfterOrEqual: (status: Status) => boolean;
  updateStatus: (status: Status) => void;
  updateMode: (mode: DeviceMode) => void;
  loadSupportedModes: VoidFunction;
  setSceneTransition: (flag: boolean) => void;

  reset: () => void;
};

const useSessionStatus = create<UseSessionStatus>((set, get) => ({
  sessionStatus: "loading",
  mode: "desktop",
  disableSound: false,
  supportedModes: [],
  sceneTransition: false,

  toggleDisableSound: (flag?: boolean) => {
    const newState = flag !== undefined ? flag : !get().disableSound;

    set(() => ({ disableSound: newState }));
  },

  shouldDisplayLoadingScreen: () => get().sessionStatus === "loading" || get().sessionStatus === "data_loaded",
  shouldDisplayScene: () => get().sessionStatus !== "loading",
  shouldDisplayNoAccessScreen: () => get().sessionStatus === "no_access",
  shouldDisplayWelcomeScene: () =>
    get().sessionStatus === "loaded" || get().sessionStatus === "ready" || get().sessionStatus === "onpause",

  statusAfterOrEqual: (status: Status) => {
    return statusPositions[get().sessionStatus] >= statusPositions[status];
  },

  updateStatus: (status: Status) => {
    if (get().sessionStatus !== "no_access") {
      set(() => ({ sessionStatus: status }));
    }
  },

  updateMode: (mode: DeviceMode) => set(() => ({ mode })),

  loadSupportedModes: () => {
    const supportedModes: DeviceMode[] = [];

    if (isMobile()) {
      supportedModes.push("mobile");
      set(() => ({ mode: "mobile" }));
    } else if (isMobileVR()) {
      supportedModes.push("vr");
      set(() => ({ mode: "vr" }));
    } else {
      supportedModes.push("desktop");
    }

    const forcedMode = $flags.getState().mode;
    if (forcedMode) {
      set(() => ({ mode: forcedMode }));
    }

    set(() => ({ supportedModes }));
  },

  setSceneTransition: (flag: boolean) => {
    set(() => ({ sceneTransition: flag }));
  },

  reset: () => {
    set(() => ({
      sessionStatus: "loading",
      mode: "desktop",
      disableSound: false,
      supportedModes: [],
    }));
  },
}));

export default useSessionStatus;
