import { useFrame, useThree } from "@react-three/fiber";
import { useStoreMap } from "effector-react";
import { memo, useEffect, useRef } from "react";
import { useMount, useUnmount } from "react-use";
import { MathUtils } from "three";
import { ControlsSystemDefault } from "~/types/ControlsSystem";
import useSessionStatus from "~/view-scene/stores/useSessionStatus";
import { $cameraState, firstPersonCameraRadius, resetCameraSystem, setCameraState } from "./models";
import { useZoomFactor } from "./useZoomFactor";

const cameraInterpolationFactor = 1.6;

export type CameraControlsDefaultProps = {
  controlsSystem: ControlsSystemDefault;
};

export const CameraControlsDefault = memo(({ controlsSystem }: CameraControlsDefaultProps) => {
  const target = useStoreMap($cameraState, (state) => state.target);
  const camera = useThree((state) => state.camera);
  const mode = useSessionStatus((state) => state.mode);

  const radiusRef = useRef(5);

  useMount(() => {
    setCameraState({
      phi: controlsSystem.initialPhi,
      theta: controlsSystem.initialTheta,
      minPhi: controlsSystem.minPhi,
      maxPhi: controlsSystem.maxPhi,
      thirdPersonCameraRadius: controlsSystem.cameraRadius,
      lockPhi: controlsSystem.lockPhi,
      lockTheta: controlsSystem.lockTheta,
      sensitivity: controlsSystem.sensitivity,
      radius:
        controlsSystem.initialCameraMode === "thirdPerson" ? controlsSystem.cameraRadius : firstPersonCameraRadius,
      cameraMode: controlsSystem.initialCameraMode,
      switchCameraMode: controlsSystem.switchCameraMode,
    });
  });

  useZoomFactor();

  useEffect(() => {
    if (camera.userData.customCamera) {
      return;
    }

    camera.far = 260000;
    camera.position.set(0, 0, 0);
    camera.rotation.order = "YXZ";
    camera.updateProjectionMatrix();
  }, [camera]);

  useEffect(() => {
    if (!target) {
      return;
    }

    target.add(camera);

    return () => {
      target.remove(camera);
    };
  }, [target, camera]);

  useUnmount(() => {
    resetCameraSystem();
  });

  useFrame((_, delta) => {
    if (mode === "vr" || camera.userData.customCamera) {
      return;
    }

    const { phi, theta, radius: targetRadius, enabled } = $cameraState.getState();

    if (!enabled) {
      return;
    }

    let radius = radiusRef.current;

    radius = MathUtils.damp(radius, targetRadius, cameraInterpolationFactor, delta);
    radiusRef.current = radius;

    camera.position.x = radius * Math.sin(theta) * Math.cos(phi);
    camera.position.y = radius * Math.sin(phi);
    camera.position.z = radius * Math.cos(theta) * Math.cos(phi);

    if (target) {
      camera.lookAt(target.position);
    }

    camera.position.y = camera.position.y + 0.75;
  });

  return null;
});
