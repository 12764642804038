import { NodeEvalContext } from "~/libs/behave-graph";
import { BaseBehaveNode, BaseNode } from "../../base";
import { BooleanSocket, EntitySocket, FlowSocket } from "../../sockets";

export class SetVisibilityNode extends BaseNode {
  static readonly type = "action/setVisibility";
  static readonly label = "Set visibility";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new BooleanSocket("visible")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const visible = context.readInput<boolean>("visible");
    const entity = node.readEntity(context, "entity");

    if (!entity || !entity.rootObjectRef.current) {
      return;
    }

    entity.rootObjectRef.current.visible = visible;
  }
}
