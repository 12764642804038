import { ValueType } from "~/libs/behave-graph";
import { defaultMap } from "~/entities/variable";

export type MapValue = Record<string, any>;

class MapType extends ValueType {
  constructor() {
    super(
      "map",
      defaultMap,
      (json: MapValue) => json,
      (value: MapValue) => value
    );
  }
}

export const mapValue = new MapType();
