import { createEvent, sample } from "effector";
import { $selectedEntity } from "./selectEntity";
import { requestFocus } from "~/hephaestus/SceneEditor/controls";

export const focusEntity = createEvent();

sample({
  source: $selectedEntity,
  clock: focusEntity,
  filter: (entity) => Boolean(entity?.id),
  fn: (entity) => entity?.id!,
  target: requestFocus,
});
