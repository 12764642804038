import { NodeContext } from "../../base";
import { NumberCompareNode } from "./NumberCompareNode";

export class NumberLessNode extends NumberCompareNode {
  static readonly type = "logic/numberLess";
  static readonly label = "Number <";

  constructor(context: NodeContext) {
    super(context, (a, b) => a < b);
  }
}
