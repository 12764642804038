import { createEvent } from "effector";
import { $assets } from "./assets";
import { Update } from "~/common/typeUtils/update";
import IAsset from "~/types/IAsset";

export const updateAsset = createEvent<Update<IAsset>>();

$assets.on(updateAsset, (state, update) => {
  if (!state) {
    return state;
  }

  return state.map((asset) => (asset.id === update.id ? { ...asset, ...update } : asset));
});
