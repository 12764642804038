import { defaultUIEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { uiImageValue, UIImageValue } from "../values";

export class UIImageSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: UIImageValue = defaultUIEntity()) {
    super(uiImageValue.name, id, label, value);
  }

  clone() {
    return new UIImageSocket(this.id, this.label, this.value);
  }
}
