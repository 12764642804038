import { omit } from "lodash-es";
import * as THREE from "three";
import { EventEmitter } from "eventemitter3";
import { isValueDescriptor, unwrapValueDescriptor, valueDescriptorConstructors } from "~/entities/variable";
import { DefaultEvents, JSScriptExports, JSScriptFramework } from "./types";
import { addons } from "./addons";

type ExternalJSFramework = Pick<JSScriptFramework, "sceneContext" | "entity">;

export const initJSScript = (code: string, externalFramework: ExternalJSFramework) => {
  const framework: JSScriptFramework = {
    ...externalFramework,
    THREE,
    EventEmitter,
    Types: valueDescriptorConstructors,
    addons,
  };

  try {
    const exportObject: JSScriptExports = { __meta: { variables: {} } };
    // eslint-disable-next-line no-new-func
    const scriptCode = new Function("script", "Framework", code);
    scriptCode(exportObject, framework);
    unwrapVariables(exportObject);

    return { scriptCode, exportObject };
  } catch (err) {
    console.error(err);
  }
};

const defaultEventsMap: Record<keyof DefaultEvents | "__meta", true> = {
  start: true,
  tick: true,
  dispose: true,
  __meta: true,
};

export const defaultEvents = Object.keys(defaultEventsMap) as (keyof DefaultEvents)[];

export const omitDefaultEvents = (exportObject: JSScriptExports) => {
  return omit(exportObject, defaultEvents);
};

export const isEvent = (exportObject: Record<string, any>, key: string) => {
  return key.startsWith("on") && typeof exportObject[key] === "function";
};

const unwrapVariables = (exportObject: JSScriptExports) => {
  const variablesAndEvents = omitDefaultEvents(exportObject);

  for (const key in variablesAndEvents) {
    if (!isEvent(variablesAndEvents, key) && isValueDescriptor(variablesAndEvents[key])) {
      exportObject.__meta.variables[key] = variablesAndEvents[key];
      exportObject[key] = unwrapValueDescriptor(variablesAndEvents[key]);
    }
  }
};
