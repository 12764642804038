import { Box, Stack, Typography } from "@mui/material";
import { BackToExplore } from "~/common/components";
import { availableScenes } from "~/common/data/availableScenes";
import { ScenesSection } from "./ScenesSection";

export const TrySuggestedScenes = () => {
  return (
    <>
      <Box
        component="div"
        sx={{
          maxWidth: "426px",
          margin: "0 auto",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginTop: "24px",
            gridGap: "24px",
          }}
        >
          <BackToExplore text="Explore" />
          <Stack direction="row" alignItems="center">
            <Typography fontWeight="500" color="primary.gray600" variant="body1">
              or find something new below
            </Typography>
            <Box
              component="img"
              src="/static/img/content/hand.webp"
              sx={{
                display: "block",
                width: "24px",
                height: "24px",
                marginLeft: "8px",
              }}
            />
          </Stack>
        </Stack>
      </Box>
      <ScenesSection scenes={availableScenes} />
    </>
  );
};
