import { Box, BoxProps, Stack, styled } from "@mui/material";
import { HOST, WHITE_LABELING_PROFILE } from "~/config";
import { palette } from "~/theme";

type LogoProps = Omit<BoxProps, "component" | "src">;

export const Logo = (props: LogoProps) => {
  const logoClick = () => {
    window.location.href = "/";
  };

  const logo = WHITE_LABELING_PROFILE.logo;

  switch (HOST) {
    case "ticketmeta":
    case "chimera-platfrom":
    case "digital-air":
    case "legends-dance":
      // @ts-ignore
      return <WideLogo src={logo} alt={logo} onClick={logoClick} {...props} component="img" />;
    case "8xr":
    case "gameverse":
    case "deversee":
      // @ts-ignore
      return <DefaultLogo src={logo} alt={logo} onClick={logoClick} {...props} component="img" />;
    case "dogeverse":
      return (
        <DogeverseLogoContainer {...props}>
          {/* 
            // @ts-ignore */}
          <DogeverseLogo src={logo} alt={logo} onClick={logoClick} component="img" />
        </DogeverseLogoContainer>
      );
    default:
      return null;
  }
};

const DefaultLogo = styled(Box)`
  width: 57px;
  cursor: pointer;
  transition: 0.4s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const WideLogo = styled(DefaultLogo)`
  width: 100px;
`;

const DogeverseLogo = styled(DefaultLogo)`
  height: 28.82px;
  width: 28.82px;
`;

const DogeverseLogoContainer = styled(Stack)`
  width: 46.53px;
  min-width: 46.53px;
  height: 46.53px;
  border-radius: 111px;
  align-items: center;
  justify-content: center;
  background-color: ${palette.primary.black400};
  border: 1px solid ${palette.primary.gray200};
`;
