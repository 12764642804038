import { MeshBasicMaterial } from "three";
import { RenderStream } from "./RenderStream";
import { useStoreMap } from "effector-react";
import { $audioManager } from "~/view-scene/audio";
import { $webRTCConnections } from "~/view-scene/network";
import { useEffect } from "react";
import { NotificationIcon, useNotificationsState } from "~/view-scene/ui";
import { useSceneData } from "~/common/stores/useSceneData";

type RenderRemoteStreamProps = {
  remoteUserID: string | undefined;
  screenShareMaterial: MeshBasicMaterial;
};

export function RenderRemoteStream({ remoteUserID, screenShareMaterial }: RenderRemoteStreamProps) {
  const isReady = useStoreMap($audioManager, (audioManager) => audioManager.isReady);

  const videoStream = useStoreMap($webRTCConnections, (webRTCConnections) =>
    webRTCConnections
      .find((webRTCConnection) => webRTCConnection.id === remoteUserID)
      ?.streams.find((stream) => stream.getVideoTracks().length > 0)
  );

  const addNotification = useNotificationsState((state) => state.addNotification);

  useEffect(() => {
    if (!videoStream) {
      return;
    }

    const remoteUser = useSceneData.getState().remoteUsers.find((u) => u.id === remoteUserID);

    if (remoteUser) {
      addNotification({
        icon: NotificationIcon.SCREEN_SHARE_ICON,
        text: `${remoteUser.name ?? "Somebody"} started Screen Sharing`,
        duration: 3000,
      });

      return () => {
        addNotification({
          icon: NotificationIcon.SCREEN_SHARE_ICON,
          text: `${remoteUser.name ?? "Somebody"} stopped Screen Sharing`,
          duration: 3000,
        });
      };
    }
  }, [remoteUserID, videoStream]);

  if (!videoStream || !isReady) {
    return null;
  }

  return <RenderStream mediaStream={videoStream} screenShareMaterial={screenShareMaterial} />;
}
