import { pick } from "lodash-es";
import { useMemo } from "react";
import { buildQueryString } from "~/common/utils/navigate";
import { useProfile } from "~/entities/profile";

export const useIframeUrl = (path: string) => {
  let profile = useProfile((state) => state.profile);

  return useMemo(() => {
    if (!profile) {
      // profile = {
      //   id: uuid(),
      //   name: "Unknown",
      //   avatar: "",
      // } as any;

      return null;
    }

    const profileFields = pick(profile, ["id", "name", "avatar"]) as Record<string, string>;
    const profileJSON = JSON.stringify(profileFields);
    const params = new URLSearchParams(window.location.search);
    const pickCurrentParams = ["inlineMessageId", "chatId", "messageId"];

    const query = buildQueryString({
      profile: profileJSON,
      ...pickCurrentParams.reduce((acc, key) => {
        const value = params.get(key);

        if (value) {
          acc[key] = value;
        }

        return acc;
      }, {} as Record<string, string>),
    });

    if (path.includes("osolitaire")) return `${path}`;
    else return `${path}?${query}`;
  }, [path, profile]);
};
