import { defaultMap } from "~/entities/variable";
import { BaseSocket } from "../base";
import { MapValue, mapValue } from "../values";

export class MapSocket extends BaseSocket {
  constructor(id: string, public valueType: string, label: string = id, value: MapValue = defaultMap()) {
    super(mapValue.name, id, label, value);
  }

  clone() {
    return new MapSocket(this.id, this.valueType, this.label, this.value);
  }
}
