import { IconButton, Stack } from "@mui/material";
import { ArrowLeft } from "~/common/components/Icon";
import { ActiveContentName } from "../../ActiveContentName";
import { setActiveLayout } from "../../models";

type TitleProps = {
  title: string;
};

export function Title({ title }: TitleProps) {
  return (
    <Stack direction="row" justifyContent="left" alignItems="center" gap="10px">
      <IconButton onClick={() => setActiveLayout("workspace")}>
        <ArrowLeft color="primary.gray600" size="medium" />
      </IconButton>
      <ActiveContentName>{title}</ActiveContentName>
    </Stack>
  );
}
