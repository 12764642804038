import { defaultEntity } from "~/entities/variable";
import { BaseSocket } from "../base";
import { audioValue, AudioValue } from "../values";

export class AudioSocket extends BaseSocket {
  constructor(id: string, label: string = id, value: AudioValue = defaultEntity()) {
    super(audioValue.name, id, label, value);
  }

  clone() {
    return new AudioSocket(this.id, this.label, this.value);
  }
}
