import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, styled } from "@mui/material";
import { Container, Footer, Header, LogoutButton, Navigate } from "~/common/components";
import { useProfile } from "~/entities/profile";

export type ProfilePageLayout = {
  children?: ReactNode;
};

export const ProfilePageLayout = ({ children }: ProfilePageLayout) => {
  const navigate = useNavigate();

  const { profile, logOut } = useProfile((state) => ({
    profile: state.profile,
    logOut: state.logOut,
  }));

  if (!profile) {
    navigate("/401");
    return null;
  }

  return (
    <Stack direction="column" height="100%" minHeight="100vh">
      <Header />
      <Box
        component="div"
        sx={(theme) => ({
          padding: "32px 0",
          flexGrow: "1",
          [theme.breakpoints.down("sm")]: {
            padding: "32px 0 16px 0",
          },
        })}
      >
        <Container>
          <Stack
            id="avatar"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={(theme) => ({
              marginBottom: "60px",
              [theme.breakpoints.down("md")]: {
                marginBottom: "30px",
              },
            })}
          >
            <>
              <Stack
                direction="row"
                alignItems="center"
                sx={(theme) => ({
                  gridGap: "24px",
                })}
              >
                <ProfileNavigate to="/profile/settings">Profile settings</ProfileNavigate>
              </Stack>
              <Box
                component="div"
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                })}
              >
                <LogoutButton onLogout={logOut} />
              </Box>
            </>
          </Stack>
          {children}
        </Container>
      </Box>
      <Footer />
    </Stack>
  );
};

const ProfileNavigate = styled(Navigate)`
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  transition: 0.4s ease;
  border-bottom: 3px solid transparent;

  &:hover {
    color: #fff;
  }

  &.active {
    color: #fff;
    border-color: #fff;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 40px;
  }
`;
