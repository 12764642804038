import { NodeEvalContext } from "~/libs/behave-graph";
import { Euler, MathUtils } from "three";
import { BaseBehaveNode, BaseNode } from "../../base";
import { EntitySocket, FlowSocket, Vector3Socket } from "../../sockets";
import { Vector3Value } from "../../values";

const euler = new Euler();

export class SetRotationNode extends BaseNode {
  static readonly type = "action/rotate"; // legacy key name
  static readonly label = "Set rotation";

  inputs = [new FlowSocket("flow"), new EntitySocket("entity"), new Vector3Socket("rotation")];
  outputs = [new FlowSocket("flow")];

  eval(context: NodeEvalContext, node: BaseBehaveNode) {
    const entity = node.readEntity(context, "entity");
    const vector = context.readInput<Vector3Value>("rotation");

    if (!entity) {
      return;
    }

    euler.x = MathUtils.degToRad(vector.x);
    euler.y = MathUtils.degToRad(vector.y);
    euler.z = MathUtils.degToRad(vector.z);

    entity.rootObjectRef.current?.setRotationFromEuler(euler);
  }
}
