import { Mesh, Object3D } from "three";
import { useMaterialAsset } from "~/view-scene/material";
import { ForwardedRef, forwardRef, memo, useEffect, useMemo } from "react";
import { meshBounds } from "@react-three/drei";
import { isArray } from "lodash-es";
import { disposeMaterial } from "~/view-scene/utils";

type RenderObjectWithOverrideMaterialProps = {
  object: Object3D;
  materialAssetId: string;
};

export const RenderObjectWithOverrideMaterial = memo(
  forwardRef(({ object, materialAssetId }: RenderObjectWithOverrideMaterialProps, ref: ForwardedRef<Object3D>) => {
    const material = useMaterialAsset(materialAssetId);

    useMemo(() => {
      object.traverse((el) => {
        if (el instanceof Mesh) {
          if (el.material) {
            if (isArray(el.material)) {
              el.material.forEach((m) => disposeMaterial(m));
            } else {
              disposeMaterial(el.material);
            }
          }
          el.material = material;
        }
      });
    }, [object, material]);

    return <primitive object={object} raycast={meshBounds} ref={ref} />;
  })
);
