import { Camera, Vector3 } from "three";
import { offscreenCanvasSupport } from "~/common/utils/offscreenCanvasSupport";
import { OcclusionItem } from "../models";
import { boxVertices } from "./buffers";
import { depthSortSimple } from "./depthSort";
import { initShader } from "./shader";
import "./render.css";

const defaultConfig = {
  debugger: false,
};

type InitConfig = Partial<typeof defaultConfig>;

export const initRender = (inConfig: InitConfig = defaultConfig) => {
  const config = {
    ...defaultConfig,
    ...inConfig,
  };
  const canvas = initCanvas(config);
  const gl = canvas.getContext("webgl2")! as WebGL2RenderingContext;

  const shader = initShader(gl);

  const boundingBoxArray = gl.createVertexArray();
  gl.bindVertexArray(boundingBoxArray);

  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, boxVertices, gl.STATIC_DRAW);
  gl.vertexAttribPointer(0, 3, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(0);

  gl.bindVertexArray(null);

  return {
    gl,
    canvas,
    boundingBoxArray,
    positionBuffer,
    shader,
    config,
  };
};

const initCanvas = ({ debugger: drawDebugger }: Required<InitConfig>) => {
  const getCanvas = () => {
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    return canvas;
  };

  if (drawDebugger) {
    const canvas = getCanvas();
    canvas.className = "OcclusionCullingDebugger";
    document.body.appendChild(canvas);

    return canvas;
  }

  if (offscreenCanvasSupport()) {
    return new OffscreenCanvas(window.innerWidth, window.innerHeight);
  } else {
    const canvas = getCanvas();
    canvas.className = "OcclusionCullingCanvas";
    document.body.appendChild(canvas);

    return canvas;
  }
};

const cameraPosition = new Vector3();
let firstRender = true;

export const render = (
  { gl, boundingBoxArray, positionBuffer, shader, config }: ReturnType<typeof initRender>,
  camera: Camera,
  items: OcclusionItem[]
) => {
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.clearColor(0, 0, 0, 1);
  gl.enable(gl.DEPTH_TEST);
  gl.colorMask(true, true, true, true);
  gl.depthMask(true);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  if (firstRender) {
    firstRender = false;
    return;
  }

  const viewMatrix = camera.matrixWorldInverse.clone();
  const projMatrix = camera.projectionMatrix.clone();
  const viewProjMatrix = projMatrix.multiply(viewMatrix);

  camera.getWorldPosition(cameraPosition);

  items.sort((a, b) => depthSortSimple(a, b, cameraPosition));
  // items.sort((a, b) => depthSort(a, b, viewMatrix));

  // for occlusion test

  for (const item of items) {
    if (!item.query) {
      item.query = gl.createQuery()!;
      item.queryInProgress = false;
      item.occluded = false;
    }
  }
  const colorMask = config.debugger;
  gl.colorMask(colorMask, colorMask, colorMask, colorMask);
  gl.useProgram(shader.program);
  gl.bindVertexArray(boundingBoxArray);

  for (var i = 0; i < items.length; i++) {
    const item = items[i];

    gl.uniformMatrix4fv(shader.uniforms.modelMatrixLocation, false, item.matrix.elements);
    gl.uniformMatrix4fv(shader.uniforms.viewProjMatrixLocation, false, viewProjMatrix.elements);
    gl.uniform4f(shader.uniforms.colorLocation, 1.0 - i / items.length, 0, 0, 1);

    // check query results here (will be from previous frame)
    if (item.queryInProgress && gl.getQueryParameter(item.query!, gl.QUERY_RESULT_AVAILABLE)) {
      const result = gl.getQueryParameter(item.query!, gl.QUERY_RESULT);
      item.occluded = !result;
      item.queryInProgress = false;
    }

    // Query is initiated here by drawing the bounding box of the sphere

    if (!item.queryInProgress) {
      gl.beginQuery(gl.ANY_SAMPLES_PASSED_CONSERVATIVE, item.query!);
      gl.drawArrays(gl.TRIANGLES, 0, boxVertices.length / 3);
      gl.endQuery(gl.ANY_SAMPLES_PASSED_CONSERVATIVE);
      item.queryInProgress = true;
    } else {
      gl.drawArrays(gl.TRIANGLES, 0, boxVertices.length / 3);
    }

    item.object.visible = !item.occluded;
  }

  gl.flush();
};
