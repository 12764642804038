import { Icon, IconProps } from "./Icon";

export type InputSystemProps = IconProps;
export const InputSystem = (props: InputSystemProps) => (
  <Icon width="16px" height="10px" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" {...props}>
    <path
      d="M3.99998 3.66667H4.00665M5.33331 6.33333H5.33998M6.66665 3.66667H6.67331M7.99998 6.33333H8.00665M9.33331 3.66667H9.33998M10.6666 6.33333H10.6733M12 3.66667H12.0066M3.46665 9H12.5333C13.28 9 13.6534 9 13.9386 8.85468C14.1895 8.72684 14.3935 8.52287 14.5213 8.27199C14.6666 7.98677 14.6666 7.6134 14.6666 6.86667V3.13333C14.6666 2.3866 14.6666 2.01323 14.5213 1.72801C14.3935 1.47713 14.1895 1.27316 13.9386 1.14532C13.6534 1 13.28 1 12.5333 1H3.46665C2.71991 1 2.34654 1 2.06133 1.14532C1.81044 1.27316 1.60647 1.47713 1.47864 1.72801C1.33331 2.01323 1.33331 2.3866 1.33331 3.13333V6.86667C1.33331 7.6134 1.33331 7.98677 1.47864 8.27199C1.60647 8.52287 1.81044 8.72684 2.06133 8.85468C2.34654 9 2.71991 9 3.46665 9Z"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
