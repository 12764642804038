import { BaseNode } from "../../base";
import { FlowSocket, StringSocket, NumberSocket } from "../../sockets";
import { NodeEvalContext } from "~/libs/behave-graph";

export class GetTelegramUserInitData extends BaseNode {
  static readonly type = "telegram/geUserInitData";
  static readonly label = "Get Telegram User Data";

  inputs = [new FlowSocket("flow")];
  outputs = [new FlowSocket("flow"), new StringSocket("username"), new StringSocket("locale"), new NumberSocket("id")];

  eval(context: NodeEvalContext) {
    let locale = window.Telegram?.WebApp.initDataUnsafe.user?.language_code;
    let username = window.Telegram?.WebApp.initDataUnsafe.user?.username;
    let id = window.Telegram?.WebApp.initDataUnsafe.user?.id;
    if (!locale) locale = "en";
    if (!username) username = "Someone";
    if (!id) id = 0;
    context.writeOutput<string>("locale", locale);
    context.writeOutput<string>("username", username);
    context.writeOutput<number>("id", id);
  }
}
