import { Box, Button, Stack, StackProps, styled } from "@mui/material";
import { availableCategories, GameCategory, getCategoryName } from "~/common/data/games";
import { $categoryFilter, setCategoryFilter } from "../models";
import { useStore } from "effector-react";

export type CategoriesProps = Pick<StackProps, "className" | "sx">;

export const Categories = (props: CategoriesProps) => {
  const activeCategory = useStore($categoryFilter);

  const handleCategoryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const category = event.currentTarget.dataset.category as GameCategory;

    setCategoryFilter(category === activeCategory ? null : category);
  };

  return (
    <Container {...props}>
      <Inner direction="row" alignItems="center">
        {availableCategories.map((category) => (
          <Button
            key={category}
            sx={{ flexShrink: 0 }}
            color={activeCategory === category ? "success" : "primary"}
            variant="outlined"
            size="medium"
            data-category={category}
            onClick={handleCategoryClick}
          >
            {getCategoryName(category)}
          </Button>
        ))}
      </Inner>
    </Container>
  );
};

const Container = styled(Box)`
  height: 36px;
  overflow: hidden;
`;

const Inner = styled(Stack)`
  gap: 12px;
  overflow-y: hidden;
  padding-bottom: 20px;
`;
