import {
  KeyboardKeyCombination,
  KeyboardTypeKeyCombination,
  KeyCombinationOrKeyboardTypeKeyCombination,
  KeyboardKeyCombinationUnion,
  KeyboardType,
} from "./types";
import { getOS } from "~/common/utils/getOS";

export const isKeyboardSpecificKeyCombination = (
  key: KeyCombinationOrKeyboardTypeKeyCombination
): key is KeyboardTypeKeyCombination => {
  return typeof key === "object";
};

export const normalizeKeyCombinations = <TAction extends string>(
  keys: Record<TAction, KeyboardKeyCombinationUnion>,
  keyboardType: KeyboardType
): Record<TAction, KeyboardKeyCombination[]> => {
  const map = (Object.keys(keys) as TAction[]).reduce((acc, action) => {
    const combination = keys[action];
    const combinationsArr = (
      Array.isArray(combination) ? combination : [combination]
    ) as KeyCombinationOrKeyboardTypeKeyCombination[];

    const combinationKeys = combinationsArr
      .map((key) => {
        if (isKeyboardSpecificKeyCombination(key)) {
          return key.type === keyboardType ? key.combination : null;
        }

        return key;
      })
      .filter((key) => key !== null) as KeyboardKeyCombination[];

    acc[action] = combinationKeys;

    return acc;
  }, {} as Record<TAction, KeyboardKeyCombination[]>);

  return map;
};

export const getKeyCombination = (keys: KeyboardKeyCombination[], index = 0) => {
  if (index >= keys.length) {
    return null;
  }

  return keys[index];
};

export const getKeyboardType = (): KeyboardType => {
  return getOS() === "Mac" ? "mac" : "other";
};
