import { memo } from "react";
import { SnowSceneObject } from "~/types/SnowSceneObject";
import { useEntity } from "~/view-scene/utils/useEntity";
import { RenderSnow } from "./RenderSnow";

type SnowEntityProps = {
  entityId: string;
};

export const SnowEntity = memo(({ entityId }: SnowEntityProps) => {
  const entity = useEntity<SnowSceneObject>(entityId);

  return <RenderSnow entity={entity} />;
});
