import { palette } from "~/theme";
import { Box, Stack, Typography } from "@mui/material";
import { detachWallet } from "~/api/profile.api";
import { useProfile, UserWallet } from "~/entities/profile";
import { IconButton } from "~/hephaestus/components/IconButton";
import { Trash } from "~/common/components/Icon";
import { networkToIcon, networkToName } from "~/web3Helpers";

export type RenderUserWalletProps = {
  wallet: UserWallet;
};

export const RenderUserWallet = ({ wallet }: RenderUserWalletProps) => {
  const { loadProfile } = useProfile((state) => ({
    loadProfile: state.loadProfile,
  }));

  const handleDetachWallet = async () => {
    const { status } = await detachWallet(wallet.publicAddress);
    if (status === 204) {
      await loadProfile();
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        padding: "8px 12px",
        position: "relative",
        borderTop: "1px solid " + palette.primary.gray200,
        gridGap: "4px",
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
          gridGap: "4px",
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={(theme) => ({
          gridGap: "8px",
          flex: "0 0 115px",
          [theme.breakpoints.down("sm")]: {
            order: 1,
          },
        })}
      >
        <Box
          src={networkToIcon(wallet.network)}
          component="img"
          sx={{
            width: "32px",
            height: "32px",
            display: "block",
            objectFit: "contain",
          }}
        />
        <Typography
          sx={(theme) => ({
            fontSize: "16px",
            lineHeight: "20px",
            color: palette.primary.white,
            display: "block",
            fontWeight: "600",
          })}
          variant="body1"
        >
          {networkToName(wallet.network)}
        </Typography>
      </Stack>
      <Typography
        sx={(theme) => ({
          fontSize: "12px",
          lineHeight: "15px",
          color: palette.primary.gray600,
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: "400",
          maxWidth: "280px",
          [theme.breakpoints.down("xl")]: {
            maxWidth: "45vw",
          },
          [theme.breakpoints.down("sm")]: {
            order: 3,
            textOverflow: "inherit",
            maxWidth: "100%",
            wordBreak: "break-all",
            paddingLeft: "40px",
            flex: "0 0 100%",
          },
        })}
        variant="body1"
      >
        {wallet.publicAddress}
      </Typography>
      <IconButton
        onClick={handleDetachWallet}
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            order: 2,
          },
        })}
      >
        <Trash />
      </IconButton>
    </Stack>
  );
};
