import { memo, RefObject } from "react";
import { Group } from "three";
import { EntityContext, InteractiveComponentContext, useComponentContext } from "~/view-scene/runtime";
import { ComponentType } from "~/types/ComponentType";
import { InteractiveComponent as InteractiveComponentDescriptor } from "types/component";

type InteractiveComponentProps = {
  componentDto: InteractiveComponentDescriptor;
  objectRef: RefObject<Group>;
  contextRef: RefObject<EntityContext>;
};

export const InteractiveComponent = memo(({ componentDto, objectRef, contextRef }: InteractiveComponentProps) => {
  const context: InteractiveComponentContext = {
    id: componentDto.id,
    type: ComponentType.INTERACTIVE,
  };

  useComponentContext(contextRef, componentDto.id, () => context, []);

  return null;
});
